import React, { useState } from "react";
import { Drawer, Spin } from "antd";
import {
  ADD_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_DETAILS,
  UPDATE_ANNOUNCEMENT_RECEIVERS,
  GET_CLASSES,
  DELETE_ANNOUNCEMENT,
} from "../../views/announcementsView/graphql";
import { GET_RECENT_ANNOUNCEMENTS } from "../../graphql/queries";
import { useQuery, useMutation } from "@apollo/client";
import { getSchoolID } from "../../shared/getSchoolID";
import CloseIcon from "../../components/closeIcon";
import { AnnouncementForm } from "../../components/Announcement";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import { PlusIcon } from "@heroicons/react/outline";
import AnnouncementContainer from "./AnnouncementContainer";

const PAGE_SIZE = 6;

function AnnouncementDetails() {
  const [isAddNewDrawerOpen, setIsAddNewDrawerOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const onDrawerClose = () => {
    setIsAddNewDrawerOpen(false);
  };

  const schoolId = getSchoolID();

  const { loading, error, data, refetch } = useQuery(GET_RECENT_ANNOUNCEMENTS, {
    variables: {
      schoolId,
      limit: PAGE_SIZE,
      offset: 0,
    },
  });

  const totalCount = data?.announcements_aggregate?.aggregate?.count || 0;
  const offset = (currentPage - 1) * PAGE_SIZE;

  const { data: classesData } = useQuery(GET_CLASSES, {
    variables: {
      schoolId,
    },
  });

  const [createAnnouncement, { loading: addAnnouncementLoading }] = useMutation(
    ADD_ANNOUNCEMENT,
    {
      refetchQueries: [
        {
          query: GET_RECENT_ANNOUNCEMENTS,
          variables: {
            schoolId,
            limit: PAGE_SIZE,
            offset,
          },
        },
      ],
    }
  );

  const [updateAnnouncement, { loading: updateAnnouncementLoading }] =
    useMutation(UPDATE_ANNOUNCEMENT_DETAILS, {
      refetchQueries: [
        {
          query: GET_RECENT_ANNOUNCEMENTS,
          variables: {
            schoolId,
            limit: PAGE_SIZE,
            offset,
          },
        },
      ],
    });

  const [updateAnnouncementReceivers] = useMutation(
    UPDATE_ANNOUNCEMENT_RECEIVERS
  );

  const [deleteAnnouncement, { loading: deleteAnnouncementLoading }] =
    useMutation(DELETE_ANNOUNCEMENT, {
      refetchQueries: [
        {
          query: GET_RECENT_ANNOUNCEMENTS,
          variables: {
            schoolId,
            limit: PAGE_SIZE,
            offset,
          },
        },
      ],
    });

  const handleEditAnnouncement = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsAddNewDrawerOpen(true);
  };

  const handleAddNewClick = () => {
    setSelectedAnnouncement(null);
    setIsAddNewDrawerOpen(true);
  };

  const onComplete = (data) => {
    setIsAddNewDrawerOpen(false);
    setSelectedAnnouncement(null);
  };

  const handleDeleteAnnouncement = (announcement) => {
    deleteAnnouncement({
      variables: {
        announcementId: announcement.id,
      },
    });
    setIsAddNewDrawerOpen(false);
    setSelectedAnnouncement(null);
  };

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
    refetch({
      schoolId,
      limit: PAGE_SIZE,
      offset: (nextPage - 1) * PAGE_SIZE,
    });
  };

  const announcementLoader =
    addAnnouncementLoading ||
    updateAnnouncementLoading ||
    deleteAnnouncementLoading;

  if (announcementLoader) {
    return <Spin />;
  }

  return (
    <>
      <main className="flex flex-col flex-1 px-8 pt-3 pb-8 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-hidden">
        <div className="flex flex-row gap-x-4 items-center justify-between mb-2">
          <div className="flex">
            <h1 className="text-2xl text-left font-bold flex-col m-0 mr-6">
              Sent Announcements
            </h1>
            <Button
              id="announcements-addnew"
              buttonStyle="primary"
              onClick={handleAddNewClick}
            >
              <PlusIcon className="w-4 h-4 text-white" />
              <span className="ml-2">Add new announcement</span>
            </Button>
          </div>
          <button
            id="manageclasses-class-back"
            onClick={() => {
              navigate(-1);
            }}
            className="py-3 px-4 flex justify-center items-center  bg-pink-400 hover:bg-pink-500 hover:text-white focus:ring-pink-500 focus:ring-offset-pink-200  transition ease-in duration-200 text-white text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-200"
          >
            Back
          </button>
        </div>

        <div className="flex flex-1 overflow-hidden gap-x-10">
          <AnnouncementContainer
            loading={loading}
            error={error}
            data={data}
            onEditClick={handleEditAnnouncement}
          />
        </div>
        <Pagination
          size="small"
          pageSize={PAGE_SIZE}
          total={totalCount}
          current={currentPage}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
      </main>
      <Drawer
        title={
          selectedAnnouncement ? (
            <div className="flex justify-between items-center ">
              <h2 className="font-semibold text-2xl text-gray-900 mt-3">
                Edit Announcement
              </h2>
              <Button
                buttonStyle="danger"
                className="py-2 px-4 mr-5 flex justify-center items-center rounded-lg"
                onClick={() => handleDeleteAnnouncement(selectedAnnouncement)}
              >
                Delete
              </Button>
            </div>
          ) : (
            <div className="mt-3">
              <h2 className="font-semibold text-2xl text-gray-900 ">
                New Announcement
              </h2>
            </div>
          )
        }
        placement="right"
        onClose={onDrawerClose}
        open={isAddNewDrawerOpen}
        destroyOnClose
        headerStyle={{
          backgroundColor: "#EAF0F5",
          marginBottom: 0,
          borderBottomWidth: 1,
          borderBottomColor: "#cbd5e1",
        }}
        bodyStyle={{
          backgroundColor: "#EAF0F5",
        }}
        contentWrapperStyle={{
          width: "36vw",
          boxShadow: "none",
        }}
        closeIcon={<CloseIcon />}
      >
        <AnnouncementForm
          announcement={selectedAnnouncement}
          createAnnouncement={createAnnouncement}
          updateAnnouncement={updateAnnouncement}
          updateAnnouncementReceivers={updateAnnouncementReceivers}
          classes={classesData?.schools_by_pk?.classes || []}
          isLoading={
            addAnnouncementLoading ||
            updateAnnouncementLoading ||
            deleteAnnouncementLoading
          }
          onComplete={onComplete}
        />
      </Drawer>
    </>
  );
}

export default AnnouncementDetails;
