import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "../components";
import { GET_CALENDAR_EVENTS } from "../views/calendarView/graphql";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { Switch } from "@headlessui/react";
import useAddEvent from "../hooks/useAddEvent";
import useUpdateEvent from "../hooks/useUpdateEvent";
import useDeleteEvent from "../hooks/useDeleteEvent";
import { DatePicker, TimePicker, Drawer, notification } from "antd";
import { getSchoolID } from "../shared/getSchoolID";
import CloseIcon from "../components/closeIcon";
import { PlusIcon } from "@heroicons/react/solid";
import upward from "../assets/icons/upward.png";
import downward from "../assets/icons/downward.png";
import { DATE_FORMATS, MOMENT_OFFSETS } from "../utils/constants";
import ConfirmModal from "../components/modal";
import useTracking from "../hooks/useTracking";

const { DMMMYYYY, HHMMA } = DATE_FORMATS;
const deleteDesc = "Deleting event created by CSMC is not allowed";
const AddNewEventForm = ({
  addNewEvent,
  onCompleted,
  isLoading,
  event,
  editEvent,
  selectedDate,
  setSelectedDate,
  onClickCount,
}) => {
  const [formDetails, setFormDetails] = useState({
    eventName: "",
    startDate: moment(selectedDate).startOf("day"),
    endDate: moment(selectedDate).endOf("day"),
    startTime: moment(selectedDate).startOf("day"),
    endTime: moment(selectedDate).endOf("day"),
    isAllDay: false,
    isHoliday: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (event) {
      setFormDetails({
        ...formDetails,
        eventName: event.title,
        startDate: moment(event.start_at),
        endDate: moment(event.end_at),
        startTime: moment(event.start_at),
        endTime: moment(event.end_at),
        isAllDay: event.all_day,
        groupEventId: event.group_event_id,
        isHoliday: event.holiday,
      });
    }
  }, [event]);

  const handleSubmit = (e) => {
    trackEvent("Add Calendar Event");
    e.preventDefault();
    // event name is required
    if (formDetails.eventName.length === 0) {
      setErrorMessage("Event name is required");
      return;
    }
    setErrorMessage("");
    let startDate = formDetails.startDate;
    let endDate = formDetails.endDate;
    if (!formDetails.isAllDay) {
      // set start time in start date
      startDate = moment(formDetails.startDate).set({
        hour: formDetails.startTime.hour(),
        minute: formDetails.startTime.minute(),
      });
      // set end time in end date
      endDate = moment(formDetails.endDate).set({
        hour: formDetails.endTime.hour(),
        minute: formDetails.endTime.minute(),
      });
    }
    addNewEvent(
      {
        variables: {
          schoolEvent: {
            school_id: getSchoolID(),
            title: formDetails.eventName,
            all_day: formDetails.isAllDay,
            start_at: startDate.zone(MOMENT_OFFSETS.IST_OFFSET).format(),
            end_at: endDate.zone(MOMENT_OFFSETS.IST_OFFSET).format(),
            holiday: formDetails.isHoliday,
            is_active: true,
            event_type: "",
          },
        },
      },
      {
        onSuccess: (response) => {
          onCompleted(response);
          setSelectedDate(moment());
          onClickCount.current = null;
        },
        onError: (error) => {
          console.error(error);
        },
      },
    );
  };

  const handleEditEvent = (e) => {
    trackEvent("Edit Calendar Event");
    e.preventDefault();
    // event name is required
    if (formDetails?.groupEventId) {
      notification["info"]({
        message: "Not Allowed",
        description: "Editing event created by CSMC is not allowed",
        duration: 1.5,
      });
      return;
    }
    if (formDetails.eventName.length === 0) {
      setErrorMessage("Event name is required");
      return;
    }
    setErrorMessage("");
    let startDate = formDetails.startDate;
    let endDate = formDetails.endDate;
    if (!formDetails.isAllDay) {
      // set start time in start date
      startDate = moment(formDetails.startDate).set({
        hour: formDetails.startTime.hour(),
        minute: formDetails.startTime.minute(),
      });
      // set end time in end date
      endDate = moment(formDetails.endDate).set({
        hour: formDetails.endTime.hour(),
        minute: formDetails.endTime.minute(),
      });
    }
    editEvent(
      {
        eventId: event.id,
        schoolId: getSchoolID(),
        schoolEventData: {
          eventId: event.id,
          schoolId: getSchoolID(),
          title: formDetails.eventName,
          allDay: formDetails.isAllDay,
          startAt: startDate.zone(MOMENT_OFFSETS.IST_OFFSET).format(),
          endAt: endDate.zone(MOMENT_OFFSETS.IST_OFFSET).format(),
          holiday: formDetails.isHoliday,
          isActive: true,
          event_type: "",
        },
      },
      {
        onSuccess: (response) => {
          onCompleted(response);
          setSelectedDate(moment());
          onClickCount.current = null;
        },
        onError: (reserror) => console.error(reserror),
      },
    );
  };

  const disabledDate = (current) => {
    const startDate = formDetails.startDate;
    // cannot select days before start date
    return current && current.valueOf() < startDate.valueOf();
  };

  const loadingButtonClass = isLoading ? "opacity-20" : "";
  return (
    <>
      <div className="flex flex-col border-opacity-10 px-3">
        <form className="flex flex-col gap-6 mt-2">
          <div className="flex flex-col">
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
              Event Name
            </label>
            <input
              id="managecalendar-addevent-name"
              type="text"
              className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
              value={formDetails.eventName}
              required
              onChange={(e) =>
                setFormDetails({ ...formDetails, eventName: e.target.value })
              }
            />
          </div>
          <div className="flex justify-between items-center">
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left  w-20 mr-1">
              Starts on
            </label>
            <div id="managecalendar-addevent-startson" className="flex gap-5">
              <DatePicker
                value={moment(formDetails.startDate)}
                onChange={(value) => {
                  setFormDetails({
                    ...formDetails,
                    startDate: moment(value),
                    endDate: formDetails?.endDate
                      ? formDetails.endDate
                      : moment(value),
                  });
                }}
                suffixIcon={null}
                format={DMMMYYYY}
                allowClear={false}
                style={{
                  borderRadius: "6px",
                }}
              />
              {!formDetails.isAllDay && (
                <TimePicker
                  value={moment(formDetails.startTime)}
                  onChange={(value) => {
                    setFormDetails({
                      ...formDetails,
                      startTime: moment(value),
                    });
                  }}
                  suffixIcon={null}
                  format={HHMMA}
                  allowClear={false}
                  style={{
                    borderRadius: "6px",
                  }}
                />
              )}
            </div>
          </div>
          <div
            id="managecalendar-addevent-endson"
            className="flex justify-between items-center"
          >
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left w-20 mr-1">
              Ends on
            </label>
            <div className="flex gap-5">
              <DatePicker
                value={moment(formDetails.endDate)}
                disabledDate={disabledDate}
                onChange={(value) => {
                  setFormDetails({
                    ...formDetails,
                    endDate: moment(value),
                  });
                }}
                suffixIcon={null}
                format={DMMMYYYY}
                allowClear={false}
                style={{
                  borderRadius: "6px",
                }}
              />
              {!formDetails.isAllDay && (
                <TimePicker
                  value={moment(formDetails.endTime)}
                  onChange={(value) => {
                    setFormDetails({
                      ...formDetails,
                      endTime: moment(value),
                    });
                  }}
                  suffixIcon={null}
                  format={HHMMA}
                  allowClear={false}
                  style={{
                    borderRadius: "6px",
                  }}
                />
              )}
            </div>
          </div>
          <div id="managecalendar-alldayevent" className="flex justify-between">
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
              All day event?
            </label>
            <Switch
              checked={formDetails.isAllDay}
              onChange={(checked) => {
                setFormDetails({ ...formDetails, isAllDay: checked });
              }}
              disabled={formDetails?.isHoliday}
              className={`${
                formDetails.isAllDay ? "bg-indigo-700" : "bg-gray-200"
              } ${
                formDetails?.isHoliday ? "opacity-50" : "opacity-100"
              } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span
                className={`transform transition ease-in-out duration-400 ${
                  formDetails.isAllDay ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
          </div>
          <div
            id="managecalendar-markasholiday"
            className="flex justify-between"
          >
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
              Mark as holiday?
            </label>
            <Switch
              checked={formDetails.isHoliday}
              onChange={(checked) => {
                let isAllDay = formDetails?.isAllDay || false;
                if (!formDetails?.isAllDay && checked) {
                  isAllDay = true;
                }
                setFormDetails({
                  ...formDetails,
                  isHoliday: checked,
                  isAllDay,
                });
              }}
              className={`${
                formDetails.isHoliday ? "bg-pink-400" : "bg-gray-200"
              } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span
                className={`transform transition ease-in-out duration-400 ${
                  formDetails.isHoliday ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
          </div>
          {event ? (
            <div className="cancel-save-event">
              <button
                id="managecalendar-editevent-cancel"
                onClick={event ? handleEditEvent : handleSubmit}
                disabled={isLoading}
                className={`text-gray-700 font-medium py-2 px-4 rounded-md border-2 border-gray-300 ${loadingButtonClass}`}
              >
                {isLoading ? "Loading..." : "Cancel "}
              </button>
              <button
                id="managecalendar-editevent-saveevent"
                onClick={event ? handleEditEvent : handleSubmit}
                disabled={isLoading}
                className={`bg-indigo-600 text-white font-medium py-2 px-4 rounded-md ${loadingButtonClass}`}
              >
                {isLoading ? "Loading..." : "Save Event"}
              </button>
            </div>
          ) : (
            <button
              id="managecalendar-savenewevent"
              onClick={event ? handleEditEvent : handleSubmit}
              disabled={isLoading}
              className={`bg-indigo-600 text-white font-medium py-2 px-4 rounded-md ${loadingButtonClass}`}
            >
              {isLoading
                ? "Loading..."
                : event
                ? "Save Event"
                : "Add event to calendar"}
            </button>
          )}
          {errorMessage && (
            <div className="text-red-600 text-sm font-medium">
              {errorMessage}
            </div>
          )}
          <div className="flex items-center justify-center"></div>
        </form>
      </div>
    </>
  );
};

function ManageCalendar() {
  const [events, setEvents] = useState([]);
  const [isAddNewDrawerOpen, setIsAddNewDrawerOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().startOf("month"));
  const [selectedDate, setSelectedDate] = useState(moment());
  const onClickCount = useRef(null);
  const [collapseEditEventForm, setCollapseEditEventForm] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [deletedEvent, setDeletedEvent] = useState(null);

  const {
    loading,
    error,
    data,
    refetch: refetcCalendarEvents,
  } = useQuery(GET_CALENDAR_EVENTS, {
    variables: {
      schoolId: getSchoolID(),
      startDate: selectedMonth.startOf("month").format(),
      endDate: selectedMonth.endOf("month").format(),
    },
  });

  const { mutate: addCalendarEvent, isLoading: addCalendarEventLoading } =
    useAddEvent({
      onSuccess: () => {
        refetcCalendarEvents({
          schoolId: getSchoolID(),
          startDate: selectedMonth.startOf("month").format(),
          endDate: selectedMonth.endOf("month").format(),
        });
      },
    });

  const { mutate: editCalendarEvent } = useUpdateEvent({
    onSuccess: () => {
      refetcCalendarEvents({
        schoolId: getSchoolID(),
        startDate: selectedMonth.startOf("month").format(),
        endDate: selectedMonth.endOf("month").format(),
      });
    },
  });

  const { mutate: deleteCalendarEvent } = useDeleteEvent({
    onSuccess: () => {
      refetcCalendarEvents({
        schoolId: getSchoolID(),
        startDate: selectedMonth.startOf("month").format(),
        endDate: selectedMonth.endOf("month").format(),
      });
    },
  });

  const onDrawerClose = () => {
    onClickCount.current = null;
    setSelectedDate(moment());
    setIsAddNewDrawerOpen(false);
  };

  const onCalendarDateChange = (date) => {
    setSelectedMonth(date);
  };

  const onAddNewEventCompleted = (response) => {
    setIsAddNewDrawerOpen(false);
    setSelectedEvent(null);
    setSelectedDate(moment());
  };

  const handleEventClick = (event) => {
    setEvents((events) => []);
    setSelectedEvent(event);
    setIsAddNewDrawerOpen(true);

    let allDayEvents = data?.school_events;
    let currentEventDate = event.start_at.substring(8, 10);
    allDayEvents.forEach((e) => {
      let eventdate = e.start_at.substring(8, 10);
      if (currentEventDate.localeCompare(eventdate) === 0) {
        setEvents((events) => [...events, e]);
      }
    });
  };

  const handleDeleteEvent = (event) => {
    deleteCalendarEvent(
      {
        eventId: event.id,
      },
      {
        onSuccess: () => {
          onClickCount.current = null;
          setIsAddNewDrawerOpen(false);
          setSelectedEvent(null);
          setSelectedDate(moment());
        },
      },
    );
  };

  const onSelectDate = (value) => {
    onClickCount.current += 1;
    if (onClickCount.current === 2) {
      setIsAddNewDrawerOpen(true);
      setSelectedDate(value._d.toString().substring(3, 15));
      onClickCount.current = null;
    }
  };

  const toggleCollapseEditEvent = (index) => {
    setCollapseEditEventForm({
      ...collapseEditEventForm,
      [index]: !collapseEditEventForm[index],
    });
  };

  let eventCount = events.length > 1;

  const styles = {
    formStyle: {
      borderWidth: eventCount ? 1 : 0,
    },
  };

  const canDeleteAll = () => {
    let canDelete = true;
    events.forEach((e) => {
      if (e.groupEventId) {
        canDelete = false;
      }
    });
    if (canDelete) {
      setShowDeleteAllModal(true);
    } else {
      notification["info"]({
        message: "Not Allowed",
        description: deleteDesc,
        duration: 1.5,
      });
    }
  };

  const handleDeleteAllEvents = (events) => {
    if (events?.length) {
      events.forEach((e) => {
        handleDeleteEvent(e);
      });
    } else {
      handleDeleteEvent(events);
    }
  };

  const onConfirmDeleteAll = () => {
    setShowDeleteAllModal(false);
    handleDeleteAllEvents(events);
  };

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    handleDeleteEvent(deletedEvent);
  };

  const onCancel = () => {
    setShowDeleteAllModal(false);
    setShowDeleteModal(false);
  };

  return (
    <div>
      <div className="flex flex-col px-0 md:p-4 md:space-y-4 h-screen w-auto overflow-x-hidden overflow-y-auto">
        <div className="flex flex-row gap-x-4 items-center mb-6">
          <p className="text-2xl text-left font-bold flex-col m-0">Calendar</p>
          <button
            id="managecalendar-addnewevent"
            type="button"
            onClick={() => setIsAddNewDrawerOpen(true)}
            className="py-3 px-5 flex justify-center items-center bg-indigo-700 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
          >
            <span className="flex justify-center items-center gap-x-1">
              <PlusIcon className="h-4 w-5" />
              Add new event
            </span>
          </button>
        </div>
        <Calendar
          events={data?.school_events}
          onDateChange={onCalendarDateChange}
          isLoading={loading}
          onEventClick={handleEventClick}
          onSelectDate={onSelectDate}
        />
      </div>
      <Drawer
        title={
          selectedEvent ? (
            <div className="flex justify-between items-center">
              <h2 className="font-semibold text-xl text-gray-900">
                {eventCount ? "Edit Events" : "Edit Event"}
              </h2>
              <span>
                <button
                  id="managecalendar-editevent-deleteall"
                  onClick={() => {
                    canDeleteAll();
                  }}
                  className=" h-30 py-3 px-4 mr-5 flex justify-center items-center  bg-red-500 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
                >
                  {eventCount ? "Delete all Events" : "Delete Event"}
                </button>
              </span>
            </div>
          ) : (
            <h2 className="font-semibold text-xl text-gray-900 ml-3">
              Add Event
            </h2>
          )
        }
        placement="right"
        onClose={onDrawerClose}
        visible={isAddNewDrawerOpen}
        destroyOnClose
        contentWrapperStyle={{
          width: "32vw",
        }}
        closeIcon={<CloseIcon />}
        afterVisibleChange={(visible) => {
          if (!visible) {
            setSelectedEvent(null);
          }
        }}
      >
        <ConfirmModal
          visible={showDeleteAllModal}
          onConfirm={onConfirmDeleteAll}
          onCancel={onCancel}
          cancelText="No"
          okText="Yes"
          message={`Are you sure you want to remove ${
            eventCount ? " all events ?" : " this event ?"
          }`}
        />
        <ConfirmModal
          visible={showDeleteModal}
          onConfirm={onConfirmDelete}
          onCancel={onCancel}
          cancelText="No"
          okText="Yes"
          message="Are you sure you want to remove this event ?"
        />
        {selectedEvent ? (
          <div>
            {events.map((item, index) => {
              let startTime = moment(item.start_at).format("LT");
              let endTime = moment(item.end_at).format("LT");
              let totalEventTime = `${startTime} - ${endTime}`;
              let finalEventTime = totalEventTime
                .replace("AM", "am")
                .replace("PM", "pm");
              return (
                <div
                  key={index}
                  className={`${
                    eventCount ? "rounded-xl border-[#000000] p-2 mb-4" : ""
                  }`}
                  style={styles.formStyle}
                >
                  {eventCount ? (
                    <div id="editeventform" className="flex justify-between">
                      <div>
                        <div className="font-semibold text-lg mt-2 ml-2 ">
                          {collapseEditEventForm[index] ? item.title : " "}
                        </div>
                        <div className="font-sm font-normal text-gray-500 ml-2 ">
                          {collapseEditEventForm[index] ? finalEventTime : " "}
                        </div>
                      </div>
                      {!collapseEditEventForm[index] ? (
                        <div className="flex justify-end mt-2 -mr-40">
                          <button
                            id="managecalendar-editevent-delete"
                            className="w-32 h-30 py-3 px-4 flex justify-center items-center  bg-red-500 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
                            onClick={() => {
                              if (item?.groupEventId) {
                                notification["info"]({
                                  message: "Not Allowed",
                                  description: deleteDesc,
                                  duration: 1.5,
                                });
                                return;
                              }
                              setDeletedEvent(item);
                              setShowDeleteModal(true);
                            }}
                          >
                            Delete Event
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <button
                        onClick={() => toggleCollapseEditEvent(index)}
                        className="flex justify-center mr-1 "
                      >
                        <img
                          alt="img"
                          src={collapseEditEventForm[index] ? downward : upward}
                          className="w-4 h-4 mt-3"
                        />
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {(!collapseEditEventForm[index] || !eventCount) && (
                    <div>
                      <AddNewEventForm
                        addNewEvent={addCalendarEvent}
                        onCompleted={onAddNewEventCompleted}
                        isLoading={addCalendarEventLoading}
                        editEvent={editCalendarEvent}
                        event={item}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        onClickCount={onClickCount}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div id="addeventform">
            <AddNewEventForm
              addNewEvent={addCalendarEvent}
              onCompleted={onAddNewEventCompleted}
              isLoading={addCalendarEventLoading}
              editEvent={editCalendarEvent}
              event={selectedEvent}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              onClickCount={onClickCount}
            />
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default ManageCalendar;