/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAdmissionEnquires = async (payload) => {
	const config = {
		url: `${process.env.REACT_APP_NODE_ENDPOINT}/admission/getAdmissionEnquires`,
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		data: payload,
	};
	const { data } = await axios.request(config);
	return data?.data;
};

export default function useGetAdmissionEnquires(payload, options = {}) {
	return useQuery(
		["getAdmissionEnquires", payload],
		() => getAdmissionEnquires(payload),
		{			
			enabled: payload?.academicYearId !== undefined,
			...options,
		}
	);
}
