import React, { useState } from "react";
import { Dropdown } from "../../components";
import useFeesDetails from "../../hooks/useFeeDetails";
import { getFormatedNumber } from "../../utils";
import { DATE_FORMATS } from "../../utils/constants";
import moment from "moment";

const formatDataForDropdown = (feesDetails) =>
  feesDetails?.map((feeData) => ({
    label: feeData?.title,
    value: feeData?.id,
  }));

const TransactionHistory = ({ transactions }) => {
  if (transactions?.length === 0) return null;

  return (
    <section>
      <header>
        <h6 className="text-fainterGrey font-medium text-base mt-3 text-left">
          Transaction history
        </h6>
      </header>

      {transactions?.map((transaction, index) => {
        let divStyle = "w-full pb-1.5 mb-2 flex flex-1 justify-between";
        if (index !== transactions?.length - 1) {
          divStyle += " border-b border-fadedGrey";
        }

        return (
          <div className={divStyle}>
            <p className="text-darkerGrey font-medium text-base">
              {moment(transaction?.createdAt).format(
                DATE_FORMATS.DDMMMYYYYYHHMMA
              )}
            </p>
            <p className="text-darkerGrey font-medium text-base">
              {getFormatedNumber(transaction?.amountPaid)}
            </p>
          </div>
        );
      })}
    </section>
  );
};

const Fees = ({ studentId, divisionId }) => {
  const [feeSelected, setFeeSelected] = useState(null);

  const { data } = useFeesDetails(
    {
      divisionId,
      studentId,
    },
    {
      onSuccess: (feeData) => {
        const overallFee = feeData?.[0];
        setFeeSelected(overallFee);
      },
    }
  );

  const handleFeeDetailChange = (value) => {
    const selectedFee = data.find((feeDetail) => feeDetail.id === value);
    setFeeSelected(selectedFee);
  };

  const remainingFee = feeSelected?.totalFees - feeSelected?.paidFees;

  return (
    <article className="pr-5 h-full w-full block overflow-y-auto p-4 bg-white rounded-xl card-shadow">
      <header className="flex flex-1 justify-between">
        <h2 className="text-left font-semibold text-darkerGrey text-xl">
          Fees
        </h2>
        <Dropdown
          id="fee-summary-dropdown"
          value={feeSelected?.title}
          type="primary"
          options={formatDataForDropdown(data)}
          onItemClick={handleFeeDetailChange}
        />
      </header>

      <footer className="w-full mt-2.5">
        <div className="w-full border rounded-md border-fadedGrey bg-faintBlue flex flex-1 justify-between p-4 my-3">
          <p className="font-semibold text-linerGrey text-base">Total Fees</p>
          <p className="font-semibold text-darkerGrey text-base">
            {getFormatedNumber(feeSelected?.totalFees)}
          </p>
        </div>

        <div className="w-full border rounded-md border-fadedGrey bg-faintBrown flex flex-1 justify-between p-4 my-3">
          <p className="font-semibold text-linerGrey text-base">Paid Fees</p>
          <p className="font-semibold text-darkerGrey text-base">
            {getFormatedNumber(feeSelected?.paidFees)}
          </p>
        </div>

        <div className="w-full border rounded-md border-fadedGrey bg-faintPink flex flex-1 justify-between p-4 mt-3">
          <p className="font-semibold text-linerGrey text-base">
            Remaining Fees
          </p>
          <p className="font-semibold text-darkerGrey text-base">
            {getFormatedNumber(remainingFee)}
          </p>
        </div>
      </footer>

      <TransactionHistory transactions={feeSelected?.transactions} />
    </article>
  );
};

export default Fees;
