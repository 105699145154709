import React from "react";
import { ErrorMessage } from "@hookform/error-message";

const CustomInput = ({
  id,
  name,
  label,
  placeholder,
  register,
  required,
  rules,
  errors,
  type: inputType,
  disabled,
  important,
  ...props
}) => {
  return (
    <>
      {required ? (
        <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
          {label}{" "}
          {
            <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
              *
            </label>
          }
        </label>
      ) : (
        <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
          {label}
        </label>
      )}
      <input
        id={id}
        {...register(name, { required, ...rules })}
        className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 "
        type={inputType ? inputType : "text"}
        disabled={disabled}
        {...props}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p
              key={type}
              className="text-left italic text-sm text-red-600 mt-1 "
            >
              {message}
            </p>
          ))
        }
      />
    </>
  );
};
export default CustomInput;
