import { gql } from "@apollo/client";

export const GET_SCHOOL_DETAILS = gql`
  query getSchoolDetails($schoolId: Int!) {
    schools_by_pk(id: $schoolId) {
      acadamic_years(
        where: {
          is_current: { _eq: true }
          school_id: { _eq: $schoolId }
          is_active: { _eq: true }
        }
      ) {
        id
        title
        from_date
        to_date
      }
      address {
        id
        city {
          city_name
          district {
            state {
              state_name
            }
          }
        }
      }
      image
      email
      id
      board_name
      mobile_number
      udise_number
      trust_name
      title
      medium
    }
  }
`;

export const GET_DIVISION_STUDENTS = gql`
  query GetStudentsByDivision($divisionId: Int!) {
    division_students(
      where: {
        division_id: { _eq: $divisionId }
        is_active: { _eq: true }
        student: { is_active: { _eq: true }, is_draft: { _eq: false } }
      }
    ) {
      student {
        id
        aadhaar
        full_name
        gr_no
        dob
        father_name
        mother_name
        image
      }
    }
  }
`;

export const GET_STUDENT_MARKS = gql`
  query getMarksForScoreCard($divisionId: Int!, $studentId: Int!) {
    assessments(
      where: {
        class: { divisions: { id: { _eq: $divisionId } } }
        is_active: { _eq: true }
        is_graded: { _eq: true }
      }
    ) {
      id
      title
      term
      assessment_subjects(
        where: {
          subject: { is_enable: { _eq: true }, is_active: { _eq: true } }
          division_id: { _eq: $divisionId }
        }
      ) {
        assessment_results(where: { student_id: { _eq: $studentId } }) {
          mark
        }
        id
        total_marks
        weightage
        subject {
          id
          title
        }
      }
    }
  }
`;

export const GET_STUDENT_ATTENDANCE = gql`
  query getAttendance($studentId: Int!) {
    month_wise_attendance(
      where: { student_id: { _eq: $studentId } }
      order_by: { month: asc }
    ) {
      student_id
      month
      present
      total
    }
  }
`;
