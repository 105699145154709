import React from "react";
import { useTranslation } from "react-i18next";

function Text({ id, text, values }) {
  const { t: translate } = useTranslation();

  return <>{id ? translate(id, { ...values }) : text}</>;
}

export default Text;
