import React, { useState, useMemo, useEffect } from "react";
import CustomMultiSelect from "../../../components/CustomMultiSelect";
import useGetExpenseCategories from "../../../hooks/useGetExpenseCategories";
import { getSchoolID } from "../../../shared/getSchoolID";
import useFilterOptions from "../../../hooks/useFilterOptions";

const FilterTypeSelect = ({ type, onSelect }) => {
	const schoolId = getSchoolID();
	const { data: expenseData } = useGetExpenseCategories({
		schoolId,
	});
	const { data: collectionData } = useFilterOptions(
		{
			schoolId,
			entity: "feeCollection",
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const [expenseCategories, paymentModes] = useMemo(() => {
		const categories = (expenseData?.category || []).filter((c) => c?.value);
		const modes = (expenseData?.amount || []).filter((p) => p?.value);
		return [categories, modes];
	}, [expenseData]);
	const feeStructures = useMemo(() => {
		return collectionData?.feeStructures || [];
	}, [collectionData]);

	const [selectedPaymentMode, setPaymentModes] = useState([]);
	const [selectedExpenseCategories, setExpenseCategories] = useState([]);
	const [selectedFeeStructures, setFeeStructure] = useState([]);

	const handleChange = (newKey, keyData) => {
		let data = {};
		if (type === "collection") {
			data["feeStructures"] = selectedFeeStructures;
		} else if (type === "expense") {
			data["paymentModes"] = selectedPaymentMode;
			data["expenseCategories"] = selectedExpenseCategories;
		} else {
			onSelect({});
			return;
		}
		if (newKey) {
			data[newKey] = keyData;
		}
		onSelect(data);
	};

	useEffect(() => {
		handleChange(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	return type === "collection" ? (
		<CustomMultiSelect
			options={feeStructures}
			selected={selectedFeeStructures}
			onChange={(v) => {
				setFeeStructure(v);
				handleChange("feeStructures", v);
			}}
			filterType={"Filter by: Fee"}
		/>
	) : type === "expense" ? (
		<>
			<CustomMultiSelect
				options={paymentModes}
				selected={selectedPaymentMode}
				onChange={(v) => {
					setPaymentModes(v);
					handleChange("paymentModes", v);
				}}
				filterType={"Filter by: Mode"}
			/>
			<CustomMultiSelect
				options={expenseCategories}
				selected={selectedExpenseCategories}
				onChange={(v) => {
					setExpenseCategories(v);
					handleChange("expenseCategories", v);
				}}
				filterType={"Filter by: Category"}
			/>
		</>
	) : null;
};

export default FilterTypeSelect;