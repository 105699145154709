import React from "react";
import AnnouncementCard from "../../components/Announcement/AnnouncementCard";
import blueSpeaker from "../../assets/images/blueSpeaker.png";

const AnnouncementContainer = ({ loading, error, data, onEditClick }) => {
  const isEmpty =
    data?.announcements?.length === 0 ||
    data?.announcements?.length === undefined;

  if (isEmpty) {
    return (
      <div className="flex flex-col flex-1 overflow-hidden h-full">
        <div className="px-6 bg-white h-full">
          <div className="w-full h-full bg-white flex flex-col justify-center items-center">
            <img src={blueSpeaker} alt="Blue Speaker" className="w-1/3" />
            <div className="mt-5 w-2/3">
              <h4 className="text-lg font-bold text-lightBlack text-center">
                No Announcement Sent
              </h4>
              <p className="text-purpleDark text-center font-medium text-sm">
                Please click on Add New Announcement button to send announcement
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 overflow-hidden pb-4 relative">
      <div className="grid grid-cols-2 px-6 bg-white pb-4 overflow-y-auto gap-x-5">
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error! {error.message}</div>
        ) : (
          data.announcements.map((announcement, index) => (
            <AnnouncementCard
              id={index}
              key={announcement.id}
              announcement={announcement}
              onEditClick={onEditClick}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default AnnouncementContainer;
