/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getStudentAttendanceDetails = async (payload) => {
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/reports/studentDetails`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useGetStudentAttendanceDetails(payload, options = {}) {
  return useQuery(
    ["studentAttendanceDetails", payload],
    () => getStudentAttendanceDetails(payload),
    options
  );
}
