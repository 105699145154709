import { useState } from "react";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Popover } from "antd";
import {
  GET_CLASSES,
  DELETE_CLASS,
  GET_SUBJECTS_OF_CLASS,
} from "../../views/classesView/graphql";
import { getSchoolID } from "../../shared/getSchoolID";
import popOverIcon from "../../assets/images/popover.png";

function ClassMenu({ classText, classId, students_count, refetchClasses }) {
  let navigate = useNavigate();
  const [subjectCount, setSubjectCount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [deleteClass] = useMutation(DELETE_CLASS, {
    refetchQueries: {
      query: GET_CLASSES,
      variables: {
        schoolId: getSchoolID(),
      },
    },
  });

  const { loading, error } = useQuery(GET_SUBJECTS_OF_CLASS, {
    variables: {
      classid: classId,
      withDivisions: false,
      divid: 1,
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      setSubjectCount(res.subjects.filter((sub) => sub.selected).length);
    },
  });

  const handleDeleteClass = () => {
    deleteClass({
      variables: {
        id: classId,
      },
    })
      .then((response) => {
        notification["success"]({ message: "Class deleted", duration: 1.5 });
        refetchClasses();
      })
      .catch((reserror) => {
        notification["error"]({
          message: "Something went wrong",
          description: "Please try again.",
          duration: 1.5,
        });
      });
  };

  const popOverContent = (
    <div className="flex flex-row">
      <img
        src={popOverIcon}
        width={20}
        height={20}
        alt="error icon"
        className="mt-auto mb-auto mr-3"
      />
      <p style={{ textAlign: "left", color: "#0C0663" }}>
        You cannot remove the class if there are more than 0 students in the
        class.
      </p>
    </div>
  );

  return (
    <div className="flex-row flex items-center mb-2.5 gap-x-2">
      <div className=" text-lg text-gray-900 font-medium ">
        Class {classText}
      </div>
      <div id="manageclasses-deleteclass" className="">
        {students_count === 0 ? (
          <button
            className="ml-2 -mr-1 mt-1"
            onClick={(e) => {
              setShowModal(true);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="10" fill="#EB5757" />
              <path
                d="M6.66666 13.3333L13.3333 6.66666M6.66666 6.66666L13.3333 13.3333"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        ) : (
          <Popover
            placement="bottomLeft"
            content={popOverContent}
            color="#D4E8FF"
            overlayStyle={{
              width: "16vw",
              boxShadow: "none",
            }}
            overlayInnerStyle={{
              borderRadius: "8px",
              marginLeft: "-2vw",
              boxShadow: "none",
            }}
          >
            <div
              className="ml-2 -mr-1"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#9CA3AF" />
                <path
                  d="M6.66666 13.3333L13.3333 6.66666M6.66666 6.66666L13.3333 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </Popover>
        )}
      </div>
      <div className="ml-4">
        <button
          id="manageclasses-subjects"
          className="px-3 py-1 bg-indigo-700 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md"
          onClick={() => {
            navigate(`/class/${classId}/subjects`, {
              state: {
                className: classText,
                from: "Classes",
              },
            });
          }}
        >
          Manage Subjects ({subjectCount})
        </button>
      </div>
      <div className="ml-4 px-3 py-1 text-sm rounded-full text-green-800  bg-green-100">
        {students_count} Students
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-md">
            <div className="relative w-auto my-6 mx-auto max-w-3xl h-48">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center justify-center w-full h-full bg-white outline-none focus:outline-none">
                <div className="flex items-center pl-5 pr-5 pb-7 justify-center rounded-t">
                  <h3 className="text-xl font-semibold">
                    Are you sure you want to remove the class?
                  </h3>
                </div>
                <div className="flex items-center justify-center rounded-b">
                  <button
                    className="px-5 py-2 bg-cancel text-white transition ease-in duration-200 text-center text-lg leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md mr-2"
                    type="button"
                    onClick={(e) => {
                      setShowModal(false);
                      e.stopPropagation();
                    }}
                  >
                    No
                  </button>
                  <button
                    className="px-5 py-2 bg-success text-white transition ease-in duration-200 text-center text-lg leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ml-2"
                    type="button"
                    onClick={(e) => {
                      setShowModal(false);
                      e.stopPropagation();
                      handleDeleteClass();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}

export default ClassMenu;
