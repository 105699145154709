import React, { useState } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { Dropdown } from "../../components";
import useGetAcadamicYearMonths from "../../hooks/useGetAcadamicYearMonths";
import { getSchoolID } from "../../shared/getSchoolID";
import moment from "moment";
import useGetWeeklyChart from "../../hooks/useGetWeeklyChart";
import Overlay from "./Overlay";

const isTodayBetweenDates = (startDate, endDate) => {
  const today = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return today.isBetween(start, end, "days", "[]");
};

const addValuesToLabels = (data) => {
  return data?.map((month) => ({
    ...month,
    value: month.label,
  }));
};

const WeeklyChart = ({ studentId, hasActiveSubscription }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);

  const { data: months } = useGetAcadamicYearMonths(
    {
      schoolId: getSchoolID(),
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const currentMonth = data?.find((month) =>
          isTodayBetweenDates(month.startDate, month.endDate)
        );
        setSelectedMonth(currentMonth);
      },
      select: addValuesToLabels,
    }
  );

  const { data } = useGetWeeklyChart(
    {
      startDate: selectedMonth?.startDate,
      endDate: selectedMonth?.endDate,
      studentId,
    },
    {
      enabled:
        !!studentId && !!selectedMonth?.startDate && !!selectedMonth?.endDate,
    }
  );

  const handleMonthSelect = (value) => {
    const selectedValue = months.find(
      ({ label: monthValue }) => monthValue === value
    );
    setSelectedMonth(selectedValue);
  };

  return (
    <article className="flex w-full h-full flex-col bg-white px-3 py-2 rounded-xl relative card-shadow">
      <header className="flex w-full justify-between pr-10">
        <h2 className="text-darkerGrey text-xl font-semibold">Weekly Chart</h2>
        <Dropdown
          value={selectedMonth?.value}
          type="primary"
          defaultValue={selectedMonth?.label}
          options={months}
          onItemClick={handleMonthSelect}
        />
      </header>
      <div className="w-full h-full pr-10">
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <BarChart data={data} maxBarSize={50}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dateRange" />
            <YAxis
              label={{
                value: "Quiz attempts",
                angle: -90,
                position: "insideBottomLeft",
              }}
            />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Bar dataKey="attempts" fill="#CCDDFF" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {!hasActiveSubscription && <Overlay />}
    </article>
  );
};

export default WeeklyChart;
