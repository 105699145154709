import { getS3Image } from "../shared/s3";
import { LANGUAGE_INPUT_CODES } from "./constants";
import moment from "moment";

export const validateEmail = (email) => {
  return email?.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const numberToWords = (num) => {
  var ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  var tens = [
    "",
    "",
    "Twenty ",
    "Thirty-",
    "Forty-",
    "Fifty-",
    "Sixty-",
    "Seventy-",
    "Eighty-",
    "Ninety-",
  ];

  var numString = num.toString();

  if (num < 0) throw new Error("Negative numbers are not supported.");

  if (num === 0) return "zero";

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + "" + ones[numString[1]];
  }

  //100 and more
  if (numString.length === 3) {
    if (numString[1] === "0" && numString[2] === "0")
      return ones[numString[0]] + " Hundred ";
    else
      return (
        ones[numString[0]] +
        " Hundred " +
        numberToWords(+(numString[1] + numString[2]))
      );
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + " Thousand ";
    if (end < 100)
      return ones[numString[0]] + " Thousand " + numberToWords(end);
    return ones[numString[0]] + " Thousand " + numberToWords(end);
  }
};

export const convertImageUrlToBase64Image = (imageKey, callback) => {
  const imageUrl = getS3Image(imageKey);
  const xhr = new XMLHttpRequest({
    caches: "no-cache",
  });
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
      // store base64 in local storage
      localStorage.setItem(imageKey, reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", imageUrl);
  xhr.responseType = "blob";
  xhr.send();
};

export const convertDateToWords = (date) => {
  if (!date) return "";
  const formattedDate = moment(date, "DD/MM/YYYY");
  const dobDayWords = numberToWords(moment(formattedDate).format("D"));
  const dobMonthWords = moment(formattedDate).format("MMMM");
  const dobYearWords = numberToWords(moment(formattedDate).format("YYYY"));
  return `${dobDayWords} ${dobMonthWords} ${dobYearWords}`;
};

export const getLanguageInputCode = (languageCode) => {
  if (languageCode === "en") {
    return "en";
  }
  return LANGUAGE_INPUT_CODES[languageCode];
};

export const getAllAssessments = (classExamData, divisionId) => {
  const formattiveSummmativeAndOtherTest = new Map();

  classExamData.forEach((exam) => {
    if (exam?.division_id === divisionId) {
      if (formattiveSummmativeAndOtherTest.has(exam?.title)) {
        const currentExams = formattiveSummmativeAndOtherTest.get(exam?.title);
        currentExams.push(exam);
        formattiveSummmativeAndOtherTest.set(exam?.title, currentExams);
      } else {
        formattiveSummmativeAndOtherTest.set(exam?.title, [exam]);
      }
    }
  });

  let exams = [];
  for (let [key, value] of formattiveSummmativeAndOtherTest) {
    exams.push(value);
  }

  return exams;
};

export const convertMarksToGrade = (percentage, grades) => {
  percentage = parseInt(percentage);
  const grade = grades?.grades?.find(
    (legend) => legend.startRange <= percentage && legend.endRange >= percentage
  );
  return grade?.grade;
};

export const getSubjectCode = (
  subjectName,
  className,
  boardCode,
  schoolMedium
) => {
  const subjectNameWithoutWhitespace = subjectName
    ?.replace(/\s/g, "")
    ?.toLowerCase();
  const lowerCaseClassName = className?.toLowerCase();
  const lowerCaseBoardCode = boardCode?.toLowerCase();
  const lowerCaseSchoolMedium = schoolMedium?.toLowerCase();

  return `${subjectNameWithoutWhitespace}_${lowerCaseClassName}_${lowerCaseBoardCode}_${lowerCaseSchoolMedium}`;
};

export const containsNonLatinCodepoints = (s) => {
  // eslint-disable-next-line no-control-regex
  return /[^\u0000-\u00ff]/.test(s);
};

export const getFormatedNumber = (number, digitsAfterDecimal = 2) =>
  number
    ?.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: digitsAfterDecimal,
      maximumFractionDigits: digitsAfterDecimal,
    })
    ?.slice(1);

export const isNumber = (value, defaultValue = "") => {
  return typeof value === "number" && Number.isFinite(value) ? value : defaultValue;
};

export const validNumber = (value) => {
  if (typeof value === "number") return value;
  const parsed = parseInt(value, 10);
  if (Number.isNaN(parsed)) return 0;
  return parsed;
};

export const truncateString = (string, limit) => {
  if (string.length > limit) {
    return string.substring(0, limit) + "..."
  } else {
    return string
  }
};
