/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const getSchoolInventories = async () => {
  try {
    const schoolId = getSchoolID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/inventory/get`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        schoolId,
      },
    };
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (e) {
    return null;
  }
};

export default function useSchoolInventories(options = {}) {
  return useQuery(["schoolInventories"], () => getSchoolInventories(), options);
}
