import React from "react";
// Ant Components
import { Switch } from "antd";
import { MenuOutlined } from "@ant-design/icons";

// Third-Party Components
import { DndContext } from "@dnd-kit/core";
import {
	arrayMove,
	useSortable,
	SortableContext,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Custom Components
import Button from "../../components/Button";
import { PencilIcon } from "@heroicons/react/solid";

const FormInput = (props = {}) => {
	return (
		<input
			type="text"
			className={`border h-10 font-medium border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1 ${
				props.classes || ""
			}`}
			{...props}
		/>
	);
};

function SortableItem(props) {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: props.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return props.children(setNodeRef, attributes, listeners, style);
}

const FormSection = ({
	data,
	isEditable,
	onEditSection,
	onTitleChange,
	onFieldChange,
	onSave,
	onCancel,
	onSort
}) => {
	const renderField = (field, fIdx) => {
		return (
			<SortableItem id={field.id} key={field.id}>
				{(ref, attributes, listeners, style) => (
					<div
						ref={ref}
						className="my-2 flex w-full h-10 flex-row items-center justify-center"
						style={style}
					>
						<div className="w-1/5" {...attributes} {...listeners}>
							<MenuOutlined />
						</div>
						<div className="w-1/5">
							<h3 className="text-left m-0 text-linerGrey">
								{field.name}
							</h3>
						</div>
						<div className="w-1/5">
							<FormInput
								onChange={(e) =>
									onFieldChange(e.target.value, fIdx, "label")
								}
								value={field.label}
								classes="w-full"
							/>
						</div>
						<div className="w-1/5">
							<Switch
								disabled={field?.isForceMendatory}
								defaultChecked={field?.required}
								onChange={(e) =>
									onFieldChange(e, fIdx, "required")
								}
							/>
						</div>
						<div className="w-1/5">
							<Switch
								disabled={field?.isForceVisible}
								defaultChecked={field?.visible}
								onChange={(e) =>
									onFieldChange(e, fIdx, "visible")
								}
							/>
						</div>
					</div>
				)}
			</SortableItem>
		);
	};

	const handleDragEnd = (event) => {
		const {active, over} = event;   
	    if (active.id !== over.id) {
	    	const newItems = [...data?.fields];	      
	        const oldIndex = newItems.findIndex(a => a.id === active.id);
	        const newIndex = newItems.findIndex(a => a.id === over.id);
	        const sortedNew = arrayMove(newItems, oldIndex, newIndex);
	        onSort(sortedNew);
	    }
	}

	return (
		<div className="flex flex-col w-full bg-pureWhite mt-5 pb-15 border border-solid border-slate-600 rounded">
			<div className="flex flex-row relative">
				{isEditable ? (
					<FormInput
						onChange={(e) => onTitleChange(e.target.value)}
						value={data.sectionTitle}
						classes="m-4 w-60"
					/>
				) : (
					<h3 className="my-4 ml-4 font-semibold text-linerGrey">
						{data.sectionTitle}
					</h3>
				)}
				{isEditable ? null : (
					<div
						className="absolute cursor-pointer"
						onClick={onEditSection}
						style={{ right: 20, top: 15 }}
					>
						<PencilIcon className="h-6 w-6 text-primary" />
					</div>
				)}
			</div>
			{isEditable ? (
				<>
					<div className="flex w-full h-10 flex-row items-center justify-center bg-backgroundGreyLight">
						<h3 className="w-1/5 m-0 font-semibold text-linerGrey">
							Sequence
						</h3>
						<h3 className="w-1/5 text-left m-0 font-semibold text-linerGrey">
							Field Name
						</h3>
						<h3 className="w-1/5 text-left m-0 font-semibold text-linerGrey">
							Field Input Label
						</h3>
						<h3 className="w-1/5 m-0 font-semibold text-linerGrey">
							Mandatory
						</h3>
						<h3 className="w-1/5 m-0 font-semibold text-linerGrey">
							Visible in form
						</h3>
					</div>
					<DndContext onDragEnd={handleDragEnd}>
						<SortableContext
							items={data?.fields}
							strategy={verticalListSortingStrategy}
						>
							{(data?.fields || []).map(renderField)}
						</SortableContext>
					</DndContext>
					<div className="flex my-5 flex-row justify-end items-center">
						<Button
							onClick={onCancel}
							className="px-10 h-10"
							buttonStyle="default"
						>
							<span>Cancel</span>
						</Button>

						<Button
							onClick={onSave}
							type="button"
							buttonStyle="primary"
							className="px-10 mx-5 h-10"
						>
							<span>Save</span>
						</Button>
					</div>
				</>
			) : null}
		</div>
	);
};

export default FormSection;