/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const saveTeacherAttendance = async (payload) => {
  const config = {
    validateStatus: () => true,
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/saveTeacherAttendance`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useSaveTeacherAttendance(options = {}) {
  return useMutation({
    mutationFn: (data) => saveTeacherAttendance(data),
    ...options,
  });
}
