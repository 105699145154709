import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { pluralRenderer } from "../../shared/pluralRenderer";
import { useMutation } from "@apollo/client";
import { notification, Popover } from "antd";
import { DELETE_DIVISION } from "../../views/classesView/graphql";
import popOverIcon from "../../assets/images/popover.png";

const ClassCard = ({ id, data, classId, refetchClasses }) => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [deleteDivision] = useMutation(DELETE_DIVISION);

  const handleDeleteDivision = () => {
    deleteDivision({
      variables: {
        id: data.id,
      },
    })
      .then((response) => {
        refetchClasses();
        notification["success"]({ message: "Division deleted", duration: 1.5 });
      })
      .catch((reserror) => {
        notification["error"]({
          message: "Something went wrong",
          description: "Please try again.",
          duration: 1.5,
        });
      });
  };

  const popOverContent = (
    <div>
      <img
        src={popOverIcon}
        width={20}
        height={20}
        alt="error icon"
        className="block ml-auto mr-auto"
      />
      <p style={{ textAlign: "center", color: "#0C0663" }}>
        You cannot remove the division if there are more than 0 students in the
        division.
      </p>
    </div>
  );

  const renderDeleteDivision = () => {
    return (
      <div id="manageclasses-deletedivision">
        {data.is_promoted && (
          <div
            className="absolute top-3 right-10 rounded-md text-xs px-2 text-black"
            style={{
              background: "#FFE896",
            }}
          >
            Promoted
          </div>
        )}
        {data?.division_students_aggregate?.aggregate?.count === 0 ? (
          <button
            className="absolute top-2 right-2"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowModal(true);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="10" fill="#EB5757" />
              <path
                d="M6.66666 13.3333L13.3333 6.66666M6.66666 6.66666L13.3333 13.3333"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        ) : (
          <Popover
            placement="bottom"
            content={popOverContent}
            color="#D4E8FF"
            overlayStyle={{
              width: "12vw",
              boxShadow: "none",
            }}
            overlayInnerStyle={{ borderRadius: "8px", boxShadow: "none" }}
          >
            <div className="absolute top-2 right-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#9CA3AF" />
                <path
                  d="M6.66666 13.3333L13.3333 6.66666M6.66666 6.66666L13.3333 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const classTeacher = data?.class_teachers[0]?.user
    ? `${data?.class_teachers[0]?.user?.first_name} ${
        data?.class_teachers[0]?.user?.last_name || ""
      }`
    : "";
  return (
    <div
      id={id}
      className="col cursor-pointer hover:shadow-xl mt-4"
      onClick={() =>
        navigate(`/class/${data.id}`, {
          state: {
            division_name: data.division_name,
            classId: classId,
          },
        })
      }
    >
      <div
        id="manageclasses-classteacher"
        className="shadow-lg relative rounded-lg py-4 px-6 bg-white dark:bg-gray-800"
      >
        {renderDeleteDivision()}
        <div className="flex flex-col justify-start">
          <p className="text-black-700 dark:text-gray-100 text-2xl text-left font-bold my-4">
            {data.division_name}
          </p>
        </div>
        <div className="flex flex-col items-start overflow-hidden">
          <p className="text-sm text-gray-500 dark:text-white text-left ">
            Class Teacher
          </p>
          <p
            className={`text-base dark:text-white font-semibold text-left truncate w-40 ${
              classTeacher ? "text-indigo-600" : "text-brightRed"
            }`}
          >
            {classTeacher || "Yet to be assigned"}
          </p>
          <p className="text-sm text-gray-500 dark:text-white mt-4 text-left  font-medium">
            {pluralRenderer(
              data?.division_students_aggregate?.aggregate?.count,
              "student",
              "students",
            )}
          </p>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-md">
            <div className="relative w-auto my-6 mx-auto max-w-3xl h-48">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center justify-center w-full h-full bg-white outline-none focus:outline-none">
                <div className="flex items-center pl-5 pr-5 pb-7 justify-center rounded-t">
                  <h3 className="text-xl font-semibold">
                    Are you sure you want to remove the division?
                  </h3>
                </div>
                <div className="flex items-center justify-center rounded-b">
                  <button
                    className="px-5 py-2 bg-cancel text-white transition ease-in duration-200 text-center text-lg leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md mr-2"
                    type="button"
                    onClick={(e) => {
                      setShowModal(false);
                      e.stopPropagation();
                    }}
                  >
                    No
                  </button>
                  <button
                    className="px-5 py-2 bg-success text-white transition ease-in duration-200 text-center text-lg leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ml-2"
                    type="button"
                    onClick={(e) => {
                      setShowModal(false);
                      e.stopPropagation();
                      handleDeleteDivision();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};
export default ClassCard;