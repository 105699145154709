/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const getIsGroupSchool = async (payload) => {
  const config = {
    url: `${process.env.REACT_APP_CSMC_ENDPOINT}/v1/group/school_group`,
    method: "post",
    data: payload,
    validateStatus: () => true,
  };
  const { data } = await axios.request(config);
  return data;
};

export default function useIsGroupSchool() {
  const schoolId = getSchoolID();

  return useQuery(
    ["isGroupSchool", schoolId],
    () => getIsGroupSchool({ schoolId }),
    {
      enabled: !!schoolId,
    }
  );
}
