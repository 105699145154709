import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../../components/Dropdown";
import { getSortedDivisions, getSortedClasses } from "../../helpers";
import cloneDeep from "lodash/cloneDeep";

const getDefaultClass = (classValue, classes) => {
  if (classValue) {
    return {
      value: classValue,
      label: classes.find((classObj) => classObj.value === parseInt(classValue))
        ?.label,
    };
  }
  return null;
};

const getDefaultFilteredDivisions = (divisions = [], selectedClass) => {
  if (selectedClass) {
    const filterDivsWithClassID = getSortedDivisions(divisions).filter(
      (div) => div.class_id === parseInt(selectedClass)
    );
    return filterDivsWithClassID;
  }
  return [];
};

const getDefaultDivision = (divisions, selectedDivision) => {
  if (selectedDivision) {
    return {
      value: selectedDivision,
      label: divisions.find((div) => div.value === parseInt(selectedDivision))
        ?.label,
    };
  }
  return null;
};

function ClassDivisionSelector({
  id,
  classes,
  divisions,
  onDivisionChange,
  onClassChange,
  hideDivisionSelector,
  classDefaultValue,
  divisionDefaultValue,
  selectedClassId,
  selectedDivisionId,
  hideClassSelector,
  allOptionNeeded,
  selectedClass,
  selectedDivision,
  disabled
}) {
  const [filteredDivisions, setFilteredDivisions] = useState(
    getDefaultFilteredDivisions(divisions, selectedClassId)
  );
  const [currentSelectedClass, setCurrentSelectedClass] = useState(
    getDefaultClass(selectedClassId, classes)
  );
  const [currentSelectedDivision, setCurrentSelectedDivision] = useState(
    getDefaultDivision(filteredDivisions, selectedDivisionId)
  );

  const handleClassChange = (value) => {
    const sortedDivisions = getSortedDivisions(divisions);
    const filterDivsWithClassID = sortedDivisions.filter(
      (div) => div.class_id === parseInt(value)
    );
    const className = classes.find(
      (classObj) => classObj.value === parseInt(value)
    )?.label;

    setCurrentSelectedClass({
      value: value || null,
      label: className,
    });
    setCurrentSelectedDivision(null);
    setFilteredDivisions(filterDivsWithClassID);
    // Out of 3 reports, progress report uses this component.
    // ManageStudentList, TeacherList, ClassesView component pass the state setter directly without additional logic, hence setting null is not an issue
    // Progress report passes a handler with additional filtering logic that breaks down when null is passed o the handler
    if (allOptionNeeded) {
      if (onDivisionChange) {
        onDivisionChange(null);
      }
    }
    // Edge case code done
    if (onClassChange) {
      onClassChange(value || null);
    }
  };

  const handleDivisionChange = (value) => {
    const divisionLabel = filteredDivisions.find(
      (div) => div.value === parseInt(value)
    )?.label;

    setCurrentSelectedDivision({
      value,
      label: divisionLabel,
    });
    onDivisionChange(value || null);
  };

  const classDropdownValue = () => {
    if (selectedClass === null) {
      return "Class: All";
    }
    // In any other case selectedClass is falsy, the state is managed internally
    if (!selectedClass) {
      return currentSelectedClass?.label;
    }
    const selectedClassInParent = classes.find(
      (singleClass) => parseInt(singleClass.value) === parseInt(selectedClass)
    );
    return selectedClassInParent?.label;
  };

  const divisionDropdownValue = () => {
    if (selectedDivision === null) {
      return "Division: All";
    }
    if (!selectedDivision) {
      return currentSelectedDivision?.label;
    }
    const selectedDivisionInParent = divisions.find(
      (division) => parseInt(division.value) === parseInt(selectedDivision)
    );
    return selectedDivisionInParent?.label;
  };

  const classId = id + "class";
  const divisionId = id + "division";

  let classesList;
  if (allOptionNeeded) {
    classesList = [
      { value: "", label: "Class: All" },
      ...cloneDeep(getSortedClasses(classes)),
    ];
  } else {
    classesList = getSortedClasses(classes);
  }

  const filteredDivisionsAndAll =
    filteredDivisions.length === 0
      ? []
      : [
          {
            value: "",
            label: "Division: All",
            class_id: filteredDivisions[0].class_id,
          },
          ...cloneDeep(filteredDivisions),
        ];

  return (
    <>
      {!hideClassSelector && (
        <Dropdown
          id={classId}
          value={classDropdownValue()}
          defaultValue={classDefaultValue}
          type="primary"
          options={classesList}
          onItemClick={handleClassChange}
          disabled={disabled}
        />
      )}
      {!hideDivisionSelector && (
        <Dropdown
          id={divisionId}
          value={divisionDropdownValue()}
          defaultValue={divisionDefaultValue}
          type="primary"
          options={
            allOptionNeeded ? filteredDivisionsAndAll : filteredDivisions
          }
          onItemClick={handleDivisionChange}
          disabled={disabled}
        />
      )}
    </>
  );
}

ClassDivisionSelector.prototypes = {
  classes: PropTypes.array,
  divisions: PropTypes.array,
  onDivisionChange: PropTypes.func,
  onClassChange: PropTypes.func,
  hideDivisionSelector: PropTypes.bool,
  hideClassSelector: PropTypes.bool,
  classDefaultValue: PropTypes.string,
  divisionDefaultValue: PropTypes.string,
  allOptionNeeded: PropTypes.bool,
  disabled: PropTypes.bool
};

ClassDivisionSelector.defaultProps = {
  classDefaultValue: "Class : All",
  divisionDefaultValue: "Division : All",
  classes: [],
  divisions: [],
  allOptionNeeded: false,
  disabled: false
};

export default ClassDivisionSelector;
