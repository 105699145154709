import jwtDecode from "jwt-decode";
import axios from "axios";

let currentAuthId = null;

export const getAuthID = () => {
  if (currentAuthId) {
    return currentAuthId;
  }
  let token = localStorage.getItem("token");
  if (token) {
    let decoded = jwtDecode(token);
    let checkClaims = decoded.hasOwnProperty("https://hasura.io/jwt/claims");
    if (checkClaims) {
      let claims = decoded["https://hasura.io/jwt/claims"];
      let authID = claims["x-hasura-user-id"];
      return authID;
    }
  }
  return null;
};

export const setAuthID = (authId) => {
  currentAuthId = authId;
};

export const validateAuthId = (mobileNumber) => {
  if (!mobileNumber) {
    return;
  }
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/getUser`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { mobileNumber },
  };
  axios(config)
    .then((res) => {
      if (res.data?.success) {
        setAuthID(res.data?.data?.authId);
      }
    })
    .catch((err) => console.error(err));
};