/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const transformData = (data) => {
  return {
    expenseId: data?.id,
    title: data?.title,
    category: data?.category,
    categoryTitle: data?.categoryExpenseCategory?.title,
    note: data?.note,
    date: data?.date,
    time: data?.time,
    amount: data?.amount,
    amountType: data?.paymentMode,
    attachments: data?.attachments,
  };
};

const getExpenseById = async (payload) => {
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/expenses/getExpenseById`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useGetExpenseById(payload, options) {
  return useQuery(
    ["expenseId", payload?.expenseId],
    () => getExpenseById(payload),
    {
      select: transformData,
      enabled: !!payload?.expenseId,
      ...options,
    }
  );
}
