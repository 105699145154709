import React, { useState } from "react";
import { GET_TEACHER_BYPK } from "./graphql";
import { useQuery } from "@apollo/client";
import Tabs, { TabPane } from "rc-tabs";
import { Tag, notification, Modal } from "antd";
import _, { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { getAvatarForDetailedView } from "../../shared/getAvatar";
import { PencilIcon } from "@heroicons/react/solid";
import { getUserRole } from "../../shared/getUserRole";
import { USER_ROLES } from "../../utils/constants";
import lock from "../../assets/images/lock.png";
import { Button } from "../../components";
import CopyCard from "../../components/CopyCard";
import useGeneratePin from "../../hooks/useGeneratePin";
import { APP_TYPE } from "../../utils/constants";
import { CloseCircleFilled } from "@ant-design/icons";
import { TextViewForLabelAndValue, TextViewForValues } from "../../components/TextView"

export default function TeacherDetail({ teacherID, onEditClick }) {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [user, setuser] = useState([]);
  const [regeneratePinAuthId, setRegeneratePinAuthId] = useState("");

  let navigate = useNavigate();
  const [activekey, setactivekey] = useState(1);
  const { mutate: updatePin, data: pinData } = useGeneratePin(
    APP_TYPE.TEACHER_APP
  );

  const { loading } = useQuery(GET_TEACHER_BYPK, {
    fetchPolicy: "network-only",
    variables: {
      id: teacherID,
    },
    onCompleted: (res) => {
      setuser(res?.users_by_pk);
    },
  });

  const {
    first_name,
    last_name,
    middle_name,
    mobile_number,
    email,
    address,
    contacts,
    teacher_attendance_data,
    division_subjects,
    image,
    class_teachers,
  } = user;

  const isUserSuperAdmin = getUserRole()?.includes(USER_ROLES.ADMIN);
  const handleCancel = () => {
    setIsConfirmModalVisible(false);
  };
  const generatePin = async () => {
    const authId = user?.auth_id;
    handleCancel();
    if (authId) {
      setRegeneratePinAuthId(authId);
      updatePin({
        authId,
      });
      return;
    }
    notification["error"]({
      message: "Could not get authId",
      description: "Please try again.",
      duration: 1.5,
    });
  };

  if (loading) return "Loading...";
  return (
    <>
      {getAvatarForDetailedView(first_name, last_name, image)}
      <div className="p-6">
        <div className="flex flex-row justify-between mb-4 items-start">
          <div>
            <p className="mb-1 text-gray-700 text-xl font-bold capitalize">
              {first_name} {middle_name} {last_name}
            </p>
            <p className="mb-1 mt-2 text-gray-500 text-sm font-normal ">
              {isEmpty(class_teachers)
                ? "—"
                : `Class ${class_teachers[0]?.division?.division_name}`}
              {teacher_attendance_data?.[0]?.is_present ? (
                <Tag
                  color="#D1FAE5"
                  style={{
                    borderRadius: 20,
                    padding: "2px 10px",
                    marginLeft: 10,
                    fontWeight: 500,
                  }}
                >
                  <span className="text-green-800">Active</span>
                </Tag>
              ) : null}
            </p>
          </div>
          <button
            id="manageteachers-editdetails"
            className="ml-3 p-2 rounded-md bg-indigo-700 hover:bg-indigo-500 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-xs leading-4 font-medium shadow-sm  inline-flex items-center "
            onClick={() => onEditClick()}
          >
            <span className="flex justify-center items-center gap-x-1">
              <PencilIcon className="h-4 w-4" />
              Edit Details
            </span>
          </button>
        </div>
        <Tabs
          defaultActiveKey={activekey}
          onChange={(key) => setactivekey(Number(key))}
          className="custom-tabs whitespace-nowrap"
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane tab="Details" key={1}>
            <div className="flex flex-1 justify-between items-center my-3">
              <TextViewForLabelAndValue
                title="Mobile Number"
                description={mobile_number}
              />
              {isUserSuperAdmin ? (
                <div className="flex flex-1 justify-end">
                  {pinData?.success && regeneratePinAuthId === user?.auth_id ? (
                    <CopyCard text={pinData?.data?.newPin} />
                  ) : null}
                  <Button
                    className="h-10 mr-2 bg-successGreen"
                    onClick={() => setIsConfirmModalVisible(true)}
                  >
                    <img src={lock} alt="Lock" className="h-5 mr-2" />
                    <div className="text-white">Generate PIN</div>
                  </Button>
                </div>
              ) : null}
            </div>
            <TextViewForLabelAndValue
              title="Email"
              description={_.isNil(email) ? "—" : email}
            />
          </TabPane>
          <TabPane tab="Address" key={2}>
            {address && address["pincode"] !== "" ? (
              <div id="manageteachers-teacherdetails-address" className="mt-6">
                <TextViewForValues
                  data={
                    [
                      address?.address_line_one,
                      address?.address_line_two,
                      address?.city?.district?.district_name + ', ' + address?.city?.city_name,
                      address?.city?.district?.state?.state_name,
                      address?.pincode ? `Pincode: ${address?.pincode}` : null
                    ]
                  }
                />
              </div>
            ) : (
              <div className="mt-6 text-sm leading-5 font-medium text-gray-500">
                None available
              </div>
            )}
          </TabPane>
          <TabPane tab="Emergency contact" key={3}>
            {contacts?.length ? (
              <div id="manageteachers-teacherdetails-emergency">
                {contacts.map((ct, stpIndex) => {
                  return (
                    <TextViewForLabelAndValue
                      title={`${ct?.full_name} (${ct?.relation})`}
                      description={ct?.mobile_number}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="mt-6 text-sm leading-5 font-medium text-gray-500">
                None available
              </div>
            )}
          </TabPane>
          <TabPane tab="Classes" key={4}>
            <p className="text-sm leading-5 font-medium text-gray-500  mt-6">
              Class Teacher
            </p>
            <div
              id="manageteachers-teacherdetails-classes"
              className="flex flex-wrap flex-row mt-3"
            >
              {!isEmpty(class_teachers)
                ? class_teachers.map((teacher) => (
                    <div
                      className="bg-red-100 pl-6 pr-6 pt-2 pb-2 mr-2 rounded-xl cursor-pointer mb-2"
                      onClick={() =>
                        navigate(`/class/${teacher?.division?.id}`, {
                          state: {
                            classId: teacher?.division?.class_id,
                            division_name: teacher?.division?.division_name,
                          },
                        })
                      }
                    >
                      <p className="font-semibold">
                        {teacher?.division?.division_name}
                      </p>
                    </div>
                  ))
                : null}
            </div>
            <p className="text-sm leading-5 font-medium text-gray-500  mt-3">
              Subject Teacher
            </p>
            <div className="flex flex-row flex-wrap mt-3">
              {division_subjects?.map((divsub) => {
                return (
                  <div
                    className="bg-red-100 flex-col items-center justify-center pl-6 pr-6 rounded-xl mr-3 pt-1 pb-1  cursor-pointer mb-2"
                    onClick={() =>
                      navigate(`/class/${divsub?.division?.id}`, {
                        state: {
                          classId: divsub?.division?.class_id,
                          division_name: divsub?.division?.division_name,
                        },
                      })
                    }
                  >
                    <div className="flex items-center justify-center">
                      <p className="mb-2 font-semibold">
                        {divsub?.division?.division_name}
                      </p>
                    </div>
                    <div className="flex items-center justify-center">
                      <p className="font-normal">{divsub?.subject?.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </TabPane>
        </Tabs>
        <Modal
          visible={isConfirmModalVisible}
          centered
          footer={null}
          onCancel={handleCancel}
          closeIcon={
            <CloseCircleFilled
              style={{
                color: "#EB5757",
                fontSize: "24px",
              }}
            />
          }
          width={400}
        >
          <div className="mt-5">
            <div className="text-center">
              Are you sure you want to generate pin for <br />
              <span className="font-extrabold">{`${first_name?.trim()} ${middle_name?.trim()} ${last_name?.trim()}`}</span>{" "}
              for <span className="font-extrabold">Teacher App</span>?
            </div>
          </div>
          <div className="flex flex-1 justify-between">
            <Button className="w-32 mt-8 " onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="w-32 mt-8 bg-successGreen" onClick={generatePin}>
              <div className="text-white">Generate PIN</div>
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}
