/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import axios from "axios";

const downloadFile = (data, fileName) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
            "download",
            fileName || "Attendance_Catalog.xlsx"
      );
      document.body.appendChild(link);
      link.click();
};

const downloadAttendanceXLSX = async (payload) => {
      const config = {
            method: "post",
            url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getAttendanceReportXl`,
            responseType: "blob",
            data: payload,
      };
      const res = await axios.request(config);
      downloadFile(res?.data, payload?.fileName);
      return true;
};

export default function useDownloadAttendanceXLSX(options = {}) {
      return useMutation({
            mutationFn: (data) => downloadAttendanceXLSX(data),
            onError: () => {
                  notification["error"]({
                        message: "Failure",
                        description: "Unable to download attendance excel report",
                        duration: 1.5,
                  });
            },
            ...options,
      });
}