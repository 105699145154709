import gql from "graphql-tag";

export const GET_MENU_SCHOOL_DETAILS = gql`
  query GetSchoolDetails($schoolID: Int!, $authid: String!) {
    schools_by_pk(id: $schoolID) {
      id
      title
      image
      student_promotion
      udise_number
      index_number
      medium
      board_name
    }
    school_admins(where: { admin_id: { _eq: $authid } }) {
      user {
        first_name
        mobile_number
        role {
          title
        }
      }
    }
  }
`;

export const GET_DASHBOARD_STATS = gql`
  query getTeachers($schoolID: Int!) {
    teachers: users_aggregate(
      where: {
        _and: {
          school_id: { _eq: $schoolID }
          is_active: { _eq: true }
          role_id: { _eq: 2 }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    students: division_students_aggregate(
      where: {
        division: {
          school_id: { _eq: $schoolID }
          acadamic_year: { is_active: { _eq: true }, is_current: { _eq: true } }
          is_active: { _eq: true }
        }
        is_active: { _eq: true }
        student: { is_active: { _eq: true }, is_draft: { _eq: false } }
      }
    ) {
      aggregate {
        count
      }
    }
    attendance: users(
      where: {
        _and: {
          role_id: { _eq: 2 }
          school_id: { _eq: $schoolID }
          is_active: { _eq: true }
          teacher_attendance_data: { _and: { is_present: { _eq: false } } }
        }
      }
    ) {
      first_name
      last_name
      middle_name
      image
      teacher_attendance_data {
        is_present
      }
    }
    presentTeacher: users(
      where: {
        _and: {
          role_id: { _eq: 2 }
          school_id: { _eq: $schoolID }
          is_active: { _eq: true }
          teacher_attendance_data: { _and: { is_present: { _eq: true } } }
        }
      }
    ) {
      first_name
      last_name
      middle_name
      image
      teacher_attendance_data {
        is_present
      }
    }
    presentStudent: student_attendance_data_aggregate(
      where: {
        date: { _eq: "now()" }
        is_present: { _eq: true }
        division: {
          school_id: { _eq: $schoolID }
          acadamic_year: { is_active: { _eq: true }, is_current: { _eq: true } }
        }
        student: { is_active: { _eq: true }, is_draft: { _eq: false } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_RECENT_ANNOUNCEMENTS = gql`
  query recentAnnouncements($schoolId: Int, $limit: Int, $offset: Int) {
    announcements(
      where: { school_id: { _eq: $schoolId }, is_active: { _eq: true } }
      order_by: { updated_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      like_count
      is_active
      message_body
      seen_count
      title
      created_at
      send_to
      message_type
      announcement_receivers_aggregate {
        aggregate {
          count
        }
      }
      school {
        classes {
          class_name
          id
        }
      }
    }
    announcements_aggregate(
      where: { school_id: { _eq: $schoolId }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DIVISIONS_WITH_FILTER = gql`
  query getDivisionsNotMarked($schoolId: Int!, $search: String) {
    divisions(
      where: {
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
        _or: [
          { division_name: { _ilike: $search } }
          {
            class_teachers: {
              is_active: { _eq: true }
              user: { full_name: { _ilike: $search } }
            }
          }
        ]
        acadamic_year: { is_current: { _eq: true } }
      }
    ) {
      id
      division_name
      student_attendance_data(
        distinct_on: division_id
        where: { date: { _eq: "now()" } }
      ) {
        id
        date
        division_id
      }
      class {
        class_name
        classId: id
      }
      class_teachers(
        where: { is_active: { _eq: true }, user: { is_active: { _eq: true } } }
      ) {
        user {
          auth_id
          full_name
          mobile_number
          first_name
          last_name
          image
        }
      }
    }
  }
`;

export const GET_SUBJECTS_AND_GROUPS = gql`
  query getSchoolDetails($id: Int!) {
    classes_by_pk(id: $id) {
      school {
        medium
        settings
        board {
          code
        }
      }
    }
  }
`;

export const GET_SUBJECTS_OF_CLASS = gql`
  query ClassSubjects($classId: Int!, $divId: Int, $withDivisions: Boolean!) {
    subjects(
      where: { class_id: { _eq: $classId } }
      order_by: [
        { relative_order: asc }
        { is_enable: desc }
        { is_core: desc }
        { created_at: asc }
      ]
    ) {
      id
      name: title
      isEnabled: is_enable
      isOptional: is_optional
      isConsiderForOverallCalculation: is_consider_for_overall_calculation
      markingSystem: marking_system
      group
      relative_order
      is_core
      isSkillBasedEvaluation: is_skill_based_evaluation
      translations
      class {
        id
        class_name
        report_template
        is_display_subjects_by_group
      }
      division_subjects(
        where: { division_id: { _eq: $divId } }
        order_by: { id: desc }
      ) @include(if: $withDivisions) {
        subject {
          title
        }
        user {
          auth_id
          teacher_attendance_data {
            is_present
          }
        }
      }
    }
  }
`;

export const GET_SCHOOL_DETAILS = gql`
  query getSchoolDetails($schoolId: Int!) {
    schools_by_pk(id: $schoolId) {
      id
      image
      trustName: trust_name
      schoolName: title
      index_number
      slogan
      address {
        city {
          city_name
          district {
            state {
              state_name
            }
          }
        }
      }
      udiseNumber: udise_number
      boardName: board_name
      registrationNumber: registration_number
      document_number
      medium
      mobileNumber: mobile_number
      email
      settings
    }
  }
`;

export const GET_CURRENT_ACADEMIC_YEAR = gql`
  query getAcademicDetails($schoolId: Int!) {
    schools_by_pk(id: $schoolId) {
      acadamic_years(
        where: {
          is_current: { _eq: true }
          school_id: { _eq: $schoolId }
          is_active: { _eq: true }
        }
      ) {
        id
        title
        from_date
        to_date
      }
    }
  }
`;

export const GET_CLASSES_LIST = gql`
  query GetClasses($schoolId: Int!) {
    classes(
      where: {
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
        acadamic_year: { is_current: { _eq: true }, is_active: { _eq: true } }
      }
    ) {
      id
      class_name
    }
  }
`;

export const GET_TEACHER_AVAILABILITY = gql`
  query getTeacherList($search: String, $filters: users_bool_exp) {
    users(where: $filters) {
      first_name
      middle_name
      last_name
      image
      auth_id
      teacher_attendance_data {
        id
        is_present
      }
    }
  }
`;

export const GET_BOARDS = gql`
  query GetAllBoardDetails {
    boards(where: { is_active: { _eq: true } }) {
      id
      code
      name
      settings
    }
  }
`;

export const GET_LAST_ROLLNO = gql`
  query GetLastRollNo($_div_id: Int!) {
    division_students(
      where: {
        division_id: { _eq: $_div_id }
        is_active: { _eq: true }
        student: { is_active: { _eq: true }, is_draft: { _eq: false } }
      }
      limit: 1
      order_by: { student: { roll_no: desc_nulls_last } }
    ) {
      student_id
      division_id
      student {
        roll_no
      }
    }
  }
`;

export const CHECK_ROLLNO = gql`
  query CheckRollNo($_div_id: Int!, $_roll_no: Int!) {
    division_students(
      where: {
        division_id: { _eq: $_div_id }
        is_active: { _eq: true }
        student: {
          is_active: { _eq: true }
          is_draft: { _eq: false }
          roll_no: { _eq: $_roll_no }
        }
      }
    ) {
      student {
        roll_no
        full_name
        id
        division_id
      }
    }
  }
`;

export const GET_STUDENT_FILTERS = gql`
  query GetFilters($schoolId: Int!) {
    classes(
      where: {
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
        acadamic_year: { is_current: { _eq: true } }
      }
      order_by: { class_name: asc }
    ) {
      id
      class_name
      divisions(
        where: { is_active: { _eq: true } }
        order_by: { division_name: asc }
      ) {
        id
        division_name
      }
    }
    religions: students(
      where: {
        is_active: { _eq: true }
        is_draft: { _eq: false }
        division: { school_id: { _eq: $schoolId } }
      }
      distinct_on: religion
      order_by: { religion: asc }
    ) {
      religion
    }
    categories: students(
      where: {
        is_active: { _eq: true }
        is_draft: { _eq: false }
        division: { school_id: { _eq: $schoolId } }
      }
      distinct_on: category
      order_by: { category: asc }
    ) {
      category
    }
  }
`;

export const GET_STUDENT_REPORT_STUDENTS = gql`
  query getStudentsList(
    $schoolId: Int
    $studentFilter: students_bool_exp
    $totalAttendanceFilter: student_attendance_data_bool_exp
    $presentAttendanceFilter: student_attendance_data_bool_exp
    $limit: Int
    $offset: Int
  ) {
    students(
      where: $studentFilter
      order_by: { division: { division_name: asc }, roll_no: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      first_name
      middle_name
      last_name
      full_name
      mother_name
      roll_no
      image
      gender
      religion
      category
      division {
        division_name
        id
      }
    }
    filterStudentCount: students_aggregate(where: $studentFilter) {
      aggregate {
        count
      }
    }
    totalStudentCount: students_aggregate(
      where: {
        division: {
          acadamic_year: { is_active: { _eq: true }, is_current: { _eq: true } }
        }
        is_draft: { _eq: false }
        is_active: { _eq: true }
        school_id: { _eq: $schoolId }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_GENDER_CASTE_STATS = gql`
  query getCategoryWiseStats($where: school_category_data_bool_exp!) {
    school_category_data(where: $where) {
      category
      gender
      count
    }
  }
`;
