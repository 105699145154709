import React, { useState, useEffect } from "react";
import { Drawer, Spin } from "antd";
import {
  ADD_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_DETAILS,
  UPDATE_ANNOUNCEMENT_RECEIVERS,
  GET_CLASSES,
  DELETE_ANNOUNCEMENT,
} from "../../views/announcementsView/graphql";
import { GET_RECENT_ANNOUNCEMENTS } from "../../graphql/queries";
import { useQuery, useMutation } from "@apollo/client";
import { getSchoolID } from "../../shared/getSchoolID";
import CloseIcon from "../../components/closeIcon";
import { AnnouncementForm } from "../../components/Announcement";
import { Button } from "../../components";
import useNotificationList from "../../hooks/useNotificationList";
import useNotificationClearCount from "../../hooks/useNotificationClearCount";
import ReceivedAnnouncementContainer from "./ReceivedAnnouncementContainer";
import AnnouncementContainer from "./AnnouncementContainer";
import { PlusIcon } from "@heroicons/react/outline";

const PAGE_LIMIT = 10;

function Announcements() {
  const { mutate: clearCount } = useNotificationClearCount();
  const [isAddNewDrawerOpen, setIsAddNewDrawerOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const { data: notificationData } = useNotificationList({
    limit: PAGE_LIMIT,
    offset: 0,
  });

  const onDrawerClose = () => {
    setIsAddNewDrawerOpen(false);
  };

  const { loading, error, data } = useQuery(GET_RECENT_ANNOUNCEMENTS, {
    variables: {
      schoolId: getSchoolID(),
      limit: PAGE_LIMIT,
      offset: 0,
    },
  });

  const { data: classesData } = useQuery(GET_CLASSES, {
    variables: {
      schoolId: getSchoolID(),
    },
  });

  const [createAnnouncement, { loading: addAnnouncementLoading }] = useMutation(
    ADD_ANNOUNCEMENT,
    {
      refetchQueries: [
        {
          query: GET_RECENT_ANNOUNCEMENTS,
          variables: {
            schoolId: getSchoolID(),
            limit: PAGE_LIMIT,
            offset: 0,
          },
        },
      ],
    }
  );

  const [updateAnnouncement, { loading: updateAnnouncementLoading }] =
    useMutation(UPDATE_ANNOUNCEMENT_DETAILS, {
      refetchQueries: [
        {
          query: GET_RECENT_ANNOUNCEMENTS,
          variables: {
            schoolId: getSchoolID(),
            limit: PAGE_LIMIT,
            offset: 0,
          },
        },
      ],
    });

  const [updateAnnouncementReceivers] = useMutation(
    UPDATE_ANNOUNCEMENT_RECEIVERS
  );

  const [deleteAnnouncement, { loading: deleteAnnouncementLoading }] =
    useMutation(DELETE_ANNOUNCEMENT, {
      refetchQueries: [
        {
          query: GET_RECENT_ANNOUNCEMENTS,
          variables: {
            schoolId: getSchoolID(),
            limit: PAGE_LIMIT,
            offset: 0,
          },
        },
      ],
    });

  useEffect(() => {
    clearCount();
  }, []);

  const handleEditAnnouncement = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsAddNewDrawerOpen(true);
  };

  const handleAddNewClick = () => {
    setSelectedAnnouncement(null);
    setIsAddNewDrawerOpen(true);
  };

  const onComplete = (data) => {
    setIsAddNewDrawerOpen(false);
    setSelectedAnnouncement(null);
  };

  const handleDeleteAnnouncement = (announcement) => {
    deleteAnnouncement({
      variables: {
        announcementId: announcement.id,
      },
    });
    setIsAddNewDrawerOpen(false);
    setSelectedAnnouncement(null);
  };

  const announcementLoader =
    addAnnouncementLoading ||
    updateAnnouncementLoading ||
    deleteAnnouncementLoading;

  if (announcementLoader) {
    return <Spin />;
  }

  return (
    <>
      <main className="flex flex-col flex-1 px-8 pt-3 pb-8 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-hidden">
        <div className="flex flex-row gap-x-4 items-center mb-2">
          <h1 className="text-2xl text-left font-bold flex-col m-0 mr-6">
            Announcements
          </h1>
          <Button
            id="announcements-addnew"
            buttonStyle="primary"
            onClick={handleAddNewClick}
          >
            <PlusIcon className="w-4 h-4 text-white" />
            <span className="ml-2">Add new announcement</span>
          </Button>
        </div>

        <div className="flex flex-1 overflow-hidden gap-x-10">
          <AnnouncementContainer
            loading={loading}
            error={error}
            data={data}
            onEditClick={handleEditAnnouncement}
          />
          <ReceivedAnnouncementContainer
            loading={loading}
            error={error}
            data={notificationData}
            onEditClick={handleEditAnnouncement}
          />
        </div>
      </main>
      <Drawer
        title={
          selectedAnnouncement ? (
            <div className="flex justify-between items-center ">
              <h2 className="font-semibold text-2xl text-gray-900 mt-3">
                Edit Announcement
              </h2>
              <Button
                buttonStyle="danger"
                className="py-2 px-4 mr-5 flex justify-center items-center rounded-lg"
                onClick={() => handleDeleteAnnouncement(selectedAnnouncement)}
              >
                Delete
              </Button>
            </div>
          ) : (
            <div className="mt-3">
              <h2 className="font-semibold text-2xl text-gray-900 ">
                New Announcement
              </h2>
            </div>
          )
        }
        placement="right"
        onClose={onDrawerClose}
        open={isAddNewDrawerOpen}
        destroyOnClose
        headerStyle={{
          backgroundColor: "#EAF0F5",
          marginBottom: 0,
          borderBottomWidth: 1,
          borderBottomColor: "#cbd5e1",
        }}
        bodyStyle={{
          backgroundColor: "#EAF0F5",
        }}
        contentWrapperStyle={{
          width: "36vw",
        }}
        closeIcon={<CloseIcon />}
      >
        <AnnouncementForm
          announcement={selectedAnnouncement}
          createAnnouncement={createAnnouncement}
          updateAnnouncement={updateAnnouncement}
          updateAnnouncementReceivers={updateAnnouncementReceivers}
          classes={classesData?.schools_by_pk?.classes || []}
          isLoading={
            addAnnouncementLoading ||
            updateAnnouncementLoading ||
            deleteAnnouncementLoading
          }
          onComplete={onComplete}
        />
      </Drawer>
    </>
  );
}

export default Announcements;
