import React, { useState, useEffect, createContext } from "react";
import { Modal, Checkbox, Spin, Table, notification } from "antd";
import Button from "../../../components/Button";
import { PlusIcon } from "@heroicons/react/outline";
import Dropdown from "../../../components/Dropdown";
import EditableTable from "../EditTable";
import {
  CREATED_BY,
  DEFAULT_EXAMS,
  EXAM_TERM_OPTIONS,
  SAMPLE_EXAM_COLS,
} from "../../../utils/constants";
import cloneDeep from "lodash/cloneDeep";
import { CloseCircleFilled, MenuOutlined } from "@ant-design/icons";
import { PencilIcon } from "@heroicons/react/solid";
import { useMutation } from "@apollo/client";
import {
  UPDATE_CCE_EXAM,
  UPDATE_ASSESSMENTS_ORDER,
} from "../../../graphql/mutations";
import ReactDragListView from "react-drag-listview";
import useGetEvalParams from "../../../hooks/useGetEvalParams";
import useAddEvalParam from "../../../hooks/useAddEvalParam";
import { getSchoolID } from "../../../shared/getSchoolID";
import useCreateCustomExam from "../../../hooks/useCreateCustomExam";

export const EditModalContext = createContext(null);

const HeaderComponent = ({ style, children, dataIndex, ...restProps }) => {
  let childNode = children;
  return (
    <td
      {...restProps}
      key={dataIndex}
      style={{
        ...style,
        minWidth: "6rem",
        backgroundColor: "#F1F1FF",
        borderRight: "1px solid #d1d5db",
        borderBottom: "1px solid #d1d5db",
      }}
      className={`text-center py-3 w-auto draggable-column-header`}
    >
      {childNode}
    </td>
  );
};

const CellComponent = ({ style, children, dataIndex, ...restProps }) => {
  let childNode = children;
  return (
    <td
      {...restProps}
      key={dataIndex}
      style={{
        ...style,
        minWidth: "6rem",
        borderRight: "1px solid #d1d5db",
        borderBottom: "1px solid #d1d5db",
      }}
      className={`text-center py-3 w-auto`}
    >
      {childNode}
    </td>
  );
};

const LANGUAGE = "marathi";

const getAssessmentsThatBelongToSubject = (allAssessments, subjectId) => {
  const subjectParams = [];
  allAssessments.forEach((assessment) => {
    const assessmentId = assessment.id;

    const params = assessment?.assessment_subjects?.find(
      (subject) => subject.subject_id === subjectId
    );

    subjectParams.push({
      eval_params: params?.assessment_subject_eval_params,
      assessmentId,
      assessmentSubjectId: params?.id,
    });
  });
  return subjectParams;
};

const getAssessmentWithGivenId = (assessments, id) => {
  let res = [];
  assessments?.forEach((assessment) => {
    if (assessment.id === id) {
      res.push(assessment);
    }
  });
  return res;
};

// Add operations happen instantly and we refetch the data from server
function CCETab({
  selectedClass,
  classSubjectsData,
  classExamData,
  onEditExam,
  isCCE,
  refetchClassExamData,
  divisions,
  disabledButtons,
  termData,
  storeDataBeforeRefetch,
}) {
  const [isAddExamModalVisible, setIsAddExamModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [displayTableStructure, setDisplayTableStructure] = useState([]);
  const [editTableStructure, setEditTableStructure] = useState([]);
  const [addExamFields, setAddExamFields] = useState({
    title: "",
    term: "",
  });
  const [addSubExamOptions, setAddSubExamOptions] = useState([]);
  const [selectedSubExamOptions, setSelectedSubExamOptions] = useState([]);
  const [assessmentsToDelete, setAssessmentsToDelete] = useState({});
  const [assesmentToDeleteModal, setAssessmentToDeleteModal] = useState({
    assessmentId: "",
    isDeleteModalVisible: false,
  });
  const [addSubExamModal, setAddSubExamModal] = useState({
    isVisible: false,
    assessmentId: "",
    checked: [],
    newSubjectDetails: {
      isVisible: false,
      newSubjectName: "",
    },
  });
  const [subAssesssmentsToDelete, setSubAssesssmentsToDelete] = useState({});
  const [loader, setLoader] = useState(false);
  const { term, setTerm } = termData;

  const hasExams = !!classExamData && classExamData?.length > 0;

  const [updateCCE] = useMutation(UPDATE_CCE_EXAM, {
    onCompleted: () => {
      refetchClassExamData();
    },
  });

  const { mutateAsync: addEvalParam } = useAddEvalParam();

  const [updateAssessmentOrder] = useMutation(UPDATE_ASSESSMENTS_ORDER, {
    onCompleted: () => {
      refetchClassExamData();
    },
  });

  const { mutateAsync: createExam } = useCreateCustomExam();

  const resetSubExamModal = () => {
    setAddSubExamModal({
      isVisible: false,
      assessmentId: "",
      checked: [],
      newSubjectDetails: {
        isVisible: false,
        newSubjectName: "",
      },
    });
  };

  const generatePayloadForAddAndDelete = (
    assessmentId,
    addedSubExams,
    deletedSubExams
  ) => {
    const assessmentsForGivenTerm = classExamData?.filter(
      (assessment) => assessment?.term === term?.value
    );

    const assessmentNameToIdArrayMap = new Map();
    const assessmentIdToNameMap = new Map();

    assessmentsForGivenTerm?.forEach((assessment) => {
      const name = assessment?.title;
      const id = parseInt(assessment?.id);
      assessmentIdToNameMap.set(id, assessment);

      if (assessmentNameToIdArrayMap.has(name)) {
        const currentArray = assessmentNameToIdArrayMap.get(name);
        currentArray.push(id);
      } else {
        assessmentNameToIdArrayMap.set(name, [id]);
      }
    });

    const assessment = assessmentIdToNameMap.get(parseInt(assessmentId));
    const assessmentsToUpdate = assessmentNameToIdArrayMap.get(
      assessment?.title
    );

    const subEvalIds = Array.from(addedSubExams);

    const objectsPayload = [];
    assessmentsToUpdate?.forEach((assessmentId) => {
      const assessment = assessmentIdToNameMap.get(parseInt(assessmentId));

      assessment?.assessment_subjects?.forEach((subject) => {
        // Delete Payload
        subject?.assessment_subject_eval_params?.forEach((eval_param) => {
          const eval_param_id = eval_param?.eval_param_id;
          if (deletedSubExams.has(eval_param_id)) {
            const object = {
              assessment_subject_id: eval_param?.assessment_subject_id,
              eval_param_id,
              is_active: false,
            };
            objectsPayload.push(object);
          }
        });

        // Add eval_param_payload
        subEvalIds?.forEach((id) => {
          objectsPayload.push({
            assessment_subject_id: subject?.id,
            eval_param_id: id,
            is_active: true,
          });
        });
      });
    });

    return {
      objects: objectsPayload,
      updates: [],
      updateExamTitle: [],
      updateParamName: [],
    };
  };

  useEffect(() => {
    updateDefaultDisplayStructure(isEditModalVisible);
  }, [
    classExamData,
    classSubjectsData,
    term,
    isAddExamModalVisible,
    assessmentsToDelete,
    isEditModalVisible,
  ]);

  const { isLoading: evalParamsLoading } = useGetEvalParams(
    [
      isAddExamModalVisible,
      addSubExamModal.isVisible,
      addSubExamModal?.assessmentId,
    ],
    {
      onSuccess: (checkboxData) => {
        setAddSubExamOptions(checkboxData);
        setSelectedSubExamOptions(addSubExamModal?.checked);
      },
      enabled: isAddExamModalVisible || addSubExamModal.isVisible,
    }
  );

  const showSubExamAddModal = (assessmentId) => {
    const assessment = classExamData?.find(
      (assessment) => assessment?.id === assessmentId
    );
    const checkBoxSelected =
      assessment?.assessment_subjects?.[0]?.assessment_subject_eval_params?.map(
        (eval_param) => eval_param?.eval_param_id
      );

    setAddSubExamModal({
      isVisible: true,
      assessmentId: assessmentId,
      checked: checkBoxSelected,
      newSubjectDetails: {
        isVisible: false,
        newSubjectName: "",
      },
    });
  };

  const getDisplayName = (subject) => {
    let displayName = subject.title;
    const medium = (subject?.class?.school?.medium)?.toLowerCase();          
    const name = subject?.translations?.['subject']?.[medium];
    if (name) {
      displayName = name;
    }
    return displayName;
  }

  const updateDefaultDisplayStructure = (isEditModalVisible) => {
    if (classExamData?.length > 0) {
      const uniqueDivisions = divisions;
      const uniqueDeletedAssessmentIds = new Set(
        Object.keys(assessmentsToDelete)
      );
      const classExamDataWithoutDeletedAssessments = classExamData?.filter(
        (assessment) => !uniqueDeletedAssessmentIds.has(assessment.id + "")
      );
      const currentDivision = uniqueDivisions[0];

      // We use first division for all calculation and reflect the changes in all other divisions accordingly
      const allAssessments = classExamDataWithoutDeletedAssessments?.filter(
        (assesment) => assesment.division_id === currentDivision
      );

      const assessmentHeaders = allAssessments
        .map((assessment) => {
          const title = assessment?.title;
          const translation = assessment?.translations?.[LANGUAGE];

          return {
            term: assessment?.term,
            id: assessment?.id,
            title: () => {
              return (
                <div className="flex h-full flex-1 justify-between">
                  {isEditModalVisible ? null : (
                    <p
                      style={{ transform: `rotateZ(90deg)` }}
                      className="inline-block px-2 mx-4"
                    >
                      <MenuOutlined />
                    </p>
                  )}
                  <div className="flex flex-1 justify-center">
                    <p className={`font-semibold`}>
                      {title} {translation ? `(${translation})` : ""}
                    </p>
                  </div>
                </div>
              );
            },
            examTitle: title,
            editable: true,
            dataIndex: title,
            children: [],
          };
        })
        .filter((assessment) => assessment?.term === term.value);

      const examColumns = [
        {
          title: () => <p className="font-semibold text-gray-500">Subject</p>,
          dataIndex: "subject",
          width: "10rem",
          editable: false,
          render: (text) => <p className="font-semibold">{text}</p>,
        },
        {
          title: () => (
            <p className="font-semibold text-base">{term.label} Exam</p>
          ),
          editable: true,
          examTitle: `${term.label} Exam`,
          isSubExam: true,
          isGroup: true,
          children: assessmentHeaders,
        },
      ];

      assessmentHeaders.push({
        term: "Total",
        id: -1,
        title: () => {
          return (
            <div className="flex h-full flex-1 justify-between">
              <div className="flex flex-1 justify-center">
                <p className={`font-semibold`}></p>
              </div>
            </div>
          );
        },
        examTitle: "Total",
        editable: false,
        dataIndex: "",
        children: [],
      });

      const examTableData = classSubjectsData?.classes?.[0]?.subjects?.map(
        (subject, index) => {
          // Translations are used as display name, medium is school medium like english, marathi etc.
          const displayName = getDisplayName(subject);
          return {
            key: subject.id,
            subject: displayName,
            params: [],
          };
        }
      );

      assessmentHeaders?.forEach((currentAssessment, idx) => {
        const assessmentId = currentAssessment.id;
        const currentAssesmentColumn = examColumns[1].children[idx];
        const currentAssessmentHeaderData = getAssessmentWithGivenId(
          allAssessments,
          assessmentId
        )?.find((assessment) => assessment?.term === term?.value);
        // Data Index needed for identifying edit cell: The index will be assessment_id, eval_param_id, assessment_subject_id
        currentAssessmentHeaderData?.assessment_subjects?.[0]?.assessment_subject_eval_params.forEach(
          (evaluation_parameter) => {
            if (
              subAssesssmentsToDelete[`${assessmentId}`]?.[
                `${evaluation_parameter?.eval_param_id}`
              ] === undefined
            ) {
              // Check if assessmentId not in subAssessmentsToDelete, then only we push
              currentAssesmentColumn.children.push({
                id: evaluation_parameter.id,
                title: () => (
                  <div>
                    <p className="font-semibold">
                      {
                        evaluation_parameter?.eval_param?.translations?.[
                          LANGUAGE
                        ]
                      }
                    </p>
                    <p className="font-semibold">
                      {evaluation_parameter?.eval_param?.title === "Default" ? (
                        <>(No name)</>
                      ) : (
                        evaluation_parameter?.eval_param?.title
                      )}
                    </p>
                  </div>
                ),
                dataIndex: `${assessmentId},${evaluation_parameter?.eval_param_id},${evaluation_parameter?.assessment_subject_id}`,
                examTitle: evaluation_parameter?.eval_param?.title,
                assessmentId,
                evalParamId: evaluation_parameter?.eval_param_id,
                editable: true,
                isSubExam: true,
                render: (text, row) => {
                  let elemToShow;
                  const requiredRow = row?.params?.find(
                    (elem) => elem?.assessmentId === assessmentId
                  );
                  requiredRow?.eval_params?.forEach((eval_param) => {
                    if (
                      eval_param?.eval_param_id ===
                      evaluation_parameter.eval_param_id
                    ) {
                      elemToShow = eval_param;
                    }
                  });

                  return (
                    <p className="font-semibold text-center">
                      {elemToShow?.max_marks || "-"}
                    </p>
                  );
                },
              });
            }
          }
        );
        if (assessmentId !== -1) {
          currentAssesmentColumn.children.push({
            title: () => (
              <p className="font-semibold text-gray-500 text-center items-center">
                Total
              </p>
            ),
            dataIndex: "firstTotal",
            editable: false,
            render: (text, record) => {
              const requiredRow = record?.params?.find(
                (elem) => elem?.assessmentId === assessmentId
              );
              const total = requiredRow?.eval_params?.reduce(
                (acc, obj) =>
                  obj?.max_marks ? acc + parseInt(obj?.max_marks) : acc,
                0
              );
              const finalText = typeof total === "number" ? total : 0;
              return <p className="font-semibold text-center">{finalText}</p>;
            },
          });
        } else {
          currentAssesmentColumn.children.push({
            title: () => (
              <p className="font-semibold text-gray-500 text-center items-center">
                Overall Total
              </p>
            ),
            dataIndex: "firstTotal",
            editable: false,
            render: (text, record) => {
              let total = 0;
              record?.params?.forEach((requiredRow) => {
                const currentRowTotal = requiredRow?.eval_params?.reduce(
                  (acc, obj) =>
                    obj?.max_marks && typeof obj?.max_marks === "number"
                      ? acc + parseInt(obj?.max_marks)
                      : acc,
                  0
                );
                const finalAdd =
                  typeof currentRowTotal === "number" ? currentRowTotal : 0;
                total += finalAdd;
              });

              return <p className="font-semibold text-center">{total}</p>;
            },
          });
        }
      });

      const assessmnetsForCurrentTerm =
        classExamDataWithoutDeletedAssessments.filter((assessment) => {
          return (
            assessment?.term === term.value &&
            assessment?.division_id === currentDivision
          );
        });

      examTableData?.forEach((examData) => {
        const subjectId = examData?.key;
        examData.params = getAssessmentsThatBelongToSubject(
          assessmnetsForCurrentTerm,
          subjectId
        );
      });

      setDisplayTableStructure({
        columns: examColumns,
        data: examTableData,
      });
      setEditTableStructure({
        columns: examColumns,
        data: examTableData,
      });
    } else {
      // Show Default structure, if there is no exam data available
      const examTableData = classSubjectsData?.classes?.[0]?.subjects?.map(
        (subject, index) => {
          const displayName = getDisplayName(subject);
          return {
            key: subject.id,
            subject: displayName,
          };
        }
      );
      setDisplayTableStructure({
        columns:
          !selectedClass || isCCE
            ? SAMPLE_EXAM_COLS
            : [
                {
                  title: () => (
                    <p className="font-semibold text-gray-500">Subject</p>
                  ),
                  dataIndex: "subject",
                  width: "10rem",
                  editable: false,
                  render: (text) => <p className="font-semibold">{text}</p>,
                },
                {
                  title: () => (
                    <p className="font-semibold text-base">{term?.label}</p>
                  ),
                  editable: true,
                  isGroup: true,
                  children: [],
                },
              ],
        data: examTableData,
      });
      setEditTableStructure({
        columns: SAMPLE_EXAM_COLS,
        data: examTableData,
      });
    }
  };

  const components = {
    header: {
      cell: HeaderComponent,
    },
    body: {
      cell: CellComponent,
    },
  };

  const handleExamDelete = (assessmentId) => {
    setAssessmentToDeleteModal({
      assessmentId,
      isDeleteModalVisible: true,
    });
  };

  const handleTermChange = (modalTerm) => {
    const termLabel = parseInt(modalTerm) === 1 ? "First Term" : "Second Term";
    setAddExamFields({
      ...addExamFields,
      term: parseInt(modalTerm),
      label: termLabel,
    });
    if (parseInt(modalTerm) === 1) {
      handleFirstTermClick();
    } else {
      handleSecondTermClick();
    }
  };

  const handleExamTitleChange = (e) => {
    setAddExamFields({
      ...addExamFields,
      title: e.target.value,
    });
  };

  const getNewEvalParamId = async () => {
    try {
      const isNewSubject = addSubExamModal.newSubjectDetails.isVisible;
      const title = addSubExamModal.newSubjectDetails.newSubjectName.trim();

      if (isNewSubject && !title) {
        notification["error"]({
          message: "Title",
          description: "Title cannot be empty",
          duration: 1.5,
        });
        return;
      }

      if (isNewSubject && title) {
        const { data } = await addEvalParam({
          title,
          schoolId: getSchoolID(),
        });
        const newEvalParamId = data?.[0]?.id;
        if (newEvalParamId) {
          return newEvalParamId;
        }
      }
    } catch (err) {
      return null;
    }
  };

  const handleSubExamCreateAndDelete = async () => {
    setLoader(true);
    const initialSubExams = addSubExamModal?.checked;
    const finalSubExamsSubmitted = selectedSubExamOptions;

    const adddedSubExamIds = new Set(finalSubExamsSubmitted);
    initialSubExams?.forEach((subExam) => {
      adddedSubExamIds.delete(subExam);
    });

    const deletedSubExamIds = new Set(initialSubExams);
    finalSubExamsSubmitted?.forEach((subExam) => {
      deletedSubExamIds.delete(subExam);
    });

    const evalParamId = await getNewEvalParamId();
    if (evalParamId) {
      adddedSubExamIds.add(evalParamId);
    }
    const defaultSubExam = addSubExamOptions?.find(
      (subExam) => subExam?.title === "Default"
    );
    const defaultId = defaultSubExam?.id;
    // In case user deletes all checkboxes, add default as eval-param
    if (
      adddedSubExamIds.size === 0 &&
      deletedSubExamIds.size === initialSubExams.length
    ) {
      const subExamToAdd = new Set([defaultId]);
      const payload = generatePayloadForAddAndDelete(
        addSubExamModal?.assessmentId,
        subExamToAdd,
        deletedSubExamIds
      );
      resetSubExamModal();
      setSelectedSubExamOptions([]);
      storeDataBeforeRefetch((prevState) => {
        const data = cloneDeep(prevState);
        data.shouldMergeRefetchedData = true;
        return data;
      });
      await updateCCE({
        variables: payload,
      });
      setLoader(false);
      return;
    }

    // If Default present and user added another field
    const isDefaultPresent = initialSubExams?.find(
      (eval_id) => eval_id === defaultId
    );
    if (adddedSubExamIds.size !== 0 && isDefaultPresent) {
      deletedSubExamIds.add(defaultId);
    }

    const payload = generatePayloadForAddAndDelete(
      addSubExamModal?.assessmentId,
      adddedSubExamIds,
      deletedSubExamIds
    );
    resetSubExamModal();
    setSelectedSubExamOptions([]);
    storeDataBeforeRefetch((prevState) => {
      const data = cloneDeep(prevState);
      data.shouldMergeRefetchedData = true;
      return data;
    });
    await updateCCE({
      variables: payload,
    });
    setLoader(false);
  };

  const handleCreateExam = async () => {
    if (!addExamFields.term || !addExamFields.title) {
      notification["error"]({
        message: "Term and Title are required",
        description: "Term and Title cannot be empty",
        duration: 1.5,
      });
      return;
    }

    // If user wants to add in dfferent term, we show the data for that term
    const divisionIds = divisions;

    const term = addExamFields.term;
    const examTitle = addExamFields?.title;
    const createdByRole = CREATED_BY.ADMIN;
    const sameParamsForAllSubjects = true;
    let paramsList = selectedSubExamOptions;

    const newEvalParamId = await getNewEvalParamId();
    if (newEvalParamId) {
      paramsList?.push(newEvalParamId);
    }

    const defaultSubExam = addSubExamOptions?.find(
      (subExam) => subExam?.title === "Default"
    );
    const defaultId = defaultSubExam?.id;
    if (selectedSubExamOptions?.length === 0) {
      paramsList = [defaultId];
    }

    const createExamPayload = {
      divisionIds,
      term,
      examTitle,
      createdByRole,
      sameParamsForAllSubjects,
      paramsList,
    };

    setLoader(true);
    try {
      await createExam(createExamPayload);
      setIsAddExamModalVisible(false);
      refetchClassExamData();
      setAddExamFields({
        term: "",
        title: "",
      });
      setSelectedSubExamOptions([]);
      setIsEditModalVisible(true);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoader(false);
    }
  };

  // On Cancel, just refetch as user can have multiple steps
  const handleEditCancel = () => {
    setAssessmentsToDelete({});
    setIsEditModalVisible(false);
    refetchClassExamData();
    return;
  };

  const handleEditExam = (editedData, tableState, updatedNames) => {
    onEditExam({
      formData: editedData,
      tableState,
      term,
      assessmentsToDelete,
      updatedNames,
    });
    setIsEditModalVisible(false);
  };

  const handleFirstTermClick = () => {
    setTerm({
      label: "First Term",
      value: 1,
    });
  };

  const handleSecondTermClick = () => {
    setTerm({
      label: "Second Term",
      value: 2,
    });
  };

  const getFormattedCheckboxData = () => {
    const formattedCheckboxData = addSubExamOptions?.map((eval_param) => ({
      label: eval_param?.title,
      value: eval_param?.id,
    }));
    const formattedCheckboxWithoutDefault = formattedCheckboxData?.filter(
      (checkboxData) => {
        return checkboxData?.label !== "Default";
      }
    );
    return formattedCheckboxWithoutDefault;
  };

  const handleConfirmDelete = () => {
    const assessmentId = assesmentToDeleteModal.assessmentId;
    setAssessmentsToDelete((prevState) => ({
      ...prevState,
      [assessmentId]: assessmentId,
    }));
    setAssessmentToDeleteModal({
      assessmentId: "",
      isDeleteModalVisible: false,
    });
  };

  const handleCancelDelete = () => {
    setAssessmentToDeleteModal({
      assessmentId: "",
      isDeleteModalVisible: false,
    });
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newDisplayTableStructure = cloneDeep(displayTableStructure);
      const allColumns = newDisplayTableStructure?.columns;
      const columnsThatAreReordered = allColumns?.find(
        (column) => column?.dataIndex !== "subject"
      )?.children;
      const item = columnsThatAreReordered.splice(fromIndex, 1)[0];
      columnsThatAreReordered.splice(toIndex, 0, item);

      const columnsWithoutOverallTotal = columnsThatAreReordered?.filter(
        (assessment) => assessment?.id && assessment?.id > 0
      );
      const classId = parseInt(selectedClass?.value);
      if (classId) {
        const newAssessmentorder = columnsWithoutOverallTotal?.map(
          (assessment, idx) => ({
            _set: { relative_order: idx },
            where: {
              title: { _eq: assessment?.examTitle },
              term: { _eq: assessment?.term },
              class_id: { _eq: classId },
            },
          })
        );
        updateAssessmentOrder({
          variables: {
            newAssessmentorder,
          },
        });
      }
      setDisplayTableStructure(newDisplayTableStructure);
    },
    nodeSelector: "td",
    handleSelector: "table tr:nth-child(2) td.draggable-column-header",
  };

  const toggleAddSubExam = () => {
    setAddSubExamModal((prevState) => ({
      ...prevState,
      newSubjectDetails: {
        isVisible: !prevState.newSubjectDetails.isVisible,
        newSubjectName: prevState.newSubjectDetails.newSubjectName,
      },
    }));
  };

  const handleAddSubExamText = (e) => {
    setAddSubExamModal((prevState) => ({
      ...prevState,
      newSubjectDetails: {
        ...prevState.newSubjectDetails,
        newSubjectName: e.target.value,
      },
    }));
  };

  const isEditDisabled =
    selectedClass && disabledButtons && hasExams ? false : true;
  const isAddDisabled = selectedClass && disabledButtons ? false : true;

  return (
    <div className="w-full h-full mt-2 cursor-default">
      <div
        className="flex flex-col h-full gap-y-3 bg-white mt-3 pt-2 px-3 pb-2 rounded-lg shadow-md"
        style={{
          height: "auto",
        }}
      >
        <div className="flex flex-col gap-y-2 justify-center">
          <div className="flex items-center justify-between gap-x-4">
            <div className="flex">
              <div className="mr-6">
                <Button
                  id="first-term"
                  className={term.value === 1 && "bg-thaliumFlame text-white"}
                  onClick={handleFirstTermClick}
                >
                  First Term Exam
                </Button>
              </div>
              <div className="mr-6">
                <Button
                  id="first-term"
                  className={term.value === 2 && "bg-thaliumFlame text-white"}
                  onClick={handleSecondTermClick}
                >
                  Second Term Exam
                </Button>
              </div>
            </div>
            <div className="flex">
              {!isCCE && !hasExams && (
                <div className="flex h-full items-center my-auto font-semibold text-base text-cancel mr-4">
                  Exams are not created yet.
                </div>
              )}
              {!isCCE && (
                <Button
                  id="manageexams-addexam"
                  buttonStyle="primary"
                  className="w-32 mr-2"
                  onClick={() => setIsAddExamModalVisible(true)}
                  disabled={isAddDisabled}
                >
                  <span className="flex justify-center items-center gap-x-1">
                    <PlusIcon className="h-4 w-5" />
                    Add Exam
                  </span>
                </Button>
              )}
              <Button
                id="manageexams-edittable"
                buttonStyle="primary"
                className="w-32"
                onClick={() => setIsEditModalVisible(true)}
                disabled={isEditDisabled}
              >
                <span className="flex justify-center items-center gap-x-1">
                  <PencilIcon className="h-4 w-5" />
                  Edit Table
                </span>
              </Button>
            </div>
          </div>
        </div>
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            bordered
            scroll={{ x: 400 }}
            pagination={false}
            columns={displayTableStructure?.columns}
            dataSource={
              displayTableStructure?.data
                ? displayTableStructure?.data
                : DEFAULT_EXAMS
            }
            components={components}
          />
        </ReactDragListView.DragColumn>
        {/* Disable Schedule Exam until further releases */}
        {/* <div className="flex justify-end">
          <Button
            id="manageexams-examschedule"
            buttonStyle="success"
            className="w-60 h-10"
            onClick={onScheduleExam}
          >
            Schedule Exam
          </Button>
        </div> */}
      </div>
      <Modal
        open={addSubExamModal?.isVisible && !evalParamsLoading}
        centered
        destroyOnClose
        onCancel={resetSubExamModal}
        closeIcon={
          <CloseCircleFilled
            style={{
              color: "#EB5757",
              fontSize: "24px",
            }}
          />
        }
        bodyStyle={{
          justifyContent: "center",
        }}
        width={250}
        footer={null}
      >
        <div className="flex flex-col justify-center">
          <div className="mb-5">
            <p className="text-black text-lg font-bold">Add Sub Exam</p>
          </div>
          <div className="flex-col">
            {loader || evalParamsLoading ? (
              <Spin />
            ) : (
              <Checkbox.Group
                style={{ display: "flex", flexDirection: "column" }}
                options={getFormattedCheckboxData(addSubExamOptions)}
                defaultValue={addSubExamModal?.checked}
                onChange={(checkedValues) => {
                  setSelectedSubExamOptions(checkedValues);
                }}
              />
            )}
          </div>
          <div className="flex flex-1 flex-col justify-start mt-4 items-start">
            <span
              className="flex justify-center items-center gap-x-1 text-primary cursor-pointer font-medium"
              onClick={toggleAddSubExam}
            >
              <PlusIcon className="h-3 w-3 text-primary" />
              Add new sub-exam
            </span>
            {addSubExamModal.newSubjectDetails.isVisible && (
              <input
                type="text"
                className="border border-gray-400 h-10 rounded-md pl-1 outline-none"
                onChange={handleAddSubExamText}
                placeholder="Type sub-exam name "
                value={addSubExamModal.newSubjectDetails.newSubjectName}
              />
            )}
          </div>
          <Button
            className="mt-8 w-28 self-center"
            buttonStyle="success"
            onClick={handleSubExamCreateAndDelete}
          >
            Add Sub Exam
          </Button>
        </div>
      </Modal>
      <Modal
        open={assesmentToDeleteModal?.isDeleteModalVisible}
        onOk={handleConfirmDelete}
        centered
        closable={false}
        bodyStyle={{
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
        footer={null}
        zIndex={1001} // Default antD modal zIndex is 1000, hence to make sure this modal always overlays
      >
        <div className="flex flex-col justify-center">
          <h3 className="text-xl font-semibold flex flex-1 justify-center">
            Are you sure you want to delete this exam?
          </h3>
          <div className="flex justify-evenly pt-10">
            <Button
              buttonStyle="primary"
              onClick={handleCancelDelete}
              style={{
                height: 30,
                background: "#22C55E",
              }}
            >
              No
            </Button>
            <Button
              buttonStyle="danger"
              onClick={handleConfirmDelete}
              style={{
                background: "#EF4444",
                height: 30,
                marginLeft: 25,
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={isAddExamModalVisible}
        centered
        destroyOnClose
        onCancel={() => setIsAddExamModalVisible(false)}
        closeIcon={
          <CloseCircleFilled
            style={{
              color: "#EB5757",
              fontSize: "24px",
            }}
          />
        }
        bodyStyle={{
          justifyContent: "center",
        }}
        width={250}
        footer={null}
      >
        <div className="flex flex-col justify-center">
          <div className="mb-5">
            <p className="text-black text-lg font-bold">Add Exam</p>
          </div>
          <Dropdown
            type="primary"
            value={addExamFields?.label || "Select Term"}
            options={EXAM_TERM_OPTIONS}
            onItemClick={(term) => handleTermChange(term)}
            className="justify-between"
          />
          <div className="mt-4 flex flex-col">
            <label className="text-gray-700 font-medium">Exam Name</label>
            <input
              type="text"
              className="border border-gray-400 h-10 rounded-md pl-1 outline-none"
              onChange={handleExamTitleChange}
              value={addExamFields?.title}
            />
          </div>
          <div className="mt-4">
            <p className="text-gray-700 font-medium">Sub Exam</p>
          </div>
          <div className="flex-col">
            {loader ? (
              <Spin />
            ) : (
              <Checkbox.Group
                style={{ display: "flex", flexDirection: "column" }}
                options={getFormattedCheckboxData(addSubExamOptions)}
                defaultValue={[]}
                onChange={(checkedValues) => {
                  setSelectedSubExamOptions(checkedValues);
                }}
              />
            )}
          </div>
          <div className="flex flex-1 flex-col justify-start mt-4 items-start">
            <span
              className="flex justify-center items-center gap-x-1 text-primary cursor-pointer font-medium"
              onClick={toggleAddSubExam}
            >
              <PlusIcon className="h-3 w-3 text-primary " />
              Add new sub-exam
            </span>
            {addSubExamModal.newSubjectDetails.isVisible && (
              <input
                type="text"
                className="border border-gray-400 h-10 rounded-md pl-1 outline-none"
                onChange={handleAddSubExamText}
                placeholder="Type sub-exam name "
                value={addSubExamModal.newSubjectDetails.newSubjectName}
              />
            )}
          </div>
          <Button
            className="mt-4 w-28 self-center"
            buttonStyle="success"
            onClick={handleCreateExam}
          >
            Create Exam
          </Button>
        </div>
      </Modal>
      <Modal
        open={isEditModalVisible}
        centered
        closable={false}
        bodyStyle={{
          justifyContent: "center",
          overflowX: "auto",
        }}
        width={"90%"}
        footer={null}
        destroyOnClose
      >
        <div className="flex flex-col gap-y-3">
          <EditModalContext.Provider
            value={{
              isEditModalVisible,
              setIsEditModalVisible,
              isCCE,
              handleExamDelete,
              showSubExamAddModal,
            }}
          >
            <EditableTable
              isEditable
              onCancel={handleEditCancel}
              tableColumns={editTableStructure?.columns}
              tableData={
                displayTableStructure?.data?.length > 0
                  ? displayTableStructure?.data
                  : DEFAULT_EXAMS
              }
              onSave={handleEditExam}
              term={term}
              onDelete={handleExamDelete}
              divisions={divisions}
              isCCE={isCCE}
              updateParentState={
                storeDataBeforeRefetch ? storeDataBeforeRefetch : null
              }
            />
          </EditModalContext.Provider>
        </div>
      </Modal>
    </div>
  );
}

export { CCETab };
