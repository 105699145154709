import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Select, Spin, notification, Upload, message, Button } from "antd";
import CustomButton from "../../components/Button";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CustomFieldArrSelect from "../../components/FormSelect";
import CustomInput from "../../components/FormInput";
import { GET_CITIES, GET_DISTRICTS, GET_STATES } from "../settingsView/graphql";
import {
  GET_TEACHER_BYPK,
  UPDATE_TEACHER_BYPK,
  ADD_TEACHER_ADDRESS,
  UPDATE_TEACHER_ADDRESS_RELATIONSHIP,
} from "./graphql";
import { getAuthID } from "../../shared/getAuthID";
import { v4 as uuid } from "uuid";
import { getS3Image, getS3Bucket } from "../../shared/s3";
import axios from "axios";
import CustomRadioInput from "../studentsView/CustomRadioInput";
import CustomAntdDatePicker from "../studentsView/CustomDatePicker";
import moment from "moment";
import { DATE_FORMATS } from "../../utils/constants";
import { getSortedLabels } from "../../helpers";
const imagesS3Bucket = getS3Bucket();
const mockOptions = ["mockOption 1", "mockOption 2", "mockOption 3"];
const importURL = `${process.env.REACT_APP_FUNCTION_ENDPOINT}/editUsers`;

const final = (payload) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "post",
      url: importURL,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios(config)
      .then((res) => {
        if (res?.data?.failedArray?.length > 0) {
          console.error("something went wrong");
          notification["error"]({
            message: "Phone number already available",
            description: `Please use a different number`,
            duration: 3,
          });
          reject("Phone number belongs to another user");
        } else {
          notification["success"]({
            message: "Phone number changed successfully",
            duration: 3,
          });
          resolve("Valid phone number");
        }
      })
      .catch((err) => {
        reject(err.response.data.message);
      });
  });
};

const { Option } = Select;
const FormCard = ({ children, final, reset }) => {
  return (
    <>
      <div className="w-3/4 flex flex-col">
        <div className="border-2 bg-white rounded-md mb-1 pt-6">{children}</div>
        {final ? (
          <div className="px-4 py-3 text-right sm:px-6">
            <CustomButton
              onClick={reset}
              buttonStyle={"default"}
              className={"mr-2 cw-btn-100"}
              style={{ display: "inline-block" }}
            >
              Cancel
            </CustomButton>
            <button
              type="submit"
              className="inline-flex -mr-6 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};
const FormCol = ({ children, overrideClassnames }) => {
  return (
    <div className={`${overrideClassnames} flex flex-col flex-auto`}>
      {children}
    </div>
  );
};
const FormRow = ({ children, overrideClassnames }) => {
  return (
    <div
      className={`${overrideClassnames} flex flex-row justify-start gap-x-8 mb-4 px-6`}
    >
      {children}
    </div>
  );
};
const FormSideSection = ({ title, description, children }) => {
  return (
    <div className="w-1/4 text-left ">
      <p className="text-lg leading-6 font-medium text-gray-900  ">{title}</p>
      <p className="mt-1 text-sm leading-5 font-normal text-gray-500 w-8/12  ">
        {description}
      </p>
      {children}
    </div>
  );
};

const EditTeacher = ({ onSuccess, teacherID }) => {
  const [user, setuser] = useState(null);
  const [loader, setloader] = useState(false);
  const [hasImage, setHasImage] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [birthDate, setBirthDate] = useState(moment());

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    criteriaMode: "all",
    defaultValues: user,
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "contactDetails", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const stateValue = watch("stateValue");
  const districtValue = watch("districtValue");
  const cityValue = watch("cityValue");

  const splitPhone = (phnumber) => {
    if (!_.isNil(phnumber)) {
      let contains = phnumber.includes("+91", 0);
      if (contains) {
        let theSplit = phnumber.split("+91");
        return theSplit[1];
      } else {
        return phnumber;
      }
    } else {
      return null;
    }
  };

  const {
    loading: statesLoading,
    error: statesError,
    data: statesData,
  } = useQuery(GET_STATES);

  const [
    getDistricts,
    { loading: districtsLoading, error: districtsError, data: districtsData },
  ] = useLazyQuery(GET_DISTRICTS);

  const [
    getCities,
    { loading: citiesLoading, error: citiesError, data: citiesData },
  ] = useLazyQuery(GET_CITIES);

  const [addAddress] = useMutation(ADD_TEACHER_ADDRESS);
  const [updateAddressRelationship] = useMutation(
    UPDATE_TEACHER_ADDRESS_RELATIONSHIP
  );
  const stateList = statesData?.states?.map((state) => {
    return {
      value: state.id,
      label: state.state_name,
    };
  });
  const districtList = districtsData?.districts?.map((district) => {
    return {
      value: district.id,
      label: district.district_name,
    };
  });
  const cityList = citiesData?.cities?.map((city) => {
    return {
      value: city.id,
      label: city.city_name,
    };
  });

  const [updateTeacher] = useMutation(UPDATE_TEACHER_BYPK);
  const { loading, error, data } = useQuery(GET_TEACHER_BYPK, {
    variables: {
      id: teacherID,
    },
    onCompleted: (res) => {
      setuser(res?.users_by_pk);

      let resdata = res?.users_by_pk;
      let launch = {
        firstName: resdata?.first_name,
        middleName: resdata?.middle_name,
        lastName: resdata?.last_name,
        mobileNumber: splitPhone(resdata?.mobile_number),
        emailAddress: resdata?.email,
        add1: resdata?.address?.address_line_one,
        add2: resdata?.address?.address_line_two,
        state: resdata?.address?.city?.district?.state?.id,
        district: resdata?.address?.city?.district?.id,
        city: resdata?.address?.city?.id,
        cityValue: resdata?.address?.city?.city_name,
        districtValue: resdata?.address?.city?.district?.district_name,
        stateValue: resdata?.address?.city?.district?.state?.state_name,
        pincode: resdata?.address?.pincode,
        contactDetails: [],
        authID: resdata?.auth_id,
        dateOfBirth: resdata.dob,
        gender: resdata.gender,
      };

      if (!_.isNil(resdata.dob)) {
        launch.dateOfBirth = moment(resdata.dob);
      }

      if (resdata.image !== null) {
        setHasImage(true);
        setImageID(resdata?.image);
        setImageURL(getS3Image(resdata?.image));
      }
      let potts = [];
      resdata?.contacts.map((contact) => {
        potts.push({
          fullName: contact?.full_name,
          contactInfo: contact?.mobile_number,
          contactRelationship: contact?.relation,
        });
      });
      launch.contactDetails = potts;
      setuser(launch);
      setBirthDate(launch.dateOfBirth);
      reset(launch);
    },
  });

  useEffect(() => {
    if (!user?.state) return;
    if (user?.cityValue) {
      setValue("cityValue", user?.cityValue);
    }

    getDistricts({ variables: { stateId: user?.state } }).then((districts) => {
      const userDistrictId = districts?.data?.districts?.find(
        (district) => district.district_name === user?.districtValue
      );
      if (userDistrictId)
        getCities({ variables: { districtId: userDistrictId?.id } });
    });
  }, [user]);

  const onSubmit = (dataz) => {
    setloader(true);
    // If user has not input state, city and district allow save
    // If user has input state, then city and district must be entered for save
    if (!stateValue && !districtValue && !cityValue) {
      updateUser(dataz);
    } else if (!districtValue) {
      notification["error"]({
        message: "Please select a district",
        description: "District is a required field",
        duration: 3,
      });
      setloader(false);
      return;
    } else if (!cityValue) {
      notification["error"]({
        message: "Please select a city",
        description: "City is a required field",
        duration: 3,
      });
      setloader(false);
      return;
    }
    // Update user in case all state details are entered
    updateUser(dataz);
  };

  const updateUser = (payloadFromSubmit) => {
    let {
      emailAddress,
      firstName,
      middleName,
      lastName,
      mobileNumber,
      add1,
      add2,
      pincode,
      city,
      authID,
      gender,
      dateOfBirth,
    } = payloadFromSubmit;
    let firebasePload = {
      data: [
        {
          cur_phone: `+91${user.mobileNumber}`,
          phone: `+91${mobileNumber}`,
          uid: authID,
        },
      ],
    };

    let pload = {
      email: emailAddress,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      mobile_number: `+91${mobileNumber}`,
      gender: gender,
      dob: dateOfBirth
        ? moment(dateOfBirth).format(DATE_FORMATS.YYYYMMDD)
        : null,
    };
    if (hasImage) {
      pload.image = imageID;
    }

    let cityId = citiesData?.cities?.find(
      (city) => city.city_name === cityValue
    )?.id;

    let addresspload = {
      address_line_one: add1,
      address_line_two: add2,
      pincode: pincode,
      city_id:
        cityValue !== undefined
          ? cityId
          : parseInt(payloadFromSubmit.cityValue),
    };

    if (user.mobileNumber !== mobileNumber) {
      final(firebasePload)
        .then((res) => {
          if (data?.users_by_pk?.address?.id === undefined) {
            addAddress({ variables: { address: addresspload } }).then(
              (addressResponse) => {
                updateTeacher({
                  variables: {
                    _set: pload,
                    id: teacherID,
                    addressid:
                      addressResponse?.data?.insert_addresses?.returning[0]?.id,
                    _setaddress: addresspload,
                    addedBy: getAuthID(),
                    teacherAuthID: user.authID,
                  },
                })
                  .then((response) => {
                    updateAddressRelationship({
                      variables: {
                        authID: user.authID,
                        addressid:
                          addressResponse?.data?.insert_addresses?.returning[0]
                            ?.id,
                        addedBy: getAuthID(),
                      },
                    }).then((res) => {
                      notification["success"]({
                        message: "Updated values",
                        description: `for teacher: ${response?.data?.update_users_by_pk?.first_name}`,
                        duration: 2,
                      });

                      onSuccess();
                      setloader(false);
                    });
                  })

                  .catch((reserror) => {
                    setloader(false);
                  });
              }
            );
          } else {
            updateTeacher({
              variables: {
                _set: pload,
                id: teacherID,
                addressid: data?.users_by_pk?.address?.id,
                _setaddress: addresspload,
                addedBy: getAuthID(),
                teacherAuthID: user.authID,
              },
            })
              .then((response) => {
                notification["success"]({
                  message: "Updated values",
                  description: `for teacher: ${response?.data?.update_users_by_pk?.first_name}`,
                  duration: 2,
                });

                onSuccess();
                setloader(false);
              })
              .catch((reserror) => {
                setloader(false);
              });
          }
        })
        .catch((err) => setloader(false));
    } else {
      if (data?.users_by_pk?.address?.id === undefined) {
        addAddress({ variables: { address: addresspload } }).then(
          (addressResponse) => {
            updateTeacher({
              variables: {
                _set: pload,
                id: teacherID,
                addressid:
                  addressResponse?.data?.insert_addresses?.returning[0]?.id,
                _setaddress: addresspload,
                addedBy: getAuthID(),
                teacherAuthID: user.authID,
              },
            })
              .then((response) => {
                updateAddressRelationship({
                  variables: {
                    authID: user.authID,
                    addressid:
                      addressResponse?.data?.insert_addresses?.returning[0]?.id,
                    addedBy: getAuthID(),
                  },
                }).then((res) => {
                  notification["success"]({
                    message: "Updated values",
                    description: `for teacher: ${response?.data?.update_users_by_pk?.first_name}`,
                    duration: 2,
                  });

                  onSuccess();
                  setloader(false);
                });
              })

              .catch((reserror) => {
                setloader(false);
              });
          }
        );
      } else {
        updateTeacher({
          variables: {
            _set: pload,
            id: teacherID,
            addressid: data?.users_by_pk?.address?.id,
            _setaddress: addresspload,
            addedBy: getAuthID(),
            teacherAuthID: user.authID,
          },
        })
          .then((response) => {
            notification["success"]({
              message: "Updated values",
              description: `for teacher: ${response?.data?.update_users_by_pk?.first_name}`,
              duration: 2,
            });

            onSuccess();
            setloader(false);
          })
          .catch((reserror) => {
            setloader(false);
          });
      }
    }
  };
  const onAddressValueClear = () => {
    setValue("stateValue", null);
    setValue("districtValue", null);
    setValue("cityValue", null);
    setValue("pincode", "");
  };

  const filterOptionsOnInput = (inputValue, option) => {
    return option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase());
  };

  const renderImage = () => {
    if (hasImage) {
      return (
        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
          <img
            src={imageURL}
            alt="avatar"
            className="w-full h-full object-cover rounded-md"
          />
        </span>
      );
    }

    return (
      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  };
  const handleUpload = async (file, onSuccess) => {
    const fileId = uuid();
    // get file extension
    const fileExtension = file.name.split(".").pop();
    const key = `${process.env.REACT_APP_S3_BUCKET_FOLDER}/${fileId}.${fileExtension}`;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
    };
    imagesS3Bucket.putObject(params).send((err, data) => {
      if (err) {
        // handle the error here
        console.error("s3 err", err);
      } else {
        // handle the success here
        onSuccess(data.response, file);
        //  store file id in school instead of image url
        const imageKey = `${fileId}.${fileExtension}`;
        // update image in school

        setHasImage(true);
        setImageID(imageKey);
        setImageURL(getS3Image(imageKey));
      }
    });
  };
  const uploadProps = {
    customRequest({ file, onSuccess }) {
      return handleUpload(file, onSuccess);
    },
  };

  const handleDOBChange = (dob) => {
    setValue(`dateOfBirth`, dob);
    setBirthDate(moment(dob));
  };

  if (loading) return <div>Loading...</div>;
  return (
    <Spin spinning={loader}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          <FormSideSection
            title="Teacher Details"
            description="Personal information of the teacher"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <div>
                  <label className="block text-gray-700 font-semibold text-sm leading-5 text-left">
                    Photo (Optional)
                  </label>
                  <div className="mt-1 flex items-center">
                    {renderImage()}
                    <Upload
                      {...uploadProps}
                      beforeUpload={(file) => {
                        // check if file is an jpeg or png
                        if (
                          file.type !== "image/jpeg" &&
                          file.type !== "image/png"
                        ) {
                          message.error("Only jpeg or png images are allowed!");
                          return false;
                        }
                        // check if file size is less than 2MB
                        if (file.size > 2000000) {
                          message.error("File size should be less than 2MB!");
                          return false;
                        }
                        return true;
                      }}
                      showUploadList={false}
                      className="ml-5 bg-white py-2 px-3 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Button
                        style={{
                          borderRadius: 6,
                          fontWeight: "bold",
                          color: "#374151",
                        }}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  name="firstName"
                  placeholder="firstName"
                  label="First Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "required",
                    },
                  }}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="middleName"
                  placeholder="middleName"
                  label="Middle Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "required",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="lastName"
                  placeholder="lastName"
                  label="Last Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "required",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <CustomInput
                  name="mobileNumber"
                  placeholder="mobileNumber"
                  label="Mobile Number"
                  register={register}
                  rules={{ required: true, pattern: /^[6-9]\d{9}$/i }}
                  errors={errors}
                  required
                />
              </FormCol>

              <FormCol>
                <CustomInput
                  name="emailAddress"
                  placeholder="emailAddress"
                  label="Email"
                  register={register}
                  errors={errors}
                  type="email"
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol overrideClassnames="col-span-1">
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={{ required: "required" }}
                  render={({ field }) => (
                    <>
                      <CustomAntdDatePicker
                        name="dateOfBirth"
                        placeholder="dateOfBirth"
                        label="Date of birth"
                        register={register}
                        errors={errors}
                        onChange={handleDOBChange}
                        fieldData={{
                          value: birthDate,
                        }}
                        defaultValue={birthDate}
                        required
                      />
                    </>
                  )}
                />
              </FormCol>

              <FormCol overrideClassnames="col-span-2">
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Gender"}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>

                <div className="flex flex-row">
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="M"
                      label="Male"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="F"
                      label="Female"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="T"
                      label="Transgender"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-22 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="NA"
                      label="Not Disclosed"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
          </FormCard>
        </div>
        <div className="flex">
          <FormSideSection
            title="Address"
            description="Teacher's current address"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <CustomInput
                  name="add1"
                  placeholder="add1"
                  label="Address Line 1"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <CustomInput
                  name="add2"
                  placeholder="add2"
                  label="Address Line 2"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  State
                </label>
                <Controller
                  name="stateValue"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      loading={statesLoading}
                      className="customised-antd-selector-address"
                      onChange={(value) => {
                        setValue("stateValue", value);
                        setValue("districtValue", null);
                        setValue("cityValue", null);
                        setValue("pincode", "");
                        getDistricts({ variables: { stateId: value } });
                      }}
                      virtual={false}
                      showSearch
                      filterOption={filterOptionsOnInput}
                      size="large"
                      allowClear
                      placeholder={
                        _.isNil(user?.stateValue)
                          ? "Select State"
                          : `${user?.stateValue}`
                      }
                      onClear={() => onAddressValueClear()}
                    >
                      {getSortedLabels(stateList)?.map((classl) => (
                        <Option key={classl.value}>{classl.label}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  District
                </label>
                <Controller
                  name="districtValue"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="customised-antd-selector-address"
                      onChange={(value) => {
                        setValue("districtValue", value);
                        setValue("cityValue", null);
                        setValue("pincode", "");
                        getCities({ variables: { districtId: value } });
                      }}
                      virtual={false}
                      size="large"
                      allowClear
                      showSearch
                      filterOption={filterOptionsOnInput}
                      placeholder={
                        _.isNil(user?.districtValue)
                          ? "Select District"
                          : `${user?.districtValue}`
                      }
                      onClear={() => onAddressValueClear()}
                    >
                      {getSortedLabels(districtList)?.map((classl) => (
                        <Option key={classl.value}>{classl.label}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  City
                </label>
                <Controller
                  name="cityValue"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="customised-antd-selector-address"
                      onChange={(value) => {
                        const cityName = citiesData?.cities?.find(
                          (city) => city?.id === parseInt(value)
                        )?.city_name;
                        setValue("cityValue", cityName);
                        setValue("pincode", "");
                      }}
                      virtual={false}
                      showSearch
                      filterOption={filterOptionsOnInput}
                      size="large"
                      allowClear
                      placeholder={
                        _.isNil(user?.cityValue)
                          ? "Select City"
                          : `${user?.cityValue}`
                      }
                      onClear={() => onAddressValueClear()}
                    >
                      {getSortedLabels(cityList)?.map((classl) => (
                        <Option key={classl.value}>{classl.label}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="pincode"
                  placeholder="pincode"
                  label="Pincode"
                  register={register}
                  errors={errors}
                  overrideClassnames="w-56"
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>
        <div className="flex">
          <FormSideSection title="Emergency Contact" description="(Optional)" />
          <FormCard final reset={reset}>
            {fields.map((field, fieldIndex) => (
              <FormRow key={field.id} overrideClassnames="items-end">
                <FormCol>
                  <CustomInput
                    key={field.id} // important to include key with field's id
                    register={() =>
                      register(`contactDetails.${fieldIndex}.fullName`, {
                        required: true,
                      })
                    }
                    errors={errors}
                    label="Full Name"
                  />
                </FormCol>
                <FormCol>
                  <CustomInput
                    key={field.id} // important to include key with field's id
                    name="contactInfo"
                    placeholder="contactInfo"
                    label="Contact information"
                    register={() =>
                      register(`contactDetails.${fieldIndex}.contactInfo`, {
                        required: true,
                        pattern: /^[6-9]\d{9}$/i,
                      })
                    }
                    errors={errors}
                    required
                  />
                </FormCol>
                <FormCol>
                  <CustomFieldArrSelect
                    key={field.id} // important to include key with field's id
                    options={mockOptions}
                    name="contactRelationship"
                    placeholder="contactRelationship"
                    label="Contact relationship"
                    register={() =>
                      register(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        {
                          required: true,
                        }
                      )
                    }
                    errors={errors}
                    defaultValue={`${field.contactRelationship}`}
                    onChange={(value) =>
                      setValue(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        value
                      )
                    }
                    required
                  />
                </FormCol>
                <FormCol>
                  <button
                    type="reset"
                    className="py-3 px-3 mr-2 justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100 "
                    onClick={() => {
                      remove(fieldIndex);
                    }}
                  >
                    Cancel
                  </button>
                </FormCol>
              </FormRow>
            ))}
            <FormRow>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    fullName: "",
                    contactInfo: "",
                    contactRelationship: "",
                  });
                }}
                className="p-2 rounded-md bg-white border border-gray-300 text-xs
              leading-4 font-medium shadow-sm  cursor-pointer "
              >
                {" "}
                Add new contact (optional)
              </button>
            </FormRow>
          </FormCard>
        </div>
      </form>
    </Spin>
  );
};

export default EditTeacher;
