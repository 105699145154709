import React, { useState, useEffect } from "react";
import { AutoComplete, DatePicker, notification } from "antd";
import { getS3Image } from "../../shared/s3";
import moment from "moment";
import {
  PencilIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import { Button, Text } from "../../components";
import { convertDateToWords } from "../../utils";

const googleTransliterate = require("../../utils/googleInputTool");

const FormCol = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};
const FormRow = ({ children, className }) => {
  return <div className={`${className} flex my-2`}>{children}</div>;
};

const AutoCompleteInput = ({
  field,
  options,
  formData,
  onSelect,
  onChange,
  onBlur,
}) => {
  return (
    <AutoComplete
      options={options}
      style={{ width: 164, borderRadius: "6px" }}
      value={formData[field]}
      onSelect={onSelect}
      onBlur={onBlur}
      dropdownMatchSelectWidth={false}
      onInputKeyDown={(e) => e.stopPropagation()}
    >
      <input
        type="text"
        className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
        value={formData[field]}
        onChange={onChange}
      />
    </AutoComplete>
  );
};

function TransferCertificateView({
  isMasked,
  isEditing,
  isEditable,
  data,
  translatedData,
  languageInputCode,
  nextSerialNumber,
  setNextSerialNumber,
  onCancel,
  onEdit,
  onSave,
  onIssueDocument,
  viewOnly,
  containerStyle,
  hideDownload,
  isEditFormat,
  onDownloadDocument,
  getTranslatedText,
  selectedLanguage,
}) {
  const [tcFormData, setTcFormData] = useState({});
  const [isDocumentExpanded, setIsDocumentExpanded] = useState(false);
  const [inputOptions, setInputOptions] = useState([]);
  const isEditingStudent = isEditing && !isEditFormat;

  const onToggleDocumentView = () => {
    setIsDocumentExpanded(!isDocumentExpanded);
  };

  useEffect(() => {
    if (data) {
      // get image base64 from local storage
      const localBase64 = localStorage.getItem(data.image);
      // set imageUrl in tcFormData as localBase64 if exists else get and set imageUrl from s3
      if (localBase64) {
        setTcFormData({ ...data, ...translatedData, imageUrl: localBase64 });
      } else {
        const imageUrl = getS3Image(data.image);
        setTcFormData({ ...data, ...translatedData, imageUrl });
      }
    }
  }, [data, translatedData]);

  const handleSave = () => {
    onSave(tcFormData);
  };

  const handleIssueAndDownload = () => {
    if (!nextSerialNumber && !tcFormData.isTransferred) {
      notification.error({
        message: "Please enter serial number",
      });
      return;
    }
    onIssueDocument(tcFormData);
  };

  const {
    trustName,
    schoolName,
    cityName,
    stateName,
    studentStateName,
    udiseNumber,
    schoolRegistrationNumber,
    mobileNumber,
    board,
    schoolMedium,
    email,
    generalRegisterNumber,
    date,
    studentRef,
    uidNumber,
    studentName,
    serialNumber,
    motherName,
    nationality,
    caste,
    subCaste,
    religion,
    motherTongue,
    birthPlace,
    country,
    dateOfBirth,
    dateOfBirthInWords,
    previousSchool,
    previousSchoolClass,
    dateOfAdmission,
    admittedClass,
    academicProgress,
    behaviour,
    transferDate,
    reasonForTransfer,
    remarks,
    leftClass,
  } = tcFormData;

  const getOptionValue = (str, repeat = 1) => ({
    value: str.repeat(repeat),
  });

  const onSearch = (searchText) => {
    const tcDocument = data?.settings?.documents?.find(
      (doc) => doc.code === "tc"
    );
    const documentInputCode = languageInputCode || tcDocument?.googleInputCode;
    if (!documentInputCode || documentInputCode === "en") {
      return;
    }
    let sourceText = searchText;
    let inputLanguage = documentInputCode;
    let maxResult = 8;
    let request = new XMLHttpRequest();
    googleTransliterate(request, sourceText, inputLanguage, maxResult).then(
      function (response) {
        const suggestionOptions = response.map((item) =>
          getOptionValue(item?.[0])
        );
        setInputOptions(suggestionOptions);
      }
    );
  };

  const onDobChange = async (value, dateString) => {
    let dobWords = convertDateToWords(dateString);
    if (selectedLanguage !== "en") {
      const textList = [dobWords || "-"];
      const translatedData = await getTranslatedText(
        textList,
        "en",
        selectedLanguage
      );
      const translatedDOBWords = translatedData[0]?.translatedText;
      setTcFormData({
        ...tcFormData,
        dateOfBirth: dateString,
        dateOfBirthInWords: translatedDOBWords,
      });
    } else {
      setTcFormData({
        ...tcFormData,
        dateOfBirth: dateString,
        dateOfBirthInWords: dobWords,
      });
    }
  };

  return (
    <form
      id="tcDocument"
      className={`${
        isMasked && "opacity-20"
      } flex flex-col overflow-x-hidden bg-white h-3/4 mt-2 rounded-lg px-6 pt-6`}
      style={{
        ...containerStyle,
      }}
    >
      <div
        className="relative overflow-y-hidden border-4 border-indigo-600 rounded-xl"
        style={{
          height: isDocumentExpanded ? "1800px" : "380px",
          maxHeight: isDocumentExpanded ? "1800px" : "380px",
          transition: "all 1s ease-out",
        }}
      >
        <div className="relative overflow-y-scroll box-border h-full w-full flex flex-col p-2">
          {data?.image && (
            <img
              id="school-image"
              alt="Logo"
              src={tcFormData?.imageUrl}
              className="h-28 w-28 ml-4 mt-4 top-2 absolute"
            />
          )}
          {!data?.image && (
            <div
              id="school-logo"
              className="h-28 w-28 ml-4 mt-4 top-2 bg-gray-200 absolute"
            ></div>
          )}
          <div className="flex flex-col justify-center items-center">
            {isEditing && !isEditingStudent ? (
              <div className="flex mb-2">
                <label className="font-bold text-gray-700 mr-1">
                  <Text id="slogan" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="slogan"
                  options={inputOptions}
                  formData={tcFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setTcFormData({
                      ...tcFormData,
                      slogan: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setTcFormData({
                      ...tcFormData,
                      slogan: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </div>
            ) : (
              <h4 className="font-bold text-indigo-700 text-base">
                {data ? data?.slogan || "" : "Slogan"}
              </h4>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="trust-name" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="trustName"
                  options={inputOptions}
                  formData={tcFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setTcFormData({
                      ...tcFormData,
                      trustName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setTcFormData({
                      ...tcFormData,
                      trustName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <h4 className="font-bold text-gray-700 text-base">
                {data || translatedData ? trustName || "" : "Trust Name"}
              </h4>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="school-name" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="schoolName"
                  options={inputOptions}
                  formData={tcFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setTcFormData({
                      ...tcFormData,
                      schoolName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setTcFormData({
                      ...tcFormData,
                      schoolName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <h3
                id="school-name"
                className={`font-bold text-indigo-700 ${
                  schoolName?.length > 30 ? "text-lg" : "text-3xl"
                }`}
              >
                {schoolName || "School Name"}
              </h3>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="city" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="cityName"
                  options={inputOptions}
                  formData={tcFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setTcFormData({
                      ...tcFormData,
                      cityName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setTcFormData({
                      ...tcFormData,
                      cityName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <></>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="state" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="stateName"
                  options={inputOptions}
                  formData={tcFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setTcFormData({
                      ...tcFormData,
                      stateName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setTcFormData({
                      ...tcFormData,
                      stateName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <></>
            )}
            {!isEditing && !isEditingStudent && (
              <h4 className="text-black font-bold text-base">
                {cityName || "City"}, {stateName || "State"}
              </h4>
            )}
          </div>
          <div className="flex text-left gap-x-56 items-center justify-center">
            <FormCol>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="school-registration-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={tcFormData.schoolRegistrationNumber}
                    onChange={(e) =>
                      setTcFormData({
                        ...tcFormData,
                        schoolRegistrationNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {schoolRegistrationNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="u-dise-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={tcFormData.udiseNumber}
                    onChange={(e) =>
                      setTcFormData({
                        ...tcFormData,
                        udiseNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {udiseNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="index-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={tcFormData.indexNumber}
                    onChange={(e) =>
                      setTcFormData({
                        ...tcFormData,
                        indexNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {tcFormData?.indexNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="mobile-number" /> :
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={tcFormData.mobileNumber}
                    onChange={(e) =>
                      setTcFormData({
                        ...tcFormData,
                        mobileNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {mobileNumber}
                  </p>
                )}
              </FormRow>
            </FormCol>
            <FormCol>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1">&nbsp;</label>
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="board" /> :{" "}
                </label>
                {isEditing ? (
                  <AutoCompleteInput
                    field="board"
                    options={inputOptions}
                    formData={tcFormData}
                    onBlur={(e) => {
                      setInputOptions([]);
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                      setTcFormData({
                        ...tcFormData,
                        board: e.target.value,
                      });
                    }}
                    onSelect={(value) => {
                      setTcFormData({
                        ...tcFormData,
                        board: value,
                      });
                      setInputOptions([]);
                    }}
                  />
                ) : (
                  <p className="font-bold text-black text-base">{board}</p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="school-medium" /> :{" "}
                </label>
                {isEditing ? (
                  <AutoCompleteInput
                    field="schoolMedium"
                    options={inputOptions}
                    formData={tcFormData}
                    onBlur={(e) => {
                      setInputOptions([]);
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                      setTcFormData({
                        ...tcFormData,
                        schoolMedium: e.target.value,
                      });
                    }}
                    onSelect={(value) => {
                      setTcFormData({
                        ...tcFormData,
                        schoolMedium: value,
                      });
                      setInputOptions([]);
                    }}
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {schoolMedium}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="email" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={tcFormData.email}
                    onChange={(e) =>
                      setTcFormData({
                        ...tcFormData,
                        email: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">{email}</p>
                )}
              </FormRow>
            </FormCol>
          </div>
          <div
            id="title-container"
            className="grid grid-flow-col gap-x-3 pl-28 pr-28 mt-2 mb-7 text-center justify-center items-center"
          >
            <div
              id="title-border-left"
              className="border-b-4 w-36 border-indigo-600 m-auto"
            ></div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div id="tcTitle" className="text-2xl w-auto mb-auto">
              <p
                style={{
                  fontFamily: containerStyle?.fontFamily || "Kurale",
                }}
                className="text-3xl"
              >
                <Text id="transfer-certificate" />
              </p>
            </div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div
              id="title-border-right"
              className="border-b-4 w-36 border-indigo-600 m-auto"
            ></div>
          </div>
          {data?.isTransferred && (
            <div
              id="duplicate-watermark"
              className="text-lg font-bold text-red-500"
            >
              <p>DUPLICATE COPY</p>
            </div>
          )}
          <div className="flex flex-col gap-x-32 text-left">
            <div className="flex gap-x-8 justify-between text-left mx-11 mr-12">
              <FormCol className="flex flex-col">
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="serial-number" /> :
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={nextSerialNumber}
                      onChange={(e) => setNextSerialNumber(e.target.value)}
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {serialNumber || nextSerialNumber}
                    </p>
                  )}
                </FormRow>
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="student-id" /> :{" "}
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={tcFormData.studentRef}
                      onChange={(e) =>
                        setTcFormData({
                          ...tcFormData,
                          studentRef: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {studentRef}
                    </p>
                  )}
                </FormRow>
              </FormCol>
              <FormCol className="flex flex-col">
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="general-registration-number" /> :{" "}
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={tcFormData.generalRegisterNumber}
                      onChange={(e) =>
                        setTcFormData({
                          ...tcFormData,
                          generalRegisterNumber: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {generalRegisterNumber}
                    </p>
                  )}
                </FormRow>
              </FormCol>
              <FormCol className="flex flex-col items-end -ml-8">
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="date" /> :
                  </label>
                  {isEditingStudent ? (
                    <DatePicker
                      value={
                        date ? moment(tcFormData?.date, "DD/MM/YYYY") : null
                      }
                      onChange={(value, dateString) => {
                        setTcFormData({
                          ...tcFormData,
                          date: dateString,
                        });
                      }}
                      suffixIcon={null}
                      format="DD/MM/YYYY"
                      className="rounded-md"
                      allowClear={false}
                      style={{
                        borderRadius: "6px",
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black text-base">{date}</p>
                  )}
                </FormRow>
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="uid-number" /> (<Text id="aadhar-number" />) :{" "}
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={tcFormData?.uidNumber}
                      onChange={(e) =>
                        setTcFormData({
                          ...tcFormData,
                          uidNumber: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {uidNumber &&
                      uidNumber !== "null" &&
                      uidNumber !== "undefined"
                        ? uidNumber
                        : ""}
                    </p>
                  )}
                </FormRow>
              </FormCol>
            </div>
            <div className="flex flex-col border border-gray-400 mx-11 rounded-lg mt-4">
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="student-full-name" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="studentName"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          studentName: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          studentName: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{studentName}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="mother-name" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="motherName"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          motherName: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          motherName: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{motherName}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="state" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ?
                    (
                      <AutoCompleteInput
                        field="studentStateName"
                        options={inputOptions}
                        formData={tcFormData}
                        onBlur={(e) => {
                          setInputOptions([]);
                        }}
                        onChange={(e) => {
                          onSearch(e.target.value);
                          setTcFormData({
                            ...tcFormData,
                            studentStateName: e.target.value,
                          });
                        }}
                        onSelect={(value) => {
                          setTcFormData({
                            ...tcFormData,
                            studentStateName: value,
                          });
                          setInputOptions([]);
                        }}
                      />
                    )
                  : (
                    <p className="font-bold text-black">
                      {studentName ? studentStateName : ""}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="country" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                {isEditingStudent ? (
                    <AutoCompleteInput
                      field="country"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          country: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          country: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{country}</p>
                  )}
                  </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="nationality" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="nationality"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          nationality: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          nationality: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{nationality}</p>
                  )}
                </div>
              </div>
             <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="religion" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="religion"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          religion: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          religion: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{religion}</p>
                  )}
                </div>
              </div>              
               <div className="flex justify-between text-left border-b border-gray-400  text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="caste" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="caste"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          caste: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          caste: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{caste}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400  text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="subCaste" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="subCaste"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          subCaste: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          subCaste: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{subCaste}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="mother-tongue" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="motherTongue"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          motherTongue: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          motherTongue: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{motherTongue}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="birth-place" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="birthPlace"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          birthPlace: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          birthPlace: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{birthPlace}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="date-of-birth" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <DatePicker
                      value={
                        dateOfBirth
                          ? moment(tcFormData?.dateOfBirth, "DD/MM/YYYY")
                          : null
                      }
                      onChange={onDobChange}
                      suffixIcon={null}
                      format="DD/MM/YYYY"
                      className="rounded-md"
                      allowClear={false}
                      style={{
                        borderRadius: "6px",
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">
                      {dateOfBirth ? dateOfBirth : ""}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="date-of-birth-in-words" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="dateOfBirthInWords"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          dateOfBirthInWords: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          dateOfBirthInWords: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">
                      {dateOfBirth ? dateOfBirthInWords : ""}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="previous-school" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="previousSchool"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          previousSchool: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          previousSchool: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{previousSchool}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="previous-school-class" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="previousSchoolClass"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          previousSchoolClass: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          previousSchoolClass: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">
                      {previousSchoolClass}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="date-of-admission" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <DatePicker
                      value={moment(tcFormData?.dateOfAdmission, "DD/MM/YYYY")}
                      onChange={(value, dateString) => {
                        setTcFormData({
                          ...tcFormData,
                          dateOfAdmission: dateString,
                        });
                      }}
                      suffixIcon={null}
                      format="DD/MM/YYYY"
                      className="rounded-md"
                      allowClear={false}
                      style={{
                        borderRadius: "6px",
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{dateOfAdmission}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="admitted-class" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="admittedClass"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          admittedClass: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          admittedClass: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{admittedClass}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="transfer-date" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <DatePicker
                      value={
                        tcFormData?.transferDate
                          ? moment(tcFormData?.transferDate, "DD/MM/YYYY")
                          : null
                      }
                      onChange={(value, dateString) => {
                        setTcFormData({
                          ...tcFormData,
                          transferDate: dateString,
                        });
                      }}
                      suffixIcon={null}
                      format="DD/MM/YYYY"
                      className="rounded-md"
                      allowClear={false}
                      style={{
                        borderRadius: "6px",
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{transferDate}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="exit-class" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="leftClass"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          leftClass: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          leftClass: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{leftClass}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="reason-for-transfer" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="reasonForTransfer"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          reasonForTransfer: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          reasonForTransfer: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{reasonForTransfer}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="academic-progress" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="academicProgress"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          academicProgress: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          academicProgress: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{academicProgress}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-b border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="behaviour" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="behaviour"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          behaviour: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          behaviour: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{behaviour}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-left border-gray-400 text-base">
                <div className="w-1/2 border-gray-400 pl-7 mt-0 mb-0 py-3 font-bold text-gray-700">
                  <label>
                    <Text id="remark" />
                  </label>
                </div>
                <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="remarks"
                      options={inputOptions}
                      formData={tcFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setTcFormData({
                          ...tcFormData,
                          remarks: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setTcFormData({
                          ...tcFormData,
                          remarks: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black">{remarks}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-left border-b-4 border-indigo-600 pb-6 mt-7 mb-28 mr-6 ml-6 pl-6">
            <p className="font-bold text-black text-base">
              <Text
                id="certify-as-per-reg-number"
                values={{
                  generalnumber:
                    generalRegisterNumber || tcFormData?.generalRegisterNumber,
                }}
              />
            </p>
          </div>
          <div className="grid grid-cols-3 text-black border-b-4 border-indigo-600 pb-3 mr-6 ml-6 pl-6">
            <div>
              <h3 className="font-bold text-base">
                <Text id="head-clerk" />
              </h3>
            </div>
            <div>
              <h3 className="font-bold text-base">
                <Text id="class-teacher" />
              </h3>
            </div>
            <div>
              <h3 className="font-bold text-base">
                <Text id="principal" />
              </h3>
            </div>
          </div>
          <p className="text-red-600 font-medium mt-1 text-base">
            <Text id="note-legal-action" />
          </p>
        </div>
        <Button
          id="documents-transfer-view"
          buttonStyle="primary"
          className="h-5 pt-4 z-10 flex items-center justify-center text-center absolute right-0 bottom-0 rounded-tl-md rounded-bl-none rounded-br-lg rounded-tr-none"
          onClick={onToggleDocumentView}
        >
          {isDocumentExpanded ? "Minimize document" : "View entire document"}
          {isDocumentExpanded ? (
            <ChevronUpIcon className="h-4 w-4 ml-3" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 ml-3" aria-hidden="true" />
          )}
        </Button>
      </div>
      <div
        id="form-controls"
        className="flex justify-end my-4"
        style={{
          fontFamily: "Inter",
        }}
      >
        {isEditing && (
          <Button
            id="documents-transfer-edit-cancel"
            style={{
              minWidth: "6rem",
            }}
            buttonStyle="default"
            onClick={() => onCancel()}
            className="h-12"
          >
            Cancel
          </Button>
        )}
        {isEditing && (
          <button
            id="documents-transfer-edit-save"
            type="button"
            className="h-12 py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              handleSave();
            }}
          >
            <span className="ml-2">Save Changes</span>
          </button>
        )}
        {!isEditing &&
          isEditable &&
          data &&
          !data?.isTransferred &&
          !isEditFormat && (
            <button
              id="documents-transfer-editdetails"
              type="button"
              className="h-12 py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
              onClick={() => {
                onEdit();
              }}
            >
              <PencilIcon className="h-4 w-4" aria-hidden="true" />
              <span className="ml-2">Edit Details</span>
            </button>
          )}
        {!isEditing && isEditFormat && (
          <button
            id="documents-transfer-edit-format"
            type="button"
            className="h-12 py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              onEdit();
            }}
          >
            <PencilIcon className="h-4 w-4" aria-hidden="true" />
            <span className="ml-2">Edit Format</span>
          </button>
        )}
        {data && isEditable && !data?.isTransferred && !hideDownload && (
          <Button
            id="documents-transfer-download"
            buttonStyle="success"
            onClick={handleIssueAndDownload}
            className="h-12 ml-5 py-3 px-4"
            disabled={isEditing || isEditingStudent}
          >
            Issue & Download <br /> Document
          </Button>
        )}
        {data && data?.isTransferred && !viewOnly && !hideDownload && (
          <Button
            id="documents-transfer-downloadduplicate"
            buttonStyle="success"
            onClick={onDownloadDocument}
            className="h-12 ml-5"
          >
            Download Duplicate Document
          </Button>
        )}
      </div>
    </form>
  );
}

export default TransferCertificateView;
