/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const getStaffReports = async (payload) => {
  try {
    const schoolId = getSchoolID();
    const finalPayload = { ...payload, schoolId };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/teacher/getStaffAttendance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: finalPayload,
    };
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (e) {
    return null;
  }
};

export default function useStaffReport(payload, options = {}) {
  return useQuery(
    [
      "staffReport",
      payload?.month,
      payload?.staffType,
      payload?.limit,
      payload?.offset,
    ],
    () => getStaffReports(payload),
    {
      enabled: !!payload?.month,
      ...options,
    }
  );
}
