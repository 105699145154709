/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { v4 as uuid } from "uuid";

const transformData = (expenses) => {
  const formattedExpenses = expenses?.data?.map((expense) => {
    if (expense?.attachments?.length) {
      const attachmentsWithId = expense.attachments.map(
        (currentAttachment) => ({
          ...currentAttachment,
          id: uuid(),
          isUploaded: true,
        })
      );
      expense.attachments = attachmentsWithId;
    }
    return expense;
  });
  return { data: formattedExpenses, totalCount: expenses?.totalCount };
};

const getExpenses = async (payload) => {
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/expenses/getExpenses`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};


export default function useGetExpenses(payload, options = {}) {
  return useQuery(
    [
      "expenses",
      payload?.startDate,
      payload?.endDate,
      payload?.searchText,
      payload?.paymentMode,
      payload?.expenseCategoryId,
      payload?.offset,
      payload?.limit,
    ],
    () => getExpenses(payload),
    {
      select: transformData,
      ...options,
    }
  );
}
