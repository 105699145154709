import React from "react";
import Menu from "../Menu";
import { useLocation } from "react-router-dom";
import usePageViews from "../../hooks/usePageViews";

function Layout({ children }) {
  const location = useLocation();
  usePageViews();
  const excludePaths = [
    "/auth",
    "/adminSettings",
    "/adminsettings",
    "/bulkUploadSchool",
    "/createSchool",
    "/do-you-know",
  ];
  const showLayout = !excludePaths.includes(location.pathname);
  if (!showLayout) {
    return <>{children}</>;
  }
  return (
    <div className="bg-gray-100 dark:bg-gray-800 rounded-2xl h-screen w-screen overflow-hidden relative">
      <main className="flex w-full">
        <Menu />
        {children}
      </main>
    </div>
  );
}

export default Layout;
