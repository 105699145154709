import React, { useState, useEffect } from "react";
import { Spin, Modal, DatePicker, notification } from "antd";
import Button from "../../../components/Button";
import ClassDivisionSelector from "../../../components/ClassDivisionSelector";
import Dropdown from "../../../components/Dropdown";
import { DownloadIcon } from "@heroicons/react/outline";
import ReportCardView from "./ReportCardView";
import { GET_CLASSES_AND_DIVISIONS } from "../../../views/studentsView/graphql";
import { GET_SCHOOL_DETAILS, GET_DIVISION_STUDENTS } from "../graphql";
import { getSortedClasses } from "../../../helpers";
import { getSchoolID } from "../../../shared/getSchoolID";
import { EXAM_TERMS, DATE_FORMATS } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import axios from "axios";
import moment from "moment";
const { FIRST_TERM, SECOND_TERM, BOTH_TERM } = EXAM_TERMS;

const marathiMedium = "Marathi";
const examTermOptions = [
  {
    label: "First Term",
    value: FIRST_TERM,
  },
  {
    label: "Second Term",
    value: SECOND_TERM,
  },
  {
    label: "Both (First Term + Second Term)",
    value: BOTH_TERM,
  },
];

function ProgressReport() {
  const [classData, setClassData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [currentSelectedClass, setCurrentSelectedClass] = useState(null);
  const [currentSelectedDivision, setCurrentSelectedDivision] = useState(null);
  const [filteredDivisions, setFilteredDivisions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [schoolReopeningDate, setSchoolReopeningDate] = useState(0);

  //School Term
  const [selectedTermText, setSelectedTermText] = useState(
    examTermOptions[0].label
  );
  const [isDownloadInfoShown, setIsDownloadInfoShown] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(examTermOptions[0].value);

  const navigate = useNavigate();

  const downloadOptions = [
    {
      label: "Download Single PDF",
      value: 1,
    },
    {
      label: "Download For State Board (New)",
      value: 3,
    },
        {
      label: "Download With Template",
      value: 2,
    }
  ];

  useEffect(() => {
    getSchoolDetails({
      variables: {
        schoolId: getSchoolID(),
      },
    });
  }, []);

  const { loading: classesLoading } = useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (classResponse) => {
      const classLists = [];
      const divList = [];
      const sortedClasses = getSortedClasses(classResponse.classes);
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
        classObj.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: classObj.id,
          })
        );
      });
      setClassData(classLists);
      setDivisionData(divList);
    },
  });

  const [getSchoolDetails, { loading: detailsLoading }] = useLazyQuery(
    GET_SCHOOL_DETAILS,
    {
      onCompleted: (data) => {
        const schoolData = data?.schools_by_pk;
        setSchoolDetails({
          image: schoolData?.image,
          udiseNumber: schoolData?.udise_number,
          trustName: schoolData?.trust_name,
          boardName: schoolData?.board_name,
          schoolName: schoolData?.title,
          cityName: schoolData?.address?.city?.city_name,
          stateName: schoolData?.address?.city?.district?.state?.state_name,
          email: schoolData?.email,
          mobileNumber: schoolData?.mobile_number,
          academicYear: schoolData?.acadamic_years?.[0]?.title,
          academicYearFromDate: schoolData?.acadamic_years?.[0]?.from_date,
          academicYearToDate: schoolData?.acadamic_years?.[0]?.to_date,
          medium: schoolData?.medium,
        });
      },
    }
  );

  const [getDivisionStudents, { loading: studentsLoading }] = useLazyQuery(
    GET_DIVISION_STUDENTS,
    {
      onCompleted: (data) => {
        if (data?.division_students?.length > 0) {
          handleReportDownload();
        } else {
          setIsWarningModalVisible(true);
        }
      },
    }
  );

  const handleReportDownload = () => {
    setIsLoading(true);
    if(!isDownloadInfoShown) {
      setIsDownloadInfoShown(true);
      notification["info"]({
        message: "Printing information",
        description: "Please print these pages back to back to get the correct report"
      });
    }
    const divisionId = parseInt(currentSelectedDivision.value);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/getScoreCard`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        school_id: getSchoolID(),
        divisions: [divisionId],
        term: selectedTerm,
        reopening_date: schoolReopeningDate,
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${
          currentSelectedDivision.label?.replace("Division: ", "") || ""
        }_Progress_Report`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleDownloadTemplate = (templateId) => {
    setIsLoading(true);
    if(!isDownloadInfoShown) {
      setIsDownloadInfoShown(true);
      notification["info"]({
        message: "Printing information",
        description: "Please print these pages back to back to get the correct report"
      });
    }
    const divisionId = parseInt(currentSelectedDivision.value);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/template/templatizeProgressReport`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        school_id: getSchoolID(),
        divisionId: divisionId,
        term: selectedTerm || 1,
        templateId
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${
          currentSelectedDivision.label?.replace("Division: ", "") || ""
        }_Progress_Report`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }

  const handleCurrentClassChange = (value) => {
    const filterDivsWithClassID = divisionData.filter(
      (div) => div.class_id === parseInt(value)
    );
    const className = classData.find(
      (classObj) => classObj.value === parseInt(value)
    )?.label;
    setCurrentSelectedClass({
      value,
      label: className,
    });
    setCurrentSelectedDivision(null);
    setFilteredDivisions(filterDivsWithClassID);
  };

  const handleCurrentDivisionChange = (value) => {
    const divisionLabel = filteredDivisions.find(
      (div) => div.value === parseInt(value)
    ).label;
    setCurrentSelectedDivision({
      value,
      label: divisionLabel,
    });
  };

  const handleDownload = () => {
    if (!currentSelectedDivision) {
      return;
    }
    // query school details
    getSchoolDetails({
      variables: {
        schoolId: getSchoolID(),
      },
    });
    // query student list
    getDivisionStudents({
      variables: {
        divisionId: currentSelectedDivision.value,
      },
    });
  };

  const handleDownloadSelect = (value) => {
    if (parseInt(value) === 1) {
      handleDownload();
    } else if (parseInt(value) === 2) {
      handleDownloadTemplate(1);
    } else if (parseInt(value) === 3) {
      handleDownloadTemplate(2);
    }
  };

  const handleTermChange = (value) => {
    const term = examTermOptions.find((t) => t.value == value);
    setSelectedTerm(value);
    setSelectedTermText(term.label);
  };

  return (
    <>
      <div className="flex flex-col items-start pl-8 mt-6 pr-8 pb-9 h-screen w-screen overflow-x-hidden overflow-y-auto">
        <Spin
          spinning={isLoading}
          style={{
            maxHeight: "100vh",
          }}
        >
          <div className="flex flex-row w-full items-center justify-between mb-10">
            <p className="text-darkgrey text-2xl text-left font-bold flex-col m-0">
              Progress Report
            </p>
            <Button
              id="progressreport-back"
              buttonStyle="secondary"
              onClick={() => navigate(-1)}
              className="h-10"
            >
              Back to Reports
            </Button>
          </div>
          {!currentSelectedClass || !currentSelectedDivision ? (
            <p className="text-left text-red-500 font-semibold">
              Please select class and division.
            </p>
          ) : schoolDetails && schoolDetails?.medium === marathiMedium ? (
            <p className="text-left text-red-500 font-semibold">
              टिप : Progress Report मराठी भाषेमध्ये Download होईल.
            </p>
          ) : null}
          <div className="flex w-full mt-4 justify-between">
            <div className="flex gap-x-2 h-10">
              <ClassDivisionSelector
                id={"progressreport-sortby"}
                classes={classData}
                divisions={divisionData}
                onClassChange={handleCurrentClassChange}
                onDivisionChange={handleCurrentDivisionChange}
              />
              <DatePicker
                onChange={(value) => {
                  setSchoolReopeningDate(value ? moment(value).unix() : false);
                }}
                placeholder="School Reopening Date"
                suffixIcon={null}
                format={DATE_FORMATS.DDMMMYYYY}
                style={{
                  borderRadius: "6px",
                  width: "200px",
                }}
              />
            </div>
            <div className="flex gap-x-2 h-10">
              <Dropdown
                id={"termSelection"}
                value={selectedTermText}
                defaultValue={selectedTermText}
                type="primary"
                options={examTermOptions}
                disabled={!currentSelectedDivision}
                onItemClick={handleTermChange}
              />
              <Dropdown
                id="progressreport-download"
                defaultValue={
                  <div className="flex items-center">
                    <DownloadIcon className="h-5 w-6 mr-2" />
                    <p className="font-medium text-sm">Download All</p>
                  </div>
                }
                type="success"
                options={downloadOptions}
                onItemClick={handleDownloadSelect}
                disabled={!currentSelectedDivision}
                style={{ maxHeight: 40 }}
              />
            </div>
          </div>
          <div className="mt-7 w-full h-full">
            <ReportCardView schoolDetails={schoolDetails} />
          </div>
        </Spin>
      </div>
      <Modal
        visible={isWarningModalVisible}
        centered
        closable={false}
        bodyStyle={{
          justifyContent: "center",
          overflowX: "auto",
          borderRadius: "6px",
        }}
        width={320}
        footer={null}
      >
        <div className="flex flex-col justify-center items-center text-center gap-y-5 pt-3">
          <p className="font-bold text-black text-lg">
            Since there is no student in the division, there is no data in the
            progress report.
          </p>
          <Button
            buttonStyle="primary"
            className="w-20"
            onClick={() => {
              setIsWarningModalVisible(false);
            }}
          >
            Got it
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ProgressReport;
