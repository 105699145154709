/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const createExpenseCategory = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/expenses/insertExpenseCategories`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useCreateExpenseCategory(options = {}) {
  return useMutation({
    mutationFn: (data) => createExpenseCategory(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "Expense Category Created",
        duration: 1,
      });
    },
    onError: (error) => {
      notification["error"]({
        message: "Failure",
        description: "Could not create Expense Category",
        duration: 1.5,
      });
      console.error(error);
    },
    ...options,
  });
}
