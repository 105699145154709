import React, { useState } from "react";
import Tabs, { TabPane } from "rc-tabs";
import ManageStudentList from "./ManageStudentList";
import { Badge } from "antd";

const StudentsView = ({
  studentsData,
  enableEditMode,
  setdraweropen,
  filterData,
  setFilterData,
  loading,
}) => {
  const { draftCount } = filterData;
  const handleTabChange = (key) => {
    setFilterData((filterData) => {
      return {
        ...filterData,
        selectedClass: null,
        selectedDivision: null,
        activekey: Number(key),
        search: "",
        sortType: undefined,
        sortTypeLabel: undefined,
        currentPage: 1,
        pageSize: filterData.pageSize,
      };
    });
  };

  return (
    <div>
      <div className="flex flex-row mt-4">
        <Tabs
          defaultActiveKey={filterData.activekey}
          onChange={(key) => handleTabChange(key)}
          className="custom-tabs"
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane id="manage-students-active" tab="Active" key={1}>
            {/* Condition for active student is: transfer_date is null and is_active is true */}
            <ManageStudentList
              enableEditMode={(values) => {
                enableEditMode(values);
              }}
              setdraweropen={(values) => setdraweropen(values, true)}
              studentsData={studentsData}
              filterData={filterData}
              setFilterData={setFilterData}
              loading={loading}
            />
          </TabPane>
          <TabPane id="manage-students-transferred" tab="Transferred" key={2}>
            {/* Condition for transferred: transfer_date not null and is_active is false */}
            <ManageStudentList
              enableEditMode={(values) => {
                enableEditMode(values);
              }}
              setdraweropen={(values) => setdraweropen(values, true)}
              studentsData={studentsData}
              filterData={filterData}
              setFilterData={setFilterData}
              loading={loading}
            />
          </TabPane>
          <TabPane
            tab={
              <div id="managestudents-drafts">
                Drafts{" "}
                <Badge
                  count={draftCount}
                  style={{
                    backgroundColor: "#FEE2E2",
                    color: "#991B1B",
                    border: 0,
                    boxShadow: "none",
                  }}
                />
              </div>
            }
            key={3}
          >
            {/* Condition for draft: transfer_date is not null and is_active is true */}
            <ManageStudentList
              enableEditMode={(values) => {
                enableEditMode(values);
              }}
              setdraweropen={(values) => setdraweropen(values, true)}
              studentsData={studentsData}
              filterData={filterData}
              setFilterData={setFilterData}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default StudentsView;
