import PropTypes from "prop-types";

function TextViewForValues({ data, className }) {
  return (
    <div className={`flex flex-col mt-6 ${className}`}>
      {
        data.map((element, elementIndex) => {
          return (
            <p key={elementIndex} className="text-sm leading-5 font-semibold text-gray-900">
              {element}
            </p>
          )
        })
      }
    </div>
  );
};

TextViewForValues.prototypes = {
  elementsToRender: PropTypes.array,
  style: PropTypes.style
}

export default TextViewForValues;