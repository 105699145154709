import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { ADD_CITY } from "./graphql";
import { useQuery, useMutation } from "@apollo/client";
import {
  Cascader,
  Select,
  Upload,
  message,
  notification,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { getS3Image, getS3Bucket } from "../../shared/s3";
import Switch from "../../components/Switch";
import Dropdown from "../../components/Dropdown";
import { InfoCircleFilled } from "@ant-design/icons";
import { getSchoolID } from "../../shared/getSchoolID";
import axios from "axios";
import { GET_MENU_SCHOOL_DETAILS } from "../../graphql/queries";
import { getAuthID } from "../../shared/getAuthID";
import { Radio } from "antd";
import Button from "../../components/Button";
import { validateEmail } from "../../utils/";
import {
  managementTypeList,
  categoryList,
  roleList,
  rollNoPreferenceList,
  schoolMediumList,
  DATE_FORMATS,
  SHIFTS,
} from "../../utils/constants";
import useTracking from "../../hooks/useTracking";
import useLearnAccess from "../../hooks/useLearnAccess";
import useUpdateLearnAccess from "../../hooks/useUpdateLearnAccess";
import useUpdateAcademicYear from "../../hooks/useUpdateAcademicYear";
import moment from "moment";
import useUpsertNonTeachingStaff from "../../hooks/useUpsertNonTeachingStaff";
import useGetNonTeachingStaffList from "../../hooks/useGetNonTeachingStaff";
import useIsGroupSchool from "../../hooks/useIsGroupSchool";
import useGetGroupURCAndCenters from "../../hooks/useGetGroupURCAndCenters";

const { Option } = Select;
const imagesS3Bucket = getS3Bucket();
const CREATE_NEW_CITY = "CREATE_NEW_CITY";

const CONTACT_DIGITS = 10;

const getFormattedData = (arr) => {
  return (
    arr?.map((item) => ({
      label: item,
      value: item,
    })) || []
  );
};

const FormCard = ({ children, overrideClassnames }) => {
  return (
    <>
      <div
        className={`${overrideClassnames} w-full border-2 bg-white rounded-md mb-6 pt-6`}
      >
        {children}
      </div>
    </>
  );
};

const FormCol = ({ id, children, overrideClassnames, style }) => {
  return (
    <div
      id={id}
      className={`${overrideClassnames} flex flex-col flex-auto`}
      style={style}
    >
      {children}
    </div>
  );
};
const FormRow = ({ children, overrideClassnames }) => {
  return (
    <div
      className={`${overrideClassnames} flex flex-row justify-start gap-x-8 mb-4 px-6`}
    >
      {children}
    </div>
  );
};

const FormSideSection = ({ title, description, children }) => {
  return (
    <div className="w-1/4 text-left ">
      <p className="text-lg leading-6 font-medium text-gray-900 mb-1">
        {title}
      </p>
      <p className="mt-1 text-sm leading-5 font-normal text-gray-500 w-10/12  ">
        {description}
      </p>
      {children}
    </div>
  );
};

function SettingsForm({
  updateAdminRole,
  generateRollNumber,
  updateRollno,
  settingsData,
  refetchSettings,
  createClassesInit,
  academicYearData,
  createClasses,
  isClassDetailsLoading,
  createAdmin,
  updateSchool,
  states,
  boards,
  getDistricts,
  districts,
  getCities,
  cities,
  addAddress,
  updateUserAddress,
  isSchoolDetailsLoading,
  academicYearsData,
  refetchAcademicYear,
  onPromotionsSave,
  isStudentsNotPromoted,
}) {
  const navigate = useNavigate();
  const defaultRollno = settingsData?.schools_by_pk?.roll_no_param;
  const defaultPreference = settingsData?.schools_by_pk?.preferences;

  const [schoolFormDetails, setSchoolFormDetails] = useState({});
  const [districtId, setDistrictId] = useState(null);
  const [classFormDetails, setClassFormDetails] = useState({});
  const [currentAcademicYear, setCurrentAcademicYear] = useState({});
  const [adminFormDetails, setAdminFormDetails] = useState([]);
  const [adminFormItems, setAdminFormItems] = useState([]);
  const [nonTeachingStaffItems, setNonTeachingItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [adminErrorMessage, setAdminErrorMessage] = useState("");
  const [academicYears, setAcademicYears] = useState([]);
  const [promotionFormData, setPromotionFormData] = useState({});
  const [showAdminModal, setshowAdminModal] = useState(false);
  const [nonTeachingModal, setNonTeachingModal] = useState({
    isVisible: false,
    staffData: null,
  });
  const [adminNumber, setAdminNumber] = useState(null);
  const [adminName, setAdminName] = useState("");
  const [userData, setUserData] = useState(null);
  const [defaultAdminList, setdefaultAdminList] = useState([]);
  const [generatedRollno, setGeneratedRollno] = useState(
    settingsData?.schools_by_pk?.roll_no_param
  );
  const [editRollNoDetails, setEditRollNoDetails] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState({
    schoolDetails: false,
    adminDetails: false,
    classDetails: false,
    promotionDetails: false,
    learnAccessDetails: false,
    nonTeachingStaffDetails: false,
  });
  const [learnAccessDetails, setLearnAccessDetails] = useState({});
  const [nonTeachingStaffFormDetails, setNonTeachingStaffFormDetails] =
    useState([]);
  const [selectedUrcCenterShift, setSelectedUrcCenterShift] = useState({
    urc: "",
    center: "",
    shift: "",
  });
  const [nonTeachingStaffToDelete, setNonTeachingStaffToDelete] = useState([]);
  const [adminNumbersToDelete, setAdminNumbersToDelete] = useState([]);
  const { trackEvent } = useTracking();

  // disable classes edit if already added
  const shouldDisableClasses =
    settingsData?.schools_by_pk?.school_class_inits?.length > 0;

  const schoolId = getSchoolID();

  const { data: schoolGroupData } = useIsGroupSchool();
  const groupId = schoolGroupData?.data?.groupId;
  const isGroupScool = schoolGroupData?.success;

  const { data: urcCenterShiftData } = useGetGroupURCAndCenters(
    { groupId },
    {
      select: (rawURCCenterData) => {
        const formattedCenter = getFormattedData(rawURCCenterData?.centers);
        const formattedURC = getFormattedData(rawURCCenterData?.urc);
        const formattedShifts = getFormattedData(SHIFTS);
        return {
          centers: formattedCenter,
          urc: formattedURC,
          shifts: formattedShifts,
        };
      },
    }
  );

  useQuery(GET_MENU_SCHOOL_DETAILS, {
    variables: {
      schoolID: schoolId,
      authid: getAuthID(),
    },
    onCompleted: (res) => {
      setUserData(res);
    },
  });

  const { data: learnAccessData } = useLearnAccess({
    onSuccess: (res) => {
      setLearnAccessDetails({
        ...learnAccessDetails,
        ...res,
      });
    },
  });
  const { mutateAsync: upsertNonTeachingStaff } = useUpsertNonTeachingStaff();
  const { mutateAsync: updateLearnAccess } = useUpdateLearnAccess();
  const { mutateAsync: updateAcademicYear } = useUpdateAcademicYear({
    onSuccess: (data) => {
      if (refetchAcademicYear) refetchAcademicYear();
      notification["success"]({
        message: "Success",
        description: "Academic Year Updated",
        duration: 1.5,
      });
    },
  });

  const { data: serverNonTeachingStaff, refetch: fetchNonTeachingStaff } =
    useGetNonTeachingStaffList({
      onSuccess: (nonTeachingData) => {
        setNonTeachingStaffFormDetails(nonTeachingData);
      },
    });

  const [createCity] = useMutation(ADD_CITY);

  useEffect(() => {
    if (settingsData) {
      const schoolData = settingsData?.schools_by_pk;

      setSchoolFormDetails({
        ...schoolFormDetails,
        ...defaultSchoolDetails,
      });
      setClassFormDetails({
        ...classFormDetails,
        ...defaultClassDetails,
      });
      setAdminFormDetails(schoolData?.school_admins);

      setdefaultAdminList(schoolData?.school_admins);
      setSelectedUrcCenterShift({
        urc: schoolData?.urc,
        center: schoolData?.urc_center,
        shift: schoolData?.shift,
      });
    }
  }, [settingsData]);

  useEffect(() => {
    if (academicYearsData) {
      const academicYearsList = academicYearsData.acadamic_years.map(
        (academicYear) => {
          return {
            label: academicYear.title,
            value: academicYear.id,
            isCurrent: academicYear.is_current,
          };
        }
      );
      setAcademicYears(academicYearsList);
      // find current academic year
      const academicYear = academicYearsData.acadamic_years.find(
        (academicYear) => academicYear.is_current === true
      );
      setCurrentAcademicYear(academicYear);
      setPromotionFormData({
        ...promotionFormData,
        selectedAcademicYear: {
          value: academicYear?.id,
          label: academicYear?.title,
          isCurrent: academicYear?.is_current,
        },
        studentPromotion: settingsData?.schools_by_pk?.student_promotion,
      });
    }
  }, [academicYearsData]);

  useEffect(() => {
    if (
      schoolFormDetails?.stateId != null &&
      schoolFormDetails?.districtId != null
    ) {
      getDistricts({ variables: { stateId: schoolFormDetails?.stateId } });
      getCities({ variables: { districtId: schoolFormDetails?.districtId } });
      setDistrictId(schoolFormDetails?.districtId);
    }
  }, [schoolFormDetails]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    trigger,
    setError,
    setValue,
  } = useForm({
    criteriaMode: "all",
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "contactDetails", // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  );

  const handleAcademicYearDateChange = (date, key) => {
    let academicYear = { ...(currentAcademicYear || {}) };
    academicYear[key] = date;
    setCurrentAcademicYear(academicYear);
  };

  const handleClassAndAcademicDetailsSubmit = () => {
    if (!shouldDisableClasses) {
      const classesInitData = {
        class_from: classFormDetails.classStartsFrom?.[0],
        class_to: classFormDetails.classUpto?.[0],
        divisions_each_class: classFormDetails.divisionsEachClass?.[0],
        school_id: settingsData?.schools_by_pk?.id,
      };
      let error = false;
      if (!classesInitData?.class_from) {
        notification["error"]({
          message: "Error",
          description: "Please select from class",
          duration: 1.5,
        });
        error = true;
      } else if (!classesInitData?.class_from) {
        notification["error"]({
          message: "Error",
          description: "Please select to class",
          duration: 1.5,
        });
        error = true;
      } else if (!classesInitData?.divisions_each_class) {
        notification["error"]({
          message: "Error",
          description: "Please select division each class",
          duration: 1.5,
        });
        error = true;
      }
      if (!error) {
        const academicYearId = academicYearsData?.acadamic_years?.[0]?.id;
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_NODE_ENDPOINT}/class/create/multiple`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            schoolId: schoolId,
            academicYearId: academicYearId,
            fromClass: classFormDetails.classStartsFrom?.[0],
            toClass: classFormDetails.classUpto?.[0],
            numberOfDivisions: classFormDetails.divisionsEachClass?.[0],
          },
        };
        trackEvent("Save Class Details");
        axios(config)
          .then((res) => {
            createClassesInit({
              variables: {
                classesData: classesInitData,
              },
            });
            notification["success"]({
              message: "Classes added",
              duration: 1.5,
            });
          })
          .catch((err) => {
            notification["error"]({
              message: "Something went wrong",
              description: "Please try again.",
              duration: 1.5,
            });
          });
      } else {
        setIsEditingDetails({
          ...isEditingDetails,
          classDetails: false,
        });
        return;
      }
    }
    if (currentAcademicYear?.id) {
      const fromDate = moment(currentAcademicYear?.from_date).format(
        DATE_FORMATS.YYYYMMDDHHMMSSZ
      );
      const toDate = moment(currentAcademicYear?.to_date).format(
        DATE_FORMATS.YYYYMMDDHHMMSSZ
      );
      updateAcademicYear({
        academicYearId: currentAcademicYear?.id,
        fromDate,
        toDate,
      });
    }
    setIsEditingDetails({
      ...isEditingDetails,
      classDetails: false,
    });
  };

  const classList = [
    { value: 1, label: "Class 1" },
    { value: 2, label: "Class 2" },
    { value: 3, label: "Class 3" },
    { value: 4, label: "Class 4" },
    { value: 5, label: "Class 5" },
    { value: 6, label: "Class 6" },
    { value: 7, label: "Class 7" },
    { value: 8, label: "Class 8" },
    { value: 9, label: "Class 9" },
    { value: 10, label: "Class 10" },
  ];
  const divisionsList = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
  ];

  const getSortedStates = (states) => {
    // sort data by name
    return states?.slice().sort((a, b) => {
      if (a.state_name < b.state_name) {
        return -1;
      }
      if (a.state_name > b.state_name) {
        return 1;
      }
      return 0;
    });
  };

  const getSortedDistricts = (districts) => {
    // sort data by name
    return districts?.slice().sort((a, b) => {
      if (a.district_name < b.district_name) {
        return -1;
      }
      if (a.district_name > b.district_name) {
        return 1;
      }
      return 0;
    });
  };

  let sortedDistricts, sortedCities;

  const getSortedCities = (cities) => {
    // sort data by name
    return cities?.slice().sort((a, b) => {
      if (a.city_name < b.city_name) {
        return -1;
      }
      if (a.city_name > b.city_name) {
        return 1;
      }
      return 0;
    });
  };

  const sortedStates = getSortedStates(states);
  if (districts) {
    sortedDistricts = getSortedDistricts(districts);
  }
  if (cities) {
    sortedCities = getSortedCities(cities);
  }
  const stateList = sortedStates?.map((state) => {
    return {
      value: state.id,
      label: state.state_name,
    };
  });
  const districtList = sortedDistricts?.map((district) => {
    return {
      value: district.id,
      label: district.district_name,
    };
  });
  const cityList = sortedCities?.map((city) => {
    return {
      value: city.id,
      label: city.city_name,
    };
  });

  const key = "code";

  const BoardList = [
    ...new Map(boards?.map((board) => [board[key], board])).values(),
  ];

  const defaultSchoolDetails = {
    image: settingsData?.schools_by_pk?.image,
    boardName: settingsData?.schools_by_pk?.board_name,
    medium: settingsData?.schools_by_pk?.medium,
    trustName: settingsData?.schools_by_pk?.trust_name,
    udiseNumber: settingsData?.schools_by_pk?.udise_number,
    indexNumber: settingsData?.schools_by_pk?.index_number,
    title: settingsData?.schools_by_pk?.title,
    addressLineOne: settingsData?.schools_by_pk?.address?.address_line_one,
    addressLineTwo: settingsData?.schools_by_pk?.address?.address_line_two,
    stateId: settingsData?.schools_by_pk?.address?.city?.district?.state?.id,
    stateName:
      settingsData?.schools_by_pk?.address?.city?.district?.state?.state_name,
    districtId: settingsData?.schools_by_pk?.address?.city?.district?.id,
    districtName:
      settingsData?.schools_by_pk?.address?.city?.district?.district_name,
    cityId: settingsData?.schools_by_pk?.address?.city?.id,
    cityName: settingsData?.schools_by_pk?.address?.city?.city_name,
    pinCode: settingsData?.schools_by_pk?.address?.pincode,
    mobileNumber: settingsData?.schools_by_pk?.mobile_number,
    email: settingsData?.schools_by_pk?.email,
    studentPromotion: settingsData?.schools_by_pk?.student_promotion,
    managementType: settingsData?.schools_by_pk?.management_type,
    categoryType: settingsData?.schools_by_pk?.category,
    rollnoParams: settingsData?.schools_by_pk?.roll_no_param,
    rollNoPreference: settingsData?.schools_by_pk?.preferences,
  };

  const defaultClassDetails = {
    classStartsFrom:
      settingsData?.schools_by_pk?.school_class_inits?.[0]?.class_from,
    classUpto: settingsData?.schools_by_pk?.school_class_inits?.[0]?.class_to,
    divisionsEachClass:
      settingsData?.schools_by_pk?.school_class_inits?.[0]
        ?.divisions_each_class,
  };

  const addNewCity = async () => {
    if (schoolFormDetails?.cityName !== "" && districtId !== null) {
      // capitalize first letter of city
      const cityName =
        schoolFormDetails?.cityName.charAt(0).toUpperCase() +
        schoolFormDetails?.cityName.slice(1);
      const addCityData = {
        object: {
          city_name: cityName,
          district_id: districtId,
        },
      };
      createCity({
        variables: addCityData,
      }).then((res) => {
        updateSchoolDetails({
          cityId: res.data.insert_cities_one?.id,
        });
      });
      getCities({ variables: { districtId: districtId } });
    }
  };

  const updateSchoolDetails = (options) => {
    const cityId = options?.cityId || parseInt(schoolFormDetails.cityId);
    const addressData = {
      address_line_one: schoolFormDetails.addressLineOne,
      address_line_two: schoolFormDetails.addressLineTwo,
      city_id: cityId,
      pincode: schoolFormDetails.pinCode,
    };
    const schoolData = {
      title: schoolFormDetails.title,
      address_id: settingsData?.schools_by_pk?.address?.id,
      board_name: schoolFormDetails.boardName,
      board_id: schoolFormDetails.boardId,
      settings: schoolFormDetails?.boardSettings,
      trust_name: schoolFormDetails.trustName,
      udise_number: schoolFormDetails.udiseNumber,
      index_number: schoolFormDetails.indexNumber,
      medium: schoolFormDetails.medium,
      mobile_number: schoolFormDetails.mobileNumber,
      email: schoolFormDetails.email,
      management_type: schoolFormDetails.managementType,
      category: schoolFormDetails.categoryType,
      urc: selectedUrcCenterShift?.urc || settingsData?.schools_by_pk?.urc,
      urc_center:
        selectedUrcCenterShift?.center ||
        settingsData?.schools_by_pk?.urc_center,
      shift:
        selectedUrcCenterShift?.shift || settingsData?.schools_by_pk?.shift,
    };

    let errorMessage = null;

    if (schoolData?.mobile_number?.length >= 1) {
      if (schoolData?.mobile_number?.length !== 10) {
        errorMessage = "Phone Number should be of 10 digits";
      }
      const isNAN = Number.isNaN(parseInt(schoolData?.mobile_number, 10));
      if (isNAN === true) {
        errorMessage = "Phone Number is not a number";
      }
    }

    if (schoolData?.email?.length >= 1 && !validateEmail(schoolData?.email)) {
      errorMessage = "Please enter valid email";
    }

    if (errorMessage) {
      notification["error"]({
        message: "Validation Error",
        description: errorMessage,
        duration: 1.5,
      });
      return false;
    }

    if (settingsData?.schools_by_pk?.address?.id) {
      schoolData.address_id = settingsData?.schools_by_pk?.address?.id;
      // update address
      updateUserAddress({
        variables: {
          addressId: settingsData?.schools_by_pk?.address?.id,
          addressData: addressData,
        },
      });
      updateSchool({
        variables: {
          schoolId: settingsData?.schools_by_pk?.id,
          schoolData: schoolData,
        },
      }).then((response) => {
        notification["success"]({
          message: "Updated values",
          description: `for school ${settingsData?.schools_by_pk?.title}`,
          duration: 2,
        });
      });
    } else {
      // add address and add to user
      addAddress({
        variables: {
          addressData: addressData,
        },
      }).then((response) => {
        const addressId = response?.data?.insert_addresses_one?.id;
        updateSchool({
          variables: {
            schoolId: settingsData?.schools_by_pk?.id,
            schoolData: {
              ...schoolData,
              address_id: addressId,
            },
          },
        }).then((response) => {
          notification["success"]({
            message: "Updated values",
            description: `for school ${settingsData?.schools_by_pk?.title}`,
            duration: 2,
          });
        });
      });
    }
    setIsEditingDetails({
      ...isEditingDetails,
      schoolDetails: false,
    });
  };

  const handleSchoolDetailsSubmit = () => {
    // set error message for empty school title
    if (!schoolFormDetails.title) {
      setErrorMessage("Please fill school name");
      return;
    }
    if (!schoolFormDetails.boardName) {
      setErrorMessage("Please select the Board");
      return;
    }
    if (!schoolFormDetails.medium) {
      setErrorMessage("Please select the school medium");
      return;
    }
    trackEvent("Save School Details");
    // clear error message
    setErrorMessage("");
    let cityId = null;
    if (schoolFormDetails.cityId === CREATE_NEW_CITY) {
      // create new city then update school
      addNewCity();
    } else {
      cityId = parseInt(schoolFormDetails.cityId);
      const editted = updateSchoolDetails({ cityId });
      if (editted === false) return;
    }
    setIsEditingDetails({
      ...isEditingDetails,
      schoolDetails: false,
    });
  };

  const validateStaffDetails = (staffMember) => {
    const incompleteName = staffMember?.full_name?.trim() === "";
    const contactLength = staffMember?.mobile_number?.length;
    const isValidContact =
      contactLength === 0 || contactLength === CONTACT_DIGITS;

    if (incompleteName) {
      notification["error"]({
        message: "Wrong Data",
        description: "Name cannot be empty",
        duration: 3,
      });
      return false;
    }

    if (!isValidContact) {
      notification["error"]({
        message: "Wrong Data",
        description: `Add ${CONTACT_DIGITS} digits or remove mobile number for ${staffMember?.full_name}`,
        duration: 3,
      });
      return false;
    }
    return true;
  };

  const handleUpsertNonTeaching = async () => {
    let isUpdateValid = true;
    const updatedNonTeachingStaff = nonTeachingStaffFormDetails?.map(
      (staffMember) => {
        isUpdateValid = isUpdateValid && validateStaffDetails(staffMember);
        return {
          id: staffMember?.id,
          full_name: staffMember?.full_name?.trim(),
          mobile_number:
            staffMember?.mobile_number?.length > 3
              ? `+91${staffMember?.mobile_number}`
              : "",
          school_id: schoolId,
        };
      }
    );

    const createNonTeachingStaff = nonTeachingStaffItems?.map((staffMember) => {
      isUpdateValid = isUpdateValid && validateStaffDetails(staffMember);
      const newStaffMember = {
        ...staffMember,
        school_id: schoolId,
        mobile_number:
          staffMember?.mobile_number?.length > 3
            ? `+91${staffMember?.mobile_number}`
            : "",
      };
      delete newStaffMember["id"];
      delete newStaffMember["schoolId"];
      return newStaffMember;
    });

    const payload = {
      nonTeachingStaffData: [
        ...updatedNonTeachingStaff,
        ...createNonTeachingStaff,
      ],
    };

    if (isUpdateValid) {
      const deletePayload = {
        nonTeachingStaffData: nonTeachingStaffToDelete,
      };
      upsertNonTeachingStaff(deletePayload);
      await upsertNonTeachingStaff(payload);
      resetNonTeachingForm();
    }
  };

  const handleRemoveContact = async (mobileNumber) => {
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/delete/user`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        phone: mobileNumber,
        deleteRole: "school_admin",
      },
    };

    try {
      const res = await axios(config);
      const { data } = res;
      if (data.success === true) {
        notification["success"]({
          message: `${data?.message}`,
          duration: 1.5,
        });
      } else if (data.success === false) {
        notification["error"]({
          message: "Something went wrong",
          description: `${data?.message}`,
          duration: 1.5,
        });
      }
    } catch {
      notification["error"]({
        message: "Something went wrong",
        description: "Please try again.",
        duration: 1.5,
      });
    }
  };

  const deleteAdmins = async () => {
    const res = await Promise.allSettled(
      adminNumbersToDelete.map(
        async (contactNumber) => await handleRemoveContact(contactNumber)
      )
    );
    return res;
  };

  const handleCreateAdminSubmit = async () => {
    await deleteAdmins();
    setAdminNumbersToDelete([]);
    handleEditAdminSubmit();
    setAdminErrorMessage("");
    if (adminFormItems.length > 0) {
      if (adminFormItems[0].contactNumber === "") {
        setAdminErrorMessage("Please fill admin contact");
        return;
      }
      if (adminFormItems[0].contactNumber.length !== 10) {
        setAdminErrorMessage("Please fill correct contact");
        return;
      }
    }
    trackEvent("Save Admin Details");
    const filteredFormItems = adminFormItems.filter(
      (admin) => admin.contactNumber !== ""
    );
    const adminList = filteredFormItems.map((item) => {
      return {
        fullName: item.schoolAdminName,
        contactNumber: item.contactNumber,
        role: item.adminRole ? item.adminRole : null,
      };
    });
    if (adminList.length > 0) {
      const admins = await createAdmin(adminList);
      if (admins) {
        admins.forEach((admin) => {
          adminFormDetails.push({
            user: {
              first_name: admin.first_name,
              mobile_number: admin.phone,
            },
            admin_type: admin.admin_type,
          });
        });
        setAdminFormDetails(adminFormDetails);
        setAdminFormItems([]);
        setIsEditingDetails({
          ...isEditingDetails,
          adminDetails: false,
        });
      }      
    }else{
      setIsEditingDetails({
        ...isEditingDetails,
        adminDetails: false,
      });
    }    
    // window.location.reload();
  };

  const handleNonTeachingStaffDelete = () => {
    const staffToDelete = nonTeachingModal.staffData;
    const id = staffToDelete?.id;
    const deletePayload = {
      id,
      is_active: false,
      full_name: staffToDelete?.full_name,
      school_id: staffToDelete?.school_id,
    };
    const filteredNonTeachingStaff = nonTeachingStaffFormDetails.filter(
      (staff) => staff?.id !== id
    );
    setNonTeachingStaffFormDetails(filteredNonTeachingStaff);
    setNonTeachingStaffToDelete((prevStaff) => [...prevStaff, deletePayload]);
    setNonTeachingModal({
      isVisible: false,
      staffData: null,
    });
  };

  const handleLocalRemoveContact = (mobileNumber) => {
    setAdminNumbersToDelete((prevState) => [...prevState, mobileNumber]);
    setAdminFormDetails((prevState) =>
      prevState?.filter((admin) => admin?.user?.mobile_number !== mobileNumber)
    );
  };

  const resetSchoolDetailsForm = () => {
    setSchoolFormDetails({
      ...schoolFormDetails,
      ...defaultSchoolDetails,
    });
    setIsEditingDetails({
      ...isEditingDetails,
      schoolDetails: false,
    });
    const schoolData = settingsData?.schools_by_pk;
    setSelectedUrcCenterShift({
      urc: schoolData?.urc,
      center: schoolData?.urc_center,
      shift: schoolData?.shift,
    });
  };

  const resetAdminDetailsForm = () => {
    setAdminErrorMessage("");
    setAdminFormItems([]);
    setAdminFormDetails(settingsData?.schools_by_pk?.school_admins);
    setIsEditingDetails({
      ...isEditingDetails,
      adminDetails: false,
    });
  };

  const resetNonTeachingForm = () => {
    setAdminErrorMessage("");
    setNonTeachingItems([]);
    setNonTeachingStaffFormDetails(serverNonTeachingStaff);
    setIsEditingDetails({
      ...isEditingDetails,
      nonTeachingStaffDetails: false,
    });
    setNonTeachingStaffToDelete([]);
    fetchNonTeachingStaff();
  };

  const resetClassDetailsForm = () => {
    setClassFormDetails({
      ...classFormDetails,
      ...defaultClassDetails,
    });
    setIsEditingDetails({
      ...isEditingDetails,
      classDetails: false,
    });
  };

  const handleUpload = async (file, onSuccess) => {
    const fileId = uuid();
    // get file extension
    const fileExtension = file.name.split(".").pop();
    const key = `${process.env.REACT_APP_S3_BUCKET_FOLDER}/${fileId}.${fileExtension}`;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
    };
    imagesS3Bucket.putObject(params).send((err, data) => {
      if (err) {
        // handle the error here
        console.error("s3 err", err);
      } else {
        // handle the success here
        onSuccess(data.response, file);
        //  store file id in school instead of image url
        const imageKey = `${fileId}.${fileExtension}`;
        // update image in school
        updateSchool({
          variables: {
            schoolId: settingsData?.schools_by_pk?.id,
            schoolData: {
              image: imageKey,
            },
          },
        });
      }
    });
  };

  const renderImage = () => {
    if (schoolFormDetails.image) {
      const url = getS3Image(schoolFormDetails.image);
      return (
        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
          <img className="h-full w-full object-cover" src={url} alt="avatar" />
        </span>
      );
    }
    return (
      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  };

  const loadingButtonClass = isSchoolDetailsLoading ? "opacity-20" : "";
  const classesLoadingClass = isClassDetailsLoading ? "opacity-20" : "";

  const uploadProps = {
    customRequest({ file, onSuccess }) {
      return handleUpload(file, onSuccess);
    },
  };

  const handleAcademicYearChange = (value) => {
    const academicYear = academicYears.find(
      (item) => item.value === parseInt(value)
    );
    setPromotionFormData({
      ...promotionFormData,
      selectedAcademicYear: {
        value: academicYear?.value,
        label: academicYear?.label,
        isCurrent: academicYear?.isCurrent,
      },
    });
  };

  const handlePromotionSwitchChange = (value) => {
    setPromotionFormData({
      ...promotionFormData,
      studentPromotion: value,
    });
  };

  const handlePromotionsFormSubmit = () => {
    trackEvent("Save Promotion Details");
    onPromotionsSave({
      isPromotion: promotionFormData.studentPromotion,
      academicYear: promotionFormData.selectedAcademicYear,
    });
    setIsEditingDetails({
      ...isEditingDetails,
      promotionDetails: false,
    });
  };

  const handlePromotionCancel = () => {
    const currentAcademicYear = academicYearsData.acadamic_years.find(
      (academicYear) => academicYear.is_current === true
    );
    setPromotionFormData({
      ...promotionFormData,
      selectedAcademicYear: {
        value: currentAcademicYear?.id,
        label: currentAcademicYear?.title,
        isCurrent: currentAcademicYear?.is_current,
      },
      studentPromotion: settingsData?.schools_by_pk?.student_promotion,
    });
    setIsEditingDetails({
      ...isEditingDetails,
      promotionDetails: false,
    });
  };

  const submitRollNo = () => {
    trackEvent("Save Roll No Details");
    const rollno = schoolFormDetails.rollnoParams;
    const rollPreference = schoolFormDetails.rollNoPreference;
    updateRollno({
      variables: {
        schoolId: settingsData?.schools_by_pk?.id,
        roll_no_param: rollno,
        preferences: rollPreference,
      },
    });

    generateRollNumber();
    setEditRollNoDetails(false);
  };

  const cancelRollNo = () => {
    setGeneratedRollno(defaultRollno);
    setEditRollNoDetails(false);
    setSchoolFormDetails({
      ...schoolFormDetails,
      rollNoPreference: defaultPreference,
    });
  };

  const handleEditAdminSubmit = () => {
    adminFormDetails.forEach((admin) => {
      let id2 = defaultAdminList.find(
        (x) => x.school_admin_id === admin.school_admin_id
      );
      if (admin.admin_type !== id2.admin_type) {
        updateAdminRole({
          variables: {
            school_admin_id: admin.school_admin_id,
            admin_type: admin.admin_type,
          },
        }).then((response) => {
          notification["success"]({
            message: "Updated Admin Role",
            description: `for school ${settingsData?.schools_by_pk?.title}`,
            duration: 2,
          });
        });
      }
    });
  };

  const handleUpdateLearnAccess = (checked) => {
    // set has_learn_access to true for all teachers if checked is true else false
    const updatedTeacherList = learnAccessDetails?.teacherList?.map(
      (teacher) => {
        return {
          ...teacher,
          has_learn_access: checked,
        };
      }
    );
    setLearnAccessDetails({
      ...learnAccessDetails,
      schoolAccessOverall: checked,
      teacherList: updatedTeacherList,
    });
  };

  const handleLearnAccessCancel = () => {
    setLearnAccessDetails({
      ...learnAccessDetails,
      ...learnAccessData,
    });
    setIsEditingDetails({
      ...isEditingDetails,
      learnAccessDetails: false,
    });
  };

  const handleUpdateTeacherAccess = (authId) => {
    const teacher = learnAccessDetails?.teacherList?.find(
      (teacher) => teacher.auth_id === authId
    );
    const teacherIndex = learnAccessDetails?.teacherList?.findIndex(
      (teacher) => teacher.auth_id === authId
    );
    const updatedTeacher = {
      ...teacher,
      has_learn_access: !teacher.has_learn_access,
    };
    const updatedTeacherList = [
      ...learnAccessDetails?.teacherList?.slice(0, teacherIndex),
      updatedTeacher,
      ...learnAccessDetails?.teacherList?.slice(teacherIndex + 1),
    ];
    setLearnAccessDetails({
      ...learnAccessDetails,
      teacherList: updatedTeacherList,
    });
  };

  const handleSaveLearnAccessUpdate = () => {
    const allowAccessList = learnAccessDetails?.teacherList
      ?.filter((teacher) => teacher.has_learn_access === true)
      ?.map((teacher) => teacher.auth_id);
    const disAllowAccessList = learnAccessDetails?.teacherList
      ?.filter((teacher) => teacher.has_learn_access === false)
      ?.map((teacher) => teacher.auth_id);
    updateLearnAccess({
      allowAccessList,
      disAllowAccessList,
    });
    setIsEditingDetails({
      ...isEditingDetails,
      learnAccessDetails: false,
    });
  };

  const handleNonTeachingStaffChange = (e, id) => {
    setNonTeachingStaffFormDetails((prevState) => {
      const newStaffDetails = prevState?.map((currentStaff) => {
        if (parseInt(currentStaff.id) !== parseInt(id)) {
          return currentStaff;
        }
        const staffData = { ...currentStaff, [e.target.name]: e.target.value };
        return staffData;
      });
      return newStaffDetails;
    });
  };

  const styles = {
    schoolDetailsStyle: {
      color: isEditingDetails.schoolDetails ? "#000000" : "#b8b8b8",
      backgroundColor: isEditingDetails.schoolDetails ? "#ffffff" : "#f5f5f5",
    },
    adminDetailsStyle: {
      color: isEditingDetails.adminDetails ? "#000000" : "#b8b8b8",
      backgroundColor: isEditingDetails.adminDetails ? "#ffffff" : "#f5f5f5",
    },
  };

  const handleDelete = (id) => {
    const updatedItems = nonTeachingStaffItems.filter((item) => item.id !== id);
    setNonTeachingItems(updatedItems);
  };
  const handleDelete1 = (id) => {
    const updatedItems = adminFormItems.filter((item) => item.id !== id);
    setAdminFormItems(updatedItems);
  };

  return (
    <form>
      <div className="flex">
        <FormSideSection
          title="School Details"
          description="Information about the school and points of contact"
        />
        <FormCard>
          <FormRow>
            <FormCol>
              <div>
                <label className="block text-sm font-bold text-gray-700 text-left">
                  School Logo
                </label>
                <div className="mt-1 flex items-center">
                  {renderImage()}
                  <Upload
                    disabled={!isEditingDetails.schoolDetails}
                    {...uploadProps}
                    beforeUpload={(file) => {
                      // check if file is an jpeg or png
                      if (
                        file.type !== "image/jpeg" &&
                        file.type !== "image/png"
                      ) {
                        message.error("Only jpeg or png images are allowed!");
                        return false;
                      }
                      // check if file size is less than 2MB
                      if (file.size > 2000000) {
                        message.error("File size should be less than 2MB!");
                        return false;
                      }
                      return true;
                    }}
                    showUploadList={false}
                    className={`${
                      !isEditingDetails.schoolDetails
                        ? "cursor-default"
                        : "cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-50"
                    } ml-5 bg-white py-2 px-3 rounded-md text-sm leading-4 font-medium`}
                  >
                    <Button
                      disabled={!isEditingDetails.schoolDetails}
                      className={`${
                        !isEditingDetails.schoolDetails
                          ? "text-gray-300"
                          : " text-gray-700"
                      }`}
                      id="settings-upload"
                      style={{
                        borderRadius: 6,
                        fontWeight: "bold",
                        height: 30,
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </div>
              </div>
            </FormCol>
            <FormCol id={"settings-schoolboard"} overrideClassnames="w-60">
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                School Board
              </label>
              <Select
                className="customised-antd-selector-controlled"
                defaultValue={schoolFormDetails.boardName}
                value={schoolFormDetails.boardName}
                onChange={(e) => {
                  const boardName = BoardList.find((x) => x.id === e).name;
                  const boardSettings = BoardList.find(
                    (x) => x.id === e
                  ).settings;
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    boardId: e,
                    boardName: boardName,
                    boardSettings: boardSettings,
                  });
                }}
                size="large"
                placeholder={"Board Name"}
                disabled={settingsData?.schools_by_pk?.board_id}
              >
                {BoardList.map((board) => {
                  return (
                    <Option key={board?.id} value={board?.id}>
                      {board.name}
                    </Option>
                  );
                })}
              </Select>
            </FormCol>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                School Medium
              </label>
              <Select
                className="customised-antd-selector-controlled"
                disabled={
                  !isEditingDetails.schoolDetails ||
                  schoolFormDetails.medium?.length > 0
                }
                defaultValue={schoolFormDetails.medium}
                value={schoolFormDetails.medium}
                onChange={(e) => {
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    medium: e,
                  });
                }}
                size="large"
              >
                {schoolMediumList.map((medium) => {
                  return (
                    <Option key={medium?.value} value={medium?.value}>
                      {medium.label}
                    </Option>
                  );
                })}
              </Select>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                School Name
              </label>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-schoolname"
                type="text"
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.title}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    title: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                Trust Name
              </label>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-trustname"
                type="text"
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.trustName}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    trustName: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                U-DISE Number
              </label>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-udise"
                type="text"
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.udiseNumber}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    udiseNumber: e.target.value,
                  })
                }
              />
            </FormCol>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                Index Number
              </label>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-index-number"
                type="text"
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.indexNumber}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    indexNumber: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <div className="flex flex-row justify-start gap-x-8 mb-1 px-6">
            <label className="text-gray-700 font-bold text-sm leading-5  ">
              School Address
            </label>
          </div>
          <FormRow>
            <FormCol>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-address1"
                type="text"
                placeholder="Address Line 1"
                required
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.addressLineOne}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    addressLineOne: e.target.value,
                  })
                }
              />
            </FormCol>
            <FormCol>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-address2"
                type="text"
                placeholder="Address Line 2"
                required
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.addressLineTwo}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    addressLineTwo: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <div id="settings-state" className="flex flex-col">
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!isEditingDetails.schoolDetails}
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails?.stateName}
                  value={schoolFormDetails?.stateName}
                  labelInValue
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(val) => {
                    getDistricts({ variables: { stateId: val?.value } });
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      stateId: val.value,
                      stateName: val.label,
                      districtId: null,
                      districtName: null,
                      cityId: null,
                      cityName: null,
                    });
                  }}
                  size="large"
                  placeholder={"State"}
                >
                  {stateList?.map((state) => (
                    <Option key={state?.value} value={state?.value}>
                      {state?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </FormCol>
            <FormCol>
              <div id="settings-district" className="flex flex-col">
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!isEditingDetails.schoolDetails}
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails.districtName}
                  value={schoolFormDetails.districtName}
                  labelInValue
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(val) => {
                    setDistrictId(val.value);
                    getCities({ variables: { districtId: val.value } });
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      districtId: val.value,
                      districtName: val.label,
                      cityId: null,
                      cityName: null,
                    });
                  }}
                  size="large"
                  placeholder={"District"}
                >
                  {districtList?.map((district) => (
                    <Option key={district.value}>{district.label}</Option>
                  ))}
                </Select>
              </div>
            </FormCol>
            <FormCol>
              <div id="settings-city" className="flex flex-col">
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!isEditingDetails.schoolDetails}
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails.cityName}
                  value={schoolFormDetails.cityName}
                  size="large"
                  placeholder={"City"}
                  showSearch
                  labelInValue
                  optionFilterProp="children"
                  onChange={(val) => {
                    let label = val.label;
                    if (val.value === CREATE_NEW_CITY) {
                      label = val.key;
                    }
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      cityId: val.value,
                      cityName: label,
                    });
                  }}
                  onSearch={(e) => {
                    const pascalCaseCity =
                      e.charAt(0).toUpperCase() + e.slice(1);
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      citySearchValue: pascalCaseCity,
                    });
                  }}
                >
                  {schoolFormDetails?.citySearchValue &&
                    cityList?.filter(
                      (city) =>
                        city.label?.toLowerCase() ===
                        schoolFormDetails?.citySearchValue?.toLowerCase()
                    ).length === 0 && (
                      <Option
                        key={schoolFormDetails?.citySearchValue}
                        value={CREATE_NEW_CITY}
                      >
                        {"Add New City " +
                          `"${schoolFormDetails?.citySearchValue}"`}
                      </Option>
                    )}
                  {cityList?.map((city) => (
                    <Option key={city?.value} value={city?.value}>
                      {city?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </FormCol>
          </FormRow>
          <FormRow overrideClassnames="grid grid-cols-3">
            <FormCol overrideClassnames="col-span-1">
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-pincode"
                type="text"
                placeholder="Pin Code"
                required
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.pinCode}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    pinCode: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>

          <FormRow>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                Email Address
              </label>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-email-address"
                type="text"
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.email}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    email: e.target.value,
                  })
                }
              />
            </FormCol>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                Mobile Number
              </label>
              <input
                disabled={!isEditingDetails.schoolDetails}
                id="settings-index-number"
                type="text"
                style={styles.schoolDetailsStyle}
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.mobileNumber}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    mobileNumber: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>

          <FormRow overrideClassnames="grid grid-cols-2">
            <FormCol overrideClassnames="col-span-1">
              <div id="settings-management" className="flex flex-col">
                <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                  School Management Type
                </label>
                <Select
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!isEditingDetails.schoolDetails}
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails?.managementType}
                  value={schoolFormDetails?.managementType}
                  onChange={(value) =>
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      managementType: value,
                    })
                  }
                  size="large"
                  placeholder={"Select"}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {managementTypeList?.map((type) => (
                    <Option key={type?.value}>{type?.label}</Option>
                  ))}
                </Select>
              </div>
            </FormCol>

            <FormCol overrideClassnames="col-span-1">
              <div id="settings-category" className="flex flex-col">
                <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                  School Category
                </label>
                <Select
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!isEditingDetails.schoolDetails}
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails?.categoryType}
                  value={schoolFormDetails?.categoryType}
                  onChange={(value) =>
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      categoryType: value,
                    })
                  }
                  size="large"
                  placeholder={"Select"}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {categoryList?.map((type) => (
                    <Option key={type?.value}>{type?.label}</Option>
                  ))}
                </Select>
              </div>
            </FormCol>
          </FormRow>
          {isGroupScool && (
            <FormRow overrideClassnames="flex flex-1">
              <div className="flex flex-1">
                <div id="urc-settings" className="flex flex-col w-full">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                    URC
                  </label>
                  <Select
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled={!isEditingDetails.schoolDetails}
                    className="customised-antd-selector-controlled"
                    defaultValue={selectedUrcCenterShift?.urc}
                    value={selectedUrcCenterShift?.urc}
                    onChange={(value) =>
                      setSelectedUrcCenterShift({
                        ...selectedUrcCenterShift,
                        urc: value,
                      })
                    }
                    size="large"
                    placeholder={"Select"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {urcCenterShiftData?.urc?.map((type) => (
                      <Option key={type?.value}>{type?.label}</Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex flex-1">
                <div id="center-settings" className="flex flex-col w-full">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                    Center
                  </label>
                  <Select
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled={!isEditingDetails.schoolDetails}
                    className="customised-antd-selector-controlled"
                    defaultValue={selectedUrcCenterShift?.center}
                    value={selectedUrcCenterShift?.center}
                    onChange={(value) =>
                      setSelectedUrcCenterShift({
                        ...selectedUrcCenterShift,
                        center: value,
                      })
                    }
                    size="large"
                    placeholder={"Select"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {urcCenterShiftData?.centers?.map((type) => (
                      <Option key={type?.value}>{type?.label}</Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex flex-1">
                <div id="center-settings" className="flex flex-col w-full">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                    Shift
                  </label>
                  <Select
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled={!isEditingDetails.schoolDetails}
                    className="customised-antd-selector-controlled"
                    defaultValue={selectedUrcCenterShift?.shift}
                    value={selectedUrcCenterShift?.shift}
                    onChange={(value) =>
                      setSelectedUrcCenterShift({
                        ...selectedUrcCenterShift,
                        shift: value,
                      })
                    }
                    size="large"
                    placeholder={"Select"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {urcCenterShiftData?.shifts?.map((type) => (
                      <Option key={type?.value}>{type?.label}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            </FormRow>
          )}
          <FormRow overrideClassnames="justify-end">
            {errorMessage && (
              <div className="text-red-500 text-sm">{errorMessage}</div>
            )}
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            {isEditingDetails.schoolDetails ? (
              <div className="flex gap-x-3">
                <Button
                  id="settings-schooldetails-cancel"
                  onClick={() => resetSchoolDetailsForm()}
                  className="px-5"
                  buttonStyle="default"
                >
                  <span>Cancel</span>
                </Button>

                <Button
                  id="settings-schooldetails-save"
                  onClick={handleSchoolDetailsSubmit}
                  type="button"
                  buttonStyle="primary"
                  className="px-5"
                >
                  <span>{isSchoolDetailsLoading ? "Loading..." : "Save"}</span>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsEditingDetails({
                      ...isEditingDetails,
                      schoolDetails: !isEditingDetails.schoolDetails,
                    });
                  }}
                >
                  <span>Edit Details</span>
                </Button>
              </div>
            )}
          </FormRow>
        </FormCard>
      </div>
      <div className="flex">
        <FormSideSection title="Academic Details" description="School setup" />
        <FormCard>
          <FormRow>
            <FormCol id={"settings-academic-year"} style={{ width: "33%" }}>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <>
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Academic Year
                    </label>
                    <Cascader
                      {...field}
                      register={register}
                      className="customised-antd-cascader-controlled"
                      size="large"
                      options={academicYears}
                      disabled
                      value={promotionFormData?.selectedAcademicYear?.label}
                      defaultValue
                      style={{ width: "93%" }}
                    />
                  </>
                )}
              />
            </FormCol>
            <FormCol id={"settings-start-date"} style={{ width: "33%" }}>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <>
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      School Start Date
                    </label>
                    <DatePicker
                      {...field}
                      disabled={!isEditingDetails.classDetails}
                      value={moment(currentAcademicYear?.from_date)}
                      format={DATE_FORMATS.DDMMMYYY}
                      style={{
                        height: "40px",
                        width: "93%",
                      }}
                      className="customAntdDatePicker"
                      onChange={(date) =>
                        handleAcademicYearDateChange(date, "from_date")
                      }
                    />
                  </>
                )}
              />
            </FormCol>
            <FormCol id={"settings-end-date"} style={{ width: "33%" }}>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <>
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      School End Date
                    </label>
                    <DatePicker
                      {...field}
                      disabled={!isEditingDetails.classDetails}
                      value={moment(currentAcademicYear?.to_date)}
                      format={DATE_FORMATS.DDMMMYYY}
                      style={{
                        height: "40px",
                        width: "93%",
                      }}
                      className="customAntdDatePicker"
                      onChange={(date) =>
                        handleAcademicYearDateChange(date, "to_date")
                      }
                    />
                  </>
                )}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol id={"settings-startsfrom"} style={{ width: "33%" }}>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <>
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Class starts from
                    </label>
                    <Cascader
                      {...field}
                      register={register}
                      className="customised-antd-cascader-controlled"
                      size="large"
                      style={{ width: "93%" }}
                      options={classList}
                      value={value}
                      disabled={
                        shouldDisableClasses || !isEditingDetails.classDetails
                      }
                      defaultValue={classFormDetails.classStartsFrom}
                      onChange={(value) => {
                        setClassFormDetails({
                          ...classFormDetails,
                          classStartsFrom: value,
                        });
                      }}
                      placeholder={
                        shouldDisableClasses
                          ? classFormDetails?.classStartsFrom
                          : "Please select"
                      }
                      rules={{ required: true }}
                    />
                  </>
                )}
              />
            </FormCol>
            <FormCol id={"settings-upto"} style={{ width: "33%" }}>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <>
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Class upto
                    </label>
                    <Cascader
                      {...field}
                      size="large"
                      style={{ width: "93%" }}
                      className="customised-antd-cascader-controlled"
                      register={register}
                      options={classList}
                      disabled={
                        shouldDisableClasses || !isEditingDetails.classDetails
                      }
                      defaultValue={classFormDetails.classUpto}
                      onChange={(value) => {
                        setClassFormDetails({
                          ...classFormDetails,
                          classUpto: value,
                        });
                      }}
                      placeholder={
                        shouldDisableClasses
                          ? classFormDetails.classUpto
                          : "Please select"
                      }
                      rules={{ required: true }}
                    />
                  </>
                )}
              />
            </FormCol>
            <FormCol id={"settings-classdivision"} style={{ width: "33%" }}>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <>
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Divisions in each class
                    </label>
                    <Cascader
                      {...field}
                      register={register}
                      className="customised-antd-cascader-controlled"
                      size="large"
                      options={divisionsList}
                      disabled={
                        shouldDisableClasses || !isEditingDetails.classDetails
                      }
                      style={{ width: "93%" }}
                      defaultValue={classFormDetails.divisionsEachClass}
                      onChange={(value) => {
                        setClassFormDetails({
                          ...classFormDetails,
                          divisionsEachClass: value,
                        });
                      }}
                      placeholder={
                        shouldDisableClasses
                          ? classFormDetails.divisionsEachClass
                          : "Please select"
                      }
                      rules={{ required: true }}
                    />
                  </>
                )}
              />
            </FormCol>
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            {isEditingDetails.classDetails ? (
              <div className={`flex gap-x-3`}>
                <Button
                  id="settings-classdetails-cancel"
                  type="button"
                  onClick={() => resetClassDetailsForm()}
                  className="px-5"
                  buttonStyle="default"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  id="settings-classdetails-save"
                  type="button"
                  onClick={handleClassAndAcademicDetailsSubmit}
                  className="px-5"
                  buttonStyle="primary"
                >
                  <span>{isClassDetailsLoading ? "Loading..." : "Save"}</span>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  disabled={
                    isClassDetailsLoading || isEditingDetails.classDetails
                  }
                  buttonStyle="primary"
                  onClick={() => {
                    setIsEditingDetails({
                      ...isEditingDetails,
                      classDetails: !isEditingDetails.classDetails,
                    });
                  }}
                >
                  <span>Edit Details</span>
                </Button>
              </div>
            )}
          </FormRow>
        </FormCard>
      </div>
      <div className="flex">
        <FormSideSection
          title="Admin Details"
          description="Information about admin"
        />
        <FormCard>
          {adminFormDetails.length > 0 &&
            adminFormDetails.map((admin, index) => {
              const nameId = "settings-adminname-" + index;
              const contactId = "settings-admincontact-" + index;
              return (
                <FormRow overrideClassnames="grid grid-cols-7">
                  <FormCol overrideClassnames="col-span-2 ">
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      School Admin Name
                    </label>
                    <input
                      disabled
                      id={nameId}
                      type="text"
                      style={{ color: "#b8b8b8", backgroundColor: "#f5f5f5" }}
                      className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                      value={admin.user.first_name}
                    />
                  </FormCol>
                  <FormCol overrideClassnames="col-span-2">
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Contact Number
                    </label>
                    <input
                      disabled
                      id={contactId}
                      type="text"
                      style={{ color: "#b8b8b8", backgroundColor: "#f5f5f5" }}
                      className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                      value={admin?.user?.mobile_number?.slice(3)}
                    />
                  </FormCol>
                  <FormCol overrideClassnames="col-span-2">
                    <div id="settings-role" className="flex flex-col ">
                      <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left">
                        Role
                      </label>
                      <Select
                        disabled={
                          admin.admin_type != null
                            ? true
                            : isEditingDetails.adminDetails
                            ? false
                            : true
                        }
                        className="customised-antd-selector-controlled"
                        defaultValue={admin.admin_type}
                        value={admin.admin_type}
                        onChange={(e) => {
                          setAdminFormDetails([
                            ...adminFormDetails.map((item) => {
                              if (
                                item.school_admin_id === admin.school_admin_id
                              ) {
                                return {
                                  ...item,
                                  admin_type: e,
                                };
                              }
                              return item;
                            }),
                          ]);
                        }}
                        size="large"
                        placeholder={"Select"}
                      >
                        {roleList?.map((type) => (
                          <Option key={type?.value}>{type?.label}</Option>
                        ))}
                      </Select>
                    </div>
                  </FormCol>
                  <FormCol overrideClassnames="col-span-1">
                    {showAdminModal ? (
                      <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-md">
                          <div className="relative w-auto my-6 mx-auto max-w-3xl h-48">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center justify-center w-full h-full bg-white outline-none focus:outline-none">
                              <div className="flex items-center pl-5 pr-5 pb-7 justify-center rounded-t">
                                <h3 className="text-xl font-semibold">
                                  <p> Are you sure you want to remove </p>
                                  <span className="text-red-500">
                                    {" "}
                                    {adminName}{" "}
                                  </span>{" "}
                                  as a admin?
                                </h3>
                              </div>
                              <div className="flex items-center justify-center rounded-b">
                                <Button
                                  buttonStyle="primary"
                                  onClick={(e) => {
                                    setshowAdminModal(false);
                                  }}
                                  style={{
                                    background: "#EF4444",
                                    height: 30,
                                  }}
                                >
                                  No
                                </Button>
                                <Button
                                  buttonStyle="primary"
                                  onClick={(e) => {
                                    setshowAdminModal(false);
                                    handleLocalRemoveContact(adminNumber);
                                  }}
                                  style={{
                                    background: "#22C55E",
                                    height: 30,
                                    marginLeft: 25,
                                  }}
                                >
                                  Yes
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="opacity-5 fixed inset-0 z-40 bg-black"></div>
                      </>
                    ) : null}
                    <button
                      disabled={
                        !isEditingDetails.adminDetails ||
                        admin?.user?.mobile_number ===
                          userData?.school_admins[0]?.user?.mobile_number
                      }
                      type="button"
                      onClick={() => {
                        setAdminNumber(admin.user.mobile_number);
                        admin.user.last_name == null
                          ? setAdminName(`${admin.user.first_name}`)
                          : setAdminName(
                              `${admin.user.first_name} ${admin.user.last_name}`
                            );
                        setshowAdminModal(true);
                      }}
                      className={`${
                        isEditingDetails.adminDetails &&
                        admin?.user?.mobile_number !==
                          userData?.school_admins[0]?.user?.mobile_number
                          ? "py-3 mt-6 w-20 flex justify-center items-center text-white bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none rounded-md "
                          : "py-3 mt-6 w-20 flex justify-center items-center bg-red-100 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none rounded-md"
                      }`}
                    >
                      <span>Remove</span>
                    </button>
                  </FormCol>
                </FormRow>
              );
            })}
          {adminFormItems.map((item) => {
            return (
              <FormRow overrideClassnames="grid grid-cols-7">
                <FormCol overrideClassnames="col-span-2 ">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                    School Admin Name
                  </label>
                  <input
                    id="settings-adminname"
                    type="text"
                    className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={item.schoolAdminName}
                    onChange={(e) =>
                      setAdminFormItems([
                        ...adminFormItems.map((admin) => {
                          if (admin.id === item.id) {
                            return {
                              ...admin,
                              schoolAdminName: e.target.value,
                            };
                          }
                          return admin;
                        }),
                      ])
                    }
                  />
                </FormCol>
                <FormCol overrideClassnames="col-span-2">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                    Contact Number
                  </label>
                  <input
                    id="settings-admincontact"
                    type="text"
                    required
                    className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={item.contactNumber}
                    maxLength={10}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setAdminFormItems([
                        ...adminFormItems.map((admin) => {
                          if (admin.id === item.id) {
                            return {
                              ...admin,
                              contactNumber: value,
                            };
                          }
                          return admin;
                        }),
                      ]);
                    }}
                  />
                </FormCol>
                <FormCol overrideClassnames="col-span-2">
                  <div id="settings-role" className="flex flex-col">
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Role
                    </label>
                    <Select
                      className="customised-antd-selector-controlled"
                      value={item.adminRole}
                      onChange={(e) => {
                        setAdminFormItems([
                          ...adminFormItems.map((admin) => {
                            if (admin.id === item.id) {
                              return {
                                ...admin,
                                adminRole: e,
                              };
                            }
                            return admin;
                          }),
                        ]);
                      }}
                      size="large"
                      placeholder={"Select"}
                    >
                      {roleList?.map((type) => (
                        <Option key={type?.value}>{type?.label}</Option>
                      ))}
                    </Select>
                  </div>
                </FormCol>
                <FormCol>
                  <Button
                    onClick={() => handleDelete1(item.id)}
                    className="px-5 my-5 w-20"
                    buttonStyle="default"
                    id="settings-admindetails-cancel-row"
                  >
                    <span>Cancel</span>
                  </Button>
                </FormCol>
              </FormRow>
            );
          })}

          <FormRow>
            <Button
              disabled={!isEditingDetails.adminDetails}
              id="settings-addnewadmin"
              onClick={() => {
                setAdminFormItems([
                  ...adminFormItems,
                  {
                    id: adminFormItems.length + 1,
                    schoolAdminName: "",
                    contactNumber: "",
                    adminRole: "",
                  },
                ]);
              }}
              buttonStyle="default"
            >
              Add new contact (Optional)
            </Button>
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            {adminErrorMessage && (
              <div className="text-red-500 text-sm">{adminErrorMessage}</div>
            )}
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            {isEditingDetails.adminDetails ? (
              <div className="flex gap-x-3">
                <Button
                  onClick={resetAdminDetailsForm}
                  id="settings-admindetails-cancel"
                  className="px-5"
                  buttonStyle="default"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  id="settings-admindetails-save"
                  onClick={handleCreateAdminSubmit}
                  type="button"
                  className="px-5"
                  buttonStyle="primary"
                >
                  <span>Save</span>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsEditingDetails({
                      ...isEditingDetails,
                      adminDetails: !isEditingDetails.adminDetails,
                    });
                  }}
                >
                  <span>Edit Details</span>
                </Button>
              </div>
            )}
          </FormRow>
        </FormCard>
      </div>

      <div className="flex">
        <FormSideSection
          title="Non-Teaching Staff Details"
          description="Information about non-teaching staff"
        />
        <FormCard>
          {nonTeachingStaffFormDetails.length > 0 &&
            nonTeachingStaffFormDetails.map((nonTeachingStaff, index) => {
              const nameId = "settings-non-teaching-staff-" + index;
              const contactId = "settings-non-teaching-staff-" + index;
              return (
                <FormRow overrideClassnames="grid grid-cols-7">
                  <FormCol overrideClassnames="col-span-2 ">
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Name
                    </label>
                    <input
                      disabled={!isEditingDetails.nonTeachingStaffDetails}
                      id={nameId}
                      name="full_name"
                      type="text"
                      style={
                        isEditingDetails.nonTeachingStaffDetails
                          ? {}
                          : { color: "#b8b8b8", backgroundColor: "#f5f5f5" }
                      }
                      className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                      value={nonTeachingStaff?.full_name}
                      onChange={(e) =>
                        handleNonTeachingStaffChange(e, nonTeachingStaff.id)
                      }
                    />
                  </FormCol>
                  <FormCol overrideClassnames="col-span-2">
                    <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                      Contact Number
                    </label>
                    <input
                      disabled={!isEditingDetails.nonTeachingStaffDetails}
                      id={contactId}
                      type="number"
                      style={
                        isEditingDetails.nonTeachingStaffDetails
                          ? {}
                          : { color: "#b8b8b8", backgroundColor: "#f5f5f5" }
                      }
                      name="mobile_number"
                      className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                      value={nonTeachingStaff?.mobile_number}
                      onChange={(e) => {
                        if (e.target.value.length > CONTACT_DIGITS) {
                          return;
                        }
                        handleNonTeachingStaffChange(e, nonTeachingStaff.id);
                      }}
                    />
                  </FormCol>
                  <FormCol overrideClassnames="col-span-1">
                    <button
                      disabled={!isEditingDetails.nonTeachingStaffDetails}
                      type="button"
                      onClick={() => {
                        setAdminNumber(nonTeachingStaff?.mobile_number);
                        setAdminName(nonTeachingStaff?.full_name);
                        setNonTeachingModal({
                          isVisible: true,
                          staffData: nonTeachingStaff,
                        });
                      }}
                      className={`${
                        isEditingDetails?.nonTeachingStaffDetails
                          ? "py-3 mt-6 w-20 flex justify-center items-center text-white bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none rounded-md "
                          : "py-3 mt-6 w-20 flex justify-center items-center bg-red-100 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none rounded-md"
                      }`}
                    >
                      <span>Remove</span>
                    </button>
                  </FormCol>
                </FormRow>
              );
            })}

          {nonTeachingStaffItems.map((item) => {
            return (
              <FormRow overrideClassnames="grid grid-cols-7">
                <FormCol overrideClassnames="col-span-2 ">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                    Name
                  </label>
                  <input
                    id="settings-non-teaching-staff-name"
                    type="text"
                    className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={item?.full_name}
                    onChange={(e) =>
                      setNonTeachingItems([
                        ...nonTeachingStaffItems.map((staff) => {
                          if (staff.id === item.id) {
                            return {
                              ...staff,
                              full_name: e.target.value,
                            };
                          }
                          return staff;
                        }),
                      ])
                    }
                  />
                </FormCol>
                <FormCol overrideClassnames="col-span-2">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                    Contact Number
                  </label>
                  <input
                    id="settings-non-teaching-staff-contact"
                    type="text"
                    required
                    className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={item?.mobile_number}
                    onChange={(e) =>
                      setNonTeachingItems([
                        ...nonTeachingStaffItems.map((staff) => {
                          if (
                            staff.id === item.id &&
                            e.target.value.length <= CONTACT_DIGITS
                          ) {
                            return {
                              ...staff,
                              mobile_number: e.target.value,
                            };
                          }
                          return staff;
                        }),
                      ])
                    }
                  />
                </FormCol>

                <FormCol>
                  <Button
                    onClick={() => handleDelete(item.id)}
                    className="px-5 my-5 w-20"
                    buttonStyle="default"
                    id="settings-non-teaching-staff-cancel-row"
                  >
                    <span>Cancel</span>
                  </Button>
                </FormCol>
              </FormRow>
            );
          })}

          <FormRow>
            <Button
              disabled={!isEditingDetails.nonTeachingStaffDetails}
              id="settings-add-new-non-teaching-staff"
              onClick={() => {
                setNonTeachingItems([
                  ...nonTeachingStaffItems,
                  {
                    id: nonTeachingStaffItems.length + 1,
                    full_name: "",
                    mobile_number: "",
                    schoolId,
                  },
                ]);
              }}
              buttonStyle="default"
            >
              Add new member (Optional)
            </Button>
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            {adminErrorMessage && (
              <div className="text-red-500 text-sm">{adminErrorMessage}</div>
            )}
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            {isEditingDetails?.nonTeachingStaffDetails ? (
              <div className="flex gap-x-3">
                <Button
                  onClick={resetNonTeachingForm}
                  id="settings-non-teaching-staff-cancel"
                  className="px-5"
                  buttonStyle="default"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  id="settings-non-teaching-staff-save"
                  onClick={handleUpsertNonTeaching}
                  type="button"
                  className="px-5"
                  buttonStyle="primary"
                >
                  <span>Save</span>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsEditingDetails({
                      ...isEditingDetails,
                      nonTeachingStaffDetails:
                        !isEditingDetails.nonTeachingStaffDetails,
                    });
                  }}
                >
                  <span>Edit Details</span>
                </Button>
              </div>
            )}
          </FormRow>
        </FormCard>
      </div>

      <div className="flex">
        <FormSideSection
          title="Generate Roll Number"
          description="Setup roll number sequence"
        />
        <FormCard overrideClassnames="flex flex-col">
          <FormRow>
            <FormCol>
              <label className="block text-sm font-bold text-gray-700 mb-1 text-left">
                Based on
              </label>
              <Radio.Group
                className="self-start"
                defaultValue={generatedRollno}
                value={generatedRollno}
                onChange={(e) => {
                  setGeneratedRollno(e.target.value);
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    rollnoParams: e.target.value,
                  });
                }}
              >
                <Radio disabled={!editRollNoDetails} key="1" value="first_name">
                  First Name
                </Radio>
                <Radio disabled={!editRollNoDetails} key="2" value="last_name">
                  Last Name
                </Radio>
                <Radio disabled={!editRollNoDetails} key="3" value="gr_number">
                  General Register Number
                </Radio>
              </Radio.Group>
            </FormCol>
          </FormRow>
          <FormRow overrideClassnames="grid grid-cols-3">
            <FormCol overrideClassnames="col-span-1">
              <div id="settings-preference" className="flex flex-col">
                <label className="block text-sm font-bold text-gray-700 mb-1 text-left">
                  Preference
                </label>
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!editRollNoDetails}
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails?.rollNoPreference}
                  value={schoolFormDetails?.rollNoPreference}
                  onChange={(value) =>
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      rollNoPreference: value,
                    })
                  }
                  size="large"
                  placeholder={"Select"}
                >
                  {rollNoPreferenceList?.map((type) => (
                    <Option key={type?.value}>{type?.label}</Option>
                  ))}
                </Select>
              </div>
            </FormCol>
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            {editRollNoDetails ? (
              <div className="flex gap-x-3">
                <Button
                  disabled={!editRollNoDetails}
                  onClick={cancelRollNo}
                  className="px-5"
                  buttonStyle="default"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  disabled={!editRollNoDetails}
                  buttonStyle="primary"
                  onClick={submitRollNo}
                >
                  <span>{"Generate"}</span>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  buttonStyle="primary"
                  onClick={() => setEditRollNoDetails(!editRollNoDetails)}
                >
                  <span>Edit Details</span>
                </Button>
              </div>
            )}
          </FormRow>
        </FormCard>
      </div>
      <div className="flex">
        <FormSideSection
          title="Get Ready for the Next Year!"
          description="Promote students"
        />
        <FormCard overrideClassnames="flex flex-col">
          <FormRow>
            <FormCol>
              <div className="flex items-center whitespace-nowrap">
                <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left mr-14">
                  Student Promotion
                </label>
                <Switch
                  disabled={!isEditingDetails.promotionDetails}
                  id="settings-studentpromotion"
                  type="default"
                  checked={promotionFormData.studentPromotion}
                  onChange={handlePromotionSwitchChange}
                />
              </div>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <div className="flex items-center whitespace-nowrap">
                <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left mr-7">
                  Current Academic Year
                </label>
                <Dropdown
                  className={`${
                    isEditingDetails.promotionDetails
                      ? "cursor-pointer"
                      : "cursor-default"
                  }`}
                  yearId={"settings-currentyear"}
                  value={promotionFormData?.selectedAcademicYear?.label}
                  type="default"
                  options={academicYears}
                  onItemClick={handleAcademicYearChange}
                  disabled={isStudentsNotPromoted}
                />
              </div>
            </FormCol>
          </FormRow>
          <FormRow>
            <div className="flex gap-x-2 bg-gray-100 text-red-500 rounded-md font-medium py-2 px-2">
              <InfoCircleFilled
                style={{
                  color: "#EB5757",
                  fontSize: "16px",
                }}
              />
              <p className="text-left">
                You can change the current academic year only when the student
                promotion activity is <br /> completed for all the divisions. To
                know the current status, go to Manage Classes.
              </p>
            </div>
          </FormRow>
          <div className="flex flex-col gap-y-3 border-t bg-white w-full mt-3 mb-4 h-3/4 pl-6 items-start justify-start">
            <div className="flex flex-col gap-y-16 mt-6 text-left">
              <div className="flex gap-x-5">
                <span className="font-semibold text-lg text-success whitespace-nowrap">
                  Step 1 :
                </span>
                <p className="font-medium text-base text-gray-500">
                  Select the next academic year in{" "}
                  <button onClick={() => navigate("/class")}>
                    <strong className="underline">Manage Classes </strong>
                  </button>{" "}
                  and click on{" "}
                  <strong>Import classes from previous year</strong> button.
                </p>
              </div>
              <div className="flex gap-x-5">
                <span className="font-semibold text-lg text-success whitespace-nowrap">
                  Step 2 :
                </span>
                <p className="font-medium text-base text-gray-500">
                  Enable <strong>Student Promotion</strong> in{" "}
                  <strong className="underline">Settings</strong> and click on{" "}
                  <strong>Save</strong> button. After that the{" "}
                  <button
                    onClick={() => {
                      if (settingsData?.schools_by_pk?.student_promotion) {
                        navigate("/students-promotion");
                      }
                    }}
                  >
                    <strong className="underline">Promote Students</strong>
                  </button>{" "}
                  section will be visible in the main menu. <br /> Click on it
                  to promote students.
                </p>
              </div>
              <div className="flex gap-x-5 items-center">
                <span className="font-semibold text-lg text-success whitespace-nowrap">
                  Step 3 :
                </span>
                <p className="font-medium text-base text-gray-500">
                  Change the <strong>Current Academic Year</strong> from the{" "}
                  <strong className="underline">Settings</strong>. Select the
                  latest academic year.
                </p>
              </div>
            </div>
          </div>
          <FormRow overrideClassnames="justify-end">
            {isEditingDetails.promotionDetails ? (
              <div className="flex gap-x-3">
                <Button
                  id="settings-promotestudents-cancel"
                  onClick={handlePromotionCancel}
                  className="px-5"
                  buttonStyle="default"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  id="settings-promotestudents-save"
                  buttonStyle="primary"
                  onClick={handlePromotionsFormSubmit}
                  className="px-5"
                >
                  <span>{"Save"}</span>
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsEditingDetails({
                      ...isEditingDetails,
                      promotionDetails: !isEditingDetails.promotionDetails,
                    });
                  }}
                >
                  <span>Edit Details</span>
                </Button>
              </div>
            )}
          </FormRow>
        </FormCard>
      </div>
      {localStorage.getItem("isSuperAdmin") ||
      localStorage.getItem("IsRegionalAdmin") ? (
        <div className="flex">
          <FormSideSection
            title="Teach Better"
            description="Access to the video content"
          />
          <FormCard overrideClassnames="flex flex-col">
            <FormRow>
              <FormCol>
                <div className="flex items-center whitespace-nowrap ">
                  <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left mr-14">
                    Allow teacher to see video content in GroundUp Teacher
                    Connect App
                  </label>
                  <Switch
                    disabled={!isEditingDetails.learnAccessDetails}
                    type="default"
                    checked={learnAccessDetails?.schoolAccessOverall}
                    onChange={handleUpdateLearnAccess}
                  />
                </div>
              </FormCol>
            </FormRow>
            {learnAccessDetails?.schoolAccessOverall && (
              <FormRow>
                <FormCol>
                  <div className="grid grid-cols-2 gap-x-10 gap-y-4 items-center whitespace-nowrap border-t border-greyBorder pt-6">
                    {learnAccessDetails?.teacherList?.map((teacher) => {
                      return (
                        <div className="flex justify-between">
                          <label>{`${teacher?.first_name} ${
                            teacher?.middle_name || ""
                          } ${teacher?.last_name || ""}`}</label>
                          <Switch
                            disabled={!isEditingDetails.learnAccessDetails}
                            type="default"
                            checked={teacher?.has_learn_access}
                            onChange={() => {
                              handleUpdateTeacherAccess(teacher?.auth_id);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </FormCol>
              </FormRow>
            )}
            <FormRow overrideClassnames="justify-end">
              {isEditingDetails.learnAccessDetails ? (
                <div className="flex gap-x-3">
                  <Button
                    onClick={handleLearnAccessCancel}
                    className="px-5"
                    buttonStyle="default"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    buttonStyle="primary"
                    onClick={handleSaveLearnAccessUpdate}
                    className="px-5"
                  >
                    <span>{"Save"}</span>
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    buttonStyle="primary"
                    onClick={() => {
                      setIsEditingDetails({
                        ...isEditingDetails,
                        learnAccessDetails:
                          !isEditingDetails.learnAccessDetails,
                      });
                    }}
                  >
                    <span>Edit Details</span>
                  </Button>
                </div>
              )}
            </FormRow>
          </FormCard>
        </div>
      ) : null}
      {nonTeachingModal.isVisible ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-md">
            <div className="relative w-auto my-6 mx-auto max-w-3xl h-48">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center justify-center w-full h-full bg-white outline-none focus:outline-none">
                <div className="flex items-center pl-5 pr-5 pb-7 justify-center rounded-t">
                  <h3 className="text-xl font-semibold">
                    <p> Are you sure you want to remove </p>
                    <span className="text-red-500"> {adminName} </span> as a
                    non-teaching staff?
                  </h3>
                </div>
                <div className="flex items-center justify-center rounded-b">
                  <Button
                    buttonStyle="primary"
                    onClick={(e) => {
                      setNonTeachingModal({
                        isVisible: false,
                        staffData: null,
                      });
                    }}
                    style={{
                      background: "#EF4444",
                      height: 30,
                    }}
                  >
                    No
                  </Button>
                  <Button
                    buttonStyle="primary"
                    onClick={handleNonTeachingStaffDelete}
                    style={{
                      background: "#22C55E",
                      height: 30,
                      marginLeft: 25,
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-5 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </form>
  );
}

export default SettingsForm;