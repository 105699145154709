import { gql } from "@apollo/client";

export const CREATE_SCHOOL = gql`
mutation createSchool($schoolDetails: schools_insert_input!) {
  insert_schools_one(object: $schoolDetails) {
    id
    image
    title
    board_name
    mobile_number
    category
    management_type
    address {
      id
      address_line_one
      address_line_two
      pincode
      city {
        id
        city_name
        district {
          id
          district_name
          state {
            id
            state_name
          }
        }
      }
    }
    acadamic_years(where: {from_date: {_lte: "now()"}, to_date: {_gte: "now()"}}) {
      from_date
      to_date
      title
      id
    }
  }
}
`;
