/* eslint-disable no-console */
import { notification } from "antd";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const notifyForBalances = async (payload) => {
  const token = localStorage.getItem("token");
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/notificationForFeeBalance`,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...payload,
    },
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useNotificationForFeeBalance(payload, options) {
  return useQuery(
    ["notificationForBalance"],
    () => notifyForBalances(payload),
    {
      enabled: payload?.feeBalanceData?.length > 0,
      onSuccess: () => {
        notification["success"]({
          message: "Reminder Sent!",
          description: "Notification is to send to selected parent",
          duration: 1.5,
        });
      },
      ...options,
    }
  );
}
