import { useState } from "react";
import { containsNonLatinCodepoints } from "../utils";

const googleTransliterate = require("../utils/googleInputTool");

// transliterate text
const getTransliteratedText = async (text, inputCode) => {
  if (!text || !inputCode || inputCode === "en" || text === "-") {
    return text;
  }
  let sourceText = text;
  let inputLanguage = inputCode;
  let maxResult = 8;
  let request = new XMLHttpRequest();
  const multiText = sourceText.split(" ");
  try {
    if (multiText.length > 1) {
      // call googleTransliterate for each word
      let finalWord = "";
      for (const word of multiText) {
        if (word && word?.length > 0) {
          if(containsNonLatinCodepoints(word)){
            finalWord += `${word} `;
          }else{
            const response = await googleTransliterate(
              request,
              word,
              inputLanguage,
              maxResult
            );
            let resWord = response?.[0]?.[0] || word || "";
            finalWord += resWord + " ";
          }          
        }
      }
      return finalWord;
    } else {
      const textData = await googleTransliterate(
        request,
        sourceText,
        inputLanguage,
        maxResult
      );
      return textData?.[0]?.[0];
    }
  } catch (error) {
    console.error({ error });
    return text;
  }
};

const useTransliteration = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async ({ textObj, inputCode }) => {
    try {
      const textObject = {};
      const keys = Object.keys(textObj);
      for (const key of keys) {
        const transliteratedText = await getTransliteratedText(
          textObj[key],
          inputCode
        );
        textObject[key] = transliteratedText;
      }
      setData(textObject);
      return textObject;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return [fetchData, { data, error, loading, refetch: fetchData }];
};

export default useTransliteration;
