/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const getHolidayStatus = async (payload) => {
  const schoolId = getSchoolID();
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/isHoliday`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: { ...payload, schoolId },
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useCheckStaffHoliday(payload, options = {}) {
  return useQuery(
    ["staffHoliday", payload?.date],
    () => getHolidayStatus(payload),
    options
  );
}
