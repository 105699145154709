import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DownloadIcon } from "@heroicons/react/outline";
import { Spin } from "antd";
import { Button, ClassDivisionSelector, Dropdown } from "../../../components";
import Tabs, { TabPane } from "rc-tabs";
import StudentsTable from "./StudentsTable";
import { useQuery } from "@apollo/client";
import {
  GET_STUDENT_FILTERS,
  GET_STUDENT_REPORT_STUDENTS,
  GET_CURRENT_ACADEMIC_YEAR,
} from "../../../graphql/queries";
import { getSchoolID } from "../../../shared/getSchoolID";
import { getSortedClasses } from "../../../helpers";
import axios from "axios";
import moment from "moment";
import StudentsStatsTable from "../../Dashboard/StudentsStatsTable";
import useGetStudentAttendanceDetails from "../../../hooks/useGetStudentAttendanceDetails";
import { cloneDeep } from "lodash";
import { DATE_FORMATS } from "../../../utils/constants";

const mergeAttendanceDataAndStudentsData = (students, attendanceData) => {
  if (!attendanceData) return students;
  const studentAttendanceData = new Map();
  attendanceData?.forEach(({ studentId, totalDays, totalPresentDays }) => {
    studentAttendanceData.set(studentId, { totalDays, totalPresentDays });
  });
  const studentsList = cloneDeep(students);
  studentsList?.forEach((student) => {
    const { id } = student;
    const data = studentAttendanceData.get(id);
    student.totalDays = data?.totalDays || 0;
    student.totalPresentDays = data?.totalPresentDays || 0;
  });
  return studentsList;
};

function StudentReport() {
  const [activeTab, setActiveTab] = useState(1);
  const [classes, setClasses] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [religions, setReligions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [attendanceMonths, setAttendanceMonths] = useState([]);
  const [selectedAttendanceMonth, setSelectedAttendanceMonth] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const genderOptions = [
    { value: "", label: "All" },
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "T", label: "Transgender" },
    { value: "NA", label: "NA" },
  ];
  const genderLabels = {
    M: "Male",
    F: "Female",
    T: "Transgender",
    NA: "NA",
  };
  const [attendaneDetailsPayload, setAttendanceDetailsPayload] = useState([]);

  const { loading: classesLoading } = useQuery(GET_STUDENT_FILTERS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (res) => {
      const classLists = [];
      const divList = [];
      const sortedClasses = getSortedClasses(res.classes);
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
        classObj.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: classObj.id,
          })
        );
      });
      const religionList = res?.religions?.map((item) => ({
        value: item.religion,
        label: item.religion,
      }));
      religionList.unshift({ value: "", label: "All" });
      const categoryList = res?.categories?.map((item) => ({
        value: item.category,
        label: item.category,
      }));
      categoryList.unshift({ value: "", label: "All" });
      setClasses(classLists);
      setDivisions(divList);
      setReligions(religionList);
      setCategories(categoryList);
    },
  });

  const { data: academicYearData } = useQuery(GET_CURRENT_ACADEMIC_YEAR, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (res) => {
      const fromDate = moment(
        res?.schools_by_pk?.acadamic_years?.[0]?.from_date,
        DATE_FORMATS.YYYYMMDD
      );
      const toDate = moment();
      // create an array of months between two dates
      const months = [{ value: "", label: "All" }];
      let month = fromDate.clone();
      while (month.isSameOrBefore(toDate, "month")) {
        months.push({
          value: month.format(DATE_FORMATS.YYYYMMDD),
          label: month.format(DATE_FORMATS.MMMM),
        });
        month = month.add(1, "month");
      }
      setAttendanceMonths(months);
    },
  });

  const {
    loading: studentsLoading,
    data: studentsData,
    fetchMore: fetchMoreStudents,
  } = useQuery(GET_STUDENT_REPORT_STUDENTS, {
    variables: {
      limit: 7,
      offset: 0,
      schoolId: getSchoolID(),
      studentFilter: {
        school_id: {
          _eq: getSchoolID(),
        },
        is_draft: {
          _eq: false,
        },
        is_active: {
          _eq: true,
        },
        division_id: {
          _eq:
            selectedDivision?.value !== null
              ? selectedDivision?.value
              : undefined,
        },
        division: {
          acadamic_year: {
            is_active: {
              _eq: true,
            },
            is_current: {
              _eq: true,
            },
          },
          class_id: {
            _eq:
              selectedClass?.value !== null ? selectedClass?.value : undefined,
          },
        },
        religion: {
          _eq: selectedReligion?.value,
        },
        gender: {
          _eq: selectedGender?.value,
        },
        category: {
          _eq: selectedCategory?.value,
        },
      },
    },
  });

  const { data: studentsAttendanceData } = useGetStudentAttendanceDetails(
    attendaneDetailsPayload
  );

  useEffect(() => {
    if (!studentsData) return;
    const divisionsWithStudentIds = studentsData?.students?.reduce(
      (accumulatedStudents, { division, id: studentId }) => {
        const { id } = division;
        if (accumulatedStudents?.[id]) {
          accumulatedStudents[id].push(studentId);
        } else {
          accumulatedStudents[id] = [studentId];
        }
        return accumulatedStudents;
      },
      {}
    );
    const divisionIds = Object.keys(divisionsWithStudentIds);
    const formattedPayload = divisionIds?.map((divisionId) => ({
      divisionId,
      studentIds: divisionsWithStudentIds[divisionId],
    }));
    const currentYear = academicYearData?.schools_by_pk?.acadamic_years?.[0];
    setAttendanceDetailsPayload({
      divisionWithStudents: formattedPayload,
      startDate: currentYear?.from_date,
      endDate: currentYear?.to_date,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsData]);

  const handleCurrentClassChange = (value) => {
    if (value === "All") {
      setSelectedClass(null);
    } else {
      setSelectedClass({
        value,
        label: value,
      });
    }
    setSelectedDivision(null);
  };

  const handleCurrentDivisionChange = (value) => {
    if (value === "All") {
      setSelectedDivision(null);
    } else {
      setSelectedDivision({
        value,
        label: value,
      });
    }
  };

  const handleReligionSelect = (value) => {
    if (!value) {
      setSelectedReligion(null);
    } else {
      setSelectedReligion({
        value,
        label: `Religion: ${value}`,
      });
    }
  };

  const handleGenderSelect = (value) => {
    if (!value) {
      setSelectedGender(null);
    } else {
      setSelectedGender({
        value,
        label: `Gender: ${value}`,
      });
    }
  };

  const handleCategorySelect = (value) => {
    if (!value) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory({
        value,
        label: `Category: ${value}`,
      });
    }
  };

  const handleAttendanceSelect = (value) => {
    let startDate = null;
    let endDate = null;
    if (!value) {
      const currentYear = academicYearData?.schools_by_pk?.acadamic_years?.[0];
      startDate = currentYear?.from_date;
      endDate = currentYear?.to_date;
      setSelectedAttendanceMonth(null);
    } else {
      const currentMonth = moment(value);
      startDate = value;
      endDate = currentMonth.endOf("month").format(DATE_FORMATS.YYYYMMDD);
      setSelectedAttendanceMonth({
        value: value,
        label: `Attendance: ${currentMonth.format(DATE_FORMATS.MMMM)}`,
      });
    }
    setAttendanceDetailsPayload({
      ...attendaneDetailsPayload,
      startDate,
      endDate,
    });
  };

  const handleReportDownload = () => {
    setIsLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getStudentListXl`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        studentStatsVariable: {
          where: {
            school_id: {
              _eq: getSchoolID(),
            },
            class_id: {
              _eq: selectedClass?.value,
            },
            division_id: { _eq: selectedDivision?.value },
            religion: {
              _eq: selectedReligion?.value,
            },
          },
        },
        attendanceData: {
          currentAcademicYear:
            academicYearData?.schools_by_pk?.acadamic_years?.[0],
          selectedMonth: selectedAttendanceMonth?.value,
        },
        studentListVariables: {
          schoolId: getSchoolID(),
          studentFilter: {
            division: {
              acadamic_year: {
                is_active: {
                  _eq: true,
                },
                is_current: {
                  _eq: true,
                },
              },
              class_id: {
                _eq: selectedClass?.value,
              },
            },
            is_draft: {
              _eq: false,
            },
            is_active: {
              _eq: true,
            },
            school_id: {
              _eq: getSchoolID(),
            },
            category: {
              _eq: selectedCategory?.value,
            },
            religion: {
              _eq: selectedReligion?.value,
            },
            gender: {
              _eq: selectedGender?.value,
            },
            division_id: {
              _eq: selectedDivision?.value,
            },
          },
        },
        cellColorFilter: {
          category: selectedCategory?.value,
          gender: selectedGender?.value,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `Students_Details_Report`;
        const docFormat = "xlsx";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-start px-8 mt-6 pb-9 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row w-full items-center justify-between mb-10">
        <p className="text-darkgrey text-2xl text-left font-bold flex-col m-0">
          Student Details
        </p>
        <Button
          id="progressreport-back"
          buttonStyle="secondary"
          onClick={() => navigate(-1)}
          className="h-10"
        >
          Back to Reports
        </Button>
      </div>
      <div className="flex w-full mt-4 justify-between">
        <div className="flex gap-x-5 h-10">
          <ClassDivisionSelector
            id={"studentsreport-sortby"}
            classes={classes}
            divisions={divisions}
            onClassChange={handleCurrentClassChange}
            onDivisionChange={handleCurrentDivisionChange}
            allOptionNeeded
          />
          <Dropdown
            type="primary"
            defaultValue="Religion: All"
            value={selectedReligion?.label}
            options={religions}
            onItemClick={handleReligionSelect}
          />
          <Dropdown
            type="primary"
            defaultValue="Category: All"
            value={selectedCategory?.label}
            options={categories}
            onItemClick={handleCategorySelect}
          />
          <Dropdown
            type="primary"
            defaultValue="Gender: All"
            value={selectedGender?.label}
            options={genderOptions}
            onItemClick={handleGenderSelect}
          />
          <Dropdown
            type="primary"
            defaultValue="Attendance"
            value={selectedAttendanceMonth?.label}
            options={attendanceMonths}
            onItemClick={handleAttendanceSelect}
          />
        </div>
        <Button
          id="studentreport-download"
          buttonStyle="success"
          onClick={handleReportDownload}
          className="w-40 h-10"
        >
          <DownloadIcon className="h-5 w-6 mr-2" />
          Download Excel
        </Button>
      </div>
      <div className="mt-5 w-full h-full flex flex-row relative">
        <Tabs
          activeKey={activeTab.toString()}
          onChange={(key) => setActiveTab(Number(key))}
          className="custom-tabs h-full"
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane tab="Student List" key={1} className="h-full">
            <Spin spinning={classesLoading || studentsLoading || isLoading}>
              <StudentsTable
                divisionStudents={mergeAttendanceDataAndStudentsData(
                  studentsData?.students,
                  studentsAttendanceData
                )}
                fetchMoreStudents={fetchMoreStudents}
                pageLimit={7}
                totalStudents={
                  studentsData?.filterStudentCount?.aggregate?.count
                }
              />
            </Spin>
          </TabPane>
          <TabPane tab="Student Statistics" key={2}>
            <div className="bg-white mt-5 pt-8 px-9 rounded-lg shadow-sm">
              <StudentsStatsTable
                selectedClass={selectedClass}
                selectedDivision={selectedDivision}
                selectedReligion={selectedReligion}
                genderFilterData={genderLabels[selectedGender?.value]}
                categoryFilterData={selectedCategory?.value?.toLowerCase()}
              />
            </div>
          </TabPane>
        </Tabs>
        <div className="absolute right-10 flex gap-x-10">
          <p className="whitespace-nowrap">
            Selected Students:{" "}
            <strong>
              {studentsData?.filterStudentCount?.aggregate?.count || 0}
            </strong>
          </p>
          <p className="whitespace-nowrap">
            Total Students:{" "}
            <strong>
              {studentsData?.totalStudentCount?.aggregate?.count || 0}
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default StudentReport;
