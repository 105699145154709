import React from "react";
import { Pagination } from "antd";
import StaffBadge from "../../../components/StaffBadge";
import { getAvatar } from "../../../shared/getAvatar";
import { getFirstAndLastName, getGender } from "../../../helpers";
import { DATE_FORMATS } from "../../../utils/constants";

function StaffTable({
  staffData,
  totalStaff,
  pageLimit,
  setDrawer,
  currentPage,
  setCurrentPage,
  month,
}) {
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const displayStaff = (staffs) => {
    if (staffs?.length > 0) {
      return staffs;
    }
    return [];
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal w-24"
                    >
                      Sr. No.
                    </th>
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Contact Number
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Gender
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Attendance ({month.format(DATE_FORMATS.MMMM)})
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    />
                  </tr>
                </thead>
                <tbody>
                  {displayStaff(staffData)?.map((staff, index) => {
                    const rowId = staff?.authId;
                    const serialNumber =
                      (currentPage - 1) * pageLimit + index + 1;
                    const [firstName, lastName] = getFirstAndLastName(
                      staff?.name
                    );
                    const contactNumber = staff?.contact;
                    const staffAttendance = staff?.percentage || "0.00";

                    const handleDetailsClick = () => {
                      setDrawer({
                        isOpen: true,
                        authId: staff?.authId,
                      });
                    };

                    return (
                      <tr id={rowId} key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-gray-500">{serialNumber}</p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center text-left">
                            <div className="flex-shrink-0">
                              {getAvatar(firstName, lastName, staff?.image)}
                            </div>
                            <div className="ml-3">
                              <p className="text-black font-medium whitespace-no-wrap">
                                {staff?.name}
                              </p>
                              <StaffBadge staffType={staff.staffType} />
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-black whitespace-no-wrap">
                            {contactNumber}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-black whitespace-no-wrap">
                            {getGender(staff?.gender)}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          {/* eslint-disable-next-line eqeqeq */}
                          {staffAttendance != 0 ? `${staffAttendance}%` : "NA"}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <span
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            onClick={handleDetailsClick}
                          >
                            Details
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              size="small"
              pageSize={pageLimit}
              current={currentPage}
              showSizeChanger={false}
              total={totalStaff}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffTable;
