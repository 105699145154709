import { notification } from "antd";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  //   signInSuccessUrl: "/signedIn",
  // We will display Google and Facebook as auth providers.

  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: "image", // 'audio'
        size: "invisible", // 'invisible' or 'compact'
        badge: "bottomright", //' bottomright' or 'inline' applies to invisible.
      },
      defaultCountry: "IN",
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      authResult.user.getIdTokenResult().then((tokenResult) => {
        console.log(`signInSuccessWithAuthResult`, tokenResult);
      });
      return true;
    },
    signInFailure: () => {
      notification["info"]({
        message: "This account does not exist",
        description: "Please contact GroundUp and register your number"
      });
    }
  },
};
const fireBaseApp = firebase.initializeApp(config);

// export const firestore = firebase.firestore();
// export const auth = getAuth();
export default fireBaseApp;
