import React, { useState } from "react";
import { GET_STUDENTS_BYPK } from "./graphql";
import { useQuery } from "@apollo/client";
import Tabs, { TabPane } from "rc-tabs";
import moment from "moment";
import { notification, Tag, Modal } from "antd";
import _ from "lodash";
import { DATE_FORMATS } from "../../utils/constants";
import { PencilIcon } from "@heroicons/react/solid";
import { getUserRole } from "../../shared/getUserRole";
import { USER_ROLES, APP_TYPE } from "../../utils/constants";
import lock from "../../assets/images/lock.png";
import { Button } from "../../components";
import CopyCard from "../../components/CopyCard";
import useGeneratePin from "../../hooks/useGeneratePin";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  TextViewForLabelAndValue,
  TextViewForValues,
} from "../../components/TextView";
import { Avatar } from "antd";
import { getS3Image } from "../../shared/s3";
import { useNavigate } from "react-router";

const getAvatarForDetailedView = (first_name, last_name, image, size) => {
  let lastName = _.isNil(last_name) ? "" : last_name;
  let hasNoImage = _.isNil(image);
  if (hasNoImage) {
    return (
      <div
        shape="square"
        className="flex items-center justify-center"
        style={{
          backgroundColor: "#4F46E5",
          width: 137,
          height: 96,
          borderRadius: 13,
          fontSize: 56,
          color: "white"
        }}
      >
        {first_name?.charAt(0)}
        {lastName?.charAt(0)}
      </div>
    );
  }
  return (
    <img
      src={getS3Image(image)}
      alt="Student Profile"
      className="max-h-24 p-0 h-full m-0"
      style={{
        maxWidth: "30%",
        borderRadius: 13,
      }}
    />
  );
};

export default function StudentDetail({ studentID, onEditClick }) {
  const [user, setuser] = useState([]);
  const [activekey, setactivekey] = useState(1);
  const [auditTrails, setAuditTrails] = useState([]);
  const [currentParent, setCurrentParent] = useState(null);
  const [regeneratePinAuthId, setRegeneratePinAuthId] = useState("");
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const { loading, error, data } = useQuery(GET_STUDENTS_BYPK, {
    variables: {
      id: studentID,
    },
    onCompleted: (res) => {
      setuser(res?.students_by_pk);
      setAuditTrails(res?.audit_trails);
    },
  });
  const navigate = useNavigate();

  const { mutate: updatePin, data: pinData } = useGeneratePin(
    APP_TYPE.PARENT_APP
  );

  const {
    first_name,
    last_name,
    middle_name,
    division_students,
    aadhaar,
    dob,
    caste,
    religion,
    student_parents,
    is_draft,
    is_active,
    image,
    admitted_class,
    paying_or_free,
    last_school,
    place_of_birth,
    transfer_date,
    gender,
    gr_set,
    rte,
    category,
    remarks,
    left_class,
    conduct,
    student_progress,
    student_ref,
    roll_no,
    subscriptions,
  } = user;

  const intermediate = division_students?.[0];
  const division = {
    id: intermediate?.division_id,
    ...intermediate?.division,
  };

  const isUserSuperAdmin = getUserRole()?.includes(USER_ROLES.ADMIN);

  const handleCancel = () => {
    setIsConfirmModalVisible(false);
  };

  const generatePin = async () => {
    handleCancel();
    const authId = currentParent?.auth_id;
    if (authId) {
      setRegeneratePinAuthId(authId);
      updatePin({
        authId,
      });
      return;
    }
    notification["error"]({
      message: "Could not get authId",
      description: "Please try again.",
      duration: 1.5,
    });
  };

  const handleGeneratePinClick = (parentData) => {
    setIsConfirmModalVisible(true);
    setCurrentParent(parentData);
  };

  const handleNavigateToAnalytics = () => {
    let name = `${first_name.trim()} ${middle_name?.trim()}`.trim();
    name = `${name} ${last_name.trim()}`.trim();
    const className = division?.division_name;
    const divisionId = division?.id;

    navigate(`/analytics/${divisionId}/${studentID}`, {
      state: {
        currentStudent: studentID,
        fullName: name,
        className,
        divisionId,
        hasActiveSubscription: subscriptions?.length > 0,
      },
    });
  };

  if (loading) return "Loading...";

  return (
    <div className="h-full">
      <div className="mt-4 flex mx-6">
        {getAvatarForDetailedView(first_name, last_name, image)}

        <div className="flex flex-1 flex-col items-start ml-6 mb-4">
          <p className="mb-1 text-gray-700 text-xl font-bold uppercase">
            {first_name} {middle_name} {last_name}
          </p>
          <p className="mb-1 text-gray-500 text-sm font-normal capitalize">
            Class {division?.division_name}
            {roll_no ? ` | Roll No. ${roll_no}` : ""}
            {transfer_date && !is_active ? (
              <Tag
                color="#f5dada"
                style={{
                  borderRadius: 20,
                  padding: "2px 10px",
                  marginLeft: 10,
                  fontWeight: 500,
                }}
              >
                <span className="text-red-500">Transferred</span>
              </Tag>
            ) : !is_draft ? (
              <Tag
                color="#D1FAE5"
                style={{
                  borderRadius: 20,
                  padding: "2px 10px",
                  marginLeft: 10,
                  fontWeight: 500,
                }}
              >
                <span className="text-green-800">Active</span>
              </Tag>
            ) : null}
          </p>
          <div className="flex mt-2">
            <button
              id="managestudents-details-edit"
              className="p-2 mr-2 rounded-md bg-indigo-700 hover:bg-indigo-500 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-xs leading-4 font-medium shadow-sm  inline-flex items-center "
              onClick={() => onEditClick()}
            >
              <span className="flex justify-center items-center gap-x-1 whitespace-nowrap">
                <PencilIcon className="h-4 w-4" />
                Edit Details
              </span>
            </button>
            <button
              id="managestudents-details-edit"
              className="p-2 mr-2 rounded-md bg-indigo-700 hover:bg-indigo-500 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-xs leading-4 font-medium shadow-sm  inline-flex items-center "
              onClick={handleNavigateToAnalytics}
            >
              <span className="flex justify-center items-center gap-x-1 whitespace-nowrap">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                  <path
                    fillRule="evenodd"
                    d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Check Performance
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="mx-6">
        <Tabs
          defaultActiveKey={activekey}
          onChange={(key) => setactivekey(Number(key))}
          className="custom-tabs whitespace-nowrap"
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane tab="Details" key={1}>
            <TextViewForLabelAndValue
              title="Aadhaar Number"
              description={aadhaar !== "" ? aadhaar : "NA"}
            />
            <TextViewForLabelAndValue
              title="Date of birth"
              description={
                dob !== null ? moment(dob).format(DATE_FORMATS.DDMMMYYY) : "NA"
              }
            />
            <TextViewForLabelAndValue
              title="Gender"
              description={
                gender !== null
                  ? gender === "NA"
                    ? "NA"
                    : gender === "M"
                    ? "Male"
                    : gender === "F"
                    ? "Female"
                    : "Transgender"
                  : "Not Provided"
              }
            />
            <TextViewForLabelAndValue
              title="Place of birth"
              description={place_of_birth !== "" ? place_of_birth : "NA"}
            />
            <TextViewForLabelAndValue
              title="Caste"
              description={caste !== "" ? caste : "Not disclosed"}
            />
            <TextViewForLabelAndValue
              title="Religion"
              description={religion !== "" ? religion : "Not disclosed"}
            />
          </TabPane>
          <TabPane tab="Contact" key={2}>
            {student_parents ? (
              <div>
                <p className="mt-6 text-sm leading-5 font-medium text-gray-500">
                  Contact Information
                </p>
                {student_parents.map((stp, stpIndex) => {
                  return (
                    <div className="flex flex-1 justify-between items-center my-3">
                      <div className="flex flex-col">
                        <p className="text-sm leading-5 font-semibold text-gray-900 mb-1">
                          {`${stp?.user?.first_name} (${stp?.relation})`}
                        </p>
                        <p className="text-sm leading-5 font-semibold text-gray-900">
                          {stp?.user?.mobile_number}
                        </p>
                      </div>
                      {isUserSuperAdmin ? (
                        <div className="flex flex-1 justify-end">
                          {pinData?.success &&
                          regeneratePinAuthId === stp?.user?.auth_id ? (
                            <CopyCard text={pinData?.data?.newPin} />
                          ) : null}
                          <Button
                            className="h-10 mr-2 bg-successGreen"
                            onClick={() => handleGeneratePinClick(stp?.user)}
                          >
                            <img src={lock} alt="Lock" className="h-5 mr-2" />
                            <div className="text-white">Generate PIN</div>
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mt-3 text-sm leading-5 font-normal text-gray-900">
                None available
              </div>
            )}
          </TabPane>
          <TabPane tab="Additional">
            <TextViewForLabelAndValue
              title="Last School"
              description={last_school !== "" ? last_school : "NA"}
            />

            <TextViewForLabelAndValue
              title="Paying or Free"
              description={paying_or_free !== "" ? paying_or_free : "NA"}
            />
            <TextViewForLabelAndValue
              title="Standard and Class in which admitted"
              description={admitted_class !== "" ? admitted_class : "NA"}
            />

            <TextViewForLabelAndValue
              title="Standard and Class in which Left"
              description={left_class !== "" ? left_class : "NA"}
            />

            <TextViewForLabelAndValue
              title="Overall Progress"
              description={student_progress !== "" ? student_progress : "NA"}
            />

            <TextViewForLabelAndValue
              title="Conduct"
              description={conduct !== "" ? conduct : "NA"}
            />
            <TextViewForLabelAndValue
              title="Remarks"
              description={remarks !== "" ? remarks : "NA"}
            />

            <TextViewForLabelAndValue
              title="Category"
              description={category !== "" ? category : "Not disclosed"}
            />
            <TextViewForLabelAndValue
              title="RTE"
              description={_.isNil(rte) ? "NA" : rte ? "Yes" : "No"}
            />

            <TextViewForLabelAndValue
              title="Student Reference"
              description={_.isNil(student_ref) ? "NA" : student_ref}
            />

            <TextViewForLabelAndValue
              title="GR Set"
              description={gr_set !== "" ? gr_set : "NA"}
            />
          </TabPane>
          <TabPane tab="Edit History" key={3}>
            {auditTrails?.length > 0 ? (
              <div className=" mt-4 ">
                <p className=" text-sm font-semibold text-gray-500 ">
                  Last Updated On
                </p>
                {auditTrails.map((audit, auditIndex) => {
                  const updatedBy = audit?.userByAddedBy?.first_name;
                  const updatedTime = `${moment(audit?.updated_at).format(
                    DATE_FORMATS.DDMMMYYY
                  )} • ${moment(audit?.updated_at).format(DATE_FORMATS.HHMMA)}`;
                  return (
                    <TextViewForValues
                      key={auditIndex}
                      data={[updatedBy, updatedTime]}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="mt-6 text-sm leading-5 font-medium text-gray-500">
                None available
              </div>
            )}
          </TabPane>
        </Tabs>
      </div>
      <Modal
        open={isConfirmModalVisible}
        centered
        footer={null}
        onCancel={handleCancel}
        closeIcon={
          <CloseCircleFilled
            style={{
              color: "#EB5757",
              fontSize: "24px",
            }}
          />
        }
        width={400}
      >
        <div className="mt-5">
          <div className="text-center">
            Are you sure you want to generate pin for <br />
            <span className="font-extrabold">{`${first_name?.trim()} ${middle_name?.trim()} ${last_name?.trim()}`}</span>{" "}
            for <span className="font-extrabold">Student App</span>?
          </div>
        </div>
        <div className="flex flex-1 justify-between">
          <Button className="w-32 mt-8 " onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="w-32 mt-8 bg-successGreen" onClick={generatePin}>
            <div className="text-white">Generate PIN</div>
          </Button>
        </div>
      </Modal>
    </div>
  );
}
