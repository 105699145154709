import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { Drawer, DatePicker } from "antd";
import CloseIcon from "../../../components/closeIcon";
import Button from "../../../components/Button";
import ExpensesTable from "./ExpensesTable";
import { Dropdown } from "../../../components";
import manageIllustration from "../../../assets/images/manage.png";
import useGetExpenseCategories from "../../../hooks/useGetExpenseCategories";
import { getSchoolID } from "../../../shared/getSchoolID";
import { DATE_FORMATS } from "../../../utils/constants";
import useGetExpenses from "../../../hooks/useGetExpenses";
import useDeleteExpense from "../../../hooks/useDeleteExpense";
import ExpenseForm from "../../../components/ExpenseForm";

const { RangePicker } = DatePicker;

export const PAGE_SIZE = 10;

const getNonNulledObjects = (arr) =>
  arr?.filter((elem) => elem?.value !== null);

function ManageExpenses() {
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [isStructureDrawerOpen, setIsStructureDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [amount, setAmount] = useState(null);
  const [category, setCategory] = useState(null);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [searchText, setSearchText] = useState("");

  const { data: expenseCategoriesAndPaymentModes, isLoading } =
    useGetExpenseCategories({
      schoolId: getSchoolID(),
    });

  const { mutate: deleteExpense } = useDeleteExpense();

  const { data: expensesDataWithCount } = useGetExpenses(
    {
      schoolId: getSchoolID(),
      startDate: dates.startDate,
      endDate: dates.endDate,
      searchText,
      paymentMode: amount?.value,
      expenseCategoryId: category?.value,
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
    },
    {
      enabled: !isLoading,
    }
  );

  const totalCount = expensesDataWithCount?.totalCount;
  const expensesData = expensesDataWithCount?.data;

  const handleAmountSelect = (value) => {
    const selectedItem = expenseCategoriesAndPaymentModes.amount.find(
      (currentAmount) => currentAmount.value === value
    );
    setAmount(selectedItem);
  };

  const handleCategorySelect = (value) => {
    const selectedItem = expenseCategoriesAndPaymentModes.category.find(
      (currentCategory) => parseInt(currentCategory.value) === parseInt(value)
    );
    setCategory(selectedItem);
  };

  const handleEditDetails = (expense) => {
    setSelectedExpense(expense);
    setIsStructureDrawerOpen(true);
  };

  const onRangeChange = (dates) => {
    if (dates) {
      const formattedStartDate = dates[0].format(DATE_FORMATS.YYYYMMDD);
      const formattedEndDate = dates[0].format(DATE_FORMATS.YYYYMMDD);
      setDates({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    } else {
      setDates({
        startDate: null,
        endDate: null,
      });
    }
  };

  const isAnyFilterAdded = () => {
    return (
      dates?.startDate ||
      dates?.endDate ||
      searchText ||
      amount?.value ||
      category?.value
    );
  };

  const handleDrawerClose = () => {
    setIsStructureDrawerOpen(false);
    setSelectedExpense(null);
  };

  const handleExpenseDelete = () => {
    deleteExpense({
      expenseId: selectedExpense?.expenseId,
    });
    handleDrawerClose();
  };

  return (
    <>
      <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
        <div className="flex flex-row gap-x-4 items-center mb-8">
          <p className="text-2xl text-left font-bold flex-col m-0">Expenses</p>
          <Button
            id="download-all-students-data"
            buttonStyle="primary"
            className="py-3 px-4"
            onClick={() => setIsStructureDrawerOpen(true)}
          >
            <PlusIcon className="h-4 w-4" />
            <span className="ml-2">Create New Expense</span>
          </Button>
        </div>
        {expensesData?.length > 0 || isAnyFilterAdded() ? (
          <>
            <div className="flex items-start justify-between">
              <form
                className="flex flex-col items-start md:flex-row md:space-x-3 space-y-3 md:space-y-0"
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
              >
                <div className=" relative ">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    className="w-auto pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by category or title"
                    value={searchText}
                    style={{ width: 290 }}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </form>
              <div className="flex flex-row gap-x-5">
                <RangePicker
                  className="rounded-md"
                  onChange={onRangeChange}
                  format={DATE_FORMATS.DDMMMYYYY}
                  style={{
                    borderRadius: 5,
                    minWidth: 225,
                    height: "100%",
                    padding: 9,
                  }}
                />
                <Dropdown
                  id="expense-amount"
                  value={amount?.label}
                  defaultValue="Payment Mode: All"
                  type="primary"
                  options={expenseCategoriesAndPaymentModes?.amount}
                  onItemClick={handleAmountSelect}
                />
                <Dropdown
                  id="expense-category"
                  value={category?.label}
                  defaultValue="Category: All"
                  type="primary"
                  options={expenseCategoriesAndPaymentModes?.category}
                  onItemClick={handleCategorySelect}
                />
              </div>
            </div>
            <ExpensesTable
              expensesData={expensesData}
              onEditDetails={handleEditDetails}
              totalCount={totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <div className="mb-0 pb-0 object-fit h-96 flex mt-44 flex-col place-items-center">
            <img src={manageIllustration} alt="" className=" max-h-full" />
            <p className="font-bold text-3xl mt-9">
              You have not added any expenses yet
            </p>
            <p className="font-medium text-xl mt-7">
              Click on Create New Expense button
            </p>
          </div>
        )}
      </div>
      <Drawer
        title={
          selectedExpense ? (
            <div className="flex justify-between items-center">
              <h2 className="font-semibold text-xl text-gray-900">
                Edit Expense
              </h2>
              <span>
                <button
                  id="managecalendar-editevent-deleteall"
                  onClick={handleExpenseDelete}
                  className=" h-30 py-3 px-4 mr-5 flex justify-center items-center  bg-red-500 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
                >
                  Delete Expense
                </button>
              </span>
            </div>
          ) : (
            <h2 className="font-semibold text-xl text-gray-900 ml-3">
              Create new expense
            </h2>
          )
        }
        placement="right"
        onClose={handleDrawerClose}
        open={isStructureDrawerOpen}
        destroyOnClose
        contentWrapperStyle={{
          width: "40vw",
        }}
        closeIcon={<CloseIcon />}
        afterOpenChange={(visible) => {
          if (!visible) {
            setSelectedExpense(null);
          }
        }}
      >
        <ExpenseForm
          selectedExpense={selectedExpense}
          category={getNonNulledObjects(
            expenseCategoriesAndPaymentModes?.category
          )}
          onCancel={handleDrawerClose}
          amount={getNonNulledObjects(expenseCategoriesAndPaymentModes?.amount)}
          onSave={handleDrawerClose}
        />
      </Drawer>
    </>
  );
}

export default ManageExpenses;
