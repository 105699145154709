import React, { useState } from "react";
import { Spin, Pagination } from "antd";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { ClassDivisionSelector } from "../../../components";
import { DownloadIcon } from "@heroicons/react/outline";
import manageIllustration from "../../../assets/images/manage.png";
import { Dropdown } from "../../../components";
import { DATE_FORMATS, FEE_STUDENT_TYPES } from "../../../utils/constants";
import { getSchoolID } from "../../../shared/getSchoolID";
import useFilterOptions from "../../../hooks/useFilterOptions";
import useStudentFeeDetails from "../../../hooks/useStudentFeeDetails";
import useDownloadFeeDetailsReport from "../../../hooks/useDownloadFeeDetailsReport";
import useTracking from "../../../hooks/useTracking";
import moment from "moment";
const PAGE_SIZE = 10;

const getDateAndTime = (history) => {
  let dateText = "-";
  let timeText = "";
  let paymentMode = "";
  if (history) {
    const mm = moment(history.createdAt);
    dateText = mm.format(DATE_FORMATS.DDMMMYYYY);
    timeText = mm.format(DATE_FORMATS.HHMMA);
    paymentMode = history.paymentMode;
  }
  return { dateText, timeText, paymentMode };
};

function FeesTable({ filterData, onEditDetails }) {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: studentFeeData, isLoading: isLoadingStudents } =
    useStudentFeeDetails(
      {
        offset: PAGE_SIZE * (currentPage - 1),
        includeTotalCount: true,
        limit: PAGE_SIZE,
        ...filterData,
      },
      {
        enabled: filterData?.schoolId !== undefined,
        refetchOnWindowFocus: false,
      }
    );

  const totalCount = studentFeeData?.totalRows || 0;
  const studentFeesData = studentFeeData?.students || [];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handleEditClick = (collectionId) => {
    onEditDetails(collectionId);
  };

  if (studentFeesData?.length === 0 && isLoadingStudents === false) {
    return (
      <div className="mb-0 pb-0 object-fit h-96 flex mt-44 flex-col place-items-center">
        <img src={manageIllustration} alt="" className=" max-h-full" />
        <p className="font-bold text-3xl mt-9">No data found</p>
      </div>
    );
  }

  return (
    <Spin spinning={isLoadingStudents}>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Roll No.
                </th>
                <th
                  scope="col"
                  className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Student Name
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Date-Time
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 text-right bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Fees Paid
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 text-right bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Total Fees
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Transactions
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(studentFeesData) &&
                studentFeesData?.map((studentData, index) => {
                  const { dateText, timeText, paymentMode } = getDateAndTime(
                    studentData?.history?.[0]
                  );
                  return (
                    <tr key={index}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center text-left">
                          <div className="ml-3">
                            <p className="text-black font-medium whitespace-no-wrap">
                              {studentData?.roll_no || "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center text-left">
                          <div className="ml-3">
                            <div className="flex flex-1">
                              <p className="text-black font-medium whitespace-no-wrap">
                                {studentData?.studentName ||
                                  studentData?.student_full_name ||
                                  ""}
                              </p>
                              {studentData?.isNew && (
                                <p
                                  className="bg-bloodRed p-0.5 px-1 text-xs font-mediun text-white ml-2"
                                  style={{ fontSize: 10, borderRadius: 4 }}
                                >
                                  New
                                </p>
                              )}
                            </div>
                            <p className="text-textGrey">
                              Class :{" "}
                              {studentData?.className ||
                                studentData?.divisionName ||
                                ""}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-black whitespace-no-wrap font-medium">
                          {dateText}
                        </p>
                        <p className="text-textGrey">{timeText}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        {studentData?.paidAmountText ? (
                          <p className="text-right text-success whitespace-no-wrap font-medium">
                            {studentData?.paidAmountText}
                          </p>
                        ) : (
                          <p className="text-right text-black whitespace-no-wrap font-medium">
                            0
                          </p>
                        )}
                        <p className="text-textGrey text-right">
                          {paymentMode || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        {studentData?.totalAmountText ? (
                          <p className="text-right text-darkerGrey whitespace-no-wrap font-medium">
                            {studentData?.totalAmountText}
                          </p>
                        ) : (
                          <p className="text-right text-darkerGrey whitespace-no-wrap font-medium">
                            0
                          </p>
                        )}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        {studentData?.history &&
                          studentData?.history.map((history) => (
                            <div
                              onClick={() => handleEditClick(history.id)}
                              className="text-black font-medium whitespace-no-wrap flex justify-end"
                              style={{ cursor: "pointer" }}
                            >
                              <span className="text-darkerGrey">
                                {history.paidAmountText}
                              </span>{" "}
                              <span className="text-textGrey">
                                - {history.timeText}{" "}
                              </span>
                              <span className="text-primary underline ml-5">
                                Edit
                              </span>
                            </div>
                          ))}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          size="small"
          pageSize={PAGE_SIZE || 10}
          total={totalCount}
          current={currentPage}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
      </div>
    </Spin>
  );
}

function StudentFeesReport() {
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({
    schoolId: getSchoolID(),
    studentType: FEE_STUDENT_TYPES[0].value,
  });
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const { mutateAsync: downloadReport } = useDownloadFeeDetailsReport({
    onSettled: () => {
      setIsDownloadingReport(false);
    },
  });
  const { trackEvent } = useTracking();

  const { data: filterOptions } = useFilterOptions(
    {
      schoolId: getSchoolID(),
      entity: "studentFeeDetails",
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleClassChange = (classId) => {
    setFilterData({
      ...filterData,
      classId,
      divisionId: null,
    });
  };

  const handleDivisionChange = (divisionId) => {
    setFilterData({
      ...filterData,
      divisionId,
    });
  };

  const studentTypeValue = () => {
    const id = filterData?.studentType;
    if (!id) return "EXISTING";
    // eslint-disable-next-line eqeqeq
    const typeData = FEE_STUDENT_TYPES.find(({ value }) => value === id);
    return typeData?.label;
  };

  const handleEditDetails = (collectionId) => {
    navigate(`/fees-collection/new-collection`, {
      state: {
        feesCollectionData: {
          id: collectionId,
        },
      },
    });
  };

  const handleDownloadReport = async () => {
    trackEvent("Download Stdent Fees Report", { filterData });
    setIsDownloadingReport(true);
    const { classId, divisionId } = filterData;
    let fileName = "Fees_report";
    if (divisionId) {
      const divisionData = filterOptions?.divisions?.find(
        (d) => d.value === parseInt(divisionId)
      );
      if (divisionData) {
        const divisionName = divisionData.label.split(": ")?.[1];
        fileName = `Class ${divisionName}_${fileName}`;
      }
    } else if (classId) {
      const classData = filterOptions?.classes?.find(
        (c) => c.value === parseInt(classId)
      );
      if (classData) {
        const className = classData.label.split(": ")?.[1];
        fileName = `Class ${className}_${fileName}`;
      }
    }
    await downloadReport({
      ...filterData,
      fileName: `${fileName}.pdf`,
    });
  };

  return (
    <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={isDownloadingReport}>
        <div className="flex flex-row gap-x-4 items-center justify-between mb-8">
          <p className="text-2xl text-left font-bold flex-col m-0">
            Student's Fees Report
          </p>
          <Button buttonStyle="secondary" onClick={() => navigate(-1)}>
            Back to Reports
          </Button>
        </div>
        <div className="flex items-start justify-between">
          <div className="flex flex-row gap-x-5">
            <Dropdown
              value={studentTypeValue()}
              defaultValue="Fee Structure: All"
              type="primary"
              options={FEE_STUDENT_TYPES}
              onItemClick={(studentType) =>
                setFilterData({
                  ...filterData,
                  studentType,
                  divisionId: null,
                  classId: null,
                })
              }
            />
            <ClassDivisionSelector
              classDefaultValue="Class : All"
              classes={filterOptions?.classes}
              divisions={filterOptions?.divisions}
              allOptionNeeded
              disabled={filterData?.studentType === FEE_STUDENT_TYPES[1].value}
              onClassChange={handleClassChange}
              onDivisionChange={handleDivisionChange}
            />
          </div>
          <Button
            onClick={handleDownloadReport}
            buttonStyle="success"
            className="py-3 px-4"
          >
            <DownloadIcon className="h-4 w-4" />
            <span className="ml-2">Download Report</span>
          </Button>
        </div>
        <FeesTable filterData={filterData} onEditDetails={handleEditDetails} />
      </Spin>
    </div>
  );
}

export default StudentFeesReport;
