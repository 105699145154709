import { gql } from "@apollo/client";

export const CREATE_SUBJECTS_FOR_CLASS = gql`
  mutation Insert_subjects($subjects: [subjects_insert_input!]!) {
    insert_subjects(
      objects: $subjects
      on_conflict: {
        constraint: subjects_pkey
        update_columns: [title, group, is_enable, is_core, translations]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_RELATIVE_ORDER_OF_SUBJECTS = gql`
  mutation updateRelativeOrder($subjectOrder: [subjects_updates!]!) {
    update_subjects_many(updates: $subjectOrder) {
      affected_rows
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation updateClass($classId: Int!, $classData: classes_set_input!) {
    update_classes_by_pk(pk_columns: { id: $classId }, _set: $classData) {
      id
    }
  }
`;

export const UPDATE_ASSESSMENTS_ORDER = gql`
  mutation updateAssessmentOrder($newAssessmentorder: [assessments_updates!]!) {
    update_assessments_many(updates: $newAssessmentorder) {
      affected_rows
    }
  }
`;

export const UPDATE_CCE_EXAM = gql`
  mutation addUpdateEvalParams(
    $objects: [assessment_subject_eval_params_insert_input!]!
    $updates: [assessment_subjects_updates!]!
    $updateParamName: [assessment_subject_eval_params_updates!]!
    $updateExamTitle: [assessments_updates!] = { where: {} }
  ) {
    insert_assessment_subject_eval_params(
      objects: $objects
      on_conflict: {
        constraint: assessment_subject_eval_params_assessment_subject_id_eval_param
        update_columns: [max_marks, is_active, relative_order]
      }
    ) {
      affected_rows
    }
    update_assessment_subjects_many(updates: $updates) {
      affected_rows
    }
    update_assessment_subject_eval_params_many(updates: $updateParamName) {
      affected_rows
    }
    update_assessments_many(updates: $updateExamTitle) {
      affected_rows
    }
  }
`;

export const UPDATE_EXAM = gql`
  mutation updateExam(
    $assessmentId: Int!
    $assessmentDetails: assessments_set_input!
  ) {
    update_assessments(
      where: { id: { _eq: $assessmentId } }
      _set: $assessmentDetails
    ) {
      returning {
        id
        title
        start_at
        end_at
        is_graded
      }
      affected_rows
    }
  }
`;

export const UPDATE_EXAM_SUBJECT = gql`
  mutation updateExamSubject(
    $assessmentId: Int!
    $subjectId: Int!
    $assessmentSubject: assessment_subjects_set_input!
  ) {
    update_assessment_subjects(
      where: {
        assessment_id: { _eq: $assessmentId }
        subject_id: { _eq: $subjectId }
      }
      _set: $assessmentSubject
    ) {
      affected_rows
    }
  }
`;

export const ADD_EXAMS = gql`
  mutation addExams($exams: [assessments_insert_input!]!) {
    insert_assessments(objects: $exams) {
      affected_rows
      returning {
        title
        start_at
        is_graded
        id
        end_at
      }
    }
  }
`;

export const ADD_EXAM_SUBJECT = gql`
  mutation addExamSubject($examSubjects: [assessment_subjects_insert_input!]!) {
    insert_assessment_subjects(objects: $examSubjects) {
      affected_rows
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation updateSchool($schoolData: schools_set_input, $schoolId: Int!) {
    update_schools_by_pk(pk_columns: { id: $schoolId }, _set: $schoolData) {
      id
      image
      title
      board_id
      board_name
      mobile_number
      email
      trust_name
      settings
      address {
        id
        address_line_one
        address_line_two
        pincode
        city {
          id
          city_name
          district {
            id
            district_name
            state {
              id
              state_name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_TEACHERS_ATTENDANCE = gql`
  mutation addTeachersAttendanceData(
    $objects: [teacher_attendance_data_insert_input!]!
  ) {
    insert_teacher_attendance_data(
      objects: $objects
      on_conflict: {
        constraint: teacher_attendance_data_pkey
        update_columns: is_present
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_STUDENT = gql`
  mutation inActivateStudent(
    $studentId: Int!
    $studentsSetChanges: students_set_input!
  ) {
    update_students_by_pk(
      pk_columns: { id: $studentId }
      _set: $studentsSetChanges
    ) {
      id
    }
    delete_student_parents(where: { student_id: { _eq: $studentId } }) {
      affected_rows
    }
    update_division_students(
      where: { student_id: { _eq: $studentId } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_TC_ISSUANCE_HISTORY = gql`
  mutation revertTransferOfStudent($studentId: Int!, $documentId: Int!) {
    update_students_by_pk(
      pk_columns: { id: $studentId }
      _set: { is_active: true, transfer_date: null, transfer_reason: null }
    ) {
      full_name
      id
    }
    update_documents_issuance_history(
      where: { id: { _eq: $documentId } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_BONAFIDE_NU_ISSUANCE_HISTORY = gql`
  mutation revertBonafideOrNirgamOfStudent($documentId: Int!) {
    update_documents_issuance_history(
      where: { id: { _eq: $documentId } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_ROLLNO_PARAM = gql`
  mutation updateSchoolRollNoParam(
    $schoolId: Int!
    $roll_no_param: String!
    $preferences: String!
  ) {
    update_schools_by_pk(
      pk_columns: { id: $schoolId }
      _set: { roll_no_param: $roll_no_param, preferences: $preferences }
    ) {
      roll_no_param
      preferences
    }
  }
`;

export const UPDATE_ADMIN_ROLE = gql`
  mutation updateAdminType($school_admin_id: Int!, $admin_type: String!) {
    update_school_admins_by_pk(
      pk_columns: { id: $school_admin_id }
      _set: { admin_type: $admin_type }
    ) {
      school_admin_id: id
      admin_type
      user {
        full_name
        mobile_number
      }
    }
  }
`;

export const BULK_UPLOAD_SCHOOLS = gql`
  mutation createSchool($schoolDetails: schools_insert_input!) {
    insert_schools_one(object: $schoolDetails) {
      schoolId: id
    }
  }
`;

export const UPDATE_EXAM_SUBJECTS = gql`
  mutation upsertMarks($examSubjects: [assessment_subjects_insert_input!]!) {
    insert_assessment_subjects(
      objects: $examSubjects
      on_conflict: {
        constraint: assessment_subjects_assessment_id_subject_id_division_id_is_act
        update_columns: [total_marks, updated_at]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_EVALUATUATION_FOR_CLASS = gql`
  mutation updateExamType($updates: [divisions_updates!] = { where: {} }) {
    update_divisions_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const DELETE_EXAMS = gql`
  mutation deleteExam($assessmentIds: [Int!]) {
    update_assessments(
      where: { id: { _in: $assessmentIds } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
    update_assessment_subjects_many(
      updates: {
        where: { assessment_id: { _in: $assessmentIds } }
        _set: { is_active: false }
      }
    ) {
      affected_rows
    }
    update_assessment_subject_eval_params(
      where: { assessment_subject: { assessment_id: { _in: $assessmentIds } } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_EXAM_SUBJECTS_SCHEDULE = gql`
  mutation upsertMarks($examSubjects: [assessment_subjects_insert_input!]!) {
    insert_assessment_subjects(
      objects: $examSubjects
      on_conflict: {
        constraint: assessment_subjects_assessment_id_subject_id_division_id_is_act
        update_columns: [start_time, updated_at]
      }
    ) {
      affected_rows
    }
  }
`;
