import React from "react";
import { useNavigate } from "react-router-dom";
import Attendance from "./AttendanceView";
import { Button } from "../../components";

const AttendancePending = () => {
  const navigate = useNavigate();
  return (
    <main className="flex flex-col w-screen px-8 py-6 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row justify-between gap-x-4 items-center ">
        <h1 className="text-2xl text-left font-bold flex-col">
          Today's Attendance
        </h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          buttonStyle="secondary"
        >
          Back to Dashboard
        </Button>
      </div>
      <Attendance />
    </main>
  );
};

export default AttendancePending;
