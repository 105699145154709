import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const getToken = () => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        return true;
      }
      return false;
    } catch (error) {
      localStorage.clear();
      return false;
    }
  };
  if (!getToken()) {
    return <Navigate to="/auth" state={{ from: location }} />;
  } else {
    return (
      <>
        <Component />
      </>
    );
  }
};
export default PrivateRoute;
