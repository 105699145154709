import React from "react";

function Table({ theadData, tbodyData }) {
  return (
    <table className="min-w-full leading-normal">
      <thead>
        <tr>
          {theadData?.map((headText) => {
            return (
              <th
                scope="col"
                className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-medium"
              >
                {headText}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {tbodyData?.map((data, index) => {
          return (
            <tr key={index}>
              {data?.items?.map((item, index) => {
                return (
                  <td
                    key={index}
                    className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left text-gray-500"
                  >
                    {item}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
