import { gql } from "@apollo/client";

export const GET_DIVISION_STUDENTS = gql`
  query GetStudentsByDivision($divisionId: Int!, $assessments: [Int!]) {
    division_students(
      where: {
        division_id: { _eq: $divisionId }
        is_active: { _eq: true }
        student: { is_active: { _eq: true }, is_draft: { _eq: false } }
      }
    ) {
      student {
        id
        aadhaar
        full_name
        gr_no
        dob
        father_name
        mother_name
        promotion_histories(where: { from_division_id: { _eq: $divisionId } }) {
          is_promoted
        }
        division {
          id
        }
        image
        marks_in_all_exams: assessment_results_aggregate(
          where: {
            assessment_subject: {
              assessment_id: { _in: $assessments }
              is_active: { _eq: true }
              assessment: { is_graded: { _eq: true } }
              is_publish: { _eq: true }
            }
          }
        ) {
          aggregate {
            sum {
              mark
            }
          }
        }
      }
    }
  }
`;

export const PROMOTE_STUDENTS = gql`
  mutation promoteStudents(
    $promotedStudents: [Int!]!
    $nonPromotedStudents: [Int!]!
    $divisionIdPromoted: Int!
    $divisionIdNonPromoted: Int
    $divisionStudentsData: [division_students_insert_input!]!
    $currentDivisionId: Int!
    $promotionHistoryData: [promotion_history_insert_input!]!
  ) {
    promoted: update_students(
      where: { id: { _in: $promotedStudents } }
      _set: { division_id: $divisionIdPromoted }
    ) {
      affected_rows
    }
    notPromoted: update_students(
      where: { id: { _in: $nonPromotedStudents } }
      _set: { division_id: $divisionIdNonPromoted }
    ) {
      affected_rows
    }
    insert_division_students(objects: $divisionStudentsData) {
      affected_rows
    }
    update_divisions(
      where: { id: { _eq: $currentDivisionId } }
      _set: { is_promoted: true }
    ) {
      affected_rows
    }
    insert_promotion_history(objects: $promotionHistoryData) {
      affected_rows
    }
  }
`;

export const PROMOTE_FINAL_STUDENTS = gql`
  mutation promoteStudentsofLastClass(
    $nonPromotedStudents: [Int!]
    $divisionIdNonPromoted: Int
    $divisionStudentsData: [division_students_insert_input!]!
    $currentDivisionId: Int!
    $promotionHistoryData: [promotion_history_insert_input!]!
  ) {
    notPromoted: update_students(
      where: { id: { _in: $nonPromotedStudents } }
      _set: { division_id: $divisionIdNonPromoted }
    ) {
      affected_rows
    }
    insert_division_students(objects: $divisionStudentsData) {
      affected_rows
    }
    update_divisions(
      where: { id: { _eq: $currentDivisionId } }
      _set: { is_promoted: true }
    ) {
      affected_rows
    }
    insert_promotion_history(objects: $promotionHistoryData) {
      affected_rows
    }
  }
`;

export const GET_EXAMS = gql`
  query getAssessments($classId: Int!) {
    assessments(
      where: { class_id: { _eq: $classId }, is_active: { _eq: true } }
    ) {
      id
      title
      start_at
      end_at
      subjectCount: assessment_subjects_aggregate(
        where: { is_active: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
      total_marks_this_exam: assessment_subjects_aggregate(
        distinct_on: subject_id
        where: {
          is_active: { _eq: true }
          is_publish: { _eq: true }
          assessment: { is_graded: { _eq: true } }
        }
      ) {
        aggregate {
          sum {
            total_marks
          }
        }
      }
    }
  }
`;
