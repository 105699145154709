module.exports = function(request, sourceText, inputLanguageCode, maxResult) {
    return new Promise(function(resolve, reject) {
        if (!(sourceText?.length > 0)) {
            resolve('');
            return;
        }
        let encodedUrl = encodeURI(
            'https://inputtools.google.com/request?text='
            + sourceText
            + '&itc='
            + inputLanguageCode
            + '&num='
            + maxResult
            + '&cp=0&cs=1&ie=utf-8&oe=utf-8&app=demopage'
        );
        request.open('GET', encodedUrl);

        request.onreadystatechange = function () {
            if (request.readyState === request.DONE) {
                if (request.status === 200) {
                    const responseJson = JSON.parse(this.responseText);

                    if (typeof responseJson[1] === 'undefined' || typeof responseJson[1][0] === 'undefined') {
                        reject('Unable to translate');
                        return
                    }
                    const responseList = responseJson[1][0][1];
                    resolve(responseList.map(response => {
                        return [
                            response,
                            response
                        ];
                    }));
                } else {
                    reject('Rejected status code: ' + request.status);
                }
            }
        };

        request.onerror = function() {
            reject(Error('Network Error'));
        };

        request.send();
    });
};