import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Code Refernce: https://codesandbox.io/s/react-table-drag-and-drop-sort-rows-with-dnd-kit-forked-1zzjne?file=/src/Table.jsx
// TODO: Use isDragging to render row and create space for it
const DraggableRow = (props) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: props["data-row-key"],
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };
  const { children, ...restProps } = props;

  /**
   * 'children[1]` is a row of `dataSource`
   * Check if `children[1]` is an array
   * because antd gives 'No Data' element when `dataSource` is an empty array
   */
  return children instanceof Array ? (
    <tr ref={setNodeRef} style={style} {...attributes} {...restProps}>
      {children.map((child) => {
        const { children: rowChildren, key, ...restProps } = child;

        return key === "dragHandle" ? (
          <td {...listeners} {...restProps}>
            {child}
          </td>
        ) : (
          <td {...restProps}>{child}</td>
        );
      })}
    </tr>
  ) : (
    <tr {...restProps}>{children}</tr>
  );
};

export default DraggableRow;
