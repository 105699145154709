import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
//Custom Components
import Button from "../../../components/Button";
//Third-Party Components
import {
  Modal,
  DatePicker,
  Radio,
  Spin,
  message,
  Upload,
  notification,
} from "antd";
import deleteIcon from "../../../assets/images/delete.png";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import {
  MenuOutlined,
  InfoCircleFilled,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
//Custom Hooks
import useFeesCollectionDataForStructure from "../../../hooks/useFeesCollectionDataForStructure";
import useSaveStudentFeeStructure from "../../../hooks/useSaveStudentFeeStructure";
import useSaveStudentCollection from "../../../hooks/useSaveStudentCollection";
import useUploadFeesAsset from "../../../hooks/useUploadFeesAsset";

//Helper Functions
import { getSchoolID } from "../../../shared/getSchoolID";
import { v4 as uuid } from "uuid";
import moment from "moment";
//Constants
import { DATE_FORMATS } from "../../../utils/constants";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import useTracking from "../../../hooks/useTracking";

const FeeCollectionData = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setFeeStructure,
    removeFeeStructure,
    setStudentDetails,
  }));
  const [studentData, setStudentData] = useState({});
  const [previousReceiptNumber, setPreviousReceiptNumber] = useState(0);
  const [feeStructureId, setFeeStructureId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [collectionData, setCollectionData] = useState(null);
  const { mutateAsync: saveFeesAsset } = useUploadFeesAsset();
  const [isSavingCollection, setIsSavingCollection] = useState(false);
  const { mutateAsync: saveFeeCollection } = useSaveStudentCollection();

  const isCollectionSaved = props?.feeCollectionId !== undefined;
  const enableCollection = collectionData == null && feeStructureId != null;
  const [attachments, setAttachments] = useState(
    studentData?.attachments || []
  );
  const { trackEvent } = useTracking();

  const { isFetching: isLoadingCollection, refetch: refetchCollection } =
    useFeesCollectionDataForStructure(
      {
        feeCollectionId: props.feeCollectionId,
        studentId: props.studentId,
        divisionId: props.divisionId,
        feeStructureId,
      },
      {
        refetchOnWindowFocus: false,
        enabled: enableCollection,
        onSuccess: (data) => {
          if (!props?.feeCollectionId) {
            setStudentDetails({
              receiptNumber: data?.receiptNumber || '',
            });
            setPreviousReceiptNumber(data?.receiptNumber - 1);
          }
          setCollectionData(data);
          processTotal(data);
        },
      }
    );

  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  let timeout = null;

  const setStudentDetails = (newData) => {
    setStudentData({
      ...studentData,
      ...newData,
    });
    if (newData?.attachments) {
      setAttachments(newData?.attachments);
    }
  };

  const saveFileInMemory = (file) => {
    const newFileAttatchment = {
      size: file.size,
      title: file.name,
      file,
      id: uuid(),
      isUploaded: false,
    };
    setAttachments((prevAttachments) => [
      ...prevAttachments,
      newFileAttatchment,
    ]);
  };

  const handleDelete = (id) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter(({ id: currentId }) => currentId !== id)
    );
  };

  const setFeeStructure = (mFeeStructureId) => {
    setFeeStructureId(mFeeStructureId);
    setCollectionData(null);
    setTotalPayableAmount(0);
    if (!isCollectionSaved) {
      setStudentData({
        ...studentData,
        paymentMode: "Cash",
        date: moment().format(DATE_FORMATS.YYYYMMDDHHMMSSZ),
      });
    }
  };

  const removeFeeStructure = () => {
    setCollectionData(null);
    setFeeStructureId(null);
    setTotalPayableAmount(0);
    setAttachments([]);
  };

  const setCollectionAmount = (idx, newAmount) => {
    const particulars = collectionData?.particulars;
    newAmount = parseInt(newAmount, 10);
    if (Number.isNaN(newAmount)) newAmount = 0;
    let totalPaid = particulars[idx].paidAmount + newAmount;
    if (totalPaid > particulars[idx].totalAmount) {
      newAmount = particulars[idx].totalAmount - particulars[idx].paidAmount;
      totalPaid = particulars[idx].totalAmount;
    }
    particulars[idx].newPaidAmount = newAmount;
    particulars[idx].amount = totalPaid;
    collectionData.particulars = particulars;
    setCollectionData({ ...collectionData });

    processTotal();
  };

  const setCollectionBreakAmount = (pidx, idx, newAmount) => {
    const breakup = collectionData?.particulars?.[pidx]?.breakup;
    if (!(breakup?.length > 0)) return;
    newAmount = parseInt(newAmount, 10);
    if (Number.isNaN(newAmount)) newAmount = 0;
    let totalPaid = breakup[idx].paidAmount + newAmount;
    if (totalPaid > breakup[idx].totalAmount) {
      newAmount = breakup[idx].totalAmount - breakup[idx].paidAmount;
      totalPaid = breakup[idx].totalAmount;
    }
    breakup[idx].newPaidAmount = newAmount;
    breakup[idx].amount = totalPaid;
    collectionData.particulars[pidx].breakup = breakup;
    setCollectionData({ ...collectionData });

    processTotal();
  };

  const processTotal = (data) => {
    clearTimeout(timeout);
    const calculateTotal = () => {
      let totalAmount = 0;
      (data || collectionData)?.particulars?.forEach((par) => {
        if (par?.breakup?.length > 0) {
          par.breakup.forEach((b) => {
            if (isCollectionSaved && b?.paidAmount > 0) {
              totalAmount += b.paidAmount;
            } else if (b?.amount) {
              totalAmount += b.amount - b.paidAmount;
            }
          });
        } else {
          if (isCollectionSaved && par?.paidAmount > 0) {
            totalAmount += par.paidAmount;
          } else if (par?.amount) {
            totalAmount += par.amount - par.paidAmount;
          }
        }
      });
      setTotalPayableAmount(totalAmount);
    };
    timeout = setTimeout(() => {
      calculateTotal();
    }, 500);
  };

  const saveCollection = async () => {
    try {
      if (!props?.feeCollectionId) {
        if (!totalPayableAmount) {
          notification["error"]({
            message: "Total Amount can not be zero",
            description: "please try again",
            duration: 1.5,
          });
          return;
        }
        if (!(studentData?.studentName?.length > 0)) {
          notification["error"]({
            message: "Student name is required",
            description: "Please enter student name!",
            duration: 1,
          });
          return;
        }
        if (!(studentData?.className?.length > 0)) {
          notification["error"]({
            message: "Class name is required",
            description: "Please enter class name!",
            duration: 1,
          });
          return;
        }
      }
      setIsSavingCollection(true);
      trackEvent("Save Collection");
      const finalParticulars = collectionData?.particulars.map((p) => {
        const breakup = (p?.breakup || []).map((b) => {
          const paidAmount = b?.amount ? b.amount - b.paidAmount : 0;
          return {
            title: b.title,
            amount: paidAmount,
          };
        });
        const paidAmount = p?.amount ? p.amount - p.paidAmount : 0;
        return {
          title: p.title,
          amount: p?.breakup?.length > 0 ? undefined : paidAmount,
          breakup,
        };
      });

      // Save Images
      const uploadedFiles = studentData?.attachments || [];
      const attachmentsToSave = attachments?.filter(
        ({ isUploaded }) => isUploaded === false
      );
      const filesToUpload = attachmentsToSave?.map(({ file, id }) => ({
        file,
        id,
      }));

      let finalListOfFiles = uploadedFiles || [];
      if (filesToUpload.length) {
        const { data: uploadedFilesResponse } = await saveFeesAsset(
          filesToUpload
        );
        const { success = [] } = uploadedFilesResponse;

        const formattedSuccessFiles = success.map((file) => {
          const { fileName, value: url } = file;
          const id = fileName?.split(".")?.[0];
          const correspondingFile = attachments.find(
            ({ id: currentFileId }) => currentFileId === id
          );
          return {
            size: correspondingFile?.size,
            title: correspondingFile?.title,
            url,
          };
        });
        finalListOfFiles = [...uploadedFiles, ...formattedSuccessFiles];
      }
      const response = await saveFeeCollection({
        feeCollectionId: props?.feeCollectionId,
        particulars: finalParticulars,
        feeStructureId,
        studentId: props.studentId,
        divisionId: props.divisionId,
        note: studentData?.note,
        schoolId: getSchoolID(),
        amountPaid: totalPayableAmount,
        className: studentData?.className,
        studentName: studentData?.studentName,
        receiptNumber: studentData?.receiptNumber,
        paymentMode: studentData?.paymentMode || "Cash",
        attachments: finalListOfFiles,
        createdAt: moment(studentData?.createdAt).format(
          DATE_FORMATS.YYYYMMDDHHMMSSZ
        ),
      });
      if (response?.success) {
        notification["success"]({
          message: "Success",
          description: `Collection ${
            isCollectionSaved ? "Updated" : "Created"
          }!`,
          duration: 1,
        });
        if (props.onSuccess) props.onSuccess();
      } else if (response?.success === false) {
        throw new Error(response.message);
      } else {
        throw new Error("Please try again");
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
        description: "Unable to save fee structure",
        duration: 1.5,
      });
    } finally {
      setIsSavingCollection(false);
    }
  };

  const handleResetParticular = () => {
    refetchCollection();
    setCollectionData(null);
    setShowEditModal(false);
  };

  const renderHistory = ({ timeText, paidAmountText }) => {
    return (
      <p style={style.note} key={timeText}>
        • {timeText} - {paidAmountText}
      </p>
    );
  };

  const renderBreakup = (particular) => {
    return (
      <div style={tableStyle.breakupCover} key={particular.title}>
        <div style={tableStyle.breakupArrow} />
        <span style={tableStyle.breakupTxt}>
          {particular.title}
          <span style={tableStyle.amountNote}>
            &nbsp; (Paid Amount: {particular?.paidAmount}/
            {particular.totalAmount})
          </span>
          {particular.isFullyPaid ? <TickIcon className="h-4 w-4" /> : null}
        </span>
      </div>
    );
  };

  const renderBreakupInput = (particular, index, pindex) => {
    if (particular.isFullyPaid && !isCollectionSaved) {
      return <div className="mt-5 h-8" key={particular.title} />;
    }
    const value = isCollectionSaved
      ? particular.paidAmount
      : particular.newPaidAmount;
    return (
      <input
        key={particular.title}
        type="text"
        style={{
          marginTop: 10,
        }}
        disabled={isCollectionSaved}
        className="mt-3 pl-2 w-auto font-semibold h-8 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
        value={value}
        onChange={(e) =>
          setCollectionBreakAmount(pindex, index, e.target.value)
        }
      />
    );
  };

  const renderParticular = (particular, index) => {
    const hasBreakdown = particular?.breakup?.length > 0;
    return (
      <div style={tableStyle.row} key={particular.title}>
        <div className="w-1/5" style={tableStyle.cell}>
          <p style={tableStyle.text}>{index + 1}</p>
        </div>
        <div className="w-3/5" style={tableStyle.cell}>
          {hasBreakdown ? (
            <>
              <span style={tableStyle.particular} className="mb-2 mt-3">
                {particular.title}
              </span>
              {particular.breakup.map(renderBreakup)}
            </>
          ) : (
            <span style={tableStyle.particular}>
              {particular.title}
              <span style={tableStyle.amountNote}>
                &nbsp; (Paid Amount: {particular?.paidAmount}/
                {particular.totalAmount})
              </span>
              {particular.isFullyPaid ? <TickIcon className="h-4 w-4" /> : null}
            </span>
          )}
        </div>
        <div
          className="w-2/5"
          style={{ ...tableStyle.cell, paddingBottom: 10 }}
        >
          {!particular.isFullyPaid && hasBreakdown ? (
            <>
              <div className="h-6" />
              {particular.breakup.map((p, i) =>
                renderBreakupInput(p, i, index)
              )}
            </>
          ) : !particular.isFullyPaid || isCollectionSaved ? (
            <input
              type="text"
              style={{
                marginTop: 10,
              }}
              disabled={isCollectionSaved}
              className="w-auto pl-2 h-8 font-semibold border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
              value={
                isCollectionSaved
                  ? particular.paidAmount
                  : particular.newPaidAmount
              }
              onChange={(e) => setCollectionAmount(index, e.target.value)}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div style={tableStyle.main}>
        <div style={tableStyle.mainRow}>
          <div className="w-1/5" style={tableStyle.cell}>
            <p style={tableStyle.header}>Sr. No.</p>
          </div>
          <div className="w-3/5" style={tableStyle.cell}>
            <p style={tableStyle.header}>Particulars</p>
          </div>
          <div className="w-2/5" style={tableStyle.cell}>
            <p style={tableStyle.header}>Amount</p>
          </div>
        </div>
        {(collectionData?.particulars || []).map(renderParticular)}
        <div style={tableStyle.mainRow}>
          <div className="w-1/5" style={tableStyle.cell}></div>
          <div className="w-3/5" style={tableStyle.cell}>
            <p style={tableStyle.particular}>Total</p>
          </div>
          <div className="w-2/5" style={tableStyle.cell}>
            <p style={tableStyle.particular}>{totalPayableAmount}</p>
          </div>
        </div>
      </div>
    );
  };

  if (!collectionData || isLoadingCollection) {
    if (isLoadingCollection)
      return (
        <div
          className="flex items-center justify-center"
          style={{ width: 400, height: 100 }}
        >
          <Spin spinning />
        </div>
      );
    return null;
  }

  return (
    <Spin spinning={isSavingCollection}>
      {props.studentType === "NEW" ? null : (
        <div
          className="flex flex-row mb-6 rounded-lg w-3/5 items-center justify-between"
          style={style.card}
        >
          <p style={style.title}>
            Total fees for {studentData.studentName} for this structure
          </p>
          <Button
            buttonStyle="primary"
            className="h-10"
            onClick={() => setShowEditModal(true)}
            disabled={isCollectionSaved}
          >
            <PencilIcon className="h-4 w-4" aria-hidden="true" />
            <span className="ml-2">Edit Fees</span>
          </Button>
        </div>
      )}

      <div className="flex flex-row w-3/5 rounded-lg mb-6" style={style.card}>
        <div style={style.content}>
          <p style={style.title}>Collected Fees</p>
          <p className="text-textGreen" style={style.data}>
            {collectionData?.paidAmountText}
          </p>
        </div>
        <div style={style.content}>
          <p style={style.title}>Remaining Fees</p>
          <p className="text-cancel" style={style.data}>
            {props.studentType === "NEW"
              ? "0"
              : collectionData?.remainingAmountText}
          </p>
        </div>
        <div style={style.historyContent}>
          <p style={style.subTitle}>Collection History</p>
          {collectionData?.history?.length > 0 ? (
            collectionData.history.map(renderHistory)
          ) : (
            <p style={style.note}>No collection history</p>
          )}
        </div>
      </div>

      <div className="w-3/5 rounded-lg mt-6 mb-6" style={style.card2}>
        <div
          className="flex flex-row justify-between"
          style={{ alignItems: "center" }}
        >
          <p style={style.title}>Collect Fees</p>
        </div>

        {renderTable()}
      </div>

      {isCollectionSaved && (
        <div className="flex flex-1 w-3/5 text-left justify-between px-2 rounded-md bg-backgroundGrey">
          <div className="pr-2 -mt-0.5">
            <InfoCircleFilled
              style={{
                color: "#EB5757",
              }}
            />
          </div>
          <p className="font-medium  text-sm text-cancel ">
            Please note that the fields above cannot be edited. If you need to
            make changes to the receipt, kindly delete this collection and
            create a new one.
          </p>
        </div>
      )}

      <div className="flex flex-col mt-6">
        <div className="flex flex-col">
          <div className="flex items-center">
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
              Receipt Number
            </label>
            {!isCollectionSaved && (
              <div className="p-1 bg-backgroundGrey mx-3 rounded-md flex items-center">
                <InfoCircleFilled
                  className="mr-1.5"
                  style={{
                    color: "#6B7280",
                  }}
                />
                <p className="text-xs text-textGrey">
                  Generated based on previous receipt number{" "}
                  {previousReceiptNumber}
                </p>
              </div>
            )}
          </div>
          <input
            type="text"
            className="w-36 border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 mt-3"
            value={studentData?.receiptNumber}
            required
            onChange={(e) => {
              setStudentData({
                ...studentData,
                receiptNumber: e.target.value,
              });
            }}
          />
        </div>
        <div className="flex flex-row gap-x-4 mt-6 mb-6">
          <div className="flex flex-col">
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
              Date/Time
            </label>
            <div className="flex gap-x-4">
              <DatePicker
                value={moment(studentData?.createdAt)}
                format={DATE_FORMATS.DDMMMYYYY}
                onChange={(dateTime) => {
                  setStudentData({
                    ...studentData,
                    createdAt:
                      dateTime || moment().format(DATE_FORMATS.YYYYMMDDHHMMSS),
                  });
                }}
                suffixIcon={<CalendarOutlined style={{ color: "#111827" }} />}
                className="border border-gray-300 p-2 shadow-sm"
                style={{
                  width: 150,
                  borderRadius: 8,
                  height: "40px",
                  fontWeight: "bold",
                }}
              />
              <DatePicker
                value={moment(studentData?.createdAt)}
                onChange={(dateTime) => {
                  setStudentData({
                    ...studentData,
                    createdAt:
                      dateTime || moment().format(DATE_FORMATS.YYYYMMDDHHMMSS),
                  });
                }}
                suffixIcon={
                  <ClockCircleOutlined style={{ color: "#111827" }} />
                }
                picker="time"
                className="border border-gray-300 p-2 shadow-sm"
                format={DATE_FORMATS.HHMMA}
                style={{
                  borderRadius: 8,
                  height: "40px",
                  width: 130,
                  fontWeight: "bold",
                }}
              />
            </div>
          </div>

          <div className="flex flex-col ">
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
              Mode of Payment
              <span className="text-cancel">*</span>
            </label>

            <div
              className="h-10 border border-gray-300 p-2 shadow-sm"
              style={{ borderRadius: 8 }}
            >
              <Radio.Group
                onChange={(e) => {
                  setStudentData({
                    ...studentData,
                    paymentMode: e.target.value,
                  });
                }}
                value={studentData?.paymentMode || "Cash"}
                style={{
                  display: "flex",
                  columnGap: "0.75rem",
                }}
              >
                <div className="bg-white">
                  <Radio value={"Cash"}>
                    <strong className="font-normal text-greyLight">Cash</strong>
                  </Radio>
                </div>
                <div className="bg-white">
                  <Radio value={"Cheque"}>
                    <strong className="font-normal text-greyLight">
                      Cheque
                    </strong>
                  </Radio>
                </div>
                <div className="bg-white">
                  <Radio value={"Online"}>
                    <strong className="font-normal text-greyLight">
                      Online
                    </strong>
                  </Radio>
                </div>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-3/5 mt-6">
        <div className="flex items-center">
          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
            Remark
          </label>
          <div className="p-1 bg-backgroundGrey mx-3 rounded-md flex items-center">
            <InfoCircleFilled
              className="mr-1.5"
              style={{
                color: "#6B7280",
              }}
            />
            <p className="text-xs text-textGrey">
              For internal purpose only. This remark should not be shown on
              receipt
            </p>
          </div>
        </div>

        <input
          type="text"
          className="max-w-3xl border mt-3 border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
          value={studentData?.note}
          required
          placeholder="Type any extra note here..."
          onChange={(e) => {
            setStudentData({
              ...studentData,
              note: e.target.value,
            });
          }}
        />
        <div className="self-end">
          <p className="text-textGrey">0/30 characters</p>
        </div>
      </div>

      <div className="flex flex-col w-3/5 justify-center">
        <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
          Add Attachment
        </label>
        <div className="flex flex-col w-full">
          {attachments.map((attachment) => (
            <div className="flex items-center justify-start">
              <div className="mt-3 p-2 border flex  border-gray-300 rounded-md flex-1 flex-row justify-start">
                <span className="font-semibold">{attachment?.title}</span>
                <span className="ml-2 text-textGrey">
                  ({attachment?.size} kB)
                </span>
              </div>
              <div
                className="hover:cursor-pointer"
                onClick={() => handleDelete(attachment.id)}
                style={{ height: 24, width: 24, marginLeft: 6 }}
              >
                <img src={deleteIcon} alt="Delete Button" />
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-start items-center py-3 h-12 my-3">
          <UploadCard handleUpload={saveFileInMemory} />
          <div className="p-2.5 bg-backgroundGrey mx-3 rounded-md flex items-center">
            <InfoCircleFilled
              className="mr-1.5"
              style={{
                color: "#6B7280",
              }}
            />
            <p className="text-sm text-textGrey">File size limit is 5MB</p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-6">
        <Button
          onClick={saveCollection}
          buttonStyle="primary"
          className="py-3 px-4 w-44 h-12"
        >
          Save Collection
        </Button>
        <Button className="py-3 px-4 w-32 h-12">Cancel</Button>
      </div>

      <Modal
        open={showEditModal}
        centered
        closeIcon={<div />}
        destroyOnClose
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
          rowGap: 10,
        }}
        width="50%"
        onCancel={() => setShowEditModal(false)}
        footer={null}
      >
        <FeeStructureManager
          data={collectionData?.particulars}
          studentName={studentData.studentName}
          studentId={props.studentId}
          divisionId={props.divisionId}
          feeStructureId={feeStructureId}
          onSaveStructure={handleResetParticular}
          onClose={handleResetParticular}
        />
      </Modal>
    </Spin>
  );
});

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return props.children(setNodeRef, attributes, listeners, style);
}

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.4",
      },
    },
  }),
};

export function SortableOverlay({ children }) {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  );
}

const FeeStructureManager = ({
  isVisible,
  onSaveStructure,
  onClose,
  studentName,
  studentId,
  divisionId,
  feeStructureId,
  data = [],
}) => {
  const formatData = (data) => {
    return data.map((p, i) => {
      p.id = `un-${i}`;
      return p;
    });
  };
  const [particulars, setParticulars] = useState(formatData(data));
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: saveStructure } = useSaveStudentFeeStructure();
  let timeout = null;

  const [activeItem, setActiveItem] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const setStructureAmount = (idx, newAmount) => {
    newAmount = parseInt(newAmount, 10);
    if (Number.isNaN(newAmount)) newAmount = 0;
    particulars[idx].totalAmount = newAmount;
    particulars[idx].isEdited = true;
    setParticulars([...particulars]);

    calculateTotalAmount();
  };

  const setParticularTitle = (idx, title) => {
    particulars[idx].title = title;
    particulars[idx].isEdited = true;
    setParticulars([...particulars]);
  };

  const setStructureBreakAmount = (pidx, idx, newAmount) => {
    const breakup = particulars?.[pidx]?.breakup;
    if (!(breakup?.length > 0)) return;
    newAmount = parseInt(newAmount, 10);
    if (Number.isNaN(newAmount)) newAmount = 0;
    breakup[idx].totalAmount = newAmount;
    breakup[idx].isEdited = true;

    let perticularTotal = 0;
    breakup.forEach((b) => {
      perticularTotal += b.totalAmount;
    });
    particulars[pidx].totalAmount = perticularTotal;
    particulars[pidx].breakup = breakup;
    setParticulars([...particulars]);

    calculateTotalAmount();
  };

  const calculateTotalAmount = () => {
    clearTimeout(timeout);
    const setAmount = () => {
      let totalAmount = 0;
      particulars.forEach((p) => {
        if (p?.breakup?.length > 0) {
          p.breakup.forEach((b) => {
            totalAmount += b?.totalAmount || 0;
          });
        } else {
          totalAmount += p?.totalAmount || 0;
        }
      });
      setTotalAmount(totalAmount);
    };
    timeout = setTimeout(() => {
      setAmount();
    }, 500);
  };

  const handleDragEnd = ({ active, over }) => {
    if (over && active.id !== over?.id) {
      const activeIndex = particulars.findIndex(({ id }) => id === active.id);
      const overIndex = particulars.findIndex(({ id }) => id === over.id);

      setParticulars(arrayMove(particulars, activeIndex, overIndex));
    }
    setActiveItem(null);
  };

  const handleDragStart = ({ active }) => {
    let current = particulars.find(({ id }) => {
      return id === active.id;
    });
    if (current) {
      current.isDragging = true;
      setActiveItem(current);
    }
  };

  const addNewPerticular = () => {
    particulars.push({
      id: `up-${particulars.length}`,
      totalAmount: 0,
      title: "",
      isNew: true,
    });
    setParticulars([...particulars]);
  };

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const finalParticulars = particulars.map((p) => {
        const breakup = (p?.breakup || []).map((b) => {
          return {
            title: b.title,
            amount: b.totalAmount,
          };
        });
        return {
          title: p.title,
          amount: breakup?.length > 0 ? undefined : p.totalAmount,
          breakup,
        };
      });
      const response = await saveStructure({
        particulars: finalParticulars,
        feeStructureId,
        studentId,
        divisionId,
      });
      if (response?.success) {
        notification["success"]({
          message: "Success",
          description: "Structure Updated.",
          duration: 1,
        });
        if (onSaveStructure) onSaveStructure(particulars);
      } else if (response?.success === false) {
        throw new Error(response.message);
      } else {
        throw new Error("Please try again");
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
        description: "Unable to save fee structure",
        duration: 1.5,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    calculateTotalAmount();
  });

  const renderBreakup = (particular) => {
    return (
      <div style={tableStyle.breakupCover} key={particular.title}>
        <div style={tableStyle.breakupArrow} />
        <span style={tableStyle.breakupTxt}>{particular.title}</span>
      </div>
    );
  };

  const renderBreakupInput = (particular, index, pindex) => {
    return (
      <input
        type="text"
        style={{
          flex: 1,
          borderTopWidth: index ? 1 : 0,
          width: "100%",
          paddingLeft: 10,
          backgroundColor: particular.isEdited ? "#FFF5D3" : "white",
        }}
        placeholder="Enter Amount"
        className="focus:outline-none font-semibold focus:border-transparent"
        value={particular.totalAmount}
        onChange={(e) => setStructureBreakAmount(pindex, index, e.target.value)}
      />
    );
  };

  const renderParticular = (particular, index, extra = false) => {
    const hasBreakdown = particular?.breakup?.length > 0;
    const extraData =
      extra === true
        ? {
            borderColor: "black",
            borderLeftWidth: 1,
            borderTopWidth: 1,
            boxShadow: materialShadow,
          }
        : {};
    return (
      <SortableItem id={particular.id}>
        {(ref, attributes, listeners, style) => (
          <li
            ref={ref}
            style={{ ...tableStyle.row, ...style, ...extraData }}
            key={particular.id}
          >
            <div
              {...attributes}
              {...listeners}
              className="w-1/5"
              style={tableStyle.cell}
            >
              <MenuOutlined />
            </div>
            <div className="w-3/5" style={tableStyle.cell}>
              {hasBreakdown ? (
                <>
                  <span style={tableStyle.particular} className="mb-2 mt-3">
                    {particular.title}
                  </span>
                  {particular.breakup.map(renderBreakup)}
                </>
              ) : particular.isNew ? (
                <input
                  type="text"
                  style={{
                    flex: 1,
                    borderTopWidth: index ? 1 : 0,
                    borderColor: "black",
                    width: "100%",
                    border: "none",
                    paddingLeft: 10,
                    backgroundColor: particular.isEdited ? "#FFF5D3" : "white",
                  }}
                  placeholder="Enter particular amount"
                  className="focus:outline-none font-semibold focus:border-transparent"
                  value={particular.title}
                  onChange={(e) => setParticularTitle(index, e.target.value)}
                />
              ) : (
                <span style={tableStyle.particular}>{particular.title}</span>
              )}
            </div>
            <div className="w-2/5" style={tableStyle.cell}>
              {!particular.isFullyPaid && hasBreakdown ? (
                particular.breakup.map((p, i) =>
                  renderBreakupInput(p, i, index)
                )
              ) : (
                <input
                  type="text"
                  style={{
                    flex: 1,
                    width: "100%",
                    paddingLeft: 10,
                    backgroundColor: particular.isEdited ? "#FFF5D3" : "white",
                  }}
                  placeholder="Enter Amount"
                  className="focus:outline-none font-semibold focus:border-transparent"
                  value={particular.totalAmount}
                  onChange={(e) => setStructureAmount(index, e.target.value)}
                />
              )}
            </div>
          </li>
        )}
      </SortableItem>
    );
  };

  const renderTable = () => {
    return (
      <div style={tableStyle.main}>
        <div className="bg-lightPurple" style={tableStyle.mainRow}>
          <div className="w-1/5" style={tableStyle.cell}>
            <p style={tableStyle.header}>Sequence</p>
          </div>
          <div className="w-3/5" style={tableStyle.cell}>
            <p style={tableStyle.header}>Particulars</p>
          </div>
          <div className="w-2/5" style={tableStyle.cell}>
            <p style={tableStyle.header}>Amount</p>
          </div>
        </div>
        <DndContext
          id="dnd-kit"
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={() => {
            setActiveItem(null);
          }}
        >
          <SortableContext
            items={particulars || []}
            strategy={verticalListSortingStrategy}
          >
            {(particulars || []).map(renderParticular)}
          </SortableContext>

          <SortableOverlay>
            {activeItem ? renderParticular(activeItem, 0, true) : null}
          </SortableOverlay>
        </DndContext>
        <div className="bg-lightPurple" style={tableStyle.mainRow}>
          <div className="w-1/5" style={tableStyle.cell}></div>
          <div className="w-3/5" style={tableStyle.cell}>
            <p style={tableStyle.particular}>Total</p>
          </div>
          <div className="w-2/5" style={tableStyle.cell}>
            <p style={tableStyle.particular}>{totalAmount}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div
        className="flex flex-row justify-between"
        style={{ alignItems: "center" }}
      >
        <p style={style.modleTitle}>Edit Fees for {studentName}</p>
        <Button
          onClick={addNewPerticular}
          buttonStyle="primary"
          className="h-10"
        >
          <PlusIcon className="h-4 w-4" aria-hidden="true" />
          <span className="ml-2">Add Particular</span>
        </Button>
      </div>
      {renderTable()}
      <div
        className="flex flex-row justify-between"
        style={{ alignItems: "center" }}
      >
        <div className="flex flex-row items-center">
          <div
            style={{
              width: 70,
              height: 40,
              backgroundColor: "#FFF5CF",
              borderWidth: 1,
              borderColor: "#D0D5DC",
            }}
          />
          <p className="ml-2"> - Edited cells</p>
        </div>
        <div
          className="flex flex-row justify-end"
          style={{ alignItems: "center" }}
        >
          <Button className="mr-6" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={saveChanges} buttonStyle="primary" className="h-10">
            <span className="ml-2">Save Changes</span>
          </Button>
        </div>
      </div>
    </Spin>
  );
};

const UploadCard = ({ handleUpload, title }) => {
  const handleCustomRequest = ({ file, onSuccess }) => {
    return handleUpload(file, onSuccess);
  };

  const checkIfFileIsImageOrVideo = (file) => {
    // check if file is an jpeg or png or mp4
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "application/pdf"
    ) {
      message.error("Only jpeg, png and pdf are allowed");
      return false;
    }
    if (file.size > 5 * 1024 * 1024) {
      message.error("File size too large, should be less than 5 MB");
      return false;
    }
    return true;
  };

  return (
    <div>
      <Upload
        customRequest={handleCustomRequest}
        beforeUpload={checkIfFileIsImageOrVideo}
        showUploadList={false}
        className="bg-white rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Button
          style={{
            borderRadius: 6,
            fontWeight: "bold",
            color: "#374151",
          }}
        >
          Upload
        </Button>
      </Upload>
    </div>
  );
};

const TickIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#27AE60" />
      <path
        d="M4 8.58823L6.66667 11.2016L12 5.60156"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const materialShadow =
  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px";
const style = {
  card: {
    padding: 10,
    boxShadow: materialShadow,
  },
  card2: {
    padding: 15,
    boxShadow: materialShadow,
  },
  content: {
    width: "32%",
    paddingLeft: 10,
    paddingBottom: 7,
    paddingRight: 7,
    paddingTop: 7,
    borderRightWidth: 1,
    borderColor: "#D1D5DB",
  },
  historyContent: {
    flex: 1,
    padding: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
  },
  modleTitle: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  data: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  subTitle: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "500",
  },
  note: {
    fontSize: 13,
    textAlign: "left",
  },
};

const tableStyle = {
  main: {
    marginTop: 10,
    marginBottom: 10,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: "black",
  },
  cell: {
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: "black",
    minHeight: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
  },
  mainRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F6F6F6",
  },
  header: {
    fontSize: 14,
    color: "black",
    fontWeight: "500",
  },
  text: {
    fontSize: 14,
    color: "black",
    fontWeight: "bold",
  },
  particular: {
    fontSize: 14,
    color: "black",
    textAlign: "left",
    fontWeight: "600",
    width: "100%",
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },
  amountNote: {
    fontSize: 12,
    color: "#3D3B58",
    textAlign: "left",
    marginRight: 4,
    marginTop: 3,
  },
  breakupTxt: {
    fontSize: 12,
    color: "black",
    textAlign: "left",
    width: "100%",
    paddingLeft: 5,
    marginTop: 2,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
  },
  breakupCover: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginLeft: 25,
    display: "flex",
  },
  breakupArrow: {
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    width: 30,
    height: "100%",
    marginTop: -25,
    borderColor: "#D1D5DB",
  },
};

export default FeeCollectionData;
