import { gql } from "@apollo/client";

export const INSERT_STUDENTS_ONE = gql`
  mutation insert_students_one($objects: students_insert_input!) {
    insert_students_one(
      object: $objects
      on_conflict: {
        constraint: students_first_name_student_ref_school_id_mother_name_key
        update_columns: [
          aadhaar
          admitted_class
          caste
          category
          conduct
          division_id
          dob
          enroll_date
          father_name
          first_name
          gender
          gr_no
          gr_set
          last_name
          left_class
          middle_name
          mother_name
          paying_or_free
          place_of_birth
          religion
          remarks
          rte
          school_id
          student_progress
          student_ref
          last_school
          parents_occupation
          address_id
          weight_term_one
          height_term_one
          weight_term_two
          height_term_two
          mother_tongue
        ]
      }
    ) {
      id
      first_name
      division_id
    }
  }
`;
export const INSERT_PARENT_STUDENT_RELATIONSHIP = gql`
  mutation insertParentStudent($objects: [student_parents_insert_input!]!) {
    insert_student_parents(objects: $objects) {
      affected_rows
    }
  }
`;

export const INSERT_PARENT_STUDENT_RELATIONSHIP_UPDATE_NAME = gql`
  mutation insertParentStudent(
    $objects: [student_parents_insert_input!]!
    $userUpdate: [users_updates!]!
  ) {
    insert_student_parents(objects: $objects) {
      affected_rows
    }
    update_users_many(updates: $userUpdate) {
      affected_rows
    }
  }
`;

export const GET_STUDENTS_BYPK = gql`
  query Students($id: Int!) {
    students_by_pk(id: $id) {
      is_draft
      aadhaar
      admitted_class
      paying_or_free
      last_school
      place_of_birth
      caste
      created_at
      division_id
      dob
      enroll_date
      transfer_date
      transfer_reason
      first_name
      id
      is_draft
      is_active
      last_name
      last_school
      middle_name
      gender
      gr_no
      gr_set
      rte
      category
      father_name
      mother_name
      remarks
      left_class
      conduct
      student_progress
      student_ref
      religion
      image
      roll_no
      parents_occupation
      weight_term_one
      height_term_one
      weight_term_two
      height_term_two
      mother_tongue
      sub_caste
      is_minority
      is_below_poverty_line
      mother_occupation
      no_of_sisters
      no_of_brothers
      total_family_members
      is_handicap
      register_book_no
      last_school_transfer_date
      nationality
      father_education
      mother_education
      blood_group
      last_school_class
      division_students(
        limit: 1
        order_by: { created_at: desc }
        where: { is_active: { _eq: true } }
      ) {
        division_id
        division {
          division_name
          class_id
        }
      }
      division {
        division_name
        id
        class_id
        class {
          class_name
        }
      }
      student_parents {
        id
        relation
        user_id
        user {
          first_name
          mobile_number
          auth_id
        }
      }
      address {
        id
        address_line_one
        address_line_two
        pincode
        city {
          id
          city_name
          district {
            id
            district_name
            state {
              id
              state_name
            }
          }
        }
      }
      subscriptions(
        where: {
          is_active: { _eq: true }
          end_date: { _gte: "now()" }
          start_date: { _lte: "now()" }
        }
      ) {
        id
      }
    }
    audit_trails(
      where: { student_id: { _eq: $id } }
      order_by: { updated_at: desc }
    ) {
      updated_at
      userByAddedBy {
        first_name
        last_name
      }
    }
  }
`;

/**
 * Audit Trial Removed Fix Later
*/ 
export const UPDATE_STUDENTS_ONE = gql`
  mutation updateStudent(
    $_set: students_set_input
    $id: Int!
    $addressid: Int!
    $_setaddress: addresses_set_input
  ) {
    update_students_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      first_name
    }
    update_addresses_by_pk(pk_columns: { id: $addressid }, _set: $_setaddress) {
      id
    }
  }
`;

export const GET_CLASSES_AND_DIVISIONS = gql`
  query GetClasses($schoolId: Int!) {
    classes(
      where: {
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
        acadamic_year: { is_current: { _eq: true }, is_active: { _eq: true } }
      }
    ) {
      id
      class_name
      divisions(where: { is_active: { _eq: true } }) {
        id
        division_name
        exam_type
      }
    }
  }
`;
export const INSERT_STUDENTS = gql`
  mutation insert_students($objects: [students_insert_input!]!) {
    insert_students(
      objects: $objects
      on_conflict: {
        constraint: students_first_name_student_ref_school_id_mother_name_key
        update_columns: [
          aadhaar
          admitted_class
          caste
          category
          conduct
          division_id
          dob
          enroll_date
          father_name
          first_name
          gender
          gr_no
          gr_set
          last_name
          left_class
          middle_name
          mother_name
          paying_or_free
          place_of_birth
          religion
          remarks
          rte
          school_id
          student_progress
          student_ref
          last_school
        ]
      }
    ) {
      affected_rows
      returning {
        id
        division_id
      }
    }
  }
`;

export const GET_STUDENTS_LIST_WITH_FILTER = gql`
  query Students($filters: students_bool_exp, $schoolId: Int!) {
    students(where: $filters, order_by: { updated_at: desc }) {
      id
      first_name
      last_name
      middle_name
      mother_name
      updated_at
      created_at
      image
      is_active
      transfer_date
      division {
        division_name
      }
      student_parents(order_by: { updated_at: desc }) {
        relation
        user {
          full_name: first_name
          mobile_number
        }
      }
      audit_trails(order_by: { updated_at: desc }) {
        updated_at
        userByAddedBy {
          first_name
          last_name
        }
      }
    }
    agg: students_aggregate(
      where: {
        is_draft: { _eq: true }
        division: {
          school_id: { _eq: $schoolId }
          acadamic_year: { is_current: { _eq: true } }
        }
        transfer_date: { _is_null: true }
        is_active: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_DIVISION = gql`
  mutation updateStudentDivision($student_id: Int!, $new_division_id: Int!) {
    update_division_students(
      where: { student_id: { _eq: $student_id }, to_date: { _is_null: true } }
      _set: { to_date: "now()", is_active: false }
    ) {
      affected_rows
    }
    insert_division_students(
      objects: {
        division_id: $new_division_id
        from_date: "now()"
        is_active: true
        student_id: $student_id
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_DIVISION_FOR_BULK_UPLOAD = gql`
  mutation updateStudentDivisionFOrBulkUpload(
    $student_id: [Int!]!
    $objects: [division_students_insert_input!]!
  ) {
    update_division_students(
      where: { student_id: { _in: $student_id }, to_date: { _is_null: true } }
      _set: { to_date: "now()", is_active: false }
    ) {
      affected_rows
    }
    insert_division_students(objects: $objects) {
      affected_rows
    }
  }
`;

export const GET_STUDENTS_LIST_WITH_FILTER_PAGINATED = gql`
  query Students(
    $filters: students_bool_exp
    $schoolId: Int!
    $offset: Int!
    $limit: Int = 7
    $order_by: [students_order_by!] = { updated_at: desc }
  ) {
    students(
      where: $filters
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      first_name
      last_name
      middle_name
      mother_name
      updated_at
      created_at
      image
      is_active
      transfer_date
      division {
        division_name
      }
      student_parents(order_by: { updated_at: desc }) {
        relation
        user {
          full_name: first_name
          mobile_number
        }
      }
      audit_trails(order_by: { updated_at: desc }) {
        updated_at
        userByAddedBy {
          first_name
          last_name
        }
      }
    }
    agg: students_aggregate(
      where: {
        is_draft: { _eq: true }
        division: {
          school_id: { _eq: $schoolId }
          acadamic_year: { is_current: { _eq: true } }
        }
        transfer_date: { _is_null: true }
        is_active: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    students_aggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const ADD_STUDENT_ADDRESS = gql`
  mutation addAddress($address: [addresses_insert_input!]!) {
    insert_addresses(objects: $address) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

/**
 * Audit Trial Removed Fix Later
 */ 
export const UPDATE_STUDENT_ADDRESS_RELATIONSHIP = gql`
  mutation updateAddressRelationship(
    $studentId: Int!
    $addressId: Int!
  ) {
    update_students(
      where: { id: { _eq: $studentId } }
      _set: { address_id: $addressId }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_STUDENT_IMAGE = gql`
  mutation updateStudentImage(
    $studentId: Int!
    $image: String!
  ) {
    update_students(
      where: { id: { _eq: $studentId } }
      _set: { image: $image }
    ) {
      affected_rows
    }
  }
`;
