import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Menu as AntDMenu, Dropdown, Drawer, Spin } from "antd";
import { getSchoolID } from "../shared/getSchoolID";
import MarksTable from "../views/reportsView/marksTable";
import { DownloadIcon } from "@heroicons/react/outline";
import { GET_CLASSES_AND_DIVISIONS } from "../views/studentsView/graphql";
import {
  GET_DIVISION_EXAMS,
  GET_STUDENT_MARKS_DETAILS,
} from "../views/reportsView/graphql";
import { getSortedClasses } from "../helpers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../components/Button";
import CloseIcon from "../components/closeIcon";
import { ClassDivisionSelector } from "../components";
import { EXAM_TERMS } from "../utils/constants";
import GUDropdown from "../components/Dropdown";

import { MultiSelect } from "react-multi-select-component";

const { FIRST_TERM, SECOND_TERM, BOTH_TERM } = EXAM_TERMS;
const examTermOptions = [
  {
    label: "First Term",
    value: FIRST_TERM,
  },
  {
    label: "Second Term",
    value: SECOND_TERM,
  },
  {
    label: "Both (First Term + Second Term)",
    value: BOTH_TERM,
  },
];

const defaultDownloadOptions = [
  {
    value: 2,
    label: "Download Excel",
  },
  {
    value: 1,
    label: "Download Marksheet",
  },
  {
    value: 5,
    label: "Download Marksheet V2",
  },
  {
    value: 4,
    label: "Download घोषवारा",
  },
];

function Reports() {
  const [classData, setclassdata] = useState([]);
  const [divisionData, setdivisiondata] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDivisionName, setSelectedDivisionName] = useState(null);
  const [selectedClassName, setSelectedClassName] = useState(null);
  const [divisionList, setDivisionList] = useState([]);
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);

  const [selectedDetailsExamIndex, setSelectedDetailsExamIndex] = useState(0);
  const [isExamSelectOpen, setIsExamSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //School Term
  const [selectedTermText, setSelectedTermText] = useState(
    examTermOptions[0].label
  );
  const [selectedTerm, setSelectedTerm] = useState(examTermOptions[0].value);

  //Assessments & Exam
  const [visibleAssessments, setVisibleAssessments] = useState([]);
  const [selectedExam, setSelectedExam] = useState([]);

  //Students Data
  const [studentsData, setStudentsData] = useState([]);
  const [studentCount, setStudentCount] = useState(0);

  //Selected Student
  const [selectedStudent, setSelectedStudent] = useState(0);

  //Download Options
  const [downloadOptions, setDownloadOptions] = useState([]);

  const [grades, setGrades] = useState([]);

  const PAGE_LIMIT = 7;

  let navigate = useNavigate();

  const getFilters = () => {
    if (selectedClass !== null && selectedDivision === null) {
      return {
        is_draft: { _eq: false },
        is_active: {
          _eq: true,
        },
        division: {
          class_id: { _eq: selectedClass },
          school_id: { _eq: getSchoolID() },
        },
      };
    } else if (selectedClass !== null && selectedDivision !== null) {
      return {
        is_draft: { _eq: false },
        is_active: {
          _eq: true,
        },
        division_id: { _eq: selectedDivision },
        division: {
          school_id: { _eq: getSchoolID() },
        },
      };
    } else {
      return {
        is_draft: { _eq: false },
        is_active: {
          _eq: true,
        },
        division: {
          school_id: { _eq: getSchoolID() },
        },
      };
    }
  };

  const { loading: classesLoading } = useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (classResponse) => {
      let classLists = [];
      let divList = [];
      const sortedClasses = getSortedClasses(classResponse.classes);
      sortedClasses?.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
        classObj.divisions.map((divz) => {
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: classObj.id,
            exam_type: divz.exam_type,
          });
        });
      });
      setclassdata(classLists);
      setdivisiondata(divList);
    },
  });

  const [getExams, { loading: examsLoading, data: examsData }] =
    useLazyQuery(GET_DIVISION_EXAMS);

  const [getMarksDetails, { loading: marksLoading, data: marksDetails }] =
    useLazyQuery(GET_STUDENT_MARKS_DETAILS);

  useEffect(() => {
    if (examsData && examsData.assessments.length > 0) {
      const assessmentOptions = [];
      examsData.assessments.forEach((as) => {
        if (selectedTerm == 0 || as.term == selectedTerm) {
          assessmentOptions.push({ label: as.title, value: as.id });
        }
      });
      setVisibleAssessments(assessmentOptions);
      setSelectedExam(assessmentOptions);
    }
    setStudentsData([]);
  }, [selectedTerm, examsData]);

  useEffect(() => {
    if (selectedExam?.length > 0) {
      getStudentsData();
    }
  }, [selectedExam]);

  const handleClassChange = (value) => {
    let filterDivsWithClassID = divisionData.filter(
      (div) => div.class_id === parseInt(value)
    );
    setSelectedClass(value);
    setDivisionList(filterDivsWithClassID);
    const className = classData.find((x) => x.value === parseInt(value))?.label;
    setSelectedClassName(className);
    setSelectedDivision(null);
    setSelectedDivisionName(null);
    setSelectedExam([]);
    setVisibleAssessments([]);
    setStudentsData([]);
  };

  const handleDivisionChange = (value) => {
    setSelectedExam([]);
    setVisibleAssessments([]);
    setStudentsData([]);
    setSelectedDivision(value);
    const division = divisionList.find((div) => div.value === parseInt(value));
    if (division.exam_type === "CUSTOM") {
      setDownloadOptions([
        defaultDownloadOptions[0],
        { value: 3, label: "Download PDF" }, //Custom Exam PDF Excel
      ]);
    } else {
      setDownloadOptions(defaultDownloadOptions); //Includes PDF For CCE Exam
    }
    setSelectedDivisionName(division.label);
    getExams({
      variables: {
        divisionId: value,
        examType: division.exam_type,
      },
    });
  };

  const handleDetailsClick = (student) => {
    setSelectedStudent(student);
    setIsDetailsDrawerOpen(true);
  };

  const handleTermChange = (value) => {
    const term = examTermOptions.find((t) => t.value == value);
    setSelectedTerm(value);
    setSelectedTermText(term.label);
  };

  const downloadReport = (docFormat) => {
    if (!selectedExam || !selectedDivision) {
      return;
    }
    const assessmentIds = [];
    for (let assessment of selectedExam) {
      assessmentIds.push(assessment.value);
    }
    setIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getResultReport`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        divisionId: parseInt(selectedDivision),
        assessmentIds: assessmentIds,
        exportType: docFormat,
        term: selectedTerm,
      },
    })
      .then(({ data }) => {
        setIsLoading(false);
        const fileName = `${selectedClassName}_${selectedDivisionName}_Marksheet`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const downloadReportCCE = (isNew = false) => {
    if (!selectedExam || !selectedDivision) {
      return;
    }
    setIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getMarksReportCCE${isNew ? 'V2' : ''}`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        examType: "CCE_MR",
        divisionId: parseInt(selectedDivision),
        term: selectedTerm == 0 ? [1, 2] : [selectedTerm],
      },
    })
      .then(({ data }) => {
        setIsLoading(false);
        const fileName = `${selectedClassName}_${selectedDivisionName}_Marksheet`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const fetchMoreStudents = (offset) => {
    getStudentsData(offset);
  };

  const getStudentsData = (offset = 0, limit = PAGE_LIMIT) => {
    if (selectedExam.length == 0 || selectedDivision == false) return;
    const assessmentIds = [];
    for (let assessment of selectedExam) {
      assessmentIds.push(assessment.value);
    }
    setIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/exams/v1/getMultipleExamStudentsResultData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        divisionId: parseInt(selectedDivision),
        assessmentIds: assessmentIds,
        limit: limit,
        offset: offset,
      },
    })
      .then(({ data }) => {
        if (data?.totalStudents) {
          setStudentsData(data.students);
          setStudentCount(data.totalStudents);
          setGrades(data.grades);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const downloadMenu = (
    <AntDMenu
      onClick={({ key }) => {
        key = parseInt(key);
        if (key === 1 || key === 5) {
          downloadReportCCE(key !== 1);
        } else if (key === 2) {
          downloadReport("xlsx");
        } else if (key === 3 || key === 4) {
          downloadReport("pdf");
        }
      }}
    >
      {downloadOptions.map((div) => (
        <AntDMenu.Item key={div.value} title={div.label}>
          {div.label}
        </AntDMenu.Item>
      ))}
    </AntDMenu>
  );

  const renderStudentMarksDetails = () => {
    return (
      <div className="h-full">
        <div className="pl-6 pr-6">
          <div className="flex justify-between items-center w-full bg-white rounded-xl shadow-sm pt-4 pr-5 pl-5 pb-5 mb-5">
            <p className="text-gray-900 text-xl font-bold">
              {selectedStudent.name}
            </p>
            <p className="text-gray-500">{`Class ${selectedDivisionName}`}</p>
          </div>

          <div className="items-center w-full bg-white rounded-xl shadow-sm pt-4 pr-5 pl-5 pb-5 mb-7">
            <p className="text-black-700 font-semibold">Selected Exams</p>
            {selectedExam.map((exam, index) => {
              return (
                <p className="text-gray-500 mt-2" key={index}>
                  • {exam.label}
                </p>
              );
            })}
          </div>

          <div className="flex justify-between mb-4 text-gray-900 font-semibold">
            <p>Subject</p>
            <div className="flex gap-x-7 w-2/5 justify-between">
              <p>Marks</p>
              <p>Grade</p>
            </div>
          </div>
        </div>
        <div className="bg-white flex flex-col overflow-hidden rounded-3xl h-full pl-8 pr-8 pb-9 pt-5">
          {selectedStudent
            ? selectedStudent.subjects.map((subject) => {
                return (
                  <div className="flex gap-y-9 mb-8 justify-between text-center">
                    <div>
                      <p>{subject.name}</p>
                    </div>
                    <div className="flex gap-x-12 w-2/5 justify-between">
                      <div className="flex justify-between w-full">
                        <p>
                          {subject.total || "-"}/{subject.maxMarks || "-"}
                        </p>
                        <p>{subject.total ? subject.grade : "-"}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col h-screen w-screen pl-0 md:p-4 md:space-y-4 overflow-x-hidden overflow-y-auto">
        <div className="flex flex-row gap-x-4 items-center mb-0 justify-between mr-7">
          <p className="text-2xl text-left font-bold flex-col m-0">
            Marks Report
          </p>
          <Button
            id="marksreport-back"
            onClick={() => navigate(-1)}
            buttonStyle="secondary"
          >
            Back to Reports
          </Button>
        </div>
        <Spin spinning={isLoading || examsLoading}>
          <div className="relative mr-7 h-4/5">
            <div className="flex justify-between mt-4 mb-4">
              <div className="flex gap-x-3 h-10">
                <ClassDivisionSelector
                  id="marksReport-sortBy"
                  classes={classData}
                  divisions={divisionData}
                  onClassChange={handleClassChange}
                  onDivisionChange={handleDivisionChange}
                ></ClassDivisionSelector>
              </div>
              <div className="flex h-10 gap-x-3">
                <GUDropdown
                  id={"asd"}
                  value={selectedTermText}
                  defaultValue={selectedTermText}
                  type="primary"
                  className
                  options={examTermOptions}
                  disabled={!selectedDivisionName}
                  onItemClick={handleTermChange}
                />
                <div style={style.selectWrapper}>
                  <MultiSelect
                    options={visibleAssessments}
                    className="max-w-xs focus:ring-2 focus:outline-none ring-indigo-700 text-left"
                    value={selectedExam}
                    labelledBy={"Select"}
                    overrideStrings={{
                      allItemsAreSelected: "All Exams Selected",
                      search: "Search Exams",
                      selectSomeItems: "Select Exams",
                    }}
                    disabled={!selectedDivisionName}
                    onChange={setSelectedExam}
                    style={{ minWidth: 200 }}
                  />
                </div>
                <Dropdown overlay={downloadMenu}>
                  <button
                    id="marksreport-download"
                    className="py-3 px-4 flex justify-center items-center whitespace-nowrap bg-success  focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-197 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ant-dropdown-trigger"
                  >
                    <DownloadIcon className="h-4 mr-1 w-5" />
                    Download
                    <ChevronDownIcon
                      className="h-4 w-5 ml-4"
                      aria-hidden="true"
                    />
                  </button>
                </Dropdown>
              </div>
            </div>
            {studentsData?.length > 0 ? (
              <MarksTable
                divisionStudents={studentsData}
                selectedExam={selectedExam}
                pageLimit={PAGE_LIMIT}
                grades={grades}
                onDetailsClick={handleDetailsClick}
                fetchMoreStudents={fetchMoreStudents}
                totalStudents={studentCount}
              />
            ) : (
              <div className="flex flex-col gap-y-3 bg-white mt-3 h-screen rounded-lg justify-center shadow-md">
                <p className="font-bold text-2xl text-gray-400">
                  Please select Class & Division
                </p>
              </div>
            )}
          </div>
        </Spin>
      </div>
      <Drawer
        title={
          <div className="text-lg leading-7 font-medium">Marks Details</div>
        }
        placement="right"
        onClose={() => {
          setIsDetailsDrawerOpen(false);
        }}
        visible={isDetailsDrawerOpen}
        destroyOnClose
        contentWrapperStyle={{
          width: "35%",
        }}
        headerStyle={{
          height: "76px",
          backgroundColor: "#F3F4F6",
        }}
        bodyStyle={{
          padding: 0,
          backgroundColor: "#F3F4F6",
        }}
        closeIcon={<CloseIcon />}
      >
        {renderStudentMarksDetails()}
      </Drawer>
    </>
  );
}

const style = {
  selectWrapper: {
    backgroundColor: "#ffffff",
    borderRadius: 7,
    minWidth: 200,
    boxShadow: "0px 1px 1px 0px #00000012",
  },
};
export default Reports;
