/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";
import { v4 as uuid } from "uuid";

const uploadFile = async (file) => {
  const url = `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/aws/uploadDoYouKnowFile`;
  const fileExtension = file.name.split(".").pop();
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", `${uuid()}.${fileExtension}`);
  const res = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res?.data;
};

export default function useUploadDoYouKnowAsset(appName, options = {}) {
  return useMutation({
    mutationFn: (data) => uploadFile(data, appName),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "File Uploaded Successfully",
        duration: 1.5,
      });
    },
    onError: (error) => {
      notification["error"]({
        message: "Could not upload file",
        description: "Please try again.",
        duration: 1.5,
      });
      console.error(error);
    },
    ...options,
  });
}
