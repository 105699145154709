/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getCollectionData = async (payload) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/getFeesCollectionDataForStructure`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (e) {
    return null;
  }
};


export default function useFeesCollectionDataForStructure(payload, options = {}) {
  return useQuery(
    ["feeCollectionForStructure", payload?.feeStructureId, payload?.studenId, payload?.divisionId],
    () => getCollectionData(payload),
    options
  );
}
