import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Spin, Select, Upload, message, Button, notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CustomFieldArrSelect from "../../components/FormSelect";
import CustomInput from "../../components/FormInput";
import { getSchoolID } from "../../shared/getSchoolID";
import { GET_CITIES, GET_DISTRICTS, GET_STATES } from "../settingsView/graphql";
import {
  ADD_TEACHER_ADDRESS,
  ADD_TEACHER_CONTACTS,
  UPDATE_TEACHER_ADDRESS_RELATIONSHIP,
} from "./graphql";
import { getAuthID } from "../../shared/getAuthID";
import { v4 as uuid } from "uuid";
import { getS3Image, getS3Bucket } from "../../shared/s3";
import CustomRadioInput from "../studentsView/CustomRadioInput";
import CustomAntdDatePicker from "../studentsView/CustomDatePicker";
import moment from "moment";
import { DATE_FORMATS } from "../../utils/constants";
import { getSortedLabels } from "../../helpers";
import useTracking from "../../hooks/useTracking";

const parentRelationship = ["Mother", "Father", "Guardian"];
const { Option } = Select;
const imagesS3Bucket = getS3Bucket();

const FormCard = ({ children, final, id }) => {
  return (
    <>
      <div id={id} className="w-3/4 flex flex-col">
        <div className="border-2 bg-white rounded-md mb-1 pt-6">{children}</div>
        {final ? (
          <div className="px-4 py-3 text-right sm:px-6">
            <button
              id="manageteachers-addteacher-cancel"
              type="reset"
              className="py-3 px-3 mr-2 justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100 "
            >
              Cancel
            </button>
            <button
              id="manageteachers-addteacher-save"
              type="submit"
              className="ml-2 inline-flex -mr-6 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};
const FormCol = ({ children, overrideClassnames, id }) => {
  return (
    <div id={id} className={`${overrideClassnames} flex flex-col flex-auto`}>
      {children}
    </div>
  );
};
const FormRow = ({ children, overrideClassnames }) => {
  return (
    <div
      className={`${overrideClassnames} flex flex-row justify-start gap-x-8 mb-4 px-6`}
    >
      {children}
    </div>
  );
};
const FormSideSection = ({ title, description, children }) => {
  return (
    <div className="w-1/4 text-left ">
      <p className="text-lg leading-6 font-medium text-gray-900  ">{title}</p>
      <p className="mt-1 text-sm leading-5 font-normal text-gray-500 ">
        {description}
      </p>
      {children}
    </div>
  );
};

const AddTeacher = ({ onSuccess }) => {
  const [loader, setLoader] = useState(false);
  const [hasImage, setHasImage] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    trigger,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm({
    criteriaMode: "all",
  });
  const stateValue = watch("stateValue");
  const districtValue = watch("districtValue");
  const cityValue = watch("cityValue");
  // reset()
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "contactDetails", // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  );

  const [addAddress] = useMutation(ADD_TEACHER_ADDRESS);
  const [addContacts] = useMutation(ADD_TEACHER_CONTACTS);
  const [updateAddressRelationship] = useMutation(
    UPDATE_TEACHER_ADDRESS_RELATIONSHIP
  );
  const { trackEvent } = useTracking();

  const {
    loading: statesLoading,
    error: statesError,
    data: statesData,
  } = useQuery(GET_STATES);

  const [
    getDistricts,
    { loading: districtsLoading, error: districtsError, data: districtsData },
  ] = useLazyQuery(GET_DISTRICTS);

  const [
    getCities,
    { loading: citiesLoading, error: citiesError, data: citiesData },
  ] = useLazyQuery(GET_CITIES);

  const stateList = statesData?.states?.map((state) => {
    return {
      value: state.id,
      label: state.state_name,
    };
  });
  const districtList = districtsData?.districts?.map((district) => {
    return {
      value: district.id,
      label: district.district_name,
    };
  });
  const cityList = citiesData?.cities?.map((city) => {
    return {
      value: city.id,
      label: city.city_name,
    };
  });
  const onSubmit = (data) => {
    createInFirebase(data);
  };
  const createInFirebase = (payloadFromSubmit) => {
    trackEvent("Add Teacher Save");
    setLoader(true);
    const userPayload = [];
    userPayload.push({
      phone: `+91${payloadFromSubmit.mobileNumber}`,
      first_name: payloadFromSubmit.firstName,
      school_id: getSchoolID(),
      role_id: 2,
      email: payloadFromSubmit.emailAddress,
      middle_name: payloadFromSubmit.middleName,
      last_name: payloadFromSubmit.lastName,
      gender: payloadFromSubmit.gender,
      dob: payloadFromSubmit.dateOfBirth
        ? moment(payloadFromSubmit.dateOfBirth).format(DATE_FORMATS.YYYYMMDD)
        : null,
    });

    if (hasImage) {
      userPayload[0].image = imageID;
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_FUNCTION_ENDPOINT}/createUsers`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { data: userPayload },
    };
    axios(config)
      .then((res) => {
        if (res?.data?.failedArray?.length > 0) {
          console.error("something went wrong");
          notification["error"]({
            message: "Phone number already available",
            description: `Please use a different number`,
            duration: 3,
          });
          setLoader(false);
        } else {
          sendAddress(res.data.successArray, payloadFromSubmit);
        }
      })
      .catch((err) => console.error(err));
  };
  const sendAddress = (sArray, payloadFromSubmit) => {
    const address = {
      address_line_one: payloadFromSubmit.add1,
      address_line_two: payloadFromSubmit.add2,
      pincode: payloadFromSubmit.pincode,
      city_id: parseInt(payloadFromSubmit.cityValue),
    };

    addAddress({ variables: { address: address } })
      .then((response) => {
        if (payloadFromSubmit.contactDetails.length > 0) {
          sendContacts(
            sArray,
            payloadFromSubmit,
            response?.data?.insert_addresses?.returning[0]?.id
          );
        } else {
          sendAddressRelationship(
            sArray,
            response?.data?.insert_addresses?.returning[0]?.id
          );

          setLoader(false);
          onSuccess();
        }
      })
      .catch((reserror) => {
        console.error(reserror);
        setLoader(false);
      });
  };

  const sendContacts = (sArray, payloadFromSubmit, addressID) => {
    const contacts = [];
    payloadFromSubmit?.contactDetails?.forEach((cdeet) => {
      contacts.push({
        full_name: cdeet.fullName,
        mobile_number: cdeet.contactInfo,
        relation: cdeet.contactRelationship,
        auth_id: sArray[0].uid,
      });
    });
    addContacts({ variables: { contacts: contacts } })
      .then((response) => {
        sendAddressRelationship(sArray, addressID);
      })
      .catch((reserror) => {
        console.error(reserror);
        setLoader(false);
      });
  };

  const sendAddressRelationship = (sArray, addressID) => {
    updateAddressRelationship({
      variables: {
        authID: sArray[0].uid,
        addressid: addressID,
        addedBy: getAuthID(),
      },
    })
      .then((response) => {
        setLoader(false);
        onSuccess();
      })
      .catch((reserror) => {
        console.error(reserror);
        setLoader(false);
      });
  };

  const renderImage = () => {
    if (hasImage) {
      return (
        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
          <img
            src={imageURL}
            alt="avatar"
            className="w-full h-full object-cover rounded-md"
          />
        </span>
      );
    }
    return (
      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  };
  const handleUpload = async (file, onSuccess) => {
    const fileId = uuid();
    // get file extension
    const fileExtension = file.name.split(".").pop();
    const key = `${process.env.REACT_APP_S3_BUCKET_FOLDER}/${fileId}.${fileExtension}`;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
    };
    imagesS3Bucket.putObject(params).send((err, data) => {
      if (err) {
        // handle the error here
        console.error("s3 err", err);
      } else {
        // handle the success here
        onSuccess(data.response, file);
        //  store file id in school instead of image url
        const imageKey = `${fileId}.${fileExtension}`;
        // update image in school

        setHasImage(true);
        setImageID(imageKey);
        setImageURL(getS3Image(imageKey));
      }
    });
  };
  const uploadProps = {
    customRequest({ file, onSuccess }) {
      return handleUpload(file, onSuccess);
    },
  };

  return (
    <Spin spinning={loader}>
      <form
        id="manageteachers-addteacherform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex">
          <FormSideSection
            title="Teacher Details"
            description="Personal information of the teacher"
          />
          <FormCard id="manageteachers-addteacherscard">
            <FormRow>
              <FormCol>
                <div>
                  <label className="block text-gray-700 font-semibold text-sm leading-5 text-left">
                    Photo (Optional)
                  </label>
                  <div className="mt-1 flex items-center">
                    {renderImage()}
                    <Upload
                      {...uploadProps}
                      beforeUpload={(file) => {
                        // check if file is an jpeg or png
                        if (
                          file.type !== "image/jpeg" &&
                          file.type !== "image/png"
                        ) {
                          message.error("Only jpeg or png images are allowed!");
                          return false;
                        }
                        // check if file size is less than 2MB
                        if (file.size > 2000000) {
                          message.error("File size should be less than 2MB!");
                          return false;
                        }
                        return true;
                      }}
                      showUploadList={false}
                      className="ml-5 bg-white py-2 px-3 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Button
                        id="manageteachers-upload"
                        style={{
                          borderRadius: 6,
                          fontWeight: "bold",
                          color: "#374151",
                        }}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"manageteachers-addteachersform-firstname"}
                  name="firstName"
                  placeholder="firstName"
                  label="First Name"
                  register={register}
                  rules={{ maxLength: 20 }}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"manageteachers-addteachersform-middlename"}
                  name="middleName"
                  placeholder="middleName"
                  label="Middle Name"
                  register={register}
                  rules={{ maxLength: 20 }}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"manageteachers-addteachersform-lastname"}
                  name="lastName"
                  placeholder="lastName"
                  label="Last Name"
                  register={register}
                  rules={{ maxLength: 20 }}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow grid grid-cols-2>
              <FormCol>
                <CustomInput
                  id={"manageteachers-addteachersform-mobilenumber"}
                  name="mobileNumber"
                  placeholder="mobileNumber"
                  label="Mobile Number"
                  register={register}
                  type="number"
                  onKeyPress={(e) => {
                    if (e.target.value.length >= 10) e.preventDefault();
                  }}
                  rules={{
                    required: true,
                    pattern: /^[6-9]\d{9}$/i,
                    length: 10,
                  }}
                  errors={errors}
                  required
                />
              </FormCol>

              <FormCol>
                <CustomInput
                  id={"manageteachers-addteachersform-emailaddress"}
                  name="emailAddress"
                  placeholder="emailAddress"
                  label="Email"
                  register={register}
                  errors={errors}
                  type="email"
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol overrideClassnames="col-span-1">
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={{ required: "required" }}
                  render={({ field }) => (
                    <>
                      <CustomAntdDatePicker
                        fieldData={{ ...field }}
                        name="dateOfBirth"
                        placeholder="dateOfBirth"
                        label="Date of birth"
                        register={register}
                        errors={errors}
                        onChange={(dob) => setValue(`dateOfBirth`, dob)}
                        required
                      />
                    </>
                  )}
                />
              </FormCol>

              <FormCol overrideClassnames="col-span-2">
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Gender"}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>

                <div className="flex flex-row">
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="M"
                      label="Male"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="F"
                      label="Female"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="T"
                      label="Transgender"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-22 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="NA"
                      label="Not Disclosed"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
          </FormCard>
        </div>
        <div className="flex">
          <FormSideSection
            title="Address"
            description="Teacher's current address"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <CustomInput
                  id={"manageteachers-addteachersform-address-1"}
                  name="add1"
                  placeholder="add1"
                  label="Address Line 1"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <CustomInput
                  id={"manageteachers-addteachersform-address-2"}
                  name="add2"
                  placeholder="add2"
                  label="Address Line 2"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol id={"manageteachers-addteachersform-state"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  State
                </label>
                <Controller
                  name="stateValue"
                  control={control}
                  defaultValue={stateValue}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      loading={statesLoading}
                      className="customised-antd-selector-address"
                      onChange={(value) => {
                        setValue("stateValue", value);
                        setValue("districtValue", null);
                        setValue("cityValue", null);
                        getDistricts({ variables: { stateId: value } });
                      }}
                      size="large"
                      allowClear
                      placeholder="State"
                    >
                      {getSortedLabels(stateList)?.map((classl) => (
                        <Option key={classl.value}>{classl.label}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
              <FormCol id={"manageteachers-addteachersform-district"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  District
                </label>
                <Controller
                  name="districtValue"
                  control={control}
                  defaultValue={districtValue}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="customised-antd-selector-address"
                      onChange={(value) => {
                        setValue("districtValue", value);
                        setValue("cityValue", null);
                        getCities({ variables: { districtId: value } });
                      }}
                      size="large"
                      allowClear
                      placeholder="District"
                    >
                      {getSortedLabels(districtList)?.map((classl) => (
                        <Option key={classl.value}>{classl.label}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol id={"manageteachers-addteachersform-city"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  City
                </label>
                <Controller
                  name="cityValue"
                  control={control}
                  defaultValue={cityValue}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue={cityValue}
                      className="customised-antd-selector-address"
                      onChange={(value) => {
                        setValue("cityValue", value);
                      }}
                      size="large"
                      allowClear
                      placeholder="City"
                    >
                      {getSortedLabels(cityList)?.map((classl) => (
                        <Option key={classl.value}>{classl.label}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Pincode
                </label>
                <CustomInput
                  id={"manageteachers-addteachersform-pincode"}
                  name="pincode"
                  placeholder="pincode"
                  register={register}
                  errors={errors}
                  overrideClassnames="w-56"
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>
        <div className="flex">
          <FormSideSection title="Emergency Contact" description="(Optional)" />
          <FormCard final>
            {fields.map((field, fieldIndex) => (
              <FormRow key={field.id} overrideClassnames="items-end">
                <FormCol>
                  <CustomInput
                    id={"manageteachers-addteachersform-contactname"}
                    key={field.id} // important to include key with field's id
                    register={() =>
                      register(`contactDetails.${fieldIndex}.fullName`, {
                        required: true,
                      })
                    }
                    errors={errors}
                    label="Full Name"
                  />
                </FormCol>
                <FormCol>
                  <CustomInput
                    id={"manageteachers-addteachersform-contactnumber"}
                    key={field.id} // important to include key with field's id
                    name="contactInfo"
                    placeholder="contactInfo"
                    label="Contact information"
                    register={() =>
                      register(`contactDetails.${fieldIndex}.contactInfo`, {
                        required: true,
                        pattern: /^[6-9]\d{9}$/i,
                      })
                    }
                    errors={errors}
                    required
                  />
                </FormCol>
                <FormCol>
                  <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                    Contact relationship
                  </label>
                  <CustomFieldArrSelect
                    id={"manageteachers-addteachersform-contactrelation"}
                    key={field.id} // important to include key with field's id
                    options={parentRelationship}
                    name="contactRelationship"
                    placeholder="contactRelationship"
                    register={() =>
                      register(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        {
                          required: true,
                        }
                      )
                    }
                    errors={errors}
                    defaultValue={`${field.contactRelationship}`}
                    onChange={(value) =>
                      setValue(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        value
                      )
                    }
                    required
                  />
                </FormCol>
                <FormCol>
                  <button
                    type="reset"
                    className="py-3 px-3 mr-2 justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100 "
                    onClick={() => remove(fieldIndex)}
                  >
                    Cancel
                  </button>
                </FormCol>
              </FormRow>
            ))}
            <FormRow>
              <button
                id="manageteachers-addteachersform-addcontact"
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    fullName: "",
                    contactInfo: "",
                    contactRelationship: "",
                  });
                }}
                className="p-2 rounded-md bg-white border border-gray-300 text-xs
              leading-4 font-medium shadow-sm  cursor-pointer "
              >
                {" "}
                Add new contact (optional)
              </button>
            </FormRow>
          </FormCard>
        </div>
      </form>
    </Spin>
  );
};

export default AddTeacher;
