/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getTeacherAttendance = async (payload) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/getTeacherAttendanceList`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    const res = await axios.request(config);
    return res?.data;
  } catch (e) {
    return null;
  }
};

export default function useTeacherAttendance(payload, options = {}) {
  return useQuery(
    ["teacherAttendance", payload?.date],
    () => getTeacherAttendance(payload),
    {
      ...options,
      refetchOnWindowFocus: false,
    }
  );
}
