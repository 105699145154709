import PropTypes from "prop-types";

function TextViewForLabelAndValue ({ title, description, style = '', descStyle = '' }) {
  return (
    <div className={`flex flex-col mt-6 ${style}`}>
      <p className="text-sm leading-5 font-medium text-gray-500 mb-1">
        {title}
      </p>
      <p className={`text-sm leading-5 font-semibold text-gray-900 ${descStyle}`}>
        {description}
      </p>
    </div>
  );
};

TextViewForLabelAndValue.prototypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  style: PropTypes.style
}

export default TextViewForLabelAndValue;