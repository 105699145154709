/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";

const getMonths = async (payload) => {
  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/performance/getAcademicYearMonths`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };
    const { data } = await axios.request(config);
    return data?.data;
  } catch (e) {
    console.error("useGetAcadamicYearMonths Error: ", { e: e.message });
    return null;
  }
};

export default function useGetAcadamicYearMonths(payload, options = {}) {
  return useQuery(["months", payload?.schoolId], () => getMonths(payload), {
    ...options,
  });
}
