import { gql } from "@apollo/client";

export const GET_CALENDAR_EVENTS = gql`
  query getSchoolEvents(
    $schoolId: Int
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    school_events(
      where: {
        _or: [
          {
            _and: [
              { start_at: { _lt: $startDate } }
              { end_at: { _gte: $startDate } }
            ]
          }
          {
            _and: [
              { start_at: { _gte: $startDate } }
              { end_at: { _lte: $endDate } }
            ]
          }
          {
            _and: [
              { start_at: { _lte: $endDate } }
              { end_at: { _gt: $endDate } }
            ]
          }
        ]
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
      }
    ) {
      id
      title
      description
      all_day
      start_at
      end_at
      holiday
      event_type
      is_active
      group_event_id
    }
  }
`;

export const ADD_CALENDAR_EVENT = gql`
  mutation AddSchoolEvent($schoolEvent: school_events_insert_input!) {
    insert_school_events_one(object: $schoolEvent) {
      id
      title
      description
      all_day
      start_at
      end_at
      holiday
      event_type
      is_active
    }
  }
`;

export const EDIT_CALENDAR_EVENT = gql`
  mutation EditSchoolEvent(
    $schoolEvent: school_events_set_input!
    $id: Int!
    $schoolId: Int!
  ) {
    update_school_events(
      _set: $schoolEvent
      where: { id: { _eq: $id }, school_id: { _eq: $schoolId } }
    ) {
      returning {
        id
        title
        description
        all_day
        start_at
        end_at
        holiday
        event_type
        is_active
      }
    }
  }
`;

export const DELETE_CALENDAR_EVENT = gql`
  mutation DeleteSchoolEvent($id: Int!, $schoolId: Int!) {
    update_school_events(
      _set: { is_active: false }
      where: { id: { _eq: $id }, school_id: { _eq: $schoolId } }
    ) {
      returning {
        id
      }
    }
  }
`;
