import { gql } from "@apollo/client";

export const GET_CLASSES = gql`
  query GetClasses($schoolId: Int!) {
    classes(
      where: { school_id: { _eq: $schoolId }, is_active: { _eq: true } }
    ) {
      id
      class_name
      divisions(where: { is_active: { _eq: true } }) {
        id
        division_name
        class_teachers(
          order_by: { id: desc }
          where: {
            is_active: { _eq: true }
            user: { is_active: { _eq: true } }
          }
        ) {
          id
          user {
            full_name: first_name
          }
        }
        division_students_aggregate(
          where: {
            is_active: { _eq: true }
            student: { is_active: { _eq: true }, is_draft: { _eq: false } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
export const GET_STUDENTS_FROM_DIVISION = gql`
  query GetClasses(
    $divid: Int!
    $search: String!
    $orderby: [division_students_order_by!]
  ) {
    division_students(
      where: {
        division_id: { _eq: $divid }
        student: {
          first_name: { _ilike: $search }
          is_active: { _eq: true }
          is_draft: { _eq: false }
        }
        is_active: { _eq: true }
      }
      order_by: $orderby
    ) {
      id
      division {
        division_name
      }
      student {
        id
        first_name
        is_active
        last_name
        middle_name
        updated_at
        image
        roll_no
        audit_trails(order_by: { updated_at: desc }) {
          updated_at
          userByAddedBy {
            first_name
            last_name
          }
        }
        student_parents {
          relation
          user {
            full_name: first_name
            mobile_number
            parent_app_version
          }
        }
        subscriptions(where:{is_active:{_eq:true},start_date:{_lte:"now()"},end_date:{_gte:"now()"}}, order_by: {end_date: desc}){
          end_date
          subscription_plan {
            id
            duration_type
          }
          order {
            amount
          }
        }
      }
    }
  }
`;
export const GET_TEACHER_SUBJECTS = gql`
  query TeacherSubjects($divid: Int!) {
    teacher_subjects(
      where: {
        subject: { division_subjects: { division_id: { _eq: $divid } } }
      }
    ) {
      subject {
        title
        id
      }
      user {
        full_name: first_name
        id: auth_id
      }
    }
  }
`;

export const GET_SUBJECTS_OF_CLASS = gql`
  query ClassSubjects($classid: Int!, $divid: Int!, $withDivisions: Boolean!) {
    subjects(where: { class_id: { _eq: $classid } }) {
      id
      name: title
      selected: is_enable
      division_subjects(
        where: {
          division_id: { _eq: $divid }
          is_active: { _eq: true }
          user: { is_active: { _eq: true } }
        }
        order_by: { id: desc }
      ) @include(if: $withDivisions) {
        subject {
          title
        }
        user {
          auth_id
          teacher_attendance_data {
            is_present
          }
        }
      }
    }
  }
`;
export const GET_SUBJECTS_OF_CLASS_ISENABLE = gql`
  query ClassSubjects($classid: Int!, $divid: Int!, $withDivisions: Boolean!) {
    subjects(where: { class_id: { _eq: $classid } }) {
      id
      name: title
      selected: is_enable
      division_subjects(
        where: {
          division_id: { _eq: $divid }
          is_active: { _eq: true }
          user: { is_active: { _eq: true } }
        }
        order_by: { id: desc }
      ) @include(if: $withDivisions) {
        subject {
          title
        }
        user {
          auth_id
          teacher_attendance_data {
            is_present
          }
        }
      }
    }
  }
`;
export const INSERT_SUBJECTS_OF_CLASS = gql`
  mutation Insert_subjects($objects: [subjects_insert_input!]!) {
    insert_subjects(
      objects: $objects
      on_conflict: { constraint: subjects_pkey, update_columns: is_enable }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_CLASS_TEACHER_WITH_PREVIOUS = gql`
  mutation upsertClassTeacherWithPrevious(
    $divid: Int!
    $teacherid: String!
    $date: date
    $prevTeacher: String!
  ) {
    insert_class_teachers_one(
      object: { division_id: $divid, teacher_id: $teacherid, from_date: $date }
      on_conflict: {
        constraint: class_teachers_pkey
        update_columns: teacher_id
        where: { division_id: { _eq: $divid } }
      }
    ) {
      id
    }
    update_class_teachers(
      where: { teacher_id: { _eq: $prevTeacher }, division_id: { _eq: $divid } }
      _set: { is_active: false, to_date: $date }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_CLASS_TEACHER = gql`
  mutation upsertClassTeacher($divid: Int!, $teacherid: String!, $date: date) {
    insert_class_teachers_one(
      object: { division_id: $divid, teacher_id: $teacherid, from_date: $date }
      on_conflict: {
        constraint: class_teachers_pkey
        update_columns: teacher_id
        where: { division_id: { _eq: $divid } }
      }
    ) {
      id
    }
  }
`;
export const UPDATE_CLASS_TEACHER = gql`
  mutation UpdateClassTeacher($divid: Int!, $teacherid: String!) {
    update_class_teachers(
      where: { division_id: { _eq: $divid }, academic_year_id: { _eq: 1 } }
      _set: { teacher_id: $teacherid }
    ) {
      affected_rows
      returning {
        id
        teacher_id
        academic_year_id
        division_id
      }
    }
  }
`;

export const UPDATE_SUBJECT_TEACHER = gql`
  mutation UpdateSubjectTeacher(
    $divid: Int!
    $subid: Int!
    $teacherid: String!
  ) {
    insert_division_subjects_one(
      object: {
        assigned_to: $teacherid
        division_id: $divid
        subject_id: $subid
      }
      on_conflict: {
        constraint: division_subjects_division_id_subject_id_key
        update_columns: [assigned_to, is_active]
        where: { division_id: { _eq: $divid }, subject_id: { _eq: $subid } }
      }
    ) {
      assigned_to
    }
  }
`;

export const GET_CLASS_TEACHER_AND_TEACHERS_LIST = gql`
  query ClassTeachersAndTeachers($divid: Int!, $schoolId: Int!) {
    class_teacher: divisions(
      where: {
        id: { _eq: $divid }
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
      }
    ) {
      division_name
      class_teachers(
        order_by: { id: desc }
        where: { is_active: { _eq: true }, user: { is_active: { _eq: true } } }
      ) {
        id
        user {
          id: auth_id
          first_name
          last_name
        }
      }
    }
    teachers: users(
      where: {
        role_id: { _eq: 2 }
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
      }
    ) {
      id: auth_id
      full_name: first_name
      first_name
      last_name
      middle_name
      teacher_attendance_data {
        is_present
      }
    }
  }
`;
export const GET_DIVISION_SUBJECTS = gql`
  query DivisionSubjects($divid: Int!) {
    division_subjects(
      where: {
        division_id: { _eq: $divid }
        subject: { is_enable: { _eq: true } }
        is_active: { _eq: true }
        user: { is_active: { _eq: true } }
      }
    ) {
      subject {
        title
        id
      }
      user {
        id: auth_id
        full_name: first_name
      }
    }
  }
`;
export const GET_CLASSES_WITH_FILTER = gql`
  query GetClasses(
    $classfilters: classes_bool_exp
    $divisionFilters: divisions_bool_exp
  ) {
    classes(where: $classfilters) {
      id
      class_name
      divisions(where: $divisionFilters) {
        id
        division_name
        is_promoted
        exam_type
        class_teachers(
          order_by: { id: desc }
          where: {
            is_active: { _eq: true }
            user: { is_active: { _eq: true } }
          }
        ) {
          id
          user {
            full_name: first_name
            first_name
            last_name
          }
        }
        division_students_aggregate(
          where: {
            is_active: { _eq: true }
            student: { is_active: { _eq: true }, is_draft: { _eq: false } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_ACADEMIC_YEAR = gql`
  query getAcademicYear($schoolId: Int!) {
    acadamic_years(
      where: {
        school_id: { _eq: $schoolId }
        from_date: { _lte: "now()" }
        to_date: { _gte: "now()" }
      }
    ) {
      id
      is_active
      title
    }
  }
`;

export const ADD_CLASS = gql`
  mutation AddClass($classData: classes_insert_input!) {
    insert_classes_one(object: $classData) {
      id
    }
  }
`;

export const DELETE_CLASS = gql`
  mutation DeleteClass($id: Int!) {
    update_classes_by_pk(pk_columns: { id: $id }, _set: { is_active: false }) {
      id
    }
    update_divisions(
      where: { class_id: { _eq: $id } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_DIVISION = gql`
  mutation DeleteDivision($id: Int!) {
    update_divisions_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: false }
    ) {
      id
    }
    update_class_teachers(
      where: { division_id: { _eq: $id } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
    update_division_subjects(
      where: { division_id: { _eq: $id } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;

export const GET_STUDENTS_DATA_FROM_DIVISION = gql`
  query getStudentsDataFromDivision($_eq: Int!) {
    division_students_aggregate(
      where: {
        division_id: { _eq: $_eq }
        is_active: { _eq: true }
        student: { is_active: { _eq: true }, is_draft: { _eq: false } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
