import React from "react";
import wohoo from "../../assets/images/wohoo_stats.png";
import { getAvatar } from "../../shared/getAvatar";

const AttendanceCard = ({ divisionsData }) => {
  return (
    <div
      className="shadow-lg rounded-lg bg-white dark:bg-gray-800 h-150 overflow-y-auto"
      tabIndex="0"
    >
      {divisionsData.length > 0 ? (
        <div className="pt-2">
          {divisionsData?.map((division, index) => {
            return (
              <div
                key={index}
                className={`${
                  index === 2 ? "" : "border-b border-gray-300"
                } flex justify-between px-4 py-1`}
              >
                <div className="flex items-center w-2/4">
                  <div className="">
                    {getAvatar(
                      division?.class_teacher?.first_name || "Not",
                      division?.class_teacher?.last_name || "Assigned",
                      division?.class_teacher?.image,
                      "small",
                      division?.class_teacher === null
                        ? { backgroundColor: "#EB5757" }
                        : {}
                    )}
                  </div>
                  <span
                      className={`text-left ml-2 font-semibold text-sm ${
                        division?.class_teacher === null ? "text-cancel" : ""
                      }`}
                    >
                      {division?.class_teacher?.full_name || "Not Assigned"}
                  </span>
                </div>
                <div className="flex flex-wrap flex-row w-3/4 justify-end">
                  {division?.divisions?.map((div, index) => {
                    return (
                      <span
                        className="inline-block h-9 mb-2 ml-4 font-medium text-sm text-cgray bg-red-200  px-4 py-2 rounded uppercase ml-4"
                        key={index}
                      >
                        {div?.division_name}
                      </span>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex items-end h-full justify-between">
          <div className="mt-5 font-semibold text-base leading-6 self-start ml-3 text-left">
            All teachers have marked the attendance
          </div>
          <div className="mb-0 pb-0 object-fit h-full flex justify-center mx-auto">
            <img src={wohoo} alt="" className=" max-h-full" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AttendanceCard;
