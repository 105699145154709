import React from "react";
import PropTypes from "prop-types";
import { Menu as AntDMenu, Dropdown as AntDDropdown } from "antd";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Button from "../Button";

function Dropdown({
  yearId,
  onItemClick,
  value,
  defaultValue,
  options,
  type,
  disabled,
  renderIcon=null,
  ...props
}) {
  const handleItemClick = ({ key }) => {
    onItemClick(key);
  };

  const overlayMenu = (
    <AntDMenu data-testid="dropdown-menu" onClick={handleItemClick}>
      {options?.map((item) => (
        <AntDMenu.Item key={item.value}>{item.label}</AntDMenu.Item>
      ))}
    </AntDMenu>
  );

  return (
    <AntDDropdown overlay={overlayMenu} disabled={disabled}>
      <Button
        buttonId={yearId}
        buttonStyle={type}
        disabled={disabled}
        style={{
          justifyContent: "space-between",
        }}
        {...props}
      > 
        {renderIcon ? renderIcon() : null}
        {value || defaultValue}
        <ChevronDownIcon className="h-4 w-5 ml-4" aria-hidden="true" />
      </Button>
    </AntDDropdown>
  );
}

Dropdown.prototypes = {
  onItemClick: PropTypes.func,
  value: PropTypes.string || PropTypes.number || PropTypes.element,
  defaultValue: PropTypes.string || PropTypes.number || PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Dropdown;
