import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, DatePicker, Form } from "antd";
import moment from "moment";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  isDateTime,
  isGroup,
  inputType,
  isEditable,
  editable,
  children,
  dataIndex,
  examId,
  record,
  column,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (isGroup) return;
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const saveDate = (value) => {
    const values = {};
    const formattedDate = moment(value).format("YYYY-MM-DD hh:mm A");
    if (record[dataIndex] !== formattedDate) {
      record.editedKeys = [
        ...(record?.editedKeys || []),
        {
          id: examId,
          title: dataIndex,
        },
      ];
    }
    values[dataIndex] = formattedDate;
    toggleEdit();
    handleSave({ ...record, ...values });
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  const renderDisplay = () => {
    if (isDateTime) {
      const dateDisplay = record[dataIndex]
        ? moment(record[dataIndex], "YYYY-MM-DD hh:mm A").format("DD MMM YYYY")
        : "-";
      const timeDisplay = record[dataIndex]
        ? moment(record[dataIndex], "YYYY-MM-DD hh:mm A").format("hh:mm A")
        : "-";
      if (dateDisplay === "-" && timeDisplay === "-" && isEditable) {
        return (
          <div
            className="editable-cell-value-wrap text-center items-center flex justify-center whitespace-nowrap"
            style={{
              paddingRight: 24,
            }}
            onClick={toggleEdit}
          >
            <div className="text-left">
              <p className="text-gray-400">Select Date</p>
            </div>
          </div>
        );
      }
      return (
        <div
          className="editable-cell-value-wrap text-center items-center flex justify-center whitespace-nowrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          <div className="text-left">
            <p className="font-semibold">{dateDisplay}</p>
            <p className="text-gray-400">{timeDisplay}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  };

  let childNode = children;
  if (editable) {
    if (isDateTime && editing) {
      childNode = (
        <DatePicker
          ref={inputRef}
          format="YYYY-MM-DD hh:mm A"
          disabledDate={disabledDate}
          showTime={{
            defaultValue: moment("00:00", "hh:mm A"),
            use12Hours: true,
          }}
          onBlur={toggleEdit}
          onOk={saveDate}
          style={{
            width: "10rem",
            height: "6rem",
            outline: "none",
            border: "none",
          }}
        />
      );
    } else {
      childNode = renderDisplay();
    }
  }

  const editedStyle = isEditable
    ? {
        padding: 0,
      }
    : {};
  return (
    <td
      {...restProps}
      style={{
        textAlign: "center",
        borderRightColor: "#d1d5db",
        borderBottomColor: "#d1d5db",
        ...editedStyle,
      }}
    >
      {childNode}
    </td>
  );
};

const EditableHeader = ({
  title,
  column,
  editable,
  isGroup,
  isEditable,
  style,
  children,
  dataIndex,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const [formFields, setFormFields] = useState({});
  let childNode = children;

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (!isEditable) return;
    setEditing(!editing);
  };

  const save = async () => {
    try {
      if (dataIndex !== formFields[dataIndex]) {
        column.editedKeys = [
          ...(column?.editedKeys || []),
          { id: column.id, title: dataIndex },
        ];
      }
      toggleEdit();
      setFormFields({
        ...formFields,
        editedKeys: [...(column?.editedKeys || [])],
      });
      handleSave({ ...column, ...formFields });
    } catch (error) {
      console.error("Save failed:", error);
    }
  };

  if (editable && !isGroup && editing) {
    childNode = (
      <input
        ref={inputRef}
        defaultValue={dataIndex}
        value={formFields[dataIndex]}
        onChange={(e) => {
          setFormFields({ ...formFields, [dataIndex]: e.target.value });
        }}
        onBlur={save}
        style={{
          outline: "none",
          border: "none",
          width: "100%",
          height: "4rem",
          textAlign: "center",
        }}
      />
    );
  } else {
    const editModeStyle =
      editable && !isGroup && isEditable
        ? {
            color: "black",
          }
        : {
            color: "#6B7280",
          };
    childNode = (
      <div
        className="editable-cell-value-wrap font-semibold"
        style={{
          ...style,
          ...editModeStyle,
        }}
        onClick={toggleEdit}
      >
        {formFields[dataIndex] || children}
      </div>
    );
  }

  const editedClass =
    isEditable &&
    formFields?.["editedKeys"]?.some(
      (key) =>
        key.title === dataIndex &&
        formFields[dataIndex] &&
        key.title !== formFields[dataIndex]
    )
      ? "bg-yellow-100"
      : "";
  const editingStyle =
    isEditable && editing && !isGroup
      ? {
          border: "2px solid black",
        }
      : {
          borderRight: "1px solid #d1d5db",
          borderBottom: "1px solid #d1d5db",
        };
  return (
    <td
      {...restProps}
      key={dataIndex}
      style={{
        ...style,
        ...editingStyle,
        minWidth: "6rem",
      }}
      className={`text-center py-3 w-auto ${editedClass}`}
    >
      {childNode}
    </td>
  );
};

function EditableTable({
  tableColumns,
  tableData,
  isEditable,
  isHeaderEditable,
}) {
  const [columns, setColumns] = useState(tableColumns);
  const [data, setData] = useState({
    dataSource: tableData,
    count: 2,
  });
  const [editedFormFields, setEditedFormFields] = useState({});

  useEffect(() => {
    setData({
      dataSource: tableData,
      count: 2,
    });
  }, [tableData]);

  useEffect(() => {
    setColumns(tableColumns);
  }, [tableColumns]);

  const handleColumnSave = (column) => {
    const newColumns = { ...(editedFormFields?.columns || {}) };
    const dataIndex = column.dataIndex;
    const newValue = column[dataIndex];
    newColumns[dataIndex] = newValue;
    newColumns["editedKeys"] = [
      ...(newColumns["editedKeys"] || []),
      {
        id: column.id,
        title: dataIndex,
      },
    ];
    setEditedFormFields({ ...editedFormFields, columns: newColumns });
  };

  const handleRowSave = (row) => {
    const newData = [...data.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setData({
      dataSource: newData,
    });
    setEditedFormFields({
      ...editedFormFields,
      data: newData,
    });
  };

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onHeaderCell: (column) => {
        return {
          column,
          editable: col.editable,
          isGroup: col.isGroup,
          isEditable: isHeaderEditable,
          style: col.headerStyle || {},
          dataIndex: col.dataIndex,
          examId: col.id,
          title: col.title,
          handleSave: handleColumnSave,
        };
      },
      onCell: (record) => ({
        record,
        editable: col.editable,
        isDateTime: col.isDateTime,
        isGroup: col.isGroup,
        isEditable: isEditable,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        examId: col.id,
        title: col.title,
        handleSave: handleRowSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const { dataSource } = data;
  const components = {
    header: {
      row: EditableRow,
      cell: EditableHeader,
    },
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const displayColumns = columns.map(mapColumns);
  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={displayColumns}
        pagination={false}
        scroll={{ x: 400 }}
        className="custom-table"
      />
    </div>
  );
}

EditableTable.defaultProps = {
  tableColumns: [],
  tableData: [],
  isHeaderEditable: true,
};

export default EditableTable;
