import React, { useEffect, useState } from "react";
import ClassDetailedView from "../views/classesView/ClassDetailedView";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_CLASS_TEACHER_AND_TEACHERS_LIST,
  UPSERT_CLASS_TEACHER,
  UPSERT_CLASS_TEACHER_WITH_PREVIOUS,
  GET_STUDENTS_DATA_FROM_DIVISION,
} from "../views/classesView/graphql";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Drawer, Select, Spin, notification } from "antd";
import _ from "lodash";
import StudentDetail from "../views/studentsView/studentDetail";
import CloseIcon from "../components/closeIcon";
import { getSchoolID } from "../shared/getSchoolID";
import { getSortedTeachers } from "../helpers";
import useDeleteClassTeacher from "../hooks/useDeleteClassTeacher";

const ClassStudents = () => {
  const [classTeacher, setclassTeacher] = useState(null);
  const [teachers, setteachers] = useState([]);
  const [classTeacherChanged, setclassTeacherChanged] = useState(false);
  const [duplicateTeacherID, setDuplicateTeacherID] = useState(null);
  const [currentStudent, setcurrentStudent] = useState(null);
  const [draweropen, setdraweropen] = useState(false);
  let params = useParams();
  const { state } = useLocation();
  let navigate = useNavigate();

  const [
    getStudentCount,
    { loading: studentLoading, error: studentError, data },
  ] = useLazyQuery(GET_STUDENTS_DATA_FROM_DIVISION, {
    variables: {
      _eq: parseInt(params.id),
    },
  });

  useEffect(() => {
    getStudentCount();
  }, [getStudentCount]);

  const { loading, error } = useQuery(GET_CLASS_TEACHER_AND_TEACHERS_LIST, {
    variables: {
      divid: parseInt(params.id),
      schoolId: getSchoolID(),
    },
    onCompleted: (res) => {
      setclassTeacher(res?.class_teacher[0]?.class_teachers[0]?.user?.id);
      setDuplicateTeacherID(res?.class_teacher[0]?.class_teachers[0]?.user?.id);
      setteachers(res?.teachers);
    },
  });

  const [
    upsertClassTeacherWithPrevious,
    { data: uctData, loading: uctLoading, error: uctError },
  ] = useMutation(UPSERT_CLASS_TEACHER_WITH_PREVIOUS, {
    refetchQueries: [
      {
        query: GET_CLASS_TEACHER_AND_TEACHERS_LIST,
        variables: {
          divid: parseInt(params.id),
          schoolId: getSchoolID(),
        },
      },
    ],
  });
  const [
    upsertClassTeacher,
    { data: uctData1, loading: uctLoading1, error: uctError1 },
  ] = useMutation(UPSERT_CLASS_TEACHER, {
    refetchQueries: [
      {
        query: GET_CLASS_TEACHER_AND_TEACHERS_LIST,
        variables: {
          divid: parseInt(params.id),
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const { mutateAsync: deleteClassTeacherAssigned } = useDeleteClassTeacher();

  const deleteCurrentTeacher = () => {
    deleteClassTeacherAssigned({
      divisionId: parseInt(params.id),
      teacherId: classTeacher,
    }).then((data) => {
      setclassTeacherChanged(false);
      setclassTeacher(null);
      setDuplicateTeacherID(null);
    });
  };

  const onSaveClassTeacher = () => {
    if (classTeacher === null) {
      deleteCurrentTeacher();
      return;
    }
    if (_.isNil(duplicateTeacherID)) {
      upsertTeacher();
    } else {
      upsertTeacherWithPrevious();
    }
  };

  const handleSuccessNotfication = () => {
    notification["success"]({
      message: "Success",
      description: "Teacher Assigned",
      duration: 1.5,
    });
  }

  const upsertTeacherWithPrevious = () => {
    upsertClassTeacherWithPrevious({
      variables: {
        divid: parseInt(params.id),
        teacherid: classTeacher,
        date: moment(new Date()).format("YYYY-MM-DD"),
        prevTeacher: duplicateTeacherID,
      },
    })
      .then((response) => {
        handleSuccessNotfication();
        setclassTeacherChanged(false);
      })
      .catch((reserror) => console.log(reserror));
  };
  const upsertTeacher = () => {
    upsertClassTeacher({
      variables: {
        divid: parseInt(params.id),
        teacherid: classTeacher,
        date: moment(new Date()).format("YYYY-MM-DD"),
      },
    })
      .then((response) => {
        handleSuccessNotfication();
        setclassTeacherChanged(false);
      })
      .catch((reserror) => console.log(reserror));
  };

  const sortedTeachersList = getSortedTeachers(teachers, true);

  return (
    <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-col w-full px-8 py-3 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <p className="text-2xl mr-4 text-left font-bold">
              Class {state?.division_name}
            </p>
            <p className="text-center pt-1 pl-2 pr-2 h-7 mt-1 rounded-full text-green-800  bg-green-100 font-normal text-sm">
              {data?.division_students_aggregate.aggregate?.count} Students
            </p>
          </div>
          <button
            id="manageclasses-class-back"
            onClick={() => {
              navigate("/class");
            }}
            className="py-3 px-4 flex justify-center items-center  bg-pink-400 hover:bg-pink-500 hover:text-white focus:ring-pink-500 focus:ring-offset-pink-200  transition ease-in duration-200 text-white text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-200"
          >
            Back to Manage Classes
          </button>
        </div>

        <div className="flex flex-row items-end content-end">
          <label
            className="text-gray-700 text-left font-bold text-sm leading-5"
            htmlFor="classTeach"
          >
            Class Teacher
            <span
              id="manageclasses-class-teacher"
              className="flex flex-row mt-1"
            >
              <Select
                name="classTeach"
                id="classTeach"
                className="customised-antd-selector"
                size="large"
                value={classTeacher}
                onChange={(value) => {
                  setclassTeacherChanged(true);
                  setclassTeacher(value);
                }}
                placeholder="Not Assigned"
                showSearch
                style={{ fontWeight: '400' }}
                filterOption={(inputValue, option) =>
                  option.children
                    .join("")
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {sortedTeachersList?.map((teach) => {
                  return (
                    <React.Fragment key={teach.id}>
                      <Select.Option value={teach.id}>
                        {teach.first_name} {teach.middle_name} {teach.last_name}
                      </Select.Option>
                    </React.Fragment>
                  );
                })}
              </Select>
            </span>
          </label>
          {classTeacherChanged ? (
            <Spin spinning={uctLoading || uctLoading1}>
              <button
                id="manageclasses-class-teacher-save"
                onClick={() => onSaveClassTeacher()}
                className="ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white w-16 transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white"
              >
                Save
              </button>
            </Spin>
          ) : null}
        </div>
        <ClassDetailedView
          setdraweropen={(values) => {
            setcurrentStudent(values);
            setdraweropen(true);
          }}
        />
      </div>
      <Drawer
        title={
          <div className="text-lg leading-7 font-medium">Student Details</div>
        }
        placement="right"
        onClose={() => setdraweropen(false)}
        visible={draweropen}
        destroyOnClose
        contentWrapperStyle={{
          width: "35%",
        }}
        headerStyle={{
          height: "76px",
        }}
        bodyStyle={{
          padding: 0,
        }}
        closeIcon={<CloseIcon />}
      >
        <StudentDetail
          studentID={currentStudent}
          onEditClick={() => {
            navigate("/students", {
              state: {
                currentStudent: currentStudent,
                editStudent: true,
                fromClassStudents: true,
              },
            });
          }}
        />
      </Drawer>
    </div>
  );
};
export default ClassStudents;