import React, { useState } from "react";

// Ant Components
import { Modal, Spin } from "antd";

// Custom Components
import CloseIcon from "../../components/closeIcon";
import CustomInput from "../../components/FormInput";
import Button from "../../components/Button";

// Hooks
import { useForm } from "react-hook-form";
import useUpdateAdmissionNote from "../../hooks/useUpdateAdmissionNote";

// Helper functions
import { getSchoolID } from "../../shared/getSchoolID";

const FormCol = ({ id, children, overrideClassnames }) => {
	return (
		<div
			id={id}
			className={`${overrideClassnames} flex flex-col flex-auto mb-5`}
		>
			{children}
		</div>
	);
};

const NoteAdder = ({ onClose, data }) => {
	const {
		register,
		formState: { errors },
	} = useForm({
		criteriaMode: "all",
	});
	const schoolId = getSchoolID();
	const [currentNote, setCurrentNote] = useState(data?.note || "");
	const [isCreating, setIsCreating] = useState(false);
	const { mutateAsync: updateEnquiryNote } = useUpdateAdmissionNote();


	const submitDetails = async () => {
		try {
			setIsCreating(true);
			const response = await updateEnquiryNote({
				id: data.id,
				note: currentNote,
				schoolId,
			});
			if (response?.success && onClose) {
				onClose();
			}
		} catch (err) {
			// Ignore
		} finally {
			setIsCreating(false);
		}
	};

	return (
		<Modal
			visible
			centered
			footer={null}
			bodyStyle={{
				padding: 0,
			}}
			width={400}
			onClose={onClose}
			closable={false}
			closeIcon={null}
		>
			<Spin spinning={isCreating}>
				<div className="px-5 pt-5 my-2 border-gray-300">
					<div className="flex flex-row justify-between">
						<h3 className="font-bold text-lg">Update Note</h3>
						<div className="cursor-pointer" onClick={onClose}>
							<CloseIcon />
						</div>
					</div>
				</div>
				<div className="px-5 pb-5">
					<FormCol>
						<CustomInput
							id={"admission-note"}
							name="note"
							placeholder="note"
							label="Additional Note"
							rules={{
								maxLength: {
									value: 20,
									message:
										"can't be longer than 20 characters",
								},
								required: "required",
							}}
							onChange={(e) => {
								setCurrentNote(e.target.value);
							}}
							value={currentNote}
							register={register}
							errors={errors}
						/>
					</FormCol>

					<div className="flex gap-x-3 justify-end">
						<Button
							id="settings-schooldetails-cancel"
							onClick={onClose}
							className="px-5 h-10"
							buttonStyle="default"
						>
							<span>Cancel</span>
						</Button>

						<Button
							id="settings-schooldetails-save"
							type="button"
							buttonStyle="primary"
							className="px-5 h-10"
							onClick={submitDetails}
						>
							<span>Save</span>
						</Button>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export default NoteAdder;