import React from "react";
import clipboard from "../../assets/images/clipboard.png";
import clipboard2 from "../../assets/images/clipboard2.png";
import clipboard3 from "../../assets/images/clipboard3.png";
import clipboard4 from "../../assets/images/clipboard4.png";
import { useNavigate } from "react-router-dom";

function Documents() {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col ml-6 pl-0 md:p-4 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row gap-x-4 items-center mb-44">
        <p className="text-2xl text-left font-bold flex-col m-0">
          TC/ Documents
        </p>
      </div>
      <div className="flex justify-center items-center gap-x-11">
        <button
          id="documents-transfer"
          onClick={() => {
            navigate(`/documents/transferCertificate`);
          }}
          className="relative text-center"
        >
          <img alt="clipboard" src={clipboard} className="h-44 w-44" />
          <div className="absolute top-10 left-12 text-white font-bold">
            <p>Transfer</p>
            <p>Certificate</p>
          </div>
        </button>
        <button
          id="documents-bonafide"
          onClick={() => {
            navigate(`/documents/bonafideCertificate`);
          }}
          className="relative text-center"
        >
          <img alt="clipboard" src={clipboard2} className="h-44 w-44" />
          <div className="absolute top-10 left-12 text-white font-bold">
            <p>Bonafide</p>
            <p>Certificate</p>
          </div>
        </button>
        <button
          id="documents-nirgam"
          onClick={() => {
            navigate(`/documents/nirgamUtara`);
          }}
          className="relative text-center"
        >
          <img alt="clipboard" src={clipboard3} className="h-44 w-44" />
          <div className="absolute top-10 left-16 text-white font-bold">
            <p>Nirgam</p>
            <p>Utara</p>
          </div>
        </button>
        <button
          id="documents-issuance"
          onClick={() => {
            navigate(`/documents/issuanceHistory`);
          }}
          className="relative text-center"
        >
          <img alt="clipboard" src={clipboard4} className="h-44 w-44" />
          <div className="absolute top-10 left-16 text-white font-bold">
            <p>Issuance</p>
            <p>History</p>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Documents;
