import { gql } from "@apollo/client";

export const GET_STUDENT_ATTENDANCE = gql`
  query StudentsAttendance(
    $startDate: date!
    $endDate: date!
    $limit: Int
    $offset: Int
    $filters: students_bool_exp
  ) {
    students(
      where: $filters
      order_by: { first_name: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      first_name
      last_name
      middle_name
      image
      division {
        id
        division_name
      }
      student_parents(where: { relation: { _eq: "Mother" } }) {
        relation
        user {
          full_name: first_name
        }
      }
      present: student_attendance_data_aggregate(
        where: {
          date: { _gte: $startDate, _lte: $endDate }
          is_present: { _eq: true }
        }
      ) {
        aggregate {
          count
        }
      }
      total: student_attendance_data_aggregate(
        where: { date: { _gte: $startDate, _lte: $endDate } }
      ) {
        aggregate {
          count
        }
      }
    }
    students_aggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ATTENDANCE_DETAILS = gql`
  query attendanceData(
    $studentId: Int!
    $startDate: date
    $endDate: date
    $divisionId: Int!
  ) {
    students(where: { id: { _eq: $studentId } }) {
      id
      image
      first_name
      last_name
      middle_name
      division {
        id
        division_name
      }
      last_absent: student_attendance_data(
        where: { is_present: { _eq: false } }
        limit: 1
        order_by: { date: desc }
      ) {
        date
        is_present
      }
      this_month_present: student_attendance_data_aggregate(
        distinct_on: date
        where: {
          date: { _gte: $startDate, _lte: $endDate }
          is_present: { _eq: true }
        }
      ) {
        aggregate {
          count
        }
      }
      total_month_days: student_attendance_data_aggregate(
        distinct_on: date
        where: { date: { _gte: $startDate, _lte: $endDate } }
      ) {
        aggregate {
          count
        }
      }
      this_year_absent: student_attendance_data_aggregate(
        distinct_on: date
        where: { is_present: { _eq: false }, division_id: { _eq: $divisionId } }
      ) {
        aggregate {
          count
        }
        absent_dates: nodes {
          date
        }
      }
      total_year_days: student_attendance_data_aggregate(
        distinct_on: date
        where: { division_id: { _eq: $divisionId } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_EXAMS = gql`
  query getAssessments($classId: Int!) {
    assessments(
      where: { class_id: { _eq: $classId }, is_active: { _eq: true } }
    ) {
      id
      title
      start_at
      end_at
      subjectCount: assessment_subjects_aggregate(
        where: { is_active: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
      total_marks_this_exam: assessment_subjects_aggregate(
        distinct_on: subject_id
        where: {
          is_active: { _eq: true }
          is_publish: { _eq: true }
          assessment: { is_graded: { _eq: true } }
        }
      ) {
        aggregate {
          sum {
            total_marks
          }
        }
      }
    }
  }
`;

export const GET_DIVISION_EXAMS = gql`
  query getAssessments(
    $divisionId: Int!
    $examType: String!
  ) {
    assessments(
      where: {
        division_id: { _eq: $divisionId }
        is_active: { _eq: true }
        type : { _eq : $examType }
      }
    ) {
      id
      title
      term
      start_at
      end_at
    }
  }
`;

export const GET_STUDENT_EXAM_MARKS = gql`
  query StudentsMarks(
    $limit: Int
    $offset: Int
    $assessmentId: Int!
    $assessments: [Int!]
    $filters: students_bool_exp
  ) {
    students(
      where: $filters
      order_by: { first_name: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      first_name
      last_name
      middle_name
      image
      division {
        id
        division_name
      }
      student_parents(where: { relation: { _eq: "Mother" } }) {
        relation
        user {
          full_name: first_name
        }
      }
      marks_in_selected_exam: assessment_results_aggregate(
        where: {
          assessment_subject: {
            assessment_id: { _eq: $assessmentId }
            is_active: { _eq: true }
            assessment: { is_graded: { _eq: true } }
            is_publish: { _eq: true }
          }
        }
      ) {
        aggregate {
          sum {
            mark
          }
        }
      }
      marks_in_all_exams: assessment_results_aggregate(
        where: {
          assessment_subject: {
            assessment_id: { _in: $assessments }
            is_active: { _eq: true }
            assessment: { is_graded: { _eq: true } }
            is_publish: { _eq: true }
          }
        }
      ) {
        aggregate {
          sum {
            mark
          }
        }
      }
    }
    students_aggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_STUDENT_MARKS_DETAILS = gql`
  query scorecardData($studentId: Int!, $divisionId: Int!) {
    students(
      where: { id: { _eq: $studentId }, division_id: { _eq: $divisionId } }
    ) {
      id
      image
      first_name
      last_name
      middle_name
      division {
        division_name
        acadamic_year {
          title
          from_date
          to_date
        }
        assessments_list: assessment_subjects(distinct_on: assessment_id) {
          assessment {
            title
            id
            start_at
            end_at
            assessment_subjects(
              where: {
                division_id: { _eq: $divisionId }
                is_active: { _eq: true }
              }
            ) {
              subject_id
              total_marks
              is_publish
              subject {
                title
              }
              assessment_results(where: { student_id: { _eq: $studentId } }) {
                mark
                result_status
              }
            }
          }
        }
      }
    }
  }
`;
