import React, { Component, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/auth";
import ClassStudents from "./pages/classStudents";
import Announcements from "./pages/Announcements";
import ManageCalendar from "./pages/manageCalendar";
import ManageClasses from "./pages/ManageClasses";
import ManageStudents from "./pages/manageStudents";
import ManageTeachers from "./pages/manageTeachers";
import ManageExams from "./pages/ManageExams";
import StudentBulkUpload from "./pages/studentBulkUpload";
import TeacherBulkUpload from "./pages/teacherBulkUpload";
import ManageTeacherAvailability from "./pages/TeacherAvailability";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import Settings from "./pages/Settings";
import AttendancePending from "./pages/AttendancePending";
import SuperAdminSettings from "./pages/superAdminSettings";
import SuperAdminCreateSchool from "./pages/superAdminCreateSchool";
import SuperAdminBulkUploadSchools from "./pages/superAdminBulkUploadSchools";
import Reports from "./pages/Reports";
import AttendanceReport from "./pages/attendanceReport";
import MarksReport from "./pages/marksReport";
import ProgressReport from "./pages/Reports/ProgressReport";
import StudentReport from "./pages/Reports/StudentReport";
import Documents from "./pages/Documents";
import TransferCertificate from "./pages/transferCertificate";
import BonafideCertificate from "./pages/bonafideCertificate";
import NirgamUtara from "./pages/nirgamUtara";
import IssuanceHistory from "./pages/issuanceHistory";
import StudentsPromotion from "./pages/StudentsPromotion";
import ManageSubjects from "./pages/ManageSubjects";
import DoYouKnow from "./pages/doYouKnow";

import FeesStructure from "./pages/ManageFees/FeesStructure";
import Expenses from "./pages/ManageFees/Expenses";
import FeesCollection from "./pages/ManageFees/FeesCollection";
import FeesDashboard from "./pages/ManageFees/FeesDashboard";
import NewCollection from "./pages/ManageFees/FeesCollection/NewCollection";
import NewStructure from "./pages/ManageFees/FeesStructure/NewStructure";
import FeesReports from "./pages/ManageFees/Reports";
import StudentFeesReport from "./pages/ManageFees/Reports/StudentFeesReport";
import FeeReceiptSettings from "./pages/ManageFees/FeeReceiptSettings/";

import Admissions from "./pages/Admissions/";
import AdmissionFormSettings from "./pages/Admissions/FormSettings";
import AdmissionFormView from "./pages/Admissions/AdmissionFormView";
import EditAdmissionForm from "./pages/Admissions/EditAdmissionForm";

import DailyTransactions from "./pages/ManageFees/Reports/DailyTransactions";
import MonthlyTransactions from "./pages/ManageFees/Reports/MonthlyTransactions";
import AnnouncementDetails from "./pages/AnnouncementDetails";
import ReceivedAnnouncementDetails from "./pages/ReceivedAnnouncementDetails";
import SchoolInventories from "./pages/SchoolInventories";
import StaffReport from "./pages/Reports/StaffReport";
import Analytics from "./pages/Analytics";
import Layout from "./components/Layout";

export default class Main extends Component {
  render() {
    return (
      <main style={{ minHeight: "100vh" }}>
        <BrowserRouter>
          <Layout>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<PrivateRoute component={Dashboard} />}
                />
                <Route
                  exact
                  path="/auth"
                  element={<PublicRoute component={Auth} />}
                />
                <Route
                  exact
                  path="/class"
                  element={<PrivateRoute component={ManageClasses} />}
                />
                <Route
                  exact
                  path="/class/:id"
                  element={<PrivateRoute component={ClassStudents} />}
                />
                <Route
                  exact
                  path="/class/:id/subjects"
                  element={<PrivateRoute component={ManageSubjects} />}
                />
                <Route
                  exact
                  path="/students"
                  element={<PrivateRoute component={ManageStudents} />}
                />
                <Route
                  exact
                  path="/teachers"
                  element={<PrivateRoute component={ManageTeachers} />}
                />
                <Route
                  exact
                  path="/calendar"
                  element={<PrivateRoute component={ManageCalendar} />}
                />
                <Route
                  exact
                  path="/announcements"
                  element={<PrivateRoute component={Announcements} />}
                />
                <Route
                  exact
                  path="/announcements/detail"
                  element={<PublicRoute component={AnnouncementDetails} />}
                />
                <Route
                  exact
                  path="/announcements/received"
                  element={
                    <PublicRoute component={ReceivedAnnouncementDetails} />
                  }
                />
                <Route
                  exact
                  path="/teacher/availability"
                  element={
                    <PrivateRoute component={ManageTeacherAvailability} />
                  }
                />
                <Route
                  exact
                  path="/settings"
                  element={<PrivateRoute component={Settings} />}
                />
                <Route
                  exact
                  path="/student/bulkUpload"
                  element={<PrivateRoute component={StudentBulkUpload} />}
                />
                <Route
                  exact
                  path="/teacher/bulkUpload"
                  element={<PrivateRoute component={TeacherBulkUpload} />}
                />
                <Route
                  exact
                  path="/attendance"
                  element={<PrivateRoute component={AttendancePending} />}
                />
                <Route
                  exact
                  path="/adminSettings"
                  element={<PrivateRoute component={SuperAdminSettings} />}
                />
                <Route
                  exact
                  path="/createSchool"
                  element={<PrivateRoute component={SuperAdminCreateSchool} />}
                />
                <Route
                  exact
                  path="/bulkUploadSchool"
                  element={
                    <PrivateRoute component={SuperAdminBulkUploadSchools} />
                  }
                />
                <Route
                  exact
                  path="/reports"
                  element={<PrivateRoute component={Reports} />}
                />
                <Route
                  exact
                  path="/attendanceReport"
                  element={<PrivateRoute component={AttendanceReport} />}
                />
                <Route
                  exact
                  path="/marksReport"
                  element={<PrivateRoute component={MarksReport} />}
                />
                <Route
                  exact
                  path="/reports/staffReport"
                  element={<PrivateRoute component={StaffReport} />}
                />
                <Route
                  exact
                  path="/reports/progressReport"
                  element={<PrivateRoute component={ProgressReport} />}
                />
                <Route
                  exact
                  path="/reports/student-report"
                  element={<PrivateRoute component={StudentReport} />}
                />
                <Route
                  exact
                  path="/documents"
                  element={<PrivateRoute component={Documents} />}
                />
                <Route
                  exact
                  path="/documents/transferCertificate"
                  element={<PrivateRoute component={TransferCertificate} />}
                />
                <Route
                  exact
                  path="/documents/bonafideCertificate"
                  element={<PrivateRoute component={BonafideCertificate} />}
                />
                <Route
                  exact
                  path="/documents/nirgamUtara"
                  element={<PrivateRoute component={NirgamUtara} />}
                />
                <Route
                  exact
                  path="/documents/issuanceHistory"
                  element={<PrivateRoute component={IssuanceHistory} />}
                />
                <Route
                  exact
                  path="/students-promotion"
                  element={<PrivateRoute component={StudentsPromotion} />}
                />
                <Route
                  exact
                  path="/manage-exams"
                  element={<PrivateRoute component={ManageExams} />}
                />
                <Route
                  exact
                  path="/do-you-know"
                  element={<PrivateRoute component={DoYouKnow} />}
                />
                <Route
                  exact
                  path="/fees-structure"
                  element={<PrivateRoute component={FeesStructure} />}
                />
                <Route
                  exact
                  path="/expenses"
                  element={<PrivateRoute component={Expenses} />}
                />
                <Route
                  exact
                  path="/fees-collection"
                  element={<PrivateRoute component={FeesCollection} />}
                />
                <Route
                  exact
                  path="/fees-dashboard"
                  element={<PrivateRoute component={FeesDashboard} />}
                />
                <Route
                  exact
                  path="/fees-collection/new-collection"
                  element={<PrivateRoute component={NewCollection} />}
                />
                <Route
                  exact
                  path="/fees-structure/new-structure"
                  element={<PrivateRoute component={NewStructure} />}
                />
                <Route
                  exact
                  path="/fees-reports"
                  element={<PrivateRoute component={FeesReports} />}
                />
                <Route
                  exact
                  path="/fees-reports/student-fees-report"
                  element={<PrivateRoute component={StudentFeesReport} />}
                />
                <Route
                  exact
                  path="/fees-reports/daily-transactions"
                  element={<PrivateRoute component={DailyTransactions} />}
                />
                <Route
                  exact
                  path="/fees-reports/monthly-transactions"
                  element={<PrivateRoute component={MonthlyTransactions} />}
                />
                <Route
                  exact
                  path="/fee-receipt-settings"
                  element={<PrivateRoute component={FeeReceiptSettings} />}
                />
                <Route
                  exact
                  path="/analytics/:divisionId/:studentId"
                  element={<PrivateRoute component={Analytics} />}
                />
                <Route
                  exact
                  path="/school-inventory"
                  element={<PrivateRoute component={SchoolInventories} />}
                />
                <Route
                  exact
                  path="/admissions"
                  element={<PrivateRoute component={Admissions} />}
                />
                <Route
                  exact
                  path="/admissions/form-settings"
                  element={<PrivateRoute component={AdmissionFormSettings} />}
                />
                <Route
                  exact
                  path="/admissions/form/:formId"
                  element={<PrivateRoute component={AdmissionFormView} />}
                />
                <Route
                  exact
                  path="/admissions/edit-form/:formId"
                  element={<PrivateRoute component={EditAdmissionForm} />}
                />
              </Routes>
            </Suspense>
          </Layout>
        </BrowserRouter>
      </main>
    );
  }
}
