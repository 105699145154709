import React from "react";
import { Input, Select, Checkbox } from "antd";

function capitalize(text) {
	return text.replace(/\b\w/g, function (m) {
		return m.toUpperCase();
	});
}

const EditableInput = (field) => {
	const { label, width, onChange } = field;
	const renderField = () => {
		switch (field.type) {
			case "optionInput":
				return (
					<Select
						value={field.value}
						onChange={onChange}
						options={field.options}
						disabled={field?.disabled}
						style={{
							minWidth: 100,
							marginLeft: 10,
							marginRight: 10,
							borderRadius: 5,
						}}
					/>
				);
			case "dateInput":
				return (
					<Input
						value={field?.value}
						type="date"
						onChange={(e) => onChange(e.target.value)}
						disabled={field?.disabled}
						style={{
							marginLeft: 10,
							marginRight: 10,
							borderRadius: 5,
						}}
					/>
				);
			default:
				return (
					<Input
						value={field?.value || field?.defaultValue}
						onChange={(e) => onChange(capitalize(e.target.value))}
						disabled={field?.disabled}
						style={{
							marginLeft: 10,
							marginRight: 10,
							borderRadius: 5,
							textTransform: "capitalize",
						}}
					/>
				);
		}
	};

	return (
		<div
			style={{
				display: "flex",
				width,
				alignItems: "center",
				marginTop: 20,
			}}
		>
			<span style={styles.inputLabel}>{label} :</span>
			{renderField()}
		</div>
	);
};

const EditableForm = ({
	data,
	disabled = false,
	onChange,
	optionData,
	schoolImage,
	schoolData = {},
}) => {
	const yesNoOptions = [
		{
			label: "No",
			value: false,
		},
		{
			label: "Yes",
			value: true,
		},
	];

	const admissionFormFields = [
		// Row 1
		{
			width: "55%",
			label: "Register No",
			type: "textInput",
			id: "registerBookNo",
		},
		{
			width: "45%",
			label: "GR No",
			type: "textInput",
			id: "grNo",
		},
		// Row 2
		{
			width: "33%",
			label: "Class",
			type: "optionInput",
			id: "classId",
			disabled: true,
		},
		{
			width: "33%",
			label: "Admission Date",
			type: "dateInput",
			id: "admissionDate",
		},
		{
			width: "33%",
			label: "Nationality",
			type: "textInput",
			id: "nationality",
		},
		// Row 3
		{
			width: "70%",
			label: "Student Full Name",
			type: "textInput",
			id: "studentName",
		},
		{
			width: "30%",
			label: "Gender",
			type: "optionInput",
			id: "gender",
			options: [
				{
					value: "M",
					label: "Male",
				},
				{
					value: "F",
					label: "Female",
				},
				{
					value: "T",
					label: "Transgender",
				},
			],
		},
		// Row 4
		{
			width: "25%",
			label: "Religion",
			type: "optionInput",
			id: "religion",
			options: [],
		},
		{
			width: "25%",
			label: "Caste",
			type: "textInput",
			id: "caste",
		},
		{
			width: "25%",
			label: "Sub-caste",
			type: "textInput",
			id: "subCaste",
		},
		{
			width: "25%",
			label: "Category",
			type: "optionInput",
			id: "category",
			options: [],
		},
		// Row 5
		{
			width: "65%",
			label: "Aadhar No",
			type: "textInput",
			id: "aadhaarNo",
		},
		{
			width: "35%",
			label: "Student ID",
			type: "textInput",
			id: "studentRef",
		},
		// Row 6
		{
			width: "50%",
			label: "Date of Birth",
			type: "dateInput",
			id: "dateOfBirth",
		},
		{
			width: "50%",
			label: "Place of Birth",
			type: "textInput",
			id: "placeOfBirth",
		},
		// Row 7
		{
			width: "50%",
			label: "Blood Group",
			type: "optionInput",
			id: "bloodGroup",
			options: [],
		},
		{
			width: "50%",
			label: "Mother Tongue",
			type: "textInput",
			id: "motherTongue",
		},
		// Row 8
		{
			width: "60%",
			label: "Father Full Name",
			type: "textInput",
			id: "fatherName",
		},
		{
			width: "40%",
			label: "Contact No",
			type: "textInput",
			id: "fatherMobileNumber",
		},
		// Row 9
		{
			width: "50%",
			label: "Occupation",
			type: "textInput",
			id: "fatherOccupation",
		},
		{
			width: "50%",
			label: "Education",
			type: "textInput",
			id: "fatherEducation",
		},
		// Row 10
		{
			width: "60%",
			label: "Mother Full Name",
			type: "textInput",
			id: "motherName",
		},
		{
			width: "40%",
			label: "Contact No",
			type: "textInput",
			id: "motherMobileNumber",
		},
		// Row 11
		{
			width: "50%",
			label: "Occupation",
			type: "textInput",
			id: "motherOccupation",
		},
		{
			width: "50%",
			label: "Education",
			type: "textInput",
			id: "motherEducation",
		},
		// Row 12
		{
			width: "100%",
			label: "Previous School Name",
			type: "textInput",
			id: "lastSchool",
		},
		// Row 12
		{
			width: "50%",
			label: "Previous Class",
			type: "textInput",
			id: "lastSchoolClass",
		},
		{
			width: "50%",
			label: "Transfer Date",
			type: "dateInput",
			id: "lastSchoolTransferDate",
		},
		// Row 13
		{
			width: "100%",
			label: "Student’s address",
			type: "textInput",
			id: "address",
		},
		// Row 14
		{
			width: "33%",
			label: "Minority",
			type: "optionInput",
			id: "isMinority",
			options: yesNoOptions,
		},
		{
			width: "33%",
			label: "BPL",
			type: "optionInput",
			id: "isBelowPovertyLine",
			options: yesNoOptions,
		},
		{
			width: "33%",
			label: "Handicap",
			type: "optionInput",
			id: "isHandicap",
			options: yesNoOptions,
		},
		// Row 15
		{
			width: "33%",
			label: "Total Sisters",
			type: "textInput",
			id: "noOfSisters",
		},
		{
			width: "33%",
			label: "Total Brothers",
			type: "textInput",
			id: "noOfBrothers",
		},
		{
			width: "33%",
			label: "Total Family Members",
			type: "textInput",
			id: "totalFamilyMembers",
		},
		// Row 16
		{
			width: "100%",
			label: "Any other important note ",
			type: "textInput",
			id: "note",
		},
	];

	const documentFields = [
		{
			id: "birthCertificate",
			label: "Birth Certificate (Copy)",
			type: "optionInput",
			options: yesNoOptions,
		},
		{
			id: "residentProof",
			label: "Birth Certificate (Original)",
			type: "optionInput",
			options: yesNoOptions,
		},
		{
			id: "casteCertificate",
			label: "Caste Certificate (Copy)",
			type: "optionInput",
			options: yesNoOptions,
		},
		{
			id: "studentPhoto",
			label: "Student Photo",
			type: "optionInput",
			options: yesNoOptions,
		},
		{
			id: "markMemo",
			label: "Mark Memo",
			type: "optionInput",
			options: yesNoOptions,
		},
		{
			id: "transerCertificate",
			label: "Transer Certificate (Original)",
			type: "optionInput",
			options: yesNoOptions,
		},
		{
			id: "cumulativeRecordCertificate",
			label: "Cumulative Record Certificate",
			type: "optionInput",
			options: yesNoOptions,
		},
		{
			id: "aadharCard",
			label: "Aadhar Card (Copy)",
			type: "optionInput",
			options: yesNoOptions,
		},
	];

	return (
		<div
			style={{
				...styles.main,
				pointerEvents: disabled ? "none" : "unset",
			}}
			className="bg-pureWhite"
		>
			<div style={styles.content}>
				<div style={styles.header}>
					<img
						src={schoolImage}
						alt="schoolLogo"
						style={styles.schoolLogo}
					/>
					<div style={styles.schoolCover}>
						<div style={styles.trustName}>
							{schoolData?.trustName}
						</div>
						<div style={styles.schoolName}>{schoolData?.title}</div>
						<div style={styles.schoolAddress}>
							{schoolData?.address}
						</div>
					</div>
					<div style={styles.photoCover}>
						<div style={styles.labelText}>Passport size photo</div>
					</div>
				</div>
				<div style={styles.academicYear}>
					Admission Form {schoolData?.academicYear}
				</div>
				<div style={styles.editableContainer}>
					{admissionFormFields.map((m) => (
						<EditableInput
							key={m.id}
							value={data[m.id]}
							onChange={(v) => onChange(m.id, v)}
							type={m.type}
							label={m.label}
							width={m.width}
							options={optionData[m.id] || m?.options}
							disabled={m?.disabled}
						/>
					))}
				</div>

				<div style={styles.subTitle}>Submit Documents</div>
				<div style={styles.editableContainer}>
					{documentFields.map((m) => (
						<div style={{ width: "40%", display: "flex" }}>
							<Checkbox
								checked={data[m.id]}
								onChange={(e) =>
									onChange(m.id, e.target.checked)
								}
							>
								{m.label}
							</Checkbox>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const styles = {
	main: {
		width: "100%",
		display: "flex",
		padding: 10,
	},
	content: {
		width: "100%",
		padding: 20,
		borderRadius: 10,
		borderWidth: 2,
		borderColor: "#1D129E",
		borderStyle: "solid",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		display: "flex",
		alignItems: "center",
	},
	photoCover: {
		height: 140,
		width: 110,
		borderWidth: 1,
		borderColor: "black",
		borderStyle: "solid",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	schoolCover: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	labelText: {
		fontWeight: "500",
		color: "black",
	},
	inputLabel: {
		fontWeight: "500",
		color: "black",
		textAlign: "left",
		whiteSpace: "nowrap",
	},
	schoolLogo: {
		height: 100,
		width: 100,
		borderRadius: 100,
		overflow: "hidden",
		backgroundColor: "#C4C4C4",
	},
	trustName: {
		fontWeight: "500",
		fontSize: 16,
		color: "#525151",
	},
	schoolName: {
		fontWeight: "bold",
		fontSize: 28,
		color: "#1D129E",
	},
	schoolAddress: {
		fontWeight: "bold",
		fontSize: 16,
		color: "black",
	},
	academicYear: {
		marginTop: 10,
		textAlign: "center",
		fontWeight: "600",
		fontSize: 22,
		color: "black",
	},
	editableContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		display: "flex",
		justifyContent: "space-between",
	},
	subTitle: {
		fontWeight: "600",
		textAlign: "left",
		marginTop: 20,
		marginBottom: 10,
		color: "black",
		fontSize: 18,
	},
};

export default EditableForm;