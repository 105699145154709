/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const saveStudentCollection = async (payload) => {
  const config = {
  	validateStatus: () => true,
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/saveStudentCollection`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useSaveStudentCollection(options = {}) {
  return useMutation({
    mutationFn: (data) => saveStudentCollection(data),    
    ...options,
  });
}
