/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";
import { getSchoolID } from "../shared/getSchoolID";

const uploadFiles = async (files) => {
  const url = `${process.env.REACT_APP_NODE_ENDPOINT}/expenses/uploadFeesAsset`;
  const formData = new FormData();

  files.forEach(({ file, id }) => {
    const fileExtension = file.name.split(".").pop();
    formData.append("file", file);
    formData.append("fileName", `${id}.${fileExtension}`);
  });

  formData.append("schoolId", getSchoolID());

  const res = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res?.data;
};

export default function useUploadFeesAsset(options = {}) {
  return useMutation({
    mutationFn: (data) => uploadFiles(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "File Uploaded Successfully",
        duration: 1.5,
      });
    },
    onError: (error) => {
      notification["error"]({
        message: "Could not upload file",
        description: "Please try again.",
        duration: 1.5,
      });
      console.error(error);
    },
    ...options,
  });
}
