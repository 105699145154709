import React, { useState } from "react";
import { Pagination } from "antd";
import _ from "lodash";
import { getAvatar } from "../../shared/getAvatar";
import { convertMarksToGrade } from "../../utils/";

function MarksTable({
  divisionStudents,
  selectedExam,
  onDetailsClick,
  fetchMoreStudents,
  allExams,
  totalStudents,
  pageLimit,
  grades
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page, pageSize) => {
    fetchMoreStudents((page - 1) * pageSize);
    setCurrentPage(page);
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-normal"
                    >
                      Roll No.
                    </th>
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Name
                    </th>                    
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50 text-center border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-normal"
                    >
                      Marks
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-normal"
                    >
                      Overall Percentage
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-normal"
                    >
                      Overall Grade
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-normal"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {divisionStudents?.map((divisionStudent, index) => {
                    const student = divisionStudent;
                    const rowId = "marksreport-student-" + index;
                    const detailsId = "marksreport-details-" + index;
                    const pr = (divisionStudent.total / divisionStudent.max_marks) * 100;
                    const percentage = Number(isNaN(pr) ? 0 : pr).toFixed(2);
                    const grade = convertMarksToGrade(pr, grades);
                    return (
                      <tr id={rowId} key={divisionStudent.id}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                          <p className="text-gray-500">
                            {student?.roll_no}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center text-left">
                            <div className="flex-shrink-0">
                              {getAvatar(student?.name,"",student?.image)}
                            </div>
                            <div className="ml-3">
                              <p className="text-black font-medium whitespace-no-wrap">
                                {student?.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                          <p className="text-black whitespace-no-wrap">
                            {divisionStudent.total}/{divisionStudent.max_marks}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                          <p className="text-black whitespace-no-wrap">
                            {percentage}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                          <p className="text-black whitespace-no-wrap">
                            {grade}
                          </p>
                        </td>
                        <td
                          id={detailsId}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center"
                        >
                          <span
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              onDetailsClick(student);
                            }}
                          >
                            Details
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              size="small"
              pageSize={pageLimit}
              total={totalStudents}
              current={currentPage}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarksTable;
