/* eslint-disable no-console */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const deleteExpense = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/expenses/deleteExpense`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useDeleteExpense(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => deleteExpense(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenses"] });
      queryClient.invalidateQueries({ queryKey: ["dailyTransactions"] });
      notification["success"]({
        message: "Success",
        description: "Expense is deleted",
        duration: 1.5,
      });
    },
    onError: (error) => {
      notification["error"]({
        message: "Failure",
        description: "Could not delete Expense",
        duration: 1.5,
      });
      console.error(error);
    },
    ...options,
  });
}
