// common graphql queries and mutations shared between multiple components
import { gql } from "@apollo/client";

export const GET_ACADEMIC_YEARS = gql`
  query getAcademicYears($schoolId: Int!) {
    acadamic_years(
      where: { school_id: { _eq: $schoolId }, is_active: { _eq: true } }
    ) {
      id
      title
      is_active
      is_current
      from_date
      to_date
    }
  }
`;

export const GET_CURRENT_ACADEMIC_YEAR = gql`
  query getCurrentAcademicYear($schoolId: Int!) {
    acadamic_years(
      where: { school_id: { _eq: $schoolId }, is_active: { _eq: true } , is_current: {_eq:true}}
    ) {
      id
      title
      is_active
      is_current
      from_date
      to_date
    }
  }
`;

export const GET_CLASSES_FOR_YEAR = gql`
  query GetClasses($schoolId: Int!, $academicYearId: Int!) {
    classes(
      where: {
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
        academic_year_id: { _eq: $academicYearId }
      }
    ) {
      id
      class_name
      divisions(where: { is_active: { _eq: true } }) {
        id
        division_name
        is_promoted
        class_teachers(order_by: {id: desc}, where: {is_active: {_eq: true}, user: {is_active: {_eq: true}}}) {
          id
          user {
            full_name: first_name
          }
        }
        division_students_aggregate(
          where: {
            is_active: { _eq: true }
            student: { is_active: { _eq: true }, is_draft: { _eq: false } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
