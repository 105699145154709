import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { notification, Spin } from "antd";
import axios from "axios";
import { getSchoolID } from "../../shared/getSchoolID";
import {
  GET_ACADEMIC_YEARS,
  GET_CURRENT_ACADEMIC_YEAR,
} from "../../shared/graphql";

import SettingsForm from "./SettingsForm";

import {
  GET_SETTINGS,
  CREATE_CLASSES_INIT,
  CREATE_CLASSES,
  GET_ACADEMIC_YEAR,
  UPDATE_SCHOOL,
  GET_STATES,
  GET_DISTRICTS,
  GET_CITIES,
  ADD_ADDRESS,
  UPDATE_USER_ADDRESS,
  GET_BOARD_LIST,
  UPDATE_ACADEMIC_YEAR,
  UPDATE_STUDENT_PROMOTION,
} from "./graphql";

import {
  UPDATE_ADMIN_ROLE,
  UPDATE_ROLLNO_PARAM,
} from "../../graphql/mutations";

import { GET_CLASSES_FOR_YEAR } from "../../shared/graphql";

function Settings() {
  // by default it is true as all input are marked disabled= true
  const [loader, setLoader] = useState(false);
  const [isStudentsNotPromoted, setIsStudentsNotPromoted] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.isEdit) {
    }
  }, [state]);

  const {
    loading: settingsLoading,
    error,
    refetch: refetchSettings,
    data: settingsData,
  } = useQuery(GET_SETTINGS, {
    variables: {
      schoolId: getSchoolID(),
    },
    fetchPolicy: "network-only",
  });

  const { data: academicYearsData, refetch: refetchAcademicYear } = useQuery(
    GET_ACADEMIC_YEARS,
    {
      variables: {
        schoolId: getSchoolID(),
      },
    }
  );
  const { data: currentAcademicYearData } = useQuery(
    GET_CURRENT_ACADEMIC_YEAR,
    {
      variables: {
        schoolId: getSchoolID(),
      },
    }
  );

  const { data: academicYearData } = useQuery(GET_ACADEMIC_YEAR, {
    variables: {
      schoolId: getSchoolID(),
    },
  });

  const [getCurrentYearClasses] = useLazyQuery(GET_CLASSES_FOR_YEAR, {
    onCompleted: (classData) => {
      const isNotPromoted = classData?.classes?.some((classObj) => {
        return classObj?.divisions?.some((division) => {
          return (
            division?.division_students_aggregate?.aggregate?.count > 0 &&
            !division?.is_promoted
          );
        });
      });
      setIsStudentsNotPromoted(isNotPromoted);
    },
  });

  useEffect(() => {
    if (academicYearsData) {
      getCurrentYearClasses({
        variables: {
          schoolId: getSchoolID(),
          academicYearId: academicYearsData?.acadamic_years?.find(
            (academicYear) => academicYear.is_current
          )?.id,
        },
      });
    }
  }, [academicYearsData]);

  const { data: statesData } = useQuery(GET_STATES);

  const [getDistricts, { data: districtsData }] = useLazyQuery(GET_DISTRICTS);

  const [getCities, { data: citiesData }] = useLazyQuery(GET_CITIES);

  const { data: boardListData } = useQuery(GET_BOARD_LIST);

  const [UpdateSchool, { loading: updateSchoolLoading }] = useMutation(
    UPDATE_SCHOOL,
    {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    }
  );

  const [AddAddress, { loading: addAddressLoading }] = useMutation(ADD_ADDRESS);

  const [UpdateUserAddress, { loading: updateUserAddressLoading }] =
    useMutation(UPDATE_USER_ADDRESS, {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    });

  const [updateAcademicYear] = useMutation(UPDATE_ACADEMIC_YEAR, {
    refetchQueries: [
      {
        query: GET_SETTINGS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
      {
        query: GET_ACADEMIC_YEARS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const [updateStudentPromotion] = useMutation(UPDATE_STUDENT_PROMOTION, {
    refetchQueries: [
      {
        query: GET_SETTINGS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const createAdmin = async (payloadforAdmins) => {
    setLoader(true);
    let userPayload = [];
    payloadforAdmins.forEach((usr) => {
      userPayload.push({
        phone: `+91${usr.contactNumber}`,
        first_name: usr.fullName,
        school_id: getSchoolID(),
        admin_type: usr.role,
      });
    });
    // loop userPayload and create user
    const admins = [];
    try {
      for (const user of userPayload) {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_FUNCTION_ENDPOINT}/createAdmin`,
          headers: {
            "Content-Type": "application/json",
          },
          data: { ...user },
          validateStatus: () => true
        };
        const { data } = await axios(config);
        setLoader(false);
        if(data.status) {
          admins.push(user);
          notification["success"]({ message: "Created admin " + user.first_name, duration: 1.5 });
        } else {
          notification["error"]({ message: "Unable to create admin " + user.first_name, duration: 1.5 });
          return false;
        }        
      }
    } catch (err) {
      notification["error"]({ message: err.message, duration: 1.5 });
      return null;
    }
  };

  const generateRollNumber = () => {
    setLoader(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/generateRollNo`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        schoolId: getSchoolID(),
        acadamicId: parseInt(currentAcademicYearData?.acadamic_years?.[0]?.id),
      },
    };

    axios(config)
      .then((res) => {
        const { success } = res?.data;
        if (success === true)
          notification["success"]({
            message: res?.data?.message,
            duration: 1.5,
          });
        else
          notification["error"]({
            message: "Could not generate roll numbers",
            duration: 1.5,
          });
        setLoader(false);
      })
      .catch((err) => {
        notification["error"]({ message: error, duration: 1.5 });
        setLoader(false);
      });
  };

  const [AddClassesInit, { loading: addClassesInitLoading }] = useMutation(
    CREATE_CLASSES_INIT,
    {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    }
  );

  const [AddClasses, { loading: addClassesLoading }] = useMutation(
    CREATE_CLASSES,
    {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    }
  );

  const handleUpdateAcademicYear = (newAcademicYearId) => {
    updateAcademicYear({
      variables: {
        schoolId: getSchoolID(),
        newAcademicYearId,
      },
    }).then(() => {
      notification["success"]({
        message: "Updated values",
        description: `for school ${settingsData?.schools_by_pk?.title}`,
        duration: 2,
      });
    });
  };

  const handleUpdateStudentPromotion = (isStudentPromotion) => {
    updateStudentPromotion({
      variables: {
        schoolId: getSchoolID(),
        studentPromotion: isStudentPromotion,
      },
    }).then(() => {
      notification["success"]({
        message: "Updated values",
        description: `for school ${settingsData?.schools_by_pk?.title}`,
        duration: 2,
      });
    });
  };

  const handlePromotionsSettingsSave = (formData) => {
    if (!formData?.academicYear?.isCurrent) {
      handleUpdateAcademicYear(formData.academicYear.value);
    }
    handleUpdateStudentPromotion(formData.isPromotion);
  };

  const [updateRollno] = useMutation(UPDATE_ROLLNO_PARAM, {
    refetchQueries: [
      {
        query: GET_SETTINGS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const [updateAdminRole] = useMutation(UPDATE_ADMIN_ROLE, {
    refetchQueries: [
      {
        query: GET_SETTINGS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const renderSettings = () => {
    if (settingsLoading) {
      return (
        <div>
          <p>Loading</p>
        </div>
      );
    }
    if (error) {
      return (
        <div>
          <p>{error.message}</p>
        </div>
      );
    }

    return (
      <SettingsForm
        updateAdminRole={updateAdminRole}
        generateRollNumber={generateRollNumber}
        updateRollno={updateRollno}
        settingsData={settingsData}
        refetchSettings={refetchSettings}
        updateSchool={UpdateSchool}
        createAdmin={createAdmin}
        academicYearData={academicYearData}
        createClassesInit={AddClassesInit}
        createClasses={AddClasses}
        isClassDetailsLoading={addClassesLoading || addClassesInitLoading}
        states={statesData?.states}
        boards={boardListData?.boards}
        getDistricts={getDistricts}
        districts={districtsData?.districts}
        getCities={getCities}
        cities={citiesData?.cities}
        refetchAcademicYear={refetchAcademicYear}
        addAddress={AddAddress}
        updateUserAddress={UpdateUserAddress}
        isSchoolDetailsLoading={
          updateSchoolLoading || addAddressLoading || updateUserAddressLoading
        }
        academicYearsData={academicYearsData}
        onPromotionsSave={handlePromotionsSettingsSave}
        isStudentsNotPromoted={isStudentsNotPromoted}
      />
    );
  };

  const loading = loader || settingsLoading;
  return (
    <div className="flex flex-col px-8 py-3 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={loading}>
        <div className="flex flex-row gap-x-x4 items-center mb-8 justify-between">
          <p className="text-2xl text-left font-bold flex-col m-0 ">
            Edit Settings
          </p>
        </div>
        {renderSettings()}
      </Spin>
    </div>
  );
}

export default Settings;
