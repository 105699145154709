/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";

const getPerformanceTopics = async (variables) => {
  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/performance/subjectData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: variables,
    };
    const { data } = await axios.request(config);
    return data?.data;
  } catch (e) {
    console.error({ e: e.message });
    return null;
  }
};

export default function usePerformanceTopics(
  studentId,
  learningId,
  options = {}
) {
  return useQuery(
    ["performanceTopics", studentId, learningId],
    () =>
      getPerformanceTopics({
        studentId,
        learningId,
      }),
    {
      enabled: !!studentId && !!learningId,
      ...options,
    }
  );
}
