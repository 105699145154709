import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import { getS3Image } from "../../shared/s3";
import moment from "moment";
import {
  PencilIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import { Button, Text } from "../../components";
import { convertDateToWords } from "../../utils";
import AutoCompleteInput from "../../components/AutoCompleteInput";
const googleTransliterate = require("../../utils/googleInputTool");

const FormCol = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};
const FormRow = ({ children, className }) => {
  return <div className={`${className} flex my-2`}>{children}</div>;
};

const FieldAutoCompleteInput = ({
  id,
  label,
  formData,
  shouldDisplayInput,
  options,
  field,
  overrideClasses,
  onBlur,
  onChange,
  onSelect,
  viewOnly,
  value,
  style,
}) => {
  const displayValue = value ? value : `${formData?.[field] || ""}`;
  return (
    <div className="flex">
      {label && (
        <label className="font-bold text-gray-700 mr-1 text-lg">
          <Text id={id} /> :
        </label>
      )}
      {shouldDisplayInput ? (
        <AutoCompleteInput
          field={field}
          options={options}
          formData={formData}
          onBlur={onBlur}
          onChange={onChange}
          onSelect={onSelect}
          style={style}
        />
      ) : (
        <strong className="font-bold text-black text-lg">{displayValue}</strong>
      )}
    </div>
  );
};

const FieldDateInput = ({
  id,
  label,
  shouldDisplayInput,
  field,
  formData,
  onChange,
}) => {
  return (
    <>
      {label && (
        <label className="font-bold text-gray-700 mr-1 text-lg">
          <Text id={id} /> :
        </label>
      )}
      {shouldDisplayInput ? (
        <DatePicker
          value={
            formData?.[field] ? moment(formData?.[field], "DD/MM/YYYY") : null
          }
          onChange={onChange}
          suffixIcon={null}
          format="DD/MM/YYYY"
          className="rounded-md"
          allowClear={false}
          style={{
            borderRadius: "6px",
          }}
        />
      ) : (
        <strong className="font-bold text-black text-lg">{`${
          formData?.[field] || ""
        }`}</strong>
      )}
    </>
  );
};

function NirgamUtaraView({
  isMasked,
  isEditing,
  isEditable,
  isEditFormat,
  translatedData,
  data,
  onCancel,
  onEdit,
  onSave,
  onIssueDocument,
  onDownloadDocument,
  viewOnly,
  containerStyle,
  languageInputCode,
  getTranslation,
  selectedLanguage,
}) {
  const [documentFormData, setDocumentFormData] = useState({});
  const [isDocumentExpanded, setIsDocumentExpanded] = useState(false);
  const [inputOptions, setInputOptions] = useState([]);
  const isEditingStudent = isEditing && !isEditFormat;

  const onToggleDocumentView = () => {
    setIsDocumentExpanded(!isDocumentExpanded);
  };

  useEffect(() => {
    if (data) {
      // get image base64 from local storage
      const localBase64 = localStorage.getItem(data.image);
      // set imageUrl in tcFormData as localBase64 if exists else get and set imageUrl from s3
      if (localBase64) {
        setDocumentFormData({
          ...data,
          ...translatedData,
          imageUrl: localBase64,
        });
      } else {
        const imageUrl = getS3Image(data.image);
        setDocumentFormData({ ...data, ...translatedData, imageUrl });
      }
    }
  }, [data, translatedData]);

  const handleSave = () => {
    onSave(documentFormData);
  };

  const handleIssueAndDownload = () => {
    onIssueDocument(documentFormData);
    onDownloadDocument();
  };

  const getOptionValue = (str, repeat = 1) => ({
    value: str.repeat(repeat),
  });

  const onSearch = (searchText) => {
    const document = data?.settings?.documents?.find(
      (doc) => doc.code === "nu"
    );
    const documentInputCode = languageInputCode || document?.googleInputCode;
    if (!documentInputCode || documentInputCode === "en") {
      return;
    }
    let sourceText = searchText;
    let inputLanguage = documentInputCode;
    let maxResult = 8;
    let request = new XMLHttpRequest();
    googleTransliterate(request, sourceText, inputLanguage, maxResult).then(
      function (response) {
        const suggestionOptions = response.map((item) =>
          getOptionValue(item?.[0])
        );
        setInputOptions(suggestionOptions);
      }
    );
  };

  const dataToDisplay = translatedData
    ? {
        ...documentFormData,
        ...translatedData,
      }
    : documentFormData;
  const { trustName, schoolName, cityName, stateName } = dataToDisplay;

  const renderInputField = ({
    id,
    label,
    key,
    overrideClasses,
    viewOnly,
    value,
  }) => {
    const displayValue = value ? value : `${dataToDisplay?.[key] || ""}`;
    return (
      <div className="flex">
        {label && (
          <label className="font-bold text-gray-700 mr-1 text-lg">
            <Text id={id} /> :
          </label>
        )}
        {isEditingStudent && !viewOnly ? (
          <input
            type="text"
            className={`border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 text-sm ${overrideClasses}`}
            value={documentFormData[key]}
            onChange={(e) =>
              setDocumentFormData({
                ...documentFormData,
                [key]: e.target.value,
              })
            }
          />
        ) : (
          <strong className="font-bold text-black text-lg">
            {displayValue}
          </strong>
        )}
      </div>
    );
  };

  const renderDateField = ({ id, label, key, viewOnly }) => {
    return (
      <>
        {label && (
          <label className="font-bold text-gray-700 mr-1 text-lg">
            <Text id={id} /> :
          </label>
        )}
        {isEditingStudent && !viewOnly ? (
          <DatePicker
            value={
              documentFormData?.[key]
                ? moment(documentFormData?.[key], "DD/MM/YYYY")
                : null
            }
            onChange={(value, dateString) => {
              setDocumentFormData({
                ...documentFormData,
                [key]: dateString,
              });
            }}
            suffixIcon={null}
            format="DD/MM/YYYY"
            className="rounded-md"
            allowClear={false}
            style={{
              borderRadius: "6px",
            }}
          />
        ) : (
          <strong className="font-bold text-black text-lg">{`${
            documentFormData?.[key] || ""
          }`}</strong>
        )}
      </>
    );
  };

  const onAutoCompleteChange = (value, field) => {
    onSearch(value);
    setDocumentFormData({
      ...documentFormData,
      [field]: value,
    });
  };

  const onAutoCompleteSelect = (value, field) => {
    setDocumentFormData({
      ...documentFormData,
      [field]: value,
    });
    setInputOptions([]);
  };

  const onDobChange = async (value, dateString) => {
    let dobWords = convertDateToWords(dateString);
    if (selectedLanguage !== "en") {
      const translatedData = await getTranslation({
        textObj: {
          dobWords: dobWords || "-",
        },
        sourceLang: "en",
        targetLang: selectedLanguage,
      });
      const translatedDOBWords = translatedData?.dobWords;
      setDocumentFormData({
        ...documentFormData,
        dateOfBirth: dateString,
        dateOfBirthInWords: translatedDOBWords,
      });
    } else {
      setDocumentFormData({
        ...documentFormData,
        dateOfBirth: dateString,
        dateOfBirthInWords: dobWords,
      });
    }
  };

  return (
    <form
      id="document"
      className={`${
        isMasked && "opacity-20"
      } flex flex-col overflow-x-hidden bg-white mt-2 h-3/4 rounded-lg p-6`}
      style={containerStyle}
    >
      <div
        className="relative overflow-y-hidden border-4 border-indigo-600 rounded-xl"
        style={{
          height: isDocumentExpanded ? "1500px" : "380px",
          maxHeight: isDocumentExpanded ? "1500px" : "380px",
          transition: "all 1s ease-out",
        }}
      >
        <div className="relative overflow-y-scroll box-border h-full w-full flex flex-col p-2">
          {data?.image && (
            <img
              id="school-image"
              alt="Logo"
              src={documentFormData?.imageUrl}
              className="h-36 w-36 ml-4 mt-4 top-2 absolute"
            />
          )}
          {!data?.image && (
            <div
              id="school-logo"
              className="h-28 w-28 ml-4 mt-4 top-2 bg-gray-200 absolute"
            ></div>
          )}
          <div className="flex flex-col justify-center items-center">
            {isEditing && !isEditingStudent ? (
              <div className="flex mb-2">
                <label className="font-bold text-gray-700 mr-1">
                  <Text id="slogan" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="slogan"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "slogan");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "slogan");
                  }}
                />
              </div>
            ) : (
              <h4 className="font-bold text-indigo-700 text-base">
                {data ? data?.slogan || "" : "Slogan"}
              </h4>
            )}
            {isEditing && !isEditingStudent ? (
              <div className="flex mb-2">
                <label className="font-bold text-gray-700 mr-1">
                  <Text id="trust-name" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="trustName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "trustName");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "trustName");
                  }}
                />
              </div>
            ) : (
              <h4 className="font-bold text-gray-700 text-lg">
                {data || translatedData ? trustName || "" : "Trust Name"}
              </h4>
            )}
            {isEditing && !isEditingStudent ? (
              <div className="flex">
                <label className="font-bold text-gray-700 mr-1">
                  <Text id="school-name" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="schoolName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "schoolName");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "schoolName");
                  }}
                />
              </div>
            ) : (
              <h3
                id="school-name"
                className="font-bold text-indigo-700 text-3xl"
              >
                {schoolName || "School Name"}
              </h3>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="city" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="cityName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setDocumentFormData({
                      ...documentFormData,
                      cityName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setDocumentFormData({
                      ...documentFormData,
                      cityName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <></>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="state" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="stateName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setDocumentFormData({
                      ...documentFormData,
                      stateName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setDocumentFormData({
                      ...documentFormData,
                      stateName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <></>
            )}
            {!isEditing && !isEditingStudent && (
              <h4 className="text-black font-bold text-base">
                {cityName || "City"}, {stateName || "State"}
              </h4>
            )}
          </div>
          <div className="flex text-left gap-x-56 items-center justify-center">
            <FormCol>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="school-registration-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={documentFormData.schoolRegistrationNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        schoolRegistrationNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.schoolRegistrationNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="u-dise-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={documentFormData.udiseNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        udiseNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.udiseNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="index-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={documentFormData?.indexNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        indexNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.indexNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="mobile-number" /> :
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={documentFormData.mobileNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        mobileNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.mobileNumber}
                  </p>
                )}
              </FormRow>
            </FormCol>
            <FormCol>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1">&nbsp;</label>
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="board" /> :{" "}
                </label>
                {isEditing ? (
                  <AutoCompleteInput
                    field="board"
                    options={inputOptions}
                    formData={documentFormData}
                    onBlur={(e) => {
                      setInputOptions([]);
                    }}
                    onChange={(e) => {
                      onAutoCompleteChange(e.target.value, "board");
                    }}
                    onSelect={(value) => {
                      onAutoCompleteSelect(value, "board");
                    }}
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.board}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="school-medium" /> :{" "}
                </label>
                {isEditing ? (
                  <AutoCompleteInput
                    field="schoolMedium"
                    options={inputOptions}
                    formData={documentFormData}
                    onBlur={(e) => {
                      setInputOptions([]);
                    }}
                    onChange={(e) => {
                      onAutoCompleteChange(e.target.value, "schoolMedium");
                    }}
                    onSelect={(value) => {
                      onAutoCompleteSelect(value, "schoolMedium");
                    }}
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.schoolMedium}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="email" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                    value={documentFormData.email}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        email: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.email}
                  </p>
                )}
              </FormRow>
            </FormCol>
          </div>
          <div
            id="title-container"
            className="grid grid-flow-col gap-x-3 pl-28 pr-28 mt-7 mb-0 text-center justify-center items-center"
          >
            <div
              id="title-border-left"
              className="border-b-4 w-36 border-indigo-600 m-auto"
            ></div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div id="docTitle" className="text-3xl w-auto mb-auto">
              <p
                style={{
                  fontFamily: containerStyle?.fontFamily || "Kurale",
                }}
              >
                <Text id="nirgam-utara" />
              </p>
            </div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div
              id="title-border-right"
              className="border-b-4 w-36 border-indigo-600 m-auto"
            ></div>
          </div>
          {data?.isIssued && (
            <div
              id="duplicate-watermark"
              className="text-lg font-bold text-red-500"
            >
              <p>DUPLICATE COPY</p>
            </div>
          )}
          <div className="flex flex-col gap-x-32 text-left mx-14 mt-6">
            <div className="flex gap-x-8 justify-between text-left mx-11 mr-12">
              <FormCol className="flex flex-col">
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="serial-number" /> :
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={documentFormData?.serialNumber}
                      onChange={(e) =>
                        setDocumentFormData({
                          ...documentFormData,
                          serialNumber: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {documentFormData?.serialNumber ||
                        documentFormData?.nextSerialNumber}
                    </p>
                  )}
                </FormRow>
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="student-id" /> :{" "}
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={documentFormData.studentRef}
                      onChange={(e) =>
                        setDocumentFormData({
                          ...documentFormData,
                          studentRef: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {documentFormData?.studentRef}
                    </p>
                  )}
                </FormRow>
              </FormCol>
              <FormCol className="flex flex-col">
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="general-registration-number" /> :{" "}
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={documentFormData.generalRegisterNumber}
                      onChange={(e) =>
                        setDocumentFormData({
                          ...documentFormData,
                          generalRegisterNumber: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {documentFormData?.generalRegisterNumber}
                    </p>
                  )}
                </FormRow>
              </FormCol>
              <FormCol className="flex flex-col items-end -ml-8">
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="date" /> :
                  </label>
                  {isEditingStudent ? (
                    <DatePicker
                      value={
                        documentFormData?.date
                          ? moment(documentFormData?.date, "DD/MM/YYYY")
                          : null
                      }
                      onChange={(value, dateString) => {
                        setDocumentFormData({
                          ...documentFormData,
                          date: dateString,
                        });
                      }}
                      suffixIcon={null}
                      format="DD/MM/YYYY"
                      className="rounded-md"
                      allowClear={false}
                      style={{
                        borderRadius: "6px",
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {documentFormData?.date}
                    </p>
                  )}
                </FormRow>
                <FormRow>
                  <label className="font-bold text-gray-700 mr-1 text-base whitespace-nowrap">
                    <Text id="uid-number" /> (<Text id="aadhar-number" />) :{" "}
                  </label>
                  {isEditingStudent ? (
                    <input
                      type="text"
                      className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                      value={documentFormData?.uidNumber}
                      onChange={(e) =>
                        setDocumentFormData({
                          ...documentFormData,
                          uidNumber: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="font-bold text-black text-base">
                      {documentFormData?.uidNumber &&
                      documentFormData?.uidNumber !== "null" &&
                      documentFormData?.uidNumber !== "undefined"
                        ? documentFormData?.uidNumber
                        : ""}
                    </p>
                  )}
                </FormRow>
              </FormCol>
            </div>
          </div>
          <div className="flex flex-col border border-gray-400 mx-11 rounded-lg mt-14">
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 mt-4 mb-4 justify-center items-center font-bold text-gray-700">
                <label>
                  <Text id="admission-number" />
                </label>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                {renderInputField({
                  key: "generalRegisterNumber",
                })}
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <label>
                  <Text id="student-full-name" />
                </label>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="studentName"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "studentName");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "studentName");
                  }}
                  style={{
                    width: 350,
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <label>
                  <Text id="mother-name" />
                </label>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="motherName"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "motherName");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "motherName");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <label>
                  <Text id="father-name" />
                </label>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="fatherName"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "fatherName");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "fatherName");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <label>
                  <Text id="father-occupation" />
                </label>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="fatherOccupation"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "fatherOccupation");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "fatherOccupation");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <label>
                  <Text id="caste" />
                </label>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="caste"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "caste");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "caste");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="mother-tongue" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="motherTongue"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "motherTongue");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "motherTongue");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="date-of-birth" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldDateInput
                  field="dateOfBirth"
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onChange={onDobChange}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="date-of-birth-in-words" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="dateOfBirthInWords"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "dateOfBirthInWords");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "dateOfBirthInWords");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="date-of-admission" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                {renderDateField({
                  key: "dateOfAdmission",
                })}
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="admitted-class" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="admittedClass"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "admittedClass");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "admittedClass");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="previous-school" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="previousSchool"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "previousSchool");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "previousSchool");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="previous-school-class" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="previousSchoolClass"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "previousSchoolClass");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "previousSchoolClass");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="nirgam-date" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                {renderDateField({
                  key: "transferDate",
                })}
              </div>
            </div>
            <div className="flex justify-between text-left border-b border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="nirgam-class" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="class"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "class");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "class");
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between text-left border-gray-400 text-lg">
              <div className="w-1/2 border-gray-400 pl-7 py-3 font-bold text-gray-700">
                <p>
                  <Text id="reason-to-leave-school" />
                </p>
              </div>
              <div className="border-l w-1/2 border-gray-400 pl-7 py-3">
                <FieldAutoCompleteInput
                  field="reasonForTransfer"
                  options={inputOptions}
                  formData={documentFormData}
                  shouldDisplayInput={isEditingStudent}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "reasonForTransfer");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "reasonForTransfer");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-end justify-end mt-28 mb-1 font-bold text-gray-700 text-lg border-b-4 border-indigo-600 pb-3 mr-6 ml-6 pl-6">
            <p>
              <Text id="principal-signature" />
            </p>
          </div>
          <p className="text-red-600 font-medium mt-1 text-lg">
            <Text id="note-legal-nu" />
          </p>
        </div>
        <Button
          id="documents-nirgam-view"
          buttonStyle="primary"
          className="h-5 pt-4 z-10 flex items-center justify-center text-center absolute right-0 bottom-0 rounded-tl-md rounded-bl-none rounded-br-lg rounded-tr-none"
          onClick={onToggleDocumentView}
        >
          {isDocumentExpanded ? "Minimize document" : "View entire document"}
          {isDocumentExpanded ? (
            <ChevronUpIcon className="h-4 w-4 ml-3" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 ml-3" aria-hidden="true" />
          )}
        </Button>
      </div>
      <div id="form-controls" className="flex justify-end pt-6">
        {isEditing && (
          <Button
            id="documents-nirgam-edit-cancel"
            style={{
              minWidth: "6rem",
            }}
            buttonStyle="default"
            onClick={() => onCancel()}
            className="h-12"
          >
            Cancel
          </Button>
        )}
        {isEditing && (
          <button
            id="documents-nirgam-edit-save"
            type="button"
            className="h-12 py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              handleSave();
            }}
          >
            <span className="ml-2">Save Changes</span>
          </button>
        )}
        {!isEditing && isEditable && data && !data?.isIssued && (
          <button
            id="documents-nirgam-editdetails"
            type="button"
            className="h-12 py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              onEdit();
            }}
          >
            <span className="flex justify-center items-center gap-x-1">
              <PencilIcon className="h-4 w-4" />
              Edit Details
            </span>
          </button>
        )}
        {!isEditing && isEditFormat && (
          <button
            id="documents-nirgam-edit-format"
            type="button"
            className="h-12 py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              onEdit();
            }}
          >
            <PencilIcon className="h-4 w-4" aria-hidden="true" />
            <span className="ml-2">Edit Format</span>
          </button>
        )}
        {data && isEditable && !data?.isIssued && (
          <Button
            id="documents-nirgam-download"
            buttonStyle="success"
            onClick={handleIssueAndDownload}
            className="h-12 ml-5 py-3 px-4"
          >
            Issue & Download <br /> Document
          </Button>
        )}
        {data && data?.isIssued && !viewOnly && (
          <Button
            id="documents-nirgam-downloadduplicate"
            buttonStyle="success"
            onClick={onDownloadDocument}
            className="h-12 ml-5"
          >
            Download Duplicate Document
          </Button>
        )}
      </div>
    </form>
  );
}

export default NirgamUtaraView;
