export const DATE_FORMATS = {
  YYYYMMDD: "YYYY-MM-DD",
  YYYYDDMM: "YYYY-DD-MM",
  DDMMYYYY: "DD-MM-YYYY",
  DMMMYYYY: "D MMM YYYY",
  DDMMMYYY: "DD MMMM YYYY",
  DDMMMYYYY: "DD MMM YYYY",
  DMMMdddd: "D MMM (ddd)",
  DDMMYY: "DD/MM/YY",
  MMMDYYYY: "MMM D, YYYY",
  HHMMA: "hh:mm A",
  YYYYMMDDHHMMA: "YYYY-MM-DD hh:mm A",
  MMMM: "MMMM",
  HHMMSS: "hh:mm:ss",
  YYYYMMDDHHMMSS: "YYYY-MM-DD HH:mm:ss",
  YYYYMMDDHHMMSSZ: "YYYY-MM-DD HH:mm:ss Z",
  DDMMMYYYYYHHMMA: "DD MMM YYYY, h:MM A",
  DDSMMMSYY: "DD MMM YYYY",
  MMMMYYYY: "MMMM YYYY",
  DDMMMYYHHMMA: "DD MMM YY hh:mm A",
  DAY: "dddd",
};

export const MOMENT_OFFSETS = {
  IST_OFFSET: "+05:30",
};

export const MENU_OPTIONS = {
  EDIT: "edit",
  DELETE: "delete",
};

export const EXAM_TERM_OPTIONS = [
  {
    label: "First Term",
    value: 1,
  },
  {
    label: "Second Term",
    value: 2,
  },
];

export const TEACHER_BULK_UPLOAD_FORMAT = [
  {
    columns: [
      { title: "S.No" },
      { title: "Teacher's Name" },
      { title: "Mobile Number" },
      { title: "Gender" },
      { title: "Birth Date" },
    ],
    data: [
      [
        { value: 1 },
        { value: "Krishna Anil Patil" },
        { value: "9999999999" },
        { value: "M or F" },
        { value: "04-02-1999" },
      ],
    ],
  },
];

export const STUDENT_BULK_UPLOAD_FORMAT = [
  {
    columns: [
      { title: "S.No" },
      { title: "GR Set" },
      { title: "GR No." },
      { title: "Class" },
      { title: "Division" },
      { title: "Student ID" },
      { title: "Student Name" },
      { title: "Mother's Name" },
      { title: "RTE" },
      { title: "Birth Date" },
      { title: "Gender" },
      { title: "Religion" },
      { title: "Category" },
      { title: "Inserted Date" },
      { title: "Mobile Number" },
      { title: "Aadhaar" },
      { title: "Caste" },
      { title: "Enroll date" },
      { title: "Place of birth" },
      { title: "Last School" },
      { title: "Paying or free" },
      { title: "Admitted class" },
      { title: "Admitted division" },
      { title: "Conduct" },
      { title: "Student Progress" },
      { title: "Remarks" },
    ],
    data: [
      [
        { value: 1 },
        { value: "Entire School" },
        { value: 3215 },
        { value: 1 },
        { value: 1 },
        { value: "ID:2011272812007040021" },
        { value: "Anand Shyam Deshmukh" },
        { value: "Neha" },
        { value: "Yes/No" },
        { value: "04-02-1999" },
        { value: "M or F" },
        { value: "Hindu" },
        { value: "OBC" },
        { value: "18-09-2021" },
        { value: 8118551824 },
        { value: 123432432422 },
        { value: "Maratha" },
        { value: "30-01-2021" },
        { value: "Renapur Tq.Dist.Latur" },
        { value: "Sane Guruji sec.school Latur" },
        { value: "Free" },
        { value: 9 },
        { value: 1 },
        { value: "good" },
        { value: "good" },
        { value: "good" },
      ],
    ],
  },
];

export const SCHOOLS_BULK_UPLOAD_FORMAT = [
  {
    columns: [
      { title: "S.No" },
      { title: "Trust Name" },
      { title: "School Name" },
      { title: "Board" },
      { title: "Medium" },
      { title: "Academic Year Title" },
      { title: "Academic Year From" },
      { title: "Academic Year To" },
      { title: "U-DISE Number" },
      { title: "Index Number" },
      { title: "Address Line 1" },
      { title: "Address Line 2" },
      { title: "State" },
      { title: "District" },
      { title: "City" },
      { title: "Pin Code" },
      { title: "Management Type" },
      { title: "Category" },
      { title: "POC" },
      { title: "Admin Name" },
      { title: "Admin Mobile Number" },
      { title: "Admin Role" },
    ],
    data: [
      [
        { value: 1 },
        { value: "GroundUp Trust 2" },
        { value: "GroundUp Test School 2" },
        { value: "MAHARASHTRA" },
        { value: "English" },
        { value: "2022-2023" },
        { value: "01-05-2021" },
        { value: "30-05-2022" },
        { value: 1234556 },
        { value: "IDX1234" },
        { value: "ABC Chowk" },
        { value: "PCMC" },
        { value: "Maharashtra" },
        { value: "Pune" },
        { value: "Pune" },
        { value: 411044 },
        { value: "25 - Private Partially Aided" },
        { value: "1 - Primary" },
        { value: 8 },
        { value: "M S Dhoni" },
        { value: 7789766770 },
        { value: "Clerk" },
      ],
    ],
  },
];

export const GRADE_HEADERS = [
  "Interval in Percent",
  "Grade",
  "Description of Grades",
];

export const GRADE_DATA = [
  {
    items: ["91-100", "A1", "Outstanding"],
  },
  {
    items: ["81-90", "A2", "Excellent"],
  },
  {
    items: ["71-80", "B1", "Very Good"],
  },
  {
    items: ["61-70", "B2", "Good"],
  },
  {
    items: ["51-60", "C1", "Satisfactory"],
  },
  {
    items: ["41-50", "C2", "Satisfactory"],
  },
  {
    items: ["31-40", "D", "Average"],
  },
  {
    items: ["21-30", "E1", "Average"],
  },
  {
    items: ["0-20", "E2", "Average"],
  },
];

export const MARKS_HEADERS = ["Interval in Percent", "Description of Marks"];

export const MARKS_DATA = [
  {
    items: ["91-100", "Outstanding"],
  },
  {
    items: ["81-90", "Excellent"],
  },
  {
    items: ["71-80", "Very Good"],
  },
  {
    items: ["61-70", "Good"],
  },
  {
    items: ["51-60", "Satisfactory"],
  },
  {
    items: ["41-50", "Satisfactory"],
  },
  {
    items: ["31-40", "Average"],
  },
  {
    items: ["21-30", "Average"],
  },
  {
    items: ["0-20", "Average"],
  },
];

export const REPORT_DATA = [
  {
    items: [
      <div className="flex">
        <p className="font-bold text-lightergrey w-1/4">Register Number</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>5478231</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex">
        <p className="font-bold text-lightergrey w-1/4">Student ID</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>ID:2014123312</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex">
        <p className="font-bold text-lightergrey w-1/4">Student Name</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>Sayali Santosh Nikam</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex">
        <p className="font-bold text-lightergrey w-1/4">Aadhar Number</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>9849327345817202</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex">
        <p className="font-bold text-lightergrey w-1/4">Date of Birth</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>22/01/2000</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex">
        <p className="font-bold text-lightergrey w-1/4">
          Date of Birth in words
        </p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>Twenty Two January Two Thousand</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex ">
        <p className="font-bold text-lightergrey w-1/4">Class</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>5</p>
        <div className="flex ml-32">
          <p className="font-bold text-lightergrey">Division</p>
          <p className="text-lightergrey ml-5 mr-4">:</p>
          <p>5</p>
        </div>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex ">
        <p className="font-bold text-lightergrey w-1/4">Medium</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>English</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex ">
        <p className="font-bold text-lightergrey w-1/4">Mother's Name</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>Manisha</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex ">
        <p className="font-bold text-lightergrey w-1/4">Father's Name</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>Santosh Nikam</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex ">
        <p className="font-bold text-lightergrey w-1/4">Parent's Occupation</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>Teacher</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex ">
        <p className="font-bold text-lightergrey w-1/4">Mother Tongue</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>Marathi</p>
      </div>,
    ],
  },
  {
    items: [
      <div className="flex ">
        <p className="font-bold text-lightergrey w-1/4">Address</p>
        <p className="text-lightergrey ml-0.5 mr-4">:</p>
        <p>Shivaji Nagar, Pune</p>
      </div>,
    ],
  },
];

export const getStudentPersonalData = (student) => {
  const {
    grNo,
    aadhaar,
    fullName,
    dob,
    dobInWords,
    className,
    divisionName,
    schoolBoard,
    motherName,
    fatherName,
    parentOccupation = "",
    address,
    motherTongue,
    studentId
  } = student;
  return [
    {
      items: [
        <div className="flex">
          <p className="font-bold text-lightergrey w-1/4">Register Number</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{grNo}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex">
          <p className="font-bold text-lightergrey w-1/4">Student ID</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{studentId}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex">
          <p className="font-bold text-lightergrey w-1/4">Student Name</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{fullName}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex">
          <p className="font-bold text-lightergrey w-1/4">Aadhar Number</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{aadhaar}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex">
          <p className="font-bold text-lightergrey w-1/4">Date of Birth</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{dob}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex">
          <p className="font-bold text-lightergrey w-1/4">
            Date of Birth in words
          </p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{dobInWords}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex ">
          <p className="font-bold text-lightergrey w-1/4">Class</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{className}</p>
          <div className="flex ml-32">
            <p className="font-bold text-lightergrey">Division</p>
            <p className="text-lightergrey ml-5 mr-4">:</p>
            <p>{divisionName}</p>
          </div>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex ">
          <p className="font-bold text-lightergrey w-1/4">School Board</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{schoolBoard}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex ">
          <p className="font-bold text-lightergrey w-1/4">Mother's Name</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{motherName}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex ">
          <p className="font-bold text-lightergrey w-1/4">Father's Name</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{fatherName}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex ">
          <p className="font-bold text-lightergrey w-1/4">
            Parent's Occupation
          </p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{parentOccupation}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex ">
          <p className="font-bold text-lightergrey w-1/4">Mother Tongue</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{motherTongue}</p>
        </div>,
      ],
    },
    {
      items: [
        <div className="flex ">
          <p className="font-bold text-lightergrey w-1/4">Address</p>
          <p className="text-lightergrey ml-0.5 mr-4">:</p>
          <p>{address}</p>
        </div>,
      ],
    },
  ];
};
export const EXAM_HEADERS = [
  "Sr. No.",
  "Subject",
  "Grade",
  "Descriptive Entries",
];

export const EXAM_DATA = [
  {
    items: ["1", "Marathi", "A1"],
  },
  {
    items: ["2", "English", "A2"],
  },
  {
    items: ["3", "Maths", "B1"],
  },
  {
    items: ["4", "Art", "C1"],
  },
  {
    items: ["5", "Science", "A1"],
  },
  {
    items: ["6", "Social Science", "A2"],
  },
  {
    items: ["7", "Hindi", "B1"],
  },
];

export const getGradesExamData = (examResults) => {
  if (!examResults) return EXAM_DATA;
  const resultsData = [];

  for (const [index, [key, value]] of Object.entries(
    Object.entries(examResults)
  )) {
    let subjectObtainedMarks = 0;
    let subjectObtainedGrade = "-";
    if (value.obtainedMarks !== undefined) {
      subjectObtainedMarks = Math.round(
        (parseInt(value.obtainedMarks) / parseInt(value.maximumMarks)) * 100
      );
      subjectObtainedGrade = getGrade(subjectObtainedMarks);
    }
    resultsData.push({
      items: [parseInt(index) + 1, key, subjectObtainedGrade],
    });
  }

  return resultsData;
};

export const MARKS_EXAM_HEADERS = [
  "Sr. No.",
  "Subject",
  "Obtained Marks",
  "Max. Marks",
];

export const MARKS_EXAM_DATA = [
  {
    items: ["1", "Marathi", "24", "30"],
  },
  {
    items: ["2", "English", "18", "30"],
  },
  {
    items: ["3", "Maths", "20", "30"],
  },
  {
    items: ["4", "Art", "22", "30"],
  },
  {
    items: ["5", "Science", "26", "30"],
  },
  {
    items: ["6", "Social Science", "25", "30"],
  },
  {
    items: ["7", "Hindi", "16", "30"],
  },
  {
    items: ["Total Marks", "151", "210"],
  },
];

export const getMarksExamData = (examResults) => {
  if (!examResults) return MARKS_EXAM_DATA;

  const resultsData = [];
  let totalObtainedMarks = 0;
  let totalMaximumMarks = 0;
  for (const [index, [key, value]] of Object.entries(
    Object.entries(examResults)
  )) {
    let subjectObtainedMarks = 0;
    if (value.obtainedMarks) {
      subjectObtainedMarks = Math.round(
        (parseInt(value.obtainedMarks) / parseInt(value.maximumMarks)) * 100
      );
    }
    resultsData.push({
      items: [parseInt(index) + 1, key, subjectObtainedMarks, 100],
    });
    totalObtainedMarks += subjectObtainedMarks;
    totalMaximumMarks += 100;
  }
  resultsData.push({
    items: ["Total Marks", totalObtainedMarks, totalMaximumMarks],
  });
  return resultsData;
};

export const ATTENDANCE_HEADERS = [
  "Month",
  "June",
  "July",
  "Aug.",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec.",
  "Jan.",
  "Feb.",
  "Mar.",
  "April",
  "Total",
];

export const getAttendanceTableItems = (
  fromDate,
  toDate,
  monthWiseAttendance
) => {
  let attendanceHeaders = ["Month"];
  let attendanceData = [
    {
      items: ["Present Days"],
    },
    {
      items: ["Working Days"],
    },
  ];
  let totalPresentDays = 0;
  let totalWorkingDays = 0;
  while (toDate > fromDate || fromDate.format("M") === toDate.format("M")) {
    attendanceHeaders.push(fromDate.format("MMM"));
    // get fromDate month number
    const monthNumber = parseInt(fromDate.format("M"));
    // find attendance in month wise attendance where month number matches
    const attendance = monthWiseAttendance.find(
      (att) => att.month === monthNumber
    );
    if (attendance) {
      attendanceData[0].items.push(attendance.present);
      attendanceData[1].items.push(attendance.total);
      totalPresentDays += attendance.present;
      totalWorkingDays += attendance.total;
    } else {
      attendanceData[0].items.push(0);
      attendanceData[1].items.push(0);
    }
    fromDate.add(1, "month");
  }
  attendanceHeaders.push("Total");
  attendanceData[0].items.push(totalPresentDays);
  attendanceData[1].items.push(totalWorkingDays);
  return {
    attendanceHeaders,
    attendanceData,
  };
};

export const ATTENDANCE_DATA = [
  {
    items: [
      "Present Days",
      "9",
      "23",
      "12",
      "20",
      "19",
      "7",
      "20",
      "18",
      "9",
      "18",
      "22",
      "177",
    ],
  },
  {
    items: [
      "Working Days",
      "13",
      "24",
      "13",
      "22",
      "21",
      "9",
      "20",
      "21",
      "14",
      "21",
      "23",
      "201",
    ],
  },
];

export const OVERALL_EXAM_HEADERS = [
  "Exam",
  "First Term Exam",
  "Second Term Exam",
  "Overall",
];

export const OVERALL_EXAM_DATA = [
  {
    items: ["Grade", "A1", "A2", "A1"],
  },
  {
    items: ["Description of Grades", "Outstanding", "Excellent", "Outstanding"],
  },
];

export const getOverallGradesExamData = (firstTermItems, secondTermItems) => {
  const overallMarksData = [
    {
      items: ["Grade"],
    },
    {
      items: ["Description of Grades"],
    },
  ];
  const firstTermObtained =
    firstTermItems[firstTermItems.length - 1]?.items?.[1];
  const firstTermMaximum =
    firstTermItems[firstTermItems.length - 1]?.items?.[2];
  const secondTermObtained =
    secondTermItems[secondTermItems.length - 1]?.items?.[1];
  const secondTermMaximum =
    secondTermItems[secondTermItems.length - 1]?.items?.[2];

  const firstTermPercentage = Math.round(
    (parseInt(firstTermObtained) / parseInt(firstTermMaximum)) * 100
  );
  const secondTermPercentage = Math.round(
    (parseInt(secondTermObtained) / parseInt(secondTermMaximum)) * 100
  );
  const overallPercentage = Math.round(
    ((parseInt(firstTermObtained) + parseInt(secondTermObtained)) /
      (parseInt(firstTermMaximum) + parseInt(secondTermMaximum))) *
      100
  );
  const firstTermGrade = getGrade(firstTermPercentage);
  const secondTermGrade = getGrade(secondTermPercentage);
  const overallGrade = getGrade(overallPercentage);
  overallMarksData[0].items.push(firstTermGrade);
  overallMarksData[0].items.push(secondTermGrade);
  overallMarksData[0].items.push(overallGrade);
  const firstTermDescription = getMarksDescription(firstTermPercentage);
  const secondTermDescription = getMarksDescription(secondTermPercentage);
  const overallDescription = getMarksDescription(overallPercentage);
  overallMarksData[1].items.push(firstTermDescription);
  overallMarksData[1].items.push(secondTermDescription);
  overallMarksData[1].items.push(overallDescription);
  return overallMarksData;
};

export const OVERALL_MARKS_EXAM_HEADERS = [
  "Exam",
  "First Term Exam",
  "Second Term Exam",
  "Overall %",
];

export const OVERALL_MARKS_EXAM_DATA = [
  {
    items: ["Marks", "151/200", "151/210", "75%"],
  },
  {
    items: ["Description of Marks ", "Outstanding", "Excellent", "Outstanding"],
  },
];

export const getOverallMarksExamData = (firstTermItems, secondTermItems) => {
  const overallMarksData = [
    {
      items: ["Marks"],
    },
    {
      items: ["Description of Marks"],
    },
  ];
  const firstTermObtained =
    firstTermItems[firstTermItems.length - 1]?.items?.[1];
  const firstTermMaximum =
    firstTermItems[firstTermItems.length - 1]?.items?.[2];
  const secondTermObtained =
    secondTermItems[secondTermItems.length - 1]?.items?.[1];
  const secondTermMaximum =
    secondTermItems[secondTermItems.length - 1]?.items?.[2];
  const firstTermOverall = `${firstTermObtained}/${firstTermMaximum}`;
  const secondTermOverall = `${secondTermObtained}/${secondTermMaximum}`;
  const overallPercentage = Math.round(
    ((parseInt(firstTermObtained) + parseInt(secondTermObtained)) /
      (parseInt(firstTermMaximum) + parseInt(secondTermMaximum))) *
      100
  );
  overallMarksData[0].items.push(firstTermOverall);
  overallMarksData[0].items.push(secondTermOverall);
  overallMarksData[0].items.push(`${overallPercentage}%`);
  const firstTermPercentage = Math.round(
    (parseInt(firstTermObtained) / parseInt(firstTermMaximum)) * 100
  );
  const secondTermPercentage = Math.round(
    (parseInt(secondTermObtained) / parseInt(secondTermMaximum)) * 100
  );
  const firstTermDescription = getMarksDescription(firstTermPercentage);
  const secondTermDescription = getMarksDescription(secondTermPercentage);
  const overallDescription = getMarksDescription(overallPercentage);
  overallMarksData[1].items.push(firstTermDescription);
  overallMarksData[1].items.push(secondTermDescription);
  overallMarksData[1].items.push(overallDescription);
  return overallMarksData;
};

export const marksPercentageLegend = [
  { startRange: 91, endRange: 100, grade: "A1", description: "Outstanding" },
  { startRange: 81, endRange: 90, grade: "A2", description: "Excellent" },
  { startRange: 71, endRange: 80, grade: "B1", description: "Very Good" },
  { startRange: 61, endRange: 70, grade: "B2", description: "Good" },
  { startRange: 51, endRange: 60, grade: "C1", description: "Satisfactory" },
  { startRange: 41, endRange: 50, grade: "C2", description: "Satisfactory" },
  { startRange: 31, endRange: 40, grade: "D", description: "Average" },
  { startRange: 21, endRange: 30, grade: "E", description: "Average" },
  { startRange: 0, endRange: 20, grade: "F", description: "Average" },
];

export const getMarksDescription = (percentage) => {
  const marksDescription = marksPercentageLegend.find(
    (legend) => legend.startRange <= percentage && legend.endRange >= percentage
  );
  return marksDescription?.description;
};

export const getGrade = (percentage) => {
  const grade = marksPercentageLegend.find(
    (legend) => legend.startRange <= percentage && legend.endRange >= percentage
  );
  return grade?.grade;
};

export const DEFAULT_EXAM_COLS = [
  {
    title: () => <p className="font-semibold text-gray-500">Subject</p>,
    dataIndex: "subject",
    width: "10rem",
    editable: false,
    render: (text) => <p className="font-semibold">{text}</p>,
  },
  {
    title: () => <p className="font-semibold text-base">First Term Exam</p>,
    editable: true,
    isGroup: true,
    children: [
      {
        title: () => <p className="font-semibold">Test 1</p>,
        dataIndex: "Test 1",
        editable: true,
        render: (text) => <p className="font-semibold text-center">{text}</p>,
      },
      {
        title: () => <p className="font-semibold">Semester 1</p>,
        dataIndex: "Semester 1",
        editable: true,
        render: (text) => <p className="font-semibold text-center">{text}</p>,
      },
      {
        title: () => (
          <p className="font-semibold text-gray-500 text-center items-center">
            Total
          </p>
        ),
        dataIndex: "firstTotal",
        editable: false,
        render: (text, row) => {
          const total = row?.firstTermExamKeys?.reduce(
            (acc, obj) => parseInt(acc) + parseInt(row[obj.title]),
            0
          );
          return <p className="font-semibold text-center">{total}</p>;
        },
      },
    ],
  },
  {
    title: () => <p className="font-semibold text-base">Second Term Exam</p>,
    editable: true,
    isGroup: true,
    children: [
      {
        title: () => <p className="font-semibold">Test 2</p>,
        dataIndex: "Test 2",
        editable: true,
        render: (text) => <p className="font-semibold text-center">{text}</p>,
      },
      {
        title: () => <p className="font-semibold">Semester 2</p>,
        dataIndex: "Semester 2",
        editable: true,
        render: (text) => <p className="font-semibold text-center">{text}</p>,
      },
      {
        title: () => (
          <p className="font-semibold text-gray-500 text-center items-center">
            Total
          </p>
        ),
        dataIndex: "secondTotal",
        editable: false,
        render: (text, row) => {
          const total = row?.secondTermExamKeys?.reduce(
            (acc, obj) => parseInt(acc) + parseInt(row[obj.title]),
            0
          );
          return <p className="font-semibold text-center">{total}</p>;
        },
      },
    ],
  },
];

export const ASSESMENT_SUBJECTS = {
  classes: [
    {
      subjects: [
        {
          id: 1,
          total_marks: 40,
          start_time: "2022-08-06T00:00:00+05:30",
          title: "Subject 1",
        },
        {
          id: 2,
          total_marks: 40,
          start_time: "2022-08-06T00:00:00+05:30",
          title: "Subject 2",
        },
        {
          id: 3,
          total_marks: 40,
          start_time: "2022-08-09T00:00:00+05:30",
          title: "Subject 3",
        },
        {
          id: 4,
          total_marks: 40,
          start_time: null,
          title: "Subject 4",
        },
        {
          id: 5,
          total_marks: 40,
          start_time: null,
          title: "Subject 5",
        },
        {
          id: 6,
          total_marks: 40,
          start_time: null,
          title: "Subject 6",
        },
        {
          id: 7,
          total_marks: 40,
          start_time: null,
          title: "Subject 7",
        },
        {
          id: 8,
          total_marks: 40,
          start_time: null,
          title: "Subject 8",
        },
      ],
    },
  ],
};

export const MARATHI = "marathi";
export const ENGLISH = "english";
export const MAX_RESULT = 5;

export const DEFAULT_EXAMS = [
  {
    id: 1,
    title: "Daily Observation",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
  {
    id: 1,
    title: "Oral Work",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
  {
    id: 1,
    title: "Practical",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
  {
    id: 1,
    title: "Experiment",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
  {
    id: 1,
    title: "Project",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
  {
    id: 1,
    title: "Test",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
  {
    id: 1,
    title: "Excercise",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
  {
    id: 1,
    title: "Other",
    term: 1,
    start_at: "2022-08-09T00:00:00",
    end_at: null,
  },
];

const FORMATTIVE_ASSESSMENT = {
  title: () => (
    <p className="font-semibold">आकारिक मूल्यमापन (Formative Evaluation)</p>
  ),
  examTitle: "Formattive",
  editable: false,
  children: [
    {
      id: 1,
      title: () => (
        <div>
          <p className="font-semibold">दैनंदिन निरीक्षण</p>
          <p className="font-semibold">Daily Observation</p>
        </div>
      ),
      dataIndex: 1,
      examTitle: "Daily Observation",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 2,
      title: () => (
        <div>
          <p className="font-semibold">तोंडी काम</p>
          <p className="font-semibold">Oral Work</p>
        </div>
      ),
      dataIndex: 2,
      examTitle: "Oral Work",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 3,
      title: () => (
        <div>
          <p className="font-semibold">प्रात्यक्षित</p>
          <p className="font-semibold">Practical</p>
        </div>
      ),
      dataIndex: 3,
      examTitle: "Practical",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 4,
      title: () => (
        <div>
          <p className="font-semibold">प्रयोग</p>
          <p className="font-semibold">Experiment</p>
        </div>
      ),
      dataIndex: 4,
      examTitle: "Experiment",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 5,
      title: () => (
        <div>
          <p className="font-semibold">प्रकल्प</p>
          <p className="font-semibold">Project</p>
        </div>
      ),
      dataIndex: 5,
      examTitle: "Project",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 6,
      title: () => (
        <div>
          <p className="font-semibold">चाचणी </p>
          <p className="font-semibold">Test</p>
        </div>
      ),
      dataIndex: 6,
      examTitle: "Test",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 7,
      title: () => (
        <div>
          <p className="font-semibold">स्वाध्याय </p>
          <p className="font-semibold">Excercise</p>
        </div>
      ),
      dataIndex: 7,
      examTitle: "Excercise",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 8,
      title: () => (
        <div>
          <p className="font-semibold">इतर</p>
          <p className="font-semibold">Other</p>
        </div>
      ),
      dataIndex: 8,
      examTitle: "Other",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 9,
      title: () => (
        <div>
          <p className="font-semibold">Total</p>
        </div>
      ),
      dataIndex: 9,
      examTitle: "Total",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
  ],
};

export const CREATED_BY = {
  TEACHER: "TEACHER",
  ADMIN: "ADMIN",
};

export const MARKING_TYPE = {
  MARKS: "MARKS",
};

export const EVALUATION_TYPE = [
  { value: "CCE_MR", label: "Maharashtra State Board" },
  { value: "CUSTOM", label: "Custom" },
];

const SUMMATIVE_ASSESSMENT = {
  title: () => (
    <p className="font-semibold">संकलित मूल्यमापन (Summative Evaluation)</p>
  ),
  examTitle: "Summative",
  editable: false,
  children: [
    {
      id: 1,
      title: () => (
        <div>
          <p className="font-semibold">तोंडी</p>
          <p className="font-semibold">Oral</p>
        </div>
      ),
      dataIndex: 1,
      examTitle: "Oral",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 2,
      title: () => (
        <div>
          <p className="font-semibold">प्रात्यक्षित</p>
          <p className="font-semibold">Practical</p>
        </div>
      ),
      dataIndex: 2,
      examTitle: "Practical",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 3,
      title: () => (
        <div>
          <p className="font-semibold">लेखी</p>
          <p className="font-semibold">Written</p>
        </div>
      ),
      dataIndex: 3,
      examTitle: "Written",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
    {
      id: 4,
      title: () => (
        <div>
          <p className="font-semibold">Total</p>
        </div>
      ),
      dataIndex: 4,
      examTitle: "Total",
      editable: true,
      render: (text) => <p className="font-semibold text-center"></p>,
    },
  ],
};

export const SAMPLE_EXAM_COLS = [
  {
    title: () => <p className="font-semibold text-gray-500">Subject</p>,
    dataIndex: "subject",
    width: "10rem",
    editable: false,
    render: (text) => <p className="font-semibold">{text}</p>,
  },
  {
    title: () => <p className="font-semibold text-base">First Term Exam</p>,
    editable: true,
    isGroup: true,
    children: [FORMATTIVE_ASSESSMENT, SUMMATIVE_ASSESSMENT],
  },
];

export const DEFAULT_ALL_SUBJECTS = [
  {
    group: "Physical Education",
    groupTranslations: { marathi: "शारीरिक शिक्षण" },
    isOptional: false,
    subjectName: "Physical Education 1",
    markingSystem: "Marks",
    isSkillBasedEvaluation: false,
    isConsiderForOverallCalculation: false,
    isCore: false,
    code: "physicaled",
    translations: { marathi: "शारीरिक शिक्षण" },
  },
  {
    group: "Another Group",
    groupTranslations: { marathi: "शारीरिक शिक्षण" },
    isOptional: false,
    subjectName: "Physical Education 2",
    markingSystem: "Marks",
    isSkillBasedEvaluation: false,
    isConsiderForOverallCalculation: false,
    isCore: false,
    code: "physicaled",
    translations: { marathi: "शारीरिक शिक्षण" },
  },
  {
    group: "Physical Education",
    groupTranslations: { marathi: "शारीरिक शिक्षण" },
    isOptional: false,
    subjectName: "Physical Education 3",
    markingSystem: "Marks",
    isSkillBasedEvaluation: false,
    isConsiderForOverallCalculation: false,
    isCore: false,
    code: "physicaled",
    translations: { marathi: "शारीरिक शिक्षण" },
  },
];

export const SUBJECT_MARKING_SYSTEM_OPTIONS = [
  {
    label: "Marks",
    value: "Marks",
  },
  {
    label: "Grades",
    value: "Grades",
  },
];

export const DEFAULT_SUBJECT_LIST = [
  {
    label: "Marathi",
    value: 1,
  },
  {
    label: "English",
    value: 2,
  },
  {
    label: "Hindi",
    value: 3,
  },
  {
    label: "Science",
    value: 4,
  },
  {
    label: "Social Science",
    value: 5,
  },
  {
    label: "Mathematics",
    value: 6,
  },
  {
    label: "Sanskrit",
    value: 7,
  },
];

export const ADD_NEW_SUBJECT_ID = -1;
export const ADD_NEW_GROUP_ID = -2;

export const SUBJECT_GROUP_OPTIONS = [
  {
    label: "Marathi",
    value: "Marathi",
  },
  {
    label: "English",
    value: "English",
  },
  {
    label: "Hindi/Sanskrit",
    value: "Hindi/Sanskrit",
  },
  {
    label: "Science",
    value: "Science",
  },
  {
    label: "Social Science",
    value: "Social Science",
  },
  {
    label: "Mathematics",
    value: "Mathematics",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const LANGUAGE_CODES = {
  ENGLISH: "en",
  MARATHI: "mr-IN",
};

export const LANGUAGE_INPUT_CODES = {
  "mr-IN": "mr-t-i0-und",
};

export const managementTypeList = [
  { value: "4 - Private Aided", label: "4 - Private Aided" },
  {
    value: "5 - Pvt. Unaided (Recognized)",
    label: "5 - Pvt. Unaided (Recognized)",
  },
  { value: "8 - Unrecognized", label: "8 - Unrecognized" },
  {
    value: "10 - Social Welfare Department",
    label: "10 - Social Welfare Department",
  },
  {
    value: "11 - Social Welfare Private Aided",
    label: "11 - Social Welfare Private Aided",
  },
  {
    value: "12 - Social Welfare Private Unaided",
    label: "12 - Social Welfare Private Unaided",
  },
  {
    value: "13 - Tribal Welfare Department",
    label: "13 - Tribal Welfare Department",
  },
  {
    value: "14 - Tribal Welfare Private Aided",
    label: "14 - Tribal Welfare Private Aided",
  },
  { value: "16 - Zilla Parishad (ZP)", label: "16 - Zilla Parishad (ZP)" },
  {
    value: "17 - Municipal Corporations (MNC)",
    label: "17 - Municipal Corporations (MNC)",
  },
  {
    value: "18 - Nagarpalika/ Nagarparishad/ nagarpanchayat",
    label: "18 - Nagarpalika/ Nagarparishad/ nagarpanchayat",
  },
  { value: "19 - Private Unaided", label: "19 - Private Unaided" },
  { value: "22 - Central Schools", label: "22 - Central Schools" },
  { value: "23 - Navodaya Vidyalaya", label: "23 - Navodaya Vidyalaya" },
  {
    value: "25 - Private Partially Aided",
    label: "25 - Private Partially Aided",
  },
  { value: "27 - Self Finance School", label: "27 - Self Finance School" },
  {
    value: "97 - Madarsa Recognized (by Wakf board/Madarsa Board)",
    label: "97 - Madarsa Recognized (by Wakf board/Madarsa Board)",
  },
  { value: "98 - Madarsa Unrecognized", label: "98 - Madarsa Unrecognized" },
];

export const categoryList = [
  { value: "1 - Primary", label: "1 - Primary" },
  {
    value: "2 - Primary with Upper Primary",
    label: "2 - Primary with Upper Primary",
  },
  {
    value: "3 - Pr. with Up.Pr. sec. and H.Sec.",
    label: "3 - Pr. with Up.Pr. sec. and H.Sec.",
  },
  { value: "4 - Upper Primary only", label: "4 - Upper Primary only" },
  {
    value: "5 - Up. Pr. Secondary and Higher Sec",
    label: "5 - Up. Pr. Secondary and Higher Sec",
  },
  {
    value: "6 - Pr. Up Pr. and Secondary Only",
    label: "6 - Pr. Up Pr. and Secondary Only",
  },
  {
    value: "7 - Upper Pr. and Secondary",
    label: "7 - Upper Pr. and Secondary",
  },
  { value: "8 - Secondary Only", label: "8 - Secondary Only" },
  {
    value: "10 - Secondary with Higher Secondary",
    label: "10 - Secondary with Higher Secondary",
  },
  {
    value: "11 - Higher Secondary only/Jr. College",
    label: "11 - Higher Secondary only/Jr. College",
  },
];

export const roleList = [
  { value: "Principal", label: "Principal" },
  { value: "Clerk", label: "Clerk/Teacher" },
];

export const rollNoPreferenceList = [
  { value: "F", label: "Girls first in the order" },
  { value: "M", label: "Boys first in the order" },
  { value: "NA", label: "No Preference" },
];

export const rteOptions = [
  { label: "RTE: All", value: "All" },
  { label: "RTE: Yes", value: "true" },
  { label: "RTE: No", value: "false" },
];

export const categoryData = [
  "key",
  "gender",
  "open",
  "sc",
  "st",
  "obc",
  "sbc",
  "sebc",
  "vj",
  "nt-b",
  "nt-c",
  "nt-d",
  "na",
  "total",
];

export const allOption = "All";

export const genderList = ["Male", "Female", "Transgender", "NA", "Total"];
export const casteList = new Map([
  ["open", 1],
  ["general", 1],
  ["sc", 2],
  ["st", 3],
  ["obc", 4],
  ["sbc", 5],
  ["sebc", 6],
  ["vj", 7],
  ["ntb", 8],
  ["ntc", 9],
  ["ntd", 10],
  ["na", 11],
  ["total", 12],
]);

export const EXAM_TERMS = {
  FIRST_TERM: 1,
  SECOND_TERM: 2,
  BOTH_TERM: 0,
};

export const schoolMediumList = [
  {
    label: "English",
    value: "English",
    code: "en",
  },
  {
    label: "Marathi (मराठी)",
    value: "Marathi",
    code: "mr-IN",
  },
  {
    label: "Urdu (اردو)",
    value: "Urdu",
    code: "ur",
  },
];

export const DEFAULT_STUDENT_CITY = "default_astra";

export const USER_ROLES = {
  ADMIN: "admin",
  REGIONAL_ADMIN: "regional_admin",
};

export const PLAN_TYPES = {
  ANNUALLY: "YEARS",
  MONTHLY: "MONTHLY",
};

export const APP_TYPE = {
  PARENT_APP: "PARENT_APP",
  TEACHER_APP: "TEACHER_APP",
};

export const MONTH_OPTIONS = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const TRANSCATION_TYPES = [
  { label: "Filter by: All", value: null },
  { label: "Fee Collection", value: "collection" },
  { label: "Expense", value: "expense" },
];

export const FEE_STRUCTURE = [
  { label: "Annual Fees", value: "annual" },
  { label: "Admisson Fees ", value: "admisson" },
  { label: "Term Fees ", value: "term" },
];

export const PAYMENT_MODE = [
  { label: "Cash", value: "cash" },
  { label: "Cheque ", value: "cheque" },
  { label: "Online ", value: "online" },
];

export const FEE_STUDENT_TYPES = [
  { label: "Existing", value: "EXISTING" },
  { label: "New", value: "NEW" },
];

export const STAFF_TYPE = {
  NON_TEACHING: "NON-TEACHING",
  TEACHING: "TEACHING",
  BOTH: "BOTH",
};

export const DOWNLOAD_OPTIONS = [
  { label: "Download Excel", value: "xlsx" },
  { label: "Download PDF", value: "pdf" },
];

export const SHIFTS = ["General", "Morning", "Afternoon"];
