import React from "react";
import { ErrorMessage } from "@hookform/error-message";

const CustomRadioInput = ({
  id,
  name,
  label,
  placeholder,
  register,
  required,
  rules,
  errors,
  checked,
  value,
  type: inputType,
  disabled,
}) => {
  return (
    <>
      <div className="flex-row gap-1 flex justify-start items-center">
        <input
        id={id}
          {...register(name, { required, ...rules })}
          className="border border-gray-300 rounded-md p-2 shadow-sm"
          type={inputType ? inputType : "text"}
          disabled={disabled}
          value={value}
        />
        <label className="text-gray-700 font-semibold text-sm text-left ">
          {label}
        </label>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p
                key={type}
                className="text-left italic text-sm text-red-600 mt-1 "
              >
                {message}
              </p>
            ))
          }
        />
      </div>
    </>
  );
};
export default CustomRadioInput;
