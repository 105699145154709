import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import PropTypes from "prop-types";

const FormSelect = ({
  id,
  name,
  label,
  placeholder,
  register,
  required,
  rules,
  errors,
  options,
  defaultValue,
  onChange,
}) => {
  return (
    <>
      <label
        htmlFor={name}
        className="text-gray-700 font-medium text-sm leading-5 pb-1 text-left "
      >
        {label}
      </label>
      <select
        {...register(name, { required, ...rules })}
        id={id}
        name={name}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder={placeholder || null}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
      >
        {options?.map((opt, optindex) => {
          return <option key={optindex}>{opt}</option>;
        })}
      </select>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p
              key={type}
              className="text-left italic text-sm text-red-600 mt-1 "
            >
              {message}
            </p>
          ))
        }
      />
    </>
  );
};

FormSelect.prototypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  rules: PropTypes.object,
  errors: PropTypes.object,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormSelect;
