import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Radio, Spin } from "antd";
import { getSchoolID } from "../shared/getSchoolID";
import {
  GET_ACTIVE_STUDENTS,
  GET_STUDENT_DOCUMENT_DETAILS,
  UPDATE_STUDENT_AND_SCHOOL,
  ISSUE_DOCUMENT,
} from "../views/documentsView/graphql";
import { GET_SCHOOL_DETAILS } from "../graphql/queries";
import { GET_ACADEMIC_YEAR } from "../views/settingsView/graphql";
import BonafideCertificateView from "../views/documentsView/bonafideCertificateView";
import { useKeyPress } from "../utils/hooks";
import Button from "../components/Button";
import Tabs, { TabPane } from "rc-tabs";
import { LANGUAGE_CODES } from "../utils/constants";
import { useTranslation } from "react-i18next";
import useTranslationAPI from "../hooks/useTranslation";
import useTransliteration from "../hooks/useTransliteration";
import { getLanguageInputCode, numberToWords } from "../utils";
import { UPDATE_SCHOOL } from "../graphql/mutations";
import axios from "axios";
import { PencilIcon } from "@heroicons/react/solid";
import useTracking from "../hooks/useTracking";

function BonafideCertificate() {
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [activeTab, setActiveTab] = useState(1);
  const [isEditingFormat, setIsEditingFormat] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    LANGUAGE_CODES.ENGLISH
  );
  const [previousLanguage, setPreviousLanguage] = useState("en");
  const [documentSchoolData, setDocumentSchoolData] = useState(null);
  const [documentStudentData, setDocumentStudentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const { i18n } = useTranslation();
  const DOCUMENT_CODE = "bnc";
  const { trackEvent } = useTracking();

  const [getTranslation, { loading: isTranslationLoading }] =
    useTranslationAPI();

  const [getTransliteratedData, { loading: isTransliterationLoading }] =
    useTransliteration();

  const { loading: studentsLoading, data: studentsData } = useQuery(
    GET_ACTIVE_STUDENTS,
    {
      variables: {
        schoolId: getSchoolID(),
        name: `%${search}%`,
        documentType: "Bonafide Certificate",
      },
    }
  );

  const { data: dbSchoolData } = useQuery(GET_SCHOOL_DETAILS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (res) => {
      const school = res.schools_by_pk;
      let schoolData = {
        image: school.image,
        slogan: school.slogan,
        trustName: school.trustName,
        schoolName: school.schoolName,
        indexNumber: school.index_number,
        cityName: school?.address?.city?.city_name,
        districtName: school?.address?.city?.district?.district_name,
        stateName: school?.address?.city?.district?.state?.state_name,
        udiseNumber: school.udiseNumber,
        schoolRegistrationNumber: school?.registrationNumber,
        mobileNumber: school?.mobileNumber,
        board: school?.boardName,
        schoolMedium: school?.medium,
        email: school?.email,
        settings: school?.settings,
      };
      // get language from school settings documents
      let currentLanguage = LANGUAGE_CODES.ENGLISH;
      const documents = school?.settings?.documents;
      if (documents?.length) {
        // find document with code "nu"
        const document = documents.find(
          (document) => document.code === DOCUMENT_CODE
        );
        if (document) {
          currentLanguage = document.languageCode;
          setSelectedLanguage(document.languageCode);
          i18n.changeLanguage(document.languageCode);
        }
      }
      setDocumentSchoolData({
        ...documentSchoolData,
        ...schoolData,
      });
      // find language specific data in settings
      const languageData = schoolData.settings?.translation?.[currentLanguage];
      if (languageData) {
        schoolData = {
          ...schoolData,
          ...languageData,
        };
        setDocumentSchoolData({
          ...documentSchoolData,
          ...schoolData,
        });
      } else {
        if (currentLanguage !== LANGUAGE_CODES.ENGLISH) {
          translateSchoolData(currentLanguage, schoolData);
        }
      }
    },
  });

  const translateSchoolData = async (targetLang, schoolData) => {
    let data = schoolData || documentSchoolData;
    const schoolLanguageData = data.settings?.translation?.[targetLang];
    if (schoolLanguageData) {
      data = {
        ...data,
        ...schoolLanguageData,
      };
      setDocumentSchoolData({
        ...documentSchoolData,
        ...data,
      });
    } else if (targetLang === LANGUAGE_CODES.ENGLISH) {
      // if no saved data for enlish lang and translating back to english, then use original data
      const school = dbSchoolData.schools_by_pk;
      let dbData = {
        image: school.image,
        trustName: school.trustName,
        schoolName: school.schoolName,
        cityName: school?.address?.city?.city_name,
        stateName: school?.address?.city?.district?.state?.state_name,
        udiseNumber: school.udiseNumber,
        schoolRegistrationNumber: school?.registrationNumber,
        mobileNumber: school?.mobileNumber,
        board: school?.boardName,
        schoolMedium: school?.medium,
        email: school?.email,
        settings: school?.settings,
      };
      setDocumentSchoolData({
        ...documentSchoolData,
        ...dbData,
      });
    } else {
      const { board, schoolMedium } = data;
      const translatedSchoolData = await getTranslation({
        textObj: {
          board,
          schoolMedium,
        },
        sourceLang: previousLanguage,
        targetLang: targetLang,
      });
      let transliterateTextData = {
        trustName: data?.trustName,
        schoolName: data?.schoolName,
        cityName: data?.cityName,
        stateName: data?.stateName,
      };
      const transliteratedSchoolData = await getTransliteratedData({
        textObj: transliterateTextData,
        inputCode: getLanguageInputCode(targetLang),
      });
      setDocumentSchoolData({
        ...documentSchoolData,
        ...data,
        ...translatedSchoolData,
        ...transliteratedSchoolData,
      });
    }
  };

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && downPress) {
      setCursor((prevState) =>
        prevState < students.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && enterPress) {
      handleSelectStudent(students[cursor]);
    }
  }, [cursor, enterPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && hovered) {
      setCursor(students.indexOf(hovered));
    }
  }, [hovered]);

  const {
    loading: academicYearLoading,
    error: academicYearError,
    data: academicYearData,
  } = useQuery(GET_ACADEMIC_YEAR, {
    variables: {
      schoolId: getSchoolID(),
    },
  });

  const [issueDocument] = useMutation(ISSUE_DOCUMENT, {
    refetchQueries: [
      {
        query: GET_STUDENT_DOCUMENT_DETAILS,
        variables: {
          studentId: documentStudentData?.studentId,
          documentType: "Bonafide Certificate",
        },
      },
    ],
  });

  const [
    getStudentDocumentDetails,
    { loading: docLoading, data: studentDocumentDetails },
  ] = useLazyQuery(GET_STUDENT_DOCUMENT_DETAILS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (docData) => {
      const student = docData?.students_by_pk;
      const issuedDoc = student?.documents_issuance_histories?.[0];
      const dobDayWords = numberToWords(moment(student?.dob).format("D"));
      const dobMonthWords = moment(student?.dob).format("MMMM");
      const dobYearWords = numberToWords(moment(student?.dob).format("YYYY"));
      const currentYear = moment().year();
      const schoolDocNumber = student?.division?.school?.document_number + 1;
      const defaultSerialNumber = `${currentYear}${schoolDocNumber.toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 5,
          useGrouping: false,
        }
      )}B`;
      let studentRef = student?.student_ref;
      // check for system generated student ref, make it blank if exists
      if (studentRef?.startsWith("GUID")) {
        studentRef = "";
      }
      let studentDocumentData = {
        studentId: student?.id,
        schoolDocumentNumber: student?.division?.school?.document_number,
        serialNumber: issuedDoc?.document_number || defaultSerialNumber,
        date: issuedDoc
          ? moment(issuedDoc?.issuance_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        studentName: student?.full_name,
        birthPlace: student?.place_of_birth,
        caste: student?.caste,
        studentRef: studentRef,
        generalRegisterNumber: student?.gr_no,
        dateOfBirth: student?.dob
          ? moment(student?.dob).format("DD/MM/YYYY")
          : null,
        dateOfBirthInWords: `${
          dobDayWords || ""
        } ${dobMonthWords} ${dobYearWords}`,
        academicYear: student?.division?.acadamic_year?.title,
        class: student?.division?.class?.class_name,
        transferDate: student?.transfer_date
          ? moment(student?.transfer_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        image: student?.division?.school?.image,
        behaviour: student?.conduct,
        isIssued: issuedDoc?.is_active,
        additionalInfo: student?.additional_info,
      };
      setDocumentStudentData({
        ...studentDocumentData,
      });
      let currentLanguage = "en";
      const document = documentSchoolData?.settings?.documents?.find(
        (document) => document.code === DOCUMENT_CODE
      );
      currentLanguage = document?.languageCode;
      const languageData =
        student.additional_info?.translation?.[currentLanguage];
      if (languageData) {
        studentDocumentData = {
          ...studentDocumentData,
          ...languageData,
          date: languageData.issuanceDate || studentDocumentData.date,
        };
        setDocumentStudentData({
          ...studentDocumentData,
        });
      } else {
        // translate data if language data is not available
        const docData = documentSchoolData?.settings?.documents?.find(
          (document) => document.code === DOCUMENT_CODE
        );
        // Translate document if language is not english
        if (docData?.languageCode !== LANGUAGE_CODES.ENGLISH) {
          translateStudentData(docData?.languageCode, studentDocumentData);
        }
      }
    },
  });

  const translateStudentData = async (targetLang, studentData) => {
    setIsLoading(true);
    let data = studentData || documentStudentData;
    const hasStudentData = data?.studentName;
    if (!hasStudentData) {
      return;
    }
    const studentLanguageData = data?.additionalInfo?.translation?.[targetLang];
    if (studentLanguageData) {
      setDocumentStudentData({
        ...documentStudentData,
        ...studentLanguageData,
      });
    } else {
      const textObj = {
        caste: data?.caste,
        dateOfBirthInWords: data?.dateOfBirthInWords,
        class: data?.class,
        behaviour: data?.behaviour,
      };
      const translatedStudentData = await getTranslation({
        textObj,
        sourceLang: previousLanguage,
        targetLang: targetLang,
      });
      let transliterateTextData = {
        studentName: data?.studentName?.trim(),
      };
      const transliteratedData = await getTransliteratedData({
        textObj: transliterateTextData,
        inputCode: getLanguageInputCode(targetLang),
      });
      setDocumentStudentData({
        ...documentStudentData,
        ...data,
        ...translatedStudentData,
        ...transliteratedData,
      });
    }
    setIsLoading(false);
  };

  const [updateSchool] = useMutation(UPDATE_SCHOOL, {
    refetchQueries: [
      {
        query: GET_SCHOOL_DETAILS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const [updateStudentAndSchool] = useMutation(UPDATE_STUDENT_AND_SCHOOL, {
    refetchQueries: [
      {
        query: GET_SCHOOL_DETAILS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
      {
        query: GET_STUDENT_DOCUMENT_DETAILS,
        variables: {
          studentId: studentDocumentDetails?.students_by_pk?.id,
          documentType: "Bonafide Certificate",
        },
      },
    ],
  });
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSelectStudent = (student) => {
    setSearch("");
    // query for student document details and set to formDetails when data is returned
    getStudentDocumentDetails({
      variables: {
        studentId: student.id,
        documentType: "Bonafide Certificate",
      },
    });
  };

  const handleSave = (docDetails) => {
    trackEvent("Save Bonafide Certificate");
    let studentAdditionalInfo = {
      ...documentStudentData?.additionalInfo,
    };
    if (!studentAdditionalInfo.translation) {
      studentAdditionalInfo.translation = {};
    }
    const studentTranslationData = {
      ...studentAdditionalInfo.translation,
      [selectedLanguage]: {
        ...studentAdditionalInfo.translation?.[selectedLanguage],
        generalRegisterNumber: docDetails.generalRegisterNumber,
        studentRef: docDetails.studentRef,
        studentName: docDetails.studentName,
        birthPlace: docDetails.birthPlace,
        taluka: docDetails.taluka,
        districtName: docDetails.districtName,
        state: docDetails.state,
        caste: docDetails.caste,
        dateOfBirth: moment(docDetails.dateOfBirth, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        dateOfBirthInWords: docDetails?.dateOfBirthInWords,
        class: docDetails.class,
        behaviour: docDetails.behaviour,
        issuanceDate: docDetails?.date || moment().format("DD/MM/YYYY"),
      },
    };
    studentAdditionalInfo.translation = studentTranslationData;
    let schoolSettings = { ...documentSchoolData?.settings };
    const hasTranslationData = schoolSettings.translation;
    if (!hasTranslationData) {
      schoolSettings.translation = {};
    }
    const schoolTranslationData = {
      ...schoolSettings.translation,
      [selectedLanguage]: {
        ...schoolSettings.translation?.[selectedLanguage],
        schoolRegistrationNumber: docDetails.schoolRegistrationNumber,
        udiseNumber: docDetails.udiseNumber,
        indexNumber: docDetails.indexNumber,
        mobileNumber: docDetails.mobileNumber,
        board: docDetails.board,
        schoolMedium: docDetails.schoolMedium,
        email: docDetails.email,
      },
    };
    schoolSettings.translation = schoolTranslationData;
    updateStudentAndSchool({
      variables: {
        studentId: docDetails.studentId,
        schoolId: getSchoolID(),
        studentData: {
          additional_info: studentAdditionalInfo,
        },
        schoolData: {
          settings: schoolSettings,
        },
      },
    });
    setIsEditing(false);
  };

  const handleLanguageChange = async (value, schoolData) => {
    setIsLoading(true);
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
    await translateSchoolData(value, schoolData);
    setIsLoading(false);
  };

  const toggleEditFormat = () => {
    setIsEditingFormat(!isEditingFormat);
  };

  const handleCancelEditFormat = () => {
    toggleEditFormat();
    // reset language to default
    // check if language is english in school settings
    const tcDocument = documentSchoolData?.settings?.documents?.find(
      (document) => document.code === "bnc"
    );
    if (tcDocument?.languageCode === "en") {
      handleLanguageChange("en");
    }
    if (tcDocument?.languageCode === "mr-IN") {
      handleLanguageChange("mr-IN");
    }
  };

  const handleSaveFormat = async (formData) => {
    trackEvent("Save Bonafide Certificate Format");
    let updatedSettings = { ...documentSchoolData?.settings };
    const englishTemplate = "mh_bnc_english";
    const marathiTemplate = "mh_bnc_marathi";
    const marathiInputCode = "mr-t-i0-und";
    // update or add document to settings
    const hasDocument =
      updatedSettings.documents &&
      updatedSettings.documents.find((doc) => doc.code === "bnc");
    if (!hasDocument) {
      const currentDocuments = updatedSettings?.documents || [];
      updatedSettings.documents = [...currentDocuments];
      setPreviousLanguage("en");
      updatedSettings.documents.push({
        name: "Bonafide Certificate",
        code: "bnc",
        language: selectedLanguage === "en" ? "English" : "Marathi",
        languageCode: selectedLanguage === "en" ? "en" : "mr-IN",
        googleInputCode: selectedLanguage === "en" ? "en" : marathiInputCode,
        template: selectedLanguage === "en" ? englishTemplate : marathiTemplate,
      });
    } else {
      const documentIndex = updatedSettings.documents.findIndex(
        (document) => document.code === "bnc"
      );
      if (documentIndex > -1) {
        const documents = [...updatedSettings.documents];
        const document = { ...documents[documentIndex] };
        setPreviousLanguage(document.languageCode);
        document.language = selectedLanguage === "en" ? "English" : "Marathi";
        document.languageCode = selectedLanguage === "en" ? "en" : "mr-IN";
        document.googleInputCode =
          selectedLanguage === "en" ? "en" : marathiInputCode;
        document.template =
          selectedLanguage === "en" ? englishTemplate : marathiTemplate;
        documents[documentIndex] = document;
        updatedSettings.documents = documents;
      }
    }
    // udpate translated data in settings
    const hasTranslationData = updatedSettings.translation;
    if (!hasTranslationData) {
      updatedSettings.translation = {};
    }
    const translationData = {
      ...updatedSettings.translation,
      [selectedLanguage]: {
        slogan: formData.slogan,
        trustName: formData.trustName,
        schoolName: formData.schoolName,
        cityName: formData.cityName,
        stateName: formData.stateName,
        udiseNumber: formData.udiseNumber,
        indexNumber: formData.indexNumber,
        schoolRegistrationNumber: formData.schoolRegistrationNumber,
        mobileNumber: formData.mobileNumber,
        board: formData.board,
        schoolMedium: formData.schoolMedium,
        email: formData.email,
      },
    };
    updatedSettings.translation = translationData;
    updateSchool({
      variables: {
        schoolId: getSchoolID(),
        schoolData: {
          settings: updatedSettings,
        },
      },
    });
    setIsEditingFormat(false);
  };

  const handleDownloadDocument = (formData, isIssued) => {
    trackEvent("Download Bonafide Certificate", {
      isIssued,
    });
    setIsEditing(false);
    setIsLoading(true);
    // copy formattedTCData and replace with "-" string if it is null
    const formattedData = { ...documentSchoolData, ...documentStudentData };
    Object.keys(formattedData).forEach((key) => {
      if (
        formattedData[key] === null ||
        formattedData[key] === "null" ||
        formattedData[key] === "undefined" ||
        formattedData[key] === ""
      ) {
        formattedData[key] = "";
      }
    });
    const requestDocData = {
      ...formattedData,
    };
    const getDocPayload = {
      ...requestDocData,
      conduct: formattedData.behaviour,
      serialNumber: formattedData?.serialNumber
        ? `${formattedData?.serialNumber}`
        : `-`,
      dateOfBirthInWords:
        formData?.dateOfBirthInWords || requestDocData?.dateOfBirthInWords,
    };
    // remove settings from payload
    delete getDocPayload["settings"];
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getBonafide`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: selectedLanguage === "en" ? "english" : "marathi",
        isDuplicate: false,
        data: {
          ...getDocPayload,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${formattedData.studentName}_Bonafide_Certificate`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        if (formData && isIssued) {
          handleSave(formData);
          const academicYearId = academicYearData?.acadamic_years?.[0]?.id;
          issueDocument({
            variables: {
              studentId: formData.studentId,
              school_id: getSchoolID(),
              documentNumber: `${formData.serialNumber}`,
              documentType: "Bonafide Certificate",
              issuanceDate: formData?.date
                ? moment(formData?.date, "DD/MM/YYYY").format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
              academicYearId,
            },
          });
          // increment school document number
          updateStudentAndSchool({
            variables: {
              studentId: formData.studentId,
              schoolId: getSchoolID(),
              studentData: {},
              schoolData: {
                document_number: documentStudentData.schoolDocumentNumber + 1,
              },
            },
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleDownloadSampleDocument = async () => {
    trackEvent("Download Sample Bonafide Certificate");
    const sampleData = {
      slogan: documentSchoolData.slogan,
      trustName: documentSchoolData?.trustName,
      schoolName: documentSchoolData?.schoolName,
      cityName: documentSchoolData?.cityName,
      stateName: documentSchoolData?.stateName,
      board: documentSchoolData?.board,
      udiseNumber: documentSchoolData.udiseNumber,
      indexNumber: documentSchoolData.indexNumber,
      schoolRegistrationNumber: documentSchoolData.schoolRegistrationNumber,
      mobileNumber: documentSchoolData.mobileNumber,
      schoolMedium: documentSchoolData.schoolMedium,
      email: documentSchoolData.email,
      generalRegisterNumber: "3000",
      date: "01/04/2022",
      studentRef: "ID:2011272812007040055",
      studentId: "4000",
      academicYear: "2020-2021",
      dateOfBirth: "01/06/2012",
      image: documentSchoolData?.image,
      conduct: "Good",
    };
    const textObj = {
      dateOfBirthInWords: "One June Two Thousand Twelve",
      class: "2",
      behaviour: "good",
      conduct: "Good",
    };
    const targetLanguage = selectedLanguage === "en" ? "en" : "mr-IN";
    const translatedStudentData = await getTranslation({
      textObj,
      sourceLang: previousLanguage,
      targetLang: targetLanguage,
    });
    let transliterateTextData = {
      caste: "Maratha",
      birthPlace: "Pune",
      taluka: "Pune",
      districtName: "Pune",
      state: "Maharashtra",
      country: "India",
      studentName: "Student Name",
    };
    const transliteratedData = await getTransliteratedData({
      textObj: transliterateTextData,
      inputCode: getLanguageInputCode(targetLanguage),
    });
    let translatedSampleData = {
      ...sampleData,
      ...translatedStudentData,
      ...transliteratedData,
    };
    setIsLoading(true);
    const formattedData = { ...sampleData };
    Object.keys(formattedData).forEach((key) => {
      if (
        formattedData[key] === null ||
        formattedData[key] === "null" ||
        formattedData[key] === "undefined"
      ) {
        formattedData[key] = "";
      }
    });
    const requestedData = {
      ...formattedData,
      ...translatedSampleData,
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getBonafide`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: selectedLanguage === "en" ? "english" : "marathi",
        isDuplicate: false,
        data: {
          ...requestedData,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `Sample_Bonafide_Certificate`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-hidden">
      <div className="flex flex-row gap-x-4 items-center mb-9 justify-between mr-7">
        <p className="text-2xl text-left font-bold flex-col m-0">
          Bonafide Certificate
        </p>
        <Button
          id="documents-bonafide-back"
          buttonStyle="secondary"
          onClick={() => navigate(-1)}
        >
          Back to Documents
        </Button>
      </div>
      <Tabs
        activeKey={activeTab.toString()}
        onChange={(key) => {
          if (!isEditingFormat && !isEditing) {
            setActiveTab(Number(key));
            if (key === "2") {
              setDocumentStudentData(null);
              setSearch(null);
            }
          }
        }}
        className="custom-tabs overflow-y-auto px-4"
        animated={{ inkBar: true, tabPane: false }}
      >
        <TabPane
          tab="Issue Bonafide Certificate"
          key={1}
          className="relative mt-4 mb-6"
        >
          <div className="relative mr-7 h-full">
            <div className="relative">
              <p className="text-left font-semibold">Select a student</p>
              <div className="flex relative mt-2">
                <span class="absolute inset-y-0 left-0 flex items-center pl-4">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  id='"form-subscribe-Filter'
                  className="pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  placeholder="Search by student name"
                  autoComplete="off"
                  onChange={(e) => {
                    e.preventDefault();
                    if (
                      e.target.value.length >= 2 ||
                      e.target.value.length === 0
                    ) {
                      setSearch(e.target.value);
                    }
                  }}
                />
                <Button
                  buttonStyle="success"
                  className="ml-6"
                  onClick={handleDownloadSampleDocument}
                >
                  Download Sample Document
                </Button>
              </div>
              {studentsData && search && (
                <div className="bg-white absolute z-30 w-11/12 mt-2 border border-gray-300 rounded-md">
                  {studentsData.students &&
                    studentsData?.students?.map((student, index) => {
                      const isActive = index === cursor;
                      return (
                        <button
                          className={`flex flex-col px-9 py-2 items-start justify-center border-b w-full text-black font-bold  ${
                            isActive && "bg-gray-200"
                          }`}
                          key={student.id}
                          onClick={() => {
                            handleSelectStudent(student);
                          }}
                          onMouseEnter={() => {
                            setHovered(student);
                          }}
                          onMouseLeave={() => {
                            setHovered(null);
                          }}
                        >
                          {`${student.full_name} • ${
                            student.division.division_name
                          } • ${student.mother_name || "-"} • ${moment(
                            student.dob
                          ).format("DD MMM YYYY")} • UID no.${student.aadhaar}`}
                        </button>
                      );
                    })}
                </div>
              )}
            </div>
            <div className="flex my-3 gap-x-9">
              <p className="text-black font">
                Selected student :{" "}
                <strong className="text-black font-semibold">
                  {documentStudentData?.studentName || "-"}
                </strong>
              </p>
            </div>
            <Spin spinning={docLoading || isLoading}>
              <BonafideCertificateView
                isMasked={studentsData?.students?.length > 0 && search}
                isEditing={isEditing}
                isEditable={documentStudentData}
                selectedLanguage={selectedLanguage}
                data={{
                  ...documentSchoolData,
                  ...documentStudentData,
                }}
                onCancel={toggleEdit}
                onEdit={toggleEdit}
                onSave={handleSave}
                onDownloadDocument={handleDownloadDocument}
                containerStyle={{
                  fontFamily: selectedLanguage === "en" ? "" : "Mukta",
                }}
              />
            </Spin>
          </div>
        </TabPane>
        <TabPane
          tab="Create your own format"
          key={2}
          className="relative mt-4 mb-6"
        >
          <div className="flex justify-between">
            <div className="text-left">
              <label className="font-semibold text-black">
                Select Language
              </label>
              <div className="flex gap-x-3 mt-1">
                <Radio.Group
                  onChange={(e) => {
                    handleLanguageChange(e.target.value);
                  }}
                  value={selectedLanguage}
                  style={{
                    display: "flex",
                    columnGap: "0.75rem",
                  }}
                  disabled={!isEditingFormat}
                >
                  <div
                    id="documents-nirgam-edit-english"
                    className="bg-white py-2 px-3 rounded-lg shadow-md"
                  >
                    <Radio
                      value={LANGUAGE_CODES.ENGLISH}
                      checked={selectedLanguage === LANGUAGE_CODES.ENGLISH}
                    >
                      <strong
                        className={`font-semibold ${
                          selectedLanguage === LANGUAGE_CODES.ENGLISH
                            ? "text-black"
                            : "text-gray-600"
                        } ${!isEditingFormat && "opacity-50"}`}
                      >
                        English
                      </strong>
                    </Radio>
                  </div>
                  <div
                    id="documents-nirgam-edit-marathi"
                    className="bg-white py-2 px-3 rounded-lg shadow-md"
                  >
                    <Radio
                      value={LANGUAGE_CODES.MARATHI}
                      checked={selectedLanguage === LANGUAGE_CODES.MARATHI}
                    >
                      <strong
                        className={`font-semibold ${
                          selectedLanguage === LANGUAGE_CODES.MARATHI
                            ? "text-black"
                            : "text-gray-600"
                        } ${!isEditingFormat && "opacity-50"}`}
                      >
                        Marathi
                      </strong>
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
              {!isEditingFormat && (
                <p className="mt-2 text-cancel font-semibold">
                  Please click on the edit format to change the school details
                  or the transfer certificate language.
                </p>
              )}
              {isEditingFormat && (
                <p className="mt-2 text-cancel font-semibold">
                  Please select the language for the transfer certificate.
                </p>
              )}
            </div>
            <Button
              id="documents-transfer-edit-format-1"
              buttonStyle="primary"
              className="h-12 mr-6"
              onClick={() => {
                toggleEditFormat();
              }}
            >
              <PencilIcon className="h-4 w-4" aria-hidden="true" />
              <span className="ml-2">Edit Format</span>
            </Button>
          </div>
          <Spin spinning={docLoading || isLoading}>
            <BonafideCertificateView
              isMasked={studentsData?.students?.length > 0 && search}
              isEditing={isEditingFormat}
              languageInputCode={getLanguageInputCode(selectedLanguage)}
              data={documentSchoolData}
              getTranslation={getTranslation}
              selectedLanguage={selectedLanguage}
              onCancel={handleCancelEditFormat}
              onEdit={toggleEditFormat}
              onSave={handleSaveFormat}
              onDownloadDocument={handleDownloadDocument}
              isEditFormat
              hideDownload
              containerStyle={{
                fontFamily:
                  selectedLanguage === LANGUAGE_CODES.ENGLISH ? "" : "Mukta",
              }}
            />
          </Spin>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default BonafideCertificate;
