import React, { useState } from "react";
import { Modal, AutoComplete } from "antd";
import { Table, Switch, Button, Dropdown } from "../../components";
import SubjectSettings from './SubjectSettings';
import useTransliteratedList from "../../hooks/useTransliteratedList";
import { ADD_NEW_GROUP_ID, ENGLISH, MAX_RESULT } from "../../utils/constants";
import { MARATHI } from "../../utils/constants";
import {
  LANGUAGE_CODES,
  LANGUAGE_INPUT_CODES,
  SUBJECT_MARKING_SYSTEM_OPTIONS,
} from "../../utils/constants";

const COLUMN_FOR = {
  GROUP_DISPLAY_NAME: "groupDisplayName",
  SUBJECT_DISPLAY_NAME: "subjectDisplayName",
};

function AutoCompleteInput({
  field,
  options,
  formData,
  onSelect,
  onChange,
  onBlur,
  style,
  inputClassName,
  value,
}) {
  return (
    <AutoComplete
      options={options}
      style={{ ...style, height: "100%" }}
      value={value}
      onSelect={onSelect}
      onBlur={onBlur}
      dropdownMatchSelectWidth={false}
      onInputKeyDown={(e) => e.stopPropagation()}
    >
      <input
        type="text"
        className={`text-center ${inputClassName || ""}`}
        value={value}
        onChange={onChange}
        style={{
          height: "5rem",
        }}
      />
    </AutoComplete>
  );
}

function EditSubjectsModal({
  subjects,
  isVisible,
  onClose,
  onSave,
  medium,
  groupDropdown,
  reportData,
}) {
  const [currentSubjectForSettings, setCurrentSubjectForSettings] = useState({
    visible: false
  })
  const [subjectsFormData, setSubjectsFormData] = useState({});
  const {
    data: translationList,
    error,
    refetch: getTransliteratedList,
  } = useTransliteratedList();
  const [optionsList, setOptions] = useState({
    column: "",
    optionsFor: "",
    options: [],
  });
  const { options } = optionsList;
  const [reportsData, setReportsData] = useState(reportData);

  const finalGroupDropdown = groupDropdown?.filter(
    (group) => group?.value !== ADD_NEW_GROUP_ID
  );

  const getList = async (sourceText, id, column) => {
    if (medium === MARATHI) {
      const inputLanguage = LANGUAGE_CODES["MARATHI"];
      const inputCode = LANGUAGE_INPUT_CODES[inputLanguage];
      const maxResult = MAX_RESULT;
      const res = await getTransliteratedList({
        sourceText,
        inputCode,
        maxResult,
      });
      setOptions({
        column,
        optionsFor: id,
        options: res,
      });
    }
  };

  const startSubjectEdit = (subject) => {
    let displayName = subject.name;
    const id = subject.id;
    if (subject?.translations?.subject?.[medium]) {
      displayName = subject.translations.subject[medium];
    }
    setCurrentSubjectForSettings({
      visible: true,
      displayName,
      id
    });
  };

  // We store display name in subjectDisplayName field and use that at the end in payload
  const getUpdatedOptions = (e, row, column) => {
    const newDisplayValue = e.target.value;
    const oldValue = subjectsFormData?.[row?.id]?.[column];
    let valueToSet = newDisplayValue;

    if (oldValue === newDisplayValue?.trim()) {
      valueToSet = optionsList?.options?.[0]?.value + " ";
      setOptions((prevOptions) => ({ ...prevOptions, options: [] }));
    }

    setSubjectsFormData({
      ...subjectsFormData,
      [row.id]: {
        ...row,
        ...subjectsFormData[row.id],
        [column]: valueToSet,
      },
    });
    getList(e.target.value, row?.id, column);
  };

  // To display tranlations(displayName)
  // If edited values present, we use that
  // If payload contains translation, use that
  // If medium is english, we use the name as it is, if user updates it it gets stored in translations
  const SUBJECT_TABLE_EDIT_COLUMNS = [
    {
      title: () => <p className="font-semibold text-base">Subject Name</p>,
      dataIndex: "name",
      editable: false,
      // antd has default margin and padding of 16px
      render: (text, row) => (
        <div
          className={`font-semibold flex items-center justify-center bg-disabledGrey w-full h-20 text-center`}
          style={{
            minWidth: "6rem",
            outline: "none",
            border: "none",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-semibold text-base">Subject Display Name</p>
      ),
      dataIndex: ["translations", "subject", medium],
      editable: true,
      render: (text, row) => {
        let valueToRender = text || "";
        if (
          typeof subjectsFormData?.[row.id]?.subjectDisplayName ===
          typeof "string"
        ) {
          valueToRender = subjectsFormData?.[row.id]?.subjectDisplayName;
        } else {
          if (medium === ENGLISH && !text) {
            valueToRender = row?.name;
          }
        }
        return (
          <span
            className={`justify-center items-center font-semibold ${
              subjectsFormData?.[row.id]?.subjectDisplayName &&
              subjectsFormData?.[row.id]?.subjectDisplayName !== text
                ? "bg-lightyellow"
                : ""
            }`}
            style={{
              width: "100%",
              minWidth: "6rem",
              outline: "none",
              height: "5rem",
              border: "none",
              textAlign: "center",
            }}
          >
            <AutoCompleteInput
              field="subjectDisplayName"
              inputClassName={`font-semibold ${
                subjectsFormData?.[row.id]?.subjectDisplayName &&
                subjectsFormData?.[row.id]?.subjectDisplayName !== text
                  ? "bg-lightyellow"
                  : ""
              }`}
              style={{
                width: "100%",
                minWidth: "6rem",
                outline: "none",
                height: "5rem",
                border: "none",
                textAlign: "center",
              }}
              value={valueToRender}
              options={
                optionsList?.optionsFor === row?.id &&
                optionsList?.column === COLUMN_FOR.SUBJECT_DISPLAY_NAME
                  ? optionsList?.options
                  : []
              }
              onChange={(e) =>
                getUpdatedOptions(e, row, COLUMN_FOR.SUBJECT_DISPLAY_NAME)
              }
              onSelect={(value) => {
                setSubjectsFormData({
                  ...subjectsFormData,
                  [row.id]: {
                    ...row,
                    ...subjectsFormData[row.id],
                    subjectDisplayName: value,
                  },
                });
              }}
            />
          </span>
        );
      },
    },

    {
      title: () => <p className="font-semibold text-base">Group</p>,
      editable: true,
      dataIndex: "group",
      render: (group, row) => (
        <div
          className={`flex justify-center items-center h-20 ${
            subjectsFormData?.[row.id]?.group &&
            subjectsFormData?.[row.id]?.group !== group
              ? "bg-lightyellow"
              : ""
          }`}
        >
          <Dropdown
            type="default"
            defaultValue="Select Group"
            value={subjectsFormData?.[row.id]?.group || group}
            options={finalGroupDropdown}
            onItemClick={(value) => {
              const currentSelectedGroup = finalGroupDropdown?.find(
                (group) => parseInt(group?.value) === parseInt(value)
              );

              const groupDisplayName =
                currentSelectedGroup?.groupTranslations?.[medium] ||
                currentSelectedGroup?.label;

              setSubjectsFormData({
                ...subjectsFormData,
                [row.id]: {
                  ...row,
                  ...subjectsFormData[row.id],
                  group: currentSelectedGroup?.label,
                  groupDisplayName,
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-semibold text-base">Group Display Name</p>
      ),
      dataIndex: ["translations", "group", medium],
      editable: true,
      render: (text, row) => {
        let valueToRender = text || "";
        if (
          typeof subjectsFormData?.[row.id]?.groupDisplayName ===
          typeof "string"
        ) {
          valueToRender = subjectsFormData?.[row.id]?.groupDisplayName;
        } else {
          if (medium === ENGLISH && !text) {
            valueToRender = row?.name;
          }
        }

        return (
          <span
            className={`justify-center items-center font-semibold ${
              subjectsFormData?.[row.id]?.groupDisplayName &&
              subjectsFormData?.[row.id]?.groupDisplayName !== text
                ? "bg-lightyellow"
                : ""
            }`}
            style={{
              width: "100%",
              minWidth: "6rem",
              outline: "none",
              height: "5rem",
              border: "none",
              textAlign: "center",
            }}
          >
            <AutoCompleteInput
              field="groupDisplayName"
              inputClassName={`font-semibold ${
                subjectsFormData?.[row.id]?.groupDisplayName &&
                subjectsFormData?.[row.id]?.groupDisplayName !== text
                  ? "bg-lightyellow"
                  : ""
              }`}
              style={{
                width: "100%",
                minWidth: "6rem",
                outline: "none",
                height: "5rem",
                border: "none",
                textAlign: "center",
              }}
              value={valueToRender}
              options={
                optionsList?.optionsFor === row?.id &&
                optionsList?.column === COLUMN_FOR.GROUP_DISPLAY_NAME
                  ? optionsList?.options
                  : []
              }
              onChange={(e) =>
                getUpdatedOptions(e, row, COLUMN_FOR.GROUP_DISPLAY_NAME)
              }
              onSelect={(value) => {
                setSubjectsFormData({
                  ...subjectsFormData,
                  [row.id]: {
                    ...row,
                    ...subjectsFormData[row.id],
                    groupDisplayName: value,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
    {
      title: () => <p className="font-semibold text-base">Core Subject</p>,
      dataIndex: "is_core",
      editable: true,
      render: (is_core, row) => (
        <div
          className={`text-center h-20 flex justify-center items-center ${
            subjectsFormData?.[row.id]?.is_core !== undefined &&
            subjectsFormData?.[row.id]?.is_core !== is_core
              ? "bg-lightyellow"
              : ""
          }`}
        >
          <Switch
            checked={subjectsFormData?.[row.id]?.is_core}
            defaultChecked={is_core}
            type="default"
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(value) => {
              setSubjectsFormData({
                ...subjectsFormData,
                [row.id]: {
                  ...row,
                  ...subjectsFormData[row.id],
                  is_core: value,
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      title: () => <p className="font-semibold text-base">Enabled/Disabled</p>,
      editable: true,
      dataIndex: "isEnabled",
      render: (isEnabled, row) => (
        <div
          className={`text-center h-20 flex justify-center items-center ${
            subjectsFormData?.[row.id]?.isEnabled !== undefined &&
            subjectsFormData?.[row.id]?.isEnabled !== isEnabled
              ? "bg-lightyellow"
              : ""
          }`}
        >
          <Switch
            checked={subjectsFormData?.[row.id]?.isEnabled}
            defaultChecked={isEnabled}
            type="default"
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(value) => {
              setSubjectsFormData({
                ...subjectsFormData,
                [row.id]: {
                  ...row,
                  ...subjectsFormData[row.id],
                  isEnabled: value,
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      title: () => <p className="font-semibold text-base">Settings</p>,
      editable: false,
      dataIndex: "isEnabled",
      render: (isEnabled, row) => (
        <div onClick={() => startSubjectEdit(row)} className={`text-center text-primary font-semibold cursor-pointer h-20 flex justify-center items-center`}>
          Edit
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    setSubjectsFormData({});
    onClose();
  };

  const handleSave = () => {
    onSave(subjectsFormData, reportsData);
    setSubjectsFormData({});
  };

  return (
    <>
    <Modal
      visible={isVisible}
      centered
      closable={false}
      bodyStyle={{
        justifyContent: "center",
      }}
      width={"90%"}
      footer={null}
      destroyOnClose
    >
      <div id="subject-edit-table" className="flex flex-col gap-y-3 pt-7 px-6">
        <Table
          tableColumns={SUBJECT_TABLE_EDIT_COLUMNS}
          tableData={subjects}
          isEditable
          isHeaderEditable={false}
          onCancel={onClose}
        />
        <div className="flex">
          <div
            className={`flex border-2 p-3 border-gray-300 justify-center items-center gap-x-2 ${
              reportsData?.progressReportTemplate !==
                reportData?.progressReportTemplate && "bg-lightyellow"
            }`}
          >
            <label className="text-gray-500 font-semibold ">
              Progress Report Template :
            </label>
            <Dropdown
              type="default"
              defaultValue={reportsData?.progressReportTemplate}
              value={reportsData?.progressReportTemplate}
              options={SUBJECT_MARKING_SYSTEM_OPTIONS}
              onItemClick={(value) => {
                setReportsData((prevState) => ({
                  ...prevState,
                  progressReportTemplate: value,
                }));
              }}
            />
          </div>
          <div
            className={`flex border-2 p-3 border-gray-300 justify-center ml-2 items-center gap-x-2 ${
              reportsData?.showGroupsInReportCard !==
                reportData?.showGroupsInReportCard && "bg-lightyellow"
            }`}
          >
            <label className="text-gray-500 font-semibold">
              Show Groups in Report Card :
            </label>
            <Switch
              checked={reportsData?.showGroupsInReportCard}
              type="default"
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(value) => {
                setReportsData((prevState) => ({
                  ...prevState,
                  showGroupsInReportCard: value,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <div className="flex">
            <div className="flex items-center gap-x-2">
              <div className="w-12 h-10 border bg-lightyellow"></div>
              <p className="text-black">- Edited cells</p>
            </div>
            <div className="flex items-center gap-x-2 ml-6">
              <div className="w-12 h-10 border bg-disabledGrey"></div>
              <p className="text-black">- Non-editable cell</p>
            </div>
          </div>
          <div className="flex gap-x-4">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button buttonStyle="primary" onClick={handleSave}>
              Save changes
            </Button>
          </div>
        </div>
      </div>
    </Modal>
    {currentSubjectForSettings.visible ?
      <SubjectSettings
        subjectName={currentSubjectForSettings.displayName}
        subjectId={currentSubjectForSettings.id}
        onClose={() => setCurrentSubjectForSettings({
          visible: false,
        })}
        visible={currentSubjectForSettings.visible}
      />
    : null}
    </>
  );
}

export default EditSubjectsModal;