import React from "react";
import AnnouncementCard from "../../components/Announcement/AnnouncementCard";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import blueSpeaker from "../../assets/images/blueSpeaker.png";

const AnnouncementContainer = ({ loading, error, data, onEditClick }) => {
  const navigation = useNavigate();

  const handleSentViewAllClick = () => {
    navigation("/announcements/detail");
  };

  const isEmpty =
    data?.announcements?.length === 0 ||
    data?.announcements?.length === undefined;

  return (
    <div className="flex flex-col flex-1 overflow-hidden pb-4 relative">
      <p className="text-darkerGrey font-semibold text-xl text-left px-2 bg-cblue p-4 rounded-t-xl pl-4">
        Sent
      </p>

      {error && <div>Error! {error.message}</div>}
      {loading && <div>Loading...</div>}
      {isEmpty ? (
        <div className="w-full h-full bg-white flex flex-col justify-center items-center">
          <img src={blueSpeaker} alt="Blue Speaker" className="w-1/2" />
          <div className="mt-5 w-2/3">
            <h4 className="text-lg font-bold text-lightBlack text-center">
              No Announcement Sent
            </h4>
            <p className="text-purpleDark text-center font-medium text-sm">
              Please click on Add New Announcement button to send announcement
            </p>
          </div>
        </div>
      ) : (
        <div className="overflow-y-scroll h-full px-4 bg-white pb-4">
          {data?.announcements?.map((announcement, index) => (
            <AnnouncementCard
              id={index}
              key={announcement.id}
              announcement={announcement}
              onEditClick={onEditClick}
            />
          ))}
        </div>
      )}

      {!isEmpty && (
        <div className="absolute bottom-0 flex w-full justify-center">
          <Button
            buttonStyle="primary"
            onClick={() => {
              handleSentViewAllClick();
            }}
            className="w-2/3"
          >
            View All
          </Button>
        </div>
      )}
    </div>
  );
};

export default AnnouncementContainer;
