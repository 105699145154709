/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment";
import { getAuthID } from "../shared/getAuthID";
import { v4 as uuid } from "uuid";

const transformData = (data) => {
  const formattedData = data?.map(({ data: feesStructure }) => {
    const givenInstallments = feesStructure?.installments;
    const sortedDueDates = givenInstallments.sort((a, b) =>
      moment(a.dueDate).diff(moment(b.dueDate))
    );

    let nextInstallment = null;
    const today = moment();
    sortedDueDates?.forEach((currentInstallment) => {
      if (
        !nextInstallment &&
        moment(currentInstallment?.dueDate).isAfter(today)
      ) {
        nextInstallment = currentInstallment;
      }
    });

    const finalData = {
      id: uuid(),
      title: feesStructure?.title,
      acadamicYear: feesStructure?.acadamicYearTitle,
      totalFees: feesStructure?.collectionTotal,
      paidFees: feesStructure?.paidAmount,
      nextInstallment,
      transactions: feesStructure?.history,
    };
    return finalData;
  });

  const overallTotalAndPaid = formattedData.reduce(
    (currentTotal, currentStructure) => {
      currentTotal.totalFees += currentStructure.totalFees;
      currentTotal.paidFees += currentStructure.paidFees;

      if (currentStructure?.transactions?.length) {
        currentTotal.transactions = [
          ...currentTotal.transactions,
          ...currentStructure.transactions,
        ];
      }
      return currentTotal;
    },
    {
      totalFees: 0,
      paidFees: 0,
      transactions: [],
    }
  );

  const overallDetails = {
    id: uuid(),
    ...overallTotalAndPaid,
    title: "Overall",
  };

  formattedData.unshift(overallDetails);
  return formattedData;
};

const getFeesDetails = async (payload) => {
  const token = getAuthID();
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/fees/getAllStudentFees`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useFeesDetails(payload, options = {}) {
  return useQuery(
    ["feesDetails", payload?.studentId],
    () => getFeesDetails(payload),
    {
      ...options,
      select: transformData,
      onError: (error) => {
        console.log("ERROR: ", error);
      },
    }
  );
}
