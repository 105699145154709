import jwtDecode from "jwt-decode";

export const getUserRole = () => {
  let token = localStorage.getItem("token");
  if(token){
    let decoded = jwtDecode(token);
    let checkClaims = decoded.hasOwnProperty("https://hasura.io/jwt/claims");
    if (checkClaims) {
      let claims = decoded["https://hasura.io/jwt/claims"];
      return claims?.["x-hasura-allowed-roles"];
    }
  }
  return null;
};
