/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getURCAndCenters = async (payload) => {
  const config = {
    url: `${process.env.REACT_APP_CSMC_ENDPOINT}/v1/group/getGroupURCAndCenters`,
    method: "post",
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useGetGroupURCAndCenters(payload, options = {}) {
  return useQuery(
    ["groupURCAndCenters", payload?.groupId],
    () => getURCAndCenters(payload),
    {
      enabled: !!payload?.groupId,
      ...options,
    }
  );
}
