import React, { useState } from "react";
import { Pagination, Modal } from "antd";
import { Button } from "../../../components";
import { EyeIcon, PencilIcon } from "@heroicons/react/solid";
import moment from "moment";
import { DATE_FORMATS } from "../../../utils/constants";
import { PAGE_SIZE } from ".";
import AttachmentGallery from "../../../components/AttachmentGallery";

function ExpensesTable({
  totalCount,
  onEditDetails,
  expensesData,
  currentPage,
  setCurrentPage,
}) {
  const [attachmentModal, setAttachmentModal] = useState({
    isVisible: false,
    attachments: null,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showAttachmentView = (attachments) => {
    setAttachmentModal({
      isVisible: true,
      attachments,
    });
  };

  const handleEditClick = (expense) => {
    onEditDetails(expense);
  };

  const handleAttachmentModalClose = () => {
    setAttachmentModal({
      isVisible: false,
      attachments: null,
    });
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey text-left text-sm uppercase font-medium"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                    >
                      Date-Time
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                    >
                      Attachment
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(expensesData) &&
                    expensesData?.map((expense, index) => {
                      return (
                        <tr key={expense.expenseId}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center text-left">
                              <div className="ml-3">
                                <p className="text-black font-medium whitespace-no-wrap">
                                  {expense?.categoryTitle}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap font-medium">
                              {expense?.title || ``}
                            </p>
                            <p className="text-textGrey">
                              {expense?.note || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap font-medium">
                              {moment(expense?.date).format(
                                DATE_FORMATS.DMMMYYYY
                              ) || ``}
                            </p>
                            <p className="text-textGrey">
                              {moment(
                                expense?.time?.split("+")?.[0],
                                DATE_FORMATS.HHMMSS
                              ).format(DATE_FORMATS.HHMMA) || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-right text-darkRed whitespace-no-wrap font-medium">
                              {expense?.amount || ``}
                            </p>
                            <p className="text-right text-textGrey">
                              {expense?.amountType || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            {expense?.attachments?.length > 0 ? (
                              <Button
                                id="download-all-students-data"
                                buttonStyle="primary"
                                className="py-3 px-4  bg-thaliumFlame"
                                onClick={() =>
                                  showAttachmentView(expense?.attachments)
                                }
                              >
                                <EyeIcon className="h-4 w-4 mr-1" />
                                View
                              </Button>
                            ) : (
                              <p className="text-textGrey">No attachments</p>
                            )}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <Button
                              id="download-all-students-data"
                              buttonStyle="primary"
                              className="py-3 px-4"
                              onClick={() => {
                                handleEditClick(expense);
                              }}
                            >
                              <PencilIcon
                                className="h-4 w-4 mr-1"
                                aria-hidden="true"
                              />
                              Edit
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Pagination
              size="small"
              pageSize={PAGE_SIZE}
              total={totalCount}
              current={currentPage}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
            <Modal
              open={attachmentModal.isVisible}
              centered
              footer={null}
              destroyOnClose
              width="80%"
              bodyStyle={{
                padding: 50,
                height: 650,
              }}
              onCancel={handleAttachmentModalClose}
            >
              <AttachmentGallery attachments={attachmentModal.attachments} />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpensesTable;
