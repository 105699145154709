import React from "react";
import moment from "moment";
import pdfImage from "../../assets/images/pdfImage.png";
import { DATE_FORMATS } from "../../utils/constants";

const ExamIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="w-6 h-6"
  >
    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
  </svg>
);

const BellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="w-6 h-6"
  >
    <path d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z" />
    <path
      fillRule="evenodd"
      d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z"
      clipRule="evenodd"
    />
  </svg>
);

const NOTIFICATION_TYPE = {
  exam: {
    id: "exam",
    title: "Exam Schedule",
    icon: <ExamIcon />,
  },
  announcement: {
    id: "announcement",
    title: "Announcement",
    icon: <BellIcon />,
  },
};

function ReceivedAnnouncement({ notification, onAssetClick }) {
  const cardId = "notification-" + notification.id;

  return (
    <div
      id={cardId}
      className="shadow-lg rounded-xl w-full p-6 dark:bg-gray-800 my-7 bg-paleRed"
    >
      <div className="flex md:flex-row items-center justify-between">
        <div className="flex items-center justify-start w-full flex-grow -mt-4 -ml-4">
          <span className="block text-oldRose mx-2">
            {NOTIFICATION_TYPE?.[notification?.type]?.icon}
          </span>
          <div className="flex flex-col items-start">
            <span className="dark:text-white text-black text-base font-semibold">
              {notification?.title ||
                NOTIFICATION_TYPE?.[notification?.type]?.title}
            </span>
            <span className="text-black text-xs dark:text-gray-300 font-normal">
              {`${NOTIFICATION_TYPE?.[notification?.type]?.title} | ${moment(
                notification?.created_at
              ).format("DD MMM YYYY")}`}
            </span>
          </div>
        </div>
      </div>
      {notification?.type === NOTIFICATION_TYPE.exam.id && (
        <div className="text-black font-medium text-sm text-left mt-2 flex -mb-1">
          <p className="text-black font-medium text-sm text-left">
            Exam should start from&nbsp;
          </p>
          <p className="text-black font-semibold text-sm text-left">
            {moment(notification?.startDate).format(DATE_FORMATS.DDMMMYYY)}
            &nbsp;
          </p>
          <p className="text-black font-medium text-sm text-left">to&nbsp;</p>
          <p className="text-black font-semibold text-sm text-left">
            {moment(notification?.endDate).format(DATE_FORMATS.DDMMMYYY)}
          </p>
        </div>
      )}
      {notification?.type === NOTIFICATION_TYPE.exam.id ? (
        <div className="flex mt-4">
          <p className="text-black font-medium text-sm text-left">Class: </p>
          <p className="text-black font-semibold text-sm text-left">
            {notification?.messageBody?.slice(7)}
          </p>
        </div>
      ) : (
        <p className="text-black font-medium text-sm text-left mt-4">
          {notification?.messageBody}
        </p>
      )}
      <div
        className="flex items-center cursor-pointer"
        onClick={(e) => {
          onAssetClick(
            notification.attachments?.map((stringifiedAttachment) =>
              JSON.parse(stringifiedAttachment)
            )
          );
        }}
      >
        {notification?.attachments?.map?.((uploadedAsset) => {
          const uploadedObject = JSON.parse(uploadedAsset);
          const extentsion = uploadedObject?.url?.split?.(".")?.pop();

          if (extentsion === "pdf") {
            return (
              <div className="w-20 h-16 my-2">
                <img src={pdfImage} alt="PDF" className="h-12" />
              </div>
            );
          }
          return (
            <img
              src={uploadedObject.url}
              alt="Notification Additional info"
              className="w-20 h-16 m-2"
            />
          );
        })}
      </div>
    </div>
  );
}

export default ReceivedAnnouncement;
