import { useState } from "react";
import axios from "axios";

const useDownloadStudentData = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getAllStudentData`;

  const fetchData = async ({ schoolId, showPhoneNumber = true }) => {
    try {
      setLoading(true);
      setError(false);
      const config = {
        method: "post",
        url: apiUrl,
        data: { schoolId, showPhoneNumber },
        responseType: "blob",
      };
      const result = await axios(config);

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `StudentList`;
      const docFormat = "xlsx";
      link.setAttribute("download", `${fileName}.${docFormat}`);
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (error) {
      setError("Unable to download file");
      return false;
    } finally {
      setLoading(false);
    }
  };

  return [fetchData, { error, loading, refetch: fetchData }];
};

export default useDownloadStudentData;
