import React, { useEffect, useState } from "react";
import Tabs, { TabPane } from "rc-tabs";
import { Modal, Spin, notification } from "antd";
import Dropdown from "../../components/Dropdown";
import { GET_CLASSES_AND_DIVISIONS } from "../../views/studentsView/graphql";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { getSchoolID } from "../../shared/getSchoolID";
import { CCETab } from "./ExamsTab/CCETab";
import ClassSettings from "./ClassSettings";
import EditableTable from "./EditTable";
import {
  GET_CLASSES_FOR_IMPORT,
  GET_UPDATED_DISPLAY_EXAM_STRUCTURE,
  GET_EXAM_SUBJECTS,
  GET_EXAM_STRUCTURE,
  GET_ACADEMIC_YEAR,
  GET_EVALUATION_PATTERN,
} from "./graphql";
import {
  UPDATE_EXAM_SUBJECTS,
  UPDATE_EXAM_SUBJECTS_SCHEDULE,
  UPDATE_EVALUATUATION_FOR_CLASS,
  DELETE_EXAMS,
  UPDATE_CCE_EXAM,
  UPDATE_EXAM,
  UPDATE_ASSESSMENTS_ORDER,
} from "../../graphql/mutations";
import {
  ASSESMENT_SUBJECTS,
  DEFAULT_EXAM_COLS,
  EVALUATION_TYPE,
  EXAM_TERM_OPTIONS,
} from "../../utils/constants";
import { getSortedClasses, evalParamTitleCorrected } from "../../helpers";
import { Button } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CREATED_BY } from "../../utils/constants";
import useImportExam from "../../hooks/useImportExam";
import { cloneDeep, isEqual } from "lodash";
import { Tooltip } from "antd";

const isRelativeOrderUnique = (assessmentsObject, classId) => {
  const assessmentNameToIdArrayMap = new Map();

  const { assessments } = assessmentsObject;
  const assessmentsWithValidDivisions = assessments?.filter(
    (assessment) => assessment?.division_id,
  );
  assessmentsWithValidDivisions?.forEach((assessment) => {
    const assessmentKey = assessment?.title + "_" + assessment?.term;
    const assessmentMap = assessmentNameToIdArrayMap.get(assessmentKey);
    if (assessmentMap) {
      assessmentMap.push(assessment);
    } else {
      const assessmentIdsArray = [assessment];
      assessmentNameToIdArrayMap.set(assessmentKey, assessmentIdsArray);
    }
  });

  // Assessments with same name and term should have same relative_order (Groups => assessments with same name and term)
  const uniqueRelativeOrder = new Set();
  for (let [assessmentKey, assessments] of assessmentNameToIdArrayMap) {
    const relativeOrder = new Set();
    assessments?.forEach((assessment) => {
      relativeOrder.add(assessment?.relative_order);
    });
    if (relativeOrder?.size === 1) {
      const relativeOrderOfThisGroup = [...relativeOrder]?.[0];
      uniqueRelativeOrder.add(relativeOrderOfThisGroup);
    }
  }

  // Update relative_order as it is not set according to group
  const newAssessmentorder = [];
  let idx = 0;
  if (uniqueRelativeOrder.size !== assessmentNameToIdArrayMap.size) {
    for (let [assessmentKey, assessments] of assessmentNameToIdArrayMap) {
      const [title, term] = assessmentKey.split("_");
      const assessmentPayload = {
        _set: { relative_order: idx },
        where: {
          title: { _eq: title },
          term: { _eq: term },
          class_id: { _eq: classId },
        },
      };
      newAssessmentorder.push(assessmentPayload);
      idx += 1;
    }
    return { isUnique: false, payload: newAssessmentorder };
  } else {
    return { isUnique: true, payload: null };
  }
};

const mergeTableData = (tableStateStored, refetchedTableState, term) => {
  // We generate max_marks for each cell
  const oldTableStateData = {};

  const prevTableData = tableStateStored;
  prevTableData?.forEach((subject) => {
    if (subject?.editedKeys) {
      const assessments = subject?.params;
      assessments?.forEach((assessment) => {
        const { assessmentId, assessmentSubjectId } = assessment;

        // Create object for the assessment if not created
        if (!oldTableStateData?.[`${assessmentId}`]) {
          oldTableStateData[`${assessmentId}`] = {};
        }

        // Create object for the assessment subject inside assessment if not created
        if (
          !oldTableStateData?.[`${assessmentId}`]?.[`${assessmentSubjectId}`]
        ) {
          oldTableStateData[`${assessmentId}`][`${assessmentSubjectId}`] = {};
        }
        const evalParams = assessment?.eval_params;

        evalParams?.forEach((evalParam) => {
          const evalParamId = evalParam?.eval_param_id;
          const max_marks = evalParam?.max_marks;

          oldTableStateData[`${assessmentId}`][`${assessmentSubjectId}`][
            `${evalParamId}`
          ] = max_marks;
        });
      });
    }
  });

  // Repopulate table from old state
  const newtTableState = refetchedTableState?.map((assessment) => {
    if (assessment?.term !== term) {
      return assessment;
    }
    const newAssesmentData = cloneDeep(assessment);
    const assessmentId = newAssesmentData?.id;
    newAssesmentData?.assessment_subjects?.forEach((subject) => {
      const subjectId = subject?.id;
      const evalParams = subject?.assessment_subject_eval_params;
      evalParams?.forEach((evalParam) => {
        const evalParamId = evalParam?.eval_param_id;

        if (
          oldTableStateData?.[`${assessmentId}`]?.[`${subjectId}`]?.[
            `${evalParamId}`
          ]
        ) {
          evalParam.max_marks =
            oldTableStateData?.[`${assessmentId}`]?.[`${subjectId}`]?.[
              `${evalParamId}`
            ];
        }
      });
    });
    return newAssesmentData;
  });

  return newtTableState;
};

function ManageExams() {
  const [academicYear, setAcademicYear] = useState(null);
  const [classSettingsVisible, setClassSettingsVisible] = useState(false);
  const [classData, setClassData] = useState([]);
  const [importClassesData, setImportClassesData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [currentSelectedClass, setCurrentSelectedClass] = useState(null);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [selectedImportType, setSelectedImportType] = useState(null);
  const [classExamData, setClassExamData] = useState(null);
  const [importTableData, setImportTableData] = useState({
    columns: [],
    data: [],
  });
  const [evaluationType, setEvaluationType] = useState({
    value: EVALUATION_TYPE[0].value,
    label: EVALUATION_TYPE[0].label,
  });
  const [showModal, setShowModal] = useState({
    saveAndCancel: false,
    evaluationTypeConfirmation: false,
  });
  const [classIdFromLocation, setClassIdFromLocation] = useState(null);
  const [term, setTerm] = useState({
    label: "First Term",
    value: 1,
  });

  const [importTableStateData, setImportTableStateData] = useState({});

  const [importTerm, setImportTerm] = useState({
    label: "First Term",
    value: 1,
  });

  const [tableStateBeforeRefetch, setTableStateBeforeRefetch] = useState([]);

  let navigate = useNavigate();
  const { state } = useLocation();

  const [createImportedExam] = useImportExam();
  const [
    refetchEvaluation,
    { loading: evaluationTypeLoading, data: evaluationTypeData },
  ] = useLazyQuery(GET_EVALUATION_PATTERN, {
    variables: {
      _classId: currentSelectedClass?.value,
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      const returnEvaluationType = res?.divisions?.[0]?.exam_type;
      getDisplayStructure({
        variables: {
          classId: parseInt(currentSelectedClass.value),
          examType: res?.divisions?.[0]?.exam_type,
        },
      });
      handleEvaluationTypeSelect(returnEvaluationType);
      const newType = EVALUATION_TYPE.find(
        (evalType) => evalType.value === res?.divisions?.[0]?.exam_type,
      );
      setEvaluationType(newType);
    },
  });

  const currentEvaluaionTypeSavedOnServer =
    evaluationTypeData?.divisions?.[0]?.exam_type;
  const disabledButtons =
    evaluationType?.value === currentEvaluaionTypeSavedOnServer;
  const isCCE = evaluationType?.value === EVALUATION_TYPE[0]?.value;

  // These 2 effects are there for persisting state between pages
  useEffect(() => {
    if (state?.classId) {
      setClassIdFromLocation(parseInt(state?.classId));
    }
  }, []);

  useEffect(() => {
    // Should run only once, hence after using it we set it to null
    if (classIdFromLocation) {
      handleCurrentClassChange(classIdFromLocation);
      setClassIdFromLocation(null);
    }
  }, [classData]);

  const handleEvaluationTypeSelect = (currentType) => {
    const newType = EVALUATION_TYPE.find(
      (evalType) => evalType.value === currentType,
    );
    // Show Preview of the selected Type
    getDisplayStructure({
      variables: {
        classId: parseInt(currentSelectedClass.value),
        examType: newType?.value,
      },
    });
    if (currentType !== currentEvaluaionTypeSavedOnServer) {
      // Show Save and Cancel Modal
      setShowModal((prevState) => ({
        ...prevState,
        saveAndCancel: true,
      }));
    } else {
      setShowModal((prevState) => ({
        ...prevState,
        saveAndCancel: false,
      }));
    }
    setEvaluationType(newType);
  };

  const [deleteExams] = useMutation(DELETE_EXAMS, {
    onCompleted: () => {
      refetchEvaluation({
        variables: {
          _classId: currentSelectedClass?.value,
        },
      });
    },
  });

  const { loading: academicYearLoading } = useQuery(GET_ACADEMIC_YEAR, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (res) => {
      setAcademicYear(res?.schools_by_pk?.acadamic_years?.[0]?.title);
    },
  });

  const { loading: classesLoading, data: classesAndDivisonsData } = useQuery(
    GET_CLASSES_AND_DIVISIONS,
    {
      variables: {
        schoolId: getSchoolID(),
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (classResponse) => {
        const sortedClasses = getSortedClasses(classResponse.classes);
        const classLists = [];
        sortedClasses.forEach((classObj) => {
          classLists.push({
            value: classObj.id,
            label: `Class: ${classObj.class_name}`,
          });
        });
        setClassData(classLists);
      },
    },
  );

  const { loading: importClassesLoading } = useQuery(GET_CLASSES_FOR_IMPORT, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (res) => {
      const classLists = [];
      const classesWithCustomExams = res?.classes?.filter(
        (currentClass) =>
          currentClass?.assessments_aggregate?.aggregate?.count > 0,
      );
      const sortedClasses = getSortedClasses(classesWithCustomExams);
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
      });
      setImportClassesData(classLists);
    },
  });

  const [updateAssessmentOrder] = useMutation(UPDATE_ASSESSMENTS_ORDER);

  const [
    getDisplayStructure,
    {
      loading: displayExamsLoading,
      refetch: refetchClassExamData,
      data: displayStructureData,
    },
  ] = useLazyQuery(GET_UPDATED_DISPLAY_EXAM_STRUCTURE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const correctedData = evalParamTitleCorrected(data);
      // Set relative_order for assessments if no relative_order
      const classId = parseInt(currentSelectedClass?.value);
      const { isUnique, payload } = isRelativeOrderUnique(
        correctedData,
        classId,
      );
      if (!isUnique && payload) {
        updateAssessmentOrder({
          variables: {
            newAssessmentorder: payload,
          },
        });
      }
      // If sub exam was added or deleted, after refetch data, we repopulate the table again by merging old and new state
      if (
        tableStateBeforeRefetch &&
        tableStateBeforeRefetch?.shouldMergeRefetchedData
      ) {
        const newAssessmentData = mergeTableData(
          tableStateBeforeRefetch?.data,
          correctedData?.assessments,
          term?.value,
        );
        setClassExamData(newAssessmentData);
        return;
      }
      setTableStateBeforeRefetch({});
      setClassExamData(correctedData?.assessments);
    },
    skip: !evaluationTypeData?.divisions?.[0]?.exam_type,
  });

  const generateImportTableData = (data, termValue) => {
    const assessments = data?.assessments;
    const assessmentsForCurrentTerm = assessments?.filter(
      (assessment) => assessment?.term === termValue,
    );

    const subAssessmentHeader = assessmentsForCurrentTerm?.map(
      (assessment, idx) => {
        const title = assessment?.title;
        const assessmentId = idx;

        const evalParams =
          assessment?.assessment_subjects?.[0]?.assessment_subject_eval_params;

        const evalParamsArray = [];
        evalParams?.forEach((evaluation_parameter) => {
          const evalParamColumnHeader = {
            id: evaluation_parameter.id,
            title: () => (
              <div>
                <p className="font-semibold">
                  {evaluation_parameter?.eval_param?.title === "Default" ? (
                    <>(No name)</>
                  ) : (
                    evaluation_parameter?.eval_param?.title
                  )}
                </p>
              </div>
            ),
            dataIndex: `${assessmentId},${evaluation_parameter?.eval_param_id},${evaluation_parameter?.assessment_subject_id}`,
            examTitle: evaluation_parameter?.eval_param?.title,
            assessmentId,
            evalParamId: evaluation_parameter?.eval_param_id,
            editable: true,
            isSubExam: true,
            render: (text, row) => {
              let elemToShow;
              const requiredRow = row?.params?.find(
                (elem) => elem?.assessmentId === assessmentId,
              );
              requiredRow?.eval_params?.forEach((eval_param) => {
                if (
                  eval_param?.eval_param_id ===
                  evaluation_parameter.eval_param_id
                ) {
                  elemToShow = eval_param;
                }
              });

              return (
                <p className="font-semibold text-center">
                  {elemToShow?.max_marks || "-"}
                </p>
              );
            },
          };
          evalParamsArray.push(evalParamColumnHeader);
        });

        const totalColumn = {
          title: () => (
            <p className="font-semibold text-gray-500 text-center items-center">
              Total
            </p>
          ),
          dataIndex: "firstTotal",
          editable: false,
          render: (text, record) => {
            const requiredRow = record?.params?.find(
              (elem) => elem?.assessmentId === assessmentId,
            );
            const total = requiredRow?.eval_params?.reduce(
              (acc, obj) =>
                obj?.max_marks ? acc + parseInt(obj?.max_marks) : acc,
              0,
            );
            return <p className="font-semibold text-center"> {total} </p>;
          },
        };
        evalParamsArray.push(totalColumn);
        return {
          title: () => {
            return (
              <div className="flex h-full flex-1">
                <p className={`font-semibold w-full`}>{title}</p>
              </div>
            );
          },
          examTitle: title,
          editable: true,
          dataIndex: title,
          children: evalParamsArray,
        };
      },
    );

    const examColumns = [
      {
        title: "Subject",
        dataIndex: "subject",
        width: "10rem",
        editable: false,
        render: (text) => <p className="font-semibold text-gray-500">{text}</p>,
      },
      {
        title: () => (
          <p className="font-semibold text-base">
            {termValue === 1
              ? EXAM_TERM_OPTIONS[0].label
              : EXAM_TERM_OPTIONS[1].label}{" "}
            Exam
          </p>
        ),
        editable: true,
        isGroup: true,
        examTitle: `${
          termValue === 1
            ? EXAM_TERM_OPTIONS[0].label
            : EXAM_TERM_OPTIONS[1].label
        }
        Exam`,
        children: subAssessmentHeader,
      },
    ];

    const examTableData = examSubjectsData?.classes?.[0]?.subjects?.map(
      (subject, index) => {
        const subjectParams = [];
        assessmentsForCurrentTerm?.forEach((assessment, idx) => {
          const assessmentId = idx;
          const assessmentTitle = assessment?.title;

          const params = assessment?.assessment_subjects?.find(
            (subject) => subject.subject_id === subject?.id,
          );
          const evalParamsWithNullMaxMarks =
            params?.assessment_subject_eval_params?.map((evalParam) => ({
              ...evalParam,
              assessmentTitle,
            }));
          subjectParams.push({
            eval_params: evalParamsWithNullMaxMarks,
            assessmentId,
            assessmentSubjectId: params?.id,
          });
        });

        return {
          key: subject.id,
          subject: subject.title,
          params: subjectParams,
        };
      },
    );
    return {
      columns: examColumns,
      data: examTableData,
    };
  };
  const [getExamStructure, { data: examStructureData }] = useLazyQuery(
    GET_EXAM_STRUCTURE,
    {
      onCompleted: (rawData) => {
        const data = evalParamTitleCorrected(rawData);
        const tableDataForTerm1 = generateImportTableData(
          data,
          EXAM_TERM_OPTIONS[0].value,
        );
        const tableDataForTerm2 = generateImportTableData(
          data,
          EXAM_TERM_OPTIONS[1].value,
        );
        setImportTableData(tableDataForTerm1);
        setImportTableStateData({
          [EXAM_TERM_OPTIONS[0].value]: tableDataForTerm1,
          [EXAM_TERM_OPTIONS[1].value]: tableDataForTerm2,
        });
        return;
      },
      skip: !currentSelectedClass?.value,
    },
  );

  const [updateCCE] = useMutation(UPDATE_CCE_EXAM, {
    refetchQueries: [
      {
        query: GET_UPDATED_DISPLAY_EXAM_STRUCTURE,
        variables: {
          classId: parseInt(currentSelectedClass?.value),
          examType: currentEvaluaionTypeSavedOnServer,
        },
      },
    ],
  });

  const [updateEvaluationType] = useMutation(UPDATE_EVALUATUATION_FOR_CLASS, {
    refetchQueries: [
      {
        query: GET_UPDATED_DISPLAY_EXAM_STRUCTURE,
        variables: {
          classId: parseInt(currentSelectedClass?.value),
          examType: currentEvaluaionTypeSavedOnServer,
        },
      },
      {
        query: GET_EVALUATION_PATTERN,
        variables: {
          _classId: currentSelectedClass?.value,
        },
      },
    ],
  });

  const [updateExam] = useMutation(UPDATE_EXAM, {
    refetchQueries: [
      {
        query: GET_UPDATED_DISPLAY_EXAM_STRUCTURE,
        variables: {
          classId: parseInt(currentSelectedClass?.value),
          examType: currentEvaluaionTypeSavedOnServer,
        },
      },
    ],
  });

  const [updateExamSubjectSchedule] = useMutation(
    UPDATE_EXAM_SUBJECTS_SCHEDULE,
  );

  const [updateExamSubjects] = useMutation(UPDATE_EXAM_SUBJECTS, {
    refetchQueries: [
      {
        query: GET_UPDATED_DISPLAY_EXAM_STRUCTURE,
        variables: {
          classId: parseInt(currentSelectedClass?.value),
          examType: currentEvaluaionTypeSavedOnServer,
        },
      },
    ],
  });

  const getDefaultExamStructure = (subjectData) => {
    const firstTermExamKeys = [{ title: "Test 1" }, { title: "Semester 1" }];
    const secondTermExamKeys = [{ title: "Test 2" }, { title: "Semester 2" }];
    const examTableData = subjectData?.classes?.[0]?.subjects?.map(
      (subject, index) => {
        return {
          key: subject.id,
          subject: subject.title,
          "Test 1": 40,
          "Semester 1": 60,
          firstTotal: 100,
          "Test 2": 40,
          "Semester 2": 60,
          secondTotal: 100,
          firstTermExamKeys,
          secondTermExamKeys,
        };
      },
    );
    setImportTableData({
      columns: DEFAULT_EXAM_COLS,
      data: examTableData,
    });
  };

  const [
    getExamSubjects,
    { data: examSubjectsData, loading: examSubjectsLoading },
  ] = useLazyQuery(GET_EXAM_SUBJECTS);

  const handleCurrentClassChange = (value) => {
    const className = classData.find(
      (classObj) => classObj.value === parseInt(value),
    )?.label;

    setCurrentSelectedClass({
      value,
      label: className,
    });
    getExamSubjects({
      variables: {
        classId: parseInt(value),
      },
    });
    refetchEvaluation({
      variables: {
        _classId: value,
      },
    });
  };

  const handleImportSelect = (value) => {
    if (value !== "default") {
      const className = classData.find(
        (classObj) => classObj.value === parseInt(value),
      )?.label;
      setSelectedImportType({
        value,
        label: className,
      });
      getExamSubjects({
        variables: {
          classId: parseInt(currentSelectedClass?.value),
        },
      });
      getExamStructure({
        variables: {
          classId: parseInt(value),
          examType: currentEvaluaionTypeSavedOnServer,
        },
      });
    } else {
      setSelectedImportType({
        value,
        label: "Default",
      });
      getExamSubjects({
        variables: {
          classId: parseInt(currentSelectedClass?.value),
        },
      });
      getDefaultExamStructure(examSubjectsData);
    }
    setIsImportModalVisible(true);
  };

  const generateImportPayloadForTerm = (tableState, term, updatedNames) => {
    const subjects = tableState?.data;
    const assessmentsForCurrentTerm = examStructureData?.assessments?.filter(
      (assessment) => assessment?.term === term?.value,
    );

    const finalPayload = {
      examsList: [],
    };
    assessmentsForCurrentTerm?.forEach((assessment, assessmentIdx) => {
      const oldName = assessment?.title;
      const newName = updatedNames?.assessmentNames?.[oldName];

      const examListPayload = {
        term: term?.value,
        examTitle: newName || oldName,
        createdByRole: CREATED_BY.ADMIN,
        subjectList: [],
      };

      subjects?.forEach((subject) => {
        const subjectListCurrentSubjectPayload = {
          subjectId: subject?.key,
          paramsList: [],
        };

        const assessmentParmas = subject?.params?.[assessmentIdx];
        const evalParams = assessmentParmas?.eval_params;

        let totalMarks = 0;
        evalParams?.forEach((evalParam) => {
          const maxMarks = evalParam?.max_marks;
          totalMarks += maxMarks;

          const dataKey = `${assessmentIdx},${evalParam?.eval_param_id}`;
          const updatedName = updatedNames?.evalParamNames?.[dataKey];

          const evalParamsPayload = {
            paramId: evalParam?.eval_param_id,
            maxMarks,
          };
          if (updatedName) {
            evalParamsPayload["updatedParamName"] = updatedName;
          }
          subjectListCurrentSubjectPayload.paramsList.push(evalParamsPayload);
        });
        subjectListCurrentSubjectPayload.totalMarks = totalMarks;
        examListPayload.subjectList.push(subjectListCurrentSubjectPayload);
      });
      finalPayload.examsList.push(examListPayload);
    });

    return finalPayload;
  };

  const isImportAssessmentNameDuplicate = (term, examList) => {
    const firstTermExamNames = examList?.map(({ examTitle }) => examTitle);
    const assessmentsForGivenTerm = classExamData?.filter(
      (assessment) => parseInt(assessment?.term) === parseInt(term),
    );
    const assessmentNamesForGivenTerm = assessmentsForGivenTerm?.map(
      (assessment) => assessment?.title,
    );
    const uniqueNames = new Set(assessmentNamesForGivenTerm);

    const returnData = {
      hasUniqueNames: true,
      repeatedName: null,
    };
    firstTermExamNames?.forEach((newExamName) => {
      if (uniqueNames.has(newExamName)) {
        returnData.hasUniqueNames = false;
        returnData.repeatedName = newExamName;
      }
    });
    return returnData;
  };

  const handleSaveExams = async (formData, tableState, updatedNames) => {
    const evalParamsUpdated = updatedNames?.evalParamNames;
    const finalEvalParams = {};
    for (let key in evalParamsUpdated) {
      const [assessmentId, evalParam] = key.split(",");
      const newKey = `${assessmentId},${evalParam}`;
      const value = evalParamsUpdated?.[key];
      finalEvalParams[newKey] = value;
    }
    const finalListOfUpdatedName = {
      ...updatedNames,
      evalParamNames: finalEvalParams,
    };

    const divisionsList = examSubjectsData?.classes?.[0]?.divisions?.map(
      (div) => div?.id,
    );

    const firstTermPayload = generateImportPayloadForTerm(
      importTableStateData?.[EXAM_TERM_OPTIONS[0]?.value],
      EXAM_TERM_OPTIONS[0],
      finalListOfUpdatedName,
    );
    const areFirstTermUnique = isImportAssessmentNameDuplicate(
      EXAM_TERM_OPTIONS[0].value,
      firstTermPayload.examsList,
    );
    if (!areFirstTermUnique.hasUniqueNames) {
      notification["error"]({
        message: "Duplication",
        description: `${areFirstTermUnique.repeatedName} is already present.\nPlease use a different name.`,
        duration: 10,
      });
      return;
    }

    const secondTermPayload = generateImportPayloadForTerm(
      importTableStateData?.[EXAM_TERM_OPTIONS[1]?.value],
      EXAM_TERM_OPTIONS[1],
      finalListOfUpdatedName,
    );
    const areSecondTermUnique = isImportAssessmentNameDuplicate(
      EXAM_TERM_OPTIONS[1].value,
      secondTermPayload.examsList,
    );
    if (!areSecondTermUnique.hasUniqueNames) {
      notification["error"]({
        message: "Duplication",
        description: `${areSecondTermUnique.repeatedName} is already present.\nPlease use a different name.`,
        duration: 10,
      });
      return;
    }

    const examsList = [
      ...firstTermPayload?.examsList,
      ...secondTermPayload?.examsList,
    ];

    const finalPayload = {
      divisionsList,
      examsList,
    };
    await createImportedExam(finalPayload);
    getDisplayStructure({
      variables: {
        classId: parseInt(currentSelectedClass.value),
        examType: currentEvaluaionTypeSavedOnServer,
      },
    });
    setIsImportModalVisible(false);
    return;
  };

  const handleScheduleExam = () => {
    setActiveTab(2);
  };

  const isAssessmentNameDuplicate = (
    assessmentNames,
    assessmentNameToCheckForUniqueness,
  ) => {
    const uniqueAssessments = new Set(assessmentNames);
    return uniqueAssessments.has(assessmentNameToCheckForUniqueness);
  };

  // To Handle Across Division
  // Create 2 maps, assessmentId -> assessmentName and assessmentname -> [assessmentIds]. Here assessment names across division is constant
  // Subject Id is also constant, hence we use that to ind assessment_subject_id, max_marks and eval_param to change in formdata
  const handleEditExam = async ({
    formData,
    tableState,
    term,
    assessmentsToDelete,
    updatedNames,
  }) => {
    const assessmentsForGivenTerm = classExamData?.filter(
      (assessment) => assessment?.term === term?.value,
    );
    const editedSubjects = formData?.filter((subject) => subject?.isEdited);

    const assessmentNameToIdArrayMap = new Map();
    const assessmentIdToNameMap = new Map();

    assessmentsForGivenTerm?.forEach((assessment) => {
      const name = assessment?.title;
      const id = parseInt(assessment?.id);
      assessmentIdToNameMap.set(id, assessment);

      if (assessmentNameToIdArrayMap.has(name)) {
        const currentArray = assessmentNameToIdArrayMap.get(name);
        currentArray.push(id);
      } else {
        assessmentNameToIdArrayMap.set(name, [id]);
      }
    });

    // To find total of subjects we use tableState
    const assessmentSubjectTotal = {};
    const subjectsThatWereUpdated = tableState?.data?.filter(
      (subject) => subject?.editedKeys,
    );
    subjectsThatWereUpdated?.forEach((subject) => {
      subject?.params?.forEach((assessmentSubject) => {
        const assessmentSubjectId = assessmentSubject?.assessmentSubjectId;
        const total = assessmentSubject?.eval_params?.reduce?.(
          (currentTotal, evalParam) => {
            const evalParamMaxMarks = evalParam?.max_marks || 0;
            return currentTotal + evalParamMaxMarks;
          },
          0,
        );
        assessmentSubjectTotal[assessmentSubjectId] = total;
      });
    });

    const objectsPayload = [];
    const updatesPayload = [];

    if (editedSubjects?.length !== 0) {
      editedSubjects?.forEach((subject) => {
        subject?.updatedFields?.forEach((updatedField) => {
          const assessmentSubjectIdForTotalCalculation =
            updatedField?.assessment_subject_id;

          const assessmentObject = assessmentIdToNameMap.get(
            parseInt(updatedField?.assessmentId),
          );
          const assessmentsAcrossDivisions = assessmentNameToIdArrayMap.get(
            assessmentObject?.title,
          );

          assessmentsAcrossDivisions?.forEach((assessmentId) => {
            const assessment = assessmentIdToNameMap.get(
              parseInt(assessmentId),
            );

            // Need to find assessment_subject_id with same key as Subject
            const updatedSubject = assessment?.assessment_subjects?.find(
              (currentSubject) =>
                parseInt(currentSubject?.subject_id) === parseInt(subject?.id),
            );

            const eval_param =
              updatedSubject?.assessment_subject_eval_params?.find(
                (eval_param) =>
                  eval_param?.eval_param_id ===
                  parseInt(updatedField?.eval_param_id),
              );

            if (
              eval_param?.assessment_subject_id &&
              eval_param?.eval_param_id &&
              eval_param?.assessment_subject_id
            ) {
              objectsPayload.push({
                assessment_subject_id: eval_param?.assessment_subject_id,
                eval_param_id: eval_param?.eval_param_id,
                max_marks: parseInt(updatedField?.max_marks),
              });
              updatesPayload.push({
                _set: {
                  total_marks:
                    assessmentSubjectTotal?.[
                      assessmentSubjectIdForTotalCalculation
                    ],
                },
                where: { id: { _eq: eval_param?.assessment_subject_id } },
              });
            }
          });
        });
      });
    }

    const listOfAssessmentNames = [...assessmentNameToIdArrayMap.keys()];

    const updateExamTitle = [];
    for (let assessmentName in updatedNames?.assessmentNames) {
      const newAssessmentname = updatedNames.assessmentNames[assessmentName];
      const assessmentIds = assessmentNameToIdArrayMap.get(assessmentName);

      if (isAssessmentNameDuplicate(listOfAssessmentNames, newAssessmentname)) {
        notification["error"]({
          message: "Duplication",
          description: "Assessment names need to be unique",
          duration: 10,
        });
        return;
      }

      assessmentIds?.forEach((id) =>
        updateExamTitle.push({
          _set: { title: newAssessmentname },
          where: { id: { _eq: id } },
        }),
      );
    }

    const updateParamName = [];
    for (let paramKey in updatedNames?.evalParamNames) {
      const [assessmentId, evalParamId] = paramKey?.split(",");

      const assessmentName = assessmentIdToNameMap.get(
        parseInt(assessmentId, 10),
      );
      const assessmentsAcrossDivision = assessmentNameToIdArrayMap.get(
        assessmentName?.title,
      );

      const newEvalParamName = updatedNames?.evalParamNames[paramKey];

      assessmentsAcrossDivision?.forEach((assessmentId) => {
        const assessmentObject = assessmentIdToNameMap.get(assessmentId);
        assessmentObject?.assessment_subjects?.forEach((subject) => {
          const { eval_param_id, assessment_subject_id } =
            subject?.assessment_subject_eval_params?.find(
              ({ eval_param_id }) =>
                parseInt(eval_param_id, 10) === parseInt(evalParamId, 10),
            );
          if (newEvalParamName && assessment_subject_id && eval_param_id) {
            const newEvalParamPayload = {
              _set: { updated_param_name: newEvalParamName },
              where: {
                assessment_subject_id: { _eq: assessment_subject_id },
                eval_param_id: { _eq: eval_param_id },
              },
            };
            updateParamName.push(newEvalParamPayload);
          }
        });
      });
    }

    await updateCCE({
      variables: {
        objects: objectsPayload,
        updates: updatesPayload,
        updateParamName,
        updateExamTitle,
      },
    });

    const arrayOfassessmentIdsToDelete = Object.keys(assessmentsToDelete);
    const deletePayload = [];
    arrayOfassessmentIdsToDelete?.forEach((assessmentId) => {
      const assessment = assessmentIdToNameMap.get(parseInt(assessmentId));
      const assessmentsAssociatedWithName = assessmentNameToIdArrayMap.get(
        assessment?.title,
      );
      assessmentsAssociatedWithName?.forEach((id) => deletePayload.push(id));
    });
    await deleteExams({
      variables: {
        assessmentIds: deletePayload,
      },
    });
  };

  const handleEditSchedule = (formData) => {
    if (formData?.columns?.editedKeys?.length) {
      formData?.columns?.editedKeys?.forEach((examObj) => {
        // for each key check if column name is edited
        let examTitle = formData?.columns?.[examObj.title];
        // if not edited, get original exam name
        if (!examTitle) {
          examTitle = examObj.title;
        }
        const paramObject = {
          variables: {
            assessmentId: examObj.id,
            assessmentDetails: {
              title: examTitle,
              is_graded: true,
            },
          },
        };
        updateExam(paramObject)
          .then((response) => {})
          .catch((reserror) => console.error(reserror));
      });
    }
    // check for edited keys in data for each subject
    formData?.data?.forEach((subjectData) => {
      if (subjectData?.editedKeys?.length) {
        subjectData?.editedKeys?.forEach((examObj) => {
          const paramObject = {
            variables: {
              assessmentId: examObj.id,
              assessmentDetails: {
                start_at: subjectData[examObj.id],
                is_graded: true,
              },
            },
          };
          updateExam(paramObject).catch((reserror) => console.error(reserror));
          // update mutation for each assessment subject
          subjectData?.editedKeys?.forEach((examObj) => {
            // create exam for this subject for each division
            const assessmentSubjects =
              examSubjectsData?.classes?.[0]?.divisions?.map((division) => {
                // create object for each assessment subject
                return {
                  assessment_id: examObj.id,
                  subject_id: subjectData.key,
                  start_time: subjectData[examObj.id],
                  division_id: division.id,
                };
              });
            // Update exam subjects for existing exams -
            // for the edited subject, if exam is not created, then it adds it
            // this is possible if subject is added after exam is created
            updateExamSubjectSchedule({
              variables: {
                examSubjects: assessmentSubjects,
              },
            }).catch((reserror) => console.error(reserror));
          });
        });
      }
    });
  };

  const count = examSubjectsData?.classes?.[0]?.subjects?.length || "";
  const countToRender = count ? `(${count})` : "";

  const closeSaveAndCancelModal = (value) => {
    // Cancel Case we reset Dropdown to prev state
    if (!value) {
      handleEvaluationTypeSelect(currentEvaluaionTypeSavedOnServer);
    }
    setShowModal((prevState) => ({
      ...prevState,
      evaluationTypeConfirmation: value,
      saveAndCancel: false,
    }));
  };

  const closeConfirmationModal = () => {
    setShowModal((prevState) => ({
      ...prevState,
      evaluationTypeConfirmation: false,
    }));
    const evaluation = EVALUATION_TYPE?.find(
      (evalType) => evalType?.value === currentEvaluaionTypeSavedOnServer,
    );
    setEvaluationType(evaluation);
    getDisplayStructure({
      variables: {
        classId: parseInt(currentSelectedClass.value),
        examType: currentEvaluaionTypeSavedOnServer,
      },
    });
  };

  const changeEvaluationTypeForAllDivisions = async () => {
    const updatesPayload = evaluationTypeData?.divisions?.map((div) => {
      return {
        _set: { exam_type: evaluationType?.value },
        where: { id: { _eq: div.id } },
      };
    });
    updateEvaluationType({
      variables: {
        updates: updatesPayload,
      },
    });

    // If examType is CCE_MR and there are no assessments, we create the exam
    if (
      evaluationType?.value === EVALUATION_TYPE?.[0]?.value &&
      displayStructureData?.assessments?.length === 0
    ) {
      const divisions = evaluationTypeData?.divisions?.map((div) => div.id);
      let userId = JSON.parse(localStorage.getItem("claims"))?.[
        "x-hasura-user-id"
      ];

      const payload = {
        divisions,
        userId,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "post",
        url: `${process.env.REACT_APP_NODE_ENDPOINT}/exams/v1/createExamCCE`,
        data: payload,
      };

      try {
        await axios(config);
        setEvaluationType(EVALUATION_TYPE?.[0]);
        getDisplayStructure({
          variables: {
            classId: parseInt(currentSelectedClass.value),
            examType: EVALUATION_TYPE?.[0]?.value,
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
    setShowModal((prevState) => ({
      ...prevState,
      evaluationTypeConfirmation: false,
      saveAndCancel: false,
    }));
  };

  const currentlistOfDivisions = classesAndDivisonsData?.classes
    ?.find(
      (classAndDivison) =>
        parseInt(classAndDivison?.id) === parseInt(currentSelectedClass?.value),
    )
    ?.divisions?.map((divison) => divison?.id);

  const isLoading =
    academicYearLoading ||
    classesLoading ||
    importClassesLoading ||
    displayExamsLoading ||
    examSubjectsLoading ||
    evaluationTypeLoading;

  let disableImportCondition =
    !currentSelectedClass ||
    (classExamData &&
      evaluationType?.value === EVALUATION_TYPE[0].value &&
      classExamData.length > 0) ||
    examSubjectsData?.classes?.[0]?.subjects?.length === 0 ||
    currentEvaluaionTypeSavedOnServer !== evaluationType?.value;

  const handleImportTermClick = (termValue) => {
    const examTerm = EXAM_TERM_OPTIONS?.find(
      (term) => term.value === termValue,
    );
    setImportTerm(examTerm);
  };

  const updateParentState = (tableData) => {
    const newTableStateData = cloneDeep(importTableStateData);
    if (
      newTableStateData?.[importTerm?.value]?.data &&
      tableData &&
      !isEqual(newTableStateData?.[importTerm?.value]?.data, tableData?.data)
    ) {
      newTableStateData[importTerm.value].data = tableData?.data;
      setImportTableStateData(newTableStateData);
    }
  };

  const handleImportCancel = () => {
    setSelectedImportType(null);
    setIsImportModalVisible(false);
    setImportTableStateData({});
    setImportTableData({
      columns: [],
      data: [],
    });
  };

  return (
    <div className="flex flex-col items-start pl-8 mt-6 pr-8 pb-9 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row w-full items-center justify-between mb-8">
        <div className="flex gap-x-6">
          <p className="text-2xl text-left font-bold flex-col m-0">
            Manage Exams
          </p>
          <p className="text-xl text-left font-medium flex-col mt-1">
            {academicYear}
          </p>
          <Dropdown
            type="primary"
            value={evaluationType?.label}
            options={EVALUATION_TYPE}
            onItemClick={handleEvaluationTypeSelect}
            disabled={currentSelectedClass ? false : true}
          />
          <div>
            {showModal.saveAndCancel && (
              <div className="h-full">
                <div className="justify-end flex flex-1 h-full">
                  <button
                    onClick={() => closeSaveAndCancelModal(true)}
                    className="ml-3 p-2 w-20  rounded-md bg-indigo-700 border border-indigo-700 text-white text-xs leading-4 font-medium shadow-sm h-full"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => closeSaveAndCancelModal(false)}
                    className="ml-3 p-2 w-20 rounded-md bg-white border border-gray-300 text-xs leading-4 font-medium shadow-sm h-full"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={showModal.evaluationTypeConfirmation}
        centered
        closable={false}
        bodyStyle={{
          justifyContent: "center",
          overflowX: "auto",
          textAlign: "center",
        }}
        footer={null}
      >
        <h3 className="text-xl font-semibold">
          Are you sure you want to change the evaluation type?
        </h3>
        <div className="flex justify-evenly">
          <Button
            buttonStyle="primary"
            onClick={closeConfirmationModal}
            style={{
              background: "#EF4444",
              height: 30,
            }}
          >
            No
          </Button>
          <Button
            buttonStyle="primary"
            onClick={changeEvaluationTypeForAllDivisions}
            style={{
              background: "#22C55E",
              height: 30,
              marginLeft: 25,
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <div className="flex justify-between w-full flex-1">
        <div className="flex flex-col flex-1">
          {currentSelectedClass === null ? (
            <div className="flex justiy-start mb-1">
              <p className="text-cancel">Please select class.</p>
            </div>
          ) : null}
          <div className="flex flex-1 flex-row justify-between">
            <div className="flex">
              <Dropdown
                id={"manageexams-sortby"}
                value={currentSelectedClass?.label}
                defaultValue="Select Class"
                options={classData}
                onItemClick={handleCurrentClassChange}
                type="primary"
              />
              <div className="mx-4" />
              <Button
                buttonStyle={"primary"}
                disabled={currentSelectedClass === null}
                onClick={() => {
                  navigate(`/class/${currentSelectedClass.value}/subjects`, {
                    state: {
                      className: currentSelectedClass.label?.split(" ")[1],
                      from: "Exams",
                    },
                  });
                }}
              >
                Manage Subjects {countToRender}
              </Button>
              <div className="mx-4" />
              {showModal.saveAndCancel || isCCE ? null : (
                <>
                  <Button
                    buttonStyle={"primary"}
                    disabled={currentSelectedClass === null}
                    onClick={() => setClassSettingsVisible(true)}
                  >
                    Report Settings
                  </Button>
                  <ClassSettings
                    className={currentSelectedClass.label}
                    classId={currentSelectedClass.value}
                    onClose={() => setClassSettingsVisible(false)}
                    visible={classSettingsVisible}
                  />
                </>
              )}
            </div>
            <div>
              {!(activeTab === 2) ? (
                <Tooltip
                  placement="left"
                  color="#4f46e5"
                  title={
                    "Classes with CUSTOM exam type are only visible here"
                  }
                >
                  <Dropdown
                    type="primary"
                    value="Import exam structure from other class"
                    options={[...importClassesData]}
                    onItemClick={handleImportSelect}
                    disabled={disableImportCondition}
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 w-full h-full">
        <Spin spinning={isLoading}>
          <CCETab
            selectedClass={currentSelectedClass}
            classSubjectsData={
              examSubjectsData ? examSubjectsData : ASSESMENT_SUBJECTS
            }
            classExamData={classExamData}
            onScheduleExam={handleScheduleExam}
            onEditExam={handleEditExam}
            isCCE={isCCE}
            setClassExamData={setClassExamData}
            refetchClassExamData={refetchClassExamData}
            divisions={currentlistOfDivisions}
            disabledButtons={disabledButtons}
            termData={{ term, setTerm }}
            storeDataBeforeRefetch={setTableStateBeforeRefetch}
          />
        </Spin>
        {/* To be added in future releases */}
        {/* <Tabs
          activeKey={activeTab.toString()}
          onChange={(key) => setActiveTab(Number(key))}
          className="custom-tabs h-full"
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane tab="Manage Exams" key={1} className="h-full">
          <Spin spinning={isLoading}>
              <CCETab
                selectedClass={currentSelectedClass}
                classSubjectsData={
                  examSubjectsData ? examSubjectsData : ASSESMENT_SUBJECTS
                }
                classExamData={classExamData}
                onScheduleExam={handleScheduleExam}
                onEditExam={handleEditExam}
                isCCE={evaluationType?.value === EVALUATION_TYPE[0]?.value}
                setClassExamData={setClassExamData}
                refetchClassExamData={refetchClassExamData}
                divisions={currentlistOfDivisions}
                disabledButtons={
                  evaluationType?.value === currentEvaluaionTypeSavedOnServer
                }
                termData={{ term, setTerm }}
                storeDataBeforeRefetch={setTableStateBeforeRefetch}
              />
            </Spin>
          </TabPane>
          <TabPane tab="Manage Exam Schedule" key={2}>
                <ExamScheduleTab
                  selectedClass={currentSelectedClass}
                  classSubjectsData={examSubjectsData}
                  classExamData={classExamData}
                  onEditSchedule={handleEditSchedule}
                />
              </TabPane>
        </Tabs> */}
      </div>
      {isImportModalVisible ? (
        <Modal
          visible={isImportModalVisible}
          onOk={handleSaveExams}
          centered
          closable={false}
          bodyStyle={{
            justifyContent: "center",
            overflowX: "auto",
          }}
          width={"90%"}
          footer={null}
        >
          <div className="flex flex-col gap-y-3">
            <div className="flex flex-1 items-center">
              <p className="text-indigo-600 font-bold text-base">
                Imported from: {selectedImportType?.label}
              </p>
              <div className="flex px-4">
                <div className="px-2">
                  <Button
                    id="first-term-import"
                    className={
                      importTerm.value === 1 && "bg-thaliumFlame text-white"
                    }
                    onClick={() => handleImportTermClick(1)}
                  >
                    First Term Exam
                  </Button>
                </div>

                <Button
                  id="second-term-import"
                  className={
                    importTerm.value === 2 && "bg-thaliumFlame text-white"
                  }
                  onClick={() => handleImportTermClick(2)}
                >
                  Second Term Exam
                </Button>
              </div>
            </div>
            <EditableTable
              tableColumns={
                importTableStateData?.[importTerm?.value]?.columns ||
                importTableData?.columns
              }
              tableData={
                importTableStateData?.[importTerm?.value]?.data ||
                importTableData?.data
              }
              isEditable
              onCancel={handleImportCancel}
              onSave={handleSaveExams}
              isImportTable
              updateParentState={updateParentState}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default ManageExams;