/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const getLearnAccessSettings = async () => {
  const schoolId = getSchoolID();
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/learn/v1/get/school/learnAccess`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        schoolId,
      },
    };
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (e) {
    return null;
  }
};

export default function useLearnAccess(options) {
  return useQuery(["learnAccess"], () => getLearnAccessSettings(), {
    ...options,
  });
}
