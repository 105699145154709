import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import avatarLogo from "../../../../assets/images/avatar.png";
import checkIcon from "../../../../assets/icons/check.png";
import {
  GRADE_HEADERS,
  GRADE_DATA,
  MARKS_HEADERS,
  MARKS_DATA,
  REPORT_DATA,
  EXAM_HEADERS,
  MARKS_EXAM_HEADERS,
  ATTENDANCE_HEADERS,
  ATTENDANCE_DATA,
  OVERALL_EXAM_HEADERS,
  OVERALL_MARKS_EXAM_HEADERS,
  getStudentPersonalData,
  getMarksExamData,
  getGradesExamData,
  getAttendanceTableItems,
  getOverallMarksExamData,
  getOverallGradesExamData,
} from "../../../../utils/constants";
import { getS3Image } from "../../../../shared/s3";
import { convertImageUrlToBase64Image } from "../../../../utils";
import { GET_STUDENT_MARKS, GET_STUDENT_ATTENDANCE } from "../../graphql";

function ExamMarksTable({ title, isGrade, examHeaders, examData }) {
  const overallPercentage = Number(
    (parseInt(examData[examData?.length - 1]?.items?.[1]) /
      parseInt(examData[examData.length - 1]?.items?.[2])) *
      100
  ).toFixed(2);
  const overallResultHeaders = ["Overall %", `${overallPercentage}%`];
  return (
    <div className="px-7">
      <div className="border-t-2 border-l-2 border-r-2 border-gray-200 py-2 bg-lightblue">
        <p className="font-bold text-black text-2xl">{title}</p>
      </div>
      <table className="border-2">
        <thead>
          {examHeaders?.map((headText, index) => {
            // set 66 width of first column
            let width = "w-72";
            if (index === 0) {
              width = "w-16";
            }
            return (
              <th
                scope="col"
                className={`${width} px-0 py-3 border-2 border-gray-200 text-gray-800  text-center text-sm font-bold`}
              >
                {headText}
              </th>
            );
          })}
        </thead>
        <tbody>
          {examData?.map((data, index) => {
            let span = "";
            // span 1st column to two columns of last row
            if (!isGrade && index === examData.length - 1) {
              span = "2";
            }
            return (
              <tr key={index}>
                {data?.items?.map((item, index) => {
                  return (
                    <td
                      key={index}
                      colSpan={!isGrade && index === 0 ? span : ""}
                      className="px-5 py-2 border-2 border-gray-200 bg-white text-black text-sm text-center font-bold"
                    >
                      {item}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!isGrade && (
        <div className="flex items-end justify-end mt-2">
          <table className="border-2">
            <thead>
              {overallResultHeaders?.map((headText, index) => {
                // set 66 width of first column
                let width = "w-full";
                if (index === 0) {
                  width = "w-52";
                }
                return (
                  <th
                    scope="col"
                    className={`${width} px-16 py-1 border-2 bg-lightblue border-gray-200 text-black whitespace-nowrap  text-center text-lg font-bold`}
                  >
                    {headText}
                  </th>
                );
              })}
            </thead>
          </table>
        </div>
      )}
    </div>
  );
}

function ReportCardView({ schoolDetails, studentDetails }) {
  const [schoolData, setSchoolData] = useState(null);
  const [studentImageUrl, setStudentImageUrl] = useState(null);
  const [firstTermResults, setFirstTermResults] = useState(null);
  const [secondTermResults, setSecondTermResults] = useState(null);
  const [attendanceHeaders, setAttendanceHeaders] =
    useState(ATTENDANCE_HEADERS);
  const [attendanceTableData, setAttendanceTableData] =
    useState(ATTENDANCE_DATA);
  const studentTableItems = studentDetails
    ? getStudentPersonalData(studentDetails)
    : REPORT_DATA;
  const isGrade = true;
  const marksHeaders = isGrade ? GRADE_HEADERS : MARKS_HEADERS;
  const marksLegendData = isGrade ? GRADE_DATA : MARKS_DATA;
  const examHeaders = isGrade ? EXAM_HEADERS : MARKS_EXAM_HEADERS;
  const firstTermExamItems = isGrade
    ? getGradesExamData(firstTermResults)
    : getMarksExamData(firstTermResults);
  const secondTermExamItems = isGrade
    ? getGradesExamData(secondTermResults)
    : getMarksExamData(secondTermResults);
  const overallExamHeaders = isGrade
    ? OVERALL_EXAM_HEADERS
    : OVERALL_MARKS_EXAM_HEADERS;
  const overallExamData = isGrade
    ? getOverallGradesExamData(
        getMarksExamData(firstTermResults),
        getMarksExamData(secondTermResults)
      )
    : getOverallMarksExamData(firstTermExamItems, secondTermExamItems);

  const [getStudentMarks, { loading: marksLoading }] = useLazyQuery(
    GET_STUDENT_MARKS,
    {
      onCompleted: (data) => {
        const firstExamResults = {};
        const secondExamResults = {};
        const firstTermExams =
          data?.assessments?.filter((exam) => exam.term === 1) || [];
        firstTermExams.forEach((exam) => {
          exam.assessment_subjects.forEach((assessmentSubject) => {
            if (!firstExamResults[assessmentSubject?.subject?.title]) {
              firstExamResults[assessmentSubject?.subject?.title] = {
                obtainedMarks: assessmentSubject?.assessment_results?.[0]?.mark,
                maximumMarks: assessmentSubject?.total_marks || 0,
              };
            } else {
              if (assessmentSubject?.assessment_results?.[0]?.mark) {
                firstExamResults[assessmentSubject?.subject?.title] = {
                  obtainedMarks:
                    firstExamResults[assessmentSubject?.subject?.title]
                      ?.obtainedMarks +
                    assessmentSubject?.assessment_results?.[0]?.mark,
                  maximumMarks:
                    firstExamResults[assessmentSubject?.subject?.title]
                      ?.maximumMarks + assessmentSubject?.total_marks || 0,
                };
              }
            }
          });
        });
        const secondTermExams =
          data?.assessments?.filter((exam) => exam.term === 2) || [];
        secondTermExams.forEach((exam) => {
          exam.assessment_subjects.forEach((assessmentSubject) => {
            if (!secondExamResults[assessmentSubject?.subject?.title]) {
              secondExamResults[assessmentSubject?.subject?.title] = {
                obtainedMarks:
                  assessmentSubject?.assessment_results?.[0]?.mark || 0,
                maximumMarks: assessmentSubject?.total_marks || 0,
              };
            } else {
              if (assessmentSubject?.assessment_results?.[0]?.mark) {
                secondExamResults[assessmentSubject?.subject?.title] = {
                  obtainedMarks:
                    secondExamResults[assessmentSubject?.subject?.title]
                      ?.obtainedMarks +
                      assessmentSubject?.assessment_results?.[0]?.mark || 0,
                  maximumMarks:
                    secondExamResults[assessmentSubject?.subject?.title]
                      ?.maximumMarks + assessmentSubject?.total_marks || 0,
                };
              }
            }
          });
        });
        setFirstTermResults(firstExamResults);
        setSecondTermResults(secondExamResults);
      },
    }
  );

  const [getStudentAttendance, { loading: attendanceLoading }] = useLazyQuery(
    GET_STUDENT_ATTENDANCE,
    {
      onCompleted: (data) => {
        const { attendanceHeaders: attendanceMonths, attendanceData } =
          getAttendanceTableItems(
            moment(schoolDetails?.academicYearFromDate, "YYYY-MM-DD"),
            moment(schoolDetails?.academicYearToDate, "YYYY-MM-DD"),
            data?.month_wise_attendance || []
          );
        setAttendanceHeaders(attendanceMonths);
        setAttendanceTableData(attendanceData);
      },
    }
  );

  useEffect(() => {
    if (schoolDetails) {
      // get image base64 from local storage
      const localBase64 = localStorage.getItem(schoolDetails.image);
      // set imageUrl in state as localBase64 if exists else get and set imageUrl from s3
      if (localBase64) {
        setSchoolData({ ...schoolDetails, imageUrl: localBase64 });
      } else {
        const imageUrl = getS3Image(schoolDetails.image);
        setSchoolData({ ...schoolDetails, imageUrl });
      }
    }
  }, [schoolDetails]);

  useEffect(() => {
    if (studentDetails) {
      // get image base64 from local storage
      const localBase64 = localStorage.getItem(studentDetails?.image);
      // set imageUrl in state as localBase64 if exists else get and set imageUrl from s3
      if (localBase64) {
        setStudentImageUrl({ imageUrl: localBase64 });
      } else {
        convertImageUrlToBase64Image(studentDetails?.image, (base64) => {
          setStudentImageUrl({ imageUrl: base64 });
        });
      }
    }
  }, [studentDetails]);

  useEffect(() => {
    if (studentDetails) {
      getStudentAttendance({
        variables: {
          studentId: studentDetails?.id,
        },
      });
      getStudentMarks({
        variables: {
          studentId: studentDetails?.id,
          divisionId: studentDetails?.divisionId,
        },
      });
    }
  }, [studentDetails?.id]);

  return (
    <div id="progressreport" className="pb-10">
      <div
        id="report-card-page-1"
        className="flex gap-x-4 bg-white w-full h-full rounded-lg py-4 px-4"
      >
        <div className="flex flex-col items-center gap-y-2 border-2 border-primary w-1/2 rounded-md pt-5 px-3 pb-5">
          <div class="sample-watermark">Sample</div>

          <p className="font-bold text-black">
            Please scan below QR code to install
          </p>
          <p className="text-primary font-bold">GroundUp Student App.</p>
          <div className="flex items-center justify-center mt-2 mb-2">
            <QRCode
              value="https://play.google.com/store/apps/details?id=com.astraeducation.prod.parentApp"
              size={150}
            />
          </div>
          <p className="font-bold text-lightergrey">
            You can see all the academic progress of your child in the App!
          </p>
          <div className="mt-11">
            <table>
              <thead id="marks-headers">
                {marksHeaders?.map((headText) => {
                  return (
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 border-2 border-gray-200 text-gray-800  text-center text-sm font-bold"
                    >
                      {headText}
                    </th>
                  );
                })}
              </thead>
              <tbody>
                {marksLegendData?.map((data, index) => {
                  return (
                    <tr id="marks-rows" key={index}>
                      {data?.items?.map((item, index) => {
                        return (
                          <td
                            key={index}
                            className="px-5 py-2 border-2 border-gray-200 bg-white text-black text-sm text-center font-bold"
                          >
                            {item}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="border-b-2 border-dashed border-primary w-96 mt-16 mb-0"></div>
        </div>
        <div className="flex flex-col border-2 relative items-center border-primary w-1/2 rounded-md pt-3">
          <div class="sample-watermark" style={{ top: '30%' }}>Sample</div>

          <div class="flex justify-center gap-x-80 mb-2">
            <p></p>
            <p className="font-bold text-lightergrey text-xs">
              UDISE Number: {schoolData?.udiseNumber || ""}
            </p>
          </div>
          <div className="flex justify-center items-center">
            {schoolDetails?.image && (
              <img
                id="school-image"
                alt="Logo"
                src={schoolData?.imageUrl}
                className="h-24 w-24 absolute top-4 left-5 rounded-full"
              />
            )}
            {!schoolDetails?.image && (
              <div
                id="school-logo"
                className="h-24 w-24 absolute top-4 left-5 bg-gray-200 rounded-full"
              ></div>
            )}
            <div className="flex flex-col justify-center items-center">
              <h4 className="font-bold text-lightergrey">
                {schoolData ? schoolData?.trustName || "" : "Trust Name"}
              </h4>
              <h3 id="school-name" className="font-bold text-xl text-primary">
                {schoolData ? schoolData?.schoolName || "" : "School Name"}
              </h3>
              <h4 className="text-black font-bold">
                {schoolData?.cityName || "City"},{" "}
                {schoolData?.stateName || "State"}
              </h4>
            </div>
          </div>
          <div className="flex justify-between w-full mr-3">
            <p className="font-bold text-lightergrey text-xs ml-4">
              Email: {schoolData?.email}
            </p>
            <p className="font-bold text-lightergrey text-xs">
              Mobile Number: {schoolData?.mobileNumber}
            </p>
          </div>
          <div
            id="title-container"
            className="grid grid-flow-col gap-x-3 pl-2 pr-2 mt-3 text-center justify-center items-center"
          >
            <div
              id="title-border-left"
              className="border-b-4 w-32 border-indigo-600 m-auto"
            ></div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div id="docTitle" className="text-2xl w-auto mb-auto">
              <p
                style={{
                  fontFamily: "Kurale",
                }}
              >
                Student Report Card
              </p>
            </div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div
              id="title-border-right"
              className="border-b-4 w-32 border-indigo-600 m-auto"
            ></div>
          </div>
          <p
            id="academicyear-title"
            className="font-bold text-lightergrey mt-3"
          >
            Academic Year{" "}
            <strong className="text-black text-sm">
              {schoolData ? schoolData?.academicYear : "2018-2019"}
            </strong>
          </p>
          <div
            id="personal-info-tag"
            className="bg-primary text-white mt-3 px-3 rounded-lg flex justify-center items-center"
          >
            <p className="">Personal Information</p>
          </div>
          {studentDetails && (
            <div
              style={{
                backgroundImage: `url("${studentImageUrl?.imageUrl}")`,
                backgroundSize: "cover",
              }}
              className="h-24 w-24 mt-4 rounded-2xl"
            ></div>
          )}
          {!studentDetails && (
            <img
              src={avatarLogo}
              alt="avatar-placeholder"
              className="h-24 w-24 mt-4"
              style={{ zIndex: 1 }}
            />
          )}

          <div className="mt-4 px-4 w-full">
            <table className="w-full border-2 border-gray-200">
              <tbody>
                {studentTableItems?.map((data, index) => {
                  return (
                    <tr id="personal-info-row" key={index}>
                      {data?.items?.map((item, index) => {
                        return (
                          <td
                            key={index}
                            className="px-5 py-1.5 w-full border-b-2 bg-white text-black text-sm text-left font-bold"
                          >
                            {item}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-4 px-4 w-full mb-7">
            <table className="w-full border-2 border-gray-200">
              <thead>
                <th
                  scope="col"
                  className={`bg-lightblue w-20 px-0 py-2 border-2 border-gray-200 text-gray-800  text-center text-xs font-bold `}
                >
                  Details
                </th>
                <th
                  scope="col"
                  className={`bg-lightblue w-20 px-0 py-2 border-2 border-gray-200 text-gray-800  text-center text-xs font-bold `}
                >
                  First Term
                </th>
                <th
                  scope="col"
                  className={`bg-lightblue w-20 px-0 py-2 border-2 border-gray-200 text-gray-800  text-center text-xs font-bold `}
                >
                  Second Term
                </th>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`text-lightergrey text-xs py-2 border-2 border-gray-200 bg-white text-black text-center font-bold`}
                  >
                    Weight
                  </td>
                  <td
                    className={`text-black text-xs py-2 border-2 border-gray-200 bg-white text-black text-center font-bold`}
                  >
                    35 kg
                  </td>
                  <td
                    className={`text-black text-xs py-2 border-2 border-gray-200 bg-white text-black text-center font-bold`}
                  >
                    37 kg
                  </td>
                </tr>
                <tr>
                  <td
                    className={`text-lightergrey text-xs py-2 border-2 border-gray-200 bg-white text-black text-center font-bold`}
                  >
                    Height
                  </td>
                  <td
                    className={`text-black text-xs py-2 border-2 border-gray-200 bg-white text-black text-center font-bold`}
                  >
                    5.4
                  </td>
                  <td
                    className={`text-black text-xs py-2 border-2 border-gray-200 bg-white text-black text-center font-bold`}
                  >
                    5.5
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        id="report-card-page-2"
        className="flex gap-x-4 bg-white w-full h-full rounded-lg py-4 px-4 mt-3"
      >
        <div className="flex flex-col gap-y-2 border-2 border-primary w-1/2 rounded-md pt-5 pb-5">
          <div class="sample-watermark" style={{ top: 'unset', bottom: '25%' }}>Sample</div>
          <div className="flex flex-col w-full justify-center items-center border-b-2 border-primary">            
            <h4 className="font-bold text-lightergrey">
              {schoolData ? schoolData?.trustName || "" : "Trust Name"}
            </h4>
            <h3 id="school-name" className="font-bold text-2xl text-primary">
              {schoolData ? schoolData?.schoolName || "" : "School Name"}
            </h3>
          </div>
          <div className="flex text-left items-start justify-start px-7 py-1">
            <p className="font-bold text-lightergrey">
              Student Name : {studentDetails?.fullName}
            </p>
          </div>
          <ExamMarksTable
            isGrade={isGrade}
            title="First Term Exam"
            examHeaders={examHeaders}
            examData={firstTermExamItems}
          />
          <div className="px-7 mt-9">
            <table className="border-2">
              <thead>
                {attendanceHeaders?.map((headText, index) => {
                  // set bg color if index is not first or last
                  let bgColor = "";
                  if (index === 0) {
                    bgColor = "bg-white";
                  } else if (index === attendanceHeaders.length - 1) {
                    bgColor = "bg-white";
                  } else {
                    bgColor = "bg-lightblue";
                  }
                  return (
                    <th
                      scope="col"
                      className={`${bgColor} w-20 px-0 py-2 border-2 border-gray-200 text-gray-800  text-center text-xs font-bold `}
                    >
                      {headText}
                    </th>
                  );
                })}
              </thead>
              <tbody>
                {attendanceTableData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      {data?.items?.map((item, index) => {
                        //   set color and size for first item
                        let color = "";
                        let size = "";
                        let paddingX = "px-0";
                        if (index === 0) {
                          color = "text-lightergrey";
                          size = "text-xs";
                        } else {
                          color = "text-black";
                          size = "text-xs";
                        }
                        // set px of last item
                        if (index === data.items.length - 1) {
                          paddingX = "px-5";
                        }
                        return (
                          <td
                            key={index}
                            className={`${color} ${size} ${paddingX} py-1 border-2 border-gray-200 bg-white text-black text-center font-bold`}
                          >
                            {item}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {!isGrade ? (
            <div className="flex pl-6 pr-7 pt-6 gap-x-2 mb-10">
              <p className="font-bold text-lightergrey text-lg">Remark:</p>
              <div className="w-full border border-gray-200">
                <div className="border-b h-10"></div>
                <div className="border-b h-10"></div>
                <div className="h-10"></div>
              </div>
            </div>
          ) : (
            <div className="h-64"></div>
          )}
          <div className="flex items-start justify-start px-7 mt-auto">
            <p className="text-black font-bold text-base">
              School will reopen on:
            </p>
          </div>
        </div>
        <div className="flex flex-col border-2 relative items-center border-primary w-1/2 rounded-md pt-3">
          <div class="sample-watermark" style={{ top: '30%' }}>Sample</div>
          <div className="flex flex-col w-full justify-center items-center border-b-2 border-primary pt-9">
            <h3 className="font-bold text-2xl text-primary">
              Report Card Year :{" "}
              {schoolData ? schoolData?.academicYear : "2018-2019"}
            </h3>
          </div>
          <div className="flex justify-between px-7 py-3 w-full">
            <p className="font-bold text-lightergrey">
              Register Number : {studentDetails?.grNo}
            </p>
            <p className="font-bold text-lightergrey mr-12">
              Class : {studentDetails?.className}
            </p>
          </div>
          <ExamMarksTable
            isGrade={isGrade}
            title="Second Term Exam"
            examHeaders={examHeaders}
            examData={secondTermExamItems}
          />
          <div className="px-7 mt-11">
            <table className="border-2">
              <thead>
                {overallExamHeaders?.map((headText) => {
                  return (
                    <th
                      scope="col"
                      className="w-72 px-5 py-2 border-2 border-gray-200 text-gray-800 text-center text-xs font-bold"
                    >
                      {headText}
                    </th>
                  );
                })}
              </thead>
              <tbody>
                {overallExamData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      {data?.items?.map((item, index) => {
                        //   set color and size for first item
                        let color = "";
                        let size = "";
                        if (index === 0) {
                          color = "text-lightergrey";
                          size = "text-xs";
                        } else {
                          color = "text-black";
                          size = "text-xs";
                        }
                        return (
                          <td
                            key={index}
                            className={`px-5 py-2 border-2 border-gray-200 bg-white ${color} ${size} text-center font-bold`}
                          >
                            {item}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex items-center gap-x-4 w-full mt-5">
              <img src={checkIcon} alt="green-check" className="h-6 w-6" />
              <p className="font-bold text-black text-base">
                Promoted to next class
              </p>
            </div>
          </div>
          <div className="flex justify-between px-10 mt-auto pb-5 text-base w-full">
            <p className="text-black font-bold">Class Teacher's Signature</p>
            <p className="text-black font-bold">Parent's Signature</p>
            <p className="text-black font-bold">Principal's Signature</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportCardView;