import React, { useState } from "react";
import { Pagination, Spin, Drawer } from "antd";
import { Button } from "../../../components";
import useFeeBalances from "../../../hooks/useFeeBalances2";
import StudentDetails from "./StudentDetails";
import CloseIcon from "../../../components/closeIcon";

function FeesBalancesTable({
  pageLimit,
  handleRemindParent,
  filterData = {},
  onViewCollection,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { data: balanceData, isLoading: isLoadingBalance } = useFeeBalances(
    {
      offset: pageLimit * (currentPage - 1),
      limit: pageLimit,
      ...filterData,
    },
    {
      refetchOnWindowFocus: false,
      enabled: filterData?.divisionIds?.length > 0,
    },
  );

  const totalCount = balanceData?.[0]?.total_row_count || 0;

  React.useEffect(() => {
    setCurrentPage(1);
  }, [filterData?.studentName]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Spin spinning={isLoadingBalance}>
        <div className="container">
          <div>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                      >
                        Roll No.
                      </th>
                      <th
                        scope="col"
                        className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-20 text-left text-sm uppercase text-textGrey font-medium"
                      >
                        Student Name
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                      >
                        Fees Paid
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                      >
                        Remaining Fees
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                      >
                        Total Fees
                      </th>
                      {/*<th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                    >
                      Payment Details
                    </th>*/}
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(balanceData) &&
                      balanceData?.map((feesData, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                              <p className="text-darkerGrey whitespace-no-wrap font-medium">
                                {feesData?.roll_no || ``}
                              </p>
                            </td>
                            <td
                              onClick={() => {
                                setCurrentStudent(feesData?.id);
                                setDrawerOpen(true);
                              }}
                              className="cursor-pointer px-5 py-5 border-b border-gray-200 bg-white text-sm text-left"
                            >
                              <p className="text-darkerGrey whitespace-no-wrap font-medium">
                                {feesData?.student_full_name || ``}
                              </p>
                              <p className="text-textGrey">
                                {feesData?.division_name || ``}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                              <p className="text-success whitespace-no-wrap">
                                {feesData?.amount_paid
                                  ? feesData?.amount_paid?.toLocaleString()
                                  : "0.00"}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                              <p className="text-darkRed whitespace-no-wrap">
                                {feesData?.remaining_amount
                                  ? feesData?.remaining_amount?.toLocaleString()
                                  : "0.00"}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                              <p className="text-black whitespace-no-wrap">
                                {feesData?.total_amount
                                  ? feesData?.total_amount?.toLocaleString()
                                  : "0.00"}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                              {feesData?.remaining_amount ? (
                                <Button
                                  buttonStyle="primary"
                                  onClick={() => handleRemindParent(feesData)}
                                  style={{ display: "inline" }}
                                >
                                  <span className="flex justify-center items-center gap-x-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                    Remind Parent
                                  </span>
                                </Button>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination
                size="small"
                pageSize={pageLimit || 10}
                total={totalCount}
                current={currentPage}
                showSizeChanger={false}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </Spin>

      <Drawer
        title={
          <div className="flex flex-row mr-0">
            <div className="flex-1 w-2/5 text-lg leading-7 font-medium mt-2 flex-nowrap whitespace-nowrap">
              Student Details
            </div>
          </div>
        }
        placement="right"
        onClose={() => {
          setDrawerOpen(false);
          setCurrentStudent(null);
        }}
        visible={drawerOpen}
        destroyOnClose
        contentWrapperStyle={{
          width: "36%",
        }}
        headerStyle={{
          height: "76px",
        }}
        bodyStyle={{
          padding: 0,
        }}
        closeIcon={<CloseIcon />}
      >
        {currentStudent ? <StudentDetails studentId={currentStudent} /> : null}
      </Drawer>
    </>
  );
}

export default FeesBalancesTable;