import {
  useMutation,
  useQuery,
  useLazyQuery,
  NetworkStatus,
} from "@apollo/client";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import CustomFieldArrSelect from "../../components/FormSelect";
import CustomInput from "../../components/FormInput";
import CustomRadioInput from "./CustomRadioInput";
import CustomButton from "../../components/Button";
// import Dropdown from "../../components/Dropdown";
import { InfoCircleFilled } from "@ant-design/icons";
import { Button, message, notification, Select, Spin, Upload } from "antd";
import {
  GET_CLASSES_AND_DIVISIONS,
  GET_STUDENTS_BYPK,
  GET_STUDENTS_LIST_WITH_FILTER_PAGINATED,
  UPDATE_STUDENTS_ONE,
  INSERT_PARENT_STUDENT_RELATIONSHIP_UPDATE_NAME,
  UPDATE_STUDENT_IMAGE,
  ADD_STUDENT_ADDRESS,
  UPDATE_STUDENT_ADDRESS_RELATIONSHIP,
} from "./graphql";
import { GET_DEFAULT_CITY } from "../settingsView/graphql";
import { CHECK_ROLLNO, GET_LAST_ROLLNO } from "../../graphql/queries";
import {
  DATE_FORMATS,
  DEFAULT_STUDENT_CITY,
  schoolMediumList,
} from "../../utils/constants";
import _ from "lodash";
import CustomAntdDatePicker from "./CustomDatePicker";
import axios from "axios";
import { getSchoolID } from "../../shared/getSchoolID";
import { v4 as uuid } from "uuid";
import { getS3Image, getS3Bucket } from "../../shared/s3";
import { getSortedClasses, getSortedClassesAndDivisions } from "../../helpers";
import useRemoveParent from "../../hooks/useRemoveParent";
import useDownloadAdmissionForm from "../../hooks/useDownloadAdmissionForm";
import useChangeStudentDivision from "../../hooks/useChangeStudentDivision";
import useFilterOptions from "../../hooks/useFilterOptions";
const imagesS3Bucket = getS3Bucket();
const parentRelationship = ["Mother", "Father", "Guardian"];
const { Option } = Select;
const FormCard = ({ children, final }) => {
  return (
    <>
      <div className="w-3/4 border-2 bg-white rounded-md mb-6 pt-6">
        {children}
      </div>
    </>
  );
};
const FormCol = ({ children, overrideClassnames }) => {
  return (
    <div className={`${overrideClassnames} flex flex-col flex-auto`}>
      {children}
    </div>
  );
};
const FormRow = ({ children, overrideClassnames }) => {
  return (
    <div
      className={`${overrideClassnames} flex flex-row justify-start gap-x-8 mb-4 px-6`}
    >
      {children}
    </div>
  );
};
const FormSideSection = ({ title, description, children }) => {
  return (
    <div className="w-1/4 text-left ">
      <p className="text-lg leading-6 font-medium text-gray-900  ">{title}</p>
      <p className="mt-1 text-sm leading-5 font-normal text-gray-500 w-8/12  ">
        {description}
      </p>
      {children}
    </div>
  );
};

const EditStudent = ({ onSuccess, currentStudent }) => {
  const [user, setuser] = useState(null);
  const [classData, setclassdata] = useState([]);
  const [divisionData, setdivisiondata] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedAdmittedDivision, setSelectedAdmittedDivision] =
    useState(null);
  const [currentDivision, setCurrentDivision] = useState(null);
  const [divisionList, setDivisionList] = useState([]);
  const [division, setDivision] = useState(null);
  const [admittedClassDiv, setAdmittedClassDiv] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasImage, setHasImage] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [contactDetails, setContactDetails] = useState([]);
  const [defaultRollno, setdefaultRollno] = useState(null);
  const [currentRollNumber, setCurrentRollNumber] = useState(defaultRollno);
  const [studentId, setStudentId] = useState(null);
  const [showLastAssigned, setShowLastAssigned] = useState(false);
  const [showClashedRollNo, setShowClashedRollNo] = useState(false);
  const [clashedRollNo, setClashedRollNo] = useState(null);
  const [lastRollNo, setlastRollNo] = useState(null);
  const [admissionFormMedium, setAdmissionFormMedium] = useState("");
  const [isResetForm, setIsResetForm] = useState(false);

  const [showParentRemoveModal, setParentRemoveModal] = useState(false);
  const [currentParentData, setCurrentParentData] = useState({});

  const { mutate: changeStudentDivision } = useChangeStudentDivision();

  const { refetch: removeParent, loading: isRemovingParent } = useRemoveParent(
    {},
  );
  const { data: filterOptions } = useFilterOptions(
    {
      schoolId: getSchoolID(),
      entity: "editStudent",
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    trigger,
    setError,
    setValue,
    getValues,
    watch,
    resetField,
  } = useForm({
    criteriaMode: "all",
    defaultValues: user,
  });
  const classValue = watch("classValue");
  const divValue = watch("divValue");
  const admittedClassDivValue = watch("admittedClassDivValue");
  const isTransfer = watch("isTransfer");

  const [lastRollNoData] = useLazyQuery(GET_LAST_ROLLNO, {
    variables: {
      _div_id: selectedDivision,
    },
  });

  const [
    checkRollNo,
    { loading: rollLoading, error: rollError, data: rollData },
  ] = useLazyQuery(CHECK_ROLLNO);

  const { isFetching: admissionFormDownloading } = useDownloadAdmissionForm(
    {
      medium: admissionFormMedium,
      studentDetails: {
        ...getValues(),
        contactDetails,
      },
      schoolId: getSchoolID(),
    },
    {
      onSettled: () => {
        setAdmissionFormMedium("");
      },
    },
  );

  useEffect(() => {
    if (currentDivision === parseInt(selectedDivision)) {
      lastRollNoData().then((res) => {
        setlastRollNo(res?.data?.division_students?.[0]?.student?.roll_no);
      });
      defaultRollno
        ? setCurrentRollNumber(defaultRollno)
        : setCurrentRollNumber(lastRollNo + 1);
      defaultRollno ? setShowLastAssigned(false) : setShowLastAssigned(true);
    } else if (
      selectedDivision !== null &&
      currentDivision !== parseInt(selectedDivision)
    ) {
      lastRollNoData()
        .then((res) => {
          setlastRollNo(res?.data?.division_students?.[0]?.student?.roll_no);
          if (res.data?.division_students.length > 0) {
            setCurrentRollNumber(
              res.data?.division_students[0].student.roll_no + 1,
            );
            setShowLastAssigned(true);
          }
          if (res.data?.division_students.length === 0) setCurrentRollNumber(1);
        })
        .catch((err) => console.error(err));
    }
  }, [selectedDivision, lastRollNo]);

  useEffect(() => {
    // current roll number should not be null or empty string, it should be a number.
    if (
      currentRollNumber &&
      currentRollNumber !== "" &&
      currentRollNumber != null &&
      !isNaN(parseInt(currentRollNumber)) &&
      defaultRollno === parseInt(currentRollNumber) &&
      user.divisionId === selectedDivision
    ) {
      setShowClashedRollNo(false);
    }
    // division and class should be not null as they are query parameters
    else if (
      currentRollNumber !== null &&
      currentRollNumber !== "" &&
      selectedDivision !== null &&
      selectedClass !== null &&
      currentRollNumber &&
      !isNaN(parseInt(currentRollNumber)) &&
      (defaultRollno !== currentRollNumber ||
        (defaultRollno === currentRollNumber &&
          user.divisionId !== selectedDivision))
    ) {
      checkRollNo({
        variables: {
          _div_id: selectedDivision,
          _roll_no: parseInt(currentRollNumber),
        },
      })
        .then((res) => {
          if (
            res.data?.division_students[0]?.student.id !== studentId &&
            res.data?.division_students.length > 0
          ) {
            setClashedRollNo(res.data?.division_students[0].student.full_name);
            setShowLastAssigned(false);
            setShowClashedRollNo(true);
          } else {
            setShowClashedRollNo(false);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [currentRollNumber]);

  useEffect(() => {
    setCurrentRollNumber(defaultRollno);
  }, [defaultRollno]);

  const [
    getDefaultCity,
    { loading: cityLoading, error: cityError, data: cityData },
  ] = useLazyQuery(GET_DEFAULT_CITY);

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "contactDetails", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  //Student Address
  const stateValue = watch("stateValue");
  const districtValue = watch("districtValue");
  const cityValue = watch("cityValue");

  const [addAddress] = useMutation(ADD_STUDENT_ADDRESS);
  const [updateAddressRelationship] = useMutation(
    UPDATE_STUDENT_ADDRESS_RELATIONSHIP,
  );

  const [updateStudentImage] = useMutation(UPDATE_STUDENT_IMAGE);

  useEffect(() => {
    getDefaultCity({
      variables: { cityName: DEFAULT_STUDENT_CITY },
    });
  }, []);

  const setStudentDetails = (res) => {
    let resdata = res?.students_by_pk;
    const defaultContactData = [];
    resdata?.student_parents?.forEach((parent) => {
      defaultContactData.push({
        id: parent?.user_id,
        fullName: parent?.user?.first_name,
        contactInfo: parent?.user?.mobile_number,
        contactRelationship: parent?.relation,
      });
    });
    setContactDetails(defaultContactData);
    let fetchedStudentData = {
      firstName: resdata.first_name,
      middleName: resdata.middle_name,
      lastName: resdata.last_name,
      enrolDivision: resdata.division_id,
      enrollDate: resdata.enroll_date,
      dateOfBirth: resdata.dob,
      placeOfBirth: resdata.place_of_birth,
      religion: resdata.religion,
      caste: resdata.caste,
      category: resdata.category,
      lastSchool: resdata.last_school,
      payingOrFree: resdata.paying_or_free,
      admittedClass: resdata.admitted_class,
      admittedClassDivValue: resdata.admitted_class,
      admittedClassDivValuez: resdata.admitted_class,
      aadhaar: resdata.aadhaar,
      classValuez: resdata?.division?.class.class_name,
      divValuez: resdata?.division?.division_name,
      classValue: resdata?.division?.class.class_name,
      divValue: resdata?.division?.division_name,
      fatherName: resdata.father_name,
      motherName: resdata.mother_name,
      remarks: resdata.remarks,
      rte: resdata.rte !== null ? (resdata.rte ? "True" : "False") : false,
      grNo: resdata.gr_no,
      grSet: resdata.gr_set,
      gender: resdata.gender,
      studentProgress: resdata.student_progress,
      conduct: resdata.conduct,
      leftClass: resdata.left_class,
      studentRef: resdata.student_ref,
      is_draft: resdata?.is_draft,
      rollNumber: resdata?.roll_no,
      divisionId: resdata?.division_id,

      heightTermOne: resdata?.height_term_one,
      weightTermOne: resdata?.weight_term_one,
      heightTermTwo: resdata?.height_term_two,
      weightTermTwo: resdata?.weight_term_two,
      parentsOccupation: resdata?.parents_occupation,
      motherTongue: resdata?.mother_tongue,

      subcaste: resdata?.sub_caste,
      minority:
        resdata?.is_minority !== null
          ? resdata.is_minority
            ? "True"
            : "False"
          : false,
      belowPovertyLine:
        resdata?.is_below_poverty_line !== null
          ? resdata.is_below_poverty_line
            ? "True"
            : "False"
          : false,
      motherOccupation: resdata?.mother_occupation,
      noOfSisters: resdata?.no_of_sisters,
      noOfBrothers: resdata?.no_of_brothers,
      totalFamilyMembers: resdata?.total_family_members,
      handicap:
        resdata?.is_handicap !== null
          ? resdata?.is_handicap
            ? "True"
            : "False"
          : false,
      registerBookNo: resdata?.register_book_no,
      lastSchoolTransferDate: resdata?.last_school_transfer_date,
      nationality: resdata?.nationality,
      bloodGroup: resdata?.blood_group,
      fatherEducation: resdata?.father_education,
      motherEducation: resdata?.mother_education,
      lastSchoolClass: resdata?.last_school_class
        ? `Class: ${resdata?.last_school_class}`
        : "",

      add1: resdata?.address?.address_line_one,
      add2: resdata?.address?.address_line_two,
      state: resdata?.address?.city?.district?.state?.id,
      district: resdata?.address?.city?.district?.id,
      city: resdata?.address?.city?.id,
      cityValue: resdata?.address?.city?.city_name,
      districtValue: resdata?.address?.city?.district?.district_name,
      stateValue: resdata?.address?.city?.district?.state?.state_name,
      pincode: resdata?.address?.pincode,
    };

    setStudentId(resdata?.id);

    if (!_.isNil(resdata.enroll_date)) {
      fetchedStudentData.enrolDate = moment(resdata.enroll_date);
    }
    if (!_.isNil(resdata.dob)) {
      fetchedStudentData.dateOfBirth = moment(resdata.dob);
    }
    if (!_.isNil(resdata.last_school_transfer_date)) {
      fetchedStudentData.lastSchoolTransferDate = moment(
        resdata.last_school_transfer_date,
      );
    }
    let filterDivsWithClassID = divisionData.filter(
      (division) => division.class_id === parseInt(resdata?.division?.class_id),
    );

    if (resdata.image !== null) {
      setHasImage(true);
      setImageID(resdata?.image);
      setImageURL(getS3Image(resdata?.image));
    }

    setDivisionList(filterDivsWithClassID);
    setCurrentDivision(resdata?.division?.id);
    setSelectedDivision(resdata?.division?.id);

    setSelectedAdmittedDivision(resdata?.admitted_class);
    setSelectedClass(resdata?.division?.class_id);

    setuser(fetchedStudentData);
    reset(fetchedStudentData);
  };

  useEffect(() => {
    if (isResetForm) {
      const handleReset = () => {
        setStudentDetails(data);
        setIsResetForm(false);
      };
      setTimeout(handleReset, 0);
    }
  }, [isResetForm, reset, setCurrentDivision]);

  const { loading: loadingDivs, data: classDivData } = useQuery(
    GET_CLASSES_AND_DIVISIONS,
    {
      variables: { schoolId: getSchoolID() },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (classd) => {
        let classLists = [];
        let divList = [];
        classd?.classes.map((classDiv) => {
          classLists.push({
            value: classDiv.id,
            label: `Class: ${classDiv.class_name}`,
          });
          classDiv.divisions.map((divz) =>
            divList.push({
              value: divz.id,
              divs: divz.division_name,
              label: `Division: ${divz.division_name}`,
              class_id: classDiv.id,
            }),
          );
        });
        setclassdata(classLists);
        setdivisiondata(divList);
      },
    },
  );

  useEffect(() => {
    if (!loading) {
      let classLists = [];
      let divList = [];
      classDivData?.classes.map((classDiv) => {
        classLists.push({
          value: classDiv.id,
          label: `Class: ${classDiv.class_name}`,
        });
        classDiv.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            divs: divz.division_name,
            label: `Division: ${divz.division_name}`,
            class_id: classDiv.id,
          }),
        );
      });
      setclassdata(classLists);
      setdivisiondata(divList);
    }
  }, [loadingDivs]);
  const { loading, error, data } = useQuery(GET_STUDENTS_BYPK, {
    variables: {
      id: currentStudent,
    },
    onCompleted: (res) => {
      setuser(res);
      setStudentDetails(res);
      setCurrentDivision(res.students_by_pk.division_id);
      setdefaultRollno(res.students_by_pk?.roll_no);
    },
  });

  const [getFieldData, { lazyLoading, lazyError, lazyData, networkStatus }] =
    useLazyQuery(GET_STUDENTS_BYPK, {
      variables: {
        id: currentStudent,
      },
    });

  const [updateStudent, { loading: updateStudentMutationLoading }] =
    useMutation(UPDATE_STUDENTS_ONE, {
      refetchQueries: [
        {
          query: GET_STUDENTS_LIST_WITH_FILTER_PAGINATED,
          variables: {
            schoolId: getSchoolID(),
            offset: 0,
            limit: 10,
            filters: {
              is_draft: { _eq: false },
              transfer_date: {
                _is_null: true,
              },
              is_active: {
                _eq: true,
              },
            },
          },
        },
      ],
    });

  const [insertParentStudent] = useMutation(
    INSERT_PARENT_STUDENT_RELATIONSHIP_UPDATE_NAME,
  );

  const getAddressId = async (addressPayload, addedBy) => {
    try {
      let addressId = null;
      if (data?.students_by_pk?.address?.id === undefined) {
        let res = await addAddress({ variables: { address: addressPayload } });
        addressId = res?.data?.insert_addresses?.returning[0]?.id;
        res = await updateAddressRelationship({
          variables: {
            studentId: data.students_by_pk.id,
            addressId: addressId,
            addedBy: addedBy,
          },
        });
      } else {
        addressId = data?.students_by_pk?.address?.id;
      }
      return addressId;
    } catch (err) {
      setIsLoading(false);
      notification["error"]({
        message: "Something went wrong",
        description: " Could not create parents",
        duration: 1.5,
      });
      return false;
    }
  };

  const createUserinFirebase = (
    payloadforParents,
    payloadFromSubmit,
    currentStudent,
    addedBy,
    addressPayload,
  ) => {
    let userPayload = [];
    payloadforParents.forEach((usr) => {
      userPayload.push({
        phone: `+91${usr.contactInfo}`,
        first_name: usr.fullName,
        school_id: getSchoolID(),
        role_id: 3,
      });
    });
    // create user in firebase
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_FUNCTION_ENDPOINT}/createUsers`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { data: userPayload },
    };
    axios(config)
      .then(async (res) => {
        if (res?.data?.successArray?.length > 0) {
          // find parent id in firebase response
          const getParentIdFromFirebase = (contactInfo) => {
            let firebaseUser = res?.data?.successArray?.find(
              (obj) => obj.phoneNumber === `+91${contactInfo}`,
            );
            if (firebaseUser) {
              return firebaseUser;
            }
            return null;
          };
          // create payload for relation
          const relationPayload = [];
          payloadforParents.forEach((parentData) => {
            const fbUser = getParentIdFromFirebase(parentData.contactInfo);
            if (fbUser) {
              relationPayload.push({
                ...parentData,
                user_id: fbUser.uid,
                update_name: fbUser.state === "existing",
              });
            }
          });
          // final payload to insert in db for student parent relation
          let payload = [];
          let userUpdate = [];
          relationPayload.forEach((parent) => {
            payload.push({
              student_id: currentStudent,
              user_id: parent.user_id,
              relation: parent.contactRelationship,
            });
            if (parent.update_name) {
              userUpdate.push({
                _set: { first_name: parent.fullName },
                where: { auth_id: { _eq: parent.user_id } },
              });
            }
          });
          insertParentStudent({ variables: { objects: payload, userUpdate } })
            .then((response) => {
              notification["success"]({
                message: "Parents added",
                duration: 1.5,
              });
            })
            .catch((reserror) => {
              console.error(reserror);
            });
          // update Divisions only if the current div is change
          const addressId = await getAddressId(addressPayload, addedBy);

          if (currentDivision !== selectedDivision) {
            updateStudent({
              variables: {
                _set: payloadFromSubmit,
                id: currentStudent,
                addedBy: addedBy,
                addressid: addressId,
                _setaddress: addressPayload,
              },
            })
              .then((response) => {
                changeStudentDivision(
                  {
                    oldDivisionId: currentDivision,
                    studentId,
                    newDivisionId: selectedDivision,
                  },
                  {
                    onSuccess: () => {
                      notification["success"]({
                        message: "Updated values",
                        description: `for student: ${response?.data?.update_students_by_pk?.first_name}`,
                        duration: 2,
                      });
                      setIsLoading(false);
                      onSuccess();
                    },
                  },
                );
              })
              .catch((reserror) => {
                setIsLoading(false);
              });
          } else {
            updateStudent({
              variables: {
                _set: payloadFromSubmit,
                id: currentStudent,
                addedBy: addedBy,
                addressid: addressId,
                _setaddress: addressPayload,
              },
            })
              .then((response) => {
                notification["success"]({
                  message: "Updated values",
                  description: `for student: ${response?.data?.update_students_by_pk?.first_name}`,
                  duration: 2,
                });
                setIsLoading(false);
                onSuccess();
              })
              .catch((reserror) => {
                setIsLoading(false);
              });
          }
        } else {
          setIsLoading(false);
          notification["error"]({
            message: "Something went wrong",
            description: " Could not create parents",
            duration: 1.5,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const onSubmit = async (dataz) => {
    const cityId = cityData?.cities ? cityData?.cities[0].id : false;
    if (isNaN(cityId) || cityId == 0) {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try again",
        duration: 3,
      });
      setIsLoading(false);
      return;
    }
    if (selectedDivision !== null && selectedClass !== null) {
      setIsLoading(true);
      let payload = {
        aadhaar: dataz.aadhaar,
        caste: dataz.caste,
        division_id: parseInt(selectedDivision),
        dob: dataz.dateOfBirth
          ? moment(dataz.dateOfBirth).format(DATE_FORMATS.YYYYMMDD)
          : null,
        enroll_date: dataz.enrolDate
          ? moment(dataz.enrolDate).format(DATE_FORMATS.YYYYMMDD)
          : null,
        first_name: dataz.firstName,
        is_draft: false,
        last_name: dataz.lastName,
        middle_name: dataz.middleName,
        religion: dataz.religion,
        admitted_class: selectedAdmittedDivision,
        paying_or_free: dataz?.payingOrFree || null,
        last_school: dataz.lastSchool,
        place_of_birth: dataz.placeOfBirth,
        transfer_date: dataz.transferData,
        transfer_reason: dataz.transferReason,
        gender: dataz.gender,
        gr_no: dataz.grNo,
        gr_set: dataz.grSet,
        rte: dataz.rte || null,
        category: dataz.category,
        father_name: dataz.fatherName,
        mother_name: dataz.motherName,
        remarks: dataz.remarks,
        left_class: dataz.leftClass,
        conduct: dataz.conduct,
        student_progress: dataz.studentProgress,
        student_ref: dataz.studentRef,

        height_term_one: dataz.heightTermOne,
        weight_term_one: dataz.weightTermOne,

        height_term_two: dataz.heightTermTwo,
        weight_term_two: dataz.weightTermTwo,

        parents_occupation: dataz.parentsOccupation,
        mother_tongue: dataz.motherTongue,

        sub_caste: dataz?.subcaste,
        is_minority: dataz?.minority || null,
        is_below_poverty_line: dataz?.belowPovertyLine || null,
        mother_occupation: dataz?.motherOccupation,
        no_of_sisters: dataz?.noOfSisters,
        no_of_brothers: dataz?.noOfBrothers,
        total_family_members: dataz?.totalFamilyMembers,
        is_handicap: dataz?.handicap || null,
        register_book_no: dataz?.registerBookNo,
        last_school_transfer_date: dataz?.lastSchoolTransferDate
          ? moment(dataz.lastSchoolTransferDate).format(DATE_FORMATS.YYYYMMDD)
          : null,
        nationality: dataz?.nationality,
        blood_group: dataz?.bloodGroup,
        father_education: dataz?.fatherEducation,
        mother_education: dataz?.motherEducation,
        last_school_class: dataz?.lastSchoolClass?.replace("Class: ", ""),

        school_id: getSchoolID(),
        roll_no: parseInt(currentRollNumber),
      };

      const addressPayload = {
        address_line_one: dataz.add1,
        city_id: cityId,
      };

      if (hasImage) {
        payload.image = imageID;
      }

      let addedBy = JSON.parse(localStorage.getItem("claims"))[
        "x-hasura-user-id"
      ];

      console.log(JSON.parse(localStorage.getItem("claims")))

      let shouldCreateParents = dataz?.contactDetails?.length > 0;
      if (shouldCreateParents) {
        createUserinFirebase(
          dataz.contactDetails,
          payload,
          currentStudent,
          addedBy,
          addressPayload,
        );
      } else {
        const addressId = await getAddressId(addressPayload, addedBy);

        if (currentDivision !== selectedDivision) {
          updateStudent({
            variables: {
              _set: payload,
              id: currentStudent,
              addedBy: addedBy,
              addressid: addressId,
              _setaddress: addressPayload,
            },
          })
            .then((response) => {
              changeStudentDivision(
                {
                  oldDivisionId: currentDivision,
                  studentId,
                  newDivisionId: selectedDivision,
                },
                {
                  onSuccess: () => {
                    notification["success"]({
                      message: "Updated values",
                      description: `for student: ${response?.data?.update_students_by_pk?.first_name}`,
                      duration: 2,
                    });
                    setIsLoading(false);
                    onSuccess();
                  },
                },
              );
            })
            .catch((reserror) => {
              setIsLoading(false);
            });
        } else {
          updateStudent({
            variables: {
              _set: payload,
              id: currentStudent,
              addedBy: addedBy,
              addressid: addressId,
              _setaddress: addressPayload,
            },
          })
            .then((response) => {
              notification["success"]({
                message: "Updated values",
                description: `for student: ${response?.data?.update_students_by_pk?.first_name}`,
                duration: 2,
              });
              setIsLoading(false);
              onSuccess();
            })
            .catch((reserror) => {
              setIsLoading(false);
            });
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  const renderImage = () => {
    if (hasImage) {
      return (
        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
          <img
            src={imageURL}
            alt="avatar"
            className="w-full h-full object-cover rounded-md"
          />
        </span>
      );
    }
    return (
      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  };

  const updateImage = async (image) => {
    await updateStudentImage({
      variables: {
        studentId: data.students_by_pk.id,
        image,
      },
    });
    notification["success"]({
      message: "Student!",
      description: "Image Updated!",
      duration: 1.5,
    });
  };

  const handleUpload = async (file, onSuccess) => {
    const fileId = uuid();
    // get file extension
    const fileExtension = file.name.split(".").pop();
    const key = `${process.env.REACT_APP_S3_BUCKET_FOLDER}/${fileId}.${fileExtension}`;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
    };
    imagesS3Bucket.putObject(params).send((err, data) => {
      if (err) {
        // handle the error here
        console.error("s3 err", err);
      } else {
        // handle the success here
        onSuccess(data.response, file);
        //  store file id in school instead of image url
        const imageKey = `${fileId}.${fileExtension}`;
        // update image in school

        setHasImage(true);
        setImageID(imageKey);
        setImageURL(getS3Image(imageKey));

        updateImage(imageKey);
      }
    });
  };
  const uploadProps = {
    customRequest({ file, onSuccess }) {
      return handleUpload(file, onSuccess);
    },
  };

  if (loadingDivs || loading || networkStatus === NetworkStatus.refetch) {
    return <div>Loading...</div>;
  }

  const handleCancel = (e) => {
    setIsResetForm(true);
  };

  const handleRollNoChange = (event) => {
    let current = event.target.value;
    if (current && current !== "" && !isNaN(parseInt(current))) {
      setCurrentRollNumber(parseInt(current));
    } else {
      setCurrentRollNumber(current);
    }
  };

  const selectValue = classData?.find(
    (elem) => elem.value === parseInt(selectedClass),
  )?.label;

  const onAddressValueClear = () => {
    setValue("stateValue", null);
    setValue("districtValue", null);
    setValue("cityValue", null);
    setValue("pincode", "");
  };

  const filterOptionsOnInput = (inputValue, option) => {
    return option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase());
  };

  const handleRemoveParent = async () => {
    const removed = await removeParent({
      studentId,
      parentAuthId: currentParentData.id,
    });
    if (removed) {
      const parentList = contactDetails.filter(
        (parent) => parent.id !== currentParentData.id,
      );
      setContactDetails(() => [...parentList]);
      setCurrentParentData({});
    } else {
      notification["error"]({
        message: "Something went wrong",
        description: " Unable to delete parents",
        duration: 1.5,
      });
    }
  };

  const handleDownloadAdmissionForm = async (medium) => {
    setAdmissionFormMedium(medium.toLowerCase());
  };

  if (isResetForm) {
    return <p>Loading...</p>;
  }

  return (
    <Spin
      spinning={
        admissionFormDownloading ||
        updateStudentMutationLoading ||
        isLoading ||
        isRemovingParent
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          <FormSideSection
            title="Student Details"
            description="Personal information of the child"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <div>
                  <label className="block text-sm font-semibold text-gray-700 text-left">
                    Photo (Optional)
                  </label>
                  <div className="mt-1 flex items-center">
                    {renderImage()}
                    <Upload
                      {...uploadProps}
                      beforeUpload={(file) => {
                        // check if file is an jpeg or png
                        if (
                          file.type !== "image/jpeg" &&
                          file.type !== "image/png"
                        ) {
                          message.error("Only jpeg or png images are allowed!");
                          return false;
                        }
                        // check if file size is less than 2MB
                        if (file.size > 2000000) {
                          message.error("File size should be less than 2MB!");
                          return false;
                        }
                        return true;
                      }}
                      showUploadList={false}
                      className="ml-5 bg-white py-2 px-3 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Button
                        style={{
                          borderRadius: 6,
                          fontWeight: "bold",
                          color: "#374151",
                        }}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  name="firstName"
                  placeholder="firstName"
                  label="First Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "can't be longer than 20 characters",
                    },
                    required: "required",
                  }}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="middleName"
                  placeholder="middleName"
                  label="Middle Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "can't be longer than 20 characters",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="lastName"
                  placeholder="lastName"
                  label="Last Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "can't be longer than 20 characters",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol overrideClassnames="col-span-1">
                <CustomInput
                  name="aadhaar"
                  placeholder="aadhaar"
                  label="Aadhaar Number"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 12,
                      message:
                        "Aadhaar number is a 12 digit number. You seem to have entered too many.",
                    },
                    minLength: {
                      value: 12,
                      message: "Aadhaar number is atleast 12 digits long.",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>

              <FormCol overrideClassnames="col-span-2">
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2 ">
                  {"Gender"}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>

                <div className="flex flex-row">
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="M"
                      label="Male"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      name="gender"
                      value="F"
                      label="Female"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4 ">
                    <CustomRadioInput
                      name="gender"
                      value="T"
                      label="Transgender"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-22 pr-4 ">
                    <CustomRadioInput
                      name="gender"
                      value="NA"
                      label="Not disclosed"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomAntdDatePicker
                  name="dateOfBirth"
                  placeholder="dateOfBirth"
                  label="Date of birth"
                  register={register}
                  errors={errors}
                  onChange={(dob) => setValue(`dateOfBirth`, dob)}
                  defaultValue={user?.dateOfBirth}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="placeOfBirth"
                  label="Place of birth"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol id={"managestudents-addstudent-bloodgroup"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Blood Group
                </label>
                <Select
                  {...register("bloodGroup")}
                  className="customised-antd-selector-controlled"
                  onChange={(value) => {
                    setValue("bloodGroup", value);
                  }}
                  optionFilterProp="children"
                  showSearch
                  defaultValue={user?.bloodGroup}
                  size="large"
                  allowClear
                >
                  {filterOptions?.bloodGroup?.map((group) => (
                    <Option key={group}>{group}</Option>
                  ))}
                </Select>
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Religion
                </label>
                <Select
                  {...register("religion")}
                  className="customised-antd-selector-controlled"
                  onChange={(value) => {
                    setValue("religion", value);
                  }}
                  optionFilterProp="children"
                  showSearch
                  defaultValue={user?.religion}
                  size="large"
                  allowClear
                >
                  {(filterOptions?.religion || [])?.map(({ value }) => (
                    <Option key={value}>{value}</Option>
                  ))}
                </Select>
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-caste"}
                  name="caste"
                  placeholder="caste"
                  label="Caste"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-caste"}
                  name="subcaste"
                  placeholder="subcaste"
                  label="Sub Caste"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Category
                </label>
                <Select
                  {...register("category")}
                  className="customised-antd-selector-controlled"
                  onChange={(value) => {
                    setValue("category", value);
                  }}
                  optionFilterProp="children"
                  showSearch
                  defaultValue={user?.category}
                  size="large"
                  allowClear
                >
                  {(filterOptions?.categories || [])?.map(({ value }) => (
                    <Option key={value}>{value}</Option>
                  ))}
                </Select>
              </FormCol>

              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-mothertongue"}
                  name="motherTongue"
                  label="Mother Tongue"
                  register={register}
                  errors={errors}
                />
              </FormCol>

              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-nationality"}
                  name="nationality"
                  label="Nationality"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Minority"}
                </label>

                <div
                  id={"managestudents-addstudent-minority"}
                  className="flex flex-row"
                >
                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-minority"}
                      name="minority"
                      value="True"
                      label="Yes"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-minority"}
                      name="minority"
                      value="False"
                      label="No"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Below Poverty Line (BPL)"}
                </label>

                <div
                  id={"managestudents-addstudent-belowPovertyLine"}
                  className="flex flex-row"
                >
                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-belowPovertyLine"}
                      name="belowPovertyLine"
                      value="True"
                      label="Yes"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-belowPovertyLine"}
                      name="belowPovertyLine"
                      value="False"
                      label="No"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudentsform-address-1"}
                  name="add1"
                  placeholder="add1"
                  label="Address Line 1"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Family information"
            description="Basic Information of family"
          />
          <FormCard>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-fathersname"}
                  name="fatherName"
                  label="Father's Name"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-parentsoccupation"}
                  name="parentsOccupation"
                  label="Father's Occupation"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-fathereducation"}
                  name="fatherEducation"
                  label="Father's Education"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-mothersname"}
                  name="motherName"
                  label="Mother's Name"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-motheroccupation"}
                  name="motherOccupation"
                  label="Mother's Occupation"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-motherducation"}
                  name="motherEducation"
                  label="Mother's Education"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-sisters"}
                  name="noOfSisters"
                  label="No. of Sisters"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-brothers"}
                  name="noOfBrothers"
                  label="No. of Brothers"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-totalfamilymembers"}
                  name="totalFamilyMembers"
                  label="Total Family Members"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Physical Details"
            description="Height and Weight will be shown in the Progress Report"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <label className="text-gray-900 font-bold text-md leading-5 text-left mb-2">
                  First Term
                </label>
                <CustomInput
                  id={"managestudents-addstudent-heighttermone"}
                  name="heightTermOne"
                  label="Height (cm)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-900 font-bold text-md leading-5 text-left mb-2">
                  Second Term
                </label>
                <CustomInput
                  id={"managestudents-addstudent-heighttermtwo"}
                  name="heightTermTwo"
                  label="Height (cm)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-weighttermOne"}
                  name="weightTermOne"
                  label="Weight (kg)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-weighttermtwo"}
                  name="weightTermTwo"
                  label="Weight (kg)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Handicap"}
                </label>

                <div
                  id={"managestudents-addstudent-handicap"}
                  className="flex flex-row"
                >
                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-handicap"}
                      name="handicap"
                      value="True"
                      label="Yes"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-handicap"}
                      name="handicap"
                      value="False"
                      label="No"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Parent Contact Details"
            description="Contact information of parents or guardians"
          />
          <FormCard>
            {contactDetails.map((contactDetail) => {
              return (
                <>
                  <FormRow
                    key={contactDetail.id}
                    overrideClassnames="items-end"
                  >
                    <FormCol>
                      <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                        Full Name
                      </label>
                      <input
                        defaultValue={`${contactDetail.fullName}`}
                        className="border border-gray-300 bg-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 "
                        label="Full Name"
                        disabled
                      />
                    </FormCol>
                    <FormCol>
                      <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                        Contact Information
                      </label>
                      <input
                        defaultValue={`${contactDetail.contactInfo.slice(3)}`}
                        className="border border-gray-300 bg-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 "
                        label="Full Name"
                        disabled
                      />
                    </FormCol>
                    <FormCol>
                      <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                        Contact Relationship
                      </label>
                      <input
                        defaultValue={`${contactDetail.contactRelationship}`}
                        className="border border-gray-300 bg-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 "
                        label="Full Name"
                        disabled
                      />
                    </FormCol>
                    <FormCol overrideClassnames="col-span-1">
                      {showParentRemoveModal ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-md">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl h-48">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center justify-center w-full h-full bg-white outline-none focus:outline-none">
                                <div className="flex items-center pl-5 pr-5 pb-7 justify-center rounded-t">
                                  <h3 className="text-xl font-semibold">
                                    <p> Are you sure you want to remove </p>
                                    <span className="text-red-500">
                                      {" "}
                                      {currentParentData?.fullName}{" "}
                                    </span>{" "}
                                    as a parent?
                                  </h3>
                                </div>
                                <div className="flex items-center justify-center rounded-b">
                                  <CustomButton
                                    buttonStyle="primary"
                                    onClick={(e) => {
                                      setParentRemoveModal(false);
                                    }}
                                    style={{
                                      background: "#EF4444",
                                      height: 30,
                                    }}
                                  >
                                    No
                                  </CustomButton>
                                  <CustomButton
                                    buttonStyle="primary"
                                    onClick={(e) => {
                                      setParentRemoveModal(false);
                                      handleRemoveParent();
                                    }}
                                    style={{
                                      background: "#22C55E",
                                      height: 30,
                                      marginLeft: 25,
                                    }}
                                  >
                                    Yes
                                  </CustomButton>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-5 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => {
                          setCurrentParentData(contactDetail);
                          setParentRemoveModal(true);
                        }}
                        className={
                          "py-3 mt-6 flex justify-center items-center text-white bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none rounded-md"
                        }
                      >
                        Remove
                      </button>
                    </FormCol>
                  </FormRow>
                </>
              );
            })}
            {fields.map((field, fieldIndex) => (
              <FormRow key={field.id} overrideClassnames="items-end">
                <FormCol>
                  <CustomInput
                    key={field.id} // important to include key with field's id
                    name="fullName"
                    register={() =>
                      register(`contactDetails.${fieldIndex}.fullName`, {
                        required: true,
                      })
                    }
                    errors={errors}
                    label="Full Name"
                    required
                  />
                </FormCol>
                <FormCol>
                  <CustomInput
                    key={field.id} // important to include key with field's id
                    name="contactInfo"
                    placeholder="contactInfo"
                    label="Contact information"
                    register={() =>
                      register(`contactDetails.${fieldIndex}.contactInfo`, {
                        required: true,
                        pattern: /^[6-9]\d{9}$/i,
                      })
                    }
                    maxLength="10"
                    errors={errors}
                    required
                  />
                </FormCol>
                <FormCol>
                  <CustomFieldArrSelect
                    key={field.id} // important to include key with field's id
                    options={parentRelationship}
                    name="contactRelationship"
                    placeholder="contactRelationship"
                    label="Contact relationship"
                    register={() =>
                      register(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        {
                          required: true,
                        },
                      )
                    }
                    errors={errors}
                    defaultValue={`${field.contactRelationship}`}
                    onChange={(value) =>
                      setValue(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        value,
                      )
                    }
                    required
                  />
                </FormCol>
                <FormCol>
                  <button
                    type="reset"
                    className="py-3 px-3 mr-2 justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100 "
                    onClick={() => remove(fieldIndex)}
                  >
                    Cancel
                  </button>
                </FormCol>
              </FormRow>
            ))}
            <FormRow>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    fullName: "",
                    contactInfo: "",
                    contactRelationship: "",
                  });
                }}
                className={
                  "p-2 rounded-md bg-white border border-gray-300 text-xs leading-4 font-medium shadow-sm  cursor-pointer "
                }
              >
                {" "}
                Add new contact (optional)
              </button>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Additional Details"
            description="Overall conduct and other details"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <CustomInput
                  name="studentRef"
                  label="Student Reference Number"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="grSet"
                  label="General Register Set"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="grNo"
                  label="General Register Number"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-registerbookno"}
                  name="registerBookNo"
                  label="Register Book No."
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mt-1 mb-1">
                  {"Paying or Free"}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left "></label>
                  }
                </label>

                <div className="flex flex-row">
                  <div className="flex flex-col w-18 pr-8">
                    <CustomRadioInput
                      id={"managestudents-addstudent-payingorfree-p"}
                      name="payingOrFree"
                      label="Paying"
                      value="Paying"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-payingorfree-f"}
                      name="payingOrFree"
                      label="Free"
                      value="Free"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>

              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mt-1 mb-1">
                  {"Right to Education (RTE) "}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left "></label>
                  }
                </label>

                <div className="flex flex-row">
                  <div className="flex flex-col w-18 pr-8">
                    <CustomRadioInput
                      id={"managestudents-addstudent-rte-y"}
                      name="rte"
                      label="Yes"
                      value="True"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-rte-n"}
                      name="rte"
                      label="No"
                      value="False"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-lastschool"}
                  name="lastSchool"
                  label="Last School Name"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol id={"managestudents-addstudent-lastschoolclass"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Last School Class
                </label>
                <Select
                  {...register("lastSchoolClass", {})}
                  className="customised-antd-selector-controlled"
                  defaultValue={user?.lastSchoolClass}
                  onChange={(value) => {
                    setValue("lastSchoolClass", value.replace("Class: ", ""));
                  }}
                  size="large"
                  allowClear
                  placeholder="Class: All"
                >
                  {getSortedClasses(classData).map((classl) => (
                    <Option key={classl.label}>{classl.label}</Option>
                  ))}
                </Select>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol id={"managestudents-addstudent-lstd"}>
                <CustomAntdDatePicker
                  name="lastSchoolTransferDate"
                  placeholder="lastSchoolTransferDate"
                  label="Last School Transfer Date"
                  register={register}
                  errors={errors}
                  defaultValue={user?.lastSchoolTransferDate}
                  onChange={(lsDate) =>
                    setValue(`lastSchoolTransferDate`, lsDate)
                  }
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Class and Division in which admitted{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left "></label>
                  }
                </label>
                <Select
                  className="customised-antd-selector-controlled"
                  value={admittedClassDivValue}
                  {...register("admittedClassDivValue", {})}
                  onChange={(value) => {
                    setSelectedAdmittedDivision(value);
                    setAdmittedClassDiv(value);
                    setValue("admittedClassDivValue", value);
                  }}
                  size="large"
                  allowClear
                  placeholder={
                    _.isNil(user?.admittedClassDivValuez)
                      ? "Select Class and Division"
                      : user?.admittedClassDivValuez
                  }
                >
                  {getSortedClassesAndDivisions(divisionData).map(
                    (div, idx) => (
                      <Option key={div.divs}>{div.divs}</Option>
                    ),
                  )}
                </Select>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <CustomInput
                  name="conduct"
                  label="Conduct"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  name="remarks"
                  label="Remarks"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol>
                <CustomInput
                  name="studentProgress"
                  label="Overall progress"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Enrollment Details"
            description="Date, Class and Division"
          />
          <FormCard final>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomAntdDatePicker
                  name="enrolDate"
                  placeholder="enrolDate"
                  label="Enrollment date"
                  register={register}
                  errors={errors}
                  onChange={(ed) => setValue(`enrolDate`, ed)}
                  defaultValue={user?.enrolDate}
                  rules={{ required: "required" }}
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Class{" "}
                  {
                    <label className="text-red-700 font-semibold text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>
                <Select
                  className="customised-antd-selector-controlled"
                  placeholder={
                    _.isNil(user?.classValuez)
                      ? "Select Class"
                      : `Class: ${user?.classValuez}`
                  }
                  {...register("classValue", { required: "required" })}
                  onChange={(value) => {
                    let filterDivsWithClassID = divisionData.filter(
                      (x) => x.class_id === parseInt(value),
                    );
                    setShowLastAssigned(false);
                    setSelectedClass(value);
                    setDivision(null);
                    setDivisionList(filterDivsWithClassID);
                    setValue("classValue", value);
                    setValue("divValue", null);
                    setuser({ ...user, divValuez: null, classValuez: null });
                    resetField("divValue");

                    setuser({ ...user, divValuez: null, classValuez: null });
                  }}
                  value={selectValue}
                  size="large"
                  allowClear
                >
                  {getSortedClasses(classData).map((classl) => (
                    <Option key={classl.value}>{classl.label}</Option>
                  ))}
                </Select>
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Division{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>
                <Select
                  className="customised-antd-selector-controlled"
                  value={divValue}
                  {...register("divValue", { required: "required" })}
                  onChange={(value) => {
                    setDivision(value);
                    setSelectedDivision(value);
                    setValue("divValue", value);
                    setShowLastAssigned(true);
                  }}
                  size="large"
                  allowClear
                  placeholder={
                    _.isNil(user?.divValuez)
                      ? "Select Division"
                      : `Division: ${user?.divValuez}`
                  }
                >
                  {getSortedClassesAndDivisions(divisionList).map((div) => (
                    <Option key={div.value}>{div.label}</Option>
                  ))}
                </Select>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol overrideClassnames="col-span-1">
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Roll Number
                </label>
                <input
                  type="text"
                  className=" max-h-10 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  placeholder="Roll Number"
                  value={currentRollNumber}
                  onChange={handleRollNoChange}
                />
              </FormCol>
              {showLastAssigned &&
                (lastRollNo === null ? (
                  <FormCol overrideClassnames="hidden" />
                ) : (
                  <FormCol overrideClassnames="col-span-2 mt-2 w-96">
                    <div className="flex gap-x-2 bg-gray-100 text-gray-500 rounded-md font-medium py-2 px-2">
                      <InfoCircleFilled
                        style={{
                          color: "#6B7280",
                          fontSize: "16px",
                          marginTop: "3px",
                        }}
                      />
                      <p className="text-left text-sm">
                        The last assigned roll number in
                        <br />
                        this class is {parseInt(lastRollNo)}
                      </p>
                    </div>
                  </FormCol>
                ))}
              {showClashedRollNo && (
                <FormCol overrideClassnames="col-span-2 mt-2 w-96">
                  <div className="flex gap-x-2 bg-gray-100 text-red-500 rounded-md font-medium py-2 px-2">
                    <InfoCircleFilled
                      style={{
                        color: "#EB5757",
                        fontSize: "16px",
                        marginTop: "3px",
                      }}
                    />
                    <p className="text-left text-sm">
                      Please note that this roll number is already
                      <br />
                      assigned to {clashedRollNo}.
                    </p>
                  </div>
                </FormCol>
              )}
            </FormRow>
          </FormCard>
        </div>
        <div className="px-4 py-1 text-right sm:px-6">
          <button
            type="reset"
            className="py-3 px-3 mr-2 justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100 "
            onClick={handleCancel}
          >
            Cancel
          </button>

          {/*<div className="inline-flex mr-2">
            <Dropdown
              value={"Download Admission Form"}
              type="success"
              options={[schoolMediumList[0], schoolMediumList[1]]}
              onItemClick={handleDownloadAdmissionForm}
            />
          </div>*/}

          <button
            type="submit"
            className="inline-flex -mr-6 flex-cols justify-center py-2 px-4 border border:transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </Spin>
  );
};
export default EditStudent;