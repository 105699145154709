/* eslint-disable no-console */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";
import { getSchoolID } from "../shared/getSchoolID";

const updateStructure = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/updateFeeStructure`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      schoolId: getSchoolID(),
      ...payload,
    },
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpdateFeeStructure(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => updateStructure(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "Fee Structure Updated",
        duration: 1.5,
      });
      queryClient.invalidateQueries("feesStructures");
    },
    onError: (error) => {
      const errorRes = error?.response?.data;
      notification["error"]({
        message: "Failure",
        description: errorRes?.message || "Could not update Fee Structure",
        duration: 1.5,
      });
    },
    ...options,
  });
}
