import React, { useState, useEffect } from "react";
import ClassCard from "./ClassCard";
import ClassMenu from "./ClassMenu";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Modal, notification } from "antd";
import {
  ADD_DIVISION,
  GET_CLASSES_WITH_FILTER,
  GET_CLASSES_FOR_YEAR,
  ADD_CLASSES_AND_DIVISIONS,
} from "./graphql";
import { Spin } from "antd";
import { getSchoolID } from "../../shared/getSchoolID";
import importIcon from "../../assets/icons/import.png";
import Button from "../../components/Button";
import plusSign from "../../assets/images/plus_sign.png";
import ClassDivisionSelector from "../../components/ClassDivisionSelector";
import checkIcon from "../../assets/icons/check.png";
import { CloseCircleFilled } from "@ant-design/icons";
import { getSortedClasses } from "../../helpers";

const ClassesView = ({
  drawerOpen,
  setdrawerOpen,
  currentAcademicYear,
  selectedAcademicYear,
  isRefetchingClasses,
}) => {
  const [data, setdata] = useState([]);
  const [search, setsearch] = useState("");
  const [classData, setclassdata] = useState([]);
  const [divisionData, setdivisiondata] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [isImportSuccessModalVisible, setIsImportSuccessModalVisible] =
    useState(false);
  const isNewAcademicYear =
    selectedAcademicYear?.isCurrent === false && classData.length === 0;
  const navigate = useNavigate();

  const [addDivision, { loading: isLoading}] = useMutation(ADD_DIVISION, {
    notifyOnNetworkStatusChange: true,
  });

  const { loading, error } = useQuery(GET_CLASSES_FOR_YEAR, {
    variables: {
      schoolId: getSchoolID(),
      academicYearId: selectedAcademicYear?.value,
    },
    fetchPolicy: "network-only",
    onCompleted: (clasData) => {
      let classLists = [];
      let divList = [];
      clasData?.classes.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
        classObj.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: classObj.id,
          })
        );
      });
      setclassdata(classLists);
      setdivisiondata(divList);
    },
  });

  const getFilters = () => {
    if (selectedClass === "All") {
      return {
        classfilters: {
          school_id: { _eq: getSchoolID() },
          is_active: { _eq: true },
          academic_year_id: { _eq: selectedAcademicYear?.value },
        },
        divisionFilters: {
          division_name: { _ilike: `%${search}%` },
          is_active: { _eq: true },
        },
      };
    }

    if (
      selectedClass !== null &&
      (selectedDivision === null || selectedDivision === "All")
    ) {
      return {
        classfilters: {
          id: { _eq: selectedClass },
          school_id: { _eq: getSchoolID() },
          is_active: { _eq: true },
          academic_year_id: { _eq: selectedAcademicYear?.value },
        },
        divisionFilters: {
          division_name: { _ilike: `%${search}%` },
          is_active: { _eq: true },
        },
      };
    } else if (selectedClass !== null && selectedDivision !== null) {
      return {
        classfilters: {
          id: { _eq: selectedClass },
          school_id: { _eq: getSchoolID() },
          is_active: { _eq: true },
          academic_year_id: { _eq: selectedAcademicYear?.value },
        },
        divisionFilters: {
          id: { _eq: selectedDivision },
          division_name: { _ilike: `%${search}%` },
          is_active: { _eq: true },
        },
      };
    } else {
      return {
        classfilters: {
          school_id: { _eq: getSchoolID() },
          is_active: { _eq: true },
          academic_year_id: { _eq: selectedAcademicYear?.value },
        },
        divisionFilters: {
          division_name: { _ilike: `%${search}%` },
          is_active: { _eq: true },
        },
      };
    }
  };

  const [addClassesAndDivisions, { loading: isAddClassesLoading }] =
    useMutation(ADD_CLASSES_AND_DIVISIONS, {
      notifyOnNetworkStatusChange: true,
      refetchQueries: [
        {
          query: GET_CLASSES_WITH_FILTER,
          variables: getFilters(),
        },
      ],
      onCompleted: () => {
        setIsImportSuccessModalVisible(true);
      },
    });

  const { refetch: refetchClasses } = useQuery(GET_CLASSES_WITH_FILTER, {
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
    variables: getFilters(),
    onCompleted: (classd) => {
      let clasarr = [];
      classd?.classes?.map((clas) => {
        if (search === "") {
          clasarr.push({
            ...clas,
            count: clas?.divisions.reduce(function (acc, obj) {
              return acc + obj.division_students_aggregate?.aggregate?.count;
            }, 0),
          });
        } else if (clas?.divisions?.length !== 0) {
          clasarr.push({
            ...clas,
            count: clas?.divisions.reduce(function (acc, obj) {
              return acc + obj.division_students_aggregate?.aggregate?.count;
            }, 0),
          });
        }
      });
      setdata(clasarr);
    },
  });

  const [getPreviousYearClasses] = useLazyQuery(GET_CLASSES_FOR_YEAR, {
    onCompleted: (classData) => {
      // bulk insert to selected academic year
      const classesParams = classData?.classes?.map((classObj) => {
        return {
          class_name: classObj.class_name,
          academic_year_id: selectedAcademicYear?.value,
          school_id: getSchoolID(),
          is_active: true,
          divisions: {
            data: classObj.divisions.map((divisionObj) => {
              return {
                division_name: divisionObj.division_name,
                school_id: getSchoolID(),
                acadamic_year_id: selectedAcademicYear?.value,
                is_active: true,
              };
            }),
          },
        };
      });
      addClassesAndDivisions({
        variables: {
          classesData: classesParams,
        },
      });
    },
  });

  useEffect(() => {
    if (isRefetchingClasses) {
      refetchClasses();
    }
  }, [isRefetchingClasses]);

  if (error) return `Error! ${error.message}`;

  const getSortedDivisions = (divisions) => {
    // sort divisions by name
    return divisions.slice().sort((a, b) => {
      if (a.division_name < b.division_name) {
        return -1;
      }
      if (a.division_name > b.division_name) {
        return 1;
      }
      return 0;
    });
  };

  const getNextDivisionName = (classes) => {
    const sortedDivs = getSortedDivisions(classes?.divisions);
    const divName = sortedDivs[sortedDivs?.length - 1];
    const divNameL = divName?.division_name?.split(" ")?.length;
    if (divNameL > 1) {
      var dName = divName?.division_name?.slice(-1) || "A";
    } else {
      dName = divName?.division_name?.slice(-1) || "A";
    }
    if (classes.divisions?.length > 0) {
      const divCardName = String.fromCharCode(dName.charCodeAt(0) + 1);
      const classNameEndsWith = classes.class_name.slice(-1);
      const checkIfString = /[a-zA-Z]/.test(classNameEndsWith);
      const nextDivisionName = checkIfString
        ? `${classes.class_name} ${divCardName}`
        : `${classes.class_name}${divCardName}`;
      return nextDivisionName;
    }

    const classNameEndsWith = classes.class_name.slice(-1);
    const checkIfString = /[a-zA-Z]/.test(classNameEndsWith);
    const nextDivisionName = checkIfString
      ? `${classes.class_name} ${dName}`
      : `${classes.class_name}${dName}`;
    return nextDivisionName;
  };

  const handleAddDivision = (classes) => {
    const divisionData = {
      division_name: getNextDivisionName(classes),
      class_id: classes.id,
      school_id: getSchoolID(),
      acadamic_year_id: selectedAcademicYear?.value,
    };

    // If we have exam_type data, send that data also
    if (classes?.divisions?.[0]?.exam_type) {
      divisionData.exam_type = classes?.divisions?.[0]?.exam_type;
    }

    addDivision({
      variables: {
        divisionData,
      },
    })
      .then((response) => {
        refetchClasses();
        notification["success"]({ message: "Division added", duration: 1.5 });
      })
      .catch((reserror) => {
        notification["error"]({
          message: "Something went wrong",
          description: "Please try again.",
          duration: 1.5,
        });
      });
  };

  const handleImportClasses = () => {
    // NOTE: For importing classes, only the next academic year will be available.
    // So, the current academic year will be selected by default as the previous academic year.
    // fetch previous year classes
    getPreviousYearClasses({
      variables: {
        schoolId: getSchoolID(),
        academicYearId: currentAcademicYear?.value,
      },
    });
  };

  const handleClassFilterChange = (value) => {
    setSelectedClass(value);
    setSelectedDivision(null);
  };

  const handleDivisionFilterChange = (value) => {
    setSelectedDivision(value);
  };

  const goToSettings = () => {
    setIsImportSuccessModalVisible(false);
    navigate("/settings", {
      state: {
        isEdit: true,
      },
    });
  };

  return (
    <Spin spinning={loading || isAddClassesLoading}>
      <div className="mt-9">
        <div className="flex flex-row mb-6 justify-between w-full items-center">
          <form
            className="flex flex-col md:flex-row md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-start"
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
          >
            <div id="manageclasses-search" className=" relative w-full">
              <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                    fill="#9CA3AF"
                  />
                </svg>
              </span>
              <input
                defaultValue={search}
                type="text"
                className="w-full pl-10 rounded-lg border-transparent appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Search by class (example: 2C)"
                onChange={(e) => {
                  e.preventDefault();
                  setsearch(e.target.value);
                }}
              />
            </div>
          </form>
          <div className="flex gap-x-2">
            <ClassDivisionSelector
              id={"manageclasses-sortby"}
              classes={classData}
              divisions={divisionData}
              onClassChange={handleClassFilterChange}
              onDivisionChange={handleDivisionFilterChange}
              allOptionNeeded
            />
          </div>
        </div>
        {isNewAcademicYear && (
          <div
            className="flex flex-col gap-y-3 bg-white w-full mt-3 h-full rounded-lg items-center justify-center shadow-lg"
            style={{
              height: "70vh",
            }}
          >
            <img alt="import" src={importIcon} className="h-24 w-24" />
            <Button
              buttonStyle="primary"
              className="h-12"
              onClick={handleImportClasses}
            >
              Import classes from previous year
            </Button>
          </div>
        )}
        {!isNewAcademicYear && (
          <>
            {getSortedClasses(data)?.map((classes, classIndex) => {
              return (
                <>
                  {classes?.divisions?.length >= 0 ? (
                    <div
                      key={classes.id}
                      className="flex flex-col justify-start items-start mb-6"
                    >
                      <ClassMenu
                        divisions={classes?.divisions}
                        classText={classes?.class_name}
                        students_count={classes?.count}
                        refetchClasses={refetchClasses}
                        setdrawerOpen={() =>
                          setdrawerOpen(
                            !drawerOpen,
                            classes?.id,
                            classes?.class_name
                          )
                        }
                        classId={classes.id}
                      />
                      <div className="flex flex-row flex-wrap gap-x-4   ">
                        {getSortedDivisions(classes?.divisions)?.map(
                          (divs, index) => {
                            const id = "classcard-" + JSON.stringify(divs.id);
                            return (
                              <ClassCard
                                id={id}
                                refetchClasses={refetchClasses}
                                key={divs?.id}
                                data={divs}
                                classId={classes.id}
                              />
                            );
                          }
                        )}
                        <button
                          disabled={isLoading}
                          className="col cursor-pointer hover:shadow-xl mt-4"
                          onClick={() => {
                            handleAddDivision(classes);
                          }}
                        >
                          <div
                            id="manageclasses-newdivision"
                            className="shadow-lg relative rounded-lg py-11 px-6 bg-white dark:bg-gray-800 cw-200"
                          >
                            <div className="flex flex-col justify-center items-center">
                              <img
                                src={plusSign}
                                height={36}
                                width={36}
                                alt="Add Division"
                              />
                            </div>
                            <div className="flex flex-col justify-center">
                              <p className="text-lg text-gray-500 dark:text-white mt-2 text-center font-medium">
                                Add Division
                              </p>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </>
        )}
      </div>
      <Modal
        visible={isImportSuccessModalVisible}
        centered
        footer={null}
        onCancel={() => setIsImportSuccessModalVisible(false)}
        closeIcon={
          <CloseCircleFilled
            style={{
              color: "#EB5757",
              fontSize: "24px",
            }}
          />
        }
        width={500}
      >
        <div className="flex flex-col bg-white rounded-md items-center text-center">
          <img alt="check" src={checkIcon} className="h-16 w-16" />
          <p className="text-lg text-black font-bold mt-6">
            The classes and divisions from the previous year imported
            succesfully.
          </p>
          <p className="mt-6 text-lg text-gray-500">
            Please visit{" "}
            <strong className="text-black">Settings{">"}Edit</strong> to know
            more about student promotion process
          </p>
          <Button
            onClick={goToSettings}
            buttonStyle="primary"
            className="w-32 mt-8"
          >
            Go to Settings
          </Button>
        </div>
      </Modal>
    </Spin>
  );
};
export default ClassesView;
