import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CopyOutlined } from "@ant-design/icons";
import { notification } from "antd";

const ONE_SECOND = 1000; // In millis
const ONE_MINUTE = 60 * ONE_SECOND;

export default function CopyCard({ text }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsVisible(false), ONE_MINUTE);
  }, []);

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(text);
      notification["success"]({
        message: "Successful",
        description: "Pin Copied successfully",
        duration: 1.5,
      });
    } catch (err) {
      notification["error"]({
        message: "Failure",
        description: "Pin wasn't copied",
        duration: 1.5,
      });
    }
  };
  return (
    <>
      {isVisible ? (
        <div
          className="border-greyBorder border mr-4 flex items-center px-3 cursor-pointer rounded-md"
          onClick={copyText}
        >
          <p className="mr-2 text-greyLight">{text}</p>
          <CopyOutlined className="text-greyLight" />
        </div>
      ) : null}
    </>
  );
}

CopyCard.propTypes = {
  text: PropTypes.string,
};
