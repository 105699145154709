import React from "react";
import PropTypes from "prop-types";

function Button({
  buttonId,
  onClick,
  buttonStyle,
  className,
  disabled,
  children,
  ...props
}) {
  const defaultTypeClasses =
    "py-3 px-3 whitespace-nowrap flex justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md";
  const primaryTypeClasses =
    "py-3 px-4 whitespace-nowrap flex justify-center items-center bg-primary focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md";
  const secondaryTypeClasses =
    "py-3 px-4 whitespace-nowrap flex justify-center items-center bg-secondary hover:bg-pink-500 focus:ring-pink-500 focus:ring-offset-pink-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md";
  const successTypeClasses =
    "py-3 px-4 whitespace-nowrap flex justify-center items-center bg-success focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md";
  const dangerTypeClasses =
    "py-3 px-4 whitespace-nowrap flex justify-center items-center bg-cancel focus:ring-cancel-500 focus:ring-offset-cancel-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md";
  const disabledClasses = disabled ? "bg-opacity-20 text-opacity-100" : "";
  const disabledDefaultClasses = disabled
    ? "bg-opacity-20 text-opacity-100 text-gray-300"
    : "";

  const handleOnClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
    id={buttonId}
      type="button"
      onClick={handleOnClick}
      disabled={disabled}
      className={
        buttonStyle === "primary"
          ? `${primaryTypeClasses} ${className} ${disabledClasses}`
          : buttonStyle === "secondary"
          ? `${secondaryTypeClasses} ${className} ${disabledClasses}`
          : buttonStyle === "danger"
          ? `${dangerTypeClasses} ${className} ${disabledClasses}`
          : buttonStyle === "success"
          ? `${successTypeClasses} ${className} ${disabledClasses}`
          : `${defaultTypeClasses} ${className} ${disabledDefaultClasses}`
      }
      {...props}
    >
      {children}
    </button>
  );
}

Button.prototypes = {
  onClick: PropTypes.func,
  buttonStyle: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
