import { Drawer } from "antd";
import CloseIcon from "../../../components/closeIcon";
import { DATE_FORMATS } from "../../../utils/constants";
import { STAFF_TYPE } from "../../../utils/constants";
import moment from "moment";
import useStaffSummary from "../../../hooks/useStaffSummary";

const StaffDrawer = ({ isOpen, setIsOpen, authId, selectedMonth }) => {
  const { data: drawerData } = useStaffSummary({
    authId,
    month: selectedMonth?.format(DATE_FORMATS.MMMMYYYY),
  });

  const lastMonth =
    drawerData?.absentDays?.[(drawerData?.absentDays?.length ?? 1) - 1] ?? [];
  const lastAbsent =
    lastMonth?.days?.[(lastMonth?.days?.length ?? 1) - 1]?.date || "-";
  const staffTypeText =
    drawerData?.staffType === STAFF_TYPE.TEACHING
      ? "Teacher"
      : "Non-Teaching Staff";
  const overallAttendance =
    (drawerData?.workingDays ?? 0) / (drawerData?.presentDays ?? 1);

  return (
    <Drawer
      title={
        <div className="text-lg leading-7 font-medium">Attendance Details</div>
      }
      placement="right"
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      destroyOnClose
      contentWrapperStyle={{
        width: "35%",
      }}
      headerStyle={{
        height: "76px",
        backgroundColor: "#F3F4F6",
      }}
      bodyStyle={{
        padding: 0,
        backgroundColor: "#F3F4F6",
      }}
      closeIcon={<CloseIcon />}
    >
      <div className="h-full">
        <div className="pl-6 pt-6 pr-6">
          <p className="text-gray-900 text-xl font-bold">{drawerData?.name}</p>
          <p className="text-gray-500">{staffTypeText}</p>
          <div className="flex justify-between mt-4 text-center bg-white rounded-xl pt-4 pr-5 pl-5 pb-5">
            <div className="flex flex-col gap-y-3">
              <p className="text-gray-500 text-sm font-normal">
                {selectedMonth?.format(DATE_FORMATS.MMMM)}
              </p>
              <p className="text-black text-lg font-semibold">{`${drawerData?.monthAttendance}%`}</p>
            </div>
            <div className="flex flex-col gap-y-3">
              <p className="text-gray-500 text-sm font-normal">Overall</p>
              <p className="text-black text-lg font-semibold">
                {`${overallAttendance?.toFixed(2)}%`}
              </p>
            </div>
            <div className="flex flex-col gap-y-3">
              <p className="text-gray-500 text-sm font-normal">Last Absent</p>
              <p className="text-black text-lg font-semibold">
                {lastAbsent !== "-"
                  ? moment(lastAbsent).format(DATE_FORMATS.DDSMMMSYY)
                  : lastAbsent}
              </p>
            </div>
          </div>
          <div className="mt-7 mb-4 text-gray-900 font-semibold">
            <p>Absent Days for {drawerData?.acadamicYearTitle}</p>
          </div>
        </div>

        <div className="bg-white rounded-3xl h-full pl-8 pt-5">
          {drawerData?.absentDays?.map((absentDate) => (
            <div className="flex flex-col gap-y-3 mb-8 w-full">
              <div className="rounded-md">
                <span className="text-center bg-red-100 rounded-md px-5 py-1 text-red-700 text-sm font-normal">
                  {absentDate.month}
                </span>
              </div>
              <div className="flex flex-col gap-y-3">
                {absentDate.days.map((absentDay) => (
                  <div className="flex gap-x-9">
                    <p className="text-gray-900 text-sm font-normal">
                      {moment(absentDay?.date, DATE_FORMATS.YYYYMMDD).format(
                        DATE_FORMATS.DDMMMYYYY
                      )}
                    </p>
                    <p className="text-gray-500">
                      {moment(absentDay?.date, DATE_FORMATS.YYYYMMDD).format(
                        DATE_FORMATS.DAY
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default StaffDrawer;
