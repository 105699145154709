import React from "react";
import { Switch as AntDSwitch } from "antd";

function Switch({
  checked,
  defaultChecked,
  onChange,
  type,
  checkedChildren,
  unCheckedChildren,
  disabled,
  ...rest
}) {
  const checkedValue = checked === undefined ? defaultChecked : checked;
  const primaryBackgroundColor =
    type === "primary" && checkedValue ? "#27AE60" : "#EB5757";
  let defaultBackgroundColor =
    type === "default" && checkedValue ? "#4F46E5" : "#E5E7EB";
  const backgroundColor =
    type === "primary" ? primaryBackgroundColor : defaultBackgroundColor;
  return (
    <AntDSwitch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      defaultChecked={defaultChecked}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      style={{
        backgroundColor: disabled && !checkedValue ? null : backgroundColor,
      }}
      {...rest}
    />
  );
}

export default Switch;
