/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const getFeesStructures = async () => {
  const token = localStorage.getItem("token");
  const schoolId = getSchoolID();
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/getFeeStructures`,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      schoolId,
    },
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useGetFeesStructures() {
  return useQuery(["feesStructures"], () => getFeesStructures());
}
