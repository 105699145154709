import React, { useState } from "react";
// import { PlusIcon } from "@heroicons/react/solid";
import { Spin } from "antd";
import { DownloadIcon } from "@heroicons/react/outline";
import Button from "../../../components/Button";
import FeesBalancesTable from "./FeesBalancesTable";
import { Dropdown } from "../../../components";
import CustomMultiSelect from "../../../components/CustomMultiSelect";
import ClassDivisionMulti from "../../../components/ClassDivisionSelector/ClassDivisionMulti";
// import { useNavigate } from "react-router-dom";
import { getSchoolID } from "../../../shared/getSchoolID";
// import { DATE_FORMATS } from "../../../utils/constants";
import useFilterOptions from "../../../hooks/useFilterOptions";
import useFeeBalanceReport from "../../../hooks/useFeeBalanceReport";
import useNotificationForFeeBalance from "../../../hooks/useNotificationForFeeBalance";
import useTracking from "../../../hooks/useTracking";
// import settings from "../../../assets/images/settings.png";
import useFeeSummary from "../../../hooks/useFeeSummary";

const PAGE_SIZE = 10;

function FeesDashboard() {
	const defaultFilterMap = {
		schoolId: getSchoolID(),
		classId: null,
		divisionIds: null,
		studentName: null,
		status: null,
	};
	const [feeDataForNotification, setFeeDataForNotification] = useState([]);
	// const navigate = useNavigate();
	const searchDebounce = React.useRef(null);
	const { data: filterOptions } = useFilterOptions(
		{
			schoolId: getSchoolID(),
			entity: "feeDashboard",
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (filterData) => {
				initFilter(filterData);
			},
		},
	);
	const [balanceStudentName, setBalanceStudentName] = useState(null);
	const [balanceFilterData, setBalanceFilterData] =
		useState(defaultFilterMap);
	const [isLoading, setIsLoading] = useState(false);
	
	const { mutateAsync: downloadBalanceReport } = useFeeBalanceReport({
		onSettled: () => {
			setIsLoading(false);
		},
	});
	useNotificationForFeeBalance(
		{
			feeBalanceData: feeDataForNotification,
		},
		{
			onSettled: (data) => {
				setFeeDataForNotification([]);
			},
		},
	);
	const { data: summaryData } = useFeeSummary(balanceFilterData, {
		refetchOnWindowFocus: false,
		enabled: balanceFilterData?.divisionIds?.length > 0,
	});
	const { trackEvent } = useTracking();

	const [totalAmount, remainingAmount, amountPaidCount, totalStudentCount] =
		React.useMemo(() => {
			const ta = (summaryData?.collection || 0).toLocaleString();
			const ra = (summaryData?.remaining || 0).toLocaleString();
			const ac = summaryData?.full_paid_count || 0;
			const ts = summaryData?.total_student || 0;
			return [ta, ra, ac, ts];
		}, [summaryData]);

	const handleStudentNameChange = (_studentName) => {
		clearTimeout(searchDebounce.current);
		setBalanceStudentName(_studentName);
		searchDebounce.current = setTimeout(() => {
			setBalanceFilterData({
				...balanceFilterData,
				studentName: _studentName,
				studentId: null,
			});
		}, 650);
	};

	const handleDivisionChangeBalance = (divisionIds) => {
		// setFilterData({
		// 	...filterData,
		// 	divisionId,
		// 	feeStructureId: null,
		// 	studentId: null,
		// });
		if (divisionIds?.length) {
			setBalanceFilterData({
				...balanceFilterData,
				divisionIds: divisionIds,
			});
		} else if (balanceFilterData?.classIds?.length) {
			handleClassChangeBalance(balanceFilterData?.classIds);
		}
	};

	const handleClassChangeBalance = (classIds) => {
		// if(balanceFilterData?.classId === classId) {
		// 	return;
		// }
		const divisionIds = [];
		filterOptions?.divisions?.forEach((d) => {
			if (classIds?.length) {
				if (classIds.includes(d?.class_id)) {
					divisionIds.push(d.value);
				}
			} else {
				divisionIds.push(d.value);
			}
		});
		setBalanceFilterData({
			...balanceFilterData,
			classIds,
			divisionIds,
		});
	};

	const handleStatusChange = (status) => {
		setBalanceFilterData({
			...balanceFilterData,
			status,
		});
	};

	const handleDownloadBalanceReport = async (params = null) => {
		setIsLoading(true);
		await downloadBalanceReport(balanceFilterData);
	};

	const handleRemindParents = (collection) => {
		const notificationPayload = {
			studentId: collection?.studentId,
			remainingAmount: collection?.remainingAmount,
		};
		trackEvent("Remind Parents", {
			collection,
			notificationPayload,
		});
		setFeeDataForNotification([
			...feeDataForNotification,
			notificationPayload,
		]);
	};

	// const remindFilteredParents = async () => {
	// 	setIsLoading(true);
	// 	await remindFiltered(balanceFilterData);
	// };

	const initFilter = (data) => {
		const divisionIds = [];
		data?.divisions?.forEach((d) => {
			divisionIds.push(d.value);
		});
		setBalanceFilterData({
			...balanceFilterData,
			divisionIds,
		});
	};

	

	return (
		<div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
			<Spin spinning={isLoading}>
				<div className="flex flex-row gap-x-4 items-center mb-8">
					<p className="text-2xl text-left font-bold flex-col m-0">
						Fees Dashboard
					</p>
				</div>
				<div className="flex items-center justify-between mb-8 w-100">
					<div
						className="h-28 shadow-sm bg-white rounded-md p-5"
						style={{ width: "30%" }}
					>
						<p class="text-md text-gray-500 dark:text-white text-left">
							Total Fee Collected
						</p>
						<h2 class="text-black-700 dark:text-gray-100 text-2xl text-left font-bold my-1">
							₹ {totalAmount}
						</h2>
					</div>
					<div
						className="h-28 shadow-sm bg-white rounded-md p-5"
						style={{ width: "30%" }}
					>
						<p class="text-md text-gray-500 dark:text-white text-left">
							Fee Remaining
						</p>
						<h2 class="text-black-700 dark:text-gray-100 text-2xl text-left font-bold my-1">
							₹ {remainingAmount}
						</h2>
					</div>
					<div
						className="h-28 shadow-sm bg-white rounded-md p-5"
						style={{ width: "30%" }}
					>
						<p class="text-md text-gray-500 dark:text-white text-left">
							Students who paid complete fees
						</p>
						<h2 class="text-black-700 dark:text-gray-100 text-2xl text-left font-bold my-1">
							{amountPaidCount}/{totalStudentCount}
						</h2>
					</div>
				</div>
				<div className="flex items-start justify-between">
					<form
						className="flex w-auto flex-col items-start md:flex-row md:space-x-3 space-y-3 md:space-y-0"
						onSubmit={(e) => e.preventDefault()}
						autoComplete="off"
					>
						<div className="relative pl-2">
							<span class="absolute inset-y-0 left-0 flex items-center pl-4">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
										fill="#9CA3AF"
									/>
								</svg>
							</span>
							<input
								type="text"
								style={{ width: 250 }}
								className="pl-10 rounded-md border-transparent appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
								placeholder="Search by student name"
								value={balanceStudentName}
								onChange={(e) => {
									handleStudentNameChange(e.target.value);
								}}
							/>
						</div>
					</form>
					<div className="flex flex-row gap-x-5">
						<Button
							onClick={() => handleDownloadBalanceReport()}
							buttonStyle="success"
							className="py-3 px-4"
						>
							<DownloadIcon className="h-4 w-4" />
							<span className="ml-2">Download Report</span>
						</Button>
						<ClassDivisionMulti
							classDefaultValue="Class : All"
							classes={filterOptions?.classes}
							divisions={filterOptions?.divisions}
							allOptionNeeded
							onClassChange={handleClassChangeBalance}
							onDivisionChange={handleDivisionChangeBalance}
						/>
						<CustomMultiSelect
							selected={balanceFilterData?.status || []}
							type="primary"
							filterType={"Filter by: Fee Status"}
							options={filterOptions?.statusList || []}
							onChange={handleStatusChange}
						/>
						{/*<Button
							buttonStyle="primary"
							onClick={remindFilteredParents}
							style={{ display: "inline" }}
						>
							<span className="flex justify-center items-center gap-x-1">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-4 w-4"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								Remind All
							</span>
						</Button>*/}
					</div>
				</div>
				<div className="overflow-x-hidden">
					<FeesBalancesTable
						filterData={balanceFilterData}
						pageLimit={PAGE_SIZE}
						handleRemindParent={handleRemindParents}
					/>
				</div>
			</Spin>
		</div>
	);
}

export default FeesDashboard;