import React from "react";
import Table from "../../../components/BasicTable";
import Switch from "../../../components/Switch";
import { getAvatar } from "../../../shared/getAvatar";

const Badge = ({ type }) => {
  return (
    <div
      className={`w-3 h-3 text-base border border-black  rounded-full text-white mr-2 ${
        type === "green" ? "c-bg-green" : "c-bg-red"
      }`}
    ></div>
  );
};

function PromotionsTable({
  students,
  allExams,
  onStudentPromotionChange,
  promotionFormData,
  isPromoted,
}) {
  const renderOverallPercentage = (student) => {
    // map over all exams and sum up the marks
    const totalMarksForAllExams = allExams?.reduce((acc, curr) => {
      const mark = curr?.total_marks_this_exam?.aggregate?.sum?.total_marks;
      if (mark && !isNaN(mark)) {
        return acc + mark;
      }
      return acc;
    }, 0);

    const studentTotalMarksInAllExams =
      student?.marks_in_all_exams?.aggregate?.sum?.mark;
    // return blank if not number
    if (
      totalMarksForAllExams &&
      studentTotalMarksInAllExams &&
      !isNaN(totalMarksForAllExams) &&
      !isNaN(studentTotalMarksInAllExams)
    ) {
      // calculate percentage
      const percentage =
        (studentTotalMarksInAllExams / totalMarksForAllExams) * 100;
      return `${percentage.toFixed(2)}%`;
    }
    return "NA";
  };

  const theadData = [
    "Name",
    "Mother's Name",
    "Overall %",
    "Promote / Not Promote",
  ];

  const tableData = students?.map((divisionStudent) => {
    const { student } = divisionStudent;
    return {
      items: [
        <div className="flex items-center">
          <div className="flex-shrink-0">{getAvatar(student?.full_name)}</div>
          <div className="ml-3">
            <p className="text-black font-medium whitespace-no-wrap">
              {student?.full_name}
            </p>
          </div>
        </div>,
        student?.mother_name,
        renderOverallPercentage(student),
        <Switch
          checked={promotionFormData?.[student?.id]?.isPromoted}
          type="primary"
          onChange={(value) => onStudentPromotionChange(student, value)}
          disabled={isPromoted}
        />,
      ],
    };
  });

  return (
    <>
      <div className="flex flex-row justify-center gap-4 align-middle justify-items-center">
        <span className="flex items-center text-black font-normal">
          <Badge type="green" /> Promoted
        </span>
        <span className="flex items-center text-black font-normal">
          <Badge type="red" /> Not Promoted
        </span>
      </div>
      <Table theadData={theadData} tbodyData={tableData} />
    </>
  );
}

export default PromotionsTable;
