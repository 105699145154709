import { useState } from "react";
import axios from "axios";

const useTranslation = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async ({ textObj, sourceLang, targetLang }) => {
    try {
      const translateKeys = [];
      const textList = [];
      // assign keys of textObj to translateKeys and values to textList at their corresponding index
      for (const key in textObj) {
        translateKeys.push(key);
        if (textObj[key]) {
          textList.push(textObj[key]);
        } else {
          textList.push("-");
        }
      }
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_NODE_ENDPOINT}/translateText`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          contents: textList,
          sourceLanguageCode: sourceLang,
          targetLanguageCode: targetLang,
        },
      };
      const translatedObj = {};
      const res = await axios.request(config);
      res?.data?.forEach((item, index) => {
        if (item?.translatedText !== "-") {
          translatedObj[translateKeys[index]] = item?.translatedText;
        } else {
          translatedObj[translateKeys[index]] = "";
        }
      });
      setData(translatedObj);
      return translatedObj;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return [fetchData, { data, error, loading, refetch: fetchData }];
};

export default useTranslation;
