import React from "react";
import wohoo from "../../assets/images/wohoo_teacher.png";
import { getAvatar } from "../../shared/getAvatar";
import StaffBadge from "../../components/StaffBadge";

const TeachersOnLeaveCard = ({ teachersOnLeave }) => {
  return (
    <div className="shadow-lg rounded-lg bg-white dark:bg-gray-800 h-150 overflow-y-auto">
      {teachersOnLeave?.length > 0 ? (
        <div className="flex flex-col flex-wrap">
          {teachersOnLeave?.map((clas, index) => {
            const teacherNames = clas?.teacherName?.split(" ");
            const firstName = teacherNames?.[0];
            const lastName = teacherNames?.[teacherNames?.length - 1];

            return (
              <div
                key={index}
                className={`${
                  index === 2 ? "" : "border-b border-gray-300"
                } flex-row gap-4 flex flex-1 justify-between items-center px-4 pt-3 pb-3`}
              >
                <div className="flex-shrink-0">
                  {getAvatar(firstName, lastName, clas?.image, "small")}
                </div>
                <div className=" flex flex-col">
                  <span className="text-gray-900 dark:text-white text-sm font-semibold">
                    {clas.teacherName}
                  </span>
                </div>
                <div className="flex flex-1 justify-end">
                  <div>
                    <StaffBadge staffType={clas?.staffType} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex items-end h-full">
          <div className="w-1/3 mt-5 font-semibold text-base leading-6 self-start ml-3 text-left">
            All teachers are present today
          </div>
          <div className="w-2/3 mb-0 pb-0 object-fit h-full flex">
            <img src={wohoo} alt="" className=" max-h-full self-end " />
          </div>
        </div>
      )}
    </div>
  );
};

export default TeachersOnLeaveCard;
