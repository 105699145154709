import React, { useState, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { Select, Spin } from "antd";
import useGetEnquiryData from "../../hooks/useGetEnquiryData";
import useGetEnquiryAdmissionForm from "../../hooks/useGetEnquiryAdmissionForm";
import useGetAdmissionFormPDF from "../../hooks/useGetAdmissionFormPDF";
import useUpdateEnquiryStatus from "../../hooks/useUpdateEnquiryStatus";
import Button from "../../components/Button";
import { getS3Image } from "../../shared/s3";
import moment from "moment";
import AcceptEnquiry from "./AcceptEnquiry";

const statusOptions = [
	{
		label: "Incomplete",
		value: "incomplete",
	},
	{
		label: "Pending",
		value: "pending",
	},
	{
		label: "Shortlisted",
		value: "shortlisted",
	},
	{
		label: "Interviewed",
		value: "interviewed",
	},
	{
		label: "Rejected",
		value: "rejected",
	},
	{
		label: "Accepted",
		value: "accepted",
	},
];

const AdmissionFormView = () => {
	const { formId } = useParams();
	const selectRef = useRef();
	const [isAccepted, setIsAccepted] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(null);
	const [acceptEnquiryModalVisible, setAcceptEnquiryModalVisible] =
		useState(false);
	const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
	const { data: enquiryData } = useGetEnquiryData(
		{
			admissionEnquiryId: formId,
		},
		{
			onSuccess: () => {
				setCurrentStatus(enquiryData?.enquiryStatus);
			},
		}
	);
	const { data: formSections = [] } = useGetEnquiryAdmissionForm({
		admissionEnquiryId: formId,
	});
	const { mutateAsync: downloadAdmissionForm } = useGetAdmissionFormPDF();
	const { mutateAsync: updateEnquiryStatus } = useUpdateEnquiryStatus();
	const studentName = useMemo(() => {
		if (formSections?.length) {
			const basicSection = formSections[0];
			const student = (basicSection?.fields || []).find(
				(f) => f.id === "studentName"
			);
			return student?.value || "";
		}
		return "Loading...";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formSections?.length]);
	const isDisabled = enquiryData?.enquiryStatus === "accepted" || isAccepted;

	const editForm = () => {
		window.open("/v2/edit-enquiry/" + formId, "_blank");
	};

	const viewFile = (fileId) => {
		if (!fileId) {
			return;
		}
		window.open(getS3Image(fileId), "_blank");
	};

	const handleChangeStatus = async (newStatus) => {
		if (newStatus === "accepted") {
			setAcceptEnquiryModalVisible(true);
			return;
		}
		try {
			setIsUpdatingStatus(true);
			const response = await updateEnquiryStatus({
				admissionEnquiryId: formId,
				enquiryStatus: newStatus,
			});
			if (response?.success) {
				setCurrentStatus(newStatus);
			} else {
				resetStatus();
			}
		} catch (err) {
			resetStatus();
		} finally {
			setIsUpdatingStatus(false);
		}
	};

	const downloadForm = async () => {
		try {
			setIsUpdatingStatus(true);
			const firstName = studentName?.split(" ")?.[0] || "";
			await downloadAdmissionForm({
				admissionEnquiryId: formId,
				fileName: `AdmissionForm_${formId}_${firstName}`,
			});
		} catch (err) {
			// Ignore
		} finally {
			setIsUpdatingStatus(false);
		}
	};

	const resetStatus = () => {
		setCurrentStatus(currentStatus || enquiryData?.enquiryStatus);
	};

	const handleAcceptEnquiry = async (divisionId) => {
		try {
			setIsUpdatingStatus(true);
			const response = await updateEnquiryStatus({
				admissionEnquiryId: formId,
				enquiryStatus: "accepted",
				divisionId,
			});
			if (response?.success) {
				setIsAccepted(true);
				setCurrentStatus("accepted");
			} else {
				resetStatus();
			}
		} catch (err) {
			resetStatus();
		} finally {
			setIsUpdatingStatus(false);
		}
	};

	const renderField = (field) => {
		switch (field?.type) {
			case "avatarInput":
			case "studentPhoto":
			case "uploadFile":
				return (
					<div
						key={field.id}
						className="w-full pb-5 mt-5 border-b border-slate-600 flex flex-row"
					>
						<div className="text-left text-base w-1/2">
							{field.label}
						</div>
						<div
							className="cursor-pointer text-left text-base w-1/2 font-bold"
							onClick={() => viewFile(field?.value)}
						>
							{field?.value ? "View File" : "Not Uploaded"}
						</div>
					</div>
				);
			case "schoolClasses":
				const cl = field.options?.find((o) => o.value == field.value);
				return (
					<div
						key={field.id}
						className="w-full pb-5 mt-5 border-b border-slate-600 flex flex-row"
					>
						<div className="text-left text-base w-1/2">
							{field.label}
						</div>
						<div className="text-left text-base w-1/2 font-bold">
							{cl ? cl.label : ""}
						</div>
					</div>
				);
			case "dateInput":
				const m = moment(field.value);
				return (
					<div
						key={field.id}
						className="w-full pb-5 mt-5 border-b border-slate-600 flex flex-row"
					>
						<div className="text-left text-base w-1/2">
							{field.label}
						</div>
						<div className="text-left text-base w-1/2 font-bold">
							{m.isValid() ? m.format("DD/MM/YYYY") : ""}
						</div>
					</div>
				);
			default:
				return (
					<div
						key={field.id}
						className="w-full pb-5 mt-5 border-b border-slate-600 flex flex-row"
					>
						<div className="text-left text-base w-1/2">
							{field.label}
						</div>
						<div className="text-left text-base w-1/2 font-bold">
							{field.value}
						</div>
					</div>
				);
		}
	};

	const renderFormSection = (section) => {
		return (
			<div
				className="mt-10 flex flex-row justify-between w-full"
				key={section.sectionId}
			>
				<h3 className="m-0 font-bold text-lg">
					{section.sectionTitle}
				</h3>
				<div className="p-5 bg-pureWhite border rounded-md border-slate-600 w-2/3">
					{section.fields.map(renderField)}
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col items-start ml-8 mt-6 pr-4 mb-6 pb-10 h-screen w-screen overflow-x-hidden relative">
			<div className="flex flex-row items-center">
				<h2 className="text-2xl text-left font-bold flex-col m-0 mr-5">
					{studentName}
				</h2>
			</div>
			<Spin spinning={isUpdatingStatus} wrapperClassName="w-full">
				<div className="mt-5 flex flex-row justify-between w-full">
					<div className="flex flex-row gap-x-8">
						{enquiryData?.id ? (
							<Select
								ref={selectRef}
								className="w-64 customised-antd-selector-controlled"
								value={currentStatus}
								onChange={handleChangeStatus}
								size="large"
								allowClear
								placeholder="Select Status"
								disabled={isDisabled}
								options={statusOptions}
								defaultValue={enquiryData?.enquiryStatus}
							/>
						) : null}
						<div className="h-full flex flex-col w-48 border items-start justify-center pl-2 rounded-md shadow-sm border-slate-600 border-solid bg-pureWhite">
							<span className="font-normal">
								Enquiry ID:{" "}
								<span className="font-bold">00{formId}</span>
							</span>
						</div>
					</div>
					<div className="flex flex-row">
						<Button
							id="edit-form"
							buttonStyle="success"
							onClick={downloadForm}
						>
							Download Form
						</Button>
						<div className="mx-2" />
						<Button
							id="edit-form"
							buttonStyle="primary"
							onClick={editForm}
						>
							Edit Details
						</Button>
					</div>
				</div>
				{(formSections || []).map(renderFormSection)}
			</Spin>
			<AcceptEnquiry
				classId={enquiryData?.classId}
				onSelect={handleAcceptEnquiry}
				visible={acceptEnquiryModalVisible}
				onClose={() => {
					setAcceptEnquiryModalVisible(false);
				}}
			/>
		</div>
	);
};

export default AdmissionFormView;