/* eslint-disable no-console */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const upsertNonTeachingStaff = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/upsertNonTeachingStaff`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpsertNonTeachingStaff(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => upsertNonTeachingStaff(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "Non Teaching Staff created/updated.",
        duration: 1.5,
      });
      queryClient.invalidateQueries("teacherAttendance");
    },
    onError: (error) => {
      const errorRes = error?.response?.data;
      notification["error"]({
        message: "Failure",
        description:
          errorRes?.message ||
          "Could not insert/update Non Teaching Staff access",
        duration: 1.5,
      });
    },
    ...options,
  });
}
