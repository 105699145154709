import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_CLASSES_LIST } from "../../graphql/queries";
import { getSchoolID } from "../../shared/getSchoolID";
import { getSortedClasses } from "../../helpers";
import { Select, Spin, Modal, DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getAvatar } from "../../shared/getAvatar";
import useTeacherAttendance from "../../hooks/useTeacherAttendance";
import useSaveTeacherAttendance from "../../hooks/useSaveTeacherAttendance";
import useCheckStaffHoliday from "../../hooks/useCheckStaffHoliday.js";
import { Switch } from "../../components";
import useTracking from "../../hooks/useTracking";
import moment from "moment";
import { InfoCircleFilled } from "@ant-design/icons";
import { DATE_FORMATS } from "../../utils/constants";
import StaffBadge from "../../components/StaffBadge";
import { Button } from "../../components";
import successImage from "../../assets/images/success.png";
import failureImage from "../../assets/images/failure.png";
import noResult from "../../assets/images/no-result.png";
import noResultCurve from "../../assets/images/no-result-curve.png";
import { useNavigate } from "react-router-dom";
import useAllRemarks from "../../hooks/useAllRemarks";

const AUTO_CLOSE_TIMEOUT = 2000;
const DEFAULT_PRESENT_REMARK = "present in school";

const Badge = ({ type }) => {
  return (
    <span
      className={`w-3 h-3 text-base border border-black  rounded-full text-white mr-2 ${
        type === "present" ? "c-bg-green" : "c-bg-red"
      }`}
    ></span>
  );
};

const Toggle = ({ data, onChange }) => {
  return (
    <Switch
      type="primary"
      checked={data.isPresent}
      onChange={(value) => onChange(value, data.id)}
      className="h-6 rounded-full w-11"
      aria-label="switch"
    >
      <span className="sr-only">{data.name}</span>
      <span
        className={`transform transition ease-in-out duration-400 ${
          data.selected ? "translate-x-6" : "translate-x-1"
        } inline-block w-4 h-4 transform bg-white rounded-full`}
      />
    </Switch>
  );
};

const hasTeacherJoinedBeforeAttendanceDate = (
  staffJoiningDate,
  attendanceDate
) => {
  const areDatesSame =
    staffJoiningDate.format(DATE_FORMATS.DDMMMYYY) ===
    attendanceDate.format(DATE_FORMATS.DDMMMYYY);

  if (staffJoiningDate.isBefore(attendanceDate) || areDatesSame) {
    return true;
  }
  return false;
};

const StaffAddedNote = ({ staffJoiningDate, attendanceDate }) => {
  if (hasTeacherJoinedBeforeAttendanceDate(staffJoiningDate, attendanceDate)) {
    return null;
  }

  return (
    <div
      className="flex p-2 bg-backgroundGreyLight rounded-md mr-12 absolute"
      style={{ width: 220 }}
    >
      <InfoCircleFilled style={{ color: "#6B7280" }} />
      <p className="text-xs text-left ml-2 text-textGrey">
        Please note that the staff was added on{" "}
        {staffJoiningDate?.format(DATE_FORMATS.DDMMMYYYY)}
      </p>
    </div>
  );
};

const TeacherAvailability = () => {
  const [search, setSearch] = useState("");
  const [classFilter, setClassFilter] = useState(-1);
  const [teacherData, setTeacherData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [staffAttendnaceDate, setStaffAttendanceDate] = useState(moment());
  const [isStaffHoliday, setIsStaffHoliday] = useState(false);

  const navigate = useNavigate();

  const schoolId = getSchoolID();

  const { data: remarksData, isLoading: areReasonsLoading } = useAllRemarks();
  const absentReasons = remarksData?.absentReasons || [];
  const presentReasons = remarksData?.presentReasons || [];

  const { data, isLoading, refetch } = useTeacherAttendance(
    {
      schoolId,
      date: staffAttendnaceDate.format(DATE_FORMATS.YYYYMMDD),
    },
    {
      onSuccess: (serverData) => {
        const data = serverData?.data;
        if (data?.hasAttendanceBeenSaved) {
          setTeacherData(data?.teacherData);
          return;
        }

        // Attendance is not saved, so by default isPresent false for late joining teachers
        const updatedTeachersList = data?.teacherData?.map((teacher) => {
          const teacherJoiningDate = moment(teacher?.createdAt);
          if (
            hasTeacherJoinedBeforeAttendanceDate(
              teacherJoiningDate,
              staffAttendnaceDate
            )
          ) {
            return teacher;
          }
          return { ...teacher, isPresent: false };
        });
        setTeacherData(updatedTeachersList);
      },
    }
  );

  useCheckStaffHoliday(
    {
      date: staffAttendnaceDate.format(DATE_FORMATS.YYYYMMDD),
    },
    {
      onSuccess: (staffSuccessData) => {
        const isHolidayOnGivenDate = staffSuccessData?.holiday || false;
        setIsStaffHoliday(isHolidayOnGivenDate);
      },
    }
  );

  const { mutate: saveTeacherAttendance } = useSaveTeacherAttendance({
    onSuccess: () => {
      refetch();
      setIsModalVisible(true);
      setTimeout(() => setIsModalVisible(false), AUTO_CLOSE_TIMEOUT);
    },
  });

  const { trackEvent } = useTracking();

  const onChange = (authId) => {
    const newTeacherData = teacherData?.map((teacher) => {
      if (teacher?.authId !== authId) return teacher;
      return {
        ...teacher,
        isPresent: !teacher?.isPresent,
        remarks: null,
      };
    });
    setTeacherData(newTeacherData);
  };

  const handleSickChange = (authId, leaveReason) => {
    const newTeacherData = teacherData?.map((teacher) => {
      if (teacher?.authId !== authId) return teacher;
      return {
        ...teacher,
        remarks: leaveReason,
      };
    });
    setTeacherData(newTeacherData);
  };

  const { data: clData, loading } = useQuery(GET_CLASSES_LIST, {
    variables: {
      schoolId,
    },
  });

  const spinnerLoading = isLoading || loading || areReasonsLoading;

  const filteredByClass =
    parseInt(classFilter) === -1
      ? teacherData
      : teacherData?.filter(({ classes }) =>
          classes?.includes(parseInt(classFilter))
        );

  const filterByClassAndName =
    search?.trim() === ""
      ? filteredByClass
      : filteredByClass?.filter(({ teacherName }) =>
          teacherName?.toLowerCase()?.indexOf(search?.toLowerCase()?.trim()) ===
          -1
            ? false
            : true
        );

  const isSaved = data?.data?.hasAttendanceBeenSaved;
  const lastSaved = data?.data?.lastSavedOn;

  const handleSave = () => {
    trackEvent("Teacher Attendance", {
      schoolId,
      date: staffAttendnaceDate.format(DATE_FORMATS.YYYYMMDD),
    });
    const teacherDataPayload = teacherData?.map((teacher) => {
      return {
        user_id: teacher?.authId,
        is_present: teacher?.isPresent,
        date: staffAttendnaceDate.format(DATE_FORMATS.YYYYMMDD),
        staffType: teacher?.staffType,
        remarks: teacher?.remarks,
      };
    });

    const teacherAbsentWithoutReasonCount = teacherDataPayload?.reduce(
      (acc, { is_present, remarks }) => {
        let newValue = acc;
        if (is_present === false && remarks === null) {
          newValue += 1;
        }
        return newValue;
      },
      0
    );

    if (teacherAbsentWithoutReasonCount === 0) {
      saveTeacherAttendance({
        teacherData: teacherDataPayload,
      });
    } else {
      setIsErrorModalVisible(true);
    }
  };

  const convertHolidayToWorkingDay = () => {
    setIsStaffHoliday(false);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handlePrevClick = () => {
    const prevDay = staffAttendnaceDate.clone().subtract(1, "days");
    setStaffAttendanceDate(prevDay);
    setIsStaffHoliday(false);
  };

  const handleNextClick = () => {
    const nextDay = staffAttendnaceDate.clone().add(1, "days");
    if (!disabledDate(nextDay)) {
      setStaffAttendanceDate(nextDay);
      setIsStaffHoliday(false);
    }
  };

  const handleCheckReportsClick = () => {
    navigate("/reports/staffReport", {
      state: {
        backText: "Attendance",
      },
    });
  };

  const handleDateChange = (newSelectedDate) => {
    setStaffAttendanceDate(newSelectedDate);
    setIsStaffHoliday(false);
  };

  const absentItems = absentReasons?.map((reason) => ({
    key: reason,
    label: reason,
  }));
  const presentItems = presentReasons?.map((reason) => ({
    key: reason,
    label: reason,
  }));

  const handleErrorModalClose = () => setIsErrorModalVisible(false);

  if (spinnerLoading) {
    return <Spin spinning={spinnerLoading} />;
  }

  return (
    <div className="h-full w-full xl:w-10/12">
      <div className="flex flex-row mb-2.5 justify-between w-full items-center mt-6">
        <div className="flex flex-row">
          <form
            autoComplete="off"
            className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className=" relative w-full">
              <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                    fill="#9CA3AF"
                  />
                </svg>
              </span>
              <input
                defaultValue={search}
                type="text"
                id='"form-subscribe-Filter'
                className="pl-10 rounded-md border-transparent appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Search by staff name "
                onChange={(e) => {
                  e.preventDefault();
                  setSearch(e.target.value);
                }}
              />
            </div>
          </form>
          <Select
            style={{
              width: 200,
              borderRadius: 6,
              marginLeft: 12,
              fontWeight: "bold",
            }}
            className="customised-antd-selector text-left text-gray-700"
            onChange={(value) => {
              setClassFilter(value);
            }}
            size="large"
            placeholder="Class: All"
            defaultValue={"Class: All"}
          >
            <Select.Option key={-1}>Class: All</Select.Option>
            {getSortedClasses(clData?.classes)?.map((cl) => {
              return (
                <Select.Option key={cl?.id}>
                  Class: {cl?.class_name}
                </Select.Option>
              );
            })}
          </Select>

          <div className="flex items-center bg-white rounded-md ml-3">
            <div
              className="pl-1 ml-1 mr-0.5 flex h-full items-center cursor-pointer"
              onClick={handlePrevClick}
            >
              <LeftOutlined style={{ color: "#111827" }} />
            </div>
            <DatePicker
              className="font-medium"
              disabledDate={disabledDate}
              value={staffAttendnaceDate}
              onChange={handleDateChange}
              format={DATE_FORMATS.DMMMdddd}
              allowClear={false}
              suffixIcon={
                <CalendarOutlined
                  style={{ color: "#111827", fontWeight: 400 }}
                />
              }
              style={{
                borderRadius: "6px",
                width: 140,
                borderColor: "white",
              }}
            />
            <div
              className="pr-1 mr-1 ml-0.5 flex h-full items-center cursor-pointer"
              onClick={handleNextClick}
            >
              <RightOutlined style={{ color: "#111827" }} />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-4 align-middle justify-items-center">
          <span className="flex items-center text-black font-normal">
            <Badge type="present" /> Present
          </span>
          <span className="flex items-center text-black font-normal">
            <Badge type="absent" /> Absent
          </span>
        </div>
      </div>
      {isStaffHoliday ? (
        <div className="shadow-lg rounded-lg bg-white dark:bg-gray-800 flex flex-col flex-1 overflow-hidden pb-4 h-4/6">
          <div className="relative w-full flex justify-center">
            <img
              src={noResultCurve}
              alt=""
              className="absolute"
              style={{ width: "100%", height: 175 }}
            />
            <img
              src={noResult}
              alt=""
              style={{ width: 150, height: 150 }}
              className="z-50 mt-10"
            />
          </div>
          <div className="w-full flex justify-center h-full mt-5">
            <div className="w-1/3 h-full bg-white">
              <h1 className="text-center font-semibold text-black text-2xl mt-2.5">
                Holiday Today!
              </h1>
              <p className="text-center text-textGrey mt-2.5">
                Attendance cannot be marked because there is a holiday, do you
                still want to mark staff attendance?
              </p>
              <div className="flex w-full justify-center mt-3">
                <Button
                  buttonStyle="primary"
                  className="py-1 px-4 w-36 h-10 mt-5"
                  onClick={convertHolidayToWorkingDay}
                >
                  Yes Please
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="shadow-lg rounded-lg bg-white dark:bg-gray-800 flex flex-col flex-1 overflow-y-scroll pb-4 h-4/6">
          <div className="row-auto flex flex-row w-full justify-start py-3 bg-backgroundGreyLight px-6 border-b border-gray-200">
            <div className="text-left font-semibold w-1/2">STAFF NAME</div>
            <div className="text-left flex flex-1 justify-end pl-4 font-semibold ml-3">
              <div className="text-left font-semibold mr-20">AVAILABILITY</div>
              <p
                className="text-left font-semibold"
                style={{ marginRight: 120 }}
              >
                REMARK
              </p>
            </div>
          </div>
          <div className="flex flex-col flex-1">
            {filterByClassAndName?.map((teach, idx) => {
              const namesArray = teach?.teacherName?.split(" ");
              const firstName = namesArray?.[0];
              const lastName = namesArray?.[namesArray?.length - 1];
              const isAbsent = !teach?.isPresent;

              const remarks = teach?.remarks;

              const absentRemark = remarks;
              const presentRemark = remarks || DEFAULT_PRESENT_REMARK;

              const backgroundColor =
                remarks === null && isAbsent ? "bg-faintRed" : "bg-white";
              const remarksList = isAbsent ? absentItems : presentItems;

              return (
                <div key={teach.authId}>
                  <div
                    className={
                      "row-auto flex flex-row w-full justify-between mt-2 py-3 px-6" +
                      ` ${backgroundColor}`
                    }
                  >
                    <div className="flex-row gap-4 flex justify-start items-center xl:w-1/3 w-1/2">
                      <div className="flex-shrink-0">
                        {getAvatar(firstName, lastName, teach?.image)}
                      </div>
                      <div className=" flex flex-col text-left">
                        <span className="text-gray-700 dark:text-white text-sm leading-g font-bold capitalize break-words">
                          {teach.teacherName}
                        </span>
                        <div className="mt-1">
                          <StaffBadge staffType={teach?.staffType} />
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-end relative">
                      <StaffAddedNote
                        staffJoiningDate={
                          teach?.createdAt ? moment(teach?.createdAt) : moment()
                        }
                        attendanceDate={staffAttendnaceDate}
                      />
                      <Toggle
                        data={teach}
                        onChange={() => onChange(teach?.authId)}
                      />
                    </div>
                    <Select
                      style={{
                        width: 180,
                        borderRadius: 6,
                        marginLeft: 110,
                      }}
                      className="customised-antd-selector text-left text-gray-700 capitalize flex h-full items-center"
                      onChange={(value) => {
                        handleSickChange(teach?.authId, value);
                      }}
                      size="large"
                      placeholder="Select Reason"
                      value={isAbsent ? absentRemark : presentRemark}
                    >
                      {remarksList?.map((leaveType) => {
                        return (
                          <Select.Option
                            key={leaveType.key}
                            className="capitalize"
                          >
                            {leaveType.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="border-b border-greyBorder mt-2" />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="w-full h-20 bg-white dark:bg-gray-800  mt-1 items-center flex px-6 shadow-lg rounded-lg justify-between">
        {!isStaffHoliday ? (
          <div className="flex h-full items-center">
            <Button
              buttonStyle="primary"
              className="py-3 px-4 w-44 h-12"
              onClick={handleSave}
            >
              Save
            </Button>
            {isSaved === false && (
              <div className="flex mt-2 gap-x-2 items-center bg-backgroundGrey mx-8">
                <InfoCircleFilled
                  style={{
                    color: "#EB5757",
                    fontSize: "16px",
                  }}
                />
                <p className="text-cancel">Please mark today’s attendance</p>
              </div>
            )}
            {lastSaved !== null && (
              <div className="flex mt-2 gap-x-2 items-center font-medium text-sm mx-8 text-textGrey">
                Last saved on{" "}
                {moment(lastSaved).format(DATE_FORMATS.DDMMMYYHHMMA)}
              </div>
            )}
          </div>
        ) : (
          <div className="flex h-full items-center"></div>
        )}
        <div
          className="flex flex-col justify-start cursor-pointer"
          onClick={handleCheckReportsClick}
        >
          <div className="text-primary flex items-center">
            Check Attendance Report
            <RightOutlined
              style={{ fontSize: 10, marginLeft: 8 }}
              className="text-primary"
            />
          </div>
          <div className="border-t border-primary" />
        </div>
      </div>
      {isModalVisible ? (
        <Modal
          open
          footer={null}
          closeIcon={<></>}
          width={300}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            position: "static",
          }}
        >
          <div className="-mt-4">
            <img
              alt="Success"
              src={successImage}
              className="w-20 h-20 mx-auto mt-6 mb-4"
            />
            <h3 className="font-bold text-xl text-center">
              Staff Attendance
              <br />
              Saved Successfully!
            </h3>
          </div>
        </Modal>
      ) : null}
      {isErrorModalVisible ? (
        <Modal
          open
          closeIcon={<></>}
          footer={null}
          width={300}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            position: "static",
          }}
        >
          <div className="-mt-4">
            <img
              alt="Error"
              src={failureImage}
              className="w-20 h-20 mx-auto mt-6 mb-4"
            />
            <h3 className="font-bold text-xl text-center">Reason Missing</h3>
            <p className="text-center text-base font-medium text-textGrey">
              Please select a leave reason for the absent staff
            </p>
            <div className="flex justify-center mt-5">
              <Button
                className="h-30 w-20"
                buttonStyle={"primary"}
                onClick={handleErrorModalClose}
              >
                Okay
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default TeacherAvailability;
