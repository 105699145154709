import { useState } from "react";
import axios from "axios";

const defaultHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const useGenerateOrderId = ({ url, method, variables = null, headers }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const apiUrl = `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/${url}`;
  const fetchData = async (params) => {
    try {
      const result = await axios.request({
        url: apiUrl,
        method: "post",
        headers: defaultHeaders,
        data: params,
      });
      const subscriptionId = result?.data?.data?.subscriptionId;
      if (result?.data?.success) {
        setData(subscriptionId);
      } else if (result?.data?.message) {
        setError(result?.data?.message);
      } else {
        setError("Unable to load data!");
      }
      return subscriptionId;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, refetch: fetchData };
};

export default useGenerateOrderId;
