/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getEnquiryAdmissionForm = async (payload) => {
	const config = {
		url: `${process.env.REACT_APP_NODE_ENDPOINT}/admission/getEnquiryAdmissionForm`,
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		data: payload,
	};
	const { data } = await axios.request(config);
	return data?.data;
};

export default function useGetEnquiryAdmissionForm(payload, options = {}) {
	return useQuery(
		["getEnquiryAdmissionForm"],
		() => getEnquiryAdmissionForm(payload),
		{
			enabled: payload?.admissionEnquiryId !== undefined,
			...options
		}
	);
}