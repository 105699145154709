import { useState } from "react";
const googleTransliterate = require("../utils/googleInputTool");

const getOptionValue = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});

const useTransliteratedList = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async ({ request, sourceText, inputCode, maxResult }) => {
    try {
      let request = new XMLHttpRequest();
      const response = await googleTransliterate(
        request,
        sourceText,
        inputCode,
        maxResult
      );
      const suggestionOptions = response.map((item) =>
        getOptionValue(item?.[0])
      );
      setData(suggestionOptions);
      return suggestionOptions;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, refetch: fetchData };
};

export default useTransliteratedList;
