import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { DatePicker, Spin } from "antd";
import { DownloadIcon } from "@heroicons/react/outline";
import Tabs, { TabPane } from "rc-tabs";
import Button from "../../../components/Button";
import FeesCollectionTable from "./FeesCollectionTable";
import FeesBalancesTable from "./FeesBalancesTable";
import { Dropdown } from "../../../components";
import { ClassDivisionSelector } from "../../../components";
import { useNavigate } from "react-router-dom";
import { getSchoolID } from "../../../shared/getSchoolID";
import { DATE_FORMATS } from "../../../utils/constants";
import useFilterOptions from "../../../hooks/useFilterOptions";
import useRemindAllParent from "../../../hooks/useRemindAllParent";
import useDownloadFeeReceipt from "../../../hooks/useDownloadFeeReceipt";
import useNotificationForFeeBalance from "../../../hooks/useNotificationForFeeBalance";
import useTracking from "../../../hooks/useTracking";
import settings from "../../../assets/images/settings.png";

const PAGE_SIZE = 10;
const { RangePicker } = DatePicker;

function FeesCollection() {
  const defaultFilterMap = {
    schoolId: getSchoolID(),
    classId: null,
    divisionId: null,
    studentId: null,
    feeStructureId: null,
    dateFrom: null,
    dateTo: null,
    studentName: null,
    status: null,
  };
  const [activeTab, setActiveTab] = useState(1);
  const [feeDataForNotification, setFeeDataForNotification] = useState([]);
  const navigate = useNavigate();
  let searchDebounce = null;
  const { data: filterOptions } = useFilterOptions(
    {
      schoolId: getSchoolID(),
      entity: activeTab === 1 ? "feeCollection" : "feeBalance",
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const [studentName, setStudentName] = useState(null);
  const [balanceStudentName, setBalanceStudentName] = useState(null);
  const [filterData, setFilterData] = useState(defaultFilterMap);
  const [balanceFilterData, setBalanceFilterData] = useState(defaultFilterMap);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: remindFiltered } = useRemindAllParent({
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const { mutateAsync: downloadFeeReceipt } = useDownloadFeeReceipt({
    onSettled: () => {
      setIsLoading(false);
    },
  });
  useNotificationForFeeBalance(
    {
      feeBalanceData: feeDataForNotification,
    },
    {
      onSettled: (data) => {
        setFeeDataForNotification([]);
      },
    }
  );
  const { trackEvent } = useTracking();

  const handleStudentNameChange = (_studentName) => {
    clearTimeout(searchDebounce);
    if (activeTab === 1) {
      setStudentName(_studentName);
    } else {
      setBalanceStudentName(_studentName);
    }
    searchDebounce = setTimeout(() => {
      if (activeTab === 1) {
        setFilterData({
          ...filterData,
          studentName: _studentName,
          studentId: null,
        });
      } else {
        setBalanceFilterData({
          ...filterData,
          studentName: _studentName,
          studentId: null,
        });
      }
    }, 650);
  };

  const handleClassChange = (classId) => {
    setFilterData({
      ...filterData,
      classId,
      divisionId: null,
      feeStructureId: null,
      studentId: null,
    });
  };

  const handleDivisionChangeBalance = (divisionId) => {
    setFilterData({
      ...filterData,
      divisionId,
      feeStructureId: null,
      studentId: null,
    });
  };

  const handleClassChangeBalance = (classId) => {
    setBalanceFilterData({
      ...filterData,
      classId,
      divisionId: null,
      feeStructureId: null,
      studentId: null,
    });
  };

  const handleDivisionChange = (divisionId) => {
    setBalanceFilterData({
      ...filterData,
      divisionId,
      feeStructureId: null,
      studentId: null,
    });
  };

  const handleFeeStructureSelect = (feeStructureId) => {
    setFilterData({
      ...filterData,
      feeStructureId,
      studentId: null,
    });
  };

  const handleStatusChange = (status) => {
    setBalanceFilterData({
      ...filterData,
      status,
    });
  };

  const handleDateRangeChanged = (dates) => {
    if (dates) {
      const formattedStartDate = dates[0].format(DATE_FORMATS.YYYYMMDD);
      const formattedEndDate = dates[1].format(DATE_FORMATS.YYYYMMDD);
      setFilterData({
        ...filterData,
        dateFrom: formattedStartDate,
        dateTo: formattedEndDate,
      });
    } else {
      setFilterData({
        ...filterData,
        dateFrom: null,
        dateTo: null,
      });
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const feeStructureValue = () => {
    const id = filterData?.feeStructureId;
    if (!id) return null;
    // eslint-disable-next-line eqeqeq
    const structure = filterOptions?.feeStructures?.find(
      ({ value }) => value == id
    );
    return structure?.label;
  };

  const statusValue = () => {
    const id = filterData?.status;
    if (!id) return null;
    // eslint-disable-next-line eqeqeq
    const statusData = filterOptions?.statusList?.find(
      ({ value }) => value == id
    );
    return statusData?.label;
  };

  const handleEditDetails = (collectionId) => {
    navigate(`/fees-collection/new-collection`, {
      state: {
        feesCollectionData: {
          id: collectionId,
        },
      },
    });
  };

  const handleSetStudentCollection = ({ student_id, student_full_name }) => {
    trackEvent("View Collection");
    const filterData = {
      ...defaultFilterMap,
      studentId: student_id,
    };
    setStudentName(student_full_name);
    setActiveTab(1);
    setFilterData(filterData);
  };

  const handleDownloadFeeReceipt = async (params = null) => {
    setIsLoading(true);
    trackEvent("Download Receipt Book", {
      params,
    });
    const defaultParams = {
      schoolId: getSchoolID(),
      fileName: `FeeReciept_Book`,
    };
    await downloadFeeReceipt(params || defaultParams);
  };

  const handleRemindParents = (collection) => {
    const notificationPayload = {
      studentId: collection?.studentId,
      remainingAmount: collection?.remainingAmount,
    };
    trackEvent("Remind Parents", {
      collection,
      notificationPayload,
    });
    setFeeDataForNotification([...feeDataForNotification, notificationPayload]);
  };

  const remindFilteredParents = async () => {
    setIsLoading(true);
    await remindFiltered(filterData);
  };

  return (
    <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={isLoading}>
        <div className="flex flex-row gap-x-4 items-center mb-8">
          <p className="text-2xl text-left font-bold flex-col m-0">
            Fees Collection
          </p>
          <Button
            id="download-all-students-data"
            buttonStyle="primary"
            className="py-3 px-4"
            onClick={() => navigate(`/fees-collection/new-collection`)}
          >
            <PlusIcon className="h-4 w-4" />
            <span className="ml-2">New Fees Collection</span>
          </Button>
          <Button
            id="download-all-students-data"
            buttonStyle="primary"
            className="py-3 px-4"
            onClick={() => navigate(`/fee-receipt-settings`)}
          >
            <img src={settings} className="h-5 w-5" />
            <span className="ml-2">Receipt Settings</span>
          </Button>
          <Button
            onClick={() => handleDownloadFeeReceipt()}
            buttonStyle="success"
            className="py-3 px-4"
          >
            <DownloadIcon className="h-4 w-4" />
            <span className="ml-2">Download Receipt Book</span>
          </Button>
        </div>
        <Tabs
          activeKey={activeTab.toString()}
          onChange={(key) => {
            handleTabChange(Number(key));
          }}
          className="custom-tabs overflow-y-auto"
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane tab="Fees Collection" key={1} className="relative mt-4 mb-6">
            <div className="flex items-start justify-between bg-gray">
              <form
                className="flex w-auto flex-col items-start md:flex-row md:space-x-3 space-y-3 md:space-y-0"
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
              >
                <div className="relative pl-2">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-4">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    style={{ width: 300 }}
                    className="pl-10 rounded-md border-transparent appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by student name"
                    value={studentName}
                    onChange={(e) => {
                      handleStudentNameChange(e.target.value);
                    }}
                  />
                </div>
              </form>
              <div className="flex flex-row flex-1 pl-5 pr-2 gap-x-3 justify-end">
                <ClassDivisionSelector
                  classDefaultValue="Class : All"
                  classes={filterOptions?.classes}
                  divisions={filterOptions?.divisions}
                  allOptionNeeded
                  onClassChange={handleClassChange}
                  onDivisionChange={handleDivisionChange}
                />
                <Dropdown
                  value={feeStructureValue()}
                  defaultValue="Fee Structure: All"
                  type="primary"
                  options={filterOptions?.feeStructures}
                  onItemClick={handleFeeStructureSelect}
                />
              </div>
              <div className="flex flex-row gap-x-1 h-full">
                <RangePicker
                  className="rounded-md shadow-sm"
                  onChange={handleDateRangeChanged}
                  format={DATE_FORMATS.DDMMMYYYY}
                  style={{
                    borderRadius: 5,
                    minWidth: 240,
                    height: "100%",
                    padding: 9,
                  }}
                />
              </div>
            </div>
            <div className="w-full overflow-x-hidden">
              <FeesCollectionTable
                filterData={filterData}
                onEditDetails={handleEditDetails}
                pageLimit={PAGE_SIZE}
                downloadFeeReceipt={handleDownloadFeeReceipt}
              />
            </div>
          </TabPane>
          <TabPane tab="Fees Balances" key={2} className="relative mt-4 mb-6">
            <div className="flex items-start justify-between">
              <form
                className="flex w-auto flex-col items-start md:flex-row md:space-x-3 space-y-3 md:space-y-0"
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
              >
                <div className="relative pl-2">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-4">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    style={{ width: 300 }}
                    className="pl-10 rounded-md border-transparent appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by student name"
                    value={balanceStudentName}
                    onChange={(e) => {
                      handleStudentNameChange(e.target.value);
                    }}
                  />
                </div>
              </form>
              <div className="flex flex-row gap-x-5">
                <ClassDivisionSelector
                  classDefaultValue="Class : All"
                  classes={filterOptions?.classes}
                  divisions={filterOptions?.divisions}
                  allOptionNeeded
                  onClassChange={handleClassChangeBalance}
                  onDivisionChange={handleDivisionChangeBalance}
                />
                <Dropdown
                  value={statusValue()}
                  defaultValue={"Status: All"}
                  type="primary"
                  options={filterOptions?.statusList}
                  onItemClick={(value) => {
                    handleStatusChange(value);
                  }}
                />
                <Button
                  buttonStyle="primary"
                  onClick={remindFilteredParents}
                  style={{ display: "inline" }}
                >
                  <span className="flex justify-center items-center gap-x-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Remind All
                  </span>
                </Button>
              </div>
            </div>
            <div className="overflow-x-hidden">
              <FeesBalancesTable
                onViewCollection={handleSetStudentCollection}
                filterData={balanceFilterData}
                pageLimit={PAGE_SIZE}
                handleRemindParent={handleRemindParents}
              />
            </div>
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
}

export default FeesCollection;
