import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import { getS3Image } from "../../shared/s3";
import moment from "moment";
import { Button, Text } from "../../components";
import { PencilIcon } from "@heroicons/react/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import AutoCompleteInput from "../../components/AutoCompleteInput";
import { convertDateToWords } from "../../utils";
import useTranslationAPI from "../../hooks/useTranslation";
const googleTransliterate = require("../../utils/googleInputTool");

const FormCol = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};
const FormRow = ({ children, className }) => {
  return <div className={`${className} flex my-2`}>{children}</div>;
};

function BonafideCertificateView({
  isMasked,
  isEditing,
  isEditable,
  isEditFormat,
  data,
  onCancel,
  onEdit,
  onSave,
  onDownloadDocument,
  selectedLanguage,
  viewOnly,
  languageInputCode,
  containerStyle,
}) {
  const [documentFormData, setDocumentFormData] = useState({});
  const [isDocumentExpanded, setIsDocumentExpanded] = useState(false);
  const [inputOptions, setInputOptions] = useState([]);
  const isEditingStudent = isEditing && !isEditFormat;

  const [getTranslation] = useTranslationAPI();

  const onToggleDocumentView = () => {
    setIsDocumentExpanded(!isDocumentExpanded);
  };

  useEffect(() => {
    if (data) {
      // get image base64 from local storage
      const localBase64 = localStorage.getItem(data.image);
      // set imageUrl in tcFormData as localBase64 if exists else get and set imageUrl from s3
      if (localBase64) {
        setDocumentFormData({ ...data, imageUrl: localBase64 });
      } else {
        const imageUrl = getS3Image(data.image);
        setDocumentFormData({ ...data, imageUrl });
      }
    }
  }, [data]);

  const handleSave = () => {
    onSave(documentFormData);
  };

  const handleIssueAndDownload = () => {
    const isIssued = true;
    onDownloadDocument(documentFormData, isIssued);
  };

  const getOptionValue = (str, repeat = 1) => ({
    value: str.repeat(repeat),
  });

  const onSearch = (searchText) => {
    const document = data?.settings?.documents?.find(
      (doc) => doc.code === "bnc"
    );
    const documentInputCode = languageInputCode || document?.googleInputCode;
    if (!documentInputCode || documentInputCode === "en") {
      return;
    }
    let sourceText = searchText;
    let inputLanguage = documentInputCode;
    let maxResult = 8;
    let request = new XMLHttpRequest();
    googleTransliterate(request, sourceText, inputLanguage, maxResult).then(
      function (response) {
        const suggestionOptions = response.map((item) =>
          getOptionValue(item?.[0])
        );
        setInputOptions(suggestionOptions);
      }
    );
  };

  const onAutoCompleteChange = (value, field) => {
    onSearch(value);
    setDocumentFormData({
      ...documentFormData,
      [field]: value,
    });
  };

  const onAutoCompleteSelect = (value, field) => {
    setDocumentFormData({
      ...documentFormData,
      [field]: value,
    });
    setInputOptions([]);
  };

  const onDobChange = async (value, dateString) => {
    let dobWords = convertDateToWords(dateString);
    if (selectedLanguage !== "en") {
      const translatedData = await getTranslation({
        textObj: {
          dobWords,
        },
        sourceLang: "en",
        targetLang: selectedLanguage,
      });
      setDocumentFormData({
        ...documentFormData,
        dateOfBirth: dateString,
        dateOfBirthInWords: translatedData.dobWords,
      });
    } else {
      setDocumentFormData({
        ...documentFormData,
        dateOfBirth: dateString,
        dateOfBirthInWords: dobWords,
      });
    }
  };

  return (
    <form
      id="bonafideDocument"
      className={`${
        isMasked && "opacity-20"
      } flex flex-col overflow-y-auto overflow-x-hidden bg-white h-3/4 mt-2 rounded-lg p-6`}
      style={{
        ...containerStyle,
      }}
    >
      <div
        className="relative overflow-y-hidden border-4 border-indigo-600 rounded-xl"
        style={{
          height: isDocumentExpanded ? "50rem" : "24rem",
          maxHeight: isDocumentExpanded ? "50rem" : "24rem",
          transition: "all 1s ease-out",
        }}
      >
        <div className="relative overflow-y-scroll box-border h-full w-full flex flex-col p-2">
          {data?.image && (
            <img
              alt="Logo"
              src={documentFormData?.imageUrl}
              className="h-28 w-28 absolute top-2 rounded-full"
            />
          )}
          {!data?.image && (
            <div
              id="school-logo"
              className="h-28 w-28 absolute top-2 bg-gray-200 rounded-full"
            ></div>
          )}
          <div className="absolute right-4 top-4 flex items-center">
            <label className="font-bold text-gray-700 mr-1 text-base">
              <Text id="date" /> :{" "}
            </label>
            {isEditingStudent ? (
              <DatePicker
                value={moment(documentFormData?.date, "DD/MM/YYYY")}
                onChange={(value, dateString) => {
                  setDocumentFormData({
                    ...documentFormData,
                    date: dateString,
                  });
                }}
                suffixIcon={null}
                format="DD/MM/YYYY"
                className="rounded-md"
                allowClear={false}
                style={{
                  borderRadius: "6px",
                }}
              />
            ) : (
              <strong
                className="font-bold text-black"
                style={{
                  minWidth: "5rem",
                }}
              >
                {documentFormData?.date}
              </strong>
            )}
          </div>
          <div className="flex flex-col justify-center items-center mt-4">
            {isEditing && !isEditingStudent ? (
              <div className="flex mb-2">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="slogan" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="slogan"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "slogan");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "slogan");
                  }}
                />
              </div>
            ) : (
              <h4 className="font-bold text-indigo-700 text-base">
                {data ? data?.slogan || "" : "Slogan"}
              </h4>
            )}
            {isEditing && !isEditingStudent ? (
              <div className="flex mb-2">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="trust-name" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="trustName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "trustName");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "trustName");
                  }}
                />
              </div>
            ) : (
              <h4 className="font-bold text-gray-700 text-base">
                {data ? data?.trustName || "" : "Trust Name"}
              </h4>
            )}
            {isEditing && !isEditingStudent ? (
              <div className="flex">
                <label className="font-bold text-gray-700 mr-1">
                  <Text id="school-name" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="schoolName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onAutoCompleteChange(e.target.value, "schoolName");
                  }}
                  onSelect={(value) => {
                    onAutoCompleteSelect(value, "schoolName");
                  }}
                />
              </div>
            ) : (
              <h3
                id="school-name"
                className="font-bold text-indigo-700 text-2xl"
              >
                {data?.schoolName || "School Name"}
              </h3>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="city" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="cityName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setDocumentFormData({
                      ...documentFormData,
                      cityName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setDocumentFormData({
                      ...documentFormData,
                      cityName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <></>
            )}
            {isEditing && !isEditingStudent ? (
              <FormRow className="flex">
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="state" /> :{" "}
                </label>
                <AutoCompleteInput
                  field="stateName"
                  options={inputOptions}
                  formData={documentFormData}
                  onBlur={(e) => {
                    setInputOptions([]);
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setDocumentFormData({
                      ...documentFormData,
                      stateName: e.target.value,
                    });
                  }}
                  onSelect={(value) => {
                    setDocumentFormData({
                      ...documentFormData,
                      stateName: value,
                    });
                    setInputOptions([]);
                  }}
                />
              </FormRow>
            ) : (
              <></>
            )}
            {!isEditing && !isEditingStudent && (
              <h4 className="text-black font-bold text-base">
                {data?.cityName || "City"}, {data?.stateName || "State"}
              </h4>
            )}
          </div>
          <div className="flex gap-x-64 text-left justify-center">
            <FormCol>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="school-registration-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={documentFormData.schoolRegistrationNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        schoolRegistrationNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.schoolRegistrationNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="u-dise-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={documentFormData.udiseNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        udiseNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.udiseNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="index-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={documentFormData.indexNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        indexNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.indexNumber}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="mobile-number" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={documentFormData.mobileNumber}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        mobileNumber: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.mobileNumber}
                  </p>
                )}
              </FormRow>
            </FormCol>
            <FormCol>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1">&nbsp;</label>
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="board" /> :{" "}
                </label>
                {isEditing ? (
                  <AutoCompleteInput
                    field="board"
                    options={inputOptions}
                    formData={documentFormData}
                    onBlur={(e) => {
                      setInputOptions([]);
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                      setDocumentFormData({
                        ...documentFormData,
                        board: e.target.value,
                      });
                    }}
                    onSelect={(value) => {
                      setDocumentFormData({
                        ...documentFormData,
                        board: value,
                      });
                      setInputOptions([]);
                    }}
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.board}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="school-medium" /> :{" "}
                </label>
                {isEditing ? (
                  <AutoCompleteInput
                    field="schoolMedium"
                    options={inputOptions}
                    formData={documentFormData}
                    onBlur={(e) => {
                      setInputOptions([]);
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                      setDocumentFormData({
                        ...documentFormData,
                        schoolMedium: e.target.value,
                      });
                    }}
                    onSelect={(value) => {
                      setDocumentFormData({
                        ...documentFormData,
                        schoolMedium: value,
                      });
                      setInputOptions([]);
                    }}
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.schoolMedium}
                  </p>
                )}
              </FormRow>
              <FormRow>
                <label className="font-bold text-gray-700 mr-1 text-base">
                  <Text id="email" /> :{" "}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={documentFormData.email}
                    onChange={(e) =>
                      setDocumentFormData({
                        ...documentFormData,
                        email: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="font-bold text-black text-base">
                    {documentFormData?.email}
                  </p>
                )}
              </FormRow>
            </FormCol>
          </div>
          <div
            id="title-container"
            className="grid grid-flow-col gap-x-3 pl-28 pr-28 mt-5 text-center justify-center items-center"
          >
            <div
              id="title-border-left"
              className="border-b-4 w-36 border-indigo-600 m-auto"
            ></div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div id="docTitle" className="text-2xl w-auto mb-auto font-bold">
              <p
                style={{
                  fontFamily: containerStyle?.fontFamily || "Kurale",
                }}
              >
                <Text id="bonafide-certificate" />
              </p>
            </div>
            <div className="rounded-full bg-indigo-600 h-3 w-3 m-auto"></div>
            <div
              id="title-border-right"
              className="border-b-4 w-36 border-indigo-600 m-auto"
            ></div>
          </div>
          <div className="mt-5 flex justify-center gap-x-64">
            <div className="flex">
              <label className="font-bold text-gray-700 mr-1 text-base">
                <Text id="general-register-number" />:{" "}
              </label>
              {isEditingStudent ? (
                <input
                  type="text"
                  className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                  value={documentFormData.generalRegisterNumber}
                  onChange={(e) =>
                    setDocumentFormData({
                      ...documentFormData,
                      generalRegisterNumber: e.target.value,
                    })
                  }
                />
              ) : (
                <p className="font-bold text-black text-base">
                  {documentFormData?.generalRegisterNumber}
                </p>
              )}
            </div>
            <div className="flex">
              <label className="font-bold text-gray-700 mr-1 text-base">
                <Text id="student-id" />:{" "}
              </label>
              {isEditingStudent ? (
                <input
                  type="text"
                  className="border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                  value={documentFormData.studentRef}
                  onChange={(e) =>
                    setDocumentFormData({
                      ...documentFormData,
                      studentRef: e.target.value,
                    })
                  }
                />
              ) : (
                <p className="font-bold text-black text-base">
                  {documentFormData?.studentRef}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-x-32 text-left mt-3 mb-0 p-0">
            <div className="flex gap-x-8 justify-center text-center">
              <FormCol className="flex flex-col">
                <FormRow className="flex">
                  <p className="font-semibold text-gray-700 text-base">
                    <Text id="this-is-to-certify" />
                  </p>
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="studentName"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          studentName: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          studentName: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <p className="font-bold text-black ml-1 text-base">
                      {documentFormData?.studentName || (
                        <span className="inline-block w-32 border-b border-black"></span>
                      )}
                    </p>
                  )}
                </FormRow>
              </FormCol>
            </div>
            <div className="flex gap-x-8 justify-center text-center">
              <FormRow className="flex flex-col gap-y-2">
                <p className="font-semibold text-gray-700 text-base">
                  <Text id="birth-place" /> -{" "}
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="birthPlace"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          birthPlace: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          birthPlace: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <strong className="font-bold text-black text-base">
                      {documentFormData?.birthPlace || (
                        <span className="inline-block w-20 border-b border-black"></span>
                      )}
                    </strong>
                  )}
                  , <Text id="taluka" /> -{" "}
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="taluka"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          taluka: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          taluka: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <strong className="font-bold text-black">
                      {documentFormData?.taluka || (
                        <span className="inline-block w-20 border-b border-black"></span>
                      )}
                    </strong>
                  )}
                  , <Text id="district" /> -{" "}
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="districtName"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          districtName: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          districtName: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <strong className="font-bold text-black">
                      {documentFormData?.districtName || (
                        <span className="inline-block w-20 border-b border-black"></span>
                      )}
                    </strong>
                  )}
                  , <Text id="state" /> -{" "}
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="state"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          state: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          state: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <strong className="font-bold text-black">
                      {documentFormData?.state || (
                        <span className="inline-block w-20 border-b border-black"></span>
                      )}
                    </strong>
                  )}
                </p>
                <p className="font-semibold text-gray-700 whitespace-pre-line text-base">
                  <Text id="during-the-academic-year" />{" "}
                  <strong className="font-bold text-black">
                    {documentFormData?.academicYear || (
                      <span className="inline-block w-20 border-b border-black"></span>
                    )}
                  </strong>
                  , <Text id="he-studying-in-class" />{" "}
                  <strong className="font-bold text-black">
                    {documentFormData?.class || (
                      <span className="inline-block w-20 border-b border-black"></span>
                    )}
                  </strong>{" "}
                  {selectedLanguage === "mr-IN" && <Text id="is-class" />}.
                </p>
                <p className="font-semibold text-gray-700 text-base">
                  <Text id="his-her-caste" />{" "}
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="caste"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          caste: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          caste: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <strong className="font-bold text-black">
                      {documentFormData?.caste || (
                        <span className="inline-block w-20 border-b border-black"></span>
                      )}
                    </strong>
                  )}{" "}
                  <Text id="and-dob" />{" "}
                  {isEditingStudent ? (
                    <DatePicker
                      value={moment(
                        documentFormData?.dateOfBirth,
                        "DD/MM/YYYY"
                      )}
                      onChange={onDobChange}
                      suffixIcon={null}
                      format="DD/MM/YYYY"
                      className="rounded-md"
                      allowClear={false}
                      style={{
                        borderRadius: "6px",
                      }}
                    />
                  ) : (
                    <strong className="font-bold text-black">
                      {documentFormData?.dateOfBirth || (
                        <span className="inline-block w-20 border-b border-black"></span>
                      )}{" "}
                      {documentFormData?.dateOfBirthInWords
                        ? `(${documentFormData?.dateOfBirthInWords || ""})`
                        : ""}
                    </strong>
                  )}
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="dateOfBirthInWords"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          dateOfBirthInWords: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          dateOfBirthInWords: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <></>
                  )}{" "}
                  {selectedLanguage === "mr-IN" && <Text id="is-like" />}.
                </p>
                <p className="font-semibold text-gray-700 text-base">
                  <Text id="his-her-conduct" />{" "}
                  {isEditingStudent ? (
                    <AutoCompleteInput
                      field="behaviour"
                      options={inputOptions}
                      formData={documentFormData}
                      onBlur={(e) => {
                        setInputOptions([]);
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                        setDocumentFormData({
                          ...documentFormData,
                          behaviour: e.target.value,
                        });
                      }}
                      onSelect={(value) => {
                        setDocumentFormData({
                          ...documentFormData,
                          behaviour: value,
                        });
                        setInputOptions([]);
                      }}
                    />
                  ) : (
                    <strong className="font-bold text-black">
                      {documentFormData?.behaviour || (
                        <span className="inline-block w-20 border-b border-black"></span>
                      )}
                    </strong>
                  )}{" "}
                  {selectedLanguage === "mr-IN" && <Text id="is" />}.
                </p>
              </FormRow>
            </div>
          </div>
          <div className="flex items-end justify-end mt-28 mr-11 text-black border-b-4 border-indigo-600 ml-11 pl-6">
            <h3 className="font-bold text-base">
              <Text id="principal" />
            </h3>
          </div>
          <p className="text-red-600 text-base font-medium mt-1">
            <Text id="note-legal-bnc" />
          </p>
        </div>
        <Button
          id="documents-bonafide-view"
          buttonStyle="primary"
          className="h-5 pt-4 z-10 flex items-center justify-center text-center absolute right-0 bottom-0 rounded-tl-md rounded-bl-none rounded-br-lg rounded-tr-none"
          onClick={onToggleDocumentView}
        >
          {isDocumentExpanded ? "Minimize document" : "View entire document"}
          {isDocumentExpanded ? (
            <ChevronUpIcon className="h-4 w-4 ml-3" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 ml-3" aria-hidden="true" />
          )}
        </Button>
      </div>
      <div id="form-controls" className="flex justify-end pt-6">
        {isEditing && (
          <Button
            id="documents-bonafide-edit-cancel"
            style={{
              minWidth: "6rem",
            }}
            buttonStyle="default"
            onClick={() => onCancel()}
            className="h-12"
          >
            Cancel
          </Button>
        )}
        {isEditing && (
          <button
            id="documents-bonafide-edit-save"
            type="button"
            className="py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              handleSave();
            }}
          >
            <span className="ml-2">Save Changes</span>
          </button>
        )}
        {!isEditing && isEditable && data && (
          <button
            id="documents-bonafide-editdetails"
            type="button"
            className="py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              onEdit();
            }}
          >
            <span className="flex justify-center items-center gap-x-1">
              <PencilIcon className="h-4 w-4" />
              Edit Details
            </span>
          </button>
        )}
        {!isEditing && isEditFormat && (
          <button
            id="documents-nirgam-edit-format"
            type="button"
            className="h-12 py-3 ml-5 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            onClick={() => {
              onEdit();
            }}
          >
            <PencilIcon className="h-4 w-4" aria-hidden="true" />
            <span className="ml-2">Edit Format</span>
          </button>
        )}
        {data && isEditable && (
          <Button
            id="documents-bonafide-download"
            buttonStyle="success"
            onClick={handleIssueAndDownload}
            className="ml-5"
          >
            Issue & Download <br /> Document
          </Button>
        )}
      </div>
    </form>
  );
}

export default BonafideCertificateView;
