/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";
import { getSchoolID } from "../shared/getSchoolID";

const removeCountryCode = (staffList) => {
  const staffListWithoutPrefix = staffList?.map((staffMember) => ({
    ...staffMember,
    mobile_number:
      staffMember?.mobile_number?.length >= 3
        ? staffMember?.mobile_number?.slice(3)
        : staffMember?.mobile_number,
  }));
  return staffListWithoutPrefix;
};

const getNonTeachingStaffList = async (payload) => {
  const token = getAuthID();
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/getNonTeachingStaffList`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useGetNonTeachingStaffList(options = {}) {
  const schoolId = getSchoolID();
  return useQuery(
    ["nonTeachingStaff", schoolId],
    () => getNonTeachingStaffList({ schoolId }),
    {
      onError: (e) => {
        console.error("use Error: ", { e: e.message });
      },
      select: removeCountryCode,
      refetchOnWindowFocus: true,
      ...options,
    }
  );
}
