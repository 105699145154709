import React, { useState } from "react";
import StudentList from "../../pages/ManageClasses/StudentList";
import Tabs, { TabPane } from "rc-tabs";
import SubsNTeachers from "../../pages/ManageClasses/SubjectsAndTeachers";

const Badge = ({ type }) => {
  return (
    <button
      type="button"
      className={`w-3 h-3 text-base rounded-full border border-black text-white mr-2 ${
        type === "present" ? "c-bg-green" : "c-bg-red"
      }`}
    ></button>
  );
};
const ClassDetailedView = ({ setdraweropen }) => {
  const [activekey, setactivekey] = useState(1);
  return (
    <div>
      <div className="flex flex-row mt-4">
        <Tabs
          defaultActiveKey={activekey}
          onChange={(key) => setactivekey(Number(key))}
          className="custom-tabs"
          animated={{ inkBar: true, tabPane: false }}
          tabBarExtraContent={
            <div
              className={
                activekey === 2
                  ? "flex flex-row justify-center gap-4 align-middle justify-items-center"
                  : "hidden"
              }
            >
              {" "}
              <span className="flex items-center ">
                <Badge type="present" /> Teacher Present
              </span>
              <span className="flex items-center ">
                <Badge type="absent" /> Teacher Absent
              </span>
            </div>
          }
        >
          <TabPane tab="Students" key={1}>
            <StudentList
              setdraweropen={(values) => setdraweropen(values, true)}
            />
          </TabPane>
          <TabPane tab="Subjects & Teachers" key={2}>
            <SubsNTeachers />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default ClassDetailedView;
