/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";
import { getSchoolID } from "../shared/getSchoolID";

const getAdminSideBar = async (payload = {}) => {
  const authId = getAuthID();
  const schoolId = getSchoolID();
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/getAdminSideBar`,
    method: "post",
    data: {
      ...payload,
      authId,
      schoolId
    },
    validateStatus: () => true,
  };
  const { data } = await axios.request(config);
  return data.data;
};

export default function useGetAdminSideBar() {
  return useQuery(["getAdminSideBar", true], () => getAdminSideBar());
}
