import React from "react";

export default function CloseIcon({ width, height }) {
  return (
    <svg
      id="closeicon"
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#EB5757" />
      <path
        d="M6.66666 13.3333L13.3333 6.66666M6.66666 6.66666L13.3333 13.3333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
