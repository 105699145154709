import React, { useState } from "react";
import { Pagination, Modal } from "antd";
import moment from "moment";
import { getAvatar } from "../../shared/getAvatar";
import { Button } from "../../components";

function IssuanceTable({
  issuanceHistory,
  onDeleteClick,
  fetchMoreData,
  totalCount,
  pageLimit,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isRemoveConfirmModalOpen, setIsRemoveConfirmModalOpen] =
    useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handlePageChange = (page, pageSize) => {
    fetchMoreData({
      variables: {
        offset: (page - 1) * pageSize,
      },
    });
    setCurrentPage(page);
  };

  const showDeleteConfirm = (docData) => {
    setIsRemoveConfirmModalOpen(true);
    setSelectedDocument(docData);
  };

  const cancelDelete = () => {
    setIsRemoveConfirmModalOpen(false);
    setSelectedDocument(null);
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Mother's Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Document Type
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Document Number
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Issuance Date/Time
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(issuanceHistory) &&
                    issuanceHistory?.map((docData, index) => {
                      const student = docData?.student;
                      return (
                        <tr key={index}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center text-left">
                              <div className="flex-shrink-0">
                                {getAvatar(
                                  student?.first_name,
                                  student?.last_name,
                                  student?.image
                                )}
                              </div>
                              <div className="ml-3">
                                <p className="text-black font-medium whitespace-no-wrap">
                                  {student?.first_name} {student?.middle_name}{" "}
                                  {student?.last_name}
                                </p>
                                {student?.transfer_date && (
                                  <p className="text-red-500">Transferred</p>
                                )}
                                {!student?.transfer_date &&
                                  student?.is_active && (
                                    <p className="text-green-500">Active</p>
                                  )}
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {student?.mother_name || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {docData?.document_type || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {docData?.document_number || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {moment(
                                docData?.issuance_date,
                                "YYYY-MM-DD"
                              ).format("DD MMM YYYY") || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <Button
                              buttonStyle="danger"
                              onClick={() => showDeleteConfirm(docData)}
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Pagination
              size="small"
              pageSize={pageLimit}
              total={totalCount}
              current={currentPage}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
            <Modal
              visible={isRemoveConfirmModalOpen}
              centered
              footer={null}
              closable={false}
              width={344}
            >
              <div className="flex flex-col bg-white rounded-md items-center text-center p-3">
                <p className="text-lg text-black font-bold mt-2 w-64">
                  Are you sure you want to remove this document?
                </p>
                <div className="flex gap-x-5">
                  <Button
                    onClick={cancelDelete}
                    buttonStyle="danger"
                    className="w-20 mt-6"
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      onDeleteClick(selectedDocument);
                      setIsRemoveConfirmModalOpen(false);
                    }}
                    buttonStyle="success"
                    className="w-20 mt-6"
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IssuanceTable;
