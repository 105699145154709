/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";

const getExamData = async (payload) => {
  const variables = payload;
  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/student/scorecardData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...variables,
      },
    };
    const { data } = await axios.request(config);
    return data?.data;
  } catch (e) {
    console.error("useExamData Error: ", { e: e.message });
    return null;
  }
};

export default function useExamData(studentId, examKey) {
  return useQuery(
    ["examData", studentId, examKey],
    () =>
      getExamData({
        studentId,
        examKey,
      }),
    {
      keepPreviousData: true,
      enabled:
        studentId !== undefined && examKey !== undefined && examKey !== null,
    }
  );
}
