import React, { useEffect, useState } from "react";
import { getUserRole } from "../shared/getUserRole";
import { USER_ROLES } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { Button } from "../components";
import {
  PlusCircleOutlined,
  InfoCircleFilled,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  Drawer,
  Upload,
  message,
  Checkbox,
  Radio,
  DatePicker,
  Switch,
} from "antd";
import CloseIcon from "../components/closeIcon";
import moment from "moment";
import useUploadDoYouKnowAsset from "../hooks/useUploadDoYouKnowAsset";
import useGetAllDoYouKnows from "../hooks/useGetAllDoYouKnows";
import { DATE_FORMATS } from "../utils/constants";
import useCreateDoYouKnow from "../hooks/useCreateDoYouKnow";
import useDeleteDoYouKnow from "../hooks/useDeleteDoYouKnow";
import useUpdateDoYouKnow from "../hooks/useUpdateDoYouKnow";

const ASPECT_RATIO = 2 / 1;

const DO_YOU_KNOW_TYPES = {
  IMAGE: "IMAGE",
  TEXT: "TEXT",
  VIDEO: "VIDEO",
};

const POST_TYPE = {
  ADVERTISEMENT: "Advertisement",
  FACT: "Fact",
};

const NAME_MAPPER = {
  TEACHER_APP: "Teacher App",
  PARENT_APP: "Student App",
  ADMIN_APP: "Admin App",
};

const APP_NAMES = [
  {
    label: (
      <p className="font-medium text-sm text-darkerGrey">
        GroundUp Teacher App
      </p>
    ),
    value: "TEACHER_APP",
  },
  {
    label: (
      <p className="font-medium text-sm text-darkerGrey">GroundUp Admin App</p>
    ),
    value: "ADMIN_APP",
    disabled: true,
  },
  {
    label: (
      <p className="font-medium text-sm text-darkerGrey">
        GroundUp Student App
      </p>
    ),
    value: "PARENT_APP",
  },
];

const generateDisabledCheckboxes = (appNames, type) => {
  return appNames.filter((app) => {
    if (app?.value !== "PARENT_APP") {
      return true;
    }
    if (type === DO_YOU_KNOW_TYPES.VIDEO) {
      return false;
    }
    return true;
  });
};

const UploadCard = ({ handleUpload, title }) => {
  const handleCustomRequest = ({ file, onSuccess }) => {
    return handleUpload(file, onSuccess);
  };

  const checkIfFileIsImageOrVideo = (file) => {
    // check if file is an jpeg or png or mp4
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "video/mp4"
    ) {
      message.error(
        "Only jpeg or png images are allowed!\n.Videos of mp4 types are allowed"
      );
      return false;
    }
    return true;
  };

  return (
    <div className="flex items-center text-darkerGrey">
      <p className="font-medium text-sm text-darkerGrey">{title}</p>
      <Upload
        customRequest={handleCustomRequest}
        beforeUpload={checkIfFileIsImageOrVideo}
        showUploadList={false}
        className="ml-5 bg-white py-2 px-3 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Button
          style={{
            borderRadius: 6,
            fontWeight: "bold",
            color: "#374151",
          }}
        >
          Upload
        </Button>
      </Upload>
    </div>
  );
};

const DisplayURL = ({ type, url, handleAssetDelete }) => {
  switch (true) {
    case type === DO_YOU_KNOW_TYPES.TEXT:
      return null;
    case type === DO_YOU_KNOW_TYPES.VIDEO:
      return (
        <div className="relative">
          <video width={"100%"} controls>
            <source src={url} />
          </video>
          <Button
            buttonStyle="danger"
            className="mr-6 absolute right-4 top-4"
            onClick={handleAssetDelete}
          >
            Remove Video
          </Button>
        </div>
      );
    case type === DO_YOU_KNOW_TYPES.IMAGE:
      return (
        <div
          className="relative aspect-video"
          style={{ aspectRatio: ASPECT_RATIO }}
        >
          <img
            src={url}
            alt=""
            style={{
              width: "100%",
              maxHeight: 60,
              maxWidth: 100,
            }}
          />
          <Button
            buttonStyle="danger"
            className="mr-6 absolute right-4 top-4"
            onClick={handleAssetDelete}
          >
            Remove Image
          </Button>
        </div>
      );
    default:
      return null;
  }
};

const setRadioState = (isAdvertisement) => {
  if (isAdvertisement) return POST_TYPE.ADVERTISEMENT;
  return POST_TYPE.FACT;
};

const setCalendarState = (whenToShow) => {
  if (whenToShow && moment(whenToShow).isValid()) return whenToShow;
  return null;
};

const DrawerDetails = ({
  isAdvertisement = false,
  whenToShow = moment().format(DATE_FORMATS.YYYYMMDD),
  text = "",
  headline = "",
  redirectUrl: redirectionURL = "",
  url = null,
  appName = [],
  type = DO_YOU_KNOW_TYPES.TEXT,
  isShareable = true,
  handleCancelClick,
  handleSaveClick,
}) => {
  const [radioValue, setRadioValue] = useState(() =>
    setRadioState(isAdvertisement)
  );
  const [selectedApps, setSelectedApps] = useState(appName);
  const [postText, setPostText] = useState(text);
  const [headlineText, setHeadlineText] = useState(headline);
  const [selectedDate, setSelectedDate] = useState(() =>
    setCalendarState(whenToShow)
  );
  const [assetURL, setAssetURL] = useState(url);
  const [redirectURL, setRedirectURL] = useState(redirectionURL);
  const [canBeShared, setCanBeShared] = useState(isShareable);
  const [assetType, setAssetType] = useState(type);

  useEffect(() => {
    if (radioValue === POST_TYPE.FACT) {
      setRedirectURL(null);
      setHeadlineText(
        headline && type === POST_TYPE.FACT ? headline : "Do You Know?"
      );
      return;
    }
    setHeadlineText(headline || "");
  }, [radioValue, headline, type]);

  const onDateChange = (_, dateString) => {
    setSelectedDate(dateString);
  };

  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleAssetDelete = () => {
    setAssetType(DO_YOU_KNOW_TYPES.TEXT);
    setAssetURL(null);
  };

  const { mutate: uploadFile } = useUploadDoYouKnowAsset();

  const handleUpload = async (file) => {
    uploadFile(file, {
      onSuccess: (res) => {
        const fileType = file?.type?.split("/")?.[0];
        if (fileType === "video") {
          setAssetType(DO_YOU_KNOW_TYPES.VIDEO);
        } else {
          setAssetType(DO_YOU_KNOW_TYPES.IMAGE);
        }
        setAssetURL(res?.url);
      },
    });
  };

  const onSave = () => {
    const payload = {
      text: postText,
      appName: selectedApps,
      url: assetURL,
      type: assetType,
      whenToShow: selectedDate,
      isShareable: canBeShared,
      isAdvertisement: radioValue === POST_TYPE.ADVERTISEMENT ? true : false,
      headline: headlineText,
      redirectUrl: redirectURL,
    };
    handleSaveClick(payload);
  };

  return (
    <div className="my-5 mx-3">
      <p className="font-medium text-sm text-darkerGrey">Post Type</p>
      <Radio.Group onChange={onChange} value={radioValue}>
        <Radio value={POST_TYPE.FACT}>{POST_TYPE.FACT}</Radio>
        <Radio value={POST_TYPE.ADVERTISEMENT}>{POST_TYPE.ADVERTISEMENT}</Radio>
      </Radio.Group>
      {radioValue === POST_TYPE.ADVERTISEMENT ? (
        <div className="mt-5">
          <input
            value={redirectURL}
            onChange={(e) => setRedirectURL(e.target.value)}
            placeholder="Enter url..."
            className="w-full border px-2 py-2 text-primary"
          />
        </div>
      ) : null}
      <div className="mt-5">
        <p className="font-medium text-sm text-darkerGrey">Headline text</p>
        <input
          value={headlineText}
          onChange={(e) => setHeadlineText(e.target.value)}
          placeholder="Enter text here..."
          className="w-full border px-2 py-2"
        />
      </div>
      <div className="mt-5">
        <p className="font-medium text-sm text-darkerGrey">Post text</p>
        <textarea
          name="Text1"
          rows="5"
          className="w-full border px-2 py-2"
          placeholder="Enter text here..."
          value={postText}
          onChange={(e) => setPostText(e.target.value)}
        />
      </div>
      {assetURL ? (
        <div className="w-full">
          <DisplayURL
            type={assetType}
            url={assetURL}
            handleAssetDelete={handleAssetDelete}
          />
        </div>
      ) : (
        <div className="mt-4">
          <span className="flex items-center flex-1">
            <p className="font-medium text-sm text-darkerGrey">
              Attach documents
            </p>
            <div className="text-textGrey ml-5 flex items-center">
              <InfoCircleFilled />
              <p className="ml-1">You can either upload image or a video</p>
            </div>
          </span>
          <UploadCard handleUpload={handleUpload} title="Add image/video" />
          <p className="font-medium text-xs text-red-500">
            For Images please use aspect ratio <strong>1.8</strong>
          </p>
        </div>
      )}
      <div className="mt-5">
        <p className="font-medium text-sm text-darkerGrey">Send To</p>
        <Checkbox.Group
          style={{ display: "flex", flexDirection: "column", marginTop: 8 }}
          options={generateDisabledCheckboxes(APP_NAMES, assetType)}
          onChange={(checkedValues) => {
            setSelectedApps(checkedValues);
          }}
          defaultValue={selectedApps}
          value={selectedApps}
        />
      </div>
      <div className="mt-5">
        <p className="font-medium text-sm text-darkerGrey">Schedule On</p>
        <DatePicker
          onChange={onDateChange}
          value={moment(selectedDate)}
          format={"MMM D, YYYY"}
          suffixIcon={<CalendarOutlined style={{ color: "#111827" }} />}
        />
      </div>
      <div className="flex mt-4">
        <p className="font-medium text-sm text-darkerGrey mr-4">
          Shareable Post
        </p>
        <Switch
          checked={canBeShared}
          onChange={(newCanBeShared) => setCanBeShared(newCanBeShared)}
        />
      </div>
      <div className="flex flex-1 justify-end mt-3">
        <div className="flex">
          <Button className="mr-6" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button buttonStyle="primary" className="mr-6" onClick={onSave}>
            Save Post
          </Button>
        </div>
      </div>
    </div>
  );
};

const DoYouKnowRow = (props) => {
  const {
    id,
    type,
    url,
    text,
    whenToShow,
    isAdvertisement,
    handleDrawerOpen,
    appName,
    headline,
  } = props;

  const imageWidth = 100;
  const imageHeight = imageWidth / ASPECT_RATIO;

  return (
    <tr id={id}>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
        <div className="flex items-center">
          {type === DO_YOU_KNOW_TYPES.IMAGE ? (
            <img
              src={url}
              alt=""
              style={{ width: imageWidth, height: imageHeight }}
              className="mr-2"
            />
          ) : null}
          <div>
            <p className="text-darkerGrey font-medium text-xl">{headline}</p>
            <p className="text-darkerGrey font-medium text-l">{text}</p>
          </div>
        </div>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
        <p className="text-darkerGrey font-normal text-base">
          {moment(whenToShow).format(DATE_FORMATS.DMMMYYYY)}
        </p>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
        <p className="text-darkerGrey font-normal text-base">
          {isAdvertisement ? "Advertisement" : "Fact"}
        </p>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
        {appName?.map((app) => (
          <p
            className="text-darkerGrey font-normal text-base"
            key={`${id}-${app}`}
          >
            {NAME_MAPPER[app]}
          </p>
        ))}
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
        <span
          className="text-indigo-600 hover:text-indigo-900 cursor-pointer font-medium text-l"
          onClick={(e) => {
            e.preventDefault();
            handleDrawerOpen(id);
          }}
        >
          Details
        </span>
      </td>
    </tr>
  );
};

const DoYouKnow = () => {
  const [currentDoYouKnow, setCurrentDoYouKnow] = useState(null);
  const [drawerDetails, setDrawerDetails] = useState({
    isDrawerOpen: false,
    isNewDoYouKnow: false,
  });

  const navigate = useNavigate();

  const handleBackPress = () => {
    navigate(-1);
  };

  const handleDrawerOpen = (doYouKnowId) => {
    setCurrentDoYouKnow(doYouKnowId);
    setDrawerDetails({
      isDrawerOpen: true,
      isNewDoYouKnow: false,
    });
  };

  const handleDrawerClose = () => {
    setDrawerDetails({
      isDrawerOpen: false,
      isNewDoYouKnow: false,
    });
    setCurrentDoYouKnow(null);
  };

  const handleNewPress = () => {
    setDrawerDetails({
      isDrawerOpen: true,
      isNewDoYouKnow: true,
    });
  };

  useEffect(() => {
    const isUserSuperAdmin = getUserRole()?.includes(USER_ROLES.ADMIN);
    if (!isUserSuperAdmin) {
      navigate(-1);
    }
  }, [navigate]);

  const { data: doYouKnowData, refetch: refetchDoYouKnow } =
    useGetAllDoYouKnows();
  const { mutate: createDoYouKnow } = useCreateDoYouKnow();
  const { mutate: deleteDoYouKnow } = useDeleteDoYouKnow();
  const { mutate: updateDoYouKnow } = useUpdateDoYouKnow();

  const doYouKnow = doYouKnowData?.find(
    (doYouKnowObject) => doYouKnowObject?.id === currentDoYouKnow
  );

  const handleSaveClick = (payload) => {
    createDoYouKnow(payload, {
      onSuccess: () => {
        refetchDoYouKnow();
      },
    });
    handleDrawerClose();
  };

  const handleDelete = () => {
    deleteDoYouKnow(
      {
        id: doYouKnow?.id,
      },
      {
        onSuccess: () => {
          refetchDoYouKnow();
        },
      }
    );
    handleDrawerClose();
  };

  const handleUpdate = (payload) => {
    updateDoYouKnow(
      { ...payload, id: currentDoYouKnow },
      {
        onSuccess: () => {
          refetchDoYouKnow();
        },
      }
    );
    handleDrawerClose();
  };

  return (
    <div className="my-3 mx-10">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-3xl">Manage Post</p>
        <Button buttonStyle="secondary" onClick={handleBackPress}>
          Back to Admin Settings
        </Button>
      </div>
      <div className="mt-4">
        <Button buttonStyle="primary" onClick={handleNewPress}>
          <PlusCircleOutlined className="mr-3" />
          Create new Post
        </Button>
      </div>
      <div className="inline-block min-w-full shadow rounded-t-3xl overflow-hidden mt-4">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                scope="col"
                className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
              >
                Post
              </th>
              <th
                scope="col"
                className="w-24 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
              >
                Scheduled on
              </th>
              <th
                scope="col"
                className="w-24 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
              >
                Type
              </th>
              <th
                scope="col"
                className="w-24 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
              >
                Apps
              </th>
              <th
                scope="col"
                className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-right text-sm uppercase font-normal"
              ></th>
            </tr>
          </thead>
          <tbody>
            {doYouKnowData?.map((doYouKnow, idx) => (
              <DoYouKnowRow
                {...doYouKnow}
                handleDrawerOpen={handleDrawerOpen}
                key={doYouKnow?.id}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Drawer
        title={
          <div className="flex flex-1 justify-between">
            <p className="text-lg leading-7 font-medium">
              {drawerDetails.isNewDoYouKnow ? "Create Post" : "Details"}
            </p>
            {!drawerDetails.isNewDoYouKnow ? (
              <Button
                buttonStyle="danger"
                className="mr-6"
                onClick={handleDelete}
              >
                Delete Post
              </Button>
            ) : null}
          </div>
        }
        placement="right"
        onClose={handleDrawerClose}
        visible={drawerDetails.isDrawerOpen}
        destroyOnClose
        contentWrapperStyle={{
          width: "35%",
        }}
        headerStyle={{
          height: "76px",
        }}
        bodyStyle={{
          padding: 0,
        }}
        closeIcon={<CloseIcon />}
      >
        <DrawerDetails
          {...doYouKnow}
          handleCancelClick={handleDrawerClose}
          handleSaveClick={
            drawerDetails.isNewDoYouKnow ? handleSaveClick : handleUpdate
          }
        />
      </Drawer>
    </div>
  );
};

export default DoYouKnow;
