import React, { useState } from "react";
import { Spin } from "antd";
import Button from "../../../components/Button";
import { Dropdown } from "../../../components";
import manageIllustration from "../../../assets/images/manage.png";
import { useNavigate } from "react-router-dom";
import { DownloadIcon } from "@heroicons/react/outline";
import { getSchoolID } from "../../../shared/getSchoolID";
import { MONTH_OPTIONS, DATE_FORMATS } from "../../../utils/constants";
import useMonthlyTransactions from "../../../hooks/useMonthlyTransactions";
import useFilterOptions from "../../../hooks/useFilterOptions";
import useDownloadMonthlyTransactionsReport from "../../../hooks/useDownloadMonthlyTransactionsReport";
import moment from "moment";
import useTracking from "../../../hooks/useTracking";

function TransactionsTable({ filterData }) {
  const { data: monthData, isLoading: isLoadingTransactions } =
    useMonthlyTransactions(
      {
        ...filterData,
      },
      {
        enabled: filterData?.schoolId !== undefined,
        refetchOnWindowFocus: false,
      }
    );
  const monthlyTransacations = monthData?.dateList;
  const navigate = useNavigate();

  if (monthlyTransacations?.length === 0 && isLoadingTransactions === false) {
    return (
      <div className="mb-0 pb-0 object-fit h-96 flex mt-44 flex-col place-items-center">
        <img src={manageIllustration} alt="" className=" max-h-full" />
        <p className="font-bold text-3xl mt-9">No data found</p>
      </div>
    );
  }

  const handleViewDetails = (date) => {
    date = moment(date, DATE_FORMATS.DDMMMYYYY).format(DATE_FORMATS.YYYYMMDD);
    navigate(`/fees-reports/daily-transactions`, {
      state: {
        fromDate: date,
        toDate: date,
      },
    });
  };

  return (
    <Spin spinning={isLoadingTransactions}>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-right text-sm uppercase font-medium"
                >
                  Credit Amount
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-right text-sm uppercase font-medium"
                >
                  Debit Amount
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                ></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(monthlyTransacations) &&
                monthlyTransacations?.map((studentData, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center text-left">
                          <div className="ml-3">
                            <p className="text-black font-medium whitespace-no-wrap">
                              {studentData?.date}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-success text-right">
                          {studentData?.credit || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-darkRed text-right">
                          {studentData?.debit || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right">
                        <button
                          className="text-primary self-start font-medium bg-white underline"
                          onClick={(e) => {
                            handleViewDetails(studentData?.date);
                          }}
                        >
                          View details
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Spin>
  );
}

function MonthlyTransactions() {
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({});
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const { mutateAsync: downloadReport } = useDownloadMonthlyTransactionsReport({
    onSettled: () => {
      setIsDownloadingReport(false);
    },
  });
  const { data: filterOptions, isLoading: isLoadingOptions } = useFilterOptions(
    {
      schoolId: getSchoolID(),
      entity: "monthlyTransactions",
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setFilterData({
          schoolId: getSchoolID(),
          month: moment().month() + 1,
          academicYearId: data?.academicYears?.[0].value,
        });
      },
    }
  );
  const { trackEvent } = useTracking();

  const monthValue = () => {
    const id = filterData?.month;
    if (!id) return null;
    // eslint-disable-next-line eqeqeq
    const month = MONTH_OPTIONS.find(({ value }) => value == id);
    return month?.label;
  };

  const academicYearValue = () => {
    const id = filterData?.academicYearId;
    if (!id) return null;
    // eslint-disable-next-line eqeqeq
    const ay = filterOptions?.academicYears.find(({ value }) => value == id);
    return ay?.label;
  };

  const handleDownloadReport = async () => {
    const month = monthValue();
    const year = academicYearValue();
    const fileName = `${month}-${year}_Transactions.pdf`;
    setIsDownloadingReport(true);
    trackEvent("Download Monthly Transactions Report");
    await downloadReport({
      ...filterData,
      fileName,
    });
  };

  return (
    <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={isDownloadingReport}>
        <div className="flex flex-row gap-x-4 items-center justify-between mb-8">
          <p className="text-2xl text-left font-bold flex-col m-0">
            Monthly Transactions
          </p>
          <Button buttonStyle="secondary" onClick={() => navigate(-1)}>
            Back to Reports
          </Button>
        </div>
        <div className="flex items-start justify-between">
          {isLoadingOptions ? (
            <div className="flex gap-x-7 h-auto">
              <Spin spinning />
            </div>
          ) : (
            <div className="flex flex-row gap-x-5">
              <Dropdown
                value={academicYearValue()}
                defaultValue="Academic Year : -"
                type="primary"
                options={filterOptions?.academicYears}
                onItemClick={(value) => {
                  setFilterData({
                    ...filterData,
                    academicYearId: value,
                  });
                }}
              />
              <Dropdown
                value={monthValue()}
                defaultValue="Select : Month"
                type="primary"
                options={MONTH_OPTIONS}
                onItemClick={(value) => {
                  setFilterData({
                    ...filterData,
                    month: value,
                  });
                }}
              />
            </div>
          )}
          <Button
            onClick={handleDownloadReport}
            buttonStyle="success"
            className="py-3 px-4"
          >
            <DownloadIcon className="h-4 w-4" />
            <span className="ml-2">Download Report</span>
          </Button>
        </div>
        <TransactionsTable filterData={filterData} />
      </Spin>
    </div>
  );
}

export default MonthlyTransactions;
