import React, { useState } from "react";
import ReceivedAnnouncement from "../../components/Announcement/ReceivedAnnouncement";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import AttachmentGallery from "../../components/AttachmentGallery";
import greenSpeaker from "../../assets/images/speaker.png";

const ReceivedAnnouncementContainer = ({ loading, error, data }) => {
  const [attachmentModal, setAttachmentModal] = useState({
    isVisible: false,
    attachments: null,
  });

  const navigation = useNavigate();

  const handleSentViewAllClick = () => {
    navigation("/announcements/received");
  };

  const showAttachmentView = (attachments) => {
    setAttachmentModal({
      isVisible: true,
      attachments,
    });
  };
  const handleAttachmentModalClose = () => {
    setAttachmentModal({
      isVisible: false,
      attachments: null,
    });
  };

  const isEmpty =
    data?.notifications?.length === 0 ||
    data?.notifications?.length === undefined;

  return (
    <div className="flex flex-col flex-1 overflow-hidden pb-4 relative">
      <p className="text-darkerGrey font-semibold text-xl text-left px-2 bg-clayPink p-4 rounded-t-xl pl-4">
        Received (From Super Admin)
      </p>

      {error && <div>Error! {error.message}</div>}
      {loading && <div>Loading...</div>}
      {isEmpty ? (
        <div className="w-full h-full bg-white flex flex-col justify-center items-center">
          <img src={greenSpeaker} alt="Green Speaker" className="w-1/2" />
          <div className="mt-5 w-2/3">
            <h4 className="text-lg font-bold text-lightBlack text-center">
              No Announcement Received
            </h4>
            <p className="text-purpleDark text-sm text-center font-medium">
              You didn’t receive any announcement from the super admin
            </p>
          </div>
        </div>
      ) : (
        <div className="overflow-y-scroll h-full px-4 bg-white pb-4 scrollbar-cream">
          {data?.notifications?.map((notification, index) => (
            <ReceivedAnnouncement
              id={index}
              key={notification.id}
              notification={notification}
              onAssetClick={showAttachmentView}
            />
          ))}
        </div>
      )}

      {!isEmpty && (
        <div className="absolute bottom-0 flex w-full justify-center">
          <Button
            buttonStyle="primary"
            onClick={() => {
              handleSentViewAllClick();
            }}
            className="w-2/3"
          >
            View All
          </Button>
        </div>
      )}
      <Modal
        open={attachmentModal.isVisible}
        centered
        footer={null}
        destroyOnClose
        width="80%"
        bodyStyle={{
          padding: 50,
          height: 650,
        }}
        onCancel={handleAttachmentModalClose}
      >
        <AttachmentGallery attachments={attachmentModal.attachments} />
      </Modal>
    </div>
  );
};

export default ReceivedAnnouncementContainer;
