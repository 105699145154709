import { SortableContext } from "@dnd-kit/sortable";

// Code Reference: https://codesandbox.io/s/antd-table-dnd-sortable-forked-9vunb5?file=/src/App.js:3498-3969
function DraggableWrapperGenerator(sortingStrategy) {
  return function DraggableWrapper(props) {
    const { children, ...restProps } = props;
    /**
     * 'children[1]` is `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    return children[1] instanceof Array ? (
      <SortableContext
        items={children[1].map((child) => child.key)}
        strategy={sortingStrategy}
        {...restProps}
      >
        <tbody {...restProps}>
          {
            // This invokes `Table.components.body.row` for each element of `children`.
            children
          }
        </tbody>
      </SortableContext>
    ) : (
      <tbody {...restProps}>{children}</tbody>
    );
  };
}

export default DraggableWrapperGenerator;
