import React, { useState } from "react";
import usePerformanceSubjects from "../../hooks/usePerformanceSubjects";
import usePerformanceTopics from "../../hooks/usePerformanceTopics";
import ProgressBar from "../../components/ProgressBar";
import TintImage from "../../components/TintImage";
import Overlay from "./Overlay";

const getTralingDots = (inputString, cutoffLength) => {
  if (inputString?.length <= cutoffLength) return inputString;
  let requiredString = inputString?.slice(0, cutoffLength - 3);
  requiredString += "...";
  return requiredString;
};

const colors = {
  crimsonRed: "#E81A3F",
  lightningYellow: "#F9B725",
  malachite: "#09BE51",
};

const levelColors = {
  0: colors.crimsonRed,
  1: colors.crimsonRed,
  2: colors.lightningYellow,
  3: colors.lightningYellow,
  4: colors.malachite,
  5: colors.malachite,
  6: colors.malachite,
};

const TopicCard = ({ level, title, score, points, numberOfAttempts }) => {
  const barColor = levelColors[level] || colors.crimsonRed;

  return (
    <article className="block w-full min-h-20 mt-3 px-3 py-2 border-2 border-fadedGrey rounded-lg text-left">
      <div className="flex flex-1 justify-between">
        <p className="text-sm text-darkerGrey font-semibold">{title}</p>
        <p className="text-studentIndigo text-xs whitespace-nowrap pl-2">
          Highest Score
        </p>
      </div>
      <div className="flex flex-1 justify-between my-1 items-center">
        <ProgressBar
          fillColor={barColor}
          maximumValue={points}
          currentValue={score}
        />
        <p className="font-semibold text-sm text-primary ml-2">{`${score}/${points}`}</p>
      </div>
      <div className="flex flex-1">
        <p className="text-fainterGrey font-medium text-xs">
          Average Score: {score}
        </p>
        <p className="text-fainterGrey font-medium text-xs ml-3">
          Number of attempts: {numberOfAttempts}
        </p>
      </div>
    </article>
  );
};

const LeftArrow = ({ onClick }) => (
  <figure className="sticky -ml-1 cursor-pointer left-0 z-10" onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#111827"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  </figure>
);

const RightArrow = ({ onClick }) => (
  <figure
    className="sticky -ml-2 cursor-pointer right-1 z-10"
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#111827"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  </figure>
);

const SUBJECT_BOX_SIZE = 62;
const TOPIC_SIZE = SUBJECT_BOX_SIZE * 2;

const SubjectListItem = (props) => {
  const {
    isActive,
    learningId,
    subjectName,
    imageUrl,
    setActiveTab,
    backgroundColor,
    fillColor,
    gradientColor,
  } = props;
  const subjectColor = "#6B7280";
  const borderColor = isActive ? "white" : subjectColor;
  const imageColor = isActive ? "white" : subjectColor;
  const boxColor = isActive ? "#4F46E5" : "white";

  const handlePress = () => {
    setActiveTab({
      id: learningId,
      backgroundColor,
      fillColor,
      gradientColor,
      subjectName,
    });
  };

  return (
    <div
      className="flex flex-col ml-2.5 mr-4 cursor-pointer"
      style={{ width: SUBJECT_BOX_SIZE }}
      onClick={handlePress}
    >
      <div
        className="flex flex-col items-center justify-center overflow-hidden rounded-xl border-2"
        style={{
          width: SUBJECT_BOX_SIZE,
          height: SUBJECT_BOX_SIZE,
          borderColor,
          backgroundColor: boxColor,
        }}
      >
        {TintImage(imageColor, learningId)}
        <img
          src={imageUrl}
          className={`h-3/4 w-3/4 object-contain`}
          alt="Subject Selection Button Box"
          style={{ filter: `url(#tint-${learningId})` }}
        />
      </div>
      <p
        className="font-semibold text-xs text-center"
        style={{ color: isActive ? "#4F46E5" : "#6B7280" }}
      >
        {getTralingDots(subjectName, 11)}
      </p>
    </div>
  );
};

const QuizPerformance = ({ studentId, hasActiveSubscription }) => {
  const [activeTab, setActiveTab] = useState({
    id: null,
    backgroundColor: "",
    fillColor: "",
    gradientColor: "",
    subjectName: "",
  });

  const [rightClicks, setRightClicks] = useState(0);

  const { data: performanceData, isLoading } = usePerformanceSubjects(
    studentId,
    {
      onSuccess: (data) => {
        const { subjectList } = data;
        const firstSubject = subjectList?.[0];

        if (!activeTab.id) {
          setActiveTab({
            id: firstSubject?.learningId,
            backgroundColor: firstSubject?.backgroundColor,
            fillColor: firstSubject?.fillColor,
            gradientColor: firstSubject?.gradientColor,
            subjectName: firstSubject?.subjectName,
          });
        }
      },
    }
  );

  const handleRightClick = () => {
    const subjectContainer = document.getElementById("topic-list");
    subjectContainer.scrollLeft += SUBJECT_BOX_SIZE + 20;
    setRightClicks(rightClicks + 1);
  };

  const handleLeftClick = () => {
    const subjectContainer = document.getElementById("topic-list");
    subjectContainer.scrollLeft -= SUBJECT_BOX_SIZE + 20;
    setRightClicks(rightClicks - 1);
  };

  const subjectList = isLoading ? [] : [...performanceData?.subjectList];

  const {
    data: topicsData,
    isLoading: isSubjectLoading,
    refetch: refetchData,
    isFetching,
  } = usePerformanceTopics(studentId, activeTab?.id);

  const rightArrowCondition = subjectList?.length > 3;
  const leftArrowCondition = rightClicks > 0;

  return (
    <article className="block w-full h-full bg-white px-3 py-2 rounded-xl relative card-shadow">
      {!hasActiveSubscription && <Overlay />}
      <header>
        <h1 className="text-left font-semibold text-darkerGrey text-xl">
          Quiz Performance
        </h1>
      </header>

      <section
        className="flex flex-row overflow-x-hidden justify-between items-center w-full relative"
        id="topic-list"
      >
        {leftArrowCondition && <LeftArrow onClick={handleLeftClick} />}
        {isLoading ? null : (
          <div className="flex flex-1 justify-start relative mt-1">
            {subjectList?.map((subject, idx) => (
              <SubjectListItem
                {...subject}
                isActive={subject?.learningId === activeTab?.id}
                setActiveTab={setActiveTab}
                key={idx}
              />
            ))}
          </div>
        )}
        {rightArrowCondition && <RightArrow onClick={handleRightClick} />}
      </section>

      <section
        className="flex flex-1 w-full flex-col overflow-y-scroll"
        style={{
          height: `calc(100% - ${TOPIC_SIZE}px)`,
        }}
      >
        {topicsData?.topics?.map((topic) => (
          <TopicCard {...topic} />
        ))}
      </section>
    </article>
  );
};

export default QuizPerformance;
