import React, { useState, useEffect } from "react";
import {
  Modal,
  Switch,
  Radio,
  Select,
  Checkbox,
  DatePicker,
  Spin,
  Tag,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import deleteIcon from "../../../assets/images/delete.png";
import addIcon from "../../../assets/images/plus.png";
import moment from "moment";
import { useLocation } from "react-router-dom";
import CloseIcon from "../../../components/closeIcon";
import { useQuery } from "@apollo/client";
import { GET_ACADEMIC_YEARS } from "../../../shared/graphql";
import { getSchoolID } from "../../../shared/getSchoolID";
import Dropdown from "../../../components/Dropdown";
import { GET_CLASSES_AND_DIVISIONS } from "../../../views/studentsView/graphql";
import { getSortedClasses } from "../../../helpers";
import useCreateFeeStructure from "../../../hooks/useCreateFeeStructure";
import useUpdateFeeStructure from "../../../hooks/useUpdateFeeStructure";
import useTracking from "../../../hooks/useTracking";
import { DATE_FORMATS } from "../../../utils/constants";
import { Tooltip } from "antd";

const tagRender = ({ classIds, ...props }) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  // Find the corresponding option in the existing data based on the value
  const selectedOption = classIds?.find((classItem) => classItem.id === value);
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closeIcon={<CloseIcon width={18} height={18} />}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        width: 125,
        whiteSpace: "nowrap",
        display: "flex",
        justifyContent: "space-around",
        height: 30,
        alignItems: "center",
        color: "#374151",
      }}
    >
      {selectedOption?.name ? `Class: ${selectedOption?.name}` : label}
    </Tag>
  );
};

function NewStructure() {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [academicYears, setAcademicYears] = useState(null);
  const [classesData, setClassesData] = useState([]);
  const [formDetails, setFormDetails] = useState({
    classIds: [],
    particulars: [
      {
        newStudents: false,
        breakup: [],
      },
    ],
    notes: [],
    installmentDetails: [{}],
    acceptInstallments: false,
  });
  let navigate = useNavigate();
  const { state } = useLocation();
  const { trackEvent } = useTracking();
  const { structureData } = state || {};
  const selectedClassOptions = formDetails?.classIds?.map((classItem) => {
    const option = classesData.find((data) => data.value === classItem.id);
    return option ? option : { value: classItem.id, label: classItem.name };
  });

  useEffect(() => {
    if (structureData) {
      setFormDetails({
        ...formDetails,
        ...structureData,
      });
    }
  }, [structureData]);

  const { loading: isAcademicYearsLoading } = useQuery(GET_ACADEMIC_YEARS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (data) => {
      const academicYearsList = data.acadamic_years.map((academicYear) => {
        return {
          label: academicYear.title,
          value: academicYear.id,
          isCurrent: academicYear.is_current,
        };
      });
      setAcademicYears(academicYearsList);
      // find current academic year
      const currentAcademicYear = data.acadamic_years.find(
        (academicYear) => academicYear.is_current === true,
      );
      setFormDetails({
        ...formDetails,
        academicYear: {
          value: currentAcademicYear.id,
          label: currentAcademicYear.title,
        },
      });
    },
  });

  const { loading: classesLoading } = useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (classResponse) => {
      const sortedClasses = getSortedClasses(classResponse.classes);
      const classLists = [];
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
      });
      setClassesData(classLists);
    },
  });

  const { mutateAsync: createFeesStructure } = useCreateFeeStructure();
  const { mutateAsync: updateFeesStructure } = useUpdateFeeStructure();

  const handleAddParticular = () => {
    const updatedDetails = {
      ...formDetails,
      particulars: [
        ...(formDetails?.particulars || []),
        {
          breakup: [],
          newStudents: false,
        },
      ],
    };
    setFormDetails(updatedDetails);
  };

  const handleUpdateParticular = (key, value, updateIndex) => {
    const updatedParticulars = formDetails?.particulars?.map((item, index) => {
      if (index === updateIndex) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    const updatedDetails = {
      ...formDetails,
      particulars: updatedParticulars,
    };
    setFormDetails(updatedDetails);
  };

  const handleUpdateNote = (value, updateIndex) => {
    const updatedNotes = formDetails?.notes?.map((item, index) => {
      if (index === updateIndex) {
        return value;
      }
      return item;
    });
    const updatedDetails = {
      ...formDetails,
      notes: updatedNotes,
    };
    setFormDetails(updatedDetails);
  };

  const handleAddNote = () => {
    const updatedDetails = {
      ...formDetails,
      notes: [...(formDetails?.notes || []), ""],
    };
    setFormDetails(updatedDetails);
  };

  const handleDeleteNote = (noteIndex) => {
    const upadatedNotes = formDetails?.notes?.filter((item, index) => {
      return index !== noteIndex;
    });
    const updatedDetails = {
      ...formDetails,
      notes: upadatedNotes,
    };
    setFormDetails(updatedDetails);
  };

  const handleAddSubParticular = (updateIndex) => {
    const updatedParticulars = formDetails?.particulars?.map((item, index) => {
      if (index === updateIndex) {
        return {
          ...item,
          breakup: [
            ...(item?.breakup || []),
            {
              name: "",
              newStudents: false,
            },
          ],
        };
      }
      return item;
    });
    const updatedDetails = {
      ...formDetails,
      particulars: updatedParticulars,
    };
    setFormDetails(updatedDetails);
  };

  const handleUpdateSubParticular = (
    key,
    value,
    particularIndex,
    updateIndex,
  ) => {
    const updatedParticulars = formDetails?.particulars?.map((item, index) => {
      if (index === particularIndex) {
        const updatedSubParticulars = item?.breakup?.map(
          (subItem, subIndex) => {
            if (subIndex === updateIndex) {
              return {
                ...subItem,
                [key]: value,
              };
            }
            return subItem;
          },
        );
        return {
          ...item,
          breakup: updatedSubParticulars,
        };
      }
      return item;
    });
    // if key is amount, update the total amount of particular
    if (key === "amount") {
      const updatedParticular = updatedParticulars[particularIndex];
      const updatedAmount = updatedParticular?.breakup?.reduce(
        (n, { amount }) => n + (parseInt(amount, 10) || 0),
        0,
      );
      updatedParticulars[particularIndex] = {
        ...updatedParticular,
        amount: updatedAmount,
      };
    }
    const updatedDetails = {
      ...formDetails,
      particulars: updatedParticulars,
    };
    setFormDetails(updatedDetails);
  };

  const handleDeleteParticular = (deleteIndex) => {
    const updatedParticulars = formDetails?.particulars?.filter(
      (item, index) => {
        return index !== deleteIndex;
      },
    );
    const updatedDetails = {
      ...formDetails,
      particulars: updatedParticulars,
    };
    setFormDetails(updatedDetails);
  };

  const handleDeleteSubParticular = (particularIndex, deleteIndex) => {
    const updatedParticulars = formDetails?.particulars?.map((item, index) => {
      if (index === particularIndex) {
        const updatedSubParticulars = item?.breakup?.filter(
          (subItem, subIndex) => {
            return subIndex !== deleteIndex;
          },
        );
        return {
          ...item,
          breakup: updatedSubParticulars,
        };
      }
      return item;
    });
    const updatedDetails = {
      ...formDetails,
      particulars: updatedParticulars,
    };
    setFormDetails(updatedDetails);
  };

  const handleAddInstallment = () => {
    const updatedDetails = {
      ...formDetails,
      installmentDetails: [...(formDetails?.installmentDetails || []), {}],
    };
    setFormDetails(updatedDetails);
  };

  const handleDeleteInstallment = (deleteIndex) => {
    const updatedInstallments = formDetails?.installmentDetails?.filter(
      (item, index) => {
        return index !== deleteIndex;
      },
    );
    const updatedDetails = {
      ...formDetails,
      installmentDetails: updatedInstallments,
    };
    setFormDetails(updatedDetails);
  };

  const handleDeleteStructureClick = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleDeleteStructureConfirm = () => {
    trackEvent("Delete Fees Structure");
    updateFeesStructure({
      isActive: false,
      feeStructureId: formDetails?.id,
    });
    setShowConfirmDeleteModal(false);
    // navigate back
    navigate(-1);
  };

  const handleAcademicYearChange = (value) => {
    const selectedYear = academicYears.find(
      (academicYear) => academicYear.value === parseInt(value),
    );
    setFormDetails({
      ...formDetails,
      academicYear: selectedYear,
    });
  };

  const handleUpdateInstallment = (key, value, updateIndex) => {
    const updatedInstallments = formDetails?.installmentDetails?.map(
      (item, index) => {
        if (index === updateIndex) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      },
    );
    const updatedDetails = {
      ...formDetails,
      installmentDetails: updatedInstallments,
    };
    setFormDetails(updatedDetails);
  };

  const handleSaveFeesStructure = async () => {
    // validate form details - particulars title and amount should not be empty
    const isParticularsValid = formDetails?.particulars?.every((particular) => {
      return particular?.title && particular?.amount;
    });
    if (!isParticularsValid) {
      notification["error"]({
        message: "Failure",
        description: "Particulars title and amount should not be empty",
        duration: 1.5,
      });
      return;
    }
    // validate installment details - total installment amount should not exceed total particulars amount
    const totalInstallmentAmount = formDetails?.installmentDetails?.reduce(
      (n, { amount }) => n + parseInt(amount, 10) || 0,
      0,
    );
    const totalParticularsAmount = formDetails?.particulars?.reduce(
      (n, { amount }) => n + parseInt(amount, 10) || 0,
      0,
    );
    if (totalInstallmentAmount > totalParticularsAmount) {
      notification["error"]({
        message: "Failure",
        description:
          "Total installment amount should not exceed total particulars amount",
        duration: 1.5,
      });
      return;
    }

    if (formDetails?.id) {
      trackEvent("Update Fees Structure");
      const response = await updateFeesStructure({
        feeStructureId: formDetails?.id,
        title: formDetails?.title,
        academicYearId: formDetails?.academicYear?.value,
        classIds: formDetails?.classIds?.map((item) => item.id) || [],
        acceptInstallments: formDetails?.acceptInstallments,
        showInstallmentInPrint: formDetails?.showInstallmentInPrint,
        notes: formDetails?.notes,
        installmentDetails: formDetails?.installmentDetails,
        particulars: formDetails?.particulars,
      });
      if (response?.success) {
        navigate(-1);
      }
    } else {
      trackEvent("Create Fees Structure");
      const response = await createFeesStructure({
        title: formDetails?.title,
        academicYearId: formDetails?.academicYear?.value,
        classIds: formDetails?.classIds?.map((item) => item.id) || [],
        acceptInstallments: formDetails?.acceptInstallments,
        showInstallmentInPrint: formDetails?.showInstallmentInPrint,
        notes: formDetails?.notes,
        installmentDetails: formDetails?.installmentDetails,
        particulars: formDetails?.particulars,
      });
      if (response?.success) {
        navigate(-1);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
        <Spin spinning={isLoading}>
          <div className="flex flex-row gap-x-4 items-center mb-2 justify-between mr-7">
            <p className="text-2xl text-left font-bold flex-col m-0">
              {structureData ? "Edit Structure" : "New Structure"}
            </p>
            <Button
              id="attendancereport-back"
              buttonStyle="secondary"
              onClick={() => navigate(-1)}
            >
              Back to Fees Structure
            </Button>
          </div>
          <div className="relative bg-white rounded-lg pt-8 pl-8 pb-10 pr-8">
            <div className="flex flex-col border-opacity-10">
              <form className="flex flex-col gap-6 mt-2">
                <div className="flex flex-row gap-x-10">
                  <div className="flex flex-col">
                    <label className="text-greyLight font-medium text-sm leading-5 pb-1 text-left">
                      Fees Structure Name
                    </label>
                    <input
                      type="text"
                      className="w-72 text-darkerGrey font-medium border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                      value={formDetails.title}
                      required
                      placeholder="Type name here..."
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="text-greyLight font-medium text-sm leading-5 pb-1 text-left ">
                      Acadedmic Year
                    </label>
                    <Dropdown
                      type="default"
                      options={academicYears}
                      value={formDetails?.academicYear?.label}
                      onItemClick={handleAcademicYearChange}
                    />
                  </div>
                  {structureData && (
                    <Button
                      buttonStyle="danger"
                      className="h-30 mr-6 self-end absolute top-5 right-1"
                      onClick={() => {
                        handleDeleteStructureClick();
                      }}
                    >
                      Delete Structure
                    </Button>
                  )}
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-greyLight font-medium text-sm">
                    Applicable to
                  </p>
                  <Select
                    className="customised-antd-selector"
                    mode="multiple"
                    value={selectedClassOptions}
                    tagRender={(props) =>
                      tagRender({ classIds: formDetails?.classIds, ...props })
                    }
                    style={{
                      flex: 1,
                      width: "60%",
                      textAlign: "left",
                    }}
                    options={classesData}
                    optionFilterProp="label"
                    optionLabelProp="label"
                    onChange={(value) => {
                      const updatedClassIds = value.map((classId) => {
                        const existingClass = formDetails.classIds.find(
                          (classItem) => classItem.id === classId,
                        );
                        return existingClass
                          ? existingClass
                          : { id: classId, name: "" };
                      });
                      setFormDetails({
                        ...formDetails,
                        classIds: updatedClassIds,
                      });
                    }}
                    size="large"
                    allowClear
                    placeholder="Select classes"
                  />
                </div>
                {formDetails?.particulars?.map((particular, index) => {
                  return (
                    <>
                      <div className="flex flex-row gap-x-12 items-center">
                        <div className="flex flex-col">
                          <label className="text-greyLight font-medium text-sm leading-5 pb-1 text-left ">
                            Particulars
                          </label>
                          <input
                            type="text"
                            className="w-72 text-darkerGrey font-medium border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                            value={particular.title}
                            required
                            placeholder="Type particular title"
                            onChange={(e) =>
                              handleUpdateParticular(
                                "title",
                                e.target.value,
                                index,
                              )
                            }
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="text-greyLight font-medium text-sm leading-5 pb-1 text-left ">
                            Amount
                          </label>
                          <input
                            type="text"
                            className="border text-darkerGrey font-medium border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                            value={particular.amount}
                            disabled={particular?.breakup?.length > 0}
                            required
                            placeholder="Enter amount"
                            onChange={(e) =>
                              handleUpdateParticular(
                                "amount",
                                e.target.value,
                                index,
                              )
                            }
                          />
                        </div>
                        <img
                          alt="trash"
                          src={deleteIcon}
                          className="h-5 mt-5 -ml-8 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteParticular(index);
                          }}
                        />
                        {formDetails?.particulars?.length === index + 1 ? (
                          <img
                            alt="add"
                            src={addIcon}
                            className="h-6 mt-5 -ml-8 cursor-pointer"
                            onClick={handleAddParticular}
                          />
                        ) : null}
                      </div>
                      {particular?.breakup?.length === 0 && (
                        <div className="-mt-3">
                          <Radio.Group
                            onChange={(e) => {
                              handleUpdateParticular(
                                "newStudents",
                                e.target.value,
                                index,
                              );
                            }}
                            value={particular.newStudents}
                            style={{
                              display: "flex",
                              columnGap: "0.75rem",
                            }}
                          >
                            <div className="bg-white">
                              <Radio
                                value={false}
                                checked={particular.newStudents === false}
                              >
                                <strong
                                  className={`font-normal text-greyLight`}
                                >
                                  All students
                                </strong>
                              </Radio>
                            </div>
                            <div className="bg-white">
                              <Tooltip
                                placement="top"
                                color="#4f46e5"
                                title={
                                  "Only students who are/will be enrolled in new academic year"
                                }
                              >
                                <Radio
                                  value={true}
                                  checked={particular.newStudents === true}
                                >
                                  <strong
                                    className={`font-normal text-greyLight`}
                                  >
                                    Newly Enrolled
                                  </strong>
                                </Radio>
                              </Tooltip>
                            </div>
                            <div className="ml-14">
                              <Checkbox
                                checked={particular?.rteApplicable}
                                onChange={(e) => {
                                  handleUpdateParticular(
                                    "rteApplicable",
                                    e.target.checked,
                                    index,
                                  );
                                }}
                              >
                                <strong
                                  className={`font-normal text-greyLight`}
                                >
                                  Applicable to RTE also
                                </strong>
                              </Checkbox>
                            </div>
                          </Radio.Group>
                        </div>
                      )}
                      {particular?.breakup?.length > 0 && (
                        <div className="relative flex flex-col gap-y-6 h-auto">
                          {particular?.breakup?.length > 0 && (
                            <>
                              <div
                                className="border-l absolute left-0 bg-lightgrey -mt-5 ml-1"
                                style={{
                                  height: "calc(100% - 28px)",
                                }}
                              ></div>
                              <div className="border-b absolute bottom-12 bg-lightgrey w-3 ml-1"></div>
                            </>
                          )}
                          {particular?.breakup?.map((subItem, subIndex) => {
                            return (
                              <div class="relative flex">
                                {subIndex !==
                                  particular?.breakup?.length - 1 && (
                                  <div className="border-b left-0 absolute bottom-12 bg-lightgrey w-3 ml-1"></div>
                                )}
                                <div className="w-auto py-4 px-3 shadow-md ml-5 flex flex-col">
                                  <div className="flex flex-row gap-x-12">
                                    <div className="flex flex-col">
                                      <input
                                        type="text"
                                        className="w-64 text-darkerGrey font-medium border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                                        value={subItem.title}
                                        required
                                        placeholder="Type sub-particular title"
                                        onChange={(e) =>
                                          handleUpdateSubParticular(
                                            "title",
                                            e.target.value,
                                            index,
                                            subIndex,
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="flex flex-col">
                                      <input
                                        type="text"
                                        className="text-darkerGrey font-medium border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                                        value={subItem.amount}
                                        required
                                        placeholder="Enter amount"
                                        onChange={(e) =>
                                          handleUpdateSubParticular(
                                            "amount",
                                            e.target.value,
                                            index,
                                            subIndex,
                                          )
                                        }
                                      />
                                    </div>
                                    <img
                                      alt="trash"
                                      src={deleteIcon}
                                      className="h-5 mt-3 -ml-8 cursor-pointer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDeleteSubParticular(
                                          index,
                                          subIndex,
                                        );
                                      }}
                                    />
                                  </div>
                                  <Radio.Group
                                    onChange={(e) => {
                                      handleUpdateSubParticular(
                                        "newStudents",
                                        e.target.value,
                                        index,
                                        subIndex,
                                      );
                                    }}
                                    value={subItem.newStudents}
                                    style={{
                                      display: "flex",
                                      columnGap: "0.75rem",
                                      marginTop: "7px",
                                    }}
                                  >
                                    <div className="bg-white">
                                      <Radio
                                        value={false}
                                        checked={subItem.newStudents === false}
                                      >
                                        <strong
                                          className={`font-normal text-greyLight`}
                                        >
                                          All students
                                        </strong>
                                      </Radio>
                                    </div>
                                    <div className="bg-white">
                                      <Radio
                                        value={true}
                                        checked={subItem.newStudents === true}
                                      >
                                        <strong
                                          className={`font-normal text-greyLight`}
                                        >
                                          Newly Enrolled
                                        </strong>
                                      </Radio>
                                    </div>
                                    <div className="ml-6">
                                      <Checkbox
                                        checked={subItem?.rteApplicable}
                                        onChange={(e) => {
                                          handleUpdateSubParticular(
                                            "rteApplicable",
                                            e.target.checked,
                                            index,
                                            subIndex,
                                          );
                                        }}
                                      >
                                        <strong
                                          className={`font-normal text-greyLight`}
                                        >
                                          Applicable to RTE also
                                        </strong>
                                      </Checkbox>
                                    </div>
                                  </Radio.Group>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <button
                        className="text-primary self-start font-medium"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddSubParticular(index);
                        }}
                      >
                        + Add sub-particular
                      </button>
                    </>
                  );
                })}
                {/*<Button
                  buttonStyle="primary"
                  className="py-3 px-4 w-56"
                  onClick={handleAddParticular}
                >
                  Add New Particulars
                </Button>*/}
                <div className="h-16 bg-zumthorBlue flex gap-x-72 -mx-6 pl-8 pr-48 items-center border-t border-riverGrey">
                  <p className="text-darkerGrey font-semibold">Total Amount</p>
                  <p className="text-darkerGrey font-bold">
                    {formDetails?.particulars?.reduce(
                      (n, { amount }) => n + (parseInt(amount, 10) || 0),
                      0,
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-greyLight">
                    Want to accept fees in fixed installments?
                  </p>
                  <Radio.Group
                    onChange={(e) => {
                      setFormDetails({
                        ...formDetails,
                        acceptInstallments: e.target.value,
                      });
                    }}
                    value={formDetails.acceptInstallments}
                    style={{
                      display: "flex",
                      columnGap: "0.75rem",
                    }}
                  >
                    <div
                      id="documents-nirgam-edit-english"
                      className="bg-white"
                    >
                      <Radio
                        value={true}
                        checked={formDetails.acceptInstallments}
                      >
                        <strong className={`font-normal text-greyLight`}>
                          Yes
                        </strong>
                      </Radio>
                    </div>
                    <div
                      id="documents-nirgam-edit-marathi"
                      className="bg-white"
                    >
                      <Radio
                        value={false}
                        checked={formDetails.acceptInstallments === false}
                      >
                        <strong className={`font-normal text-greyLight`}>
                          No
                        </strong>
                      </Radio>
                    </div>
                  </Radio.Group>
                </div>
                {formDetails?.acceptInstallments && (
                  <>
                    {formDetails?.installmentDetails?.map(
                      (installment, installmentIndex) => {
                        return (
                          <div className="flex flex-row gap-x-5 items-center">
                            <div className="flex flex-col">
                              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                                Name
                              </label>
                              <input
                                type="text"
                                className="text-darkerGrey font-medium border box-border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                                value={installment?.name}
                                required
                                placeholder="Type particular title"
                                onChange={(e) =>
                                  handleUpdateInstallment(
                                    "name",
                                    e.target.value,
                                    installmentIndex,
                                  )
                                }
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                                Due Date
                              </label>
                              <DatePicker
                                value={
                                  installment?.dueDate
                                    ? moment(
                                        installment?.dueDate,
                                        DATE_FORMATS.YYYYMMDD,
                                      )
                                    : null
                                }
                                format={DATE_FORMATS.DDMMYYYY}
                                style={{
                                  borderRadius: "12px",
                                  height: "40px",
                                }}
                                onChange={(date) =>
                                  handleUpdateInstallment(
                                    "dueDate",
                                    moment(date).format(DATE_FORMATS.YYYYMMDD),
                                    installmentIndex,
                                  )
                                }
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                                Amount
                              </label>
                              <input
                                type="text"
                                className="text-darkerGrey font-medium border box-border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                                value={installment?.amount}
                                required
                                placeholder="Enter amount"
                                onChange={(e) =>
                                  handleUpdateInstallment(
                                    "amount",
                                    e.target.value,
                                    installmentIndex,
                                  )
                                }
                              />
                            </div>
                            <img
                              alt="trash"
                              src={deleteIcon}
                              className="h-5 mt-5 cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteInstallment(installmentIndex);
                              }}
                            />
                          </div>
                        );
                      },
                    )}
                    <button
                      className="text-primary self-start font-medium -mt-4"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAddInstallment();
                      }}
                    >
                      + Add new installment
                    </button>
                    <div className="flex gap-x-8">
                      <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                        Show Installment details in print
                      </label>
                      <Switch
                        checked={formDetails?.showInstallmentInPrint}
                        onChange={(checked) =>
                          setFormDetails({
                            ...formDetails,
                            showInstallmentInPrint: checked,
                          })
                        }
                      />
                    </div>
                  </>
                )}
                <div className="flex flex-col">
                  <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                    Note
                  </label>
                  {formDetails?.notes?.map((noteItem, noteIndex) => {
                    return (
                      <>
                        <div className="flex flex-row gap-x-12 items-center">
                          <input
                            type="text"
                            className="w-3/5 text-darkerGrey font-medium border mt-1 border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 placeholder-textGrey"
                            value={noteItem}
                            required
                            placeholder="Type note here..."
                            onChange={(e) =>
                              handleUpdateNote(e.target.value, noteIndex)
                            }
                          />
                          <img
                            alt="delete"
                            src={deleteIcon}
                            className="h-5 -ml-8 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteNote(noteIndex);
                            }}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
                <button
                  className="text-primary self-start font-medium -mt-4"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddNote();
                  }}
                >
                  + Add new note
                </button>
                <div className="flex gap-x-6">
                  <Button
                    buttonStyle="primary"
                    className="py-3 px-4 w-44 h-12"
                    onClick={handleSaveFeesStructure}
                  >
                    {structureData ? "Save Changes" : "Create Structure"}
                  </Button>
                  <Button
                    className="py-3 px-4 w-32 h-12"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Spin>
      </div>
      <Modal
        open={showConfirmDeleteModal}
        centered
        closable={false}
        bodyStyle={{
          justifyContent: "center",
          alignItems: "center",
          overflowX: "auto",
          textAlign: "center",
        }}
        footer={null}
      >
        <h3 className="text-3xl font-semibold">
          Are you sure you want to delete the fee structure?
        </h3>
        <div className="flex gap-x-10 justify-center mt-9">
          <Button
            buttonStyle="primary"
            onClick={handleDeleteStructureConfirm}
            style={{
              background: "#EF4444",
              height: 40,
              width: 100,
            }}
          >
            <p className="font-bold">Yes</p>
          </Button>
          <Button
            buttonStyle="primary"
            onClick={() => {
              setShowConfirmDeleteModal(false);
            }}
            style={{
              background: "#22C55E",
              height: 40,
              width: 100,
            }}
          >
            <p className="font-bold">No</p>
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default NewStructure;