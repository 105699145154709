import { useState } from "react";
import axios from "axios";

const useImportExam = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const apiUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/exams/v1/createMultipleCustomExams`;

  const fetchData = async (payload) => {
    try {
      const result = await axios.request({
        method: "POST",
        url: apiUrl,
        data: payload,
      });
      setData(result.data);
      return result.data;
    } catch (error) {
      setError(error);
      return error;
    } finally {
      setLoading(false);
    }
  };

  return [fetchData, { data, error, loading, refetch: fetchData }];
};

export default useImportExam;
