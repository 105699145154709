/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const tranformData = (expensesAndCategories) => {
  const { expenseCategories = [], paymentMode = [] } = expensesAndCategories;
  const formattedCategories = expenseCategories.map((category) => ({
    label: category.title,
    value: category.id,
  }));
  const allExpense = {
    label: "All",
    value: null,
  };
  const finalCategories = [allExpense, ...formattedCategories];

  const formattedModes = paymentMode.map((mode) => ({
    label: mode,
    value: mode,
  }));
  const allMode = {
    label: "All",
    value: null,
  };
  const finalModes = [allMode, ...formattedModes];

  return {
    category: finalCategories,
    amount: finalModes,
  };
};

const getCategories = async (payload) => {
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/expenses/getExpenseCategories`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useGetExpenseCategories(payload, options = {}) {
  return useQuery(["categories"], () => getCategories(payload), {
    select: tranformData,
    ...options,
  });
}
