import React from "react";
import Button from "../components/Button";

export default function ConfirmModal({ message, cancelText, okText, onCancel, onConfirm, visible }) {

	return (
		<>
			{visible ?
				<div>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-md">
						<div className="relative w-auto my-6 mx-auto max-w-3xl h-48">
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center justify-center w-full h-full bg-white outline-none focus:outline-none">
								<div className="flex items-center pl-5 pr-5 pb-7 justify-center rounded-t">
									<h3 className="text-xl font-semibold">
										{message}
									</h3>
								</div>
								<div className="flex items-center justify-center rounded-b">
									<Button
										buttonStyle="danger"
										className="h-30 mr-6"
										onClick={() => {
											onCancel()
										}}
									>
										{cancelText}
									</Button>
									<Button
										buttonStyle="success"
										onClick={() => {
											onConfirm()
										}}
										className="h-30"
									>
										{okText}
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</div>
				: null
			}

		</>
	);
}
