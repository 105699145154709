import lockIcon from "../../assets/icons/lock.png";

const Overlay = () => (
  <div
    className="w-full h-full flex flex-col flex-1 absolute -mx-3 -my-2 rounded-xl justify-center items-center"
    style={{ zIndex: 999999, background: "rgba(0, 0, 0, 0.6)" }}
  >
    <img src={lockIcon} alt="Lock" style={{ width: 80, height: 80 }} />
    <p
      style={{ color: "rgba(255, 255, 255, 1)" }}
      className="font-medium text-sm mt-2.5 px-2"
    >
      The student currently doesn’t have a premium subscription.
    </p>
  </div>
);

export default Overlay;
