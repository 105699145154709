const ProgressBar = ({ fillColor, maximumValue, currentValue }) => {
  const percentageToFill = parseInt((currentValue * 100) / maximumValue);

  return (
    <div className="w-full bg-faintGrey rounded-lg h-1.5">
      <div
        className="rounded-lg h-1.5"
        style={{ width: `${percentageToFill}%`, backgroundColor: fillColor }}
      />
    </div>
  );
};

export default ProgressBar;
