/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAllDoYouKnows = async () => {
  const config = {
    url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/doYouKnow/all`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      limit: 100,
      offset: 0,
    },
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useGetAllDoYouKnows() {
  return useQuery(["AllDoYouKnows"], () => getAllDoYouKnows());
}
