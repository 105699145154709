import { ErrorMessage } from "@hookform/error-message";
import { DatePicker } from "antd";
import moment from "moment";
import React from "react";

const CustomAntdDatePicker = ({
  fieldData,
  name,
  label,
  placeholder,
  register,
  required,
  rules,
  errors,
  onChange,
  defaultValue,
  format = "DD/MM/YYYY"
}) => {
  return (
    <>
      {required ? (
        <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
          {label}{" "}
          {
            <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
              *
            </label>
          }
        </label>
      ) : (
        <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
          {label}
        </label>
      )}
      <DatePicker
        {...fieldData}
        className="customAntdDatePicker"
        size="large"
        format={format}
        on
        onChange={(date, dateString) => {
          onChange(date);
        }}
        placeholder="Select Date"
        disabledDate={(current) => {
          if (name === "dateOfBirth") {
            return current && moment().add(-1, "days") <= current;
          }
        }}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p
              key={type}
              className="text-left italic text-sm text-red-600 mt-1"
            >
              {message}
            </p>
          ))
        }
      />
    </>
  );
};

export default CustomAntdDatePicker;
