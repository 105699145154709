import { gql } from "@apollo/client";

export const UPDATE_ACADEMIC_YEAR = gql`
  mutation updateAcademicYears($schoolId: Int!, $newAcademicYearId: Int!) {
    resetCurrentAcademicYear: update_acadamic_years(
      where: { is_current: { _eq: true }, school_id: { _eq: $schoolId } }
      _set: { is_current: false }
    ) {
      affected_rows
    }
    update_acadamic_years(
      where: { id: { _eq: $newAcademicYearId } }
      _set: { is_current: true }
    ) {
      affected_rows
    }
  }
`;

export const ADD_CITY = gql`
  mutation addCity($object: cities_insert_input!) {
    insert_cities_one(object: $object) {
      id
      city_name
      district_id
    }
  }
`;

export const UPDATE_STUDENT_PROMOTION = gql`
  mutation updateStudentPromotion(
    $schoolId: Int!
    $studentPromotion: Boolean!
  ) {
    update_schools(
      where: { id: { _eq: $schoolId } }
      _set: { student_promotion: $studentPromotion }
    ) {
      affected_rows
    }
  }
`;

export const GET_SETTINGS = gql`
  query getSettings($schoolId: Int!) {
    schools_by_pk(id: $schoolId) {
      id
      image
      title
      board_name
      board_id
      medium
      settings
      email
      mobile_number
      trust_name
      udise_number
      index_number
      student_promotion
      roll_no_param
      category
      management_type
      shift
      urc
      urc_center
      preferences
      address {
        id
        address_line_one
        address_line_two
        pincode
        city {
          id
          city_name
          district {
            id
            district_name
            state {
              id
              state_name
            }
          }
        }
      }
      school_admins(
        where: { user: { is_active: { _eq: true } }, is_active: { _eq: true } }
      ) {
        admin_type
        school_admin_id: id
        user {
          id
          first_name
          middle_name
          last_name
          mobile_number
        }
      }
      school_class_inits {
        id
        class_from
        class_to
        divisions_each_class
      }
      acadamic_years(
        where: { is_active: { _eq: true }, is_current: { _eq: true } }
      ) {
        id
        title
      }
    }
  }
`;

export const GET_ACADEMIC_YEAR = gql`
  query getAcademicYear($schoolId: Int!) {
    acadamic_years(
      where: {
        school_id: { _eq: $schoolId }
        from_date: { _lte: "now()" }
        to_date: { _gte: "now()" }
      }
    ) {
      id
      is_active
      title
    }
  }
`;

export const CREATE_CLASSES_INIT = gql`
  mutation createClassesInit($classesData: [school_class_init_insert_input!]!) {
    insert_school_class_init(objects: $classesData) {
      returning {
        id
        class_from
        class_to
        divisions_each_class
      }
    }
  }
`;

export const CREATE_CLASSES = gql`
  mutation createClasses($classesData: [classes_insert_input!]!) {
    insert_classes(objects: $classesData) {
      affected_rows
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation updateSchool($schoolData: schools_set_input, $schoolId: Int!) {
    update_schools_by_pk(pk_columns: { id: $schoolId }, _set: $schoolData) {
      id
      image
      title
      board_id
      board_name
      mobile_number
      trust_name
      roll_no_param
      category
      management_type
      shift
      urc
      urc_center
      address {
        id
        address_line_one
        address_line_two
        pincode
        city {
          id
          city_name
          district {
            id
            district_name
            state {
              id
              state_name
            }
          }
        }
      }
    }
  }
`;

export const GET_STATES = gql`
  query getStates {
    states {
      id
      state_name
    }
  }
`;

export const GET_DISTRICTS = gql`
  query getDistrictsOfState($stateId: Int!) {
    districts(where: { state_id: { _eq: $stateId } }) {
      id
      district_name
    }
  }
`;

export const GET_CITIES = gql`
  query getCitiesOfDistrict($districtId: Int!) {
    cities(where: { district_id: { _eq: $districtId } }) {
      id
      city_name
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation InsertAddress($addressData: addresses_insert_input!) {
    insert_addresses_one(object: $addressData) {
      address_line_one
      address_line_two
      city_id
      pincode
      id
      city {
        id
        city_name
        district {
          id
          district_name
          state {
            id
            state_name
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_ADDRESS = gql`
  mutation updateAddress($addressData: addresses_set_input!, $addressId: Int!) {
    update_addresses(where: { id: { _eq: $addressId } }, _set: $addressData) {
      returning {
        id
        address_line_one
        address_line_two
        pincode
        city {
          id
          city_name
          district {
            id
            district_name
            state {
              id
              state_name
            }
          }
        }
      }
    }
  }
`;

export const GET_BOARD_LIST = gql`
  query getBoardList {
    boards(
      where: { is_active: { _eq: true } }
      order_by: [{ code: asc }, { version: asc }]
    ) {
      id
      name
      code
      version
      settings
    }
  }
`;

export const GET_GROUPS = gql`
  query MyQuery {
    groups {
      id
      name
    }
  }
`;

export const ADD_GROUPS = gql`
  mutation addSchoolToGroups($groupSchools: [group_schools_insert_input!]!) {
    insert_group_schools(
      objects: $groupSchools
      on_conflict: {
        constraint: group_schools_group_id_school_id_key
        update_columns: updated_at
      }
    ) {
      affected_rows
    }
  }
`;
