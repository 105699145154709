import React from "react";
import TeacherList from "./TeacherList";

const TeachersView = ({ limit, offset, teachersList, enableEditMode, setDrawerOpen }) => {
  return (
    <div>
      <div className="flex flex-row ">
        <TeacherList
          limit={limit}
          offset={offset}
          teacherDataRefetch={teachersList}
          enableEditMode={(values) => {
            enableEditMode(values);
          }}
          setdraweropen={(values) => setDrawerOpen(values, true)}
          isPresent={true}
        />
      </div>
    </div>
  );
};
export default TeachersView;
