import React from "react";
import { AutoComplete } from "antd";

function AutoCompleteInput({
  field,
  options,
  formData,
  onSelect,
  onChange,
  onBlur,
  style,
  inputClassName,
}) {
  return (
    <AutoComplete
      options={options}
      style={{ width: 164, borderRadius: "6px", ...style }}
      value={formData[field]}
      onSelect={onSelect}
      onBlur={onBlur}
      dropdownMatchSelectWidth={false}
      onInputKeyDown={(e) => e.stopPropagation()}
    >
      <input
        type="text"
        className={`border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1 ${
          inputClassName || ""
        }`}
        value={formData[field]}
        onChange={onChange}
        name={field}
      />
    </AutoComplete>
  );
}

export default AutoCompleteInput;
