import React, { useState, useRef } from "react";
import { Checkbox } from "antd";
import { ChevronDownIcon } from "@heroicons/react/outline";

const CustomMultiSelect = ({
  options = [],
  selected = [],
  onChange,
  filterType,
}) => {
  const overlayContent = useRef();
  const currentDiv = useRef();
  const [dimensions, setDimensions] = useState({
    width: 150,
    height: 60,
  });
  const [contentVisible, setContentVisible] = useState(false);
  const [exitBlock, setBlockExit] = useState(true);
  const handleCheckboxChange = (option) => {
    const updatedSelected = selected.includes(option.value)
      ? selected.filter((value) => value !== option.value)
      : [...selected, option.value];

    onChange(updatedSelected);
  };

  const render = (option) => {
    return (
      <div
        key={option.value}
        className="flex flex-row justify-between rounded-md mb-2"
      >
        <p className="mr-2">{option.label}</p>
        <Checkbox
          onChange={() => handleCheckboxChange(option)}
          checked={selected.includes(option.value)}
        />
      </div>
    );
  };

  const content = () => {
    return (
      <div style={{ width: "100%" }}>
        {selected?.length ? (
          <div
            onClick={() => onChange([])}
            className="cursor-pointer flex flex-row justify-between rounded-md mb-2"
          >
            <p className="mr-2">Clear All</p>
          </div>
        ) : null}
        {options.map(render)}
      </div>
    );
  };

  const onHoverOut = (e) => {
    if (overlayContent.current.contains(e.nativeEvent.toElement)) return;
    setContentVisible(false);
    setDimensions((dim) => ({
      ...dim,
      height: 60,
    }));
  };

  const onHoverIn = () => {
    if (currentDiv?.current?.clientWidth) {
      setDimensions({
        height: 30 * options.length + 60,
        width: currentDiv?.current?.clientWidth,
      });
    }
    setContentVisible(true);
  };

  return (
    <div>
      <button
        className="py-3 px-4 whitespace-nowrap flex justify-center items-center bg-primary focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md"
        ref={currentDiv}
      >
        {selected.length > 0
          ? `${filterType}(${selected.length})`
          : `${filterType}`}
        <ChevronDownIcon className="h-4 w-5 ml-4" aria-hidden="true" />
      </button>
      <div
        ref={overlayContent}
        onMouseOver={onHoverIn}
        onMouseOut={onHoverOut}
        style={{
          cursor: "pointer",
          marginTop: -50,
          zIndex: 100,
          position: "absolute",
          width: dimensions.width,
          height: dimensions.height,
        }}
      >
        {contentVisible ? (
          <>
            <div
              style={{
                zIndex: 100,
                width: dimensions.width,
                height: 55,
              }}
            />
            <div
              className="shadow-lg rounded p-2 bg-pureWhite"
              style={{
                zIndex: 100,
                width: dimensions.width,
                animation: "fadein 0.3s",
              }}
            >
              {content()}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CustomMultiSelect;