import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomMultiSelect from "../CustomMultiSelect";
import { getSortedDivisions, getSortedClasses } from "../../helpers";
import cloneDeep from "lodash/cloneDeep";

function ClassDivisionMulti({
	id,
	classes,
	divisions,
	onDivisionChange,
	onClassChange,
	hideDivisionSelector,
	hideClassSelector,
	allOptionNeeded,
	selectedClass,
	selectedDivision,
	disabled,
}) {
	const [filteredDivisions, setFilteredDivisions] = useState([]);
	const [currentSelectedClass, setCurrentSelectedClass] = useState([]);
	const [currentSelectedDivision, setCurrentSelectedDivision] = useState([]);

	const handleClassChange = (value) => {
		const sortedDivisions = getSortedDivisions(divisions);
		const filterDivsWithClassID = sortedDivisions.filter((div) =>
			value.includes(div.class_id),
		);
		setCurrentSelectedClass(value);
		setCurrentSelectedDivision([]);
		setFilteredDivisions(filterDivsWithClassID);
		// Out of 3 reports, progress report uses this component.
		// ManageStudentList, TeacherList, ClassesView component pass the state setter directly without additional logic, hence setting null is not an issue
		// Progress report passes a handler with additional filtering logic that breaks down when null is passed o the handler
		if (allOptionNeeded) {
			if (onDivisionChange) {
				onDivisionChange([]);
			}
		}
		// Edge case code done
		if (onClassChange) {
			onClassChange(value || []);
		}
	};

	const handleDivisionChange = (value) => {
		setCurrentSelectedDivision(value);
		onDivisionChange(value || []);
	};

	const classId = id + "class";
	const divisionId = id + "division";

	let classesList;
	if (allOptionNeeded) {
		classesList = [...cloneDeep(getSortedClasses(classes))];
	} else {
		classesList = getSortedClasses(classes);
	}

	const filteredDivisionsAndAll =
		filteredDivisions.length === 0 ? [] : [...cloneDeep(filteredDivisions)];

	return (
		<>
			{!hideClassSelector && (
				<CustomMultiSelect
					id={classId}
					selected={currentSelectedClass}
					type="primary"
					filterType={"Filter by: Class"}
					options={classesList}
					onChange={handleClassChange}
					disabled={disabled}
				/>
			)}
			{!hideDivisionSelector && (
				<CustomMultiSelect
					id={divisionId}
					selected={currentSelectedDivision}
					type="primary"
					filterType={"Filter by: Division"}
					options={
						allOptionNeeded
							? filteredDivisionsAndAll
							: filteredDivisions
					}
					onChange={handleDivisionChange}
					disabled={disabled}
				/>
			)}
		</>
	);
}

ClassDivisionMulti.prototypes = {
	classes: PropTypes.array,
	divisions: PropTypes.array,
	onDivisionChange: PropTypes.func,
	onClassChange: PropTypes.func,
	hideDivisionSelector: PropTypes.bool,
	hideClassSelector: PropTypes.bool,
	divisionDefaultValue: PropTypes.string,
	allOptionNeeded: PropTypes.bool,
	disabled: PropTypes.bool,
};

ClassDivisionMulti.defaultProps = {
	classes: [],
	divisions: [],
	allOptionNeeded: false,
	disabled: false,
};

export default ClassDivisionMulti;