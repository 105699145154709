/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const getReceiptSettings = async () => {
  const token = localStorage.getItem("token");
  const schoolId = getSchoolID();
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/getReceiptSettings`,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      schoolId,
    },
  };
  const { data: response } = await axios.request(config);
  return response?.data;
};

export default function useGetReceiptSettings(options) {
  return useQuery(
    ["getReceiptSettings"],
    () => getReceiptSettings(),
    options
  );
}
