import React, { useState } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { Dropdown } from "../../components";
import Overlay from "./Overlay";
import useGetInterestedTopics from "../../hooks/useGetInterestedTopics";
import useGetTopicDetails from "../../hooks/useGetTopicDetails";

const emptyData = [
  {
    title: "Parts and Wholes",
    score: 1,
    color: "#D7D7D7",
  },
];

const CIRCLE_SIZE = 10;
const CIRCLE_MARGIN = 16;
const Circle = ({ color }) => (
  <div
    className="w-2.5 h-2.5 rounded-full mr-4"
    style={{ backgroundColor: color }}
  />
);

const EmptyPieChart = ({ topicData }) => (
  <div className="w-full h-full pr-10 flex justify-between mb-6 items-center">
    <figure className="w-1/2 h-full flex justify-center items-center overflow-hidden">
      <ResponsiveContainer width={"80%"} height={"80%"}>
        <PieChart width={"100%"} height={"100%"}>
          <Pie
            data={emptyData}
            dataKey="score"
            nameKey="title"
            cx="50%"
            cy="50%"
            outerRadius={"100%"}
            labelLine={true}
            startAngle={-270}
          >
            {emptyData?.map(({ color }) => (
              <Cell fill={color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </figure>
    <figcaption className="w-full grid grid-cols-2">
      {topicData
        ? [...topicData].map((legendItem) => (
            <Legend
              {...legendItem}
              score={Math.max(legendItem.score - 1, 0)}
              color={"#D7D7D7"}
            />
          ))
        : [...emptyData].map((legendItem) => (
            <Legend {...legendItem} score={legendItem.score - 1} />
          ))}
    </figcaption>
  </div>
);

const Legend = ({ color, title, score, totalScore = 1 }) => {
  const percentage = Math.round((score / totalScore) * 100);
  return (
    <div className="flex flex-1 mb-4 flex-col">
      <div className="flex items-center">
        <Circle color={color} />
        <p className="font-medium text-sm text-fainterGrey text-left">
          {title}
        </p>
      </div>
      <p
        className="font-bold text-lg text-darkerGrey text-left"
        style={{ marginLeft: CIRCLE_MARGIN + CIRCLE_SIZE }}
      >
        {percentage}%
      </p>
    </div>
  );
};

const TopicChart = ({ hasActiveSubscription, studentId, divisionId, name }) => {
  const [selectedTopic, setSelectedTopic] = useState(null);

  const { data: interestedTopics } = useGetInterestedTopics(
    { divisionId },
    {
      onSuccess: (data) => {
        const firstElement = data?.[1];
        setSelectedTopic(firstElement);
      },
    }
  );

  const { data: topicDetails, isLoading } = useGetTopicDetails(
    {
      key: selectedTopic?.key,
      studentId,
      value: selectedTopic?.value,
    },
    {
      enabled: !!studentId && !!selectedTopic?.key && !!selectedTopic?.value,
    }
  );

  const handleTopicSelect = (value) => {
    const selectedValue = interestedTopics?.find(
      ({ value: topicValue }) => topicValue === value
    );
    setSelectedTopic(selectedValue);
  };

  const totalScore = topicDetails?.reduce(
    (currentTotal, topic) => currentTotal + topic?.score,
    0
  );

  const noQuizPlayed = totalScore === 0;

  return (
    <article className="flex w-full h-full flex-col bg-white px-3 py-2 rounded-xl relative card-shadow">
      <header className="flex w-full justify-between pr-10 mb-5">
        <div>
          <h2 className="text-darkerGrey text-xl font-semibold" style={{ marginBottom: 5 }}>
            Topics {name} is interested in
          </h2>
          <p className="text-fainterGrey font-medium text-sm text-left">
            Based on quiz attempts
          </p>
        </div>
        <Dropdown
          value={selectedTopic?.label}
          defaultValue={interestedTopics?.[0]?.label}
          type="primary"
          options={interestedTopics}
          onItemClick={handleTopicSelect}
        />
      </header>
      {noQuizPlayed || isLoading ? (
        <EmptyPieChart topicData={topicDetails} />
      ) : (
        <div className="w-full h-full pr-10 flex justify-between mb-6 items-center">
          <figure className="w-1/2 h-full flex justify-center items-center overflow-hidden">
            <ResponsiveContainer width={"80%"} height={"80%"}>
              <PieChart width={"100%"} height={"100%"}>
                <Pie
                  data={topicDetails}
                  dataKey="score"
                  nameKey="title"
                  cx="50%"
                  cy="50%"
                  outerRadius={"100%"}
                  labelLine={true}
                  endAngle={-270}
                  startAngle={90}
                >
                  {topicDetails?.map(({ color }) => (
                    <Cell fill={color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </figure>
          <figcaption className="w-full grid grid-cols-2">
            {[...topicDetails]?.map((legendItem) => (
              <Legend {...legendItem} totalScore={totalScore} />
            ))}
          </figcaption>
        </div>
      )}
      {!hasActiveSubscription && <Overlay />}
    </article>
  );
};

export default TopicChart;
