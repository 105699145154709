import React from "react";
import { useNavigate } from "react-router-dom";

export default function Card(props) {
  const navigate = useNavigate();
  let percentage =
    props.total === 0 ? 0 : Math.ceil((props.stat / props.total) * 100);
  const isAttendanceTaken =
    props?.isTeacherAttendanceTaken === undefined
      ? true
      : props?.isTeacherAttendanceTaken;
  const isAttendanceNotTaken = isAttendanceTaken === false;

  const navigateToTeacherAttendance = () => {
    if (isAttendanceNotTaken) {
      navigate(`/teacher/availability`);
    }
  };

  return (
    <div
      data-testid="count-card"
      id={props.id}
      className={`shadow-lg rounded-lg p-4 bg-white dark:bg-gray-800 ${
        isAttendanceNotTaken ? "cursor-pointer" : ""
      }`}
      onClick={navigateToTeacherAttendance}
    >
      <div className="flex items-center">
        <p className="text-md text-gray-500 dark:text-white">{props.title}</p>
      </div>
      {props.showPercentage ? (
        <div className="flex">
          <div className="flex flex-col justify-start">
            <h2
              className={`${
                isAttendanceNotTaken ? "text-cancel" : "text-black-700"
              } dark:text-gray-100 text-4xl text-left font-bold my-1`}
            >
              {isAttendanceNotTaken ? "NA" : percentage + "%"}
            </h2>
          </div>
          {!isAttendanceNotTaken && (
            <div className="flex items-end">
              <h3 className="text-black-700 dark:text-gray-100 font-medium text-[20px] text-left ml-1">
                ({props.stat}/{props.total})
              </h3>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-start">
          <h2 className="text-black-700 dark:text-gray-100 text-4xl text-left font-bold my-1">
            {props.stat}
          </h2>
        </div>
      )}
      {isAttendanceNotTaken && (
        <div className="flex flex-col justify-start">
          <h2
            className={`text-cancel dark:text-gray-100 text-xs text-left font-medium -my-2.5`}
          >
            Please mark today’s attendance
          </h2>
        </div>
      )}
    </div>
  );
}
