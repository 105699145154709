import React, { useState } from "react";
import moment from "moment";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Menu as AntDMenu, Dropdown, Drawer, Spin } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  GET_ISSUANCE_HISTORY,
  GET_STUDENT_DOCUMENT_DETAILS,
  GET_ACADEMIC_YEARS,
} from "../views/documentsView/graphql";
import IssuanceTable from "../views/documentsView/issuanceTable";
import TransferCertificateView from "../views/documentsView/transferCertificateView";
import BonafideCertificateView from "../views/documentsView/bonafideCertificateView";
import NirgamUtaraView from "../views/documentsView/nirgamUtaraView";
import { useNavigate } from "react-router-dom";
import { numberToWords } from "../utils";
import { getSchoolID } from "../shared/getSchoolID";
import Button from "../components/Button";
import axios from "axios";
import CloseIcon from "../components/closeIcon";
import {
  DELETE_TC_ISSUANCE_HISTORY,
  DELETE_BONAFIDE_NU_ISSUANCE_HISTORY,
} from "../graphql/mutations";

function IssuanceHistory() {
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedDocumentDetails, setSelectedDocumentDetails] = useState(null);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [translatedDocumentData, setTranslatedDocumentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const PAGE_LIMIT = 7;
  const documentTypes = [
    {
      id: "All",
      title: "All",
    },
    {
      id: "Transfer Certificate",
      title: "Transfer Certificate",
    },
    {
      id: "Bonafide Certificate",
      title: "Bonafide Certificate",
    },
    {
      id: "Nirgam Utara",
      title: "Nirgam Utara",
    },
  ];
  let navigate = useNavigate();

  const getFilters = () => {
    if (selectedDocumentType && selectedAcademicYear) {
      return {
        document_type: {
          _eq: selectedDocumentType,
        },
        academic_year_id: {
          _eq: selectedAcademicYear,
        },
        student: {
          full_name: { _ilike: `%${search}%` },
          school_id: { _eq: getSchoolID() },
        },
        is_active: {
          _eq: true,
        },
      };
    }
    if (selectedDocumentType) {
      return {
        document_type: {
          _eq: selectedDocumentType,
        },
        student: {
          full_name: { _ilike: `%${search}%` },
          school_id: { _eq: getSchoolID() },
        },
        is_active: {
          _eq: true,
        },
      };
    }
    if (selectedAcademicYear) {
      return {
        academic_year_id: {
          _eq: selectedAcademicYear,
        },
        student: {
          full_name: { _ilike: `%${search}%` },
          school_id: { _eq: getSchoolID() },
        },
        is_active: {
          _eq: true,
        },
      };
    }
    return {
      student: {
        full_name: { _ilike: `%${search}%` },
        school_id: { _eq: getSchoolID() },
      },
      is_active: {
        _eq: true,
      },
    };
  };

  const {
    loading: isStudentsLoading,
    data,
    fetchMore: fetchMoreData,
  } = useQuery(GET_ISSUANCE_HISTORY, {
    variables: {
      filters: getFilters(),
      limit: PAGE_LIMIT,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [deleteTCIssuanceHistory, { loading: isDeleteLoading }] = useMutation(
    DELETE_TC_ISSUANCE_HISTORY,
    {
      refetchQueries: [
        {
          query: GET_ISSUANCE_HISTORY,
          variables: {
            filters: getFilters(),
            limit: PAGE_LIMIT,
            offset: 0,
          },
        },
      ],
    }
  );

  const [deleteIssuanceHistory, { loading: isDeleteIssuanceLoading }] =
    useMutation(DELETE_BONAFIDE_NU_ISSUANCE_HISTORY, {
      refetchQueries: [
        {
          query: GET_ISSUANCE_HISTORY,
          variables: {
            filters: getFilters(),
            limit: PAGE_LIMIT,
            offset: 0,
          },
        },
      ],
    });

  const { data: academicYearsData } = useQuery(GET_ACADEMIC_YEARS, {
    variables: {
      schoolId: getSchoolID(),
    },
  });

  const handleDownloadTC = () => {
    setIsLoading(true);
    const tcDocument = selectedDocumentDetails?.settings?.documents?.find(
      (document) => document.code === "tc"
    );
    // copy formattedTCData and replace with "-" string if it is null
    const formattedData = { ...selectedDocumentDetails };
    Object.keys(formattedData).forEach((key) => {
      if (formattedData[key] === null) {
        formattedData[key] = "-";
      }
    });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getTC`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: tcDocument?.languageCode === "en" ? "english" : "marathi",
        isDuplicate: true,
        data: {
          ...formattedData,
          ...translatedDocumentData,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${selectedDocumentDetails.studentName}_Transfer_Certificate`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleDownloadNU = () => {
    setIsLoading(true);
    const nuDocument = selectedDocumentDetails?.settings?.documents?.find(
      (document) => document.code === "nu"
    );
    // copy formatted data and replace with "-" string if it is null
    const formattedData = { ...selectedDocumentDetails };
    Object.keys(formattedData).forEach((key) => {
      if (formattedData[key] === null) {
        formattedData[key] = "-";
      }
    });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getNirgamUtara`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: nuDocument?.languageCode === "en" ? "english" : "marathi",
        data: {
          ...formattedData,
          ...translatedDocumentData,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${formattedData.studentName}_Nirgam_Utara`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleTranslateText = (textList) => {
    setIsLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/translateText`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        contents: textList,
        sourceLanguageCode: "en",
        targetLanguageCode: "mr-IN",
      },
    };
    axios(config)
      .then((res) => {
        const translatedTextList = res.data;
        setTranslatedDocumentData({
          ...translatedDocumentData,
          trustName: translatedTextList[0]?.translatedText,
          schoolName: translatedTextList[1]?.translatedText,
          cityName: translatedTextList[2]?.translatedText,
          stateName: translatedTextList[3]?.translatedText,
          board: translatedTextList[4]?.translatedText,
          schoolMedium: translatedTextList[5]?.translatedText,
          email: translatedTextList[6]?.translatedText,
          studentName: translatedTextList?.[7]?.translatedText,
          motherName: translatedTextList?.[8]?.translatedText,
          nationality: translatedTextList?.[9]?.translatedText,
          caste: translatedTextList?.[10]?.translatedText,
          religion: translatedTextList?.[11]?.translatedText,
          motherTongue: translatedTextList?.[12]?.translatedText,
          birthPlace: translatedTextList?.[13]?.translatedText,
          country: translatedTextList?.[14]?.translatedText,
          dateOfBirthInWords: translatedTextList?.[15]?.translatedText,
          previousSchool: translatedTextList?.[16]?.translatedText,
          previousSchoolClass: translatedTextList?.[17]?.translatedText,
          admittedClass: translatedTextList?.[18]?.translatedText,
          academicProgress: translatedTextList?.[19]?.translatedText,
          behaviour: translatedTextList?.[20]?.translatedText,
          reasonForTransfer: translatedTextList?.[21]?.translatedText,
          remarks: translatedTextList?.[22]?.translatedText,
          class: translatedTextList?.[23]?.translatedText,
          division: translatedTextList?.[24]?.translatedText,
          fatherName: translatedTextList?.[25]?.translatedText,
          fatherOccupation: translatedTextList?.[26]?.translatedText,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  // dateOfAdmission may not be accurate as calcuations are based on enroll_date
  const [getDocumentDetails, { loading: docDetailsLoading }] = useLazyQuery(
    GET_STUDENT_DOCUMENT_DETAILS,
    {
      onCompleted: (docData) => {
        const student = docData?.students_by_pk;
        const dobDayWords = numberToWords(moment(student?.dob).format("D"));
        const dobMonthWords = moment(student?.dob).format("MMM");
        const dobYearWords = numberToWords(moment(student?.dob).format("YYYY"));
        const issuedDoc = student?.documents_issuance_histories?.[0];
        const schoolSettings = student?.division?.school?.settings;
        const docType = issuedDoc?.document_type;
        const studentDocumentData = {
          udiseNumber: student?.division?.school?.udise_number,
          mobileNumber: student?.division?.school?.mobile_number,
          board: student?.division?.school?.board_name,
          schoolMedium: student?.division?.school?.medium,
          email: student?.division?.school?.email,
          uidNumber: student?.aadhaar,
          trustName: student?.division?.school?.trust_name,
          schoolName: student?.division?.school?.title,
          cityName: student?.division?.school?.address?.city?.city_name,
          stateName:
            student?.division?.school?.address?.city?.district?.state
              ?.state_name,
          schoolRegistrationNumber:
            student?.division?.school?.registration_number,
          serialNumber: issuedDoc?.document_number,
          date: issuedDoc
            ? moment(issuedDoc?.issuance_date).format("DD/MM/YYYY")
            : moment().format("DD/MM/YYYY"),
          studentId: student?.id,
          studentRef: student?.student_ref,
          studentName: student?.full_name,
          fatherName: student?.father_name,
          motherTongue: student?.mother_tongue,
          motherName: student?.mother_name,
          dateOfAdmission: student?.date_of_admission
            ? moment(student?.date_of_admission).format("DD/MM/YYYY")
            : moment().format("DD/MM/YYYY"),
          dateOfBirthInWords: `${dobDayWords} ${dobMonthWords} ${dobYearWords}`,
          admittedClass: student?.admitted_class,
          previousSchool: student?.last_school,
          previousSchoolClass: student?.last_school_class,
          reasonForTransfer: student?.transfer_reason,
          remarks: student?.remarks,
          caste: student?.caste,
          religion: student?.religion,
          country: "India",
          academicProgress: student?.student_progress,
          behaviour: student?.conduct,
          birthPlace: student?.place_of_birth,
          dateOfBirth: moment(student?.dob).format("DD/MM/YYYY"),
          class: student?.division?.class?.class_name,
          division: student?.division?.division_name,
          image: student?.division?.school?.image,
          academicYear: student?.division?.acadamic_year?.title,
          isActive: student?.is_active,
          transferDate: moment(student?.transfer_date).format("DD/MM/YYYY"),
          isIssued: issuedDoc,
          documentType: issuedDoc?.document_type,
          isTransferred: student?.transfer_date && issuedDoc,
          settings: schoolSettings,
        };
        const textList = [
          studentDocumentData.trustName || "-",
          studentDocumentData.schoolName || "-",
          studentDocumentData.cityName || "-",
          studentDocumentData.stateName || "-",
          studentDocumentData.board || "-",
          studentDocumentData.schoolMedium || "-",
          studentDocumentData.email || "-",
          studentDocumentData.studentName || "-",
          studentDocumentData.motherName || "-",
          studentDocumentData.nationality || "-",
          studentDocumentData.caste || "-",
          studentDocumentData.religion || "-",
          studentDocumentData.motherTongue || "-",
          studentDocumentData.birthPlace || "-",
          studentDocumentData.country || "-",
          studentDocumentData.dateOfBirthInWords,
          studentDocumentData.previousSchool || "-",
          studentDocumentData.previousSchoolClass || "-",
          studentDocumentData.admittedClass || "-",
          studentDocumentData.academicProgress || "-",
          studentDocumentData.behaviour || "-",
          studentDocumentData.reasonForTransfer || "-",
          studentDocumentData.remarks || "-",
          studentDocumentData.class || "-",
          studentDocumentData.division || "-",
          studentDocumentData.fatherName || "-",
          studentDocumentData.fatherOccupation || "-",
        ];
        if (docType === "Transfer Certificate") {
          const tcDocument = schoolSettings?.documents?.find(
            (document) => document.code === "tc"
          );
          // Translate document if language is not english
          if (tcDocument?.languageCode !== "en") {
            handleTranslateText(textList);
          }
        }
        if (docType === "Nirgam Utara") {
          const nuDocument = schoolSettings?.documents?.find(
            (document) => document.code === "nu"
          );
          if (nuDocument?.languageCode !== "en") {
            handleTranslateText(textList);
          }
        }
        setSelectedDocumentDetails({
          ...studentDocumentData,
        });
      },
    }
  );

  const printBonafideToPDF = () => {
    const docTitle = document.getElementById("docTitle");
    docTitle.classList.add("pb-12");
    docTitle.classList.remove("text-2xl");
    docTitle.classList.add("text-5xl");

    const formControls = document.getElementById("form-controls");
    formControls.style.visibility = "hidden";

    const schoolLogo = document.getElementById("school-logo");
    if (schoolLogo) {
      schoolLogo.style.visibility = "hidden";
    }

    const schoolName = document.getElementById("school-name");
    schoolName.classList.remove("text-xl");
    schoolName.classList.add("text-4xl");

    const titleContainer = document.getElementById("title-container");
    titleContainer.classList.remove("pl-28", "pr-28");
    titleContainer.classList.add("pl-10", "pr-10");

    const titleBorderLeft = document.getElementById("title-border-left");
    titleBorderLeft.classList.remove("w-36");
    titleBorderLeft.classList.add("w-56");
    const titleBorderRight = document.getElementById("title-border-right");
    titleBorderRight.classList.remove("w-36");
    titleBorderRight.classList.add("w-56");

    const duplicateWatermark = document.getElementById("duplicate-watermark");
    if (duplicateWatermark) {
      duplicateWatermark.classList.remove("text-lg");
      duplicateWatermark.classList.add("text-2xl");
    }

    const input = document.getElementById("bonafideDocument");
    input.classList.remove("h-3/4");
    input.classList.add("text-2xl");

    html2canvas(input, {
      scrollY: -window.scrollY,
      scale: 2,
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "pt", "letter", true);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      const fileName = `${selectedDocumentDetails.studentName}_Bonafide_Certificate`;
      pdf.save(`${fileName}.pdf`);
    });

    schoolName.classList.remove("text-4xl");
    schoolName.classList.add("text-xl");

    titleContainer.classList.remove("pl-10", "pr-10");
    titleContainer.classList.add("pl-28", "pr-28");

    titleBorderLeft.classList.remove("w-56");
    titleBorderLeft.classList.add("w-36");
    titleBorderRight.classList.remove("w-56");
    titleBorderRight.classList.add("w-36");

    docTitle.classList.remove("pb-12");
    docTitle.classList.remove("text-5xl");
    docTitle.classList.add("text-2xl");

    formControls.style.visibility = "visible";

    input.classList.add("h-3/4");
    input.classList.remove("text-2xl");

    if (schoolLogo) {
      schoolLogo.style.visibility = "visible";
    }
    if (duplicateWatermark) {
      duplicateWatermark.classList.remove("text-2xl");
      duplicateWatermark.classList.add("text-lg");
    }
  };

  const handleDownloadDocument = (documentType) => {
    setIsDetailsDrawerOpen(false);
    const docView = document.getElementById("doc-view");
    docView.classList.remove("hidden");
    if (documentType === "Bonafide Certificate") {
      printBonafideToPDF();
    }
    if (documentType === "Nirgam Utara") {
      handleDownloadNU();
    }
    if (documentType === "Transfer Certificate" || documentType === "TC") {
      handleDownloadTC();
    }
    // hide docView
    docView.classList.add("hidden");
  };

  const handleDeleteClick = (docData) => {
    if (docData?.document_type === "Transfer Certificate") {
      // delete transfer date and reason along with issuance history if document is TC
      deleteTCIssuanceHistory({
        variables: {
          studentId: docData?.student?.id,
          documentId: docData?.id,
        },
      });
    } else {
      // just delete issuance history if bonafide or nirgam utara
      deleteIssuanceHistory({
        variables: {
          documentId: docData?.id,
        },
      });
    }
  };

  const documentTypeMenu = (
    <AntDMenu
      onClick={({ key }) => {
        // handle document type change
        if (key === "All") {
          setSelectedDocumentType(null);
          return;
        }
        if (key === selectedDocumentType) {
          setSelectedDocumentType(null);
        } else {
          setSelectedDocumentType(key);
        }
      }}
    >
      {documentTypes?.map((doc) => (
        <AntDMenu.Item key={doc.id} title={doc.title}>
          <div className="flex items-center">{`${doc.title}`}</div>
        </AntDMenu.Item>
      ))}
    </AntDMenu>
  );

  const academicYearsMenu = (
    <AntDMenu
      onClick={({ key }) => {
        if (key === selectedAcademicYear) {
          setSelectedAcademicYear(null);
        } else {
          setSelectedAcademicYear(key);
        }
      }}
    >
      {academicYearsData?.acadamic_years?.map((academicYear) => (
        <AntDMenu.Item key={academicYear.id} title={academicYear.title}>
          {academicYear.title}
        </AntDMenu.Item>
      ))}
    </AntDMenu>
  );

  const renderDocumentDetails = () => {
    if (docDetailsLoading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="h-full mx-6">
        <div className="pt-6 pr-6">
          <p className="text-gray-900 text-xl font-bold">
            {selectedDocumentDetails?.studentName}
          </p>
          {selectedDocumentDetails?.transferDate && (
            <p className="text-red-500">Transferred</p>
          )}
          {!selectedDocumentDetails?.transferDate &&
            selectedDocumentDetails?.isActive && (
              <p className="text-green-500">Active</p>
            )}
        </div>
        <div className="flex justify-center">
          <h3 className="font-bold text-lg">
            {selectedDocumentDetails?.documentType}
          </h3>
        </div>
        {selectedDocumentDetails?.documentType === "Transfer Certificate" && (
          <TransferCertificateView
            data={selectedDocumentDetails}
            translatedData={translatedDocumentData}
            viewOnly
          />
        )}
        {selectedDocumentDetails?.documentType === "Bonafide Certificate" && (
          <BonafideCertificateView data={selectedDocumentDetails} viewOnly />
        )}
        {selectedDocumentDetails?.documentType === "Nirgam Utara" && (
          <NirgamUtaraView
            data={selectedDocumentDetails}
            translatedData={translatedDocumentData}
            viewOnly
          />
        )}
        <div className="flex justify-center mt-8 pb-9">
          <button
            onClick={() => {
              handleDownloadDocument(selectedDocumentDetails?.documentType);
            }}
            className="bg-success py-3 ml-5 px-4 flex justify-center items-center focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
          >
            Download Duplicate Document
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col px-8 py-3 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-auto">
        <Spin
          spinning={
            isLoading ||
            isDeleteLoading ||
            isDeleteIssuanceLoading ||
            isStudentsLoading
          }
        >
          <div className="flex flex-row gap-x-4 items-center mb-9 justify-between mr-7">
            <p className="text-2xl text-left font-bold flex-col m-0">
              Issuance History
            </p>
            <Button
              id="documents-issuance-back"
              buttonStyle="secondary"
              onClick={() => navigate(-1)}
            >
              Back to Documents
            </Button>
          </div>
          <div className="relative mr-7 h-4/5">
            <div className="flex justify-between items-center mt-4">
              <div className="w-full">
                <p className="text-left font-semibold">Select a student</p>
                <div className="flex relative mt-2">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    defaultValue={search}
                    type="text"
                    id='"form-subscribe-Filter'
                    className="pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by student name"
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        e.target.value.length >= 2 ||
                        e.target.value.length === 0
                      ) {
                        setSearch(e.target.value);
                      }
                    }}
                  />
                  <Dropdown overlay={documentTypeMenu}>
                    <button
                      id="documents-issuance-type"
                      className="py-3 px-4 ml-12 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
                    >
                      {selectedDocumentType || "Document Type: All"}
                      <ChevronDownIcon
                        className="h-4 w-5 ml-4"
                        aria-hidden="true"
                      />
                    </button>
                  </Dropdown>
                  <Dropdown overlay={academicYearsMenu}>
                    <button
                      id="documents-issuance-year"
                      className="py-3 px-4 ml-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
                    >
                      Academic year
                      <ChevronDownIcon
                        className="h-4 w-5 ml-4"
                        aria-hidden="true"
                      />
                    </button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <IssuanceTable
              issuanceHistory={data?.documents_issuance_history}
              pageLimit={PAGE_LIMIT}
              onDeleteClick={handleDeleteClick}
              fetchMoreData={fetchMoreData}
              totalCount={
                data?.documents_issuance_history_aggregate?.aggregate?.count
              }
            />
            <div id="doc-view" className="hidden">
              {selectedDocumentDetails?.documentType ===
                "Transfer Certificate" && (
                <TransferCertificateView
                  data={selectedDocumentDetails}
                  viewOnly
                />
              )}
              {selectedDocumentDetails?.documentType ===
                "Bonafide Certificate" && (
                <BonafideCertificateView
                  data={selectedDocumentDetails}
                  viewOnly
                />
              )}
              {selectedDocumentDetails?.documentType === "Nirgam Utara" && (
                <NirgamUtaraView data={selectedDocumentDetails} viewOnly />
              )}
            </div>
          </div>
          <Drawer
            title={
              <div className="text-lg leading-7 font-medium">
                Document Details
              </div>
            }
            placement="right"
            onClose={() => {
              setIsDetailsDrawerOpen(false);
            }}
            visible={isDetailsDrawerOpen}
            destroyOnClose
            contentWrapperStyle={{
              width: "40%",
            }}
            headerStyle={{
              height: "76px",
              backgroundColor: "#F3F4F6",
            }}
            bodyStyle={{
              padding: 0,
              backgroundColor: "#F3F4F6",
            }}
            closeIcon={<CloseIcon />}
          >
            {renderDocumentDetails()}
          </Drawer>
        </Spin>
      </div>
    </>
  );
}

export default IssuanceHistory;
