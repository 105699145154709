import React, { useState } from "react";
import { Pagination } from "antd";
import _ from "lodash";
import { getAvatar } from "../../../shared/getAvatar";

function StudentsTable({
  divisionStudents,
  fetchMoreStudents,
  totalStudents,
  pageLimit,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page, pageSize) => {
    fetchMoreStudents({
      variables: {
        offset: (page - 1) * pageSize,
      },
    });
    setCurrentPage(page);
  };

  //TODO: merge and cache paginated data, then display set of students based on pagination
  const displayStudents = (students) => {
    if (students?.length > 0) {
      return students;
    }
    return [];
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Roll No.
                    </th>
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Mother's Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Gender
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Religion
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Attendance Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayStudents(divisionStudents)?.map((student, index) => {
                    const rowId = "attendancereport-student-" + index;
                    const detailsId = "attendancereport-details-" + index;

                    return (
                      <tr id={rowId} key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-gray-500">
                            {_.isNil(student?.roll_no)
                              ? "—"
                              : `${student?.roll_no}`}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center text-left">
                            <div className="flex-shrink-0">
                              {getAvatar(
                                student?.first_name,
                                student?.last_name,
                                student?.image
                              )}
                            </div>
                            <div className="ml-3">
                              <p className="text-black font-medium whitespace-no-wrap">
                                {student?.full_name}
                              </p>
                              <p className="text-gray-500">
                                {_.isNil(student?.division?.division_name)
                                  ? "—"
                                  : `Class ${student?.division?.division_name}`}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-black whitespace-no-wrap">
                            {student?.mother_name}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-black whitespace-no-wrap">
                            {student?.gender}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-black whitespace-no-wrap">
                            {student?.religion}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-black whitespace-no-wrap">
                            {student?.category}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          {`${student?.totalPresentDays || "-"}/${
                            student?.totalDays || "-"
                          }`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              size="small"
              pageSize={pageLimit}
              current={currentPage}
              showSizeChanger={false}
              total={totalStudents}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsTable;
