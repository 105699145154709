import React from "react";
import { useQuery } from "@apollo/client";
import { getSchoolID } from "../../shared/getSchoolID";
import { useState } from "react";
import { ClassDivisionSelector, Dropdown } from "../../components";
import { GET_CLASSES_AND_DIVISIONS } from "../../views/studentsView/graphql";
import { rteOptions } from "../../utils/constants";
import StudentsStatsTable from "./StudentsStatsTable";

function SchoolStats() {
  const [rteTypeLabel, setRteTypeLabel] = useState();
  const [classData, setclassdata] = useState([]);
  const [divisionData, setdivisiondata] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [rteType, setRteType] = useState(null);

  const { loading: clLoading, error: clError } = useQuery(
    GET_CLASSES_AND_DIVISIONS,
    {
      variables: {
        schoolId: getSchoolID(),
      },
      onCompleted: (classResponse) => {
        let classLists = [];
        let divList = [];
        classLists = classResponse?.classes.map((classObj) => {
          let divisionList = [];
          divisionList = classObj.divisions.map((divz) => {
            return {
              value: divz.id,
              label: `Division: ${divz.division_name}`,
              class_id: classObj.id,
            };
          });
          divList.push(...divisionList);
          return {
            value: classObj.id,
            label: `Class: ${classObj.class_name}`,
          };
        });

        setclassdata(classLists);
        setdivisiondata(divList);
      },
    }
  );

  const handleCurrentClassChange = (value) => {
    if (value === "All") {
      setSelectedClass(null);
    } else {
      setSelectedClass({
        value,
        label: value,
      });
    }
    setSelectedDivision(null);
  };

  const handleCurrentDivisionChange = (value) => {
    if (value === "All") {
      setSelectedDivision(null);
    } else {
      setSelectedDivision({
        value,
        label: value,
      });
    }
  };

  const handleRteSelect = (value) => {
    if (value === "All") {
      setRteType(null);
    } else {
      setRteType({
        value,
        label: `Rte: ${value}`,
      });
    }
    const rteLabel = rteOptions.find((option) => option.value === value);
    setRteTypeLabel(rteLabel.label);
  };

  return (
    <>
      <div className="flex justify-between">
        <div>
          <p className="text-sm w-full text-left mt-6 font-bold">
            School Statistics
          </p>
        </div>
        <div className="flex gap-x-2 ">
          <ClassDivisionSelector
            classes={classData}
            divisions={divisionData}
            onClassChange={handleCurrentClassChange}
            onDivisionChange={handleCurrentDivisionChange}
            allOptionNeeded
          />
          <Dropdown
            value={rteTypeLabel}
            defaultValue="RTE: All"
            type="primary"
            options={rteOptions}
            onItemClick={handleRteSelect}
          />
        </div>
      </div>
      <StudentsStatsTable
        selectedClass={selectedClass}
        selectedDivision={selectedDivision}
        rteType={rteType}
      />
    </>
  );
}

export default SchoolStats;
