/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";

const getAttendanceDetails = async (payload) => {
  const variables = payload;
  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/student/attendanceData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...variables,
      },
    };
    const { data } = await axios.request(config);
    return data?.data;
  } catch (e) {
    console.error("Use Attendance Data Error: ", { e: e.message });
    return null;
  }
};

export default function useAttendanceData(studentId) {
  return useQuery(["attendanceDetails"], () =>
    getAttendanceDetails({
      studentId,
    })
  );
}
