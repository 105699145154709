import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const updateEnquiryFields = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/admission/updateEnquiryFields`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
    validateStatus: () => true
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpdateEnquiryFields(options = {}) {
  return useMutation({
    mutationFn: (data) => updateEnquiryFields(data),
    ...options,
  });
}
