import React, { useState } from "react";
import useGetAssessments from "../../hooks/useGetAssessments";
import useExamData from "../../hooks/useExamData";
import { Dropdown } from "../../components";
import { isNumber, validNumber, truncateString } from "../../utils";

const formatExams = (allExams) =>
  allExams?.map((exam) => ({
    ...exam,
    label: exam?.name,
  }));

const MarksTable = ({ subjects }) => {
  return (
    <table className="flex flex-1 rounded-md w-full my-4 flex-col border-l border-r border-b table-fixed">
      <thead className="bg-faintBlue w-full rounded-t-md">
        <tr className="flex flex-1 border border-fadedGrey rounded-t-md">
          <th
            className="pl-4 flex justify-center border-r border-fadedGrey text-linerGrey py-4 font-semibold"
            style={{ flex: "2 2 0" }}
          >
            Subjects
          </th>
          <th className="flex flex-1 justify-center border-r border-fadedGrey text-linerGrey py-4 font-semibold">
            Marks
          </th>
          <th className="flex flex-1 justify-center text-linerGrey py-4 font-semibold pr-4 box-border">
            Grade
          </th>
        </tr>
      </thead>
      <tbody className="w-full">
        {subjects?.map((subject, index) => {
          let rowStyle = "flex flex-1 px-4";

          if (index !== subjects.length - 1) {
            rowStyle += " border-b";
          }

          return (
            <tr key={subject?.id} className={rowStyle}>
              <td
                className="flex border-r border-fadedGrey text-darkerGrey py-4 font-semibold text-left"
                style={{ flex: "2 2 0" }}
              >
                {subject?.name}
              </td>
              <td className="flex flex-1 border-r border-fadedGrey  text-darkerGrey py-4 font-semibold text-left justify-center">
                {subject?.total} / {subject?.maxMarks}
              </td>
              <td
                className="flex flex-1 text-darkerGrey py-4 font-semibold text-left justify-center"
                style={{ color: subject?.gradeColor }}
              >
                {subject?.grade}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const RemarksCard = ({ header, remarks }) => {
  let textStyle =
    "text-xs pb-2 border-b border-fainterGrey text-left font-semibold";

  if (header === "Progress Essential") {
    textStyle += " text-brightRed";
  } else {
    textStyle += " text-pantone";
  }

  if (remarks?.length === 0) return null;

  return (
    <div className="border-fainterGrey w-full rounded-xl border p-3 mt-2">
      <p className={textStyle}>{header}</p>
      {remarks?.map((remark) =>
        remark?.trim() !== "" ? (
          <p className="text-studentIndigo font-medium text-xs text-left pt-2">
            {remark}
          </p>
        ) : null
      )}
    </div>
  );
};

const SpecialRemarks = ({ specialRemarks }) => {
  if (!specialRemarks || !specialRemarks?.length || specialRemarks.length === 0)
    return null;

  const totalRemarks = specialRemarks?.reduce(
    (currentSum, currentRemark) => currentRemark?.remarks?.length + currentSum,
    0
  );

  if (totalRemarks === 0) return null;

  return (
    <section className="w-full">
      <div className="w-full flex justify-start">
        <span className="px-2 py-2 bg-scandalGreen rounded-full inline-block mt-3.5 text-left">
          <p className="text-sm text-darkGreen font-medium">Special Progress</p>
        </span>
      </div>

      {specialRemarks?.map((remark) => (
        <RemarksCard {...remark} key={remark?.header} />
      ))}
    </section>
  );
};

const Scorecard = ({ studentId }) => {
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  const { data: allAssessments, isLoading: allAssessmentsLoading } =
    useGetAssessments(studentId, {
      onSuccess: (examList) => {
        setSelectedAssessment(examList?.[0]);
      },
      select: formatExams,
    });

  const {
    data: examData,
    isLoading: examDataLoading,
    isFetching,
  } = useExamData(studentId, selectedAssessment?.value);

  const handleAssessmentChange = (value) => {
    const selectedExam = allAssessments.find(
      (exam) => parseInt(exam.value) === parseInt(value)
    );
    setSelectedAssessment(selectedExam);
  };

  const examLabel = truncateString(selectedAssessment?.label || '', 14);

  return (
    <article className="pr-5 h-full w-full block overflow-y-auto p-4 bg-white rounded-xl card-shadow">
      <header className="flex flex-1 justify-between">
        <h2 className="text-left font-semibold text-darkerGrey text-xl">
          Scorecard
        </h2>
        <Dropdown
          id="assessments-dropdown"
          value={examLabel}
          type="primary"
          options={allAssessments}
          onItemClick={handleAssessmentChange}
        />
      </header>
      <MarksTable subjects={examData?.subjects} />

      <footer className="w-full mt-2.5">
        <div className="w-full border rounded-md border-fadedGrey bg-faintBlue flex flex-1 justify-between p-4">
          <p className="font-semibold text-darkerGrey text-base">Total Score</p>
          <p className="font-semibold text-darkerGrey text-base">
            {isNumber(examData?.total, '')} / {isNumber(examData?.max_marks, '')}
          </p>
        </div>

        <div className="flex flex-1 justify-between my-2.5">
          <div style={{ height: 55 }} className="w-2/5 border rounded-md border-fadedGrey bg-faintBlue flex justify-between p-4">
            <p className="font-semibold text-darkerGrey text-base">Overall</p>
            <p className="font-semibold text-darkerGrey text-base">
              {Math.round(validNumber(examData?.percentage))}%
            </p>
          </div>

          <div style={{ height: 55 }} className="max-w-3/5 border rounded-md border-fadedGrey bg-faintBlue flex  justify-between p-4">
            <p className="font-semibold text-darkerGrey text-base">
              Overall Grade
            </p>
            <p className="font-semibold text-darkerGrey text-base px-4">
              {examData?.grade}
            </p>
          </div>
        </div>
      </footer>

      <SpecialRemarks specialRemarks={examData?.specialRemarks} />
    </article>
  );
};

export default Scorecard;
