/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getCollectionStudent = async (payload) => {
	try {
		const config = {
			method: "post",
			url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/feeCollectionStudent`,
			headers: {
				"Content-Type": "application/json",
			},
			data: payload,
		};
		const res = await axios.request(config);
		return res?.data?.data;
	} catch (e) {
		return null;
	}
};


export default function useFeeCollectionStudent(
	payload,
	options = {}
) {
	return useQuery(
		["feeCollectionStudent"],
		() => getCollectionStudent(payload),
		options
	);
}