/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const changeStudentDivision = async (payload) => {
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/division/changeStudentDivision`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const { data } = await axios.request(config);
  return data?.data;
};

export default function useChangeStudentDivision(options = {}) {
  return useMutation({
    mutationFn: (data) => changeStudentDivision(data),
    onError: (error) => {
      notification["error"]({
        message: "Failure",
        description: "Could not change division of student",
        duration: 1.5,
      });
      console.error(error);
    },
    ...options,
  });
}
