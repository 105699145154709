// Helper functions specific to the application

export const getSortedDivisions = (divisions) => {
  // sort divisions by name
  return divisions.slice().sort((a, b) => {
    let divisionA = a?.divisionName;
    let divisionB = b?.divisionName;
    if (!divisionA) {
      divisionA = a?.label?.split(": ")[1];
    }
    if (!divisionB) {
      divisionB = b?.label?.split(": ")[1];
    }
    if (divisionA < divisionB) {
      return -1;
    }
    if (divisionA > divisionB) {
      return 1;
    }
    return 0;
  });
};

export const evalParamTitleCorrected = (allData) => {
  const { assessments } = allData;
  const correctedAssessments = assessments?.map((assessment) => ({
    ...assessment,
    assessment_subjects: assessment?.assessment_subjects?.map((subject) => ({
      ...subject,
      assessment_subject_eval_params:
        subject?.assessment_subject_eval_params?.map((evalParam) => ({
          ...evalParam,
          eval_param: {
            title:
              evalParam?.updated_param_name || evalParam?.eval_param?.title,
          },
        })),
    })),
  }));
  return {
    ...allData,
    assessments: correctedAssessments,
  };
};

export const getSortedClasses = (classes) => {
  if (!classes) return [];
  // sort class name or label
  const sortedClasses = classes.slice().sort((a, b) => {
    if (a?.label && b?.label) {
      // match if it has label property beginning with 'Class:'
      if (
        a?.label?.match(/^Class: (\d+)/) &&
        b?.label?.match(/^Class: (\d+)/)
      ) {
        // split the label and sort by the number
        const aNum = a.label.split(":")[1];
        const bNum = b.label.split(":")[1];
        return aNum - bNum;
      }
      // match if it has label property beginning with 'Class:' and string name
      else if (
        a?.label?.match(/^Class: (\w+)/) &&
        b?.label?.match(/^Class: (\w+)/)
      ) {
        // split the label and sort by the string
        const aStr = a.label.split(":")[1];
        const bStr = b.label.split(":")[1];
        return aStr.localeCompare(bStr);
      }
    }
    // sort by class name
    else if (a?.class_name && b?.class_name) {
      return a.class_name.localeCompare(b.class_name, undefined, {
        numeric: true,
      });
    }
    // match if typeof a and b is string and sort
    else if (typeof a === "string" && typeof b === "string") {
      // sort if first part is number followed by text
      if (a.match(/^\d+ [a-zA-Z]+$/) && b.match(/^\d+ [a-zA-Z]+$/)) {
        const aNum = parseInt(a.split(" ")[0]);
        const bNum = parseInt(b.split(" ")[0]);
        if (aNum === bNum) {
          const aStr = a.split(" ").slice(1).join(" ");
          const bStr = b.split(" ").slice(1).join(" ");
          return aStr.localeCompare(bStr);
        }
        return aNum - bNum;
      }
      // sort if text
      else if (a.match(/^[a-zA-Z]+$/) && b.match(/^[a-zA-Z]+$/)) {
        return a.localeCompare(b);
      }
      // sort if number
      else if (a.match(/^\d+$/) && b.match(/^\d+$/)) {
        return parseInt(a) - parseInt(b);
      }
    }
    return 0;
  });
  return sortedClasses;
};

export const getSortedTeachers = (teachers, includeNotAssigned = false) => {
  const sorted = teachers.slice().sort((a, b) => {
    let aName =
      (a.first_name || "") + (a.middle_name || "") + (a.last_name || "");
    let bName =
      (b.first_name || "") + (b.middle_name || "") + (b.last_name || "");

    aName = aName.toLowerCase();
    bName = bName.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });
  if (includeNotAssigned) {
    sorted.unshift({
      id: null,
      first_name: 'Not Assigned',
      teacher_attendance_data: []
    });
  }
  return sorted;
};

export const getSortedLabels = (arrayOfObjectsWithLabelAsProperties) => {
  // This is a sorting function which expects label as a property, and sorts data according to label
  // It is intented to be used only on sorting state, district and city
  if (!arrayOfObjectsWithLabelAsProperties) return [];

  return arrayOfObjectsWithLabelAsProperties.slice().sort((a, b) => {
    const aLabel = a.label.toLowerCase();
    const bLabel = b.label.toLowerCase();

    if (aLabel < bLabel) return -1;
    if (aLabel > bLabel) return 1;
    return 0;
  });
};

export const getSortedClassesAndDivisions = (divisionData) => {
  if (!divisionData) return [];

  return divisionData.slice().sort((a, b) => {
    if (a.label.match(/^\d+$/) && b.label.match(/^\d+$/)) {
      // split the class name and take the first number
      const aNum = parseInt(a.label.split(" ")[0]);
      const bNum = parseInt(b.label.split(" ")[0]);

      return aNum - bNum;
    } else {
      //  split the class name and take the first word as number and sort the rest of the words as string
      const aNum = parseInt(a.label.split("Division: ")[1]);
      const bNum = parseInt(b.label.split("Division: ")[1]);

      if (aNum === bNum) {
        const aStr = a.label.split(" ").slice(1).join(" ");
        const bStr = b.label.split(" ").slice(1).join(" ");
        return aStr.localeCompare(bStr);
      }
      return aNum - bNum;
    }
  });
};

export const sortDivisions = (classes) => {
  // sort divisions
  const sortedClasses = classes?.slice().sort((a, b) => {
    if (a.division_name.match(/^\d+$/) && b.division_name.match(/^\d+$/)) {
      // split the class name and take the first number
      const aNum = parseInt(a.division_name.split(" ")[0]);
      const bNum = parseInt(b.division_name.split(" ")[0]);
      return aNum - bNum;
    } else {
      //  split the class name and take the first word as number and sort the rest of the words as string
      const aNum = parseInt(a.division_name.split(" ")[0]);
      const bNum = parseInt(b.division_name.split(" ")[0]);

      const aWithoutWhiteSpace = a.division_name
        .replace(/\s/g, "")
        .toLowerCase();
      const bWithoutWhiteSpace = b.division_name
        .replace(/\s/g, "")
        .toLowerCase();

      if (isNaN(aNum) && isNaN(bNum)) {
        return aWithoutWhiteSpace > bWithoutWhiteSpace ? 1 : -1;
      }

      if (isNaN(aNum)) {
        return 1;
      }
      if (isNaN(bNum)) {
        return -1;
      }

      if (aNum === bNum) {
        const aStr = a.division_name.split("").slice(1).join("");
        const bStr = b.division_name.split("").slice(1).join("");
        return aStr.localeCompare(bStr);
      }

      return aNum - bNum;
    }
  });
  return sortedClasses;
};

export const sortDivisionsAccordingToClasses = (
  divisions,
  currentSelectedClass
) => {
  const divisionClasses = {};
  // create unique class for each class name in the array, then push division name to the class if matching class name is found
  divisions?.forEach((division) => {
    let className = division?.class?.class_name;
    let classId = division?.class?.classId;
    let divisionName = division?.division_name;
    let attendanceYetToBeMarked =
      division?.student_attendance_data.length === 0;
    let divId = division?.id;
    if (
      currentSelectedClass === null ||
      parseInt(currentSelectedClass) === classId
    ) {
      if (division?.class_teachers.length > 0) {
        let classTeacherName = division?.class_teachers?.[0]?.user?.full_name;
        let classTeacherImg = division?.class_teachers?.[0]?.user?.image;
        let firstName = division?.class_teachers?.[0]?.user?.first_name;
        let lastName = division?.class_teachers?.[0]?.user?.last_name;
        let authId = division?.class_teachers?.[0]?.user?.auth_id;

        if (classTeacherName in divisionClasses) {
          divisionClasses[classTeacherName].divisions.push({
            division_id: divId,
            division_name: divisionName,
            attendanceYetToBeMarked,
          });
        } else {
          divisionClasses[classTeacherName] = {
            class_teacher: {
              first_name: firstName,
              last_name: lastName,
              full_name: classTeacherName,
              image: classTeacherImg,
              auth_id: authId,
            },

            class_name: className,
            divisions: [
              {
                division_id: divId,
                division_name: divisionName,
                attendanceYetToBeMarked,
              },
            ],
          };
        }
      } else {
        let classTeacher = "Not Assigned " + className;
        if (classTeacher in divisionClasses) {
          divisionClasses[classTeacher].divisions.push({
            division_id: divId,
            division_name: divisionName,
            attendanceYetToBeMarked,
          });
        } else {
          divisionClasses[classTeacher] = {
            class_teacher: null,
            class_name: className,
            divisions: [
              {
                division_id: divId,
                division_name: divisionName,
                attendanceYetToBeMarked,
              },
            ],
          };
        }
      }
    }
  });
  let arr = [];
  Object.entries(divisionClasses).map(([key, val]) => arr.push(val));

  // Grouping classes based on teacher assigned or not
  const assignedTeachers = [];
  const notAssigned = [];

  arr.forEach((division) => {
    if (division?.class_teacher === null) {
      notAssigned.push(division);
    } else {
      assignedTeachers.push(division);
    }
  });

  Array.prototype.push.apply(assignedTeachers, notAssigned);
  return assignedTeachers;
};

// Set in JS keeps the insertion order, hence we can use set to make sure data is always in the same order
export const getListOfDivisions = (classExamData) => {
  const uniqueDivisionsInOrder = new Set();
  classExamData.forEach((dataObj) => {
    uniqueDivisionsInOrder.add(dataObj?.division_id);
  });
  return [...uniqueDivisionsInOrder];
};

export const getFirstAndLastName = (name) => {
  if (typeof name !== "string") return ["", ""];

  const namesArray = name.split(" ");
  const firstName = namesArray[0];
  const lastIndex = namesArray?.length - 1;
  let lastName = "";
  if (lastIndex > 0) {
    lastName = namesArray[lastIndex];
  }

  return [firstName, lastName];
};

export const getGender = (gender) => {
  let genderText = "";
  switch (true) {
    case gender === "M":
      genderText = "Male";
      break;
    case gender === "T":
      genderText = "Transgender";
      break;
    case gender === "F":
      genderText = "Female";
      break;
    default:
      genderText = "NA";
  }
  return genderText;
};
