import React, { useState, useMemo } from "react";
import { Spin, Switch, Table } from "antd";
import { DownloadIcon } from "@heroicons/react/outline";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "../../components";
import {
  DEFAULT_SUBJECT_LIST,
  ADD_NEW_GROUP_ID,
  ADD_NEW_SUBJECT_ID,
  ENGLISH,
} from "../../utils/constants";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_SUBJECTS_OF_CLASS,
  GET_SUBJECTS_AND_GROUPS,
} from "../../graphql/queries";
import {
  CREATE_SUBJECTS_FOR_CLASS,
  UPDATE_CLASS,
  UPDATE_RELATIVE_ORDER_OF_SUBJECTS,
} from "../../graphql/mutations";
import AddSubjectModal from "./AddSubjectModal";
import EditSubjectsModal from "./EditSubjectsModal";
import { getSchoolID } from "../../shared/getSchoolID";
import axios from "axios";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { cloneDeep } from "lodash";
import { getSubjectCode } from "../../utils";
import { DEFAULT_ALL_SUBJECTS } from "../../utils/constants";
import { MenuOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DraggableRow,
  DraggableWrapperGenerator,
} from "../../components/DraggableComponents";
const DraggableWrapper = DraggableWrapperGenerator(verticalListSortingStrategy);

const mapRowsToKeys = (subjects) => {
  return subjects?.map((subject) => ({ ...subject, key: subject?.id }));
};

const isRelativeOrderUnique = (subjects) => {
  const uniqueRelativeOrder = new Set();
  subjects?.forEach((subject) => {
    uniqueRelativeOrder.add(subject?.relative_order);
  });

  return uniqueRelativeOrder?.size === subjects?.length;
};

const getMediumGroupAndSubjectList = (settingsData, className) => {
  const medium = settingsData?.classes_by_pk?.school?.medium;
  const mediumKey = medium.charAt(0).toLowerCase() + medium.slice(1);

  const allSubjects =
    settingsData?.classes_by_pk?.school?.settings?.allSubjects ||
    DEFAULT_ALL_SUBJECTS;

  const groupMap = new Map();
  const finalSubjectList = allSubjects?.map((subject, idx) => {
    groupMap.set(subject?.group, subject?.groupTranslations);
    return {
      group: subject?.group,
      label: subject?.subjectName,
      translations: cloneDeep(subject?.translations),
      code: subject?.code,
      id: idx,
      value: idx,
      groupTranslations: subject?.groupTranslations,
      isCore: subject?.isCore,
    };
  });

  const finalGroupList = [];
  let idx = 0;
  for (let [groupName, tranlations] of groupMap) {
    finalGroupList.push({
      label: groupName,
      value: idx,
      id: idx,
      groupTranslations: tranlations,
    });
    idx += 1;
  }

  return { mediumKey, finalGroupList, finalSubjectList };
};

function ManageSubjects() {
  const [isAddSubjectModalVisible, setIsAddSubjectModalVisible] =
    useState(false);
  const [isEditSubjectsModalVisible, setIsEditSubjectsModalVisible] =
    useState(false);
  const [subjectsDropdown, setSubjectDropdown] = useState([]);
  const [groupDropdown, setGroupDropdown] = useState([]);
  const [medium, setMedium] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { state } = useLocation();
  const { className, from } = state;
  const [activeId, setActiveId] = useState(null);
  const [subjectTableData, setSubjectTableData] = useState(null);

  const columnsToRenderOverlay = useMemo(
    () => [
      "name",
      ["translations", "subject", medium],
      "group",
      ["translations", "group", medium],
      "is_core",
      "isEnabled",
    ],
    [medium]
  );

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = subjectTableData?.find(({ id }) => id === activeId);

    const elemsToRender = [];
    columnsToRenderOverlay?.forEach((dataIndex) => {
      if (Array?.isArray(dataIndex)) {
        let elemUnderAccess = row;
        dataIndex?.forEach((objectKey) => {
          elemUnderAccess = elemUnderAccess?.[`${objectKey}`];
        });
        elemsToRender.push(elemUnderAccess);
      } else {
        elemsToRender.push(row?.[`${dataIndex}`]);
      }
    });

    return elemsToRender;
  }, [activeId, subjectTableData, columnsToRenderOverlay]);

  const {
    data: subjectsData,
    loading: isSubjectsLoading,
    refetch: refetchSubjects,
  } = useQuery(GET_SUBJECTS_OF_CLASS, {
    variables: {
      classId: parseInt(id),
      withDivisions: false,
    },
    onCompleted: (result) => {
      const subjects = result?.subjects;
      if (!isRelativeOrderUnique(subjects)) {
        // If the relative_order is not present, we set it.
        const subjectOrder = subjects?.map((subject, idx) => ({
          _set: { relative_order: idx },
          where: { id: { _eq: subject?.id } },
        }));
        updateOrder({
          variables: {
            subjectOrder,
          },
        });
      }
      setSubjectTableData(subjects);
    },
    fetchPolicy: "network-only",
  });

  const [updateOrder, { loading: updateOrderLoading }] = useMutation(
    UPDATE_RELATIVE_ORDER_OF_SUBJECTS,
    {
      refetchQueries: [
        {
          query: GET_SUBJECTS_OF_CLASS,
          variables: { classId: parseInt(id), withDivisions: false },
          notifyOnNetworkStatusChange: true,
        },
      ],
    }
  );

  const [createSubjects, { loading: isSubjectsUpdateLoading }] = useMutation(
    CREATE_SUBJECTS_FOR_CLASS,
    {
      refetchQueries: [
        {
          query: GET_SUBJECTS_OF_CLASS,
          variables: { classId: parseInt(id), withDivisions: false },
          notifyOnNetworkStatusChange: true,
        },
      ],
    }
  );

  const [updateClass, { loading: isClassUpdateLoading }] = useMutation(
    UPDATE_CLASS,
    {
      refetchQueries: [
        {
          query: GET_SUBJECTS_OF_CLASS,
          variables: { classId: parseInt(id), withDivisions: false },
          notifyOnNetworkStatusChange: true,
        },
      ],
    }
  );

  const { data: settingsData, loading } = useQuery(GET_SUBJECTS_AND_GROUPS, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
    onCompleted: (res) => {
      let {
        mediumKey: medium,
        finalGroupList,
        finalSubjectList,
      } = getMediumGroupAndSubjectList(res, className);

      // Use default subject list if there are no subjects present in settings
      if (finalSubjectList?.length === 0) {
        finalSubjectList = cloneDeep(DEFAULT_SUBJECT_LIST);
      }
      if (finalGroupList?.length === 0) {
        finalGroupList = cloneDeep(DEFAULT_SUBJECT_LIST);
      }

      finalSubjectList.push({
        label: <p className="text-primary px-2">+ Add new subject</p>,
        id: ADD_NEW_SUBJECT_ID,
        value: ADD_NEW_SUBJECT_ID,
      });

      finalGroupList.push({
        label: <p className="text-primary px-2">+ Add new group</p>,
        value: ADD_NEW_GROUP_ID,
        id: ADD_NEW_GROUP_ID,
      });

      setMedium(medium);
      setSubjectDropdown(finalSubjectList);
      setGroupDropdown(finalGroupList);
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setActiveId(active.id);
  }

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = subjectTableData?.findIndex(
        (subject) => subject?.id === active?.id
      );
      const newIndex = subjectTableData?.findIndex(
        (subject) => subject?.id === over?.id
      );
      const newSubjectsOrder = arrayMove(
        mapRowsToKeys(subjectTableData),
        oldIndex,
        newIndex
      );

      const subjectOrder = newSubjectsOrder?.map((subject, idx) => ({
        _set: { relative_order: idx },
        where: { id: { _eq: subject?.id } },
      }));
      await updateOrder({
        variables: {
          subjectOrder,
        },
      });
    }
    // Stop overlay.
    setActiveId(null);
  };

  const SUBJECT_TABLE_COLUMNS = [
    {
      key: "dragHandle",
      dataIndex: "dragHandle",
      title: <p className="font-semibold text-base">Sequence</p>,
      render: () => <MenuOutlined />,
      width: 10,
    },
    {
      title: () => <p className="font-semibold text-base">Subject Name</p>,
      dataIndex: "name",
      editable: false,
      render: (text, row) => (
        <p
          className={`font-semibold text-left ${
            row?.isEnabled ? "text-black" : "text-gray-400"
          }`}
        >
          {text}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-semibold text-base">Subject Display Name</p>
      ),
      dataIndex: ["translations", "subject"],
      editable: true,
      render: (text, row) => {
        return (
          <p
            className={`font-semibold text-left ${
              row?.isEnabled ? "text-black" : "text-gray-400"
            }`}
          >
            {text?.[`${medium}`]
              ? text?.[`${medium}`]
              : medium === ENGLISH
              ? row?.name
              : "-"}
          </p>
        );
      },
    },
    {
      title: () => <p className="font-semibold text-base">Group</p>,
      editable: true,
      dataIndex: "group",
      render: (text, row) => (
        <p
          className={`font-semibold text-left ${
            row?.isEnabled ? "text-black" : "text-gray-400"
          }`}
        >
          {text || "-"}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-semibold text-base">Group Display Name</p>
      ),
      dataIndex: ["translations", "group"], //
      editable: true,
      render: (text, row) => (
        <p
          className={`font-semibold text-left ${
            row?.isEnabled ? "text-black" : "text-gray-400"
          }`}
        >
          {text?.[`${medium}`]
            ? text?.[`${medium}`]
            : medium === ENGLISH
            ? row?.group
            : "-"}
        </p>
      ),
    },
    {
      title: () => <p className="font-semibold text-base">Core Subject</p>,
      dataIndex: "is_core",
      editable: true,
      render: (isCore, row) => (
        <p
          className={`font-semibold text-left ${
            row?.isEnabled ? "text-black" : "text-gray-400"
          }`}
        >
          {isCore ? "Yes" : "No"}
        </p>
      ),
    },
    {
      title: () => <p className="font-semibold text-base">Enabled/Disabled</p>,
      editable: true,
      dataIndex: "isEnabled",
      render: (isEnabled) => (
        <div className="text-center">
          <Switch checked={isEnabled} type="default" disabled />
        </div>
      ),
    },
  ];

  const handleAddNewSubjectClick = () => {
    setIsAddSubjectModalVisible(true);
  };

  const handleCloseAddSubjectModal = () => {
    setIsAddSubjectModalVisible(false);
  };

  const handleEditSubjectsClick = () => {
    setIsEditSubjectsModalVisible(true);
  };

  const handleCloseEditSubjectsModal = () => {
    setIsEditSubjectsModalVisible(false);
  };

  const hanldeImportClasses = () => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/class/create/single/subjects`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        schoolId: getSchoolID(),
        classId: parseInt(id),
      },
    };
    axios(config)
      .then((res) => {
        refetchSubjects();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddNewSubject = (formData) => {
    // Find Subject Based on subject name
    let code = "";
    const board = settingsData?.classes_by_pk?.school?.board?.code;
    const subject = subjectsDropdown?.find(
      (subject) => subject?.label === formData.subjectName
    );
    if (subject) {
      const subjectCode = subject?.code?.split("_")[0];
      code = getSubjectCode(subjectCode, className, board, medium);
    } else {
      code = getSubjectCode(formData?.subjectName, className, board, medium);
    }
    if (!formData.subjectName) return;
    createSubjects({
      variables: {
        subjects: [
          {
            title: formData.subjectName?.trim(),
            group: formData.groupName?.trim(),
            class_id: parseInt(id),
            is_core: formData?.isCore,
            code,
            translations: {
              group: {
                [medium]:
                  formData?.groupDisplayName?.trim() ||
                  subject?.translations?.[medium],
              },
              subject: {
                [medium]:
                  formData?.subjectDisplayName?.trim() ||
                  subject?.translations?.[medium],
              },
            },
          },
        ],
      },
    });
    setIsAddSubjectModalVisible(false);
  };

  const handleEditSubjects = (formData, reportsData) => {
    if (
      Object.values(formData)?.length > 0 &&
      Object.values(formData)?.[0]?.id !== undefined
    ) {
      createSubjects({
        variables: {
          subjects: Object.values(formData)
            .filter((subject) => typeof subject === "object")
            .map((subject) => {
              const currentSubject = subjectsData?.subjects?.find(
                (currSub) => currSub?.id === subject?.id
              );
              return {
                id: subject.id,
                title: subject.name,
                group: subject.group,
                class_id: parseInt(id),
                is_enable: subject.isEnabled,
                is_core: subject?.is_core,
                translations: {
                  group: {
                    [medium]:
                      subject?.groupDisplayName?.trim() ||
                      currentSubject?.translations?.group?.[`${medium}`],
                  },
                  subject: {
                    [medium]:
                      subject?.subjectDisplayName?.trim() ||
                      currentSubject?.translations?.subject?.[`${medium}`],
                  },
                },
              };
            }),
        },
      });
    }
    updateClass({
      variables: {
        classId: parseInt(id),
        classData: {
          report_template: reportsData.progressReportTemplate,
          is_display_subjects_by_group: reportsData.showGroupsInReportCard,
        },
      },
    });
    setIsEditSubjectsModalVisible(false);
  };

  const components = {
    body: {
      wrapper: DraggableWrapper,
      row: DraggableRow,
    },
  };

  const progressReportTemplate =
    subjectsData?.subjects?.[0]?.class?.report_template;
  const showGroupsInReportCard =
    subjectsData?.subjects?.[0]?.class?.is_display_subjects_by_group;

  return (
    <>
      <div className="flex flex-col ml-6 mr-6 pl-0 md:p-4 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-hidden">
        <div className="flex flex-row gap-x-4 items-center mb-9 justify-between">
          <div className="flex items-center justify-center gap-x-10">
            <h2 className="text-2xl text-left font-bold flex-col m-0">
              Manage Subjects
            </h2>
            <h3 className="font-medium text-gray-800 text-xl text-center m-0">
              Class: {className}
            </h3>
          </div>
          <Button
            id="manageclasses-back"
            onClick={() => {
              if (from === "Exams") {
                navigate("/manage-exams", {
                  replace: true,
                  state: {
                    classId: id,
                  },
                });
              }
              navigate(-1);
            }}
            buttonStyle="secondary"
          >
            Back to Manage {from}
          </Button>
        </div>
        <div className="flex flex-col gap-y-2 justify-center">
          <div className="flex items-center justify-between gap-x-4">
            <div className="flex gap-x-6">
              <Button
                id="manageclasses-managesubjects-import"
                buttonStyle="primary"
                className="w-44"
                onClick={hanldeImportClasses}
                disabled={subjectsData?.subjects.length > 0}
              >
                <span className="flex justify-center items-center gap-x-1">
                  <DownloadIcon className="h-5 w-5" />
                  Import Subjects
                </span>
              </Button>
              <Button
                id="manageclasses-managesubjects-addnew"
                buttonStyle="primary"
                className="w-44"
                onClick={handleAddNewSubjectClick}
              >
                <span className="flex justify-center items-center gap-x-1">
                  <PlusIcon className="h-5 w-5" />
                  Add New Subject
                </span>
              </Button>
            </div>
            <Button
              id="manageclasses-managesubjects-edit"
              buttonStyle="primary"
              className="w-32"
              onClick={handleEditSubjectsClick}
            >
              <span className="flex justify-center items-center gap-x-1">
                <PencilIcon className="h-5 w-5" />
                Edit Table
              </span>
            </Button>
          </div>
        </div>
        <div className="flex overflow-y-scroll flex-col gap-y-3 bg-white mt-3 pt-7 px-6 pb-4 rounded-lg shadow-md">
          <Spin
            spinning={
              isSubjectsLoading ||
              isSubjectsUpdateLoading ||
              isClassUpdateLoading ||
              updateOrderLoading
            }
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <Table
                bordered
                pagination={false}
                columns={SUBJECT_TABLE_COLUMNS}
                dataSource={mapRowsToKeys(subjectTableData)}
                components={components}
              />
              {/* Render overlay component. */}
              <DragOverlay>
                {activeId ? (
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr className="ant-table-row ant-table-row-level-0">
                        {selectedRow?.map((dataElem) =>
                          typeof dataElem === typeof false ? (
                            <td className="antd-table-cell">
                              {dataElem === true ? "Yes" : "No"}
                            </td>
                          ) : (
                            <td className="antd-table-cell">{dataElem}</td>
                          )
                        )}
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </DragOverlay>
            </DndContext>
          </Spin>
        </div>
      </div>
      {isAddSubjectModalVisible ? (
        <AddSubjectModal
          isVisible={isAddSubjectModalVisible}
          onClose={handleCloseAddSubjectModal}
          onSave={handleAddNewSubject}
          classDetails={{ id, className }}
          schoolDetails={{ medium, subjectsDropdown, groupDropdown }}
        />
      ) : null}
      {isEditSubjectsModalVisible ? (
        <EditSubjectsModal
          isVisible={isEditSubjectsModalVisible}
          onClose={handleCloseEditSubjectsModal}
          subjects={subjectsData?.subjects}
          onSave={handleEditSubjects}
          medium={medium}
          groupDropdown={groupDropdown}
          reportData={{ showGroupsInReportCard, progressReportTemplate }}
        />
      ) : null}
    </>
  );
}

export default ManageSubjects;
