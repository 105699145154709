import React from "react";
import feesClipboard from "../../../assets/images/report.png";
import { useNavigate } from "react-router-dom";

function Documents() {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col ml-6 pl-0 md:p-4 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row gap-x-4 items-center mb-44">
        <p className="text-2xl text-left font-bold flex-col m-0">
          Fees & Expenses Reports
        </p>
      </div>
      <div className="flex justify-center items-center gap-x-11">
        <button
          onClick={() => {
            navigate(`/fees-reports/student-fees-report`);
          }}
          className="relative text-center"
        >
          <img alt="clipboard" src={feesClipboard} className="h-64 w-56" />
          <div className="absolute left-3 right-0 top-24 text-white font-bold text-lg">
            <p>Student's</p>
            <p>Fees Report</p>
          </div>
        </button>
        <button
          onClick={() => {
            navigate(`/fees-reports/daily-transactions`);
          }}
          className="relative text-center"
        >
          <img alt="clipboard" src={feesClipboard} className="h-64 w-56" />
          <div className="absolute left-3 right-0 top-24 text-white font-bold text-lg">
            <p>Daily</p>
            <p>Transactions</p>
          </div>
        </button>
        <button
          onClick={() => {
            navigate(`/fees-reports/monthly-transactions`);
          }}
          className="relative text-center"
        >
          <img alt="clipboard" src={feesClipboard} className="h-64 w-56" />
          <div className="absolute left-3 right-0 top-24 text-white font-bold text-lg">
            <p>Monthly</p>
            <p>Transaction</p>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Documents;
