import React, { useEffect, useRef, useState } from "react";
import ClassesView from "./ClassesView";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_SUBJECTS_OF_CLASS,
  INSERT_SUBJECTS_OF_CLASS,
} from "../../views/classesView/graphql";
import { Spin, Drawer, notification } from "antd";
import _ from "lodash";
import CloseIcon from "../../components/closeIcon";
import { getSchoolID } from "../../shared/getSchoolID";
import Dropdown from "../../components/Dropdown";
import Switch from "../../components/Switch";
import { GET_ACADEMIC_YEARS } from "../../shared/graphql";
import axios from "axios";
import { PlusIcon } from "@heroicons/react/solid";
import useTracking from "../../hooks/useTracking";

const Toggle = ({ data, onChange }) => {
  return (
    <Switch
      checked={data.selected}
      onChange={(value) => onChange(value, data.id)}
      type="default"
    >
      <span className="sr-only">{data.name}</span>
      <span
        className={`transform transition ease-in-out duration-400 ${
          data.selected ? "translate-x-6" : "translate-x-1"
        } inline-block w-4 h-4 transform bg-white rounded-full`}
      />
    </Switch>
  );
};
const SubjectsToggleList = ({
  index,
  setIsOpen,
  addref,
  saveChangesref,
  setSubCount,
}) => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [newSubject, setnewsubject] = useState("");
  const [is_newToggle, setis_newToggle] = useState(false);

  useEffect(() => {
    setSubCount(selectedSubjects.filter((sub) => sub.selected).length);
  }, [selectedSubjects]);

  const { loading, error } = useQuery(GET_SUBJECTS_OF_CLASS, {
    variables: {
      classid: index,
      withDivisions: false,
      divid: 1,
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      setSelectedSubjects(
        res?.subjects.map((rsub) => ({ ...rsub, is_new: false }))
      );
    },
  });

  const [
    insertSubjects,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(INSERT_SUBJECTS_OF_CLASS, {
    refetchQueries: [
      {
        query: GET_SUBJECTS_OF_CLASS,
        variables: { classid: index, withDivisions: false, divid: 1 },
      },
    ],
  });

  const onChange = (value, id) => {
    setSelectedSubjects(
      selectedSubjects.map((sub) =>
        sub.id === id ? { ...sub, selected: value } : { ...sub }
      )
    );
  };
  const onAddNew = () => {
    setis_newToggle(true);
  };

  const onSave = () => {
    let newSubs = selectedSubjects.filter((x) => x.is_new);
    let oldSubs = selectedSubjects.filter((x) => x.is_new === false);
    let newpayload = [];
    let oldpayload = [];
    oldSubs.map((ssub) => {
      oldpayload.push({
        class_id: index,
        id: ssub.id,
        title: ssub.name,
        is_enable: ssub.selected,
        is_active: ssub.selected,
      });
    });
    newSubs.map((ssub2) => {
      newpayload.push({
        class_id: index,
        title: ssub2.name,
        is_enable: ssub2.selected,
        is_active: ssub2.selected,
      });
    });

    let finalPayload = oldpayload.concat(newpayload);

    insertSubjects({ variables: { objects: finalPayload } })
      .then((response) => {
        notification["success"]({ message: "Subjects added", duration: 1.5 });
        setIsOpen(false);
      })
      .catch((reserror) => {
        notification["error"]({
          message: "Something went wrong",
          description: "Please try again.",
          duration: 1.5,
        });
      });
  };
  const onSaveNewSubject = (newindex) => {
    let checkz = [
      ...selectedSubjects,
      {
        id: Date.now(),
        name: newSubject,
        selected: false,
        is_new: true,
      },
    ];
    setSelectedSubjects(checkz);
    setnewsubject("");
    setis_newToggle(false);
  };
  const checkIfNewSubjectHasLength = () => {
    let ns = _.trim(newSubject);
    return ns.length ? true : false;
  };

  if (error) return `Error! ${error.message}`;
  return (
    <React.Fragment>
      <Spin spinning={loading || mutationLoading}>
        <>
          <button
            className="hidden ml-3 p-2 rounded-md bg-white border border-gray-300 text-xs leading-4 font-medium shadow-sm "
            onClick={() => onAddNew()}
            ref={addref}
          >
            Add new
          </button>
        </>
        <>
          {selectedSubjects?.map((dataB, dataBIndex) => {
            return (
              <>
                <div
                  className="flex-row flex flex-wrap justify-between mt-4 "
                  key={dataBIndex}
                >
                  <span>{dataB.name}</span>
                  <span>
                    <Toggle
                      data={dataB}
                      onChange={(value, id) => onChange(value, id)}
                    />
                  </span>
                </div>
              </>
            );
          })}
        </>
        {is_newToggle ? (
          <>
            <div className="flex-row flex flex-wrap justify-between mt-4  items-center">
              <input
                type="text"
                placeholder="Add Subject"
                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 cw-200 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                onChange={(e) => setnewsubject(e.target.value)}
                value={newSubject}
              />
              <div className="w-6/12 justify-end flex    ">
                <button
                  onClick={() => {
                    setis_newToggle(false);
                    setnewsubject("");
                  }}
                  className="ml-3 p-2 rounded-md bg-white border border-gray-300 text-xs leading-4 font-medium shadow-sm "
                >
                  Cancel
                </button>
                {checkIfNewSubjectHasLength() ? (
                  <button
                    onClick={() => onSaveNewSubject()}
                    className="ml-2 p-2 rounded-md bg-indigo-700 border border-indigo-700 text-white text-xs leading-4 font-sm shadow-sm "
                  >
                    Save
                  </button>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
        <>
          <div className="mt-16">
            <button
              type="button"
              className=" hidden inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              onClick={() => onSave()}
              ref={saveChangesref}
            >
              Save Changes
            </button>
          </div>
        </>
      </Spin>
    </React.Fragment>
  );
};
const ManageClasses = () => {
  const [isOpenInfoDialog, setIsOpenInfoDialog] = useState(false);
  const [isAddNewClassOpen, setIsAddNewClassOpen] = useState(false);
  const [addClassFormDetails, setAddClassFormDetails] = useState({
    name: "",
  });
  const [indexForDrawer, setIndex] = useState();
  const [classTexts, setclassTexts] = useState("");
  const [isRefetchingClasses, setIsRefetchingClasses] = useState(false);
  const [subCount, setSubCount] = useState(null);
  const [academicYears, setAcademicYears] = useState(null);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
  const [currentAcademicYear, setCurrentAcademicYear] = useState(null);
  const addref = useRef(null);
  const saveChangesref = useRef(null);
  const { trackEvent } = useTracking();

  const { loading: isAcademicYearsLoading } = useQuery(GET_ACADEMIC_YEARS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (data) => {
      const academicYearsList = data.acadamic_years.map((academicYear) => {
        return {
          label: academicYear.title,
          value: academicYear.id,
          isCurrent: academicYear.is_current,
        };
      });
      setAcademicYears(academicYearsList);
      // find current academic year
      const currentAcademicYear = data.acadamic_years.find(
        (academicYear) => academicYear.is_current === true
      );
      setSelectedAcademicYear({
        label: currentAcademicYear.title,
        value: currentAcademicYear.id,
        isCurrent: currentAcademicYear.is_current,
      });
      setCurrentAcademicYear({
        label: currentAcademicYear.title,
        value: currentAcademicYear.id,
        isCurrent: currentAcademicYear.is_current,
      });
    },
  });

  const handleSaveNewClass = () => {
    trackEvent("Save New Class");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/class/create/single`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        className: addClassFormDetails.name,
        schoolId: getSchoolID(),
        academicYearId: selectedAcademicYear?.value,
      },
    };
    axios(config)
      .then((res) => {
        setIsRefetchingClasses(true);

        notification["success"]({ message: "Class added", duration: 1.5 });
        setAddClassFormDetails({
          name: "",
        });
        setIsAddNewClassOpen(false);
      })
      .then(() => {
        setIsRefetchingClasses(false);
      })
      .catch((err) => {
        setIsAddNewClassOpen(false);
        notification["error"]({
          message: "Something went wrong",
          description: "Please try again.",
          duration: 1.5,
        });
      });
  };

  const handleAcademicYearChange = (value) => {
    const selectedYear = academicYears.find(
      (academicYear) => academicYear.value === parseInt(value)
    );
    setSelectedAcademicYear(selectedYear);
  };

  return (
    <div className="flex flex-col px-8 py-7 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex h-9 gap-x-5">
        <p className="text-2xl text-left font-bold">Manage Classes</p>
        <Dropdown
          id="manageclasses-year"
          type="default"
          options={academicYears}
          value={selectedAcademicYear?.label}
          onItemClick={handleAcademicYearChange}
        />
        <>
          <button
            id="manageclasses-addnew"
            type="button"
            onClick={() => setIsAddNewClassOpen(true)}
            class="py-5 px-4 flex justify-center items-center  bg-indigo-700 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
          >
            <span className="flex -ml-1 justify-center items-center gap-x-1">
              <PlusIcon className="h-4 w-5" />
              Add new class{" "}
            </span>
          </button>
          {isAddNewClassOpen ? (
            <div className=" ml-4  rounded-md flex items-center gap-x-3">
              <div id="manageclasses-addnew-input" className="flex flex-col">
                <input
                  type="text"
                  placeholder="Class Name"
                  value={addClassFormDetails.name}
                  onChange={(e) =>
                    setAddClassFormDetails({
                      ...addClassFormDetails,
                      name: e.target.value,
                    })
                  }
                  className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                />
              </div>
              <button
                id="manageclasses-addnew-save"
                type="button"
                onClick={() => handleSaveNewClass()}
                class="h-9 py-3 px-4 flex justify-center items-center  bg-indigo-700 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
              >
                <span>Save</span>
              </button>
              <button
                id="manageclasses-addnew-cancel"
                onClick={() => setIsAddNewClassOpen(false)}
                className="h-9 py-2 px-4 rounded-md bg-white border border-gray-300 text-sm leading-4 font-medium shadow-sm text-center"
              >
                Cancel
              </button>
            </div>
          ) : null}
        </>
      </div>
      <Spin spinning={isAcademicYearsLoading}>
        {selectedAcademicYear && (
          <ClassesView
            isRefetchingClasses={isRefetchingClasses}
            drawerOpen={isOpenInfoDialog}
            selectedAcademicYear={selectedAcademicYear}
            currentAcademicYear={currentAcademicYear}
            setdrawerOpen={(sth, classIndex, cText) => {
              setIsOpenInfoDialog(sth);
              setIndex(classIndex);
              setclassTexts(cText);
            }}
          />
        )}
      </Spin>
      <div className="flex items-center justify-center">
        <Drawer
          width="36%"
          placement="right"
          visible={isOpenInfoDialog}
          onClose={() => setIsOpenInfoDialog(false)}
          title={
            <div className="flex flex-row items-center">
              <p>Class {classTexts}</p>
              <p>• {subCount} Subjects </p>
              <button
                className="ml-3 p-2 flex justify-center items-center  bg-indigo-700 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-xs leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md shadow-sm "
                onClick={() => {
                  addref.current.click();
                }}
              >
                Add new
              </button>
            </div>
          }
          footer={
            <div className="flex flex-row justify-end">
              <button
                className="ml-3 p-2 flex justify-center items-center  bg-indigo-700 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-xs leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md shadow-sm "
                onClick={() => {
                  saveChangesref.current.click();
                }}
              >
                Save Changes
              </button>
            </div>
          }
          closeIcon={<CloseIcon />}
          destroyOnClose
        >
          <SubjectsToggleList
            index={indexForDrawer}
            setIsOpen={setIsOpenInfoDialog}
            addref={addref}
            saveChangesref={saveChangesref}
            setSubCount={setSubCount}
          />
        </Drawer>
      </div>
    </div>
  );
};
export default ManageClasses;
