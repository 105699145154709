import React, { useState, useEffect } from "react";
import { Dropdown, Button } from "../../components";
import { getAvatar } from "../../shared/getAvatar";
import { useParams } from "react-router-dom";
import { Spin, Modal, notification } from "antd";
import moment from "moment";
import _ from "lodash";
import { CloseCircleFilled } from "@ant-design/icons";
import crown from "../../assets/icons/crown.png";
import usePlanData from "../../hooks/usePlanData";
import useGenerateOrderId from "../../hooks/useGenerateOrderId";
import { getAuthID } from "../../shared/getAuthID";
import { getUserRole } from "../../shared/getUserRole";
import { PLAN_TYPES, USER_ROLES } from "../../utils/constants";
import { GET_STUDENTS_FROM_DIVISION } from "../../views/classesView/graphql";
import { useLazyQuery } from "@apollo/client";
import CheckmarkIcon from "../../images/checkmark.png"

const StudentList = ({ setdraweropen }) => {
  const [isSubscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);
  const [sortDetails, setSortDetails] = useState({
    sortorder: "rollnumber",
    sortTypeLabel: "Sort: Roll Number",
  });
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [search, setsearch] = useState("");
  const [offerCode, setOfferCode] = useState("");
  const [activeStudentId, setActiveStudentId] = useState(null);
  const params = useParams();

  const isUserSuperAdmin = getUserRole()?.includes(USER_ROLES.ADMIN);

  const { sortorder, sortTypeLabel } = sortDetails;
  const getSortOrder = () => {
    switch (sortorder) {
      case "updated_at":
        return {
          updated_at: "desc",
        };
      case "atoz":
        return {
          student: { first_name: "asc" },
        };
      case "ztoa":
        return {
          student: { first_name: "desc" },
        };
      case "rollnumber":
        return {
          student: { roll_no: "asc" },
        };

      default:
        break;
    }
  };

  const [getStudents, { loading: studentloading, error: studentError, data }] =
    useLazyQuery(GET_STUDENTS_FROM_DIVISION, {
      variables: {
        divid: parseInt(params.id),
        search: `%${search}%`,
        orderby: getSortOrder(),
      },
    });

  useEffect(() => {
    getStudents();
  }, [getStudents]);

  const {
    refetch: fetchPlan,
    data: planData,
    loading: isPlanLoading,
  } = usePlanData({
    method: "post",
    url: "subscription/studentPlanData",
    parentNodeEndpoint: true,
  });

  const { refetch: generateOrder } = useGenerateOrderId({
    method: "post",
    url: "payment/generateOrderId",
    parentNodeEndpoint: true,
  });

  const mapPlans = (plans) => {
    if (plans) {
      return plans?.map((plan) => ({
        ...plan,
        label: `${plan?.durationType} - ₹${plan?.finalAmount}`,
        value: plan?.id,
      }));
    }
    return null;
  };

  const parentRenderer = (parent) => {
    let parentrelationnull = _.isNil(parent.relation);
    if (parent && !parentrelationnull) {
      return `${parent.user?.full_name} (${parent.relation})`;
    }
    if (parent && parentrelationnull) {
      return `${parent.user?.full_name}`;
    }
  };

  const onModalClose = () => {
    setSelectedPlan(null);
    setSubscriptionModalVisible(false);
    setOfferCode("");
  };

  const sortOptions = [
    {
      label: "Sort: A to Z",
      value: "atoz",
    },
    {
      label: "Sort: Z to A",
      value: "ztoa",
    },
    {
      label: "Sort: Last updated",
      value: "updated_at",
    },
    {
      label: "Sort: Roll Number",
      value: "rollnumber",
    },
  ];

  if (studentError) return `Error! ${studentError.message}`;

  const handleSubscribeClick = async (studentId) => {
    setActiveStudentId(studentId);
    const plans = await fetchPlan({
      method: "post",
      url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/subscription/studentPlanData`,
      parentNodeEndpoint: true,
      data: {
        studentId,
      },
    });
    if (plans?.length > 0) {
      const selectedPlan = plans?.[0];
      setSelectedPlan({
        ...selectedPlan,
        label: `${selectedPlan?.durationType} - ₹${selectedPlan?.finalAmount}`,
        value: selectedPlan?.id,
      });
      setSubscriptionModalVisible(true);
    } else {
      notification["error"]({
        message: "No Plans found for this Student/Class",
        description: "Please contact support!",
        duration: 1.5,
      });
    }
  };

  const handlePlanChange = (planId) => {
    const selectedPlan = planData?.find(
      (currentPlan) => currentPlan?.id === planId
    );
    setSelectedPlan({
      ...selectedPlan,
      label: `${selectedPlan?.durationType} - ₹${selectedPlan?.finalAmount}`,
      value: selectedPlan?.id,
    });
  };

  const handleActivateSubscription = async () => {
    const promoCode = offerCode?.trim()?.toUpperCase();
    const planId = selectedPlan?.id;
    const authId = getAuthID();
    const studentId = activeStudentId;

    const data = {
      studentId,
      promoCode,
      planId,
      authId,
      offlinePayment: true,
    };

    const subscriptionId = await generateOrder(data);

    if (!subscriptionId) {
      notification["error"]({
        message: "Subscription Error",
        description: "Please enter correct promo code.",
        duration: 1.5,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const getAppVersion = (student) => 
    student
      ?.student
      ?.student_parents
      ?.map(parent => parent?.user?.parent_app_version)
      ?.filter(appVersion => appVersion)[0]

  const appDownloadCount = data?.division_students?.reduce((count, student) => {
    if (getAppVersion(student)) {
      count += 1;
    }
    return count;
  }, 0);

  const freeSubscribedStudent = data?.division_students?.reduce(
    (studentCount, student) => {
      const amount = student?.student?.subscriptions?.[0]?.order?.amount;
      if (amount === 0) {
        studentCount.freeCount += 1;
      } else if (amount > 0) {
        studentCount.totalCount += 1;
      }
      return studentCount;
    },
    { totalCount: 0, freeCount: 0 }
  );

  return (
    <div>
      <div className="container">
        <div className="py-8">
          <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full items-center">
            <div className="">
              <form
                className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
              >
                <div className=" relative w-full">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    defaultValue={search}
                    type="text"
                    id='"form-subscribe-Filter'
                    className=" pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by student name"
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        e.target.value.length > 3 ||
                        e.target.value.length === 0
                      ) {
                        setsearch(e.target.value);
                      }
                    }}
                  />
                </div>
              </form>
            </div>
            <>
              <Dropdown
                id="manageclasses-class-studentlist-sort"
                value={sortTypeLabel}
                defaultValue={sortTypeLabel}
                type="primary"
                options={sortOptions}
                onItemClick={(value) => {
                  const filter = sortOptions.find((x) => x.value === value);
                  setSortDetails({
                    sortorder: value,
                    sortTypeLabel: filter.label,
                  });
                }}
              />
            </>
          </div>
          {isUserSuperAdmin ? (
            <div className="justify-self-start flex-1 font-bold text-lg my-4 text-left justify-start -mb-1 text-lighterYellow">
              Students with Subscription: {freeSubscribedStudent?.totalCount} |
              Free Plans: {freeSubscribedStudent?.freeCount} | App Downloads:{" "}
              {appDownloadCount}
            </div>
          ) : null}
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <Spin spinning={studentloading}>
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        ROLL NO.
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Parent Contact
                      </th>

                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Last Updated
                      </th>
                      {isUserSuperAdmin ? (
                        <th
                          scope="col"
                          className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                        >
                          Premium
                        </th>
                      ) : null}
                      <th
                        scope="col"
                        className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.division_students?.map((student, index) => {
                      const rowId = "manageclasses-student-" + index;
                      const detailsId = "manageclasses-studentdetails-" + index;
                      const lastUpdatedBy =
                        student?.student?.audit_trails[0]?.userByAddedBy;
                      const subscription =
                        student?.student?.subscriptions?.[0] || false;
                      const plan = subscription?.subscription_plan;
                      const planDuration =
                        plan?.duration_type === PLAN_TYPES.ANNUALLY
                          ? "Annually"
                          : "Monthly";
                      const subscriptionAmount = subscription?.order?.amount;
                      const isFree = subscriptionAmount === 0;

                      return (
                        <tr id={rowId}>
                          <td className="px-2 w-10 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-black font-medium whitespace-no-wrap">
                              {student?.student?.roll_no}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center text-left">
                              <div className="flex-shrink-0">
                                {getAvatar(
                                  student?.student?.first_name,
                                  student?.student?.last_name,
                                  student?.student?.image
                                )}
                              </div>
                              <div className="ml-3">
                                <p className="flex justify-between text-black font-medium whitespace-no-wrap">
                                  {student?.student?.first_name}{" "}
                                  {student?.student?.middle_name}{" "}
                                  {student?.student?.last_name}{" "}
                                  { getAppVersion(student) && <img alt="CheckmarkIcon" title={getAppVersion(student)} src={CheckmarkIcon} className="mx-auto w-5 h-5 ml-1 mt-0.4 bg-gradient-to-r from-cyan-500 to-blue-500" /> }
                                </p>
                                <p className="text-gray-500">
                                  Class {student?.division?.division_name}
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="px-2 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {student?.student?.student_parents?.length > 0
                                ? parentRenderer(
                                    student?.student?.student_parents?.[0]
                                  )
                                : `Name (Relation)`}
                            </p>
                            <p className="text-gray-500">
                              {student?.student?.student_parents.length > 0 ? (
                                `${student?.student?.student_parents[0]?.user?.mobile_number}`
                              ) : (
                                <p className="text-red-500">
                                  {`Phone number not available`}
                                </p>
                              )}
                            </p>
                          </td>

                          <td className="px-2 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {lastUpdatedBy
                                ? `${lastUpdatedBy?.first_name} ${
                                    lastUpdatedBy?.last_name || ""
                                  }`
                                : "-"}
                            </p>
                            <p className="text-gray-500">
                              {moment(student?.student?.updated_at).format(
                                "DD MMM YYYY"
                              )}
                            </p>
                          </td>
                          {isUserSuperAdmin ? (
                            <td className="px-2 py-5 border-b border-gray-200 bg-white text-sm text-left">
                              {subscription ? (
                                <>
                                  <span
                                    className={
                                      isFree
                                        ? "text-lighterYellow"
                                        : "text-success"
                                    }
                                  >
                                    {isFree ? "Free" : planDuration} Plan
                                    Activated
                                  </span>
                                </>
                              ) : (
                                <Button
                                  id="subscribe"
                                  className={
                                    "bg-thaliumFlame text-white justify-center"
                                  }
                                  onClick={() =>
                                    handleSubscribeClick(student?.student?.id)
                                  }
                                >
                                  <img
                                    alt="crown"
                                    src={crown}
                                    className="mx-auto mr-2 h-4"
                                  />
                                  Subscribe
                                </Button>
                              )}
                            </td>
                          ) : null}
                          <td
                            id={detailsId}
                            className="px-2 py-5 border-b border-gray-200 bg-white text-sm text-left"
                          >
                            <span
                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                setdraweropen(student?.student?.id, true);
                              }}
                            >
                              Details
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Spin>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isSubscriptionModalVisible}
        centered
        destroyOnClose
        onCancel={onModalClose}
        closeIcon={
          <CloseCircleFilled
            style={{
              color: "#EB5757",
              fontSize: "24px",
            }}
          />
        }
        bodyStyle={{
          justifyContent: "center",
        }}
        width={300}
        footer={null}
      >
        <div className="flex flex-col justify-center">
          <div className="mb-5">
            <p className="text-black text-lg font-bold">
              Activate Subscription
            </p>
          </div>
          {isPlanLoading && !selectedPlan ? (
            <Spin loading />
          ) : (
            <>
              <label className="text-gray-700 font-medium">Plan</label>
              <Dropdown
                type="primary"
                value={selectedPlan?.label || "Select Term"}
                options={mapPlans(planData)}
                onItemClick={handlePlanChange}
                className="justify-between"
              />
            </>
          )}
          <div className="mt-4 flex flex-col">
            <label className="text-gray-700 font-medium">Promo Code</label>
            <input
              placeholder="Enter Promo Code"
              type="text"
              className="border border-gray-400 h-10 rounded-md pl-1 outline-none"
              onChange={(e) => setOfferCode(e.target.value)}
              value={offerCode}
            />
          </div>
          <Button
            className="mt-4 w-28 self-center"
            buttonStyle="success"
            onClick={handleActivateSubscription}
          >
            Subscribe
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default StudentList;
