/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";
import { getSchoolID } from "../shared/getSchoolID";

const getEvalParams = async (assessmentId = null) => {
  const payload = { ExamType: "CUSTOM", schoolId: getSchoolID() };
  if (assessmentId) {
    payload.examId = assessmentId;
  }

  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/exams/v1/getEvalParams`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
      data: payload,
    };
    const { data } = await axios.request(config);
    return data?.data;
  } catch (e) {
    console.error("Use getEvalParams Error: ", { e: e.message });
    return null;
  }
};

export default function useGetEvalParams(triggers = [], options = {}) {
  return useQuery(
    ["getEvalParams", ...triggers],
    () => getEvalParams(triggers?.[2]),
    options
  );
}
