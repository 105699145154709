import React from "react";
import oneRing from "../../assets/images/one-ring.png";
import twoRings from "../../assets/images/two-rings.png";
import moment from "moment";

function AnnouncementCard({ id, announcement, onEditClick }) {
  const classCount =
    announcement?.announcement_receivers_aggregate?.aggregate.count;
  const sentTo =
    announcement?.send_to === "school"
      ? "Sent to all"
      : `Sent to ${classCount} classes`;
  const announcementIcon = sentTo === "Sent to all" ? twoRings : oneRing;

  const handleEditClick = (e) => {
    e.preventDefault();
    onEditClick(announcement);
  };

  const cardId = "announcement-" + id;

  return (
    <div
      id={cardId}
      className="shadow-lg rounded-xl w-full p-6 dark:bg-gray-800 my-7 bg-skyBlue"
    >
      <div className="flex md:flex-row items-center justify-between">
        <div className="flex items-center justify-start w-full flex-grow -mt-4 -ml-4">
          <span className="block relative">
            <img
              alt={
                sentTo === "Sent to all"
                  ? "Two rings in a circle"
                  : "One ring in a circle"
              }
              src={announcementIcon}
              className="h-20 w-13"
            />
          </span>
          <div className="flex flex-col items-start">
            <span className="dark:text-white text-black text-base font-semibold">
              {sentTo}
            </span>
            <span className="text-black text-xs dark:text-gray-300 font-normal">
              {moment(announcement?.created_at).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
        <div
          id="announcements-edit"
          onClick={handleEditClick}
          className="justify-items-end text-right self-start"
        >
          <button className="bg-indigo-600 text-white py-1 px-2 w-20 h-8 rounded-md">
            <span className="flex justify-center items-center gap-x-1">
              Edit
            </span>
          </button>
        </div>
      </div>
      <p className="text-black font-medium text-sm text-left">
        {announcement?.message_body}
      </p>
    </div>
  );
}

export default AnnouncementCard;
