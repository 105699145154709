import { STAFF_TYPE } from "../../utils/constants";

const TeachingStaffBadge = () => (
  <div className="flex flex-1 justify-start">
    <div className="bg-aquaGreen rounded-3xl px-2 py-0.5">
      <p className="font-medium text-xs text-greenDark">Teacher</p>
    </div>
  </div>
);

const NonTeachingStaffBadge = () => (
  <div className="flex flex-1 justify-start">
    <div className="bg-paleOrange rounded-3xl px-2 py-0.5">
      <p className="font-medium text-xs text-barberryBush">
        Non-Teaching Staff
      </p>
    </div>
  </div>
);

const StaffBadge = ({ staffType }) => {
  switch (true) {
    case staffType === STAFF_TYPE.TEACHING:
      return <TeachingStaffBadge />;
    case staffType === STAFF_TYPE.NON_TEACHING:
      return <NonTeachingStaffBadge />;
    default:
      return null;
  }
};

export default StaffBadge;
