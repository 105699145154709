/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import axios from "axios";

const downloadFile = (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Fee_Balance_Report.xlsx");
      document.body.appendChild(link);
      link.click();
};

const downloadFeeBalanceReport = async (payload) => {
      const config = {
            method: "post",
            url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/balanceReportXL`,
            responseType: "blob",
            data: payload,
      };
      const res = await axios.request(config);
      downloadFile(res?.data);
      return true;
};

export default function useDownloadFeeBalanceReport(options = {}) {
      return useMutation({
            mutationFn: (data) => downloadFeeBalanceReport(data),
            onError: () => {
                  notification["error"]({
                        message: "Failure",
                        description: "Unable to download balance report",
                        duration: 1.5,
                  });
            },
            ...options,
      });
}