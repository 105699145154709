import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";
      
const updateSchoolAdmissionForm = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/admission/updateSchoolAdmissionForm`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpdateSchoolAdmissionForm(options = {}) {
  return useMutation({
    mutationFn: (data) => updateSchoolAdmissionForm(data),
    onSuccess: () => {
    	notification["success"]({
	        message: "Success!",
	        description: "Form updated sucessfully",
	        duration: 1.5,
	    });
    },
    onError: () => {
    	notification["error"]({
	        message: "Error!",
	        description: "Unable to update form",
	        duration: 1.5,
	    });
    },
    ...options,
  });
}