/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const generatePin = async (payload, appName) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_AUTH_ENDPOINT}/v1/pin/generateLoginPin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...payload,
        appName,
      },
    };
    const res = await axios.request(config);
    return res?.data;
  } catch (e) {
    notification["error"]({
      message: "Could not generate pin",
      description: "Please try again.",
      duration: 1.5,
    });
    console.error("Generate Pin Error: ", { e: e.message });
    return null;
  }
};

export default function useGeneratePin(appName, options = {}) {
  return useMutation({
    mutationFn: (data) => generatePin(data, appName),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "Pin generated successfully",
        duration: 1.5,
      });
    },
    onError: (error) => {
      notification["error"]({
        message: "Could not generate pin",
        description: "Please try again.",
        duration: 1.5,
      });
      console.error(error);
    },
    ...options,
  });
}
