import React, { useState, useEffect } from "react";
import StatsView from "./StatsView";
import TodaysStat from "./TodaysStats";
import SchoolStat from "./SchoolStats";
import { useQuery } from "@apollo/client";
import { getSchoolID } from "../../shared/getSchoolID";
import take from "lodash/take";
import {
  GET_DASHBOARD_STATS,
  GET_DIVISIONS_WITH_FILTER,
  GET_CURRENT_ACADEMIC_YEAR,
  GET_MENU_SCHOOL_DETAILS,
} from "../../graphql/queries";
import { sortDivisions, sortDivisionsAccordingToClasses } from "../../helpers";
import { PencilIcon } from "@heroicons/react/solid";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { getAuthID } from "../../shared/getAuthID";
import useTeacherAttendance from "../../hooks/useTeacherAttendance";
import moment from "moment";
import { DATE_FORMATS } from "../../utils/constants";
import { getUserRole } from "../../shared/getUserRole";
import { USER_ROLES, STAFF_TYPE } from "../../utils/constants";

function Dashboard() {
  const isUserSuperAdmin = getUserRole()?.includes(USER_ROLES.ADMIN);
  const [absentStaff, setAbsentStaff] = useState({
    staffOnLeave: [],
    totalTeachers: 0,
    teachersOnLeave: 0,
    nonTeachingOnLeave: 0,
  });
  const [divisionsData, setDivisionsData] = useState([]);
  const schoolId = getSchoolID();
  const authId = getAuthID();

  const { data: academicYearData } = useQuery(GET_CURRENT_ACADEMIC_YEAR, {
    variables: {
      schoolId,
    },
  });

  const { data } = useTeacherAttendance(
    {
      schoolId,
      date: moment().format(DATE_FORMATS.YYYYMMDD),
    },
    {
      onSuccess: (res) => {
        const teacherList = res?.data?.teacherData;
        const staffAggregate = teacherList?.reduce(
          (acc, currentStaff) => {
            if (currentStaff?.staffType === STAFF_TYPE.TEACHING) {
              return {
                ...acc,
                totalTeachers: acc.totalTeachers + 1,
                teachersOnLeave: currentStaff?.isPresent
                  ? acc.teachersOnLeave
                  : acc.teachersOnLeave + 1,
              };
            }
            return {
              ...acc,
              nonTeachingOnLeave: currentStaff?.isPresent
                ? acc.nonTeachingOnLeave
                : acc.nonTeachingOnLeave + 1,
            };
          },
          {
            totalTeachers: 0,
            teachersOnLeave: 0,
            nonTeachingOnLeave: 0,
          }
        );
        const staffOnLeave = teacherList?.filter(
          ({ isPresent }) => isPresent === false
        );
        setAbsentStaff({
          staffOnLeave,
          ...staffAggregate,
        });
      },
    }
  );

  const { data: dashboardStatsData } = useQuery(GET_DASHBOARD_STATS, {
    variables: {
      schoolID: schoolId,
    },
  });

  const { data: divisionsYetToBeMarked } = useQuery(GET_DIVISIONS_WITH_FILTER, {
    variables: {
      schoolId,
      search: `%%`,
    },
    fetchPolicy: "network-only",
    onCompleted: () => {
      let divisionsNotMarked = divisionsYetToBeMarked?.divisions?.filter(
        (division) => {
          return division.student_attendance_data.length === 0;
        }
      );
      let divisions = sortDivisions(divisionsNotMarked);
      let divisionClasses = sortDivisionsAccordingToClasses(divisions, null);

      if (divisionClasses.length > 3) {
        divisionClasses = divisionClasses.slice(0, 3);
      }
      setDivisionsData(divisionClasses);
    },
  });

  const { data: schoolDetails } = useQuery(GET_MENU_SCHOOL_DETAILS, {
    variables: {
      schoolID: schoolId,
      authid: authId,
    },
  });

  const upto3staffOnLeave =
    absentStaff.staffOnLeave?.length > 3
      ? take(absentStaff.staffOnLeave, 3)
      : absentStaff.staffOnLeave;

  const teacherCount = absentStaff?.totalTeachers || 0;
  const studentCount = dashboardStatsData?.students?.aggregate?.count || 0;
  const leaveCount = absentStaff?.staffOnLeave?.length || 0;
  const studentPresent = dashboardStatsData?.presentStudent?.aggregate?.count;
  const teacherPresent =
    absentStaff?.totalTeachers - absentStaff?.teachersOnLeave || 0;
  const staffCount = data?.data?.teacherData?.length;

  const isTeacherAttendanceTaken = data?.data?.hasAttendanceBeenSaved;

  const navigate = useNavigate();

  useEffect(() => {
    if (navigate) {
      if (schoolId === null && isUserSuperAdmin) {
        navigate("/adminSettings");
      } else if (!authId) {
        navigate("/auth");
      }
    }
  }, [schoolId, authId, isUserSuperAdmin, navigate]);

  return (
    <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex justify-center items-center bg-white border-b-2 py-2">
        <div className="w-5/6">
          <p className="text-black font-bold text-2xl">
            {schoolDetails?.schools_by_pk?.title}
          </p>
          <div className="flex gap-x-12 justify-center py-1">
            <p className=" font-medium text-sm text-gray-500">
              U-DISE Number :{" "}
              <span className="text-black">
                {schoolDetails?.schools_by_pk?.udise_number}
              </span>{" "}
            </p>
            <p className=" font-medium text-sm text-gray-500">
              Index Number :{" "}
              <span className="text-black">
                {schoolDetails?.schools_by_pk?.index_number}
              </span>{" "}
            </p>
          </div>
        </div>

        <Button buttonStyle="primary" onClick={() => navigate("/settings")}>
          <span className="text-white flex justify-center items-center gap-x-1">
            <PencilIcon className="h-4 w-4" />
            Edit School
          </span>
        </Button>
      </div>
      <main className="flex flex-col w-full px-8 py-3 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
        <h1 className="text-2xl w-full text-left font-bold">Dashboard</h1>
        <StatsView
          currentAcademicYear={
            academicYearData?.schools_by_pk?.acadamic_years?.[0]?.title
          }
          teacherCount={teacherCount}
          studentCount={studentCount}
          studentsPresent={studentPresent}
          teachersPresent={teacherPresent}
          isTeacherAttendanceTaken={isTeacherAttendanceTaken}
        />
        <TodaysStat
          leaveCount={leaveCount}
          staffCount={staffCount}
          staffOnLeave={upto3staffOnLeave}
          divisionsData={divisionsData}
          isTeacherAttendanceTaken={isTeacherAttendanceTaken}
        />
        <SchoolStat />
      </main>
    </div>
  );
}

export default Dashboard;







