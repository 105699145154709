import { gql } from "@apollo/client";

export const GET_ACADEMIC_YEAR = gql`
  query getAcademicDetails($schoolId: Int!) {
    schools_by_pk(id: $schoolId) {
      acadamic_years(
        where: {
          is_current: { _eq: true }
          school_id: { _eq: $schoolId }
          is_active: { _eq: true }
        }
      ) {
        id
        title
        from_date
        to_date
      }
    }
  }
`;

export const GET_CLASSES_FOR_IMPORT = gql`
  query GetClassesForImportExamStructure($schoolId: Int!) {
    classes(
      where: {
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
        acadamic_year: { is_current: { _eq: true }, is_active: { _eq: true } }
      }
    ) {
      id
      class_name
      assessments_aggregate(
        where: {
          is_active: { _eq: true }
          type: { _eq: "CUSTOM" }
          created_by_role: { _neq: "TEACHER" }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_UPDATED_DISPLAY_EXAM_STRUCTURE = gql`
  query displayAssessmentStructure($classId: Int!, $examType: String!) {
    assessments(
      where: {
        is_active: { _eq: true }
        class_id: { _eq: $classId }
        type: { _eq: $examType }
        assessment_subjects: { marking_type: { _neq: "DESCRIPTIVE" } }
        created_by_role: { _neq: "TEACHER" }
      }
      order_by: [
        { relative_order: asc }
        { term: asc }
        { created_at: asc }
        { id: asc }
      ]
    ) {
      id
      title
      term
      division_id
      translations
      start_at
      end_at
      relative_order
      assessment_subjects(order_by: { subject: { relative_order: asc } }) {
        id
        division_id
        subject_id
        total_marks
        marking_type
        start_time
        subject {
          id
          title
        }
        assessment_subject_eval_params(
          where: { is_active: { _eq: true } }
          order_by: [{ eval_param: { id: asc } }, { relative_order: asc }]
        ) {
          id
          eval_param_id
          assessment_subject_id
          max_marks
          updated_param_name
          eval_param {
            title
          }
        }
      }
    }
  }
`;

export const GET_DISPLAY_EXAM_STRUCTURE = gql`
  query displayAssessmentStructure($classId: Int!) {
    assessments(
      where: { is_active: { _eq: true }, class_id: { _eq: $classId } }
      order_by: [{ relative_order: asc }, { created_at: asc }, { id: asc }]
    ) {
      id
      title
      term
      start_at
      end_at
      assessment_subjects(distinct_on: subject_id) {
        id
        total_marks
        start_time
        subject {
          id
          title
        }
      }
    }
  }
`;

export const GET_EVALUATION_PATTERN = gql`
  query getDivisions($_classId: Int!) {
    divisions(
      where: { class_id: { _eq: $_classId }, is_active: { _eq: true } }
    ) {
      id
      division_name
      exam_type
    }
  }
`;

export const GET_EXAM_SUBJECTS = gql`
  query getSubAndDiv($classId: Int!) {
    classes(where: { id: { _eq: $classId } }) {
      subjects(
        where: { is_active: { _eq: true }, is_enable: { _eq: true } }
        order_by: { relative_order: asc }
      ) {
        id
        title
        translations
        class {
          school {
            medium
          }
        }
      }
      divisions(where: { is_active: { _eq: true } }) {
        id
      }
    }
  }
`;

export const GET_EXAM_STRUCTURE = gql`
  query getAssessmentStructure($classId: Int!, $examType: String = "CUSTOM") {
    assessments(
      where: {
        is_active: { _eq: true }
        class_id: { _eq: $classId }
        type: { _eq: $examType }
        assessment_subjects: { marking_type: { _neq: "DESCRIPTIVE" } }
        created_by_role: { _neq: "TEACHER" }
      }
      order_by: [
        { title: asc }
        { term: asc }
        { created_at: asc }
        { id: asc }
      ]
      distinct_on: title
    ) {
      title
      term
      start_at
      end_at
      assessment_subjects(
        order_by: {
          subject: { is_core: desc }
          assessment_subject_eval_params_aggregate: { count: desc }
        }
        limit: 1
      ) {
        total_marks
        marking_type
        assessment_subject_eval_params(
          where: { is_active: { _eq: true } }
          order_by: { eval_param_id: asc }
        ) {
          eval_param_id
          assessment_subject_id
          max_marks
          updated_param_name
          eval_param {
            id
            title
          }
        }
      }
    }
  }
`;
