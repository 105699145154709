import React, { useState } from "react";
import { Pagination } from "antd";
import _ from "lodash";
import { getAvatar } from "../../shared/getAvatar";

function AttendanceTable({
  divisionStudents,
  onDetailsClick,
  fetchMoreStudents,
  totalStudents,
  pageLimit,
  month,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page, pageSize) => {
    fetchMoreStudents({
      variables: {
        offset: (page - 1) * pageSize,
      },
    });
    setCurrentPage(page);
  };

  const renderMothersName = (studentParents) => {
    let mother = studentParents.filter((x) => x.relation === "Mother");
    if (mother.length > 0) {
      return `${mother[0].user?.full_name}`;
    }
  };

  const renderAttendanceThisMonth = (student) => {
    const attendanceThisMonth = student?.present?.aggregate?.count;
    const totalAttendanceThisMonth = student?.total?.aggregate?.count;
    if (
      attendanceThisMonth &&
      totalAttendanceThisMonth &&
      !isNaN(attendanceThisMonth) &&
      !isNaN(totalAttendanceThisMonth)
    ) {
      const percentage = (attendanceThisMonth / totalAttendanceThisMonth) * 100;
      return `${percentage.toFixed(2)}%`;
    }
    return "NA";
  };

  //TODO: merge and cache paginated data, then display set of students based on pagination
  const displayStudents = (students) => {
    if (students?.length > 0) {
      return students;
    }
    return [];
  };

  return (
    <div>
      <div className="container">
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Class
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Mother's Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    >
                      Attendance ({month})
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {displayStudents(divisionStudents)?.map((student, index) => {
                    const rowId = "attendancereport-student-" + index;
                    const detailsId = "attendancereport-details-" + index;

                    return (
                      <tr id={rowId} key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center text-left">
                            <div className="flex-shrink-0">
                              {getAvatar(
                                student?.first_name,
                                student?.last_name,
                                student?.image
                              )}
                            </div>
                            <div className="ml-3">
                              <p className="text-black font-medium whitespace-no-wrap">
                                {student?.first_name} {student?.middle_name}{" "}
                                {student?.last_name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-gray-500">
                            {_.isNil(student?.division?.division_name)
                              ? "—"
                              : `${student?.division?.division_name}`}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          <p className="text-black whitespace-no-wrap">
                            {student?.student_parents?.length > 0
                              ? renderMothersName(student?.student_parents)
                              : ``}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                          {renderAttendanceThisMonth(student)}
                        </td>
                        <td
                          id={detailsId}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left"
                        >
                          <span
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              onDetailsClick(student);
                            }}
                          >
                            Details
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              size="small"
              pageSize={pageLimit}
              current={currentPage}
              showSizeChanger={false}
              total={totalStudents}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceTable;
