import jwtDecode from "jwt-decode";
import { getUserRole } from "./getUserRole";
import { USER_ROLES } from "../utils/constants";

export const getSchoolID = () => {
  const userRoles = getUserRole();
  // check if super admin is logged in and return school id from local storage
  if (
    userRoles?.includes(USER_ROLES.ADMIN) ||
    userRoles?.includes(USER_ROLES.REGIONAL_ADMIN)
  ) {
    const schoolId = parseInt(localStorage.getItem("schoolId"));
    return Number.isNaN(schoolId) ? null : schoolId;
  }
  // school id is in token for regular school admins
  let token = localStorage.getItem("token");
  let decoded = {};
  if (token) {
    decoded = jwtDecode(token);
  }
  let checkClaims = decoded.hasOwnProperty("https://hasura.io/jwt/claims");
  if (checkClaims) {
    let claims = decoded["https://hasura.io/jwt/claims"];
    let schoolId = parseInt(claims["X-Hasura-School-Id"]);
    return Number.isNaN(schoolId) ? null : schoolId;;
  }
  return null;
};
