import React, { useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { ChevronDownIcon } from "@heroicons/react/outline";
import {
  Menu as AntDMenu,
  Dropdown,
  DatePicker,
  Drawer,
  Spin,
  notification,
} from "antd";
import { getSchoolID } from "../shared/getSchoolID";
import CloseIcon from "../components/closeIcon";
import AttendanceTable from "../views/reportsView/attendanceTable";
import { DownloadIcon } from "@heroicons/react/outline";
import { GET_CLASSES_AND_DIVISIONS } from "../views/studentsView/graphql";
import {
  GET_STUDENT_ATTENDANCE,
  GET_ATTENDANCE_DETAILS,
} from "../views/reportsView/graphql";
import { GET_CURRENT_ACADEMIC_YEAR } from "../graphql/queries";
import { getSortedClasses } from "../helpers";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { DATE_FORMATS } from "../utils/constants";
import ClassDivisionSelector from "../components/ClassDivisionSelector";
import useDownloadAttendanceReport from "../hooks/useDownloadAttendanceReport";
import useDownloadAttendanceCatalog from "../hooks/useDownloadAttendanceCatalog";
import useDownloadAttendanceXLSX from "../hooks/useDownloadAttendanceXLSX";
const { MonthPicker } = DatePicker;

function Reports() {
  const ATTENDANCE_PDF_REPORT = 0;
  const ATTENDANCE_XL_REPORT = 1;
  const ATTENDANCE_CATELOG = 2;
  const ATTENDANCE_CATELOG_ALL_MONTH = 3;

  const downloadOptions = [
    {
      value: ATTENDANCE_PDF_REPORT,
      label: "Download Report (PDF)",
    },
    {
      value: ATTENDANCE_XL_REPORT,
      label: "Download Report (Excel)",
    },
    {
      value: ATTENDANCE_CATELOG,
      label: "Attendance Register (Selected Month)",
    },
    {
      value: ATTENDANCE_CATELOG_ALL_MONTH,
      label: "Attendance Register (All Months)",
    },
  ];

  const [classData, setClassData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDivisionName, setSelectedDivisionName] = useState(null);
  const [selectedClassName, setSelectedClassName] = useState(null);
  const [divisionList, setDivisionList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: downloadAttendanceCatalog } = useDownloadAttendanceCatalog({
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const { mutate: downloadAttendanceReport } = useDownloadAttendanceReport({
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const { mutate: downloadAttendanceXLSX } = useDownloadAttendanceXLSX({
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const { data: currentYear, loading: currentYearLoading } = useQuery(
    GET_CURRENT_ACADEMIC_YEAR,
    {
      variables: { schoolId: getSchoolID() },
    }
  );
  const PAGE_LIMIT = 7;
  let navigate = useNavigate();
  const getFilters = () => {
    if (selectedClass !== null && selectedDivision === null) {
      return {
        is_draft: { _eq: false },
        is_active: {
          _eq: true,
        },
        division: {
          class_id: { _eq: selectedClass },
          school_id: { _eq: getSchoolID() },
        },
      };
    } else if (selectedClass !== null && selectedDivision !== null) {
      return {
        is_draft: { _eq: false },
        is_active: {
          _eq: true,
        },
        division_id: { _eq: selectedDivision },
        division: {
          school_id: { _eq: getSchoolID() },
        },
      };
    } else {
      return {
        is_draft: { _eq: false },
        is_active: {
          _eq: true,
        },
        division: {
          school_id: { _eq: getSchoolID() },
        },
      };
    }
  };

  const {
    loading: studentsLoading,
    data: studentData,
    fetchMore: fetchMoreStudents,
  } = useQuery(GET_STUDENT_ATTENDANCE, {
    variables: {
      filters: getFilters(),
      startDate: selectedMonth.startOf("month").format("YYYY-MM-DD"),
      endDate: selectedMonth.endOf("month").format("YYYY-MM-DD"),
      limit: PAGE_LIMIT,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    getAttendanceDetails,
    { loading: attendanceDetailsLoading, data: attendanceDetails },
  ] = useLazyQuery(GET_ATTENDANCE_DETAILS);

  useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (classResponse) => {
      const classLists = [];
      const divList = [];
      const sortedClasses = getSortedClasses(classResponse.classes);
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
        classObj.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: classObj.id,
          })
        );
      });
      setClassData(classLists);
      setDivisionData(divList);
    },
  });

  const handleClassChange = (value) => {
    let filterDivsWithClassID = divisionData.filter(
      (div) => div.class_id === parseInt(value)
    );
    setSelectedClass(value);
    setSelectedDivision(null);
    setSelectedDivisionName(null);
    setDivisionList(filterDivsWithClassID);
    const className = classData.find((x) => x.value === parseInt(value))?.label;
    setSelectedClassName(className);
  };

  const handleDivisionChange = (value) => {
    setSelectedDivision(value);
    const divisionLabel = divisionList.find(
      (div) => div.value === parseInt(value)
    ).label;
    setSelectedDivisionName(divisionLabel);
  };

  const handleDetailsClick = (student) => {
    getAttendanceDetails({
      variables: {
        studentId: student.id,
        startDate: selectedMonth.startOf("month").format("YYYY-MM-DD"),
        endDate: selectedMonth.endOf("month").format("YYYY-MM-DD"),
        divisionId: parseInt(student?.division?.id),
      },
    });
    setIsDetailsDrawerOpen(true);
  };

  const downloadReport = (downloadType, allMonths = false) => {
    // set loader
    let divisionIds = [];
    let fileName = `School_Attendance_${selectedMonth.format("MMMM")}`;
    if (!selectedClass && !selectedDivision) {
      // all divisions of all classes
      divisionIds = divisionData?.map((div) => div.value);
    } else if (selectedClass && !selectedDivision) {
      // all divisions of selected class
      divisionIds = divisionList?.map((div) => div.value);
      fileName = `${selectedClassName}_Attendance_${selectedMonth.format(
        "MMMM"
      )}`;
    } else if (selectedClass && selectedDivision) {
      // selected division of selected class
      divisionIds = [parseInt(selectedDivision)];
      fileName = `${selectedDivisionName}_Attendance_${selectedMonth.format(
        "MMMM"
      )}`;
    }
    // sort division ids
    let docFormat = "pdf";
    divisionIds = divisionIds.sort((a, b) => a - b);
    let params = {};
    if (
      downloadType === ATTENDANCE_PDF_REPORT ||
      downloadType === ATTENDANCE_XL_REPORT
    ) {
      if (downloadType === ATTENDANCE_XL_REPORT) {
        docFormat = "xlsx";
      }
      params = {
        ...params,
        divisions: divisionIds,
        fromDate: selectedMonth.startOf("month").format(DATE_FORMATS.YYYYMMDD),
        toDate: selectedMonth.endOf("month").format(DATE_FORMATS.YYYYMMDD),
      };
    } else {
      const allMonths = ATTENDANCE_CATELOG_ALL_MONTH === downloadType;
      if (divisionIds.length !== 1) {
        notification["info"]({
          message: "Please select division",
          description: "To download attendance catelog division is required",
          duration: 1.5,
        });
        return;
      }
      params = {
        ...params,
        divisionId: divisionIds[0],
        month: allMonths ? null : selectedMonth.format(DATE_FORMATS.MMMMYYYY),
      };
    }
    params.fileName = `${fileName}.${docFormat}`;
    setIsLoading(true);
    if (downloadType === ATTENDANCE_PDF_REPORT) {
      downloadAttendanceReport(params);
    } else if (downloadType === ATTENDANCE_XL_REPORT) {
      downloadAttendanceXLSX(params);
    } else {
      downloadAttendanceCatalog(params);
    }
  };

  const downloadMenu = (
    <AntDMenu
      onClick={({ key }) => {
        downloadReport(parseInt(key));
      }}
    >
      {downloadOptions.map((div) => (
        <AntDMenu.Item key={div.value} title={div.label}>
          {div.label}
        </AntDMenu.Item>
      ))}
    </AntDMenu>
  );

  const renderAttendanceDetails = () => {
    if (attendanceDetailsLoading && currentYearLoading) {
      return <div>Loading...</div>;
    }
    const student = attendanceDetails?.students?.[0];
    const absentDates = [];
    student?.this_year_absent?.absent_dates?.forEach((absentDate) => {
      // create absent days for each month
      const month = moment(absentDate.date).format("MMM");
      const date = moment(absentDate.date).format("DD MMM YYYY");
      const day = moment(absentDate.date).format("dddd");
      const absentDay = {
        date: date,
        day: day,
      };
      const absentMonth = absentDates.find((x) => x.month === month);
      if (absentMonth) {
        absentMonth.days.push(absentDay);
      } else {
        absentDates.push({
          month: month,
          days: [absentDay],
        });
      }
    });
    const studentAttendanceDetails = {
      name: `${student?.first_name} ${student?.last_name || ""}`,
      divisionName: student?.division?.division_name,
      thisMonth:
        student?.total_month_days?.aggregate?.count > 0
          ? (
              (student?.this_month_present?.aggregate?.count /
                student?.total_month_days.aggregate.count) *
              100
            ).toFixed(2)
          : 0,
      overall:
        student?.total_year_days?.aggregate?.count > 0
          ? (
              ((student?.total_year_days.aggregate.count -
                student?.this_year_absent?.aggregate?.count) /
                student?.total_year_days.aggregate.count) *
              100
            ).toFixed(2)
          : 0,
      lastAbsent: student?.last_absent?.[0]?.date,
      absentDates: absentDates,
    };
    return (
      <div className="h-full">
        <div className="pl-6 pt-6 pr-6">
          <p className="text-gray-900 text-xl font-bold">
            {studentAttendanceDetails.name}
          </p>
          <p className="text-gray-500">{`Class ${studentAttendanceDetails.divisionName}`}</p>
          <div className="flex justify-between mt-4 text-center bg-white rounded-xl pt-4 pr-5 pl-5 pb-5">
            <div className="flex flex-col gap-y-3">
              <p className="text-gray-500 text-sm font-normal">
                {selectedMonth.format(DATE_FORMATS.MMMM)}
              </p>
              <p className="text-black text-lg font-semibold">{`${studentAttendanceDetails.thisMonth}%`}</p>
            </div>
            <div className="flex flex-col gap-y-3">
              <p className="text-gray-500 text-sm font-normal">Overall</p>
              <p className="text-black text-lg font-semibold">
                {`${studentAttendanceDetails.overall}%`}
              </p>
            </div>
            <div className="flex flex-col gap-y-3">
              <p className="text-gray-500 text-sm font-normal">Last Absent</p>
              <p className="text-black text-lg font-semibold">
                {moment(studentAttendanceDetails?.lastAbsent).format(
                  "DD MMM YYYY"
                )}
              </p>
            </div>
          </div>
          <div className="mt-7 mb-4 text-gray-900 font-semibold">
            <p>
              Absent Days for{" "}
              {currentYear?.schools_by_pk?.acadamic_years?.[0]?.title}
            </p>
          </div>
        </div>
        <div className="bg-white rounded-3xl h-full pl-8 pt-5">
          {studentAttendanceDetails.absentDates.map((absentDate) => (
            <div className="flex flex-col gap-y-3 mb-8">
              <div className="w-24 inline-block text-center bg-red-100 rounded-md px-5 py-1 ">
                <p className="text-red-700 text-sm font-normal">
                  {absentDate.month}
                </p>
              </div>
              <div className="flex flex-col gap-y-3">
                {absentDate.days.map((absentDay) => (
                  <div className="flex gap-x-9">
                    <p className="text-gray-900 text-sm font-normal">
                      {absentDay.date}
                    </p>
                    <p className="text-gray-500">{absentDay.day}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={studentsLoading || isLoading}>
        <div className="flex flex-col px-8 py-3 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
          <div className="flex flex-row gap-x-4 items-center mb-2 justify-between mr-7">
            <p className="text-2xl text-left font-bold flex-col m-0">
              Student Attendance Report
            </p>
            <Button
              id="attendancereport-back"
              buttonStyle="secondary"
              onClick={() => navigate(-1)}
            >
              Back to Reports
            </Button>
          </div>
          <div className="relative mr-7 ">
            {!selectedClass && !selectedDivision && (
              <p className="text-left text-red-500 font-semibold">
                Please select class and division.
              </p>
            )}
            <div className="flex justify-between mt-4">
              <div className="flex gap-x-3">
                <ClassDivisionSelector
                  id="attendanceReport-sortBy"
                  classes={classData}
                  divisions={divisionData}
                  onClassChange={handleClassChange}
                  onDivisionChange={handleDivisionChange}
                />
              </div>
              <div className="flex h-10">
                <MonthPicker
                  id="attendacereport-month"
                  value={selectedMonth || moment()}
                  onChange={(date) => {
                    setSelectedMonth(date);
                  }}
                  format="MMMM YYYY"
                  allowClear={false}
                  style={{
                    borderRadius: "6px",
                  }}
                />
                <Dropdown overlay={downloadMenu}>
                  <button
                    id="attendancereport-download"
                    className="py-3 px-4 ml-4 flex justify-center items-center whitespace-nowrap bg-success  focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-197 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ant-dropdown-trigger"
                  >
                    <DownloadIcon className="h-6 w-5 mr-2" />
                    Download
                    <ChevronDownIcon
                      className="h-4 w-5 ml-4"
                      aria-hidden="true"
                    />
                  </button>
                </Dropdown>
              </div>
            </div>
            <AttendanceTable
              divisionStudents={studentData?.students}
              onDetailsClick={handleDetailsClick}
              fetchMoreStudents={fetchMoreStudents}
              pageLimit={PAGE_LIMIT}
              totalStudents={studentData?.students_aggregate?.aggregate?.count}
              month={moment(selectedMonth).format(DATE_FORMATS.MMMM)}
            />
          </div>
        </div>
        <Drawer
          title={
            <div className="text-lg leading-7 font-medium">
              Attendance Details
            </div>
          }
          placement="right"
          onClose={() => {
            setIsDetailsDrawerOpen(false);
          }}
          visible={isDetailsDrawerOpen}
          destroyOnClose
          contentWrapperStyle={{
            width: "35%",
          }}
          headerStyle={{
            height: "76px",
            backgroundColor: "#F3F4F6",
          }}
          bodyStyle={{
            padding: 0,
            backgroundColor: "#F3F4F6",
          }}
          closeIcon={<CloseIcon />}
        >
          {renderAttendanceDetails()}
        </Drawer>
      </Spin>
    </div>
  );
}

export default Reports;
