import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Spin, Pagination } from "antd";
import { GET_CLASSES_AND_DIVISIONS } from "../../views/studentsView/graphql";
import moment from "moment";
import _ from "lodash";
import { getAvatar } from "../../shared/getAvatar";
import { getSchoolID } from "../../shared/getSchoolID";
import { DATE_FORMATS } from "../../utils/constants";
import { debounce } from "lodash";
import { ClassDivisionSelector, Dropdown } from "../../components";

const ManageStudentList = ({
  enableEditMode,
  setdraweropen,
  studentsData,
  filterData,
  setFilterData,
  loading,
}) => {
  const [classData, setclassdata] = useState([]);
  const [divisionData, setdivisiondata] = useState([]);
  const {
    selectedClass,
    selectedDivision,
    search,
    sortTypeLabel,
    currentPage,
    pageSize,
    studentCount,
  } = filterData;

  const setSortType = (sortType) => {
    setFilterData((sortingDetail) => {
      return { ...sortingDetail, sortType };
    });
  };

  const setSortTypeLabel = (sortTypeLabel) => {
    setFilterData((sortingDetail) => {
      return { ...sortingDetail, sortTypeLabel };
    });
  };

  const setSelectedClass = (classValue) => {
    setFilterData((filterData) => {
      return { ...filterData, selectedClass: classValue, currentPage: 1 };
    });
  };

  const setSelectedDivision = (divisionValue) => {
    setFilterData((filterData) => {
      return { ...filterData, selectedDivision: divisionValue, currentPage: 1 };
    });
  };

  const setsearch = (searchValue) => {
    setFilterData((filterData) => {
      return { ...filterData, search: searchValue, currentPage: 1 };
    });
  };

  const sortOptions = [
    {
      label: "Sort: A to Z",
      value: "atoz",
    },
    {
      label: "Sort: Z to A",
      value: "ztoa",
    },
  ];

  const { loading: clLoading, error: clError } = useQuery(
    GET_CLASSES_AND_DIVISIONS,
    {
      variables: {
        schoolId: getSchoolID(),
      },
      onCompleted: (classd) => {
        let classLists = [];
        let divList = [];
        classd?.classes.map((xx) => {
          classLists.push({
            value: xx.id,
            label: `Class: ${xx.class_name}`,
          });
          xx.divisions.map((divz) =>
            divList.push({
              value: divz.id,
              label: `Division: ${divz.division_name}`,
              class_id: xx.id,
            })
          );
        });
        setclassdata(classLists);
        setdivisiondata(divList);
      },
    }
  );

  useEffect(() => {
    setFilterData((filterData) => {
      return { ...filterData, selectedClass, selectedDivision };
    });
  }, [selectedClass, selectedDivision]);

  const parentRenderer = (parent) => {
    let parentrelationnull = _.isNil(parent.relation);
    if (parent && !parentrelationnull) {
      return `${parent.user?.full_name} (${parent.relation})`;
    }
    if (parent && parentrelationnull) {
      return `${parent.user?.full_name}`;
    }
  };

  if (clError) return `Error! ${clError.message}`;

  const handlePageChange = (page) => {
    setFilterData((filterData) => {
      return { ...filterData, currentPage: page };
    });
  };

  return (
    <Spin spinning={loading}>
      <div>
        <div className="container">
          <div className="py-8">
            <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full items-center">
              <div className="">
                <form
                  className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete="off"
                >
                  <div id="managestudents-search" className=" relative ">
                    <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                          fill="#9CA3AF"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      id='"form-subscribe-Filter'
                      className="pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      placeholder="Search by student name"
                      value={search}
                      onChange={(e) => {
                        e.preventDefault();
                        setsearch(e.target.value);
                        filterData?.debounceData?.setSkipExecution(true);
                        filterData?.debounceData?.debouncer();
                      }}
                    />
                  </div>
                </form>
              </div>
              <div id="managestudents-sort" className="flex gap-x-2">
                <Dropdown
                  id="managestudents-sorta-z"
                  value={sortTypeLabel}
                  defaultValue="Sort: All"
                  type="primary"
                  options={sortOptions}
                  onItemClick={(value) => {
                    setSortType(value);
                    const sortLabel = sortOptions.find(
                      (option) => option.value === value
                    );
                    setSortTypeLabel(sortLabel.label);
                  }}
                />
                <ClassDivisionSelector
                  id={"managestudents-sortby"}
                  classes={classData}
                  divisions={divisionData}
                  onClassChange={setSelectedClass}
                  onDivisionChange={setSelectedDivision}
                  allOptionNeeded
                  selectedClass={selectedClass}
                  selectedDivision={selectedDivision}
                />
              </div>
            </div>

            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block shadow min-w-full rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Mother's Name
                      </th>
                      <th
                        scope="col"
                        className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Parent Contact
                      </th>
                      <th
                        scope="col"
                        className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Last Updated
                      </th>
                      <th
                        scope="col"
                        className="w-32 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500 text-right text-sm uppercase font-normal"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentsData?.map((student, index) => {
                      const rowId = "managestudents-student-" + index;
                      const detailsId =
                        "managestudents-studentdetails-" + index;

                      return (
                        <tr key={index} id={rowId}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center text-left">
                              <div className="flex-shrink-0">
                                {getAvatar(
                                  student?.first_name,
                                  student?.last_name,
                                  student?.image
                                )}
                              </div>
                              <div className="ml-3">
                                <p className="text-black font-medium whitespace-no-wrap capitalize">
                                  {student?.first_name} {student?.middle_name}{" "}
                                  {student?.last_name}
                                </p>
                                <p className="text-gray-500 ">
                                  Class {student?.division?.division_name}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black font-medium whitespace-no-wrap capitalize">
                              {student?.mother_name}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap capitalize">
                              {student?.student_parents?.length > 0
                                ? parentRenderer(student?.student_parents?.[0])
                                : `Name (Relation)`}
                            </p>
                            {student?.student_parents?.length > 0 ? (
                              <p className="text-gray-500 ">
                                {
                                  student?.student_parents[0]?.user
                                    ?.mobile_number.slice(3)
                                }
                              </p>
                            ) : (
                              <p className="text-red-500">
                                {`Phone number not available`}
                              </p>
                            )}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {student?.audit_trails[0]?.userByAddedBy
                                ?.first_name || "John"}
                            </p>
                            <p className="text-gray-500">
                              {student?.audit_trails.length > 0
                                ? moment(
                                    student?.audit_trails[0]?.updated_at
                                  ).format(DATE_FORMATS.DDMMMYYYY)
                                : moment(student?.created_at).format(
                                    DATE_FORMATS.DDMMMYYYY
                                  )}
                            </p>
                          </td>
                          <td
                            id={detailsId}
                            className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right"
                          >
                            <span
                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                setdraweropen(student?.id, true);
                              }}
                            >
                              Details
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                size="small"
                pageSize={pageSize}
                total={studentCount}
                current={currentPage}
                showSizeChanger={false}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default ManageStudentList;
