import React from "react";
import moment from "moment";
import { Tag, Spin } from "antd";
import _ from "lodash";
import { DownloadIcon } from "@heroicons/react/outline";
import { Button } from "../../../components";
import useStudentFeeData from "../../../hooks/useStudentFeeData";
import { TextViewForLabelAndValue } from "../../../components/TextView";
import { getS3Image } from "../../../shared/s3";
import useDownloadFeeReceipt from "../../../hooks/useDownloadFeeReceipt";
import { useNavigate } from "react-router-dom";
const getAvatarForDetailedView = (first_name, last_name, image, size) => {
	let lastName = _.isNil(last_name) ? "" : last_name;
	let hasNoImage = _.isNil(image);
	if (hasNoImage) {
		return (
			<div
				shape="square"
				className="flex items-center justify-center"
				style={{
					backgroundColor: "#4F46E5",
					width: 137,
					height: 96,
					borderRadius: 13,
					fontSize: 56,
					color: "white",
				}}
			>
				{first_name?.charAt(0)}
				{lastName?.charAt(0)}
			</div>
		);
	}
	return (
		<img
			src={getS3Image(image)}
			alt="Student Profile"
			className="max-h-24 p-0 h-full m-0"
			style={{
				maxWidth: "30%",
				borderRadius: 13,
			}}
		/>
	);
};

export default function StudentDetail({ studentId }) {
	const navigate = useNavigate();
	const { loading, data: studentData } = useStudentFeeData({
		studentId,
	});

	const [isDownloading, setDownloading] = React.useState(false);

	const { mutateAsync: downloadFeeReceipt } = useDownloadFeeReceipt({
		onSettled: () => {
			setDownloading(false);
		},
	});

	const handleDownloadFeeReceipt = async ({ id, studentName }) => {
		setDownloading(true);
		await downloadFeeReceipt({
			feeCollectionId: id,
			fileName: `Fee_${studentName?.replace(/ /g, "_")}`,
		});
	};

	const [transactions, status] = React.useMemo(() => {
		let statusData = {
			text: "",
			textColor: "",
			bgColor: "",
		};
		if (studentData?.fee_status === "fully_paid") {
			statusData.text = "Fully Paid";
			statusData.textColor = "text-green-800";
			statusData.bgColor = "#D1FAE5";
		} else if (studentData?.fee_status === "partial_paid") {
			statusData.text = "Paid Partially";
			statusData.textColor = "text-barberryBush";
			statusData.bgColor = "#FFF2D8";
		} else {
			statusData.text = "Not Paid";
			statusData.textColor = "text-red-800";
			statusData.bgColor = "#FFF3F1";
		}
		if (studentData?.transactions?.length) {
			const list = [...studentData?.transactions];
			list.sort((a, b) => b.date - a.date);
			const final = list.map((l) => ({
				id: l.id,
				date: moment(l.date).format("DD MMM YYYY, hh:mm a"),
				amount: "₹ " + l?.amount?.toLocaleString(),
			}));
			return [final, statusData];
		}
		return [[], statusData];
	}, [studentData?.transactions, studentData?.fee_status]);

	if (loading) return "Loading...";

	return (
		<div className="h-full">
			<Spin spinning={isDownloading}>
				<div className="mt-4 flex mx-6">
					{getAvatarForDetailedView(
						studentData?.first_name,
						studentData?.last_name,
						studentData?.image,
					)}

					<div className="flex flex-1 flex-col items-start ml-6 mb-4">
						<p className="mb-1 text-gray-700 text-xl font-bold uppercase truncate" style={{ width: 300 }}>
							{studentData?.student_full_name}
						</p>
						<p className="mb-1 text-gray-500 text-sm font-normal capitalize">
							Class {studentData?.division_name}
							{studentData?.roll_no
								? ` | Roll No. ${studentData?.roll_no}`
								: ""}
							<Tag
								color={status.bgColor}
								style={{
									borderRadius: 20,
									padding: "2px 10px",
									marginLeft: 10,
									fontWeight: 500,
								}}
							>
								<span className={status.textColor}>
									{status.text}
								</span>
							</Tag>
						</p>
						<div className="flex mt-2">
							<button
								id="managestudents-details-edit"
								className="p-2 mr-2 rounded-md bg-indigo-700 hover:bg-indigo-500 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-xs leading-4 font-medium shadow-sm  inline-flex items-center "
								onClick={() =>
									navigate(
										`/fees-collection/new-collection`,
										{
											state: {
												studentData: {
													id: studentData?.id,
													name: studentData?.student_full_name,
												},
											},
										},
									)
								}
							>
								<span className="flex justify-center items-center gap-x-1 whitespace-nowrap">
									Collect Fee
								</span>
							</button>
						</div>
					</div>
				</div>
				<div className="mx-6 border-t border-slate-800 borders-solid mt-5">
					<p className="mt-2 pb-2 border-b border-slate-800 borders-solid text-md leading-5 font-semibold text-gray-900">
						Annual Fees: {studentData?.academic_year}
					</p>
					<TextViewForLabelAndValue
						title="Total Fee"
						description={
							"₹ " +
							(studentData?.total_amount
								? studentData?.total_amount?.toLocaleString()
								: 0)
						}
					/>
					<TextViewForLabelAndValue
						title="Paid Fee"
						description={
							"₹ " +
							(studentData?.amount_paid
								? studentData?.amount_paid?.toLocaleString()
								: 0)
						}
						descStyle={"text-green-800"}
					/>
					<TextViewForLabelAndValue
						title="Remaining Fee"
						description={
							"₹ " +
							(studentData?.remaining_amount
								? studentData?.remaining_amount?.toLocaleString()
								: 0)
						}
						descStyle={"text-red-800"}
					/>
				</div>

				<div className="mx-6 border-t border-slate-800 borders-solid mt-5">
					<p className="mt-2 pb-2 border-b border-slate-800 borders-solid text-md leading-5 font-semibold text-gray-900">
						Transactions
					</p>

					{transactions.map((data, stpIndex) => {
						if (!data?.id) {
							return null;
						}
						return (
							<div
								key={data.id}
								className="flex flex-1 justify-between items-center my-3"
							>
								<div className="flex flex-col">
									<p className="text-sm leading-5 font-semibold text-gray-900 mb-1">
										{data.amount}
									</p>
									<p className="text-sm leading-5 text-gray-500">
										{data.date}
									</p>
								</div>

								<div className="flex flex-1 justify-end">
									<Button
										className="h-10 mr-2 bg-successGreen"
										onClick={() => {
											handleDownloadFeeReceipt({
												id: data.id,
												studentName:
													studentData.student_full_name,
											});
										}}
									>
										<DownloadIcon className="text-white h-4 w-4" />
										<div className="text-white ml-1">
											Download
										</div>
									</Button>
								</div>
							</div>
						);
					})}
				</div>
			</Spin>
		</div>
	);
}