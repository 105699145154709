import React, { useState } from "react";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_SCHOOLS } from "../views/adminSettingsView/graphql";
import appLogo from "../images/Logo2x.png";
import { getUserRole } from "../shared/getUserRole";
import { USER_ROLES } from "../utils/constants";

const { Option } = Select;

function AdminSettings() {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const navigate = useNavigate();

  const { loading, error, data: schoolsData } = useQuery(GET_ALL_SCHOOLS);

  const handleEditClick = () => {
    localStorage.setItem("schoolId", selectedSchool);
    window.open("/", "_blank").focus();
  };

  const handleDoYouKnowClick = () => {
    navigate("/do-you-know");
  };

  const isUserSuperAdmin = getUserRole()?.includes(USER_ROLES.ADMIN);

  if (loading) return <p>Loading...</p>;

  return (
    <main className="bg-gray-100 dark:bg-gray-800 rounded-2xl h-screen overflow-hidden relative grid">
      <div className="flex items-center mt-20 flex-col gap-y-2">
        {isUserSuperAdmin ? (
          <div className="absolute top-5 right-10">
            <button
              type="button"
              onClick={handleDoYouKnowClick}
              style={{
                minWidth: "8rem",
              }}
              class="py-3 px-4 flex justify-center bg-white items-center border border-gray-300 hover:text-white hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
            >
              Manage Post
            </button>
          </div>
        ) : null}
        <div className="mt-0 mb-4">
          <img alt="book" src={appLogo} className="h-40 w-70" />
        </div>
        <div className="flex mb-5 gap-x-10">
          <button
            type="button"
            onClick={() => {
              window.open("/createSchool", "_blank").focus();
            }}
            style={{
              minWidth: "8rem",
            }}
            class="py-3 px-4 flex justify-center bg-white items-center border border-gray-300 hover:text-white hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
          >
            Create School
          </button>
          <button
            type="button"
            onClick={() => {
              window.open("/bulkUploadSchool", "_blank").focus();
            }}
            style={{
              minWidth: "8rem",
            }}
            class="py-3 px-4 flex justify-center bg-white items-center border border-gray-300 hover:text-white hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
          >
            Bulk Upload Schools
          </button>
        </div>
        <div className="flex gap-x-3 mb-5">
          <Select
            showSearch
            style={{ width: 500 }}
            className="customised-antd-selector-controlled"
            value={selectedSchool}
            onChange={(value) => setSelectedSchool(value)}
            size="large"
            placeholder="Select school"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {schoolsData?.schools.map((school) => (
              <Option key={school.id} value={school.id}>
                {`${school.id} - ${school.title}`}
              </Option>
            ))}
          </Select>
        </div>
        <button
          type="button"
          onClick={handleEditClick}
          style={{
            minWidth: "8rem",
          }}
          class="py-3 px-4 flex justify-center bg-white items-center border border-gray-300 hover:text-white hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
        >
          Edit School
        </button>
      </div>
    </main>
  );
}

export default AdminSettings;
