import React from "react";
import Main from "./main";
import "./App.css";
import useConnectionStatus from "check-connection";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { notification } from "antd";
import { onError } from "@apollo/client/link/error";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// modularised imports for antd
import "antd/lib/calendar/style/index.css"; //calendar
import "antd/lib/select/style/index.css"; //select
import "antd/lib/cascader/style/index.css"; //cascader
import "antd/lib/drawer/style/index.css"; //drawer
import "antd/lib/spin/style/index.css"; //spin
import "antd/lib/card/style/index.css"; //card
import "antd/lib/date-picker/style/index.css"; //date
import "antd/lib/dropdown/style/index.css"; //dropdown
import "antd/lib/tree-select/style/index.css"; //tree-select
import fireBaseApp from "./shared/firebase";
import { validateAuthId } from "./shared/getAuthID";
const queryClient = new QueryClient();

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GQL_ENDPOINT,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      console.error("graphQLErrors", graphQLErrors);
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "access-denied":
            if (fireBaseApp.auth().currentUser) {
              fireBaseApp
                .auth()
                .signOut()
                .then((res) => console.log("Logged out user."))
                .catch((errz) => console.log("Logout Error => ", errz));
            }
            localStorage.clear();
            window.location.href = "/";
            break;
          case "invalid-jwt":
            if (!fireBaseApp.auth().currentUser) {
              localStorage.clear();
              window.location.href = "/auth";
            }
            return new Observable((observer) => {
              fireBaseApp
                .auth()
                .currentUser.getIdToken(true)
                .then((token_) => {
                  localStorage.setItem("token", token_);
                  let oldHeaders = operation.getContext().headers;
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `Bearer ${token_}`,
                    },
                  });
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  return forward(operation).subscribe(subscriber);
                })
                .catch((error) => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                });
            });
          default:
            // console.log("default error handler");
            notification["error"]({
              message: "Something went wrong",
              description: `${graphQLErrors?.[0]?.message}`,
              duration: 3,
            });
        }
      }
    }
  },
);

// TODO: Use offsetLimitPagination in the future to paginate the data
const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          students: {
            keyArgs: false,
            merge(existing, incoming, { args: { offset, where } }) {
              // if querying with id, return existing, because incoming only has one element
              if (where?.id) {
                return existing;
              }
              // if querying with offset, return incoming
              if (offset) {
                return incoming;
              }
              return { ...existing, ...incoming };
            },
          },
          documents_issuance_history: {
            keyArgs: false,
            merge(existing, incoming, { args: { offset = 0 } }) {
              // return incoming only if offset is passed else merge existing and incoming
              return offset ? incoming : { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
  link: errorLink.concat(concat(authMiddleware, httpLink)),
});
function App(props) {
  React.useEffect(() => {
    fireBaseApp.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        localStorage.setItem("token", token);
        user.getIdTokenResult().then((res) => {
          validateAuthId(res?.claims?.phone_number);
        });
      }
    });
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ConnectionStatus {...props}>
        <ApolloProvider client={client}>
          <Main />
        </ApolloProvider>
      </ConnectionStatus>
    </QueryClientProvider>
  );
}

export default App;

function ConnectionStatus(props) {
  let connected = useConnectionStatus();
  return (
    <React.Fragment>
      <div className={`App ${!connected ? "networkDisconnected" : null}`}>
        {props.children}
      </div>
    </React.Fragment>
  );
}