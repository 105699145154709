import { gql } from "@apollo/client";

export const GET_CLASSES = gql`
  query getAllClasses($schoolId: Int!) {
    schools_by_pk(id: $schoolId) {
      classes(
        where: {
          is_active: { _eq: true }
          acadamic_year: { is_current: { _eq: true } }
        }
      ) {
        class_name
        id
      }
    }
  }
`;

export const ADD_ANNOUNCEMENT = gql`
  mutation insertAnnouncement(
    $announcementData: [announcements_insert_input!]!
  ) {
    insert_announcements(objects: $announcementData) {
      affected_rows
      returning {
        id
        like_count
        message_body
        is_active
        title
        send_to
        seen_count
        updated_at
        message_type
        announcement_receivers_aggregate(distinct_on: id) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const UPDATE_ANNOUNCEMENT_DETAILS = gql`
  mutation updateAnnouncement(
    $announcementData: announcements_set_input
    $id: Int!
  ) {
    update_announcements_by_pk(
      pk_columns: { id: $id }
      _set: $announcementData
    ) {
      id
      title
      message_body
      message_type
      like_count
      send_to
      seen_count
      is_active
      created_at
      updated_at
      announcement_receivers_aggregate(
        distinct_on: id
        where: { is_active: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const UPDATE_ANNOUNCEMENT_RECEIVERS = gql`
  mutation upsertReceiver(
    $announcementReceiversData: [announcement_receivers_insert_input!]!
  ) {
    insert_announcement_receivers(
      objects: $announcementReceiversData
      on_conflict: {
        constraint: announcement_receivers_announcement_id_class_id_key
        update_columns: is_active
      }
    ) {
      returning {
        id
        is_active
        announcement_id
        class_id
        class {
          class_name
        }
      }
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($announcementId: Int!) {
    update_announcements_by_pk(
      pk_columns: { id: $announcementId }
      _set: { is_active: false }
    ) {
      id
    }
  }
`;
