import React, { useState, useEffect } from "react";
import ClassDivisionSelector from "../../components/ClassDivisionSelector";
import { getSchoolID } from "../../shared/getSchoolID";
import { useMutation, useQuery } from "@apollo/client";
import { getSortedClasses } from "../../helpers";
import { ChevronRightIcon } from "@heroicons/react/outline";
import PromotionsTable from "./PromotionsTable";
import Button from "../../components/Button";
import starsIcon from "../../assets/images/stars.png";
import { Modal } from "antd";
import { GET_CLASSES_FOR_YEAR, GET_ACADEMIC_YEARS } from "../../shared/graphql";
import {
  GET_DIVISION_STUDENTS,
  GET_EXAMS,
  PROMOTE_STUDENTS,
  PROMOTE_FINAL_STUDENTS,
} from "./graphql";
import { InfoCircleFilled } from "@ant-design/icons";

function StudentsPromotion() {
  const [classData, setClassData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [nextYearClasses, setNextYearClasses] = useState([]);
  const [nextYearDivisions, setNextYearDivisions] = useState([]);
  const [currentSelectedClass, setCurrentSelectedClass] = useState(null);
  const [currentSelectedDivision, setCurrentSelectedDivision] = useState(null);
  const [filteredDivisions, setFilteredDivisions] = useState([]);
  const [promotedToClass, setPromotedToClass] = useState(null);
  const [promotedToDivision, setPromotedToDivision] = useState(null);
  const [isPromotionSuccessModalVisible, setIsPromotionSuccessModalVisible] =
    useState(false);
  const [isDetainModalVisible, setIsDetainModalVisible] = useState(false);
  const [nextAcademicYear, setNextAcademicYear] = useState(null);
  const [promotionFormData, setPromotionFormData] = useState({});
  const [nonPromotedStudents, setNonPromotedStudents] = useState([]);
  const [detainedClass, setDetainedClass] = useState(null);
  const [detainedDivision, setDetainedDivision] = useState();
  const [isPromotionConfirmModalOpen, setIsPromotionConfirmModalOpen] =
    useState(false);

  const hidePromotionTable =
    !currentSelectedClass ||
    !currentSelectedDivision ||
    !promotedToClass ||
    !promotedToDivision;

  useEffect(() => {
    if (currentSelectedClass && currentSelectedDivision) {
      // find next year class that matches current selected class name for detained class
      const nextYearDetainedClass = nextYearClasses.find(
        (classObj) => classObj.className === currentSelectedClass?.className
      );
      // find next year division that matches current selected division name for detained division
      const nextYearDetainedDivision = nextYearDivisions.find(
        (div) => div.divisionName === currentSelectedDivision?.divisionName
      );
      setDetainedClass(nextYearDetainedClass);
      setDetainedDivision(nextYearDetainedDivision);
    }
  }, [currentSelectedClass, currentSelectedDivision]);

  const { data: academicYearsData } = useQuery(GET_ACADEMIC_YEARS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (data) => {
      const academicYears = data?.acadamic_years;
      // sort academic years by id
      const sortedAcademicYears = academicYears?.slice().sort((a, b) => {
        return a.id - b.id;
      });
      const currentAcademicYear = sortedAcademicYears?.find((academicYear) => {
        return academicYear.is_current;
      });
      // get next academic year after current academic year
      const newAcademicYear = sortedAcademicYears?.find((academicYear) => {
        return academicYear.id > currentAcademicYear?.id;
      });
      setNextAcademicYear(newAcademicYear);
    },
  });

  const { loading, error } = useQuery(GET_CLASSES_FOR_YEAR, {
    variables: {
      schoolId: getSchoolID(),
      academicYearId: academicYearsData?.acadamic_years?.find(
        (academicYear) => academicYear.is_current === true
      )?.id,
    },
    skip: !academicYearsData,
    fetchPolicy: "network-only",
    onCompleted: (classData) => {
      const sortedClasses = getSortedClasses(classData.classes);
      let classLists = [];
      let divList = [];
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
          className: classObj.class_name,
        });
        classObj.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: classObj.id,
            isPromoted: divz.is_promoted,
            divisionName: divz.division_name,
          })
        );
      });
      setClassData(classLists);
      setDivisionData(divList);
      // update current selected division if isPromoted is changed
      const updatedDivision = divList.find(
        (div) => div.value === parseInt(currentSelectedDivision?.value)
      );
      if (updatedDivision?.isPromoted !== currentSelectedDivision?.isPromoted) {
        setCurrentSelectedDivision({
          ...currentSelectedDivision,
          isPromoted: updatedDivision?.isPromoted,
        });
      }
    },
  });

  const { loading: nextYearClassesLoading } = useQuery(GET_CLASSES_FOR_YEAR, {
    variables: {
      schoolId: getSchoolID(),
      academicYearId: nextAcademicYear?.id,
    },
    skip: !nextAcademicYear,
    fetchPolicy: "network-only",
    onCompleted: (classData) => {
      const sortedClasses = getSortedClasses(classData.classes);
      let classLists = [];
      let divList = [];
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
          className: classObj.class_name,
        });
        classObj.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: classObj.id,
            divisionName: divz.division_name,
          })
        );
      });
      classLists.push({
        value: -1,
        label: "No further class",
        className: "Promoted",
      });
      divList.push({
        value: -2,
        label: "No further division",
        class_id: -1,
        isPromoted: true,
        divisionName: "Promoted",
      });
      setNextYearClasses(classLists);
      setNextYearDivisions(divList);
    },
  });

  const { loading: examsLoading, data: examsData } = useQuery(GET_EXAMS, {
    skip: !currentSelectedDivision,
    variables: {
      classId: currentSelectedClass?.value,
    },
  });

  const { loading: studentsLoading, data: studentData } = useQuery(
    GET_DIVISION_STUDENTS,
    {
      variables: {
        divisionId: currentSelectedDivision?.value,
        assessments: examsData?.assessments.map((exam) => exam.id),
      },
      skip: !currentSelectedDivision || !examsData,
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        // update promotion form data for every student id in division with isPromoted of current selected division
        const updatedPromotionFormData = {};
        let isPromoted = true;
        res?.division_students.forEach((divisionStudent) => {
          if (divisionStudent?.student?.promotion_histories?.length > 0) {
            isPromoted =
              divisionStudent?.student?.promotion_histories[0]?.is_promoted;
          }
          updatedPromotionFormData[divisionStudent?.student.id] = {
            isPromoted,
            studentName: divisionStudent?.student.full_name,
          };
        });
        setPromotionFormData(updatedPromotionFormData);
      },
    }
  );

  const [promoteStudents] = useMutation(PROMOTE_STUDENTS, {
    refetchQueries: [
      {
        query: GET_CLASSES_FOR_YEAR,
        variables: {
          schoolId: getSchoolID(),
          academicYearId: academicYearsData?.acadamic_years?.find(
            (academicYear) => academicYear.is_current === true
          )?.id,
        },
      },
      {
        query: GET_DIVISION_STUDENTS,
        variables: {
          divisionId: currentSelectedDivision?.value,
          assessments: examsData?.assessments.map((exam) => exam.id),
        },
      },
    ],
    onCompleted: () => {
      setIsPromotionSuccessModalVisible(true);
    },
  });

  const [promoteFinalStudents] = useMutation(PROMOTE_FINAL_STUDENTS, {
    refetchQueries: [
      {
        query: GET_CLASSES_FOR_YEAR,
        variables: {
          schoolId: getSchoolID(),
          academicYearId: academicYearsData?.acadamic_years?.find(
            (academicYear) => academicYear.is_current === true
          )?.id,
        },
      },
    ],
    onCompleted: () => {
      setIsPromotionSuccessModalVisible(true);
    },
  });

  const handleCurrentClassChange = (value) => {
    const filterDivsWithClassID = divisionData.filter(
      (div) => div.class_id === parseInt(value)
    );
    const classObj = classData.find(
      (classObj) => classObj.value === parseInt(value)
    );
    setCurrentSelectedClass({
      ...classObj,
      value,
      label: classObj?.label,
    });
    setCurrentSelectedDivision(null);
    setFilteredDivisions(filterDivsWithClassID);
  };

  const handleCurrentDivisionChange = (value) => {
    const division = filteredDivisions.find(
      (div) => div.value === parseInt(value)
    );
    setCurrentSelectedDivision({
      ...division,
      value,
      label: division?.label,
      isPromoted: division?.isPromoted,
    });
  };

  const handlePromotedToClassChange = (value) => {
    const promotedToClassLabel = nextYearClasses.find(
      (classObj) => classObj.value === parseInt(value)
    )?.label;
    setPromotedToClass({
      value,
      label: promotedToClassLabel,
    });
  };

  const handlePromotedToDivisionChange = (value) => {
    const promotedToDivisionLabel = nextYearDivisions.find(
      (div) => div.value === parseInt(value)
    )?.label;
    setPromotedToDivision({
      value,
      label: promotedToDivisionLabel,
    });
  };

  const handleDetainedClassChange = (value) => {
    const filterDivsWithClassID = nextYearDivisions.filter(
      (div) => div.class_id === parseInt(value)
    );
    const className = nextYearClasses.find(
      (classObj) => classObj.value === parseInt(value)
    )?.label;
    setDetainedClass({
      value,
      label: className,
    });
    setDetainedDivision(null);
    setNextYearDivisions(filterDivsWithClassID);
  };

  const handleStudentPromotionChange = (student, isPromoted) => {
    setPromotionFormData({
      ...promotionFormData,
      [student.id]: {
        ...promotionFormData[student.id],
        studentName: student.full_name,
        isPromoted,
      },
    });
  };

  const handlePromoteStudents = () => {
    setIsPromotionConfirmModalOpen(false);
    setIsDetainModalVisible(false);
    if (parseInt(promotedToDivision?.value) === -2) {
      // final year students
      const nonPromotedStudentsIds = Object.keys(promotionFormData).filter(
        (studentId) => !promotionFormData[studentId].isPromoted
      );
      const detainedStudentsData = nonPromotedStudentsIds.map((studentId) => {
        return {
          student_id: studentId,
          division_id: detainedDivision.value,
          is_active: true,
          from_date: "now()",
        };
      });
      const detainedHistoryData = nonPromotedStudentsIds.map((studentId) => {
        return {
          student_id: studentId,
          from_division_id: currentSelectedDivision?.value,
          to_division_id: detainedDivision.value,
          is_promoted: false,
        };
      });
      promoteFinalStudents({
        variables: {
          nonPromotedStudents: nonPromotedStudentsIds,
          divisionIdNonPromoted: parseInt(detainedDivision?.value),
          divisionStudentsData: detainedStudentsData,
          currentDivisionId: parseInt(currentSelectedDivision?.value),
          promotionHistoryData: detainedHistoryData,
        },
      });
    } else {
      const promotedStudentsIds = Object.keys(promotionFormData).filter(
        (studentId) => promotionFormData[studentId].isPromoted
      );
      const nonPromotedStudentsIds = Object.keys(promotionFormData).filter(
        (studentId) => !promotionFormData[studentId].isPromoted
      );
      const promotedStudentsData = promotedStudentsIds.map((studentId) => {
        return {
          student_id: studentId,
          division_id: promotedToDivision.value,
          is_active: true,
          from_date: "now()",
        };
      });
      const promotedHistoryData = promotedStudentsIds.map((studentId) => {
        return {
          student_id: studentId,
          from_division_id: currentSelectedDivision?.value,
          to_division_id: promotedToDivision.value,
          is_promoted: true,
        };
      });
      const detainedStudentsData = nonPromotedStudentsIds.map((studentId) => {
        return {
          student_id: studentId,
          division_id: detainedDivision.value,
          is_active: true,
          from_date: "now()",
        };
      });
      const detainedHistoryData = nonPromotedStudentsIds.map((studentId) => {
        return {
          student_id: studentId,
          from_division_id: currentSelectedDivision?.value,
          to_division_id: detainedDivision.value,
          is_promoted: false,
        };
      });
      const allStudentsData = [
        ...promotedStudentsData,
        ...detainedStudentsData,
      ];
      const allHistoryData = [...promotedHistoryData, ...detainedHistoryData];
      promoteStudents({
        variables: {
          promotedStudents: promotedStudentsIds,
          nonPromotedStudents: nonPromotedStudentsIds,
          divisionIdPromoted: promotedToDivision.value,
          divisionIdNonPromoted: detainedDivision?.value,
          divisionStudentsData: allStudentsData,
          currentDivisionId: currentSelectedDivision.value,
          promotionHistoryData: allHistoryData,
        },
      });
    }
  };

  const handlePromoteStudentsSubmit = () => {
    // check if any student is not promoted
    const isNotPromoted = Object.keys(promotionFormData).find(
      (studentId) => !promotionFormData[studentId].isPromoted
    );
    if (isNotPromoted) {
      // get list of values of not promoted students
      const notPromotedStudents = Object.values(promotionFormData).filter(
        (student) => !student.isPromoted
      );
      setNonPromotedStudents(notPromotedStudents);
      setIsDetainModalVisible(true);
    } else {
      // if all students are promoted, then open confirm modal
      setIsPromotionConfirmModalOpen(true);
    }
  };

  const handleDetainedDivisionChange = (value) => {
    setDetainedDivision({
      value,
      label: divisionData.find((div) => div.value === parseInt(value))?.label,
    });
  };

  const handleCancelDetain = () => {
    setIsDetainModalVisible(false);
  };

  const handleConfirmDetain = () => {
    setIsDetainModalVisible(false);
    handlePromoteStudents();
  };

  const handleConfirmPromotionSuccess = () => {
    setIsPromotionSuccessModalVisible(false);
  };

  const handleCancelPromotion = () => {
    setCurrentSelectedDivision(null);
    setCurrentSelectedClass(null);
    setPromotedToDivision(null);
    setPromotedToClass(null);
  };

  const handleClosePromotionModal = () => {
    setIsPromotionConfirmModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-col items-start ml-8 mt-6 mr-8 mb-6 h-screen w-screen overflow-x-hidden relative">
        <h2 className="text-2xl text-left font-bold flex-col m-0">
          Promote Students
        </h2>
        <div className="flex w-full">
          <div className="flex flex-col mt-9 items-start">
            <h3 className="text-gray-700 font-semibold text-lg">
              Current Class
            </h3>
            <div className="flex gap-x-2 h-10 mt-2">
              <ClassDivisionSelector
                id={"promotestudents-sortby"}
                classes={classData}
                divisions={divisionData}
                onClassChange={handleCurrentClassChange}
                onDivisionChange={handleCurrentDivisionChange}
              />
            </div>
            {currentSelectedDivision?.isPromoted && (
              <div className="flex mt-2 gap-x-2 items-center">
                <InfoCircleFilled
                  style={{
                    color: "#EB5757",
                    fontSize: "16px",
                  }}
                />
                <p className="text-cancel">This class is already promoted</p>
              </div>
            )}
          </div>
          <div className="flex w-full items-center mx-4 mt-16">
            <div className="border-b-2 border-black border-dashed w-full"></div>
            <ChevronRightIcon className="h-7 w-8" aria-hidden="true" />
          </div>
          <div className="flex flex-col mt-9 items-start">
            <h3 className="text-gray-700 font-semibold text-lg">Promoted To</h3>
            <div className="flex gap-x-2 h-10 mt-2">
              <ClassDivisionSelector
                id={"promotestudents-sortby"}
                classes={nextYearClasses}
                divisions={nextYearDivisions}
                onClassChange={handlePromotedToClassChange}
                onDivisionChange={handlePromotedToDivisionChange}
              />
            </div>
          </div>
        </div>
        {hidePromotionTable && (
          <div className="flex flex-col gap-y-3 bg-white w-full mt-6 h-auto rounded-lg pt-16 pl-12 items-center shadow-lg overflow-y-visible">
            <p className="font-semibold text-2xl text-black">How it works</p>
            <div className="flex flex-col gap-y-10 mt-8 mb-16 text-left">
              <div className="flex gap-x-4 items-center">
                <span className="font-semibold text-xl text-success">
                  Step 1 :
                </span>
                <p className="font-medium text-lg text-gray-500">
                  Select Class and Division in Current Class section
                </p>
              </div>
              <div className="flex gap-x-5 items-center">
                <span className="font-semibold text-xl text-success">
                  Step 2 :
                </span>
                <p className="font-medium text-lg text-gray-500">
                  Select Class and Division in Promoted to section
                </p>
              </div>
              <div className="flex gap-x-5 items-center">
                <span className="font-semibold text-xl text-success">
                  Step 3 :
                </span>
                <p className="font-medium text-lg text-gray-500">
                  Click on the toggle button to promote or not promote a student
                </p>
              </div>
              <div className="flex gap-x-5 items-center">
                <span className="font-semibold text-xl text-success">
                  Step 4 :
                </span>
                <p className="font-medium text-lg text-gray-500">
                  Click on Promote Class button
                </p>
              </div>
            </div>
          </div>
        )}
        {!hidePromotionTable && (
          <div
            className="flex items-end flex-col w-full mt-4 gap-y-2 overflow-y-auto"
            style={{
              height: "60vh",
            }}
          >
            <PromotionsTable
              students={studentData?.division_students}
              allExams={examsData?.assessments}
              onStudentPromotionChange={handleStudentPromotionChange}
              promotionFormData={promotionFormData}
              isPromoted={currentSelectedDivision?.isPromoted}
            />
          </div>
        )}
        <Modal
          visible={isPromotionSuccessModalVisible}
          centered
          footer={null}
          closable={false}
          width={346}
        >
          <div className="flex flex-col bg-white rounded-md items-center text-center">
            <img alt="stars" src={starsIcon} className="h-16 w-14" />
            <p className="text-lg text-black font-bold mt-2 w-64">
              Class {currentSelectedDivision?.label?.split(":")[1]} students
              promoted to Class {promotedToDivision?.label?.split(":")[1]}
            </p>
            <Button
              onClick={handleConfirmPromotionSuccess}
              buttonStyle="primary"
              className="w-20 mt-6"
            >
              Ok
            </Button>
          </div>
        </Modal>
        <Modal
          visible={isPromotionConfirmModalOpen}
          centered
          footer={null}
          closable={false}
        >
          <div className="flex flex-col bg-white rounded-md items-center text-center p-5">
            <p className="text-lg text-black font-bold mt-2 w-64">
              Do you want to promote all the students from this class?
            </p>
            <div className="flex gap-x-5">
              <Button
                onClick={handleClosePromotionModal}
                buttonStyle="danger"
                className="w-20 mt-6"
              >
                No
              </Button>
              <Button
                onClick={handlePromoteStudents}
                buttonStyle="success"
                className="w-20 mt-6"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={isDetainModalVisible}
          centered
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            danger: false,
            style: {
              border: "none",
              fontWeight: "bold",
              backgroundColor: "#27AE60",
              width: "5rem",
              borderRadius: "6px",
            },
          }}
          cancelButtonProps={{
            danger: true,
            style: {
              fontWeight: "bold",
              color: "white",
              backgroundColor: "#EB5757",
              width: "5rem",
              borderRadius: "6px",
              marginRight: "1rem",
            },
          }}
          onCancel={handleCancelDetain}
          onOk={handleConfirmDetain}
          closable={false}
        >
          <div className="flex flex-col bg-white rounded-md items-start p-5">
            <p className="text-lg text-black font-bold">
              Do you want to keep the followning students in the same class?
            </p>
            <div className="text-gray-500 mt-6 mb-6">
              {nonPromotedStudents.length > 0 &&
                nonPromotedStudents.map((student, index) => {
                  return (
                    <p key={student.id}>
                      {index + 1}. {student.studentName}
                    </p>
                  );
                })}
            </div>
            <p className="text-red-500 font-medium">
              Note: These students will continue studying in class{" "}
              {currentSelectedDivision?.label.split(":")[1]}
            </p>
            <div>
              <p className="text-black font-medium mt-3">
                Change their division
              </p>
              <div className="flex gap-x-2 mt-2">
                <ClassDivisionSelector
                  classes={nextYearClasses}
                  divisions={nextYearDivisions}
                  selectedClassId={detainedClass?.value}
                  selectedDivisionId={detainedDivision?.value}
                  onClassChange={handleDetainedClassChange}
                  onDivisionChange={handleDetainedDivisionChange}
                  hideClassSelector
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {!hidePromotionTable && (
        <div className="flex justify-end items-center gap-x-3 w-full bg-white pr-8 absolute bottom-0 h-20 border-t">
          <Button
            buttonStyle="default"
            className="h-10"
            onClick={handleCancelPromotion}
            disabled={currentSelectedDivision?.isPromoted}
          >
            Cancel
          </Button>
          <Button
            buttonStyle="primary"
            className="h-10 w-40"
            onClick={handlePromoteStudentsSubmit}
            disabled={currentSelectedDivision?.isPromoted}
          >
            Promote Students
          </Button>
        </div>
      )}
    </>
  );
}

export default StudentsPromotion;
