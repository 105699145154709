import React, { useState } from "react";
import useNotificationList from "../../hooks/useNotificationList";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import AnnouncementContainer from "./AnnouncementContainer";

const PAGE_SIZE = 6;

function ReceivedAnnouncementDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const {
    data: notificationData,
    isLoading,
    isError,
  } = useNotificationList({
    limit: PAGE_SIZE,
    offset: (currentPage - 1) * PAGE_SIZE,
  });

  const totalCount = notificationData?.total || 0;

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  return (
    <>
      <main className="flex flex-col flex-1 px-8 pt-3 pb-8 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-hidden">
        <div className="flex flex-row gap-x-4 items-center justify-between mb-2">
          <div className="flex">
            <h1 className="text-2xl text-left font-bold flex-col m-0 mr-6">
              Received (From Super Admin)
            </h1>
          </div>
          <button
            id="manageclasses-class-back"
            onClick={() => {
              navigate(-1);
            }}
            className="py-3 px-4 flex justify-center items-center  bg-pink-400 hover:bg-pink-500 hover:text-white focus:ring-pink-500 focus:ring-offset-pink-200  transition ease-in duration-200 text-white text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-200"
          >
            Back to Announcements
          </button>
        </div>

        <div className="flex flex-1 overflow-hidden gap-x-10">
          <AnnouncementContainer
            loading={isLoading}
            error={isError}
            data={notificationData}
          />
        </div>
        <Pagination
          size="small"
          pageSize={PAGE_SIZE}
          total={totalCount}
          current={currentPage}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
      </main>
    </>
  );
}

export default ReceivedAnnouncementDetails;
