import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getSchoolFromEnquiry = async (payload = {}) => {
	try {
		const config = {
			method: "post",
			url: `${process.env.REACT_APP_NODE_ENDPOINT}/admission/getSchoolFromEnquiry`,
			headers: {
				"Content-Type": "application/json",
			},
			data: payload,
		};
		const { data } = await axios.request(config);
		return data?.data;
	} catch (e) {
		console.error("Use getSchoolFromEnquiry Error: ", { e: e.message });
		return null;
	}
};

export default function useSchoolFromEnquiry(payload = [], options = {}) {
	const finalOptions = {
		enabled: payload?.schoolId,
		...options,
	};
	return useQuery({
		queryKey: ["getSchoolFromEnquiry"],
		queryFn: () => getSchoolFromEnquiry(payload),
		options: finalOptions,
	});
}