/* eslint-disable no-console */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAuthID } from "../shared/getAuthID";
import axios from "axios";

const notificationClearCount = async (payload) => {
  const authId = getAuthID();
  const data = {
    appName: "ADMIN_APP",
    authId,    
    ...payload,
  };
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_CSMC_ENDPOINT}/v1/notification/clear_notification_count
    `,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useNotificationClearCount(options = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => notificationClearCount(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAdminSideBar"] });
    },
    ...options,
  });
}
