import { gql } from "@apollo/client";

export const GET_STUDENTS = gql`
  query getStudents($schoolId: Int!, $name: String, $documentType: String) {
    students(
      where: {
        is_draft: { _eq: false }
        school_id: { _eq: $schoolId }
        full_name: { _ilike: $name }
      }
      order_by: { first_name: asc, middle_name: asc, last_school: asc }
    ) {
      id
      dob
      division {
        division_name
      }
      first_name
      middle_name
      last_name
      mother_name
      student_ref
      aadhaar
      is_active
      transfer_date
      full_name
      documents_issuance_histories(
        where: { document_type: { _eq: $documentType } }
      ) {
        document_number
        document_type
        issuance_date
      }
    }
  }
`;

export const GET_ACTIVE_STUDENTS = gql`
  query getStudents($schoolId: Int!, $name: String, $documentType: String) {
    students(
      where: {
        is_draft: { _eq: false }
        is_active: { _eq: true }
        division: { school_id: { _eq: $schoolId } }
        full_name: { _ilike: $name }
      }
      order_by: { first_name: asc, middle_name: asc, last_school: asc }
    ) {
      id
      dob
      division {
        division_name
      }
      first_name
      middle_name
      last_name
      mother_name
      student_ref
      aadhaar
      is_active
      transfer_date
      full_name
      documents_issuance_histories(
        where: { document_type: { _eq: $documentType } }
      ) {
        document_number
        document_type
        issuance_date
      }
    }
  }
`;

export const GET_STUDENT_DOCUMENT_DETAILS = gql`
  query getStudentDocumentDetails($studentId: Int!, $documentType: String) {
    students_by_pk(id: $studentId) {
      id
      student_ref
      aadhaar
      additional_info
      full_name
      mother_name
      father_name
      father_occupation
      nationality
      caste
      sub_caste
      religion
      mother_tongue
      place_of_birth
      is_active
      dob
      school {
        image
        trust_name
        title
        settings
        address {
          city {
            city_name
            district {
              district_name
              state {
                state_name
              }
            }
          }
        }
        udise_number
        board_name
        registration_number
        document_number
        medium
        mobile_number
        email
      }
      division {
        division_name
        class_id
        class {
          class_name
        }
        acadamic_year {
          title
          from_date
          to_date
        }
      }
      left_class
      last_school
      last_school_class
      date_of_admission
      enroll_date
      admitted_class
      student_progress
      transfer_reason
      transfer_date
      remarks
      gr_no
      conduct
      additional_info
      documents_issuance_histories(
        where: { document_type: { _eq: $documentType } }
      ) {
        document_number
        document_type
        issuance_date
        is_active
      }
    }
  }
`;

export const UPDATE_STUDENT_AND_SCHOOL = gql`
  mutation updateStudentsAndSchool(
    $studentId: Int!
    $studentData: students_set_input!
    $schoolId: Int!
    $schoolData: schools_set_input!
  ) {
    update_students_by_pk(pk_columns: { id: $studentId }, _set: $studentData) {
      id
    }
    update_schools_by_pk(pk_columns: { id: $schoolId }, _set: $schoolData) {
      id
    }
  }
`;

export const ISSUE_DOCUMENT = gql`
  mutation insertDocumentHistory(
    $documentNumber: String!
    $documentType: String!
    $issuanceDate: date!
    $studentId: Int!
    $academicYearId: Int!
    $schoolId: Int!
  ) {
    insert_documents_issuance_history_one(
      object: {
        student_id: $studentId
        issuance_date: $issuanceDate
        document_type: $documentType
        document_number: $documentNumber
        academic_year_id: $academicYearId
        school_id: $schoolId
      }
    ) {
      id
    }
  }
`;

export const GET_ISSUANCE_HISTORY = gql`
  query issuanceHistory(
    $limit: Int
    $offset: Int
    $filters: documents_issuance_history_bool_exp
  ) {
    documents_issuance_history(
      limit: $limit
      offset: $offset
      order_by: { issuance_date: desc }
      where: $filters
    ) {
      student {
        id
        first_name
        middle_name
        last_name
        full_name
        is_active
        is_draft
        mother_name
        transfer_date
      }
      id
      document_type
      document_number
      issuance_date
    }
    documents_issuance_history_aggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ACADEMIC_YEARS = gql`
  query getAcademicYear($schoolId: Int!) {
    acadamic_years(where: { school_id: { _eq: $schoolId } }) {
      id
      is_active
      title
    }
  }
`;

export const GET_LAST_TC_NUMBER = gql`
  query lastTCnumber($schoolId: Int!) {
    documents_issuance_history(
      limit: 1
      order_by: { id: desc }
      where: {
        student: { school_id: { _eq: $schoolId } }
        document_type: { _eq: "Transfer Certificate" }
      }
    ) {
      document_number
    }
  }
`;
