import React, { useState, useEffect } from "react";
import { Select, Input } from "antd";
import { getSortedClasses } from "../../helpers";
import { getSchoolID } from "../../shared/getSchoolID";
import { Button } from "../../components";
import useTracking from "../../hooks/useTracking";

const { Option } = Select;
const { TextArea } = Input;

function AnnouncementForm({
  announcement,
  createAnnouncement,
  updateAnnouncement,
  updateAnnouncementReceivers,
  classes,
  isLoading,
  onComplete,
}) {
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [isSelectingClasses, setIsSelectingClasses] = useState(false);
  const [announcementType, setAnnouncementType] = useState("general");
  const [announcementMessage, setAnnouncementMessage] = useState("");
  const [announcementSendTo, setAnnouncementSendTo] = useState("school");
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (announcement) {
      setAnnouncementType(announcement.message_type);
      setAnnouncementMessage(announcement.message_body);
      if (announcement?.send_to === "school") {
        setIsSelectingClasses(false);
        setAnnouncementSendTo("school");
      } else {
        setIsSelectingClasses(true);
        setAnnouncementSendTo("class");
      }
    }
  }, [announcement]);

  function handleChange(value) {
    if (value === "class") {
      setIsSelectingClasses(true);
      setAnnouncementSendTo("class");
    } else {
      setIsSelectingClasses(false);
      setAnnouncementSendTo("school");
    }
  }

  const handleAnnouncementTypeChange = (value) => {
    setAnnouncementType(value);
  };

  const handleSelectClass = (classItem) => {
    if (selectedClasses.includes(classItem)) {
      setSelectedClasses(selectedClasses.filter((item) => item !== classItem));
    } else {
      setSelectedClasses([...selectedClasses, classItem]);
    }
  };

  const handleCreateAnnouncement = () => {
    trackEvent("Send Announcement");
    createAnnouncement({
      variables: {
        announcementData: {
          school_id: getSchoolID(),
          title: "Announcement",
          is_active: true,
          message_body: announcementMessage,
          send_to: announcementSendTo === "school" ? "school" : "class",
          message_type: announcementType.toLocaleLowerCase().includes("general")
            ? "General"
            : "",
          announcement_receivers:
            announcementSendTo === "school"
              ? null
              : {
                  data: selectedClasses?.map((selectedClass) => ({
                    class_id: selectedClass.id,
                  })),
                },
        },
      },
    })
      .then((res) => {
        onComplete(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleUpdateAnnouncement = () => {
    trackEvent("Update Announcement");
    updateAnnouncement({
      variables: {
        id: announcement.id,
        announcementData: {
          title: "Announcement",
          message_type: announcementType.toLocaleLowerCase().includes("general")
            ? "General"
            : "",
          message_body: announcementMessage,
          is_active: true,
          send_to: announcementSendTo === "school" ? "school" : "class",
        },
      },
    })
      .then((res) => {
        onComplete(res);
      })
      .catch((err) => {
        console.error(err);
      });
    if (announcementSendTo === "class") {
      const announcementReceiversData = selectedClasses?.map(
        (selectedClass) => {
          return {
            announcement_id: announcement.id,
            class_id: selectedClass.id,
            is_active: true,
          };
        }
      );
      updateAnnouncementReceivers({
        variables: {
          announcementReceiversData: announcementReceiversData,
        },
      })
        .then((res) => {
          // onComplete(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const loadingButtonClass = isLoading ? "opacity-20" : "";
  return (
    <div className="flex flex-col">
      <form className="flex flex-col gap-6">
        <div className="flex flex-col">
          <label
            className="text-gray-600 font-bold text-sm leading-5 pb-1 text-left"
            htmlFor="announcement-send-to"
          >
            Send To
          </label>
          <Select
            id="announcement-send-to"
            defaultValue="school"
            onChange={handleChange}
            className="customised-antd-selector"
            value={
              announcementSendTo === "school"
                ? "All Classes"
                : "Selected Classes"
            }
            style={{
              borderRadius: "6px",
              width: "auto",
            }}
          >
            <Option value="school">All Classes</Option>
            <Option value="class">Selected Classes</Option>
          </Select>
          <div className="gap-x-3 gap-y-3 mt-2 flex w-100 flex-wrap">
            {isSelectingClasses &&
              getSortedClasses(classes).map((classItem, index) => (
                <button
                  key={index}
                  style={{
                    minWidth: 75,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectClass(classItem);
                  }}
                  className={`${
                    selectedClasses.includes(classItem)
                      ? ` "flex py-1 px-3 bg-indigo-700 justify-center items-center text-center font-medium shadow-sm rounded-md  text-white`
                      : `flex py-1 px-3 bg-white justify-center items-center text-center font-medium text-indigo-700 shadow-sm rounded-md`
                  }`}
                >
                  {classItem.class_name}
                </button>
              ))}
          </div>
        </div>
        <div className="flex flex-col">
          <label
            className="text-gray-600 font-bold text-sm leading-5 pb-1 text-left"
            htmlFor="announcementType"
          >
            Announcement Type
          </label>
          <Select
            id="announcementType"
            defaultValue="general"
            value={announcementType}
            onChange={handleAnnouncementTypeChange}
            className="customised-antd-selector"
            style={{
              width: "auto",
            }}
          >
            <Option value="general">General</Option>
            <Option value="general">Holiday</Option>
            <Option value="general">Exam</Option>
            <Option value="general">Fees</Option>
          </Select>
        </div>
        <div className="flex flex-col">
          <label
            className="text-gray-600 font-bold text-sm leading-5 pb-1 text-left"
            htmlFor="announcementMessage"
          >
            Announcement
          </label>
          <TextArea
            id="announcementMessage"
            placeholder="Add details of the announcement here..."
            rows={4}
            value={announcementMessage}
            onChange={(e) => {
              e.preventDefault();
              setAnnouncementMessage(e.target.value);
            }}
          />
        </div>
        <Button
          id="announcements-pushtostudent"
          buttonStyle="primary"
          onClick={
            announcement ? handleUpdateAnnouncement : handleCreateAnnouncement
          }
          disabled={isLoading}
          className={`text-white font-medium py-2 px-4 rounded-md ${loadingButtonClass}`}
        >
          {isLoading ? "Loading..." : "Push to Student App"}
        </Button>
        <div className="flex items-center justify-center">
          <p className="text-indigo-700 font-semibold m-0">
            Notification will be sent to students automatically
          </p>
        </div>
      </form>
    </div>
  );
}

export default AnnouncementForm;
