import { useState, useEffect } from "react";
import axios from "axios";

const defaultHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const useAxios = ({
  url,
  method,
  variables = null,
  headers = defaultHeaders,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const apiUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/${url}`;

  const fetchData = async (
    params = {
      url: apiUrl,
      method,
      headers,
      data: variables,
    }
  ) => {
    try {
      const result = await axios.request(params);
      setData(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      url: apiUrl,
      method,
      headers,
      data: variables,
    });
  }, []);

  return { data, error, loading, refetch: fetchData };
};

export default useAxios;
