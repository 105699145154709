import { useState } from "react";
import axios from "axios";

const defaultHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const useRemoveParent = ({
  url,
  method,
  variables = null,
  headers = defaultHeaders,
}) => {
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/user/deleteParents`;

  const fetchData = async (params) => {
    setLoading(true);
    setData(false);
    setError(false);
    try {
      const result = await axios.request({
        url:apiUrl,
        method:"post",
        data:params,
        headers
      });
      if (result?.data?.success) {
        setData(true);
        return true;
      } else if (result?.data?.message) {
        setError(result?.data?.message);
      } else {
        setError("Unable to load data!");
      }
      return false;
    } catch (error) {
      setError(error);
      return false;
    } finally {      
      setLoading(false);
      return true;
    }
  };

  return { data, error, loading, refetch: fetchData };
};

export default useRemoveParent;
