import React, { useState } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import { DatePicker, Pagination, Spin } from "antd";
import Button from "../../../components/Button";
import { Dropdown } from "../../../components";
import manageIllustration from "../../../assets/images/manage.png";
import { useNavigate, useLocation } from "react-router-dom";
import useDownloadDailyTranscationsReport from "../../../hooks/useDownloadDailyTranscationsReport";
import useDailyTranscations from "../../../hooks/useDailyTranscations";
import { DownloadIcon } from "@heroicons/react/outline";
import { getSchoolID } from "../../../shared/getSchoolID";
import {
  TRANSCATION_TYPES,
  DATE_FORMATS,  
  DOWNLOAD_OPTIONS,
} from "../../../utils/constants";
import useFilterOptions from "../../../hooks/useFilterOptions";
import useGetExpenseById from "../../../hooks/useGetExpenseById";
import useDeleteExpense from "../../../hooks/useDeleteExpense";
import moment from "moment";
import { Drawer } from "antd";
import CloseIcon from "../../../components/closeIcon";
import FilterTypeSelect from './FilterTypeSelect';
import useGetExpenseCategories from "../../../hooks/useGetExpenseCategories";
import ExpenseForm from "../../../components/ExpenseForm";
import useTracking from "../../../hooks/useTracking";

const { RangePicker } = DatePicker;
const PAGE_SIZE = 10;

const getNonNulledObjects = (arr) =>
  arr?.filter((elem) => elem?.value !== null);

function TransactionsTable({ filterData, onEditClick }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [isStructureDrawerOpen, setIsStructureDrawerOpen] = useState(false);

  const { data: expenseCategoriesAndPaymentModes } = useGetExpenseCategories({
    schoolId: getSchoolID(),
  });

  const { mutate: deleteExpense } = useDeleteExpense();

  const { data: expenseData, isLoading } = useGetExpenseById({
    expenseId: selectedExpense,
  });
  const { data: transactionData, isLoading: isLoadingTransactions } =
    useDailyTranscations(
      {
        offset: PAGE_SIZE * (currentPage - 1),
        includeTotalCount: true,
        limit: PAGE_SIZE,
        ...filterData,
      },
      {
        enabled: filterData?.schoolId !== undefined,
        refetchOnWindowFocus: false,
      }
    );

  const totalCount = transactionData?.totalCount || 0;
  const dailyTransactions = transactionData?.transactions || [];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  if (!isLoadingTransactions && dailyTransactions?.length === 0) {
    return (
      <Spin spinning={isLoadingTransactions}>
        <div className="mb-0 pb-0 object-fit h-96 flex mt-44 flex-col place-items-center">
          <img src={manageIllustration} alt="" className=" max-h-full" />
          <p className="font-bold text-3xl mt-9">
            No data found for this academic year
          </p>
        </div>
      </Spin>
    );
  }

  const handleDrawerClose = () => {
    setIsStructureDrawerOpen(false);
    setSelectedExpense(null);
  };

  const handleExpenseDelete = () => {
    deleteExpense({
      expenseId: selectedExpense?.expenseId,
    });
    handleDrawerClose();
  };

  const handleExpenseClick = ({ type, id }) => {
    if (type === "expense") {
      setSelectedExpense(id);
      setIsStructureDrawerOpen(true);
    }
  };

  return (
    <Spin spinning={isLoadingTransactions}>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Sr. No.
                </th>
                <th
                  scope="col"
                  className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Student Name/Expense Title
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Date-Time
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Mode of Payment
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey  text-left text-sm uppercase font-medium"
                ></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(dailyTransactions) &&
                dailyTransactions?.map((transcation, index) => {
                  return (
                    <tr key={transcation.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center text-left">
                          <div className="ml-3">
                            <p className="text-black font-medium whitespace-no-wrap">
                              {currentPage * PAGE_SIZE + (index + 1)}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center text-left">
                          <div className="ml-3">
                            <div className="flex flex-1">
                              <p className="text-black font-medium whitespace-no-wrap">
                                {transcation?.title}
                              </p>
                              {transcation?.isNew && (
                                <p
                                  className="bg-bloodRed p-0.5 px-1 text-xs font-mediun text-white ml-2"
                                  style={{ fontSize: 10, borderRadius: 4 }}
                                >
                                  New
                                </p>
                              )}
                            </div>
                            <p className="text-textGrey">
                              {transcation?.subTitle || ``}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-black whitespace-no-wrap font-medium">
                          {transcation?.date
                            ? moment(
                                transcation?.date,
                                DATE_FORMATS.DDMMYYYY
                              ).format(DATE_FORMATS.DDMMMYYYY)
                            : ""}
                        </p>
                        <p className="text-textGrey">
                          {transcation?.time || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-darkerGrey">
                          {transcation?.paymentMode || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p
                          className={`${
                            transcation?.type === "expense"
                              ? "text-red-500"
                              : "text-success"
                          } whitespace-no-wrap font-medium text-right`}
                        >
                          {transcation?.amountText || ``}
                        </p>
                        <p
                          className="text-textGrey text-right"
                          style={{ textTransform: "capitalize" }}
                        >
                          {transcation?.type || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <Button
                          buttonStyle="primary"
                          className="py-3 px-4"
                          onClick={() => {
                            handleExpenseClick(transcation);
                            onEditClick(transcation);
                          }}
                        >
                          <PencilIcon
                            className="h-4 w-4 mr-1"
                            aria-hidden="true"
                          />
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Drawer
          title={
            selectedExpense ? (
              <div className="flex justify-between items-center">
                <h2 className="font-semibold text-xl text-gray-900">
                  Edit Expense
                </h2>
                <span>
                  <button
                    id="managecalendar-editevent-deleteall"
                    onClick={handleExpenseDelete}
                    className=" h-30 py-3 px-4 mr-5 flex justify-center items-center  bg-red-500 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
                  >
                    Delete Expense
                  </button>
                </span>
              </div>
            ) : (
              <h2 className="font-semibold text-xl text-gray-900 ml-3">
                Create new expense
              </h2>
            )
          }
          placement="right"
          onClose={handleDrawerClose}
          open={isStructureDrawerOpen}
          destroyOnClose
          contentWrapperStyle={{
            width: "40vw",
          }}
          closeIcon={<CloseIcon />}
          afterOpenChange={(visible) => {
            if (!visible) {
              setSelectedExpense(null);
            }
          }}
        >
          {!isLoading && (
            <ExpenseForm
              selectedExpense={expenseData}
              category={getNonNulledObjects(
                expenseCategoriesAndPaymentModes?.category
              )}
              amount={getNonNulledObjects(
                expenseCategoriesAndPaymentModes?.amount
              )}
              onCancel={handleDrawerClose}
              onSave={handleDrawerClose}
            />
          )}
        </Drawer>
        <Pagination
          size="small"
          pageSize={PAGE_SIZE || 10}
          total={totalCount}
          current={currentPage}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
      </div>
    </Spin>
  );
}

function DailyTransactions() {
  const [reportFilters, setReportFilters] = useState({});

  const navigate = useNavigate();
  const { state } = useLocation();
  const defaultState = state?.fromDate
    ? {
        type: null,
        schoolId: getSchoolID(),
        ...state,
      }
    : {};
  const [filterData, setFilterData] = useState(defaultState);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const { isLoading: isLoadingOptions } = useFilterOptions(
    {
      schoolId: getSchoolID(),
      entity: "dailyTransactions",
    },
    {
      enabled: defaultState?.fromDate === undefined,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setFilterData({
          type: null,
          schoolId: getSchoolID(),
          fromDate: data.fromDate,
          toDate: data.toDate,
        });
      },
    }
  );
  const { mutateAsync: downloadReport } = useDownloadDailyTranscationsReport({
    onSettled: () => {
      setIsDownloadingReport(false);
    },
  });
  const { trackEvent } = useTracking();

  const typeValue = () => {
    const id = filterData?.type;
    if (!id) return null;
    // eslint-disable-next-line eqeqeq
    const ty = TRANSCATION_TYPES.find(({ value }) => value == id);
    return ty?.label;
  };

  const handleEditClick = ({ type, id }) => {
    if (type === "fee") {
      navigate(`/fees-collection/new-collection`, {
        state: {
          feesCollectionData: {
            id,
          },
        },
      });
    }
  };

  const onRangeChange = (dates) => {
    if (dates) {
      const formattedStartDate = dates[0].format(DATE_FORMATS.YYYYMMDD);
      const formattedEndDate = dates[1].format(DATE_FORMATS.YYYYMMDD);
      setFilterData({
        ...filterData,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      });
    } else {
      setFilterData({
        ...filterData,
        fromDate: null,
        toDate: null,
      });
    }
  };

  const handleDownloadReport = async (fileType) => {
    trackEvent("Download Daily Transactions Report");
    setIsDownloadingReport(true);
    await downloadReport({
      fileType,
      ...filterData,
    });
  };

  return (
    <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={isDownloadingReport}>
        <div className="flex flex-row gap-x-4 items-center justify-between mb-8">
          <p className="text-2xl text-left font-bold flex-col m-0">
            Daily Transactions
          </p>
          <Button buttonStyle="secondary" onClick={() => navigate(-1)}>
            Back to Reports
          </Button>
        </div>
        <div className="flex items-start justify-between">
          {isLoadingOptions && defaultState?.fromDate === undefined ? (
            <div className="flex flex-row gap-x-7 shadow-sm h-auto pt-5 pl-5 pr-5 bg-white rounded">
              <Spin spinning />
            </div>
          ) : (
            <div className="flex flex-row gap-x-5">
              <RangePicker
                className="rounded-md shadow-sm"
                defaultValue={
                  filterData?.fromDate
                    ? [moment(filterData?.fromDate), moment(filterData?.toDate)]
                    : null
                }
                onChange={onRangeChange}
                format={DATE_FORMATS.DDMMMYYYY}
                style={{
                  borderRadius: 5,
                  minWidth: 240,
                  height: "100%",
                  padding: 9,
                }}
              />
              <Dropdown
                value={typeValue()}
                defaultValue="Filter by: All"
                type="primary"
                options={TRANSCATION_TYPES}
                onItemClick={(value) => {
                  setFilterData({
                    ...filterData,
                    type: value,
                  });
                }}
              />
              <FilterTypeSelect
                type={filterData?.type}
                onSelect={setReportFilters}
              />              
            </div>
          )}
          <Dropdown
            options={DOWNLOAD_OPTIONS}
            type="success"
            defaultValue="Download Reports"
            renderIcon={() => <DownloadIcon className="h-4 mr-1 w-5" />}
            onItemClick={handleDownloadReport}
          />
        </div>
        <TransactionsTable
          filterData={{
            ...filterData,
            ...reportFilters
          }}
          onEditClick={handleEditClick}
        />
      </Spin>
    </div>
  );
}

export default DailyTransactions;