import { useQuery } from "@apollo/client";
import { notification, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { getSchoolID } from "../../shared/getSchoolID";
import {
  getSortedClasses,
  sortDivisions,
  sortDivisionsAccordingToClasses,
} from "../../helpers";
import { GET_DIVISIONS_WITH_FILTER } from "../../graphql/queries";
import Button from "../../components/Button";
import ClassDivisionSelector from "../../components/ClassDivisionSelector";
import { getAvatar } from "../../shared/getAvatar";
import { GET_CLASSES_AND_DIVISIONS } from "../../views/studentsView/graphql";
import successImage from "../../assets/images/success.png";
import checkImage from "../../assets/icons/check.png";
import axios from "axios";
import useTracking from "../../hooks/useTracking";

const Badge = ({ type }) => {
  return (
    <span
      className={`w-3 h-3 text-base rounded-full border border-black text-white mr-2 ${
        type === "present" ? "c-bg-marked" : "bg-red-300"
      }`}
    ></span>
  );
};

const NOTIFICATION_TIMEOUT = 2000;

const Attendance = () => {
  const [divisionsData, setDivisionsData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [classData, setClassData] = useState([]);
  const [currentSelectedClass, setCurrentSelectedClass] = useState(null);
  const [remindAllTeachers, setRemindAllTeachers] = useState(false);
  const [remindTeachers, setRemindTeachers] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const { trackEvent } = useTracking();

  useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (classResponse) => {
      const sortedClasses = getSortedClasses(classResponse.classes);
      const classLists = [];
      sortedClasses.forEach((classObj) => {
        classLists.push({
          value: classObj.id,
          label: `Class: ${classObj.class_name}`,
        });
      });

      setClassData(classLists);
    },
  });

  const { loading: divisionsLoading, data: divisionsYetToBeMarked } = useQuery(
    GET_DIVISIONS_WITH_FILTER,
    {
      variables: {
        schoolId: getSchoolID(),
        search: `%${searchText}%`,
      },
      fetchPolicy: "network-only",
    }
  );

  const handleCurrentClassChange = (value) => {
    setCurrentSelectedClass(value);
  };

  useEffect(() => {
    if (divisionsYetToBeMarked) {
      const divisions = sortDivisions(divisionsYetToBeMarked.divisions);
      const divisionClasses = sortDivisionsAccordingToClasses(
        divisions,
        currentSelectedClass
      );
      setDivisionsData(divisionClasses);
    }
  }, [divisionsYetToBeMarked, currentSelectedClass]);

  const handleRemindAllTeacher = () => {
    let notificationData = {};
    const teacherIds = [];

    let isAtleastOneTeacherAssigned = divisionsData.find((division) => {
      return division?.class_teacher !== null;
    });
    if (!isAtleastOneTeacherAssigned) {
      setShowCard(true);
      setTimeout(() => {
        setShowCard(false);
      }, 5000);
      return;
    }

    divisionsData.forEach((div, index) => {
      let authId = div?.class_teacher?.auth_id;
      if (authId) {
        const divisionsWithoutAttendance = div?.divisions?.filter(
          (division) => division.attendanceYetToBeMarked
        );
        const divisionList = divisionsWithoutAttendance.map((div, index) => {
          return {
            divId: div?.division_id,
            divName: div?.division_name,
          };
        });

        if (divisionList.length > 0) {
          notificationData[authId] = {
            divisions: divisionList,
          };
          teacherIds.push(authId);
        }
      }
    });
    let finalData = { data: notificationData };

    trackEvent("Remind All Teachers", { teacherIds });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/notifications/pushNotifyTeacherForAttendance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { data: finalData.data },
    };
    axios(config)
      .then((res) => {
        setRemindAllTeachers(true);
        setTimeout(() => {
          setRemindAllTeachers(false);
        }, NOTIFICATION_TIMEOUT);
      })
      .catch((err) => {
        notification["error"]({
          message: JSON.stringify(err),
          duration: NOTIFICATION_TIMEOUT / 1000,
        });
      });
  };

  const handleRemindTeacher = (val) => {
    trackEvent("Remind Teacher", { teacherId: val?.class_teacher?.auth_id });
    let authId = val?.class_teacher?.auth_id;
    const divisionsWithoutAttendance = val.divisions.filter(
      (division) => division.attendanceYetToBeMarked
    );
    const divisionList = divisionsWithoutAttendance.map((div, index) => {
      return {
        divId: div?.division_id,
        divName: div?.division_name,
      };
    });

    let finalData = {
      data: {
        [authId]: {
          divisions: divisionList,
        },
      },
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/notifications/pushNotifyTeacherForAttendance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { data: finalData.data },
    };
    axios(config)
      .then((res) => {
        const teacherId = val?.class_teacher?.auth_id;
        setRemindTeachers((teacherIds) => [...teacherIds, teacherId]);
        setTimeout(() => {
          setRemindTeachers((teacherIds) =>
            teacherIds.filter(
              (currentTeacherId) => currentTeacherId !== teacherId
            )
          );
        }, NOTIFICATION_TIMEOUT);
      })
      .catch((err) => {
        notification["error"]({
          message: JSON.stringify(err),
          duration: NOTIFICATION_TIMEOUT / 1000,
        });
      });
  };

  // If attendance for all divisions is taken, button needs to be disabled
  const isAttendanceTaken = (divisions) => {
    const divisionsWithoutAttendance = divisions?.find(
      (division) => division?.attendanceYetToBeMarked
    );
    return !divisionsWithoutAttendance;
  };

  return (
    <div className="container">
      <Spin spinning={divisionsLoading}>
        <div className="flex flex-row justify-between mb-1 mt-5 sm:mb-0  w-full items-end">
          <div className="flex flex-row gap-x-4">
            <div className="">
              <form
                className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className=" relative ">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="form-subscribe-Filter"
                    className=" rounded-md pl-8 border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by teacher or class"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <ClassDivisionSelector
              id={"manageexams-sortby"}
              hideDivisionSelector
              classDefaultValue="Class : All"
              classes={classData}
              allOptionNeeded
              onClassChange={handleCurrentClassChange}
            />
            <div className="flex flex-row justify-center gap-4 align-middle justify-items-center text-end leading-tight mt-1">
              <h2 className="flex items-center ">
                <Badge type="present" /> Marked
              </h2>
              <h2 className="flex items-center ">
                <Badge type="absent" /> Pending
              </h2>
            </div>
          </div>
          <div className="flex">
            {showCard && (
              <span className="flex mr-2 bg-white  w-56">
                <span className="flex p-2">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                      cx="7.20265"
                      cy="7"
                      rx="7.10108"
                      ry="7"
                      fill="#EB5757"
                    />
                    <path
                      d="M7.03767 4.24206C6.82978 4.24206 6.66129 4.07594 6.66129 3.87103C6.66129 3.66612 6.82978 3.5 7.03767 3.5C7.24557 3.5 7.41406 3.66612 7.41406 3.87103C7.41406 4.07594 7.24557 4.24206 7.03767 4.24206Z"
                      fill="white"
                      stroke="white"
                    />
                    <path d="M7.03711 10.4459L7.03711 6.11719Z" fill="white" />
                    <path
                      d="M7.03711 10.4459L7.03711 6.11719"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                  <span className="leading-4 text-cancel text-xs text-left ml-2">
                    Please assign class teacher to the classes first.
                  </span>
                </span>
              </span>
            )}
            <Button
              buttonStyle="primary"
              onClick={handleRemindAllTeacher}
              style={{ display: "inline" }}
            >
              <span className="flex justify-center items-center gap-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Remind All
              </span>
            </Button>
          </div>
        </div>
        {remindAllTeachers ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow-md">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center justify-center w-full h-full bg-white outline-none focus:outline-none">
                  <div className="items-center pl-5 pr-5 pb-7 justify-center rounded-t">
                    <img
                      alt="School logo"
                      src={successImage}
                      className="w-20 h-20 mx-auto mt-6 mb-4"
                    />
                    <div className="mx-6">
                      <p className="text-lg font-bold text-black">
                        {" "}
                        Reminder Sent{" "}
                      </p>
                      <p className="text-sm font-medium text-gray-500">
                        All teachers are notified successfully
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                  >
                    Class
                  </th>
                  <th
                    scope="col"
                    className="w-40 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {divisionsData?.map((val, classIndex) => {
                  return (
                    <tr key={classIndex}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center text-left"></div>
                        <div className="flex items-center text-left">
                          <div className="flex flex-row ">
                            <div className="flex-shrink-0 ">
                              {getAvatar(
                                val?.class_teacher?.first_name || "Not ",
                                val?.class_teacher?.last_name || "Assigned",
                                val?.class_teacher?.image,
                                undefined,
                                val?.class_teacher === null
                                  ? { backgroundColor: "#EB5757" }
                                  : {}
                              )}
                            </div>
                          </div>
                          <div className="ml-4">
                            <p
                              className={`text-black font-medium whitespace-no-wrap ${
                                val?.class_teacher === null ? "text-cancel" : ""
                              }`}
                            >
                              {val?.class_teacher?.full_name || "Not Assigned"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <div
                          key={classIndex}
                          className="flex flex-col justify-start items-start"
                        >
                          <div className="flex gap-4 flex-wrap">
                            {val?.divisions?.map((divs, index) => {
                              const attendanceMarkedClass =
                                divs?.attendanceYetToBeMarked
                                  ? "bg-red-200"
                                  : "c-bg-marked";
                              return (
                                <div
                                  key={index}
                                  className={`${attendanceMarkedClass} font-medium text-base text-cgray px-4 py-2 rounded uppercase`}
                                >
                                  <p>{divs.division_name}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right">
                        {val?.class_teacher !== null && (
                          <div className="flex">
                            <Button
                              onClick={() => handleRemindTeacher(val)}
                              buttonStyle="primary"
                              disabled={isAttendanceTaken(val?.divisions)}
                            >
                              <span className="flex justify-center items-center gap-x-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                Remind Teacher
                              </span>
                            </Button>
                            {remindTeachers.find(
                              (teacherId) =>
                                teacherId === val?.class_teacher?.auth_id
                            ) && (
                              <img
                                alt="School logo"
                                src={checkImage}
                                className="w-5 h-5 m-auto"
                              />
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Attendance;
