import React, { useState } from "react";
import { Pagination, Modal, Spin } from "antd";
import { Button } from "../../../components";
import { EyeIcon, PencilIcon } from "@heroicons/react/solid";
import { DownloadIcon } from "@heroicons/react/outline";
import AttachmentGallery from "../../../components/AttachmentGallery";
import useFilterCollection from "../../../hooks/useFilterCollection";

function FeesCollectionTable({
  filterData = {},
  pageLimit,
  onEditDetails,
  downloadFeeReceipt,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [attachmentModal, setAttachmentModal] = useState({
    isVisible: false,
    attachments: null,
  });
  const { data: collectionData, isLoading: isLoadingCollection } =
    useFilterCollection(
      {
        offset: pageLimit * (currentPage - 1),
        includeCount: true,
        limit: pageLimit,
        ...filterData,
      },
      {
        enabled: filterData?.schoolId !== undefined,
        refetchOnWindowFocus: false,
      }
    );
  const totalCount = collectionData?.totalCount || 0;
  const studentsFeesData = collectionData?.list || [];
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const showAttachmentView = (attachments) => {
    setAttachmentModal({
      isVisible: true,
      attachments,
    });
  };

  const handleAttachmentModalClose = () => {
    setAttachmentModal({
      isVisible: false,
      attachments: null,
    });
  };

  const handleEditClick = (feesCollection) => {
    onEditDetails(feesCollection);
  };

  const handleDownloadFeeReceipt = async ({ id, studentName = "" }) => {
    downloadFeeReceipt({
      feeCollectionId: id,
      fileName: `Fee_${studentName?.replace(/ /g, "_")}`,
    });
  };

  return (
    <Spin spinning={isLoadingCollection}>
      <div className="flex flex-1 -mx-4 sm:-mx-8 px-4 sm:px-8 py-4">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                >
                  Student Name
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                >
                  Fees Structure
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50 text-right  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                >
                  Date-Time
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                >
                  Attachment
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-sm text-textGrey font-medium"
                >
                  Receipt
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-sm text-textGrey font-medium"
                ></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(studentsFeesData) &&
                studentsFeesData?.map((feesData, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-darkerGrey flex font-medium leading-4 whitespace-no-wrap">
                          {feesData?.studentName || ``}{" "}
                          {feesData?.isNew ? (
                            <p
                              className="bg-bloodRed p-0.5 px-1 text-xs font-mediun text-white ml-2"
                              style={{ fontSize: 10, borderRadius: 4 }}
                            >
                              New
                            </p>
                          ) : null}
                        </p>
                        <p className="text-textGrey">
                          {feesData?.className || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-darkerGrey font-medium leading-4 whitespace-no-wrap">
                          {feesData?.feeStructure?.title || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-success text-right whitespace-no-wrap">
                          {feesData?.paidAmountText || ``}
                        </p>
                        <p className="text-textGrey text-right">
                          {feesData?.paymentMode || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <p className="text-darkerGrey font-medium leading-4 whitespace-no-wrap">
                          {feesData?.dateText || ``}
                        </p>
                        <p className="text-textGrey">
                          {feesData?.timeText || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        {feesData?.attachments?.length > 0 ? (
                          <Button
                            id="download-all-students-data"
                            buttonStyle="primary"
                            className="py-3 px-4  bg-thaliumFlame"
                            onClick={() => {
                              showAttachmentView(feesData?.attachments);
                            }}
                          >
                            <EyeIcon className="h-4 w-4 mr-1" />
                            View
                          </Button>
                        ) : (
                          <p className="text-textGrey">No attachments</p>
                        )}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <Button
                          buttonStyle="success"
                          className="py-3 px-4"
                          onClick={() => handleDownloadFeeReceipt(feesData)}
                        >
                          <DownloadIcon className="h-4 w-4" />
                          <span className="ml-2">Download</span>
                        </Button>
                        <p className="text-textGrey whitespace-nowrap mt-1">
                          Receipt No. : {feesData?.receiptNumber || ``}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                        <Button
                          id="download-all-students-data"
                          buttonStyle="primary"
                          className="py-3 px-4"
                          onClick={() => handleEditClick(feesData.id)}
                        >
                          <PencilIcon
                            className="h-4 w-4 mr-1"
                            aria-hidden="true"
                          />
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          size="small"
          pageSize={pageLimit || 10}
          total={totalCount}
          current={currentPage}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
        <Modal
          open={attachmentModal.isVisible}
          centered
          footer={null}
          destroyOnClose
          width="80%"
          bodyStyle={{
            padding: 50,
          }}
          onCancel={handleAttachmentModalClose}
        >
          <AttachmentGallery attachments={attachmentModal.attachments} />
        </Modal>
      </div>
    </Spin>
  );
}

export default FeesCollectionTable;
