/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAllRemarks = async () => {
  const config = {
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/getStaffRemarks`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const { data } = await axios.request(config);
  return data?.data || [];
};

export default function useAllAbsentReasons() {
  return useQuery(["allRemarks"], () => getAllRemarks(), {
    refetchOnWindowFocus: false,
  });
}
