import React, { useState } from "react";

// Ant Components
import { Modal, Select, Spin } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { notification } from "antd";

// Custom Components
import CloseIcon from "../../components/closeIcon";
import CustomInput from "../../components/FormInput";
import Button from "../../components/Button";

// Hooks
import { useForm, Controller } from "react-hook-form";
import useFilterOptions from "../../hooks/useFilterOptions";
import useCreateAdmissionEnquiry from "../../hooks/useCreateAdmissionEnquiry";

// Helper functions
import { getSchoolID } from "../../shared/getSchoolID";

const FormCol = ({ id, children, overrideClassnames }) => {
	return (
		<div
			id={id}
			className={`${overrideClassnames} flex flex-col flex-auto mb-5`}
		>
			{children}
		</div>
	);
};

const AcceptEnquiry = ({ onClose, onSelect, visible, classId }) => {
	const { control } = useForm({
		criteriaMode: "all",
	});
	const schoolId = getSchoolID();
	const [formDetails, setFormDetails] = useState({});
	const { data: filterData } = useFilterOptions(
		{
			schoolId: schoolId,
			entity: "studentFeeDetails",
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const sortedDivisions = React.useMemo(() => {
		const divisions = filterData?.divisions || [];
		const mClassId = parseInt(classId, 10);
		const filtered = divisions.filter((d) => d.classId === mClassId);
		return filtered;
	}, [filterData?.divisions, classId]);

	const updateDetails = (newData) => {
		setFormDetails((currentData) => ({
			...currentData,
			...newData,
		}));
	};

	const submitDetails = async () => {
		if (!formDetails?.divisionId) {
			notification["error"]({
				message: "Error!",
				description: "Please select division",
				duration: 1.5,
			});
			return;
		}
		onSelect(formDetails?.divisionId);
	};

	return (
		<Modal
			visible={visible}
			centered
			footer={null}
			bodyStyle={{
				padding: 0,
			}}
			width={400}
			onClose={onClose}
			closable={false}
			closeIcon={null}
		>
			<div className="pb-2 p-5 border-b border-gray-300">
				<div className="flex flex-row justify-between">
					<h3 className="font-bold text-lg">Admit Enquiry</h3>
					<div className="cursor-pointer" onClick={onClose}>
						<CloseIcon />
					</div>
				</div>
			</div>
			<div className="p-5">
				<FormCol id={"managestudents-addstudent-lastschoolclass"}>
					<label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
						Select Division
					</label>
					<Controller
						name="admissionToClass"
						control={control}
						render={() => (
							<Select
								className="customised-antd-selector-controlled"
								onChange={(value) => {
									updateDetails({
										divisionId: value,
									});
								}}
								size="large"
								allowClear
							>
								{sortedDivisions.map((cl) => (
									<Select.Option key={cl.value}>
										{cl.label}
									</Select.Option>
								))}
							</Select>
						)}
					/>
				</FormCol>

				<div className="flex gap-x-3 justify-end">
					<Button
						id="settings-schooldetails-cancel"
						onClick={onClose}
						className="px-5 h-10"
						buttonStyle="default"
					>
						<span>Cancel</span>
					</Button>

					<Button
						id="settings-schooldetails-save"
						type="button"
						buttonStyle="primary"
						className="px-5 h-10"
						onClick={submitDetails}
					>
						<span>Add to division</span>
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default AcceptEnquiry;