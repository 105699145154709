/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName || "staff_attendance.xlsx");
  document.body.appendChild(link);
  link.click();
};

const downloadStaffReport = async (payload) => {
  const schoolId = getSchoolID();
  const finalPayload = { ...payload, schoolId };
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_CSMC_ENDPOINT}/v1/staff/attendance_detailed_report`,
    responseType: "blob",
    data: finalPayload,
  };
  const res = await axios.request(config);
  downloadFile(res?.data, payload?.fileName);
  return true;
};

export default function useDownloadStaffReport(options = {}) {
  return useMutation({
    mutationFn: (data) => downloadStaffReport(data),
    onError: () => {
      notification["error"]({
        message: "Failure",
        description: "Unable to download staff attendance report",
        duration: 1.5,
      });
    },
    ...options,
  });
}
