import { useParams, useLocation, useNavigate } from "react-router-dom";
import QuizPerformance from "./QuizPerformance";
import TopicChart from "./TopicChart";
import WeeklyChart from "./WeeklyChart";
import Scorecard from "./Scorecard";
import Fees from "./Fees";
import Attendance from "./Attendance";

const Analytics = () => {
  const { studentId, divisionId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { hasActiveSubscription } = state;

  return (
    <section className="w-full bg-lightBackground block overflow-x-hidden overflow-y-scroll min-h-full h-screen">
      <section className="py-4 flex flex-1 justify-between w-full">
        <p className="text-2xl text-left font-bold flex-col ml-7">
          {state?.fullName} | {state?.className}
        </p>
        <button
          id="manageclasses-class-back"
          onClick={() => {
            navigate(-1);
          }}
          className="py-3 px-4 flex justify-center items-center  bg-pink-400 hover:bg-pink-500 hover:text-white focus:ring-pink-500 focus:ring-offset-pink-200  transition ease-in duration-200 text-white text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-200"
        >
          Back to Manage Students
        </button>
      </section>

      <section
        className="flex justify-between flex-1 w-full box-border mb-4 ml-7"
        style={{ height: "70vh" }}
      >
        <div className="w-1/3 h-full mr-5">
          <QuizPerformance
            studentId={studentId}
            hasActiveSubscription={hasActiveSubscription}
          />
        </div>

        <div className="w-2/3 flex-col flex-1 justify-between h-full box-border">
          <div className="h-1/2 pb-5 box-border">
            <WeeklyChart
              studentId={studentId}
              hasActiveSubscription={hasActiveSubscription}
            />
          </div>
          <div className="h-1/2 box-border">
            <TopicChart
              hasActiveSubscription={hasActiveSubscription}
              divisionId={divisionId}
              studentId={studentId}
              name={state?.fullName}
            />
          </div>
        </div>
      </section>

      <div
        className="flex ml-7 my-10 w-full justify-between"
        style={{ height: "80vh" }}
      >
        <div className="h-full" style={{ width: "32%" }}>
          <Scorecard studentId={studentId} />
        </div>

        <div className="h-full" style={{ width: "32%" }}>
          <Attendance studentId={studentId} />
        </div>

        <div className="h-full mr-6" style={{ width: "32%" }}>
          <Fees studentId={studentId} divisionId={divisionId} />
        </div>
      </div>
    </section>
  );
};

export default Analytics;
