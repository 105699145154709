/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const updateClassSettings = async (payload) => {  
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/class/update/settings`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpdateClassSettings(options = {}) {
  return useMutation({
    mutationFn: (data) => updateClassSettings(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "Receipt Settings Updated!",
        duration: 1.5,
      });
    },
    onError: (error) => {
      notification["error"]({
        message: "Failure",
        description: "Unable to update class settings",
        duration: 1.5,
      });
    },
    ...options,
  });
}
