import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Spin, notification } from "antd";
import useGetEnquiryData from "../../hooks/useGetEnquiryData";
import useGetEnquiryAdmissionForm from "../../hooks/useGetEnquiryAdmissionForm";
import useGetAdmissionFormPDF from "../../hooks/useGetAdmissionFormPDF";
import useUpdateEnquiryStatus from "../../hooks/useUpdateEnquiryStatus";
import useUpdateEnquiryFields from "../../hooks/useUpdateEnquiryFields";
import useSchoolFromEnquiry from "../../hooks/useSchoolFromEnquiry";
import Button from "../../components/Button";
import { getS3Image } from "../../shared/s3";
import EditableForm from "./EditableForm";
import AcceptEnquiry from "./AcceptEnquiry";
import { useNavigate } from "react-router-dom";

const EditAdmissionForm = () => {
	const { formId } = useParams();
	const navigate = useNavigate();
	const [editableFormData, setEditableFormData] = useState({});
	const [formOptionList, setFormOptionList] = useState({});
	const [isAccepted, setIsAccepted] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(null);
	const [acceptEnquiryModalVisible, setAcceptEnquiryModalVisible] =
		useState(false);
	const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
	const { data: enquiryData, isLoading } = useGetEnquiryData(
		{
			admissionEnquiryId: formId,
		},
		{
			onSuccess: () => {
				setCurrentStatus(enquiryData?.enquiryStatus);
			},
		},
	);
	const { data: schoolData } = useSchoolFromEnquiry({
		admissionEnquiryId: formId,
	});
	const { data: formSections = [] } = useGetEnquiryAdmissionForm(
		{
			admissionEnquiryId: formId,
		},
		{
			onSuccess: (data) => {
				const finalFileds = {};
				const optionFileds = {};
				data.forEach((f) => {
					f.fields.forEach((d) => {
						if (d?.id === "classId") {
							finalFileds[d.id] = parseInt(d.value, 10);
						} else {
							finalFileds[d.id] = d.value;
						}
						if (d?.options?.length) {
							optionFileds[d.id] = d.options;
						}
					});
				});
				setEditableFormData(finalFileds);
				setFormOptionList(optionFileds);
			},
		},
	);
	const { mutateAsync: downloadAdmissionForm } = useGetAdmissionFormPDF();
	const { mutateAsync: updateEnquiryStatus } = useUpdateEnquiryStatus();
	const { mutateAsync: updateEnquiryFields } = useUpdateEnquiryFields();
	const studentName = useMemo(() => {
		if (formSections?.length) {
			const basicSection = formSections[0];
			const student = (basicSection?.fields || []).find(
				(f) => f.id === "studentName",
			);
			return student?.value || "";
		}
		return "Loading...";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formSections?.length]);
	const schoolImage = React.useMemo(() => {
		if (schoolData?.image) {
			return getS3Image(schoolData?.image);
		}
		return null;
	}, [schoolData?.image]);
	const isDisabled = enquiryData?.enquiryStatus === "filled" || isAccepted;
	const saveChanges = async () => {
		setIsUpdatingStatus(true);
		try {
			const fields = Object.keys(editableFormData).map((m) => ({
				id: m,
				value: editableFormData[m],
			}));
			const response = await updateEnquiryFields({
				fields: fields,
				admissionEnquiryId: formId,
			});
			if (response?.success) {
				notification["success"]({
					message: "Success!",
					description: "Saved sucessfully",
					duration: 1.5,
				});
				// setAcceptEnquiryModalVisible(true);
			} else {
				throw new Error(response?.message);
			}
		} catch (err) {
		} finally {
			setIsUpdatingStatus(false);
		}
	};

	const downloadForm = async () => {
		try {
			setIsUpdatingStatus(true);
			const firstName = studentName?.split(" ")?.[0] || "";
			await downloadAdmissionForm({
				admissionEnquiryId: formId,
				formData: editableFormData,
				fileName: `AdmissionForm_${formId}_${firstName}`,
			});
		} catch (err) {
			// Ignore
		} finally {
			setIsUpdatingStatus(false);
		}
	};

	const resetStatus = () => {
		setCurrentStatus(currentStatus || enquiryData?.enquiryStatus);
	};

	const handleAcceptEnquiry = async (divisionId) => {
		try {
			setAcceptEnquiryModalVisible(false);
			setIsUpdatingStatus(true);
			const response = await updateEnquiryStatus({
				admissionEnquiryId: formId,
				enquiryStatus: "filled",
				divisionId,
			});
			if (response?.success) {
				setIsAccepted(true);
				setCurrentStatus("filled");
			} else {
				resetStatus();
			}
		} catch (err) {
			resetStatus();
		} finally {
			setIsUpdatingStatus(false);
		}
	};

	const updateData = (k, v) => {
		const newData = { ...editableFormData };
		newData[k] = v;
		setEditableFormData(newData);
	};

	return (
		<div className="flex flex-col items-start ml-8 mt-6 pr-4 mb-6 pb-10 h-screen w-screen overflow-x-hidden relative">
			<div className="flex flex-row w-full items-center justify-between">
				<h2 className="text-2xl text-left font-bold flex-col m-0 mr-5">
					{studentName}
				</h2>
				<Button buttonStyle="secondary" onClick={() => navigate(-1)}>
					Back to Admission Inquiries
				</Button>
			</div>
			<Spin
				spinning={isUpdatingStatus || isLoading}
				wrapperClassName="w-full"
			>
				<div className="w-full relative mt-5">
					<div
						className="w-full overflow-x-hidden"
						style={{ height: "79vh" }}
					>
						<EditableForm
							onChange={(k, v) => updateData(k, v)}
							optionData={formOptionList}
							data={editableFormData}
							schoolImage={schoolImage}
							schoolData={schoolData}
							disabled={isDisabled}
						/>
					</div>
					<div style={styles.footer}>
						<Button
							id="edit-form"
							buttonStyle="success"
							onClick={downloadForm}
						>
							Download Form
						</Button>
						<div className="mx-2" />
						{/*isDisabled ? (
							<>
								<Button
									id="add-to-division"
									buttonStyle="primary"
									onClick={() =>
										setAcceptEnquiryModalVisible(true)
									}
								>
									Add to division
								</Button>
								<div className="mx-2" />
							</>
						) : null*/}

						<Button
							id="edit-form"
							buttonStyle="primary"
							style={{ height: 40 }}
							onClick={saveChanges}
							disabled={isDisabled}
						>
							Save Changes
						</Button>
					</div>
				</div>
			</Spin>
			<AcceptEnquiry
				classId={enquiryData?.classId}
				onSelect={handleAcceptEnquiry}
				visible={acceptEnquiryModalVisible}
				onClose={() => {
					setAcceptEnquiryModalVisible(false);
				}}
			/>
		</div>
	);
};

const styles = {
	footer: {
		width: "100%",
		height: "7vh",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-end",
	},
};

export default EditAdmissionForm;