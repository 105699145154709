/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const updateAcademicYear = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/school/updateAcademicYear`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpdateAcademicYear(options = {}) {
  return useMutation({
    mutationFn: (data) => updateAcademicYear(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "Academic Year Updated",
        duration: 1.5,
      });
    },
    onError: (error) => {
      const errorRes = error?.response?.data;
      notification["error"]({
        message: "Failure",
        description: errorRes?.message || "Could not update academic year",
        duration: 1.5,
      });
    },
    ...options,
  });
}
