import React from "react";
import { Table } from "antd";
import { GET_GENDER_CASTE_STATS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { getSchoolID } from "../../shared/getSchoolID";
import { useEffect, useState } from "react";
import { categoryData, casteList, genderList } from "../../utils/constants";
import PropTypes from "prop-types";

export default function StudentsStatsTable({
  categoryFilterData,
  genderFilterData,
  selectedClass,
  selectedDivision,
  selectedReligion,
  rteType,
}) {
  const [schoolStats, setSchoolStats] = useState([]);
  const [tableData, setTableData] = useState(null);

  const columns = [
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">Gender</p>
      ),
      dataIndex: categoryData[1],
      key: categoryData[1],
      render(text, record) {
        return {
          props: {
            style: { background: "#F1F1FF" },
          },
          children: (
            <div className="font-semibold text-gray-500 text-[20px]">
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">Open</p>
      ),
      dataIndex: categoryData[2],
      key: categoryData[2],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[2]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[2] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">ST</p>
      ),
      dataIndex: categoryData[4],
      key: categoryData[4],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[4]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[4] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">SC</p>
      ),
      dataIndex: categoryData[3],
      key: categoryData[3],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[3]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[3] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">OBC</p>
      ),
      dataIndex: categoryData[5],
      key: categoryData[5],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[5]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[5] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">SBC</p>
      ),
      dataIndex: categoryData[6],
      key: categoryData[6],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[6]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[6] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-00 text-[20px]">SEBC</p>
      ),
      dataIndex: categoryData[7],
      key: categoryData[7],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[7]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[7] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">VJ</p>
      ),
      dataIndex: categoryData[8],
      key: categoryData[8],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[8]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[8] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">NT-B</p>
      ),
      dataIndex: categoryData[9],
      key: categoryData[9],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[9]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[9] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">NT-C</p>
      ),
      dataIndex: categoryData[10],
      key: categoryData[10],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[10]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[10] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">NT-D</p>
      ),
      dataIndex: categoryData[11],
      key: categoryData[11],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[11]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[11] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">NA</p>
      ),
      dataIndex: categoryData[12],
      key: categoryData[12],
      render(text, record) {
        if (
          record.gender === genderFilterData &&
          categoryFilterData === categoryData[12]
        ) {
          return {
            props: {
              style: { background: "#FEE1AA" },
            },
            children: (
              <div className="font-bold text-gray-900 text-[20px]">{text}</div>
            ),
          };
        }
        return {
          props: {
            style: {
              background:
                categoryFilterData === categoryData[12] ? "#FFF2D8" : "",
            },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
    {
      title: () => (
        <p className="font-semibold text-gray-500 text-[20px]">Total</p>
      ),
      dataIndex: categoryData[13],
      key: categoryData[13],
      render(text, record) {
        return {
          props: {
            style: { background: "#F1F1F" },
          },
          children: (
            <div className="font-bold text-gray-900 text-[20px]">{text}</div>
          ),
        };
      },
    },
  ];

  const { loading: gLoading, error: gError } = useQuery(
    GET_GENDER_CASTE_STATS,
    {
      variables: {
        where: {
          school_id: { _eq: getSchoolID() },
          class_id: { _eq: selectedClass?.value },
          division_id: { _eq: selectedDivision?.value },
          rte: { _eq: rteType?.value },
          religion: {
            _eq: selectedReligion?.value,
          },
        },
      },
      onCompleted: (classResponse) => {
        let resdata = classResponse?.school_category_data;
        setSchoolStats(resdata);
      },
    }
  );

  useEffect(() => {
    // creating a 2d array for size 5,13 for table data
    let genderCasteArr = new Array(5).fill().map(() => new Array(13).fill(0));

    genderCasteArr.forEach((item, rowIndex) => {
      item.forEach((cell, rowData) => {
        if (rowData === 0) {
          item[0] = genderList[rowIndex];
        } else {
          item[rowData] = 0;
        }
      });
    });

    schoolStats.forEach((stat) => {
      let category = stat.category
        ? stat.category.toLowerCase().replace(/\s+/g, "").replace("-", "")
        : stat.category;
      let totalCategoryCount = genderCasteArr[4];

      switch (stat.gender) {
        case "M":
          let categoryForMale = genderCasteArr[0];
          if (casteList.has(category)) {
            categoryForMale[casteList.get(category)] += stat.count;
            totalCategoryCount[casteList.get(category)] += stat.count;
            categoryForMale[12] += stat.count;
          } else {
            categoryForMale[11] += stat.count;
            totalCategoryCount[11] += stat.count;
            categoryForMale[12] += stat.count;
          }
          break;
        case "F":
          let categoryForFemale = genderCasteArr[1];
          if (casteList.has(category)) {
            categoryForFemale[casteList.get(category)] += stat.count;
            totalCategoryCount[casteList.get(category)] += stat.count;
            categoryForFemale[12] += stat.count;
          } else {
            categoryForFemale[11] += stat.count;
            totalCategoryCount[11] += stat.count;
            categoryForFemale[12] += stat.count;
          }
          break;
        case "T":
          let categoryForTrans = genderCasteArr[2];
          if (casteList.has(category)) {
            categoryForTrans[casteList.get(category)] += stat.count;
            totalCategoryCount[casteList.get(category)] += stat.count;
            categoryForTrans[12] += stat.count;
          } else {
            categoryForTrans[11] += stat.count;
            totalCategoryCount[11] += stat.count;
            categoryForTrans[12] += stat.count;
          }
          break;
        default:
          let categoryForNa = genderCasteArr[3];
          categoryForNa[11] += stat.count;
          totalCategoryCount[11] += stat.count;
          categoryForNa[12] += stat.count;
      }
    });

    let finalData = [];
    let totalCount = 0;
    genderCasteArr.forEach((data, idx) => {
      let tableData = {};
      categoryData.forEach((item, index) => {
        if (item === "total") totalCount += data[index - 1];
        if (item === "key") {
          tableData[item] = idx;
        } else {
          tableData[item] = data[index - 1];
        }
      });
      finalData.push(tableData);
    });

    let tempArr = finalData[4];
    tempArr.total = totalCount;
    setTableData(finalData);
  }, [schoolStats]);

  return (
    <div id="stats-table-container">
      <Table
        id="stats-table"
        className="stats-table pb-24"
        bordered
        columns={columns}
        pagination={false}
        dataSource={tableData}
        rowClassName={(record, index) =>
          record.gender === genderFilterData ? "bg-highlightYellow" : "bg-white"
        }
      />
    </div>
  );
}

StudentsStatsTable.prototypes = {
  categoryFilterData: PropTypes.string,
  genderFilterData: PropTypes.string,
  selectedClass: PropTypes.number,
  selectedDivision: PropTypes.number,
};
