/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";

const getTopicDetails = async (payload) => {
  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/performance/getInterestedTopics`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        studentId: parseInt(payload?.studentId, 10),
        [payload?.key]: parseInt(payload?.value, 10),
      },
    };
    const { data } = await axios.request(config);
    return data?.data;
  } catch (e) {
    console.error("useFetTopicDetails Error: ", { e: e.message });
    return null;
  }
};

export default function useGetTopicDetails(payload, options = {}) {
  return useQuery(
    ["topicDetails", payload?.studentId, payload?.key, payload?.value],
    () => getTopicDetails(payload),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
}
