import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spin, Pagination } from "antd";
import { GET_TEACHER_LIST_WITH_FILTER } from "../../views/teachersView/graphql";
import { GET_CLASSES_AND_DIVISIONS } from "../../views/studentsView/graphql";
import _ from "lodash";
import { getSchoolID } from "../../shared/getSchoolID";
import { getAvatar } from "../../shared/getAvatar";
import { ClassDivisionSelector, Dropdown } from "../../components";
import { sortDivisions } from "../../helpers";

const Badge = ({ type, hasLeftMargin }) => {
  if (type === "all")
    return (
      <div
        className={`w-2 h-2 text-base rounded-full text-white inline-block ${
          hasLeftMargin ? "ml-2" : "mr-2"
        }`}
      ></div>
    );

  return (
    <div
      className={`w-2 h-2 text-base rounded-full text-white inline-block  ${
        hasLeftMargin ? "ml-2" : "mr-2"
      } ${type === "present" ? "c-bg-green" : "c-bg-red"}`}
    ></div>
  );
};

const AvailabilityOptionsItem = ({ text, badgeType }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p style={{ marginRight: "2px" }}>{text}</p>
      <Badge type={badgeType} hasLeftMargin />
    </div>
  );
};

const TeacherList = ({
  limit = 10,
  setdraweropen,
  isPresent,
  teacherDataRefetch,
}) => {
  const [search, setSearch] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [classData, setClassData] = useState([]);
  const [totalTeacherCount, setTeacherCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [divisionData, setDivisionData] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [filterType, setFilterType] = useState("");
  const [filterTypeLabel, setFilterTypeLabel] = useState("");

  const availabilityOptions = [
    {
      label: <AvailabilityOptionsItem text={"All"} badgeType={"all"} />,
      value: "all",
    },
    {
      label: <AvailabilityOptionsItem text={"Present"} badgeType={"present"} />,
      value: "present",
    },
    {
      label: <AvailabilityOptionsItem text={"Absent"} badgeType={"absent"} />,
      value: "absent",
    },
  ];

  useEffect(() => {
    setTeacherList(teacherDataRefetch);
  }, [teacherDataRefetch]);

  const getFilters = () => {
    if (selectedClass === "All") {
      return {
        role_id: { _eq: 2 },
        school_id: { _eq: getSchoolID() },
        _and: [{ full_name: { _ilike: `%${search}%` } }],
      };
    }

    if (
      selectedClass !== null &&
      (selectedDivision === null || selectedDivision === "All")
    ) {
      return {
        role_id: { _eq: 2 },
        _and: [
          { is_active: { _eq: true } },
          {
            class_teachers_obj: {
              division: {
                class_id: { _eq: selectedClass },
              },
              is_active: { _eq: true },
            },
          },
          { full_name: { _ilike: `%${search}%` } },
        ],
        school_id: { _eq: getSchoolID() },
      };
    } else if (selectedClass !== null && selectedDivision !== null) {
      return {
        role_id: { _eq: 2 },
        _and: [
          { is_active: { _eq: true } },
          {
            class_teachers_obj: {
              division_id: { _eq: selectedDivision },
              is_active: { _eq: true },
            },
          },
          { full_name: { _ilike: `%${search}%` } },
        ],
        school_id: { _eq: getSchoolID() },
      };
    } else {
      return {
        role_id: { _eq: 2 },
        is_active: { _eq: true },
        school_id: { _eq: getSchoolID() },
        _and: [{ full_name: { _ilike: `%${search}%` } }],
      };
    }
  };

  const filterTeacherData = () => {
    if (filterType === "present") {
      return teacherList.filter(
        (teacher) => teacher?.teacher_attendance_data[0]?.is_present === true
      );
    }
    if (filterType === "absent") {
      return teacherList.filter(
        (teacher) =>
          teacher?.teacher_attendance_data[0]?.is_present === false ||
          teacher?.teacher_attendance_data?.length === 0
      );
    } else {
      return teacherList;
    }
  };

  const { loading, refetch: refetchTeachers } = useQuery(GET_TEACHER_LIST_WITH_FILTER, {
    variables: {
      filters: getFilters(),
      isPresent: isPresent,
      limit,
      offset: (currentPage - 1) * limit,
    },
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      const count = response.users_aggregate.aggregate.count;
      setTeacherList(response.users);
      setTeacherCount(count);
    },
  });

  useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    onCompleted: (classd) => {
      const classLists = [];
      const divList = [];
      classd?.classes.forEach((xx) => {
        classLists.push({
          value: xx.id,
          label: `Class: ${xx.class_name}`,
        });
        xx.divisions.forEach((divz) =>
          divList.push({
            value: divz.id,
            label: `Division: ${divz.division_name}`,
            class_id: xx.id,
          })
        );
      });
      setClassData(classLists);
      setDivisionData(divList);
    },
  });

  // Returns a string that contains classes, generated conditionally, that a teacher is class teacher of.
  const getClassListOfTeacher = (listData = []) => {
    const divisionList = listData?.map((data) => data?.division);
    const classesAndDivisionsList = sortDivisions(divisionList);
    let classList;
    if (selectedClass === "All" || selectedClass === null) {
      classList = classesAndDivisionsList.reduce(
        (acc, currentDivision, currentIndex, arr) => {
          if (currentIndex === arr.length - 1) {
            // Regex to remove all whitespace
            return acc + currentDivision?.division_name.replace(/\s/g, "");
          }
          return acc + currentDivision?.division_name.replace(/\s/g, "") + ", ";
        },
        "Class "
      );
    } else if (selectedDivision === null || selectedDivision === "All") {
      const requiredClassesObject = divisionData.filter(
        (classElement) => classElement.class_id === parseInt(selectedClass)
      );
      const requiredClasses = requiredClassesObject.map(
        (elem) => elem.label.split("Division: ")[1]
      );
      const requiredDivisionList = classesAndDivisionsList.filter(
        (division) => {
          const resultingClass = requiredClasses.find(
            (requiredClass) => requiredClass === division?.division_name
          );
          if (resultingClass === undefined) return false;
          return true;
        }
      );

      classList = requiredDivisionList.reduce(
        (acc, currentDivision, currentIndex, arr) => {
          if (currentIndex === arr.length - 1) {
            // Regex to remove all whitespace
            return acc + currentDivision?.division_name.replace(/\s/g, "");
          }
          return acc + currentDivision?.division_name.replace(/\s/g, "") + ", ";
        },
        "Class "
      );
    } else {
      const data = divisionData.find(
        (elem) => parseInt(elem.value) === parseInt(selectedDivision)
      );
      classList = "Class " + data.label.split("Division: ")[1];
    }
    return classList;
  };

  const handlePageChange = (page) => {
    const offset = (page - 1) * limit;
    setCurrentPage(page);
    refetchTeachers({
      offset,
    });
  };

  return (
    <div className="container w-full">
      <div className="container">
        <div className="py-8">
          <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full items-center">
            <div className="">
              <form
                className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className=" relative ">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    defaultValue={search}
                    type="text"
                    id='"form-subscribe-Filter'
                    className="pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by teacher name"
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        e.target.value.length > 3 ||
                        e.target.value.length === 0
                      ) {
                        setSearch(e.target.value);
                      }
                    }}
                  />
                </div>
              </form>
            </div>

            <div id="manageteachers-sort" className="flex gap-x-2">
              <Dropdown
                id="manageteachers-availability"
                value={filterTypeLabel}
                defaultValue={availabilityOptions?.[0]?.label}
                type="primary"
                options={availabilityOptions}
                onItemClick={(value) => {
                  setFilterType(value);
                  const filter = availabilityOptions.find(
                    (x) => x.value === value
                  );
                  setFilterTypeLabel(filter.label);

                  handlePageChange(1);
                }}
              />
              <ClassDivisionSelector
                id={"manageteachers-sortby"}
                classes={classData}
                divisions={divisionData}
                onClassChange={(classes) => {
                  handlePageChange(1);
                  setSelectedClass(classes);
                }}
                onDivisionChange={(divisions) => {
                  handlePageChange(1);
                  setSelectedDivision(divisions);
                }}
                allOptionNeeded
              />
            </div>
          </div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <Spin spinning={loading}>
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Contact Details
                      </th>
                      <th
                        scope="col"
                        className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm uppercase font-normal"
                      >
                        Last Updated
                      </th>
                      <th
                        scope="col"
                        className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-right text-sm uppercase font-normal"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterTeacherData()?.map((teacher, mockIndex) => {
                      const rowId = "manageteachers-teacher-" + mockIndex;
                      const detailsId =
                        "manageteachers-teacherdetails-" + mockIndex;

                      return (
                        <tr id={rowId} key={mockIndex}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center text-left">
                              <div className="flex flex-row ">
                                <div className="flex items-center justify-center">
                                  <Badge
                                    type={
                                      teacher?.teacher_attendance_data[0]
                                        ?.is_present === true
                                        ? "present"
                                        : "absent"
                                    }
                                  />
                                </div>
                                <div className="flex-shrink-0 ">
                                  {getAvatar(
                                    teacher?.first_name,
                                    teacher?.last_name,
                                    teacher?.image
                                  )}
                                </div>
                              </div>
                              <div className="ml-4">
                                <p className="text-black font-medium whitespace-no-wrap">
                                  {teacher?.first_name} {teacher?.middle_name}{" "}
                                  {teacher?.last_name}
                                </p>
                                <p className="text-gray-500">
                                  {_.isNil(
                                    teacher?.class_teachers[0]?.division
                                      ?.division_name
                                  )
                                    ? "—"
                                    : getClassListOfTeacher(
                                        teacher?.class_teachers
                                      )}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {_.isNil(teacher?.email) ? "" : teacher?.email}
                            </p>
                            <p className="text-gray-500">
                              {teacher?.mobile_number.slice(3)}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {teacher?.auditTrailsByUserId[0]?.userByAddedBy
                                ?.first_name || "Admin"}
                            </p>
                            <p className="text-gray-500">
                              {teacher?.auditTrailsByUserId?.length > 0
                                ? moment(
                                    teacher?.auditTrailsByUserId[0]?.updated_at
                                  ).format("DD MMM YYYY")
                                : moment(teacher?.created_at).format(
                                    "DD MMM YYYY"
                                  )}
                            </p>
                          </td>
                          <td
                            id={detailsId}
                            className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right"
                          >
                            <span
                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                setdraweropen(teacher?.id, true);
                              }}
                            >
                              Details
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Spin>
            </div>
          </div>
          <Pagination
            size="small"
            pageSize={limit}
            total={totalTeacherCount}
            current={currentPage}
            showSizeChanger={false}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TeacherList;