import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  GET_TEACHER_SUBJECTS,
  UPDATE_SUBJECT_TEACHER,
} from "../../views/classesView/graphql";
import { Select, notification } from "antd";
import { getSortedTeachers } from "../../helpers";
import useDeleteClassTeacher from "../../hooks/useDeleteClassTeacher";

const Badge = ({ type }) => {
  return (
    <button
      type="button"
      className={`w-2 h-2 text-base  rounded-full text-white mr-2 ${
        type === "present" ? "c-bg-green" : "c-bg-red"
      }`}
    ></button>
  );
};

const TeacherSelector = ({
  teacher,
  teachersList,
  subIndex,
  sub,
  divid,
  teacherSubjects,
}) => {
  const [selectedOpt, setSelected] = useState(null);
  const [classTeacherChanged, setclassTeacherChanged] = useState(false);

  useEffect(() => {
    setSelected(sub?.division_subjects[0]?.user?.auth_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [UpdateSubjectTeacher] = useMutation(UPDATE_SUBJECT_TEACHER, {
    refetchQueries: [
      { query: GET_TEACHER_SUBJECTS, variables: { divid: divid } },
    ],
  });

  const { mutateAsync: deleteClassTeacherAssigned } = useDeleteClassTeacher();

  const deleteCurrentTeacher = () => {
    deleteClassTeacherAssigned({
      subjectId: sub.id,
      divisionId: divid,
      teacherId: selectedOpt,
    }).then((data) => {
      setclassTeacherChanged(false);
    });
  };

  const onTeacherSave = () => {
    if(selectedOpt === null) {
      deleteCurrentTeacher();
      return;
    }
    UpdateSubjectTeacher({
      variables: {
        divid: parseInt(divid),
        teacherid: selectedOpt,
        subid: sub.id,
      },
    })
      .then((response) => {
        notification["success"]({
          message: "Success",
          description: "Teacher Assigned",
          duration: 1.5,
        });
        setclassTeacherChanged(false);
      })
      .catch((reserror) => console.log(reserror));
  };

  const checkonchange = (value) => {
    setclassTeacherChanged(true);
    setSelected(value);
  };

  const getType = (attData) => {
    if (attData) {
      return attData?.is_present;
    } else {
      return false;
    }
  };

  const sortedTeachersList = getSortedTeachers(teachersList, true);

  return (
    <div id="manageclasses-class-assignedto">
      <div className="flex flex-row items-end text-left">
        <Select
          value={selectedOpt}
          onChange={(value) => checkonchange(value)}
          className="customised-antd-selector"
          size="large"
          placeholder="Not Assigned"
          showSearch
          filterOption={(inputValue, option) =>
            option.children
              .join("")
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
        >
          {sortedTeachersList?.map((teach) => {
            return (
              <React.Fragment key={teach.id}>
                <Select.Option value={teach.id}>
                  {teach?.id ? (
                    <Badge
                      type={
                        getType(teach?.teacher_attendance_data[0])
                          ? "present"
                          : "absent"
                      }
                    />
                  ) : null}
                  {`${teach?.first_name || ""} ${teach?.middle_name || ""} ${
                    teach?.last_name || ""
                  }`}
                </Select.Option>
              </React.Fragment>
            );
          })}
        </Select>
        {classTeacherChanged ? (
          <button
            onClick={() => onTeacherSave()}
            className="ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white w-16 transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white"
          >
            Save
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default TeacherSelector;
