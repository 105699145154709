import React, { useState } from "react";
import { Pagination, Spin } from "antd";
import { Button } from "../../../components";
import { EyeIcon } from "@heroicons/react/solid";
import useFeeBalances from "../../../hooks/useFeeBalances";

function FeesBalancesTable({
  pageLimit,
  handleRemindParent,
  filterData = {},
  onViewCollection,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: balanceData, isLoading: isLoadingBalance } = useFeeBalances(
    {
      offset: pageLimit * (currentPage - 1),
      limit: pageLimit,
      ...filterData,
    },
    {
      refetchOnWindowFocus: false,
      enabled: filterData?.schoolId !== undefined,
    }
  );

  const totalCount = balanceData?.totalRows || 0;
  const feesBalancesData = balanceData?.students || [];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  return (
    <Spin spinning={isLoadingBalance}>
      <div className="container">
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mb-4">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                    >
                      Roll No.
                    </th>
                    <th
                      scope="col"
                      className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-20 text-left text-sm uppercase text-textGrey font-medium"
                    >
                      Student Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                    >
                      Fees Paid
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                    >
                      Remaining Fees
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                    >
                      Total Fees
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                    >
                      Payment Details
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-left text-sm uppercase text-textGrey font-medium"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(feesBalancesData) &&
                    feesBalancesData?.map((feesData, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-darkerGrey whitespace-no-wrap font-medium">
                              {feesData?.roll_no || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-darkerGrey whitespace-no-wrap font-medium">
                              {feesData?.student_full_name || ``}
                            </p>
                            <p className="text-textGrey">
                              {feesData?.division_name || ``}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-success whitespace-no-wrap">
                              {feesData?.paidAmountText || '0.00'}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-darkRed whitespace-no-wrap">
                              {feesData?.remainingAmountText || '0.00'}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="text-black whitespace-no-wrap">
                              {feesData?.totalAmountText || '0.00'}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <Button
                              id="download-all-students-data"
                              buttonStyle="primary"
                              className="py-3 px-4  bg-thaliumFlame"
                              onClick={() => onViewCollection(feesData)}
                            >
                              <EyeIcon className="h-4 w-4 mr-1" />
                              View
                            </Button>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <Button
                              buttonStyle="primary"
                              onClick={() => handleRemindParent(feesData)}
                              style={{ display: "inline" }}
                            >
                              <span className="flex justify-center items-center gap-x-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                Remind Parent
                              </span>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Pagination
              size="small"
              pageSize={pageLimit || 10}
              total={totalCount}
              current={currentPage}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default FeesBalancesTable;
