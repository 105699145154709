import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
//Custom Components
import Button from "../../../components/Button";
import FeeCollectionData from "./FeeCollectionData";
//Third-Party Components
import { Modal, Select, Radio, Spin, Space } from "antd";

//Cusotm Hooks
import useSearchStudentForCollection from "../../../hooks/useSearchStudentForCollection";
import useFeeCollectionStudent from "../../../hooks/useFeeCollectionStudent";
import useDeleteFeeCollection from "../../../hooks/useDeleteFeeCollection";
import useFilterFeeStructure from "../../../hooks/useFilterFeeStructure";
//Helper Functions
import { getSchoolID } from "../../../shared/getSchoolID";

//Third Party Hooks
import { useNavigate, useLocation } from "react-router-dom";
//Constants
import searchImage from "../../../assets/images/search.png";
import useTracking from "../../../hooks/useTracking";

const { Option } = Select;

function NewCollection() {
  const feeStructureRef = useRef();
  const feeCollectionRef = useRef();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { feesCollectionData, studentData } = state || {};
  const schoolId = getSchoolID();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitial, setIntial] = useState(false);
  const [formDetails, setFormDetails] = useState({
    studentType: "EXISTING",
  });
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const { data: currentCollectionStudent, isLoading: isLoadingCurrentStudent } =
    useFeeCollectionStudent(
      {
        feeCollectionId: feesCollectionData?.id,
      },
      {
        refetchOnWindowFocus: false,
        enabled: feesCollectionData?.id !== undefined,
        onSuccess: (data) => {
          feeCollectionRef.current.setStudentDetails(data);
        },
      },
    );
  const { mutateAsync: deleteFeeCollection } = useDeleteFeeCollection({
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const { trackEvent } = useTracking();

  //Student Search
  const [studentName, setStudentName] = useState(false);
  const {
    isLoading: isSearchingStudent,
    refetch: fetchStudents,
    data: searchedStudentList,
  } = useSearchStudentForCollection(
    {
      studentName,
      schoolId,
    },
    {
      onSuccess: () => {
        const id = searchedStudentList?.[0]?.studentId;
        if(studentData?.id === id && isInitial === false) {
          setIntial(true);
          setIsLoading(false);
          handleStudentChange(id);
          setFormDetails({
            ...formDetails,
            studentId: studentData?.id,
          });
        }
      },
      enabled: studentName?.length > 0,
    },
  );

  const handleStudentSearch = (studentName) => {
    setStudentName(studentName);
  };

  useEffect(() => {
    if (studentName?.length > 0) {
      const debouncer = setTimeout(() => {
        fetchStudents();
      }, 700);
      return () => clearTimeout(debouncer);
    }
  }, [studentName]);

  useEffect(() => {    
    if (studentData?.name?.length) {
      setIsLoading(true);
      handleStudentSearch(studentData?.name);
    }
  }, [studentData?.studentName]);

  const handleStudentChange = (id) => {
    const { studentId, divisionId, fullName, divisionName } =
      searchedStudentList.find((std) => std.studentId === id) || {};
    setSelectedStudent({ studentId, divisionId, divisionName });
    feeCollectionRef.current.setStudentDetails({
      studentName: fullName,
      className: divisionName,
    });
    feeStructureRef?.current?.setFilterMap({ divisionId });
    feeCollectionRef.current?.removeFeeStructure();
  };

  const handleConfirmDeletion = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleChangeStudentType = (e) => {
    const studentType = e.target.value;
    setFormDetails({
      ...formDetails,
      studentType,
      studentId: null,
    });
    if (studentType === "NEW") {
      setSelectedStudent({});
      feeStructureRef?.current?.setFilterMap({
        schoolId: getSchoolID(),
      });
    } else {
      feeStructureRef?.current?.setFilterMap(null);
    }
    feeCollectionRef.current?.removeFeeStructure();
  };

  const handleSelectFeeStructure = (feeStructureId) => {
    feeCollectionRef.current.setFeeStructure(feeStructureId);
  };

  const handleDeleteFeeCollection = async () => {
    setIsLoading(true);
    trackEvent("Delete Fee Collection");
    const response = await deleteFeeCollection({
      feeCollectionId: feesCollectionData?.id,
    });
    if (response?.success) {
      navigate(-1);
    }
  };

  const renderIllustration = () => {
    const visible = selectedStudent?.studentId !== undefined;
    if (visible) return null;
    return (
      <div className="flex flex-row">
        <div>
          <div style={{ marginLeft: "48px" }}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.0167 10.4354L6.0041 10.2746C6.6327 10.1723 6.947 10.1211 7.2548 10.2001C7.5625 10.2791 7.80959 10.4744 8.30369 10.865L9.0799 11.4785C11.2901 13.2255 12.3953 14.0991 13.1351 13.8291C13.875 13.5591 13.5731 12.3924 12.9694 10.0592L11.3124 3.65551C10.8384 1.8234 10.6013 0.907368 9.8435 0.712819C9.0856 0.51827 8.41239 1.2006 7.06609 2.56538L2.3602 7.33555C0.645596 9.07362 -0.211705 9.94267 0.293595 10.5323C0.798895 11.122 2.2048 10.8931 5.0167 10.4354Z"
                fill="#111827"
              />
            </svg>
          </div>
          <div style={{ marginLeft: 6 }}>
            <svg
              width="308"
              height="300"
              viewBox="0 0 308 383"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M306.124 376.25C175.282 399.489 -65.4634 357.07 18.2863 1.48419"
                stroke="#111827"
                stroke-width="2"
                stroke-linecap="round"
                stroke-dasharray="8 8"
              />
            </svg>
          </div>
          <p className="text-2xl font-bold" style={style.illustrationTxt}>
            Search Student
          </p>
          <p className="text-sm align-right" style={style.illustrationTxt}>
            To load fees structures
            <br />
            please select student{" "}
          </p>
        </div>
        <img
          alt="illustration"
          src={searchImage}
          style={{ width: 380, height: 400 }}
          className="mt-10"
        />
      </div>
    );
  };
  const renderSearchStudent = (student) => {
    return (
      <Option
        key={`${student.studentId}-${student.divisionId}`}
        value={student.studentId}
        label={student.displayName}
      >
        <Space>
          <span className="text-darkerGrey font-medium">
            {student.displayName}
            <span className="text-cancel"> {student.remainingFeeText}</span>
          </span>
        </Space>
      </Option>
    );
  };

  const renderCurrentStudent = () => {
    if (isLoadingCurrentStudent) {
      return (
        <div
          className="flex items-center justify-center"
          style={{ width: "100%", height: 100 }}
        >
          <Spin spinning />
        </div>
      );
    }
    return (
      <div className="border-b-2 pb-4">
        <div className="flex gap-x-8">
          <div className="flex flex-col items-start">
            <p>Student Name</p>
            <p className="font-semibold">
              {currentCollectionStudent?.studentName}
            </p>
          </div>
          <div className="flex flex-col items-start">
            <p>Class</p>
            <p className="font-semibold">
              {currentCollectionStudent?.className}
            </p>
          </div>
        </div>
        <div className="flex items-start gap-x-3 mt-5">
          <p className="font-semibold">
            Total fees: {currentCollectionStudent?.totalAmount} •
          </p>
          <p className="font-semibold text-darkRed">
            Remaining fees: {currentCollectionStudent?.remainingAmount}
          </p>
        </div>
        <Button
          buttonStyle="danger"
          className="h-30 mr-6 self-end absolute top-5 right-1"
          onClick={() => {
            handleConfirmDeletion();
          }}
        >
          Delete Collection
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
        <Spin spinning={isLoading}>
          <div className="flex flex-row gap-x-4 items-center mb-2 justify-between mr-7">
            <p className="text-2xl text-left font-bold flex-col m-0">
              {feesCollectionData
                ? "Edit Fees Collection"
                : "New Fees Collection"}
            </p>
            <Button
              id="attendancereport-back"
              buttonStyle="secondary"
              onClick={() => navigate(-1)}
            >
              Back to Fees Collection
            </Button>
          </div>
          <div className="relative bg-white rounded-lg pt-4 pl-8 pb-10 pr-8">
            <div className="flex flex-col border-opacity-10">
              <form className="flex flex-col gap-6 mt-2">
                {feesCollectionData && renderCurrentStudent()}
                {!feesCollectionData && (
                  <>
                    <div>
                      <Radio.Group
                        onChange={handleChangeStudentType}
                        value={formDetails.studentType}
                        style={{
                          display: "flex",
                          columnGap: "0.75rem",
                        }}
                      >
                        <div className="bg-white">
                          <Radio
                            value={"EXISTING"}
                            checked={formDetails.studentType === "EXISTING"}
                          >
                            <strong className={`font-medium`}>
                              Existing Student
                            </strong>
                          </Radio>
                        </div>
                        <div className="bg-white">
                          <Radio
                            value={"NEW"}
                            checked={formDetails.studentType === "NEW"}
                          >
                            <strong className={`font-medium`}>
                              New Student (Not Enrolled)
                            </strong>
                          </Radio>
                        </div>
                      </Radio.Group>
                    </div>
                    {formDetails?.studentType === "EXISTING" && (
                      <>
                        <div className="flex flex-col">
                          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                            Select Student
                          </label>
                          <Select
                            className="customised-antd-selector"
                            style={{
                              flex: 1,
                              width: "60%",
                              textAlign: "left",
                            }}
                            size="large"
                            showSearch
                            required
                            value={formDetails.studentId}
                            placeholder="Search by student name"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleStudentSearch}
                            onChange={(studentId) => {
                              setFormDetails({
                                ...formDetails,
                                studentId,
                              });
                              handleStudentChange(studentId);
                            }}
                            notFoundContent={
                              isSearchingStudent ? <Spin size="small" /> : null
                            }
                          >
                            {(searchedStudentList || []).map(
                              renderSearchStudent,
                            )}
                          </Select>
                        </div>
                        {renderIllustration()}
                      </>
                    )}
                    {formDetails?.studentType === "NEW" && (
                      <div className="flex gap-x-6 w-3/5">
                        <div className="flex flex-col w-full">
                          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                            Student Name
                          </label>
                          <input
                            type="text"
                            className="w-auto border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                            value={formDetails.studentName}
                            placeholder="Enter student’s full name here"
                            required
                            onChange={(e) => {
                              feeCollectionRef.current.setStudentDetails({
                                studentName: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                            Class
                          </label>
                          <input
                            type="text"
                            className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                            value={formDetails.class}
                            placeholder="Enter class"
                            required
                            onChange={(e) => {
                              feeCollectionRef.current.setStudentDetails({
                                className: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <StudentFeeStructure
                  ref={feeStructureRef}
                  divisionName={selectedStudent?.divisionName}
                  feeCollectionId={feesCollectionData?.id}
                  onSelectStructure={handleSelectFeeStructure}
                />
                <FeeCollectionData
                  ref={feeCollectionRef}
                  studentId={selectedStudent?.studentId}
                  divisionId={selectedStudent?.divisionId}
                  studentType={formDetails.studentType}
                  feeCollectionId={feesCollectionData?.id}
                  onSuccess={() => navigate(-1)}
                />
              </form>
            </div>
          </div>
        </Spin>
      </div>
      <Modal
        open={showConfirmDeleteModal}
        centered
        closable={false}
        bodyStyle={{
          justifyContent: "center",
          alignItems: "center",
          overflowX: "auto",
          textAlign: "center",
        }}
        footer={null}
      >
        <h3 className="text-3xl font-semibold">
          Are you sure you want to delete the fee collection?
        </h3>
        <div className="flex gap-x-10 justify-center mt-9">
          <Button
            buttonStyle="primary"
            onClick={handleDeleteFeeCollection}
            style={{
              background: "#EF4444",
              height: 40,
              width: 100,
            }}
          >
            <p className="font-bold">Yes</p>
          </Button>
          <Button
            buttonStyle="primary"
            onClick={() => {
              setShowConfirmDeleteModal(false);
            }}
            style={{
              background: "#22C55E",
              height: 40,
              width: 100,
            }}
          >
            <p className="font-bold">No</p>
          </Button>
        </div>
      </Modal>
    </>
  );
}

const StudentFeeStructure = forwardRef(
  ({ onSelectStructure, divisionName, feeCollectionId }, ref) => {
    useImperativeHandle(ref, () => ({
      setFilterMap: setFilterData,
    }));
    const defaultFilterMap = feeCollectionId ? { feeCollectionId } : null;
    const [currentStructureIdx, setCurrentStructure] = useState(0);
    const [filterMap, setFilterMap] = useState(defaultFilterMap);
    const { isLoading: isLoadingStructure, data: feeStructures } =
      useFilterFeeStructure(filterMap, {
        refetchOnWindowFocus: false,
        enabled: filterMap !== null,
      });

    useEffect(() => {
      if (onSelectStructure && feeStructures?.length > 0) {
        onSelectStructure(feeStructures[0].id);
      }
    }, [feeStructures]);

    const selectStructure = (selectedStructureId, index) => {
      if (onSelectStructure) onSelectStructure(selectedStructureId);
      setCurrentStructure(index);
    };

    const setFilterData = (map) => {
      setFilterMap(map);
      setCurrentStructure(0);
    };

    const renderFeeStructure = () => {
      if (isLoadingStructure) {
        return (
          <div
            className="flex justify-center items-center"
            style={{ width: 150, height: 80 }}
          >
            <Spin spinning />
          </div>
        );
      }
      if (feeStructures?.length === 0) {
        return (
          <p className="text-left font-semibold text-darkRed">
            No fees structure is applied to Class {divisionName}
          </p>
        );
      }
      return (
        <>
          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
            Select Structure
          </label>
          <div className="flex gap-x-3 gap-y-3 w-3/5 flex-wrap">
            {(feeStructures || []).map((structure, index) => {
              const selected = currentStructureIdx === index;
              const backgroundColor = selected ? "#F4F4FF" : "white";
              const borderColor = selected ? "#4F46E5" : "#9CA3AF";
              return (
                <div
                  onClick={() => selectStructure(structure.id, index)}
                  key={structure.id}
                  style={{
                    cursor: "pointer",
                    backgroundColor,
                    borderColor,
                    width: "32%",
                  }}
                  className={
                    "flex flex-col items-start border py-3 pl-3 rounded-lg"
                  }
                >
                  <p className="font-semibold">{structure.title}</p>
                  <p>{structure.academicYear.title}</p>
                </div>
              );
            })}
          </div>
        </>
      );
    };

    if (filterMap === null) return null;
    return <div className="flex flex-col">{renderFeeStructure()}</div>;
  },
);

const style = {
  illustrationTxt: {
    marginTop: 10,
    textAlign: "end",
    marginRight: 30,
  },
};

export default NewCollection;