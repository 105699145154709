import { Carousel } from "antd";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Button from "../Button/Button";

const contentStyle = {
  margin: 0,
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  height: 550,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const AttachmentGallery = ({ attachments }) => {
  return (
    <Carousel
      arrows
      prevArrow={
        <div className="bg-black text-black">
          <ChevronLeftIcon color="black" />
        </div>
      }
      nextArrow={
        <div className="bg-black text-black">
          <ChevronRightIcon color="black" />
        </div>
      }
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {attachments?.map((attachment) => {
        const endFile = attachment?.url?.split(".");
        const extn = endFile?.[endFile?.length - 1];

        if (extn === "pdf") {
          return (
            <div>
              <div style={{ ...contentStyle, flexDirection: "column" }}>
                <iframe
                  title={attachment.title}
                  src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${attachment.url}`}
                  className="w-full h-full"
                  type="application/pdf"
                  frameborder="0"
                  scrolling="no"
                  seamless=""
                />
                <Button
                  id="download-all-students-data"
                  buttonStyle="success"
                  className="py-3 px-4"
                  onClick={() => window.open(attachment.url)}
                >
                  Download PDF
                </Button>
              </div>
            </div>
          );
        }

        return (
          <div>
            <div style={contentStyle}>
              <img
                src={attachment.url}
                alt={`Expense Proof of ${attachment.title}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default AttachmentGallery;
