/* eslint-disable no-console */
import { notification } from "antd";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const deleteFeeCollection = async (payload) => {
  const config = {
  	validateStatus: () => true,
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/deleteFeeCollection`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  const response = res?.data;
  if (response?.success) {
    notification["success"]({
      message: "Fee Collection Deleted!",
      duration: 1,
    });
  } else if (response?.success === false) {
    throw new Error(response.message);
  } else {
    throw new Error("Please try again");
  }
  return response;
};

export default function useDeleteFeeCollection(options = {}) {
  return useMutation({
    mutationFn: (data) => deleteFeeCollection(data),    
    onError: (error) => {
      notification["error"]({
        message: error.message,
        description: "Unable to delete fee collection",
        duration: 1.5,
      });
    },
    ...options,
  });
}
