/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";

const getPerformanceSubjects = async (variables) => {
  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/performance/subjects`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: variables,
      validateStatus: () => true,
    };
    const { data } = await axios.request(config);
    if (data?.success) {
      return data?.data;
    }
    throw new Error(data?.message);
  } catch (e) {
    console.error({ e: e.message });
    return null;
  }
};

export default function usePerformanceSubjects(studentId, options = {}) {
  return useQuery(
    ["performanceSubjects", studentId],
    () =>
      getPerformanceSubjects({
        studentId,
      }),
    {
      enabled: studentId !== undefined,
      ...options,
    }
  );
}
