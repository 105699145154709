import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { Radio, Spin } from "antd";
import { numberToWords } from "../utils";
import {
  GET_STUDENTS,
  GET_STUDENT_DOCUMENT_DETAILS,
  UPDATE_STUDENT_AND_SCHOOL,
  ISSUE_DOCUMENT,
} from "../views/documentsView/graphql";
import { GET_ACADEMIC_YEAR } from "../views/settingsView/graphql";
import { getSchoolID } from "../shared/getSchoolID";
import NirgamUtaraView from "../views/documentsView/nirgamUtaraView";
import { useKeyPress } from "../utils/hooks";
import Button from "../components/Button";
import Tabs, { TabPane } from "rc-tabs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { GET_SCHOOL_DETAILS } from "../graphql/queries";
import { UPDATE_SCHOOL } from "../graphql/mutations";
import { LANGUAGE_CODES } from "../utils/constants";
import useTransliteration from "../hooks/useTransliteration";
import useTranslationAPI from "../hooks/useTranslation";
import { getLanguageInputCode } from "../utils";
import { PencilIcon } from "@heroicons/react/solid";
import useTracking from "../hooks/useTracking";

function NirgamUtara() {
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [activeTab, setActiveTab] = useState(1);
  const [isEditingFormat, setIsEditingFormat] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    LANGUAGE_CODES.ENGLISH
  );
  const [previousLanguage, setPreviousLanguage] = useState("en");
  const [isLoading, setIsLoading] = useState(false);
  const [documentSchoolData, setDocumentSchoolData] = useState(null);
  const [documentStudentData, setDocumentStudentData] = useState(null);
  const DOCUMENT_CODE = "nu";

  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  let navigate = useNavigate();
  const { i18n } = useTranslation();
  const { trackEvent } = useTracking();

  const [getTransliteratedData, { loading: isTransliterationLoading }] =
    useTransliteration();

  const [getTranslation, { loading: isTranslationLoading }] =
    useTranslationAPI();

  const handleLanguageChange = async (value, schoolData) => {
    setIsLoading(true);
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
    await translateSchoolData(value, schoolData);
    setIsLoading(false);
  };

  const translateSchoolData = async (targetLang, schoolData) => {
    let data = schoolData || documentSchoolData;
    const schoolLanguageData = data.settings?.translation?.[targetLang];
    if (schoolLanguageData) {
      data = {
        ...data,
        ...schoolLanguageData,
      };
      setDocumentSchoolData({
        ...documentSchoolData,
        ...data,
      });
    } else if (targetLang === LANGUAGE_CODES.ENGLISH) {
      // if no saved data for enlish lang and translating back to english, then use original data
      const school = dbSchoolData.schools_by_pk;
      let dbData = {
        image: school.image,
        trustName: school.trustName,
        schoolName: school.schoolName,
        cityName: school?.address?.city?.city_name,
        stateName: school?.address?.city?.district?.state?.state_name,
        udiseNumber: school.udiseNumber,
        schoolRegistrationNumber: school?.registrationNumber,
        mobileNumber: school?.mobileNumber,
        board: school?.boardName,
        schoolMedium: school?.medium,
        email: school?.email,
        settings: school?.settings,
      };
      setDocumentSchoolData({
        ...documentSchoolData,
        ...dbData,
      });
    } else {
      const { board, schoolMedium } = data;
      const translatedSchoolData = await getTranslation({
        textObj: {
          board,
          schoolMedium,
        },
        sourceLang: previousLanguage,
        targetLang: targetLang,
      });
      let transliterateTextData = {
        trustName: data?.trustName,
        schoolName: data?.schoolName,
        cityName: data?.cityName,
        stateName: data?.stateName,
      };
      const transliteratedSchoolData = await getTransliteratedData({
        textObj: transliterateTextData,
        inputCode: getLanguageInputCode(targetLang),
      });
      setDocumentSchoolData({
        ...documentSchoolData,
        ...data,
        ...translatedSchoolData,
        ...transliteratedSchoolData,
      });
    }
  };

  const translateStudentData = async (targetLang, studentData) => {
    setIsLoading(true);
    let data = studentData || documentStudentData;
    const hasStudentData = data?.studentName;
    if (!hasStudentData) {
      return;
    }
    const studentLanguageData = data?.additionalInfo?.translation?.[targetLang];
    if (studentLanguageData) {
      setDocumentStudentData({
        ...documentStudentData,
        ...studentLanguageData,
      });
    } else {
      const textObj = {
        caste: data?.caste,
        fatherOccupation: data?.fatherOccupation,
        motherTongue: data?.motherTongue,
        dateOfBirthInWords: data?.dateOfBirthInWords,
        admittedClass: data?.admittedClass,
        previousSchool: data?.previousSchool,
        previousSchoolClass: data?.previousSchoolClass,
        class: data?.class,
        reasonForTransfer: data?.reasonForTransfer,
        religion: data?.religion,
      };
      const translatedStudentData = await getTranslation({
        textObj,
        sourceLang: previousLanguage,
        targetLang: targetLang,
      });
      let transliterateTextData = {
        studentName: data?.studentName?.trim(),
        motherName: data?.motherName,
        fatherName: data?.fatherName,
      };
      const transliteratedData = await getTransliteratedData({
        textObj: transliterateTextData,
        inputCode: getLanguageInputCode(targetLang),
      });
      setDocumentStudentData({
        ...documentStudentData,
        ...data,
        ...translatedStudentData,
        ...transliteratedData,
      });
    }
    setIsLoading(false);
  };

  const { loading: schoolLoading, data: dbSchoolData } = useQuery(
    GET_SCHOOL_DETAILS,
    {
      variables: {
        schoolId: getSchoolID(),
      },
      onCompleted: (res) => {
        const school = res.schools_by_pk;
        let schoolData = {
          image: school.image,
          slogan: school.slogan,
          trustName: school.trustName,
          schoolName: school.schoolName,
          cityName: school?.address?.city?.city_name,
          schoolRegistrationNumber: school?.registrationNumber,
          stateName: school?.address?.city?.district?.state?.state_name,
          indexNumber: school.index_number,
          mobileNumber: school?.mobileNumber,
          board: school?.boardName,
          schoolMedium: school?.medium,
          email: school?.email,
          udiseNumber: school.udiseNumber,
          settings: school?.settings,
        };
        // get language from school settings documents
        let currentLanguage = LANGUAGE_CODES.ENGLISH;
        const documents = school?.settings?.documents;
        if (documents?.length) {
          // find document with code "nu"
          const nuDocument = documents.find(
            (document) => document.code === DOCUMENT_CODE
          );
          if (nuDocument) {
            currentLanguage = nuDocument.languageCode;
            setSelectedLanguage(nuDocument.languageCode);
            i18n.changeLanguage(nuDocument.languageCode);
          }
        }
        setDocumentSchoolData({
          ...documentSchoolData,
          ...schoolData,
        });
        // find language specific data in settings
        const languageData =
          schoolData.settings?.translation?.[currentLanguage];
        if (languageData) {
          schoolData = {
            ...schoolData,
            ...languageData,
          };
          setDocumentSchoolData({
            ...documentSchoolData,
            ...schoolData,
          });
        } else {
          if (currentLanguage !== LANGUAGE_CODES.ENGLISH) {
            translateSchoolData(currentLanguage, schoolData);
          }
        }
      },
    }
  );

  const { loading: studentsLoading, data: studentsData } = useQuery(
    GET_STUDENTS,
    {
      variables: {
        schoolId: getSchoolID(),
        name: `%${search}%`,
        documentType: "Nirgam Utara",
      },
    }
  );

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && downPress) {
      setCursor((prevState) =>
        prevState < students.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && enterPress) {
      handleSelectStudent(students[cursor]);
    }
  }, [cursor, enterPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && hovered) {
      setCursor(students.indexOf(hovered));
    }
  }, [hovered]);

  const {
    loading: academicYearLoading,
    error: academicYearError,
    data: academicYearData,
  } = useQuery(GET_ACADEMIC_YEAR, {
    variables: {
      schoolId: getSchoolID(),
    },
  });

  const [issueDocument] = useMutation(ISSUE_DOCUMENT, {
    refetchQueries: [
      {
        query: GET_STUDENT_DOCUMENT_DETAILS,
        variables: {
          studentId: documentStudentData?.studentId,
          documentType: "Nirgam Utara",
        },
      },
    ],
  });

  const [updateSchool] = useMutation(UPDATE_SCHOOL, {
    refetchQueries: [
      {
        query: GET_SCHOOL_DETAILS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const [
    getStudentDocumentDetails,
    { loading: docLoading, data: studentDocumentDetails },
  ] = useLazyQuery(GET_STUDENT_DOCUMENT_DETAILS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (docData) => {
      const student = docData?.students_by_pk;
      const dobDayWords = numberToWords(moment(student?.dob).format("D"));
      const dobMonthWords = moment(student?.dob).format("MMMM");
      const dobYearWords = numberToWords(moment(student?.dob).format("YYYY"));
      const issuedDoc = student?.documents_issuance_histories?.[0];
      const currentYear = moment().year();
      const schoolDocNumber = student?.school?.document_number + 1;
      const defaultSerialNumber = `${currentYear}${schoolDocNumber.toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 5,
          useGrouping: false,
        }
      )}NU`;
      let studentRef = student?.student_ref;
      // check for system generated student ref, make it blank if exists
      if (studentRef?.startsWith("GUID")) {
        studentRef = "";
      }
      let studentDocumentData = {
        studentId: student?.id,
        schoolDocumentNumber: student?.school?.document_number,
        serialNumber: issuedDoc?.document_number || defaultSerialNumber,
        studentRef: studentRef,
        uidNumber: student?.aadhaar,
        date: issuedDoc
          ? moment(issuedDoc?.issuance_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        studentName: student?.full_name,
        motherName: student?.mother_name,
        religion: student?.religion,
        fatherName: student?.father_name,
        fatherOccupation: student?.father_occupation,
        caste: student?.caste,
        generalRegisterNumber: student?.gr_no,
        motherTongue: student?.mother_tongue,
        dateOfAdmission: student?.enroll_date
          ? moment(student?.enroll_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        dateOfBirth: student?.dob
          ? moment(student?.dob).format("DD/MM/YYYY")
          : null,
        dateOfBirthInWords: `${
          dobDayWords || ""
        } ${dobMonthWords} ${dobYearWords}`,
        admittedClass: student?.admitted_class,
        previousSchool: student?.last_school,
        previousSchoolClass: student?.last_school_class,
        class: student?.division?.class?.class_name,
        transferDate: student?.transfer_date
          ? moment(student?.transfer_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        reasonForTransfer: student?.transfer_reason,
        image: student?.school?.image,
        isIssued: issuedDoc?.is_active,
        additionalInfo: student?.additional_info,
      };
      setDocumentStudentData({
        ...studentDocumentData,
      });
      let currentLanguage = "en";
      const nuDocument = documentSchoolData?.settings?.documents?.find(
        (document) => document.code === DOCUMENT_CODE
      );
      currentLanguage = nuDocument?.languageCode;
      const languageData =
        student.additional_info?.translation?.[currentLanguage];
      if (languageData) {
        studentDocumentData = {
          ...studentDocumentData,
          ...languageData,
          date: languageData.issuanceDate || studentDocumentData.date,
        };
        setDocumentStudentData({
          ...studentDocumentData,
        });
      } else {
        // translate data if language data is not available
        const docData = documentSchoolData?.settings?.documents?.find(
          (document) => document.code === DOCUMENT_CODE
        );
        // Translate document if language is not english
        if (docData?.languageCode !== LANGUAGE_CODES.ENGLISH) {
          translateStudentData(docData?.languageCode, studentDocumentData);
        }
      }
    },
  });

  const [updateStudentAndSchool] = useMutation(UPDATE_STUDENT_AND_SCHOOL, {
    refetchQueries: [
      {
        query: GET_SCHOOL_DETAILS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
      {
        query: GET_STUDENT_DOCUMENT_DETAILS,
        variables: {
          studentId: studentDocumentDetails?.students_by_pk?.id,
          documentType: "Nirgam Utara",
        },
      },
    ],
  });

  const handleSaveFormat = async (formData) => {
    trackEvent("Save Nirgam Utara Format");
    let updatedSettings = { ...documentSchoolData?.settings };
    const englishTemplate = "mh_nu_english";
    const marathiTemplate = "mh_nu_marathi";
    const languageInputCode = getLanguageInputCode(selectedLanguage);
    const hasDocument =
      updatedSettings.documents &&
      updatedSettings.documents.find((doc) => doc.code === "nu");
    if (!hasDocument) {
      updatedSettings.documents = [...updatedSettings.documents];
      setPreviousLanguage("en");
      updatedSettings.documents.push({
        name: "Nirgam Utara",
        code: DOCUMENT_CODE,
        language:
          selectedLanguage === LANGUAGE_CODES.ENGLISH ? "English" : "Marathi",
        languageCode:
          selectedLanguage === LANGUAGE_CODES.ENGLISH
            ? LANGUAGE_CODES.ENGLISH
            : LANGUAGE_CODES.MARATHI,
        googleInputCode: languageInputCode,
        template:
          selectedLanguage === LANGUAGE_CODES.ENGLISH
            ? englishTemplate
            : marathiTemplate,
      });
    } else {
      const documentIndex = updatedSettings.documents.findIndex(
        (document) => document.code === DOCUMENT_CODE
      );
      if (documentIndex > -1) {
        const documents = [...updatedSettings.documents];
        const document = { ...documents[documentIndex] };
        setPreviousLanguage(document.languageCode);
        document.language =
          selectedLanguage === LANGUAGE_CODES.ENGLISH ? "English" : "Marathi";
        document.languageCode =
          selectedLanguage === LANGUAGE_CODES.ENGLISH
            ? LANGUAGE_CODES.ENGLISH
            : LANGUAGE_CODES.MARATHI;
        document.googleInputCode = languageInputCode;
        document.template =
          selectedLanguage === LANGUAGE_CODES.ENGLISH
            ? englishTemplate
            : marathiTemplate;
        documents[documentIndex] = document;
        updatedSettings.documents = documents;
      }
    }
    // udpate translated data in settings
    const hasTranslationData = updatedSettings.translation;
    if (!hasTranslationData) {
      updatedSettings.translation = {};
    }
    const translationData = {
      ...updatedSettings.translation,
      [selectedLanguage]: {
        slogan: formData.slogan,
        trustName: formData.trustName,
        schoolName: formData.schoolName,
        cityName: formData.cityName,
        stateName: formData.stateName,
        schoolRegistrationNumber: formData.schoolRegistrationNumber,
        udiseNumber: formData.udiseNumber,
        indexNumber: formData.indexNumber,
        mobileNumber: formData.mobileNumber,
        board: formData.board,
        schoolMedium: formData.schoolMedium,
        email: formData.email,
      },
    };
    updatedSettings.translation = translationData;
    updateSchool({
      variables: {
        schoolId: getSchoolID(),
        schoolData: {
          settings: updatedSettings,
        },
      },
    });
    setIsEditingFormat(false);
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSelectStudent = (student) => {
    setSearch("");
    // query for student document details and set to formDetails when data is returned
    getStudentDocumentDetails({
      variables: {
        studentId: student.id,
        documentType: "Nirgam Utara",
      },
    });
  };

  const handleSave = async (docDetails) => {
    trackEvent("Save Nirgam Utara");
    let studentAdditionalInfo = {
      ...documentStudentData?.additionalInfo,
    };
    if (!studentAdditionalInfo.translation) {
      studentAdditionalInfo.translation = {};
    }
    const studentTranslationData = {
      ...studentAdditionalInfo.translation,
      [selectedLanguage]: {
        ...studentAdditionalInfo.translation?.[selectedLanguage],
        serialNumber: docDetails.serialNumber,
        generalRegisterNumber: docDetails.generalRegisterNumber,
        studentRef: docDetails.studentRef,
        uidNumber: docDetails?.uidNumber,
        studentName: docDetails.studentName,
        motherName: docDetails.motherName,
        fatherName: docDetails.fatherName,
        fatherOccupation: docDetails.fatherOccupation,
        caste: docDetails.caste,
        religion: docDetails.religion,
        motherTongue: docDetails.motherTongue,
        dateOfBirth: moment(docDetails.dateOfBirth, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        dateOfBirthInWords: docDetails?.dateOfBirthInWords,
        admittedClass: docDetails.admittedClass,
        previousSchool: docDetails.previousSchool,
        previousSchoolClass: docDetails.previousSchoolClass,
        transferDate: moment(docDetails.transferDate, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        dateOfAdmission: moment(
          docDetails.dateOfAdmission,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY"),
        class: docDetails.class,
        reasonForTransfer: docDetails.reasonForTransfer,
        issuanceDate: docDetails?.date || moment().format("DD/MM/YYYY"),
      },
    };
    studentAdditionalInfo.translation = studentTranslationData;
    let schoolSettings = { ...documentSchoolData?.settings };
    const hasTranslationData = schoolSettings.translation;
    if (!hasTranslationData) {
      schoolSettings.translation = {};
    }
    const schoolTranslationData = {
      ...schoolSettings.translation,
      [selectedLanguage]: {
        ...schoolSettings.translation?.[selectedLanguage],
        schoolRegistrationNumber: docDetails.schoolRegistrationNumber,
        udiseNumber: docDetails.udiseNumber,
        indexNumber: docDetails.indexNumber,
        mobileNumber: docDetails.mobileNumber,
        board: docDetails.board,
        schoolMedium: docDetails.schoolMedium,
        email: docDetails.email,
      },
    };
    schoolSettings.translation = schoolTranslationData;
    updateStudentAndSchool({
      variables: {
        studentId: docDetails.studentId,
        schoolId: getSchoolID(),
        studentData: {
          additional_info: studentAdditionalInfo,
        },
        schoolData: {
          settings: schoolSettings,
        },
      },
    });
    setIsEditing(false);
  };

  const handleIssueDocument = (docDetails) => {
    trackEvent("Issue Nirgam Utara");
    setIsEditing(false);
    const academicYearId = academicYearData?.acadamic_years?.[0]?.id;
    issueDocument({
      variables: {
        studentId: docDetails.studentId,
        documentNumber: `${docDetails.serialNumber}`,
        documentType: "Nirgam Utara",
        issuanceDate: docDetails?.date
          ? moment(docDetails?.date, "DD/MM/YYYY").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        academicYearId,
      },
    });
    // increment school document number
    updateStudentAndSchool({
      variables: {
        studentId: docDetails.studentId,
        schoolId: getSchoolID(),
        studentData: {},
        schoolData: {
          document_number: documentStudentData.schoolDocumentNumber + 1,
        },
      },
    });
  };

  const toggleEditFormat = () => {
    setIsEditingFormat(!isEditingFormat);
  };

  const handleCancelEditFormat = () => {
    toggleEditFormat();
    // reset language to default
    // check if language is english in school settings
    const nuDocument = documentSchoolData?.settings?.documents?.find(
      (document) => document.code === DOCUMENT_CODE
    );
    if (nuDocument?.languageCode === LANGUAGE_CODES.ENGLISH) {
      handleLanguageChange(LANGUAGE_CODES.ENGLISH);
    }
    if (nuDocument?.languageCode === LANGUAGE_CODES.MARATHI) {
      handleLanguageChange(LANGUAGE_CODES.MARATHI);
    }
  };

  // TODO: Create a common function for downloading documents
  const handleDownloadDocument = () => {
    setIsLoading(true);
    trackEvent("Download Nirgam Utara");
    // copy formatted data and replace with "-" string if it is null
    const formattedData = { ...documentSchoolData, ...documentStudentData };
    Object.keys(formattedData).forEach((key) => {
      if (formattedData[key] === null) {
        formattedData[key] = "";
      }
    });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getNirgamUtara`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: selectedLanguage === "en" ? "english" : "marathi",
        isDuplicate: !!formattedData?.isIssued,
        data: {
          ...formattedData,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${formattedData.studentName}_Nirgam_Utara`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleDownloadSampleDocument = async () => {
    trackEvent("Download Nirgam Utara Sample");
    const sampleData = {
      slogan: documentSchoolData.slogan,
      trustName: documentSchoolData?.trustName,
      schoolName: documentSchoolData?.schoolName,
      cityName: documentSchoolData?.cityName,
      stateName: documentSchoolData?.stateName,
      board: documentSchoolData?.board,
      udiseNumber: documentSchoolData.udiseNumber,
      indexNumber: documentSchoolData.indexNumber,
      schoolRegistrationNumber: documentSchoolData.schoolRegistrationNumber,
      mobileNumber: documentSchoolData.mobileNumber,
      schoolMedium: documentSchoolData.schoolMedium,
      email: documentSchoolData.email,
      serialNumber: "40000",
      generalRegisterNumber: "3000",
      date: "01/04/2022",
      studentName: "Student Name",
      motherName: "Mother Name",
      fatherName: "Father Name",
      fatherOccupation: "Father Occupation",
      caste: "Maratha",
      religion: "Hindu",
      motherTongue: "Hindi",
      dateOfBirth: "01/06/1990",
      dateOfBirthInWords: "One June One Thousand Nine Hundred Ninety",
      dateOfAdmission: "01/04/2021",
      admittedClass: "2A",
      previousSchool: "Sane Guruji sec.school Latur",
      previousSchoolClass: "1",
      transferDate: "01/04/2022",
      class: "2",
      reasonForTransfer: "Transfer to other school",
      image: documentSchoolData?.image,
    };
    let translatedSampleData = { ...sampleData };
    if (selectedLanguage !== "en") {
      const translatedData = await getTranslation({
        textObj: {
          ...sampleData,
        },
        sourceLang: previousLanguage,
        targetLang: selectedLanguage,
      });
      translatedSampleData = {
        ...sampleData,
        ...translatedData,
      };
    }
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getNirgamUtara`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: selectedLanguage === "en" ? "english" : "marathi",
        isDuplicate: false,
        data: {
          ...translatedSampleData,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `Sample_Nirgam_Utara`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const displayData = {
    ...documentSchoolData,
  };
  const schoolDataToDisplay = {
    trustName: displayData.trustName,
    schoolName: displayData.schoolName,
    cityName: displayData.cityName,
    stateName: displayData.stateName,
    udiseNumber: displayData.udiseNumber,
    schoolRegistrationNumber: displayData.schoolRegistrationNumber,
    mobileNumber: displayData.mobileNumber,
    board: displayData.board,
    schoolMedium: displayData.schoolMedium,
    email: displayData.email,
  };
  return (
    <div className="flex flex-col ml-6 pl-0 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-hidden">
      <div className="flex flex-row gap-x-4 items-center mb-6 justify-between p-4">
        <p className="text-2xl text-left font-bold flex-col m-0">
          Nirgam Utara
        </p>
        <Button
          id="documents-nirgam-back"
          buttonStyle="secondary"
          onClick={() => navigate(-1)}
        >
          Back to Documents
        </Button>
      </div>
      <Tabs
        activeKey={activeTab.toString()}
        onChange={(key) => {
          if (!isEditingFormat && !isEditing) {
            setActiveTab(Number(key));
            if (key === "2") {
              setDocumentStudentData(null);
              setSearch(null);
            }
          }
        }}
        className="custom-tabs overflow-y-auto px-4"
        animated={{ inkBar: true, tabPane: false }}
      >
        <TabPane
          tab="Issue Nirgam Utara"
          key={1}
          className="relative mt-4 mb-6"
        >
          <div className="relative mr-7 h-full">
            {!isEditing && (
              <div className="relative">
                <p className="text-left font-semibold text-black">
                  Select a student
                </p>
                <div className="flex relative mt-2">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-4">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    className="pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by student name"
                    autoComplete="off"
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        e.target.value.length >= 2 ||
                        e.target.value.length === 0
                      ) {
                        setSearch(e.target.value);
                      }
                    }}
                  />
                  <Button
                    buttonStyle="success"
                    className="ml-6"
                    onClick={handleDownloadSampleDocument}
                  >
                    Download Sample Document
                  </Button>
                </div>
                {studentsData && search && (
                  <div className="bg-white absolute z-30 w-11/12 mt-2 border border-gray-300 rounded-md">
                    {studentsData.students &&
                      studentsData?.students?.map((student, index) => {
                        const isActive = index === cursor;
                        return (
                          <button
                            className={`flex flex-col px-9 py-2 items-start justify-center border-b w-full text-black font-bold  ${
                              isActive && "bg-gray-200"
                            }`}
                            key={student.id}
                            onClick={() => {
                              handleSelectStudent(student);
                            }}
                            onMouseEnter={() => {
                              setHovered(student);
                            }}
                            onMouseLeave={() => {
                              setHovered(null);
                            }}
                          >
                            {`${student.full_name} • ${
                              student?.division?.division_name || "NA"
                            } • ${student.mother_name || "-"} • ${
                              student.dob
                                ? moment(student.dob).format("DD MMM YYYY")
                                : "NA"
                            } • UID no.${student.aadhaar}`}
                          </button>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
            <div className="flex my-3 gap-x-9">
              <p className="text-black font">
                Selected student :{" "}
                <strong className="text-black font-semibold">
                  {documentStudentData?.studentName || "-"}
                </strong>
              </p>
            </div>
            <Spin spinning={docLoading || isLoading}>
              <NirgamUtaraView
                isMasked={studentsData?.students?.length > 0 && search}
                isEditing={isEditing}
                isEditable={documentStudentData}
                languageInputCode={getLanguageInputCode(selectedLanguage)}
                data={{
                  ...documentSchoolData,
                  ...documentStudentData,
                }}
                translatedData={{
                  ...documentSchoolData,
                  ...documentStudentData,
                }}
                onCancel={toggleEdit}
                onEdit={toggleEdit}
                onSave={handleSave}
                getTranslation={getTranslation}
                onIssueDocument={handleIssueDocument}
                selectedLanguage={selectedLanguage}
                onDownloadDocument={handleDownloadDocument}
                containerStyle={{
                  fontFamily:
                    selectedLanguage === LANGUAGE_CODES.ENGLISH ? "" : "Mukta",
                }}
              />
            </Spin>
          </div>
        </TabPane>
        <TabPane
          tab="Create your own format"
          key={2}
          className="relative mt-4 mb-6"
        >
          <div className="flex justify-between">
            <div className="text-left">
              <label className="font-semibold text-black">
                Select Language
              </label>
              <div className="flex gap-x-3 mt-1">
                <Radio.Group
                  onChange={(e) => {
                    handleLanguageChange(e.target.value);
                  }}
                  value={selectedLanguage}
                  style={{
                    display: "flex",
                    columnGap: "0.75rem",
                  }}
                  disabled={!isEditingFormat}
                >
                  <div
                    id="documents-nirgam-edit-english"
                    className="bg-white py-2 px-3 rounded-lg shadow-md"
                  >
                    <Radio
                      value={LANGUAGE_CODES.ENGLISH}
                      checked={selectedLanguage === LANGUAGE_CODES.ENGLISH}
                    >
                      <strong
                        className={`font-semibold ${
                          selectedLanguage === LANGUAGE_CODES.ENGLISH
                            ? "text-black"
                            : "text-gray-600"
                        } ${!isEditingFormat && "opacity-50"}`}
                      >
                        English
                      </strong>
                    </Radio>
                  </div>
                  <div
                    id="documents-nirgam-edit-marathi"
                    className="bg-white py-2 px-3 rounded-lg shadow-md"
                  >
                    <Radio
                      value={LANGUAGE_CODES.MARATHI}
                      checked={selectedLanguage === LANGUAGE_CODES.MARATHI}
                    >
                      <strong
                        className={`font-semibold ${
                          selectedLanguage === LANGUAGE_CODES.MARATHI
                            ? "text-black"
                            : "text-gray-600"
                        } ${!isEditingFormat && "opacity-50"}`}
                      >
                        Marathi
                      </strong>
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
              {!isEditingFormat && (
                <p className="mt-2 text-cancel font-semibold">
                  Please click on the edit format to change the school details
                  or the transfer certificate language.
                </p>
              )}
              {isEditingFormat && (
                <p className="mt-2 text-cancel font-semibold">
                  Please select the language for the transfer certificate.
                </p>
              )}
            </div>
            <Button
              id="documents-transfer-edit-format-1"
              buttonStyle="primary"
              className="h-12 mr-6"
              onClick={() => {
                toggleEditFormat();
              }}
            >
              <PencilIcon className="h-4 w-4" aria-hidden="true" />
              <span className="ml-2">Edit Format</span>
            </Button>
          </div>
          <Spin spinning={docLoading || isLoading}>
            <NirgamUtaraView
              isMasked={studentsData?.students?.length > 0 && search}
              isEditing={isEditingFormat}
              languageInputCode={getLanguageInputCode(selectedLanguage)}
              data={documentSchoolData}
              translatedData={schoolDataToDisplay}
              getTranslation={getTranslation}
              selectedLanguage={selectedLanguage}
              onCancel={handleCancelEditFormat}
              onEdit={toggleEditFormat}
              onSave={handleSaveFormat}
              onDownloadDocument={handleDownloadDocument}
              isEditFormat
              hideDownload
              containerStyle={{
                marginTop: "25px",
                fontFamily:
                  selectedLanguage === LANGUAGE_CODES.ENGLISH ? "" : "Mukta",
              }}
            />
          </Spin>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default NirgamUtara;
