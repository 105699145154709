/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const { REACT_APP_NODE_ENDPOINT } = process.env;

const downloadForm = async (variables) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${REACT_APP_NODE_ENDPOINT}/docs/getAdmissionForm`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      data: variables,
    };
    const { data } = await axios.request(config);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.setAttribute("download", `AdmissionForm.pdf`);
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (e) {
    console.error("Unable to download admission form: ", { e: e.message });
    return null;
  }
};

export default function useDownloadAdmissionForm(
  { medium, schoolId, studentDetails = false },
  options = {}
) {
  return useQuery(
    ["admissionForm", medium, studentDetails],
    () => downloadForm({ medium, schoolId, studentDetails }),
    {
      ...options,
      enabled: medium?.length > 0,
    }
  );
}