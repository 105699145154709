import { useMemo, useState } from "react";
import { Spin, DatePicker, Dropdown } from "antd";
import { Button } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { DownloadIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { CalendarOutlined } from "@ant-design/icons";
import StaffTable from "./StaffTable";
import StaffDrawer from "./StaffDrawer";
import { DATE_FORMATS, STAFF_TYPE } from "../../../utils/constants";
import useStaffReport from "../../../hooks/useStaffReport";
import useDownloadStaffReport from "../../../hooks/useDownloadStaffReport";
import { getSchoolID } from "../../../shared/getSchoolID";

const PAGE_SIZE = 7;

const { MonthPicker } = DatePicker;

const staffOptionsMenu = [
  {
    key: STAFF_TYPE.BOTH,
    label: "Staff type: All",
  },
  {
    key: STAFF_TYPE.TEACHING,
    label: "Staff type: Teacher",
  },
  {
    key: STAFF_TYPE.NON_TEACHING,
    label: "Staff type: Non-Teaching",
  },
];

const getStaffTypeArray = (selectedStaffType) => {
  const staffType = [];
  switch (true) {
    case selectedStaffType === STAFF_TYPE.BOTH:
      staffType.push(STAFF_TYPE.TEACHING);
      staffType.push(STAFF_TYPE.NON_TEACHING);
      break;
    case selectedStaffType === STAFF_TYPE.TEACHING:
      staffType.push(STAFF_TYPE.TEACHING);
      break;
    case selectedStaffType === STAFF_TYPE.NON_TEACHING:
      staffType.push(STAFF_TYPE.NON_TEACHING);
      break;
    default:
      return null;
  }
  return staffType;
};

const StaffReport = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [selectedStaffType, setSelectedStaffType] = useState(
    staffOptionsMenu?.[0]
  );
  const [drawerData, setDrawerData] = useState({
    isOpen: false,
    authId: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const offset = (currentPage - 1) * PAGE_SIZE;

  const staffType = useMemo(
    () => getStaffTypeArray(selectedStaffType?.key),
    [selectedStaffType?.key]
  );

  const monthText = selectedMonth.format(DATE_FORMATS.MMMMYYYY);

  const { data } = useStaffReport({
    month: monthText,
    staffType,
    limit: PAGE_SIZE,
    offset,
  });

  const { mutateAsync: downloadStaffAttendance } = useDownloadStaffReport();

  const handleReportDownload = async () => {
    setIsDownloading(true);
    const schoolId = getSchoolID();
    await downloadStaffAttendance({
      type: "month",
      value: monthText,
      schoolIds: [schoolId],
    });
    setIsDownloading(false);
  };

  const totalStaff = data?.total || 0;
  const staffData = data?.staffList || [];

  const isLoading = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const backText = state?.backText || "Reports";

  const handleStaffDropdownClick = ({ key }) => {
    const staffTypeSelected = staffOptionsMenu.find(
      (staffType) => staffType?.key === key
    );
    setSelectedStaffType(staffTypeSelected);
    setCurrentPage(1);
  };

  return (
    <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={isLoading || isDownloading}>
        <div className="flex flex-col px-8 py-3 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
          <div className="flex flex-row gap-x-4 items-center mb-2 justify-between mr-7">
            <p className="text-2xl text-left font-bold flex-col m-0">
              Staff Attendance Report
            </p>
            <Button
              id="staffreport-back"
              buttonStyle="secondary"
              onClick={() => navigate(-1)}
            >
              Back to {backText}
            </Button>
          </div>
          <div className="relative mr-7">
            <div className="flex justify-between mt-4">
              <div className="flex gap-x-3">
                <Dropdown
                  menu={{
                    items: staffOptionsMenu,
                    onClick: handleStaffDropdownClick,
                  }}
                >
                  <button
                    id="attendancereport-download"
                    className="py-3 px-4 flex justify-center items-center whitespace-nowrap bg-primary text-white transition ease-in duration-197 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ant-dropdown-trigger"
                  >
                    <p>{selectedStaffType?.label}</p>
                    <ChevronDownIcon
                      className="h-4 w-5 ml-4"
                      aria-hidden="true"
                    />
                  </button>
                </Dropdown>
              </div>
              <div className="flex h-10">
                <MonthPicker
                  id="attendacereport-month"
                  value={selectedMonth}
                  onChange={(date) => {
                    setSelectedMonth(date);
                  }}
                  format="MMMM YYYY"
                  allowClear={false}
                  style={{
                    borderRadius: "6px",
                  }}
                  suffixIcon={<CalendarOutlined style={{ color: "#111827" }} />}
                />
                <button
                  id="attendancereport-download"
                  className="py-3 px-4 ml-4 flex justify-center items-center whitespace-nowrap bg-success  focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-197 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ant-dropdown-trigger"
                  onClick={handleReportDownload}
                >
                  <DownloadIcon className="h-6 w-5 mr-2" />
                  Download
                </button>
              </div>
            </div>
            <StaffTable
              staffData={staffData}
              totalStaff={totalStaff}
              pageLimit={PAGE_SIZE}
              setDrawer={setDrawerData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              month={selectedMonth}
            />
          </div>
        </div>
        <StaffDrawer
          {...drawerData}
          setIsOpen={setDrawerData}
          selectedMonth={selectedMonth}
        />
      </Spin>
    </div>
  );
};

export default StaffReport;
