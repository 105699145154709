import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Calendar as AntDCalendar } from "antd";
import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";

function Calendar({
  events,
  onDateChange,
  isLoading,
  onEventClick,
  onSelectDate,
}) {
  const [selectedMonth, setSelectedMonth] = useState(moment());

  useEffect(() => {
    onDateChange(selectedMonth);
  }, [selectedMonth, onDateChange]);

  return (
    <div>
      <div className="flex justify-between mb-3">
        <p className="flex items-center px-4 text-center font-bold text-indigo-700 text-lg mt-4">
          {selectedMonth.format("MMMM YYYY")}
        </p>
        <div className="flex shadow-sm">
          <button
            id="managecalendar-previousmonth"
            className="bg-white py-3 px-4 rounded-tl-md rounded-bl-md font-medium border border-gray-300"
            onClick={() => {
              // set previous month
              const previousMonth = moment(selectedMonth).subtract(1, "month");
              setSelectedMonth(previousMonth);
            }}
          >
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            id="managecalendar-nextmonth"
            className="bg-white py-3 px-4 rounded-tr-md rounded-br-md font-medium border border-gray-300"
            onClick={() => {
              // set next month
              const nextMonth = moment(selectedMonth).add(1, "month");
              setSelectedMonth(nextMonth);
            }}
          >
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <AntDCalendar
          value={selectedMonth}
          onSelect={onSelectDate}
          headerRender={() => <></>}
          className="rounded-lg"
          disabledDate={(current) => {
            // previous and next month
            return current && current.month() !== selectedMonth.month();
          }}
          locale={{
            lang: {
              locale: "en",
              dayFormat: moment.updateLocale("en", {
                weekdaysMin: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
              }),
            },
          }}
          dateCellRender={(value) => {
            // check if value is sunday
            let isSunday = value.day() === 0;
            // get all events in the start and end date range
            let eventsForDay = events.filter(
              (event) =>
                moment(event.start_at).isSameOrBefore(value, "day") &&
                moment(event.end_at).isSameOrAfter(value, "day")
            );
            // check if some event for the day is holiday
            let isHoliday = eventsForDay.some((event) => {
              return event.holiday;
            });

            const showAsHoliday = isSunday || isHoliday;

            // return null if date is not current month
            if (value.month() !== selectedMonth.month()) {
              return null;
            }

            let titleArray = [];
            let holidayTitleArray = [];

            eventsForDay.forEach((item) => {
              let time = parseInt(item.start_at.split(":", 3)[0].split("T")[1]);
              let hour, amOrPm;
              if (time > 12) {
                hour = time - 12;
                amOrPm = "pm";
              } else {
                amOrPm = "am";
                if (time === 0) {
                  hour = 12;
                } else {
                  hour = time;
                }
              }
              titleArray.push(`${hour + amOrPm} ${item.title}`);
              holidayTitleArray.push(`${item.title}`); 
            });

            const normalEvents = titleArray.map((item) => (
              <div style={{ width: 150 }} className="event-pop" key={item}>
                <span className="text-gray-400">{item.split(" ")[0]}</span>
                &nbsp;{item.split(" ")[1]}
              </div>
            ));

            const styles = {
              dateStyle:{
                color: showAsHoliday ? "#F765A3" :"#000000" ,
                fontSize:12,
                fontWeight:400
              }
            };

            const cardEvent = eventsForDay.slice(0, 2);

            const eventsPerDay = cardEvent.map((event,index) => {
              let eventTime = parseInt(event.start_at.split(":", 3)[0].split("T")[1]);
              let eventTitle = event.title;
              const eventId="event-"+index;

              return (
                <button
                  id={eventId}
                  style={{ display: "block" }}
                  onClick={() => onEventClick(event)}
                >
                  {event.holiday || isSunday ? (
                    <div className="holiday-day-title">{eventTitle}</div>
                  ) : (
                    <div className="event-day-title" key={event.id}>
                      <span className="">
                        {eventTime > 12 ? eventTime - 12 : eventTime === 0 ? 12 : eventTime}
                        {eventTime > 11 ? "pm " : "am "}
                      </span>{" "}
                      &nbsp;{eventTitle}
                    </div>
                  )}
                </button>
              );
            });

            return (
              <>
                <div style={styles.dateStyle}>
                  <span>{value.date()}</span>
                </div>
                {eventsPerDay}
                <Tooltip
                  placement="top"
                  color="white"
                  title={normalEvents}
                  style={{ marginBottom: 10 }}
                >
                  {eventsForDay.length > 2 ? (
                    <p style={{ fontSize: 10, fontWeight: 600 }}>
                      {eventsForDay.length - 2} more...
                    </p>
                  ) : (
                    <p></p>
                  )}
                </Tooltip>
              </>
            );
          }}
        />
      )}
    </div>
  );
}

export default Calendar;
