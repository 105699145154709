import React, { useState } from "react";
import { Spin, notification } from "antd";
import FormSection from "./FormSection";
import useUpdateSchoolAdmissionForm from "../../hooks/useUpdateSchoolAdmissionForm";
import useGetSchoolAdmissionForm from "../../hooks/useGetSchoolAdmissionForm";
import { getSchoolID } from "../../shared/getSchoolID";

const FormSettings = () => {
	const schoolId = getSchoolID();
	const [isSaving, setIsSaving] = useState(false);
	const [currentFormSettings, setFormSettings] = useState([]);
	const [currentSectionIndex, setCurrentSectionIndex] = useState(null);
	const [currentSectionBackup, setCurrentSectionBackup] = useState(null);
	const { isLoading } = useGetSchoolAdmissionForm(
		{
			schoolId,
		},
		{
			onSuccess: (data) => {
				setFormSettings(data);
				editSection(data[0], 0);
			},
		}
	);
	const { mutateAsync: updateSchoolAdmissionForm } =
		useUpdateSchoolAdmissionForm();

	const editSection = (bk, idx) => {
		if (currentSectionIndex != null && currentSectionBackup) {
			const revertSettings = [...currentFormSettings];
			revertSettings[currentSectionIndex] = currentSectionBackup;
			setFormSettings(revertSettings);
		}
		setCurrentSectionBackup(bk);
		setCurrentSectionIndex(idx);
	};

	const cancelEdit = () => {
		if (currentSectionIndex != null && currentSectionBackup) {
			const revertSettings = [...currentFormSettings];
			revertSettings[currentSectionIndex] = currentSectionBackup;
			setFormSettings(revertSettings);
		}

		setCurrentSectionIndex(null);
		setCurrentSectionBackup(null);
	};

	const errorNotify = (m) => {
		notification["error"]({
			message: "Error!",
			description: m,
			duration: 1.5,
		});
	};

	const handleSave = async () => {
		try {
			const currentSection = currentFormSettings[currentSectionIndex];
			if (!currentSection?.sectionTitle?.length) {
				errorNotify("Section title is required");
				return;
			}
			let errorText = null;
			const newFields = [];
			currentSection.fields.forEach((f, idx) => {
				if (!f?.label?.length) {
					errorText = "Label for all fields are not set";
					return true;
				}
				newFields.push({
					...f,
					si: idx
				});
			});
			if (errorText) {
				errorNotify(errorText);
				return;
			}
			setIsSaving(true);
			// Adjusted for si: Sorting index
			const finalSettings = [...currentFormSettings];
			finalSettings[currentSectionIndex].fields = newFields;

			await updateSchoolAdmissionForm({
				schoolId,
				admissionForm: finalSettings,
			});
		} catch (err) {
			console.error(err);
			errorNotify("Please refresh page and try again!");
		} finally {
			setIsSaving(false);
		}
	};

	const updateTitle = (newTitle, sectionIdx) => {
		const newSettings = [...currentFormSettings];
		newSettings[sectionIdx].sectionTitle = newTitle;
		setFormSettings(newSettings);
	};

	const handleSort = (sorted, sectionIdx) => {
		const newSettings = [...currentFormSettings];
		newSettings[sectionIdx].fields = sorted;
		console.log(sorted);
		setFormSettings(newSettings);
	};

	const updateField = (newValue, fIdx, sectionIdx, key = "label") => {
		const newSettings = [...currentFormSettings];
		const newSection = newSettings[sectionIdx];
		const fields = [...newSection.fields];
		fields[fIdx][key] = newValue;
		newSection.fields = fields;
		newSettings[sectionIdx] = newSection;
		setFormSettings(newSettings);
	};

	const renderFormSection = (section, sectionIdx) => {
		return (
			<FormSection
				data={section}
				isEditable={sectionIdx === currentSectionIndex}
				onEditSection={() => editSection(section, sectionIdx)}
				onTitleChange={(v) => updateTitle(v, sectionIdx)}
				onFieldChange={(v, fidx, key) =>
					updateField(v, fidx, sectionIdx, key)
				}
				onSave={handleSave}
				onCancel={cancelEdit}
				key={sectionIdx}
				onSort={(sorted) => handleSort(sorted, sectionIdx)}
			/>
		);
	};
	return (
		<div className="flex flex-col items-start ml-8 mt-6 pr-4 pb-20 h-screen w-screen overflow-y-scroll relative">
			<div className="flex flex-row items-center">
				<h2 className="text-2xl text-left font-bold flex-col m-0 mr-5">
					Admission Form Settings
				</h2>
			</div>
			<Spin spinning={isLoading || isSaving} wrapperClassName="w-full">
				{currentFormSettings.map(renderFormSection)}
			</Spin>
		</div>
	);
};

export default FormSettings;