import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const updateEnquiryStatus = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_NODE_ENDPOINT}/admission/updateEnquiryStatus`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpdateEnquiryStatus(options = {}) {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries({ queryKey: ["getAdmissionEnquires"] });
  return useMutation({
    mutationFn: (data) => updateEnquiryStatus(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success!",
        description: "Enquiry Status updated sucessfully",
        duration: 1.5,
      });
    },
    onError: () => {
      notification["error"]({
        message: "Error!",
        description: "Unable to update enquiry status",
        duration: 1.5,
      });
    },
    ...options,
  });
}