import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import AttendanceCard from "./AttendanceCard";
import { InfoCircleFilled } from "@ant-design/icons";
import TeachersOnLeaveCard from "./TeachersOnLeaveCard";

export default function TodaysStat({
  leaveCount,
  staffCount,
  staffOnLeave,
  divisionsData,
  isTeacherAttendanceTaken,
}) {
  const navigate = useNavigate();
  return (
    <>
      <p className="text-sm w-full text-left font-bold">Today's Statistics</p>
      <div className=" grid grid-cols-2 gap-5 pt-2 pr-2 pl-2 md:pt-0 md:pr-0 md:pl-0">
        <div>
          <div className="flex flex-row justify-between items-center w-full justify-items-start mb-2">
            <p className="text-left font-medium">
              Attendance - Yet to be marked
            </p>
            <Button
              id="dashboard-attendance-viewall"
              buttonStyle="primary"
              className="ml-5 py-1 px-3 h-6 w-16 text-xs font-medium"
              style={{
                fontSize: "0.75rem",
              }}
              onClick={() => navigate("/attendance")}
            >
              View all
            </Button>
          </div>
          <AttendanceCard divisionsData={divisionsData} />
        </div>
        <div>
          <div className="flex flex-row justify-between items-center w-full justify-items-start mb-2">
            <p className="text-left font-medium">
              Staff on leave • {leaveCount} of {staffCount}
            </p>
            {isTeacherAttendanceTaken === false && (
              <div className="flex items-center bg-backgroundGrey">
                <InfoCircleFilled
                  style={{
                    color: "#EB5757",
                    fontSize: "16px",
                    marginRight: 4,
                  }}
                />
                <p className="text-cancel">Please mark today’s attendance</p>
              </div>
            )}
            <span className=" text-left">
              <Button
                id="dashboard-teachers-viewavailability"
                buttonStyle="primary"
                className="ml-5 py-1 px-3 h-6 w-28 text-xs font-medium"
                style={{
                  fontSize: "0.75rem",
                }}
                onClick={() => navigate(`/teacher/availability`)}
              >
                View attendance
              </Button>
            </span>
          </div>
          <TeachersOnLeaveCard teachersOnLeave={staffOnLeave} />
        </div>
      </div>
    </>
  );
}
