import { gql } from "@apollo/client";
export const GET_TEACHER_LIST = gql`
  query getTeacherList {
    users(where: { role_id: { _eq: 2 } }) {
      id
      first_name
      middle_name
      last_name
      mobile_number
      created_at
      class_teachers_obj {
        division {
          division_name
        }
      }
    }
  }
`;

export const ADD_TEACHER_ADDRESS = gql`
  mutation addAddress($address: [addresses_insert_input!]!) {
    insert_addresses(objects: $address) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const UPDATE_TEACHER_ADDRESS_RELATIONSHIP = gql`
  mutation updateAddressRelationship(
    $authID: String!
    $addressid: Int!
    $addedBy: String!
  ) {
    update_users(
      where: { auth_id: { _eq: $authID } }
      _set: { address_id: $addressid }
    ) {
      affected_rows
    }
    insert_audit_trails_one(
      object: { user_id: $authID, added_by: $addedBy, changes: "test" }
    ) {
      id
    }
  }
`;

export const ADD_TEACHER_CONTACTS = gql`
  mutation addContacts($contacts: [contacts_insert_input!]!) {
    insert_contacts(objects: $contacts) {
      affected_rows
    }
  }
`;

export const GET_TEACHER_BYPK = gql`
  query Teacher($id: Int!) {
    users_by_pk(id: $id) {
      id
      auth_id
      first_name
      middle_name
      last_name
      mobile_number
      email
      gender
      dob
      image
      address {
        id
        address_line_one
        address_line_two
        pincode
        city {
          id
          city_name
          district {
            id
            district_name
            state {
              id
              state_name
            }
          }
        }
      }
      contacts {
        relation
        full_name
        mobile_number
      }
      division_subjects(
        where: {
          is_active: { _eq: true }
          subject: { is_enable: { _eq: true } }
        }
      ) {
        id
        subject {
          title
        }
        division {
          id
          class_id
          division_name
        }
      }
      teacher_attendance_data {
        is_present
      }
      class_teachers(where: { is_active: { _eq: true } }) {
        division {
          id
          class_id
          division_name
        }
      }
    }
  }
`;

export const UPDATE_TEACHER_BYPK = gql`
  mutation updateStudent(
    $_set: users_set_input
    $id: Int!
    $_setaddress: addresses_set_input
    $addressid: Int!
    $addedBy: String!
    $teacherAuthID: String!
  ) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      first_name
    }
    insert_audit_trails_one(
      object: { user_id: $teacherAuthID, added_by: $addedBy, changes: "test" }
    ) {
      id
    }
    update_addresses_by_pk(pk_columns: { id: $addressid }, _set: $_setaddress) {
      id
    }
  }
`;

export const GET_TEACHER_LIST_WITH_FILTER = gql`
  query getTeacherList($filters: users_bool_exp, $limit: Int, $offset: Int) {
    users(
      where: $filters
      order_by: { updated_at: desc }       
      limit: $limit
      offset: $offset
    ) {
      id
      first_name
      last_name
      middle_name
      full_name
      mobile_number
      created_at
      email
      image
      class_teachers(
        where: {
          is_active: { _eq: true }
          division: {
            acadamic_year: { is_current: { _eq: true } }
            is_active: { _eq: true }
          }
        }
      ) {
        division {
          division_name
        }
      }
      teacher_attendance_data {
        is_present
      }
      division_subjects(
        where: {
          is_active: { _eq: true }
          subject: { is_enable: { _eq: true } }
          division: {
            acadamic_year: { is_current: { _eq: true } }
            is_active: { _eq: true }
          }
        }
      ) {
        id
      }
      auditTrailsByUserId(order_by: { updated_at: desc }) {
        added_by
        updated_at
        userByAddedBy {
          first_name
          last_name
        }
      }
    }
    users_aggregate (where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TEACHER_COUNT = gql`
  query getTeacherList($filters: users_bool_exp) {    
    users_aggregate (where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const INSERT_TEACHERS = gql`
  mutation insert_teachers($objects: [teachers_insert_input!]!) {
    insert_users(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
