/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";

const { REACT_APP_NODE_ENDPOINT } = process.env;

const downloadPdf = async (variables) => {
  try {
    const token = localStorage.getItem("token");
    const schoolId = getSchoolID();
    const config = {
      method: "post",
      url: `${REACT_APP_NODE_ENDPOINT}/fees/downloadFeeStructurePDF`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        schoolId,
        ...variables,
      },
    };
    if (variables.responseType !== "html") config.responseType = "blob";
    const { data } = await axios.request(config);
    if (variables.responseType === "html") return data;
    //download file if response type is pdf
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.setAttribute("download", `FeeStructure.pdf`);
    document.body.appendChild(link);
    link.click();
    return data;
  } catch (e) {
    console.error("Unable to download fee structure: ", { e: e.message });
    return null;
  }
};

export default function useDownloadFeeStructure(
  { feeStructureId, responseType },
  options = {}
) {
  return useQuery(
    ["admissionForm", feeStructureId],
    () => downloadPdf({ feeStructureId, responseType }),
    {
      enabled: !!feeStructureId,
      ...options,
    }
  );
}
