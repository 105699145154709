import AWS from "aws-sdk";
// import Logo from "../assets/images/school-placeholder.png";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  signatureVersion: "v4",
  region: process.env.REACT_APP_S3_REGION,
});

export const getS3Bucket = () => {
  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_S3_BUCKET },
    region: process.env.REACT_APP_S3_REGION,
  });
  return myBucket;
};

export const getS3Image = (imageKey) => {
  if (!imageKey) return "";
  const myBucket = getS3Bucket();
  const url = myBucket.getSignedUrl("getObject", {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: `${process.env.REACT_APP_S3_BUCKET_FOLDER}/${imageKey}`,
    Expires: 60 * 5,
  });
  return url;
};
