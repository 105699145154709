/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { notification } from "antd";

const updateDoYouKnow = async (payload) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_PARENT_NODE_ENDPOINT}/v1/doYouKnow/update`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  const res = await axios.request(config);
  return res?.data;
};

export default function useUpdateDoYouKnow(options = {}) {
  return useMutation({
    mutationFn: (data) => updateDoYouKnow(data),
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "Do you know updated",
        duration: 1.5,
      });
    },
    onError: (error) => {
      notification["error"]({
        message: "Failure",
        description: "Could not update do you know",
        duration: 1.5,
      });
      console.error(error);
    },
    ...options,
  });
}
