/* eslint-disable no-console */
import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import axios from "axios";

const remindAllParent = async (payload) => {
	const config = {
		method: "post",
		url: `${process.env.REACT_APP_NODE_ENDPOINT}/fees/remindFilteredBalances`,
		headers: {
			"Content-Type": "application/json",
		},
		data: payload,
	};
	const res = await axios.request(config);
	const response = res?.data;
	if (response?.success) {
		notification["success"]({
			message: "Parents Reminded",
			description: "Notification are to send to selected parents",
			duration: 1.5,
		});
	} else {
		throw new Error(response?.message || "Unable to remind parents");
	}
	return res?.data;
};

export default function useRemindAllParent(options = {}) {
	return useMutation({
		mutationFn: (data) => remindAllParent(data),
		onError: (error) => {
			notification["error"]({
				message: "Failure",
				description: error.message,
				duration: 1.5,
			});
		},
		...options,
	});
}