import React, { useEffect, useState } from "react";
import { Button, Dropdown, Switch } from "../../components";
import { Modal, Select, Option } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  ADD_NEW_GROUP_ID,
  ADD_NEW_SUBJECT_ID,
  ENGLISH,
  MAX_RESULT,
} from "../../utils/constants";
import AutoCompleteInput from "../../components/AutoCompleteInput";
import useTransliteratedList from "../../hooks/useTransliteratedList";
import useTransliteration from "../../hooks/useTransliteration";
import { getLanguageInputCode } from "../../utils";
import { MARATHI } from "../../utils/constants";
import { LANGUAGE_CODES, LANGUAGE_INPUT_CODES } from "../../utils/constants";
import useTracking from "../../hooks/useTracking";

function AddSubjectModal({
  isVisible,
  onClose,
  onSave,
  classDetails,
  schoolDetails,
}) {
  const [formData, setFormData] = useState({
    subjectName: "",
    subjectId: "",
    subjectDisplayName: "",
    groupDisplayName: "",
    groupName: "",
    groupId: "",
    isCore: true,
  });
  const [newNames, setNewNames] = useState({
    newSubjectName: "",
    newGroupName: "",
  });
  const [optionsList, setOptions] = useState({
    optionsFor: "",
    options: [],
  });
  const { options } = optionsList;
  const {
    data: translationList,
    error,
    refetch: getTransliteratedList,
  } = useTransliteratedList();
  const [getTransliteration] = useTransliteration();
  useEffect(() => {
    setFormData({
      ...formData,
      divisions: classDetails?.divisions?.map((div) => div?.value),
    });
  }, [classDetails]);

  const { id, className, divisions } = classDetails;
  const { medium, subjectsDropdown, groupDropdown } = schoolDetails;
  const { trackEvent } = useTracking();

  const handleCreate = () => {
    trackEvent("Create Subject");
    const finalFormData = { ...formData };
    if (finalFormData.groupId === ADD_NEW_GROUP_ID) {
      finalFormData.groupName = newNames.newGroupName;
    }
    if (finalFormData.subjectId === ADD_NEW_SUBJECT_ID) {
      finalFormData.subjectName = newNames.newSubjectName;
    }
    onSave(finalFormData);
  };

  const onFoucLost = () => {
    setOptions([]);
  };

  useEffect(() => {
    const getList = async () => {
      if (medium === MARATHI) {
        if (formData?.subjectDisplayName === "") {
          setOptions({
            optionsFor: "",
            options: [],
          });
          return;
        }
        const sourceText = formData?.subjectDisplayName;
        const inputLanguage = LANGUAGE_CODES["MARATHI"];
        const inputCode = LANGUAGE_INPUT_CODES[inputLanguage];
        const maxResult = MAX_RESULT;
        const res = await getTransliteratedList({
          sourceText,
          inputCode,
          maxResult,
        });
        setOptions({
          optionsFor: "subjectDisplayName",
          options: res,
        });
      }
    };
    getList();
  }, [formData.subjectDisplayName]);

  useEffect(() => {
    const getList = async () => {
      if (medium === MARATHI) {
        if (formData?.groupDisplayName === "") {
          setOptions({
            optionsFor: "",
            options: [],
          });
          return;
        }

        const sourceText = formData?.groupDisplayName;
        const inputLanguage = LANGUAGE_CODES["MARATHI"];
        const inputCode = LANGUAGE_INPUT_CODES[inputLanguage];
        const maxResult = MAX_RESULT;
        const res = await getTransliteratedList({
          sourceText,
          inputCode,
          maxResult,
        });
        setOptions({
          optionsFor: "groupDisplayName",
          options: res,
        });
      }
    };
    getList();
  }, [formData.groupDisplayName]);

  const updateSelectedSubject = (value) => {
    const subject = subjectsDropdown?.find(
      (subject) => parseInt(subject.value) === parseInt(value)
    );

    if (subject?.value === ADD_NEW_SUBJECT_ID) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subjectId: subject.value,
        subjectName: subject.label,
        subjectDisplayName: "",
        groupDisplayName: "",
        groupId: "",
        groupName: "",
      }));
    } else {
      // Find the group to which subject belongs, and set group to that subject
      const groupName = subject?.group;
      const group = groupDropdown?.find(
        (currentGroup) => currentGroup?.label === groupName
      );
      // Find translation and set it to that
      let subjectDisplayName = formData?.subjectDisplayName;
      if (medium === MARATHI) {
        subjectDisplayName = subject?.translations?.[`${medium}`];
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        subjectId: subject.value,
        subjectName: subject.label,
        groupId: group?.value,
        groupName,
        subjectDisplayName,
        groupDisplayName: subject?.groupTranslations?.[`${medium}`],
        isCore: subject?.isCore,
      }));
    }
  };

  const updateSelectedGroup = (value) => {
    const group = groupDropdown?.find(
      (group) => parseInt(group.value) === parseInt(value)
    );

    if (group?.value === ADD_NEW_GROUP_ID) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        groupId: group?.value,
        groupName: group?.label,
        groupDisplayName: "",
      }));
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      groupId: group?.value,
      groupName: group?.label,
      groupDisplayName: group?.groupTranslations?.[`${medium}`],
    }));
  };

  const updateDisplayName = async (e) => {
    let data = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: data,
    }));
  };

  const updateNames = (e) => {
    setNewNames((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const getTranlatedText = async () => {
      if (parseInt(formData.subjectId) === ADD_NEW_SUBJECT_ID) {
        const langCode = getLanguageInputCode(LANGUAGE_CODES["MARATHI"]);

        if (newNames?.newSubjectName?.length === 0) {
          setFormData({
            ...formData,
            subjectDisplayName: "",
          });
          return;
        }

        const tranlatedData = await getTransliteration({
          textObj: {
            displayName: newNames?.newSubjectName,
          },
          inputCode: langCode,
        });
        setFormData({
          ...formData,
          subjectDisplayName: tranlatedData?.displayName,
        });
      }
    };
    if (medium === MARATHI) {
      getTranlatedText();
    }
  }, [newNames?.newSubjectName]);

  useEffect(() => {
    const getTranlatedText = async () => {
      if (parseInt(formData.groupId) === ADD_NEW_GROUP_ID) {
        const langCode = getLanguageInputCode("mr-IN");

        if (newNames?.newGroupName?.length === 0) {
          setFormData({
            ...formData,
            groupDisplayName: "",
          });
          return;
        }

        const tranlatedData = await getTransliteration({
          textObj: {
            displayName: newNames?.newGroupName,
          },
          inputCode: langCode,
        });
        setFormData({
          ...formData,
          groupDisplayName: tranlatedData?.displayName,
        });
      }
    };
    if (medium === MARATHI) {
      getTranlatedText();
    }
  }, [newNames?.newGroupName]);

  const filterOptionsOnInput = (input, option) => {
    if (typeof option.children === typeof "string") {
      return option.children?.toLowerCase()?.includes(input?.toLowerCase());
    } else if (
      Array.isArray(option.children) &&
      typeof option.children?.[0] === typeof "string"
    ) {
      return option?.children?.[0]
        ?.toLowerCase()
        ?.includes(input?.toLowerCase());
    } else {
      return true;
    }
  };

  return (
    <Modal
      visible={isVisible}
      centered
      onCancel={onClose}
      destroyOnClose
      closeIcon={
        <CloseCircleFilled
          style={{
            color: "#EB5757",
            fontSize: "24px",
          }}
        />
      }
      bodyStyle={{
        justifyContent: "center",
      }}
      width={435}
      footer={null}
    >
      <form
        className="flex flex-col justify-center"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="mb-5">
          <p className="text-black text-lg font-bold">Add New Subject</p>
        </div>
        <div className="mt-4 flex flex-col">
          <label className="text-gray-700 font-medium">Subject Name</label>
          <Select
            onChange={updateSelectedSubject}
            showSearch
            placeholder="Select Subject"
            filterOption={filterOptionsOnInput}
            value={formData?.subjectName}
          >
            {subjectsDropdown?.map((subject) => (
              <Select.Option Option key={subject.value}>
                {subject.label}{" "}
                {medium !== ENGLISH && subject?.translations?.[medium]
                  ? " - " + subject?.translations?.[medium]
                  : ""}
              </Select.Option>
            ))}
          </Select>
          {parseInt(formData?.subjectId) === ADD_NEW_SUBJECT_ID ? (
            <div className="mt-4 flex flex-col">
              <input
                type="text"
                placeholder="Type subject name"
                className="border border-gray-400 h-10 rounded-md pl-2 outline-none"
                value={newNames.newSubjectName}
                onChange={updateNames}
                name="newSubjectName"
                autoFocus
              />
            </div>
          ) : null}
        </div>
        <div className="mt-4 flex flex-col">
          <label className="text-gray-700 font-medium">
            Subject Display Name
          </label>
          <AutoCompleteInput
            field="subjectDisplayName"
            inputClassName="border border-gray-400 h-10 rounded-md pl-2 outline-none"
            style={{ width: "100%", height: "100%" }}
            options={
              optionsList?.optionsFor === "subjectDisplayName" ? options : []
            }
            formData={formData}
            onChange={updateDisplayName}
            onSelect={(value) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                subjectDisplayName: value,
              }));
            }}
            onBlur={onFoucLost}
          />
        </div>
        <div className="mt-4 flex flex-col">
          <label className="text-gray-700 font-medium">Group Name</label>
          <Select
            onChange={updateSelectedGroup}
            showSearch
            placeholder="Select Group"
            filterOption={filterOptionsOnInput}
            value={formData?.groupName}
          >
            {groupDropdown?.map((group) => (
              <Select.Option Option key={group.value}>
                {group.label}{" "}
                {medium !== ENGLISH && group?.groupTranslations?.[medium]
                  ? " - " + group?.groupTranslations?.[medium]
                  : ""}
              </Select.Option>
            ))}
          </Select>
          {parseInt(formData?.groupId) === ADD_NEW_GROUP_ID ? (
            <div className="mt-4 flex flex-col">
              <input
                type="text"
                name="newGroupName"
                className="border border-gray-400 h-10 rounded-md pl-2 outline-none"
                value={newNames.newGroupName}
                onChange={updateNames}
                autoFocus
              />
            </div>
          ) : null}
          <div className="mt-4 flex flex-col">
            <label className="text-gray-700 font-medium">
              Group Display Name
            </label>
            <AutoCompleteInput
              field="groupDisplayName"
              inputClassName="border border-gray-400 h-10 rounded-md pl-2 outline-none"
              style={{ width: "100%", height: "100%" }}
              options={
                optionsList?.optionsFor === "groupDisplayName" ? options : []
              }
              formData={formData}
              onChange={updateDisplayName}
              onSelect={(value) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  groupDisplayName: value,
                }));
              }}
              onBlur={onFoucLost}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div className="mt-6 flex justify-between">
            <label className="text-gray-700 font-medium">Core Subject</label>
            <Switch
              type="default"
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(value) => {
                setFormData((prevState) => ({
                  ...prevState,
                  isCore: value,
                }));
              }}
              checked={formData.isCore}
            />
          </div>
        </div>
        <Button
          className="mt-12 w-44 self-center"
          buttonStyle="success"
          onClick={handleCreate}
        >
          Create Subject
        </Button>
      </form>
    </Modal>
  );
}

export default AddSubjectModal;
