import React, { useState } from "react";
import useSchoolInventories from "../../hooks/useSchoolInventories";
import useUpdateInventory from "../../hooks/useUpdateInventory";
import clockIcon from "../../assets/icons/clock.png";
import {ReactComponent as CheckIcon} from "../../assets/icons/check-round.svg";
import {ReactComponent as AddIcon} from "../../assets/icons/add-round.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-round.svg";

import moment from "moment";
import { Button } from "../../components";
import { Radio } from "antd";
import { getAuthID } from "../../shared/getAuthID";

function SchoolInventories() {
  const [displayStockInventories, setDisplayStockInventories] = useState(6); // Number of items to render initially and on each load
  const [displayAvailabilityInventories, setDisplayAvailabilityInventories] =
    useState(6); // Number of items to render initially and on each load
  const [stockInventories, setStockInventories] = useState([]);
  const [availabilityInventories, setAvailabilityInventories] = useState([]);

  useSchoolInventories({
    onSuccess: (schoolInventories) => {
      setStockInventories(
        schoolInventories?.filter(
          (inventory) => inventory?.categoryType === "NUMBER"
        )
      );
      setAvailabilityInventories(
        schoolInventories?.filter(
          (inventory) => inventory?.categoryType === "BOOLEAN"
        )
      );
    },
  });
  const { mutateAsync: updateInventory } = useUpdateInventory();

  const loadMoreStockList = () => {
    setDisplayStockInventories(displayStockInventories + 6);
  };

  const loadMoreAvailabilityList = () => {
    setDisplayAvailabilityInventories(displayAvailabilityInventories + 6);
  };

  const handleEditStock = (index) => {
    const updatedStockInventories = [...stockInventories];
    updatedStockInventories[index].isEditing = true;
    setStockInventories(updatedStockInventories);
  };

  const handleEditAvailability = (index) => {
    const updatedAvailabilityInventories = [...availabilityInventories];
    updatedAvailabilityInventories[index].isEditing = true;
    setAvailabilityInventories(updatedAvailabilityInventories);
  };

  const handleCancelEditStock = (index) => {
    const updatedStockInventories = [...stockInventories];
    updatedStockInventories[index].isEditing = false;
    setStockInventories(updatedStockInventories);
  };

  const handleCancelEditAvailability = (index) => {
    const updatedAvailabilityInventories = [...availabilityInventories];
    updatedAvailabilityInventories[index].isEditing = false;
    setAvailabilityInventories(updatedAvailabilityInventories);
  };

  const handleStockValueChange = (index, key, value) => {
    const updatedStockInventories = [...stockInventories];
    updatedStockInventories[index][key] = value;
    setStockInventories(updatedStockInventories);
  };

  const handleAvailabilityValueChange = (index, key, value) => {
    const updatedAvailabilityInventories = [...availabilityInventories];
    updatedAvailabilityInventories[index][key] = value;
    setAvailabilityInventories(updatedAvailabilityInventories);
  };

  const handleUpdateStock = async (index) => {
    const updatedStockInventories = [...stockInventories];
    const inventory = updatedStockInventories[index];
    const payload = {
      inventoryData: [
        {
          ...inventory,
          updatedBy: getAuthID(),
        },
      ],
    };
    await updateInventory(payload);
    updatedStockInventories[index].isEditing = false;
    setStockInventories(updatedStockInventories);
  };

  const handleUpdateAvailability = async (index) => {
    const updatedAvailabilityInventories = [...availabilityInventories];
    const inventory = updatedAvailabilityInventories[index];
    const payload = {
      inventoryData: [
        {
          ...inventory,
          updatedBy: getAuthID(),
        },
      ],
    };
    await updateInventory(payload);
    updatedAvailabilityInventories[index].isEditing = false;
    setAvailabilityInventories(updatedAvailabilityInventories);
  };

  return (
    <div className="flex flex-col px-8 py-3 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row gap-x-x4 items-center mb-8 justify-between">
        <p className="text-2xl text-left font-bold flex-col m-0 ">
          School Inventory
        </p>
      </div>
      <div className="bg-white rounded-lg shadow-md pt-6 pb-6">
        <div className="grid grid-cols-7 gap-x-4 text-left px-12">
          <h2 className="text-greyLight text-base font-medium">ITEM NAME</h2>
          <div className="text-left">
            <h2 className="text-greyLight text-base font-medium m-0">
              AVAILABLE
            </h2>
            <h3 className="text-linerGrey m-0 text-xs">(How many you have)</h3>
          </div>
          <div>
            <h2 className="text-greyLight text-base font-medium m-0">
              ADDITIONAL
            </h2>
            <h3 className="text-linerGrey m-0 text-xs">
              (How many more needed)
            </h3>
          </div>
          <h2 className="text-greyLight text-base font-medium col-span-2">
            REMARK
          </h2>
          <h2 className="text-greyLight text-base font-medium">LAST UPDATED</h2>
        </div>
        <div className="flex flex-col gap-y-5 px-6 pt-3 text-left">
          {stockInventories
            ?.slice(0, displayStockInventories)
            .map((inventory, index) => {
              return (
                <div className="grid grid-cols-7 gap-x-4 bg-white shadow-item rounded-lg pl-6 py-5 pr-5 items-center">
                  <h3 className="text-darkerGrey text-base font-semibold m-0">
                    {inventory?.categoryTitle}
                  </h3>
                  <div className="flex gap-x-2 items-center">
                    {inventory?.isEditing && (
                      <input
                        type="number"
                        className="w-20 h-10 border border-greyLight rounded-md text-center"
                        value={inventory?.inStock}
                        onChange={(e) =>
                          handleStockValueChange(
                            index,
                            "inStock",
                            e.target.value
                          )
                        }
                      />
                    )}
                    {!inventory?.isEditing ? (
                      inventory?.inStock !== null ? (
                        <>
                          <CheckIcon />
                          <h3 className="text-darkerGrey font-semibold text-base m-0">
                            {inventory?.inStock}
                          </h3>
                        </>
                      ) : (
                        <h3 className="text-darkerGrey text-sm m-0">
                          (Data not added)
                        </h3>
                      )
                    ) : null}
                  </div>
                  <div className="flex gap-x-2 items-center">
                    {inventory?.isEditing && (
                      <input
                        type="number"
                        className="w-20 h-10 border border-greyLight rounded-md text-center"
                        value={inventory?.required}
                        onChange={(e) =>
                          handleStockValueChange(
                            index,
                            "required",
                            e.target.value
                          )
                        }
                      />
                    )}
                    {!inventory?.isEditing ? (
                      inventory?.required !== null ? (
                        <>
                          <AddIcon />
                          <h3 className="text-darkerGrey font-semibold text-base m-0">
                            {inventory?.required}
                          </h3>
                        </>
                      ) : (
                        <h3 className="text-darkerGrey text-sm m-0">
                          (Data not added)
                        </h3>
                      )
                    ) : null}
                  </div>
                  <h3 className="text-blackish text-sm m-0 col-span-2 font-normal">
                    {inventory?.isEditing && (
                      <input
                        className="w-0 min-w-full h-14 border border-greyLight rounded-md p-1"
                        value={inventory?.remark}
                        placeholder="Type remark here..."
                        onChange={(e) =>
                          handleStockValueChange(
                            index,
                            "remark",
                            e.target.value
                          )
                        }
                      />
                    )}
                    {!inventory?.isEditing && inventory?.remark}
                  </h3>
                  <div className="flex gap-x-2 items-center">
                    {!inventory?.isEditing && inventory?.lastUpdated && (
                      <>
                        <img alt="clock" src={clockIcon} className="h-4 w-4" />
                        <div>
                          <h3 className="text-darkerGrey font-semibold text-sm m-0">
                            {inventory?.lastUpdated &&
                              moment(inventory?.lastUpdated).format(
                                "DD MMM YYYY"
                              )}
                          </h3>
                          <h4 className="text-doveGrey font-normal text-sm m-0">
                            {inventory?.updatedBy}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                  {inventory?.isEditing ? (
                    <div className="flex justify-end gap-x-5">
                      <Button
                        className="w-20 h-10"
                        onClick={() => handleCancelEditStock(index)}
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        buttonStyle="primary"
                        className="w-20 h-10"
                        onClick={() => handleUpdateStock(index)}
                      >
                        <span>Save</span>
                      </Button>
                    </div>
                  ) : (
                    <div className="flex justify-end grid-cols-1">
                      <Button
                        buttonStyle="primary"
                        className="w-20 h-10"
                        onClick={() => handleEditStock(index)}
                      >
                        <span>Edit</span>
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {displayStockInventories < stockInventories?.length ? (
          <div className="flex justify-start ml-6 mt-6">
            <button
              className="text-primary text-base font-medium border-b border-primary"
              onClick={loadMoreStockList}
            >
              Load More
            </button>
          </div>
        ) : (
          <div className="flex justify-start ml-6 mt-6">
            <button
              className="text-primary text-base font-medium border-b border-primary"
              onClick={() => {
                setDisplayStockInventories(6);
              }}
            >
              Load Less
            </button>
          </div>
        )}
      </div>
      <div className="bg-white rounded-lg shadow-md pt-6 pb-6">
        <div className="grid grid-cols-7 gap-x-4 text-left px-12">
          <h2 className="text-greyLight text-base font-medium col-span-2">ITEM NAME</h2>
          <h2 className="text-greyLight text-base font-medium">AVAILABLE</h2>
          <h2 className="text-greyLight text-base font-medium col-span-2">
            REMARK
          </h2>
          <h2 className="text-greyLight text-base font-medium">LAST UPDATED</h2>
        </div>
        <div className="flex flex-col gap-y-5 px-6 text-left">
          {availabilityInventories
            ?.slice(0, displayAvailabilityInventories)
            ?.map((inventory, index) => {
              return (
                <div className="grid grid-cols-7 gap-x-4 bg-white shadow-md rounded-lg pl-6 py-5 pr-5 items-center">
                  <h3 className="text-darkerGrey font-semibold text-base m-0 col-span-2">
                    {inventory?.categoryTitle}
                  </h3>
                  <div className="flex gap-x-2 items-center">
                    {inventory?.isEditing && (
                      <Radio.Group
                        onChange={(e) =>
                          handleAvailabilityValueChange(
                            index,
                            "isAvailable",
                            e.target.value
                          )
                        }
                        value={inventory?.isAvailable}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No </Radio>
                      </Radio.Group>
                    )}

                    {!inventory?.isEditing ? (
                      inventory?.isAvailable !== null ? (
                        <>
                          {inventory?.isAvailable ? (
                            <CheckIcon />
                          ) : (
                            <CloseIcon />
                          )}
                          <h3 className="text-darkerGrey font-semibold text-base m-0">
                            {inventory?.isAvailable ? "Yes" : "No"}
                          </h3>
                        </>
                      ) : (
                        <h3 className="text-darkerGrey text-sm m-0">
                          (Data not added)
                        </h3>
                      )
                    ) : null}
                  </div>
                  <h3 className="text-blackish text-sm m-0 col-span-2 font-normal">
                    {inventory?.isEditing && (
                      <input
                        className="w-0 min-w-full h-14 border border-greyLight rounded-md p-1"
                        value={inventory?.remark}
                        placeholder="Type remark here..."
                        onChange={(e) =>
                          handleAvailabilityValueChange(
                            index,
                            "remark",
                            e.target.value
                          )
                        }
                      />
                    )}
                    {!inventory?.isEditing && inventory?.remark}
                  </h3>
                  <div className="flex gap-x-2 items-center">
                    {!inventory?.isEditing && inventory?.lastUpdated && (
                      <>
                        <img alt="clock" src={clockIcon} className="h-4 w-4" />
                        <div>
                          <h3 className="text-darkerGrey font-semibold text-sm m-0">
                            {inventory?.lastUpdated &&
                              moment(inventory?.lastUpdated).format(
                                "DD MMM YYYY"
                              )}
                          </h3>
                          <h4 className="text-doveGrey text-sm m-0">
                            {inventory?.updatedBy}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                  {inventory?.isEditing ? (
                    <div className="flex justify-end gap-x-5">
                      <Button
                        className="w-20 h-10"
                        onClick={() => handleCancelEditAvailability(index)}
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        buttonStyle="primary"
                        className="w-20 h-10"
                        onClick={() => handleUpdateAvailability(index)}
                      >
                        <span>Save</span>
                      </Button>
                    </div>
                  ) : (
                    <div className="flex justify-end">
                      <Button
                        buttonStyle="primary"
                        className="w-20 h-10"
                        onClick={() => handleEditAvailability(index)}
                      >
                        <span>Edit</span>
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {displayAvailabilityInventories < availabilityInventories?.length ? (
          <div className="flex justify-start ml-6 mt-6">
            <button
              className="text-primary text-base font-medium border-b border-primary"
              onClick={loadMoreAvailabilityList}
            >
              Load More
            </button>
          </div>
        ) : (
          <div className="flex justify-start ml-6 mt-6">
            <button
              className="text-primary text-base font-medium border-b border-primary"
              onClick={() => {
                setDisplayAvailabilityInventories(6);
              }}
            >
              Load Less
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SchoolInventories;