function TintImage(tintColor, id) {
  return tintColor && id != null ? (
    <svg
      style={{
        position: "absolute",
        height: 0,
        visibility: "hidden",
        width: 0,
      }}
    >
      <defs>
        <filter id={`tint-${id}`} suppressHydrationWarning={true}>
          <feFlood floodColor={`${tintColor}`} key={tintColor} />
          <feComposite in2="SourceAlpha" operator="atop" />
        </filter>
      </defs>
    </svg>
  ) : null;
}

export default TintImage;
