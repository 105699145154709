import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import {
  BULK_UPLOAD_SCHOOLS
} from "../graphql/mutations";
import {
  GET_BOARDS
} from "../graphql/queries";
import moment from "moment";
import { notification, Spin } from "antd";
import { DATE_FORMATS, SCHOOLS_BULK_UPLOAD_FORMAT, schoolMediumList } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { pluralRenderer } from "../shared/pluralRenderer";
import ReactExport from "react-data-export";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import axios from "axios";

function StudentBulkUpload() {
  const [loadingState, setLoadingState] = useState(false);
  const [errors, setErrors] = useState();
  const [success, setSuccess] = useState(false);
  const [addSchools] = useMutation(BULK_UPLOAD_SCHOOLS);
  const [boards, setBoards] = useState({});
  const [schoolsCount, setSchoolsCount] = useState(0);
  const navigate = useNavigate();  

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  const { loading, error } = useQuery(GET_BOARDS, {
    onCompleted: (res) => {
     setBoards(res.boards);
     }
  });

  const handleDrop = (acceptedFiles) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(acceptedFiles[0]);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 0,
          defval: null,
        });
        resolve(data);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
    promise.then((data) => {
      // check if file has data or not
      if (Object.keys(data[0]).length > 1) {
        // checking if mandatory fields are present or not
        checkIfMandatoryFieldColumnMissing(data);
      } else {
        setErrors(
          "Data is missing please fill the data and then upload the file"
        );
        setLoadingState(false);
      }
    });
  };

  const checkIfMandatoryFieldColumnMissing = (data) => {
    const hasSchoolName = _.has(data[0], ["School Name"]);
    const hasSRNO = _.has(data[0], ["S.No"]);
    const hasBoardName = _.has(data[0], ["Board"]);
    const hasAcademicYearTitle = _.has(data[0], ["Academic Year Title"]);
    const hasAcademicYearFrom = _.has(data[0], ["Academic Year From"]);
    const hasAcademicYearTo = _.has(data[0], ["Academic Year To"]);
    const hasUdiseNumber = _.has(data[0], ["U-DISE Number"]);
    const hasPOC = _.has(data[0], ["POC"]);

    if (hasSchoolName === false) {
      setErrors("School name column is missing");
      setLoadingState(false);
      return;
    }
    if (hasBoardName === false) {
      setErrors("Board name column is missing");
      setLoadingState(false);
      return;
    }
    if (hasSRNO === false) {
      setErrors("S.No  column is missing");
      setLoadingState(false);
      return;
    }
    if (hasAcademicYearTitle === false) {
      setErrors("Academic Year Title column is missing");
      setLoadingState(false);
      return;
    }
    if (hasAcademicYearFrom === false) {
      setErrors("Academic Year From column is missing");
      setLoadingState(false);
      return;
    }
    if (hasAcademicYearTo === false) {
      setErrors("Academic Year To column is missing");
      setLoadingState(false);
      return;
    }
    if (hasUdiseNumber === false) {
      setErrors("UDISE-Number column is missing");
      setLoadingState(false);
      return;
    }
    if (hasPOC === false) {
      setErrors("POC column is missing");
      setLoadingState(false);
      return;
    }

    if (hasSchoolName && hasSRNO && hasBoardName && hasAcademicYearTitle && hasAcademicYearFrom && hasAcademicYearTo && hasUdiseNumber && hasPOC) {
      processData(data);
    }
  };

  const makeThemFlat = (res2) => {
    return new Promise((resolve, reject) => {
      const payload = [];

      res2?.forEach((dat, datIndex) => {
        payload.push({
          school_name: dat.school_name,
          medium: dat.medium,
          trust_name: dat.trust_name,
          academic_year_title: dat.academic_year_title,
          academic_year_from: dat.academic_year_from,
          academic_year_to: dat.academic_year_to,
          udise_number: dat.udise_number,
          index_number: dat.index_number,
          address_line_1: dat.address_line_1,
          address_line_2: dat.address_line_2,
          state: dat.state,
          district: dat.district,
          city: dat.city,
          pin_code: dat.pin_code,
          management_type: dat.management_type,
          category: dat.category,
          poc_id: dat.poc_id,
          admin_name: dat.admin_name,
          admin_mobile_number: `+91${dat.admin_mobile_number}`,
          admin_role: dat.admin_role,
          board_id: dat.board_id,
          board_name: dat.board_name,
          board_settings: dat.board_settings
        });
      });

      if (payload.length > 0) {
        resolve(payload);
      } else {
        setErrors("Not enough data");
        reject("Not enough data");
        setLoadingState(false);
      }
    });
  };

  const createAdmin = (adminData) => {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_FUNCTION_ENDPOINT}/createAdmin`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: adminData,
    };
    axios(config)
      .then((res) => {
        notification["success"]({ message: "Admin Created Successfully", description: "", duration: 2 });
      })
      .catch((err) => {
        setSuccess(true);
        setErrors("Admin already exists in another school! ");
        notification["error"]({ message: "Cannot Create Admin", description: "Admin already exists in another school", duration: 2 });
      });
  };

  const finalSendToDB = (payload) => {
    return new Promise((resolve, reject) => {
      payload
        ?.forEach((schoolDetails) => {
          const getAddress = () => {
              return {
                data: {
                  address_line_one: schoolDetails?.address_line_1
                    ? schoolDetails?.address_line_1
                    : "",
                  address_line_two: schoolDetails?.address_line_2
                    ? schoolDetails?.address_line_2
                    : "",
                  pincode: schoolDetails?.pin_code.toString() || "",
                  city: {
                    data: {
                      city_name: schoolDetails?.city ? schoolDetails?.city : "",
                      district: {
                        data: {
                          district_name: schoolDetails?.district ? schoolDetails?.district : "",
                          state: {
                            data: {
                              state_name: schoolDetails?.state ? schoolDetails?.state : ""
                            },
                            on_conflict: {
                              constraint: "states_state_name_key",
                              update_columns: ["updated_at"]
                            }
                          }
                        },
                        on_conflict: {
                          constraint: "districts_district_name_state_id_key",
                          update_columns: ["updated_at"]
                        }
                      }
                    },
                    on_conflict: {
                      constraint: "cities_city_name_district_id_key",
                      update_columns: ["updated_at"]
                    }
                  }
                }
              }
            };
          const school = {
            title: schoolDetails?.school_name,
            trust_name: schoolDetails?.trust_name,
            medium: schoolDetails?.medium,
            udise_number: schoolDetails?.udise_number.toString(),
            index_number: schoolDetails?.index_number.toString(),            
            management_type: schoolDetails?.management_type,
            category: schoolDetails?.category,
            board_id: schoolDetails?.board_id,
            board_name: schoolDetails?.board_name,
            settings: schoolDetails?.board_settings,
            address: getAddress(),
            acadamic_years: {
              data: {
                title: schoolDetails?.academic_year_title,
                from_date: schoolDetails?.academic_year_from.toString(),
                to_date: schoolDetails?.academic_year_to.toString(),
                is_current: true,
              }
            },
            group_schools: {
              data: {
                group_id: schoolDetails.poc_id
              }
            }
          };
          addSchools({ variables: { schoolDetails: school } }).then((response) => {
            setSuccess(true);
            setErrors(null);
            notification["success"]({ message: "Schools added", duration: 2 });
            setLoadingState(false);
            resolve();
            const adminData = {
              phone: schoolDetails?.admin_mobile_number,
              first_name: schoolDetails?.admin_name,
              school_id: response?.data?.insert_schools_one?.schoolId,
              admin_type : schoolDetails?.admin_role
            }
            createAdmin(adminData);
          })
          .catch((reserror) => console.log(reserror));
        })
        .catch((reserror) => {
          console.error("reserror", reserror?.graphQLErrors?.[0]?.message);
          setErrors(reserror?.graphQLErrors?.[0]?.message);
          setLoadingState(false);
          reject();
        });
    });
  };

  const checkFormatOfPhoneNumber = (data) => {
    return new Promise((resolve, reject) => {
      const testPattern = data.filter((obj) =>
        /^[6-9]\d{9}$/.test(obj?.admin_mobile_number)
      );

      const dataLength = data?.length;

      const checkIfSame = dataLength === testPattern.length;

      if (checkIfSame) {
        resolve(data);
      } else {
        const wrongFormatNo = [];
        const testPattern = data?.forEach((obj) => {
          const mob = /^[6-9]\d{9}$/.test(obj.contact_mobile_one);
          if (mob !== true) {
            wrongFormatNo.push(obj.contact_mobile_one);
          }
        });
        setErrors(
          `Some phone numbers does not meet the format requirements ${wrongFormatNo} `
        );
        reject("Some phone numbers does not meet the format requirements ");
        setLoadingState(false);
      }
    });
  };

  const havesPath = (data) => {
    setSchoolsCount(data?.length);
    checkFormatOfPhoneNumber(data)
    ?.then((passesFormatChecks) => {
      return makeThemFlat(passesFormatChecks);
    })
    ?.then((flatData) => {
      return finalSendToDB(flatData);
    })
    .catch((err) => console.log("overall error havesPath", err));
  };

  const processData = (data) => {
    // check the format and the type of fields in uploaded data
    const wrongTypeData = [];
    const wrongFormatData = [];
    data.some((datArr, index) => {
       if (typeof datArr["S.No"] !== "number") {
        wrongTypeData.push({
          field: "S.No",
          data: datArr["S.No"],
          type: "number and this should not be empty ",
        });
        return true;
      }
      // check Trust Name
      if (
        datArr["Trust Name"] &&
        typeof datArr["Trust Name"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Trust Name",
          data: datArr["Trust Name"],
          type: "string",
        });
        return true;
      }
      // check School Name
      if (
        datArr["School Name"] &&
        typeof datArr["School Name"] !== "string"
      ) {
        wrongTypeData.push({
          field: "School Name",
          data: datArr["School Name"],
          type: "string",
        });
        return true;
      }
      // check Board
      if (
        datArr["Board"] &&
        typeof datArr["Board"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Board",
          data: datArr["Board"],
          type: "string",
        });
        return true;
      }
      // check Academic Year Title
      if (
        datArr["Academic Year Title"] &&
        typeof datArr["Academic Year Title"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Academic Year Title",
          data: datArr["Academic Year Title"],
          type: "string",
        });
        return true;
      }
      // Academic Year From
      if (typeof datArr["Academic Year From"] !== "string") {
        wrongTypeData.push({
          field: "Academic Year From",
          data: datArr["Academic Year From"],
          type: "text in Excel and this should not be empty ",
        });
        return true;
      }
      // Academic Year To 
      if (typeof datArr["Academic Year To"] !== "string") {
        wrongTypeData.push({
          field: "Academic Year To",
          data: datArr["Academic Year To"],
          type: "text in Excel and this should not be empty ",
        });
        return true;
      }
      // check U-DISE Number
      if (typeof datArr["U-DISE Number"] !== "number") {
        wrongTypeData.push({
          field: "U-DISE Number",
          data: datArr["U-DISE Number"],
          type: "number and this should not be empty ",
        });
        return true;
      }
      // check POC
      if (typeof datArr["POC"] !== "number") {
        wrongTypeData.push({
          field: "POC",
          data: datArr["POC"],
          type: "number and this should not be empty ",
        });
        return true;
      }

      return false;
    });

    if (!isEmpty(wrongTypeData)) {
      setErrors(
        `${wrongTypeData[0].field} should be of type ${wrongTypeData[0].type}`
      );
      setLoadingState(false);
    } else if (!isEmpty(wrongFormatData)) {
      setErrors(
        `Field ${wrongFormatData[0].field} should be in Format ${wrongFormatData[0].type}`
      );
      setLoadingState(false);
    } else {
      const newProcessArray = [];
      data.forEach((datArr) => {
        if (datArr["School Name"] === null) {
          setErrors("School name is missing");
          setLoadingState(false);
        }
        if(boards?.length > 0){
          const board = boards?.find(board => board?.code === datArr["Board"]);
          const medium = schoolMediumList?.find(m => m?.value === datArr["Medium"]);
          const transformArray = {
            row_no: datArr["S.No"],
            school_name: datArr["School Name"],
            trust_name: datArr["Trust Name"],            
            academic_year_title: datArr["Academic Year Title"],
            academic_year_from: _.isNil(datArr["Academic Year From"])
            ? null
            : moment(datArr["Academic Year From"], DATE_FORMATS.DDMMYYYY).format(
                DATE_FORMATS.YYYYMMDD
              ),
            academic_year_to:  _.isNil(datArr["Academic Year To"])
            ? null
            : moment(datArr["Academic Year To"], DATE_FORMATS.DDMMYYYY).format(
                DATE_FORMATS.YYYYMMDD
              ),
            udise_number: datArr["U-DISE Number"],
            index_number: datArr["Index Number"],
            address_line_1: datArr["Address Line 1"],
            address_line_2: datArr["Address Line 2"],
            state: datArr["State"],
            district: datArr["District"],
            city: datArr["City"],
            pin_code: datArr["Pin Code"],
            management_type: datArr["Management Type"],
            category: datArr["Category"],
            poc_id: datArr["POC"],
            admin_name: datArr["Admin Name"],
            admin_mobile_number: datArr["Admin Mobile Number"],
            admin_role: datArr["Admin Role"],
            board_id: board?.id,
            board_name: board?.name,            
            board_settings: board?.settings,
            medium: medium?.value,
          };
          newProcessArray.push(transformArray);
        }
      });
      setLoadingState(true);
      havesPath(newProcessArray);
    }
  };

  const ErrorMessage = (errorz) => {
    return (
      <React.Fragment>
        <div className="text-left flex-col flex">
          <p className="text-red-500 text-lg leading-5 font-medium">
            Oops! Upload failed
          </p>

          <p className="text-sm leading-5 font-normal text-gray-500 mt-2.5">
            {errorz.error ? errorz.error : errorz}
          </p>
          <p className="text-sm leading-5 font-normal text-gray-500 mt-1">
            Please download the Template Excel File and upload in the right
            format or change admin details
          </p>
        </div>
        <div className="flex-col flex">
          <button
            onClick={() => navigate(-1)}
            className="opacity-50 mr-2.5 w-48 ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white pointer-events-none"
          >
            View Student Details
          </button>
        </div>
      </React.Fragment>
    );
  };
  const SuccessMessage = () => {
    return (
      <React.Fragment>
        <div className="text-left flex-col flex">
          <p className="text-black text-lg leading-5 font-medium">
            Schools Upload Successful
          </p>

          <p className="text-sm leading-5 font-normal text-gray-500 mt-2.5">
            {pluralRenderer(
              schoolsCount,
              "school record have been saved",
              "schools record have been saved"
            )}
          </p>
        </div>
        <div className="flex-col flex">
          <button
            onClick={() => navigate(-1)}
            className=" mr-2.5 w-48 ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white"
          >
            View School Details
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div>
        <main className="bg-gray-100 dark:bg-gray-800 rounded-2xl h-screen overflow-hidden relative">
        <div className="flex items-center mt-7 flex-col gap-y-2">
            <div className="flex flex-col w-10/12 pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
              <Spin spinning={loadingState}> 
                <div className="flex flex-row mb-10 justify-between">
                  <p className="text-2xl text-left font-bold">
                    Bulk Upload Schools
                  </p>
                  <button
                    id="managestudents-bulkupload-back"
                    onClick={() => {
                      navigate('/adminSettings'); 
                    }}
                    className="py-3 px-4 flex justify-center items-center  bg-pink-400 hover:bg-pink-500 hover:text-white focus:ring-pink-500 focus:ring-offset-pink-200  transition ease-in duration-200 text-white text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-200"
                  >
                    Back to Dashboard
                  </button>
                </div>

                <div className="flex flex-row justify-around items-center mt-10 gap-x-10">
                  <div className="flex flex-col w-full ">
                    <p className="text-left font-bold text-base ">
                      <span className="text-indigo-600  font-bold ">
                        Step 1:
                      </span>{" "}
                      Download Template Excel file
                    </p>

                    <div className="border-4 px-24 py-12 rounded-lg border-grey-300 bg-white h-48 mt-5">
                      <span className="flex flex-row justify-center">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_113_10484)">
                            <rect
                              x="3.5"
                              y="4.5"
                              width="42"
                              height="38"
                              rx="3.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="4"
                              y1="13.5"
                              x2="46"
                              y2="13.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="20.5"
                              x2="46"
                              y2="20.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="27.5"
                              x2="46"
                              y2="27.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="34.5"
                              x2="46"
                              y2="34.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13.5"
                              y1="14"
                              x2="13.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="23.5"
                              y1="14"
                              x2="23.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="33.5"
                              y1="14"
                              x2="33.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <circle cx="40" cy="37" r="8" fill="#4338CA" />
                            <path
                              d="M44.2825 37.5323L40.5325 41.2823C40.4611 41.3506 40.377 41.4041 40.285 41.4398C40.1024 41.5148 39.8976 41.5148 39.715 41.4398C39.6229 41.4041 39.5388 41.3506 39.4675 41.2823L35.7175 37.5323C35.6475 37.4624 35.5921 37.3794 35.5542 37.288C35.5164 37.1967 35.4969 37.0987 35.4969 36.9998C35.4969 36.8001 35.5762 36.6086 35.7175 36.4673C35.8587 36.3261 36.0502 36.2468 36.25 36.2468C36.4497 36.2468 36.6412 36.3261 36.7825 36.4673L39.25 38.9423V33.2498C39.25 33.0509 39.329 32.8602 39.4696 32.7195C39.6103 32.5788 39.801 32.4998 40 32.4998C40.1989 32.4998 40.3896 32.5788 40.5303 32.7195C40.6709 32.8602 40.75 33.0509 40.75 33.2498V38.9423L43.2175 36.4673C43.2872 36.397 43.3701 36.3412 43.4615 36.3032C43.5529 36.2651 43.6509 36.2455 43.75 36.2455C43.849 36.2455 43.947 36.2651 44.0384 36.3032C44.1298 36.3412 44.2127 36.397 44.2825 36.4673C44.3528 36.5371 44.4085 36.62 44.4466 36.7114C44.4847 36.8028 44.5043 36.9008 44.5043 36.9998C44.5043 37.0988 44.4847 37.1969 44.4466 37.2883C44.4085 37.3797 44.3528 37.4626 44.2825 37.5323Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_113_10484">
                              <rect width="48" height="48" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <ExcelFile
                        filename="GROUNDUP_EXCEL_FORMAT"
                        element={
                          <button
                          id="managestudents-bulkupload-gettemplate"
                            style={{ color: "#4F46E5" }}
                            className="font-medium"
                          >
                            Click to Download
                          </button>
                        }
                      >
                        <ExcelSheet
                          dataSet={SCHOOLS_BULK_UPLOAD_FORMAT}
                          name="GROUNDUP_EXCEL_FORMAT"
                        />
                      </ExcelFile>

                      <p className="text-xs leading-4 font-normal text-gray-500 mt-1">
                        Fill details mentioned under each column
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-full customUploader ">
                    <Dropzone onDropAccepted={(files) => handleDrop(files)}> 
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <p className="text-left font-bold text-base">
                            <span className="text-indigo-600  font-bold">
                              Step 2:
                            </span>{" "}
                            Upload Excel file
                          </p>
                          <>
                            <div
                              {...getRootProps()}
                              className="border-4 px-24 py-12 rounded-lg  bg-white h-48 mt-5 border-grey-300	border-dashed"
                            >
                              <input {...getInputProps()} />

                              <span className="flex flex-row justify-center">
                                <svg
                                  width="48"
                                  height="48"
                                  viewBox="0 0 48 48"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_113_10463)">
                                    <rect
                                      x="3.5"
                                      y="4.5"
                                      width="42"
                                      height="38"
                                      rx="3.5"
                                      stroke="#9CA3AF"
                                    />
                                    <line
                                      x1="4"
                                      y1="13.5"
                                      x2="46"
                                      y2="13.5"
                                      stroke="#9CA3AF"
                                    />
                                    <line
                                      x1="13"
                                      y1="20.5"
                                      x2="46"
                                      y2="20.5"
                                      stroke="#9CA3AF"
                                    />
                                    <line
                                      x1="13"
                                      y1="27.5"
                                      x2="46"
                                      y2="27.5"
                                      stroke="#9CA3AF"
                                    />
                                    <line
                                      x1="13"
                                      y1="34.5"
                                      x2="46"
                                      y2="34.5"
                                      stroke="#9CA3AF"
                                    />
                                    <line
                                      x1="13.5"
                                      y1="14"
                                      x2="13.5"
                                      y2="43"
                                      stroke="#9CA3AF"
                                    />
                                    <line
                                      x1="23.5"
                                      y1="14"
                                      x2="23.5"
                                      y2="43"
                                      stroke="#9CA3AF"
                                    />
                                    <line
                                      x1="33.5"
                                      y1="14"
                                      x2="33.5"
                                      y2="43"
                                      stroke="#9CA3AF"
                                    />
                                    <circle
                                      r="8"
                                      transform="matrix(1 0 0 -1 40 37)"
                                      fill="#4338CA"
                                    />
                                    <path
                                      d="M44.2825 36.4677L40.5325 32.7177C40.4611 32.6494 40.377 32.5959 40.285 32.5602C40.1024 32.4852 39.8976 32.4852 39.715 32.5602C39.6229 32.5959 39.5388 32.6494 39.4675 32.7177L35.7175 36.4677C35.6475 36.5376 35.5921 36.6206 35.5542 36.712C35.5164 36.8033 35.4969 36.9013 35.4969 37.0002C35.4969 37.1999 35.5762 37.3914 35.7175 37.5327C35.8587 37.6739 36.0502 37.7532 36.25 37.7532C36.4497 37.7532 36.6412 37.6739 36.7825 37.5327L39.25 35.0577V40.7502C39.25 40.9491 39.329 41.1398 39.4696 41.2805C39.6103 41.4212 39.801 41.5002 40 41.5002C40.1989 41.5002 40.3896 41.4212 40.5303 41.2805C40.6709 41.1398 40.75 40.9491 40.75 40.7502V35.0577L43.2175 37.5327C43.2872 37.603 43.3701 37.6588 43.4615 37.6968C43.5529 37.7349 43.6509 37.7545 43.75 37.7545C43.849 37.7545 43.947 37.7349 44.0384 37.6968C44.1298 37.6588 44.2127 37.603 44.2825 37.5327C44.3528 37.4629 44.4085 37.38 44.4466 37.2886C44.4847 37.1972 44.5043 37.0992 44.5043 37.0002C44.5043 36.9012 44.4847 36.8031 44.4466 36.7117C44.4085 36.6203 44.3528 36.5374 44.2825 36.4677Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_113_10463">
                                      <rect
                                        width="48"
                                        height="48"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              <p>
                                <span 
                          id="managestudents-bulkupload-uploadfile"
                          className="text-sm leading-5 font-medium text-indigo-600">
                                  Upload Excel file
                                </span>{" "}
                                <span className="text-sm text-gray-600">
                                  or drag and drop here
                                </span>
                              </p>
                              <p className="text-xs leading-4 font-normal text-gray-500 mt-1">
                                We will share a preview once uploaded
                              </p>
                            </div>
                          </>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
                {success ? (
                  <div className="border-2 px-4 py-6 rounded-lg  bg-white flex flex-row mt-12 justify-between items-center">
                    <SuccessMessage />
                  </div>
                ) : null}
                {errors ? (
                  <div className="border-2 px-4 py-6 rounded-lg  bg-white flex flex-row mt-12 justify-between items-center">
                    <ErrorMessage error={errors} />
                  </div>
                ) : null}
              </Spin>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
export default StudentBulkUpload;
