import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  GET_STUDENTS,
  GET_STUDENT_DOCUMENT_DETAILS,
  UPDATE_STUDENT_AND_SCHOOL,
  ISSUE_DOCUMENT,
  GET_LAST_TC_NUMBER,
} from "../views/documentsView/graphql";
import Tabs, { TabPane } from "rc-tabs";
import { GET_ACADEMIC_YEAR } from "../views/settingsView/graphql";
import { Spin, notification, Radio } from "antd";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { getSchoolID } from "../shared/getSchoolID";
import {
  getLanguageInputCode,
  numberToWords,
  containsNonLatinCodepoints,
} from "../utils";
import TransferCertificateView from "../views/documentsView/transferCertificateView";
import { useKeyPress } from "../utils/hooks";
import { useTranslation } from "react-i18next";
import { GET_SCHOOL_DETAILS } from "../graphql/queries";
import { UPDATE_SCHOOL } from "../graphql/mutations";
import axios from "axios";
import { PencilIcon } from "@heroicons/react/solid";
import useTransliteration from "../hooks/useTransliteration";
import useTracking from "../hooks/useTracking";

function TransferCertificate() {
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingFormat, setIsEditingFormat] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [isLoading, setIsLoading] = useState(false);
  const [nextSerialNumber, setNextSerialNumber] = useState(null);
  const [previousLanguage, setPreviousLanguage] = useState("en");
  const [lastIssuedDocumentNumber, setLastIssuedDocumentNumber] =
    useState(null);
  const [documentSchoolData, setDocumentSchoolData] = useState(null);
  const [documentStudentData, setDocumentStudentData] = useState(null);

  const [getTransliteratedData, { loading: isTransliterationLoading }] =
    useTransliteration();
  const { trackEvent } = useTracking();

  let navigate = useNavigate();
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const { i18n } = useTranslation();
  const translateKeys = [
    "board",
    "schoolMedium",
    "nationality",
    "caste",
    "religion",
    "motherTongue",
    "birthPlace",
    "country",
    "dateOfBirthInWords",
    "previousSchool",
    "previousSchoolClass",
    "admittedClass",
    "academicProgress",
    "behaviour",
    "reasonForTransfer",
    "remarks",
    "class",
    "division",
    "subCaste",
    "studentStateName",
  ];

  const handleTranslateText = async (
    textList,
    targetLang = "mr-IN",
    mainTranslateKeys = translateKeys
  ) => {
    if (previousLanguage === targetLang) {
      return textList;
    }
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/translateText`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        contents: textList,
        sourceLanguageCode: previousLanguage,
        targetLanguageCode: targetLang,
      },
    };
    const translatedObj = {};
    const res = await axios.request(config);
    res?.data?.forEach((item, index) => {
      if (item?.translatedText !== "-") {
        translatedObj[mainTranslateKeys[index]] = item?.translatedText;
      } else {
        translatedObj[mainTranslateKeys[index]] = "";
      }
    });
    return translatedObj;
  };

  const translateSchoolData = async (targetLang, schoolData) => {
    let data = schoolData || documentSchoolData;
    const schoolLanguageData = data.settings?.translation?.[targetLang];
    if (schoolLanguageData) {
      data = {
        ...data,
        ...schoolLanguageData,
      };
      setDocumentSchoolData({
        ...documentSchoolData,
        ...data,
      });
    } else if (targetLang === "en") {
      // if no saved data for enlish lang and translating back to english, then use original data
      const school = dbSchoolData.schools_by_pk;
      let dbData = {
        image: school.image,
        trustName: school.trustName,
        schoolName: school.schoolName,
        cityName: school?.address?.city?.city_name,
        stateName: school?.address?.city?.district?.state?.state_name,
        udiseNumber: school.udiseNumber,
        schoolRegistrationNumber: school?.registrationNumber,
        mobileNumber: school?.mobileNumber,
        board: school?.boardName,
        schoolMedium: school?.medium,
        email: school?.email,
        settings: school?.settings,
      };
      setDocumentSchoolData({
        ...documentSchoolData,
        ...dbData,
      });
    } else {
      let textList = [data?.board || "-", data?.schoolMedium || "-"];
      const translatedSchoolData = await handleTranslateText(
        textList,
        targetLang
      );
      let transliterateTextData = {
        trustName: data?.trustName?.trim(),
        schoolName: data?.schoolName?.trim(),
        cityName: data?.cityName,
        stateName: data?.stateName,
      };
      const transliteratedSchoolData = await getTransliteratedData({
        textObj: transliterateTextData,
        inputCode: getLanguageInputCode(targetLang),
      });
      setDocumentSchoolData({
        ...documentSchoolData,
        ...data,
        ...translatedSchoolData,
        ...transliteratedSchoolData,
      });
    }
  };

  const translateStudentData = async (targetLang, studentData) => {
    let data = studentData || documentStudentData;
    const hasStudentData = data?.studentName;
    if (!hasStudentData) {
      return;
    }
    setIsLoading(true);
    const studentLanguageData = data?.additionalInfo?.translation?.[targetLang];
    const overrideTranslateKeys = [];
    const studentStateName =
      data?.studentStateName || documentSchoolData?.stateName;
    let textList = [
      documentSchoolData?.board || "-",
      documentSchoolData?.schoolMedium || "-",
      data.nationality || "-",
      data.caste || "-",
      data.religion || "-",
      data.motherTongue || "-",
      data.birthPlace || "-",
      data.country || "-",
      data.dateOfBirthInWords,
      data.previousSchool || "-",
      data.previousSchoolClass || "-",
      data.admittedClass || "-",
      data.academicProgress || "-",
      data.behaviour || "-",
      data.reasonForTransfer || "-",
      data.remarks || "-",
      data.class || "-",
      data.leftClass || "-",
      data.subCaste || "-",
      studentStateName || "-",
    ];
    const finalList = [];
    translateKeys.forEach((key, listIndex) => {
      if (
        !studentLanguageData?.[key] &&
        !containsNonLatinCodepoints(textList[listIndex]) &&
        textList[listIndex] !== "-"
      ) {
        overrideTranslateKeys.push(key);
        finalList.push(textList[listIndex]);
      }
    });
    let translatedData = {};
    if (overrideTranslateKeys.length > 0) {
      translatedData = await handleTranslateText(
        finalList,
        targetLang,
        overrideTranslateKeys
      );
    }
    let transliterateTextData = {};
    if (
      data?.studentName?.length > 0 &&
      !containsNonLatinCodepoints(data?.studentName)
    ) {
      transliterateTextData.studentName = data?.studentName?.trim();
    }
    if (
      data?.motherName?.length > 0 &&
      !containsNonLatinCodepoints(data?.motherName)
    ) {
      transliterateTextData.motherName = data?.motherName;
    }
    let transliteratedData = {};
    if (Object.keys(transliterateTextData).length > 0) {
      transliteratedData = await getTransliteratedData({
        textObj: transliterateTextData,
        inputCode: getLanguageInputCode(targetLang),
      });
    }
    setDocumentStudentData({
      ...documentStudentData,
      ...(studentLanguageData || {}),
      ...data,
      ...translatedData,
      ...transliteratedData,
    });
    setIsLoading(false);
  };

  const handleLanguageChange = async (value, schoolData) => {
    setIsLoading(true);
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
    await translateSchoolData(value, schoolData);
    await translateStudentData(value);
    setIsLoading(false);
  };

  const { loading: schoolLoading, data: dbSchoolData } = useQuery(
    GET_SCHOOL_DETAILS,
    {
      variables: {
        schoolId: getSchoolID(),
      },
      onCompleted: (res) => {
        const school = res.schools_by_pk;
        let schoolData = {
          image: school.image,
          slogan: school.slogan,
          indexNumber: school?.index_number,
          trustName: school.trustName,
          schoolName: school.schoolName,
          cityName: school?.address?.city?.city_name,
          stateName: school?.address?.city?.district?.state?.state_name,
          udiseNumber: school.udiseNumber,
          schoolRegistrationNumber: school?.registrationNumber,
          mobileNumber: school?.mobileNumber,
          board: school?.boardName,
          schoolMedium: school?.medium,
          email: school?.email,
          settings: school?.settings,
        };
        // get language from school settings documents
        let currentLanguage = "en";
        const documents = school?.settings?.documents;
        if (documents?.length) {
          // find document with code "tc"
          const tcDocument = documents.find(
            (document) => document.code === "tc"
          );
          if (tcDocument) {
            currentLanguage = tcDocument.languageCode;
            setSelectedLanguage(tcDocument.languageCode);
            i18n.changeLanguage(tcDocument.languageCode);
          }
        }
        setDocumentSchoolData({
          ...documentSchoolData,
          ...schoolData,
        });
        // find language specific data in settings
        const languageData =
          schoolData.settings?.translation?.[currentLanguage];
        if (languageData) {
          schoolData = {
            ...schoolData,
            ...languageData,
          };
          setDocumentSchoolData({
            ...documentSchoolData,
            ...schoolData,
          });
        } else {
          if (currentLanguage !== "en") {
            translateSchoolData(currentLanguage, schoolData);
          }
        }
      },
    }
  );

  const { loading: studentsLoading, data: studentsData } = useQuery(
    GET_STUDENTS,
    {
      variables: {
        schoolId: getSchoolID(),
        name: `%${search}%`,
        documentType: "Transfer Certificate",
      },
    }
  );

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && downPress) {
      setCursor((prevState) =>
        prevState < students.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && !documentStudentData && enterPress) {
      handleSelectStudent(students[cursor]);
    }
  }, [cursor, enterPress]);

  useEffect(() => {
    const students = studentsData?.students || [];
    if (students.length && hovered) {
      setCursor(students.indexOf(hovered));
    }
  }, [hovered]);

  const { loading: lastTcLoading } = useQuery(GET_LAST_TC_NUMBER, {
    variables: {
      schoolId: getSchoolID(),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      const lastIssued = res?.documents_issuance_history?.[0]?.document_number;
      setLastIssuedDocumentNumber(lastIssued);
      // increment last issued document number if it is a number and set to next number
      if (!isNaN(parseInt(lastIssued)) && lastIssued !== null) {
        setNextSerialNumber(parseInt(lastIssued) + 1);
      }
    },
  });

  const [updateSchool] = useMutation(UPDATE_SCHOOL, {
    refetchQueries: [
      {
        query: GET_SCHOOL_DETAILS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const {
    loading: academicYearLoading,
    error: academicYearError,
    data: academicYearData,
  } = useQuery(GET_ACADEMIC_YEAR, {
    variables: {
      schoolId: getSchoolID(),
    },
  });

  const [issueDocument] = useMutation(ISSUE_DOCUMENT, {
    refetchQueries: [
      {
        query: GET_STUDENT_DOCUMENT_DETAILS,
        variables: {
          studentId: documentStudentData?.studentId,
          documentType: "Transfer Certificate",
        },
      },
      {
        query: GET_LAST_TC_NUMBER,
        variables: {
          schoolId: getSchoolID(),
        },
      },
    ],
  });

  const [
    getStudentTCDetails,
    { loading: tcLoading, data: studentDocumentDetails },
  ] = useLazyQuery(GET_STUDENT_DOCUMENT_DETAILS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (tcData) => {
      const student = tcData?.students_by_pk;
      const dobDayWords = numberToWords(moment(student?.dob).format("D"));
      const dobMonthWords = moment(student?.dob).format("MMMM");
      const dobYearWords = numberToWords(moment(student?.dob).format("YYYY"));
      const issuedDoc = student?.documents_issuance_histories?.[0];
      // split division_name and get the last character as division
      const divisionName = student?.division?.division_name;
      let studentRef = student?.student_ref;
      // check for system generated student ref, make it blank if exists
      if (studentRef?.startsWith("GUID")) {
        studentRef = "";
      }
      let studentDocumentData = {
        ...documentStudentData,
        serialNumber: issuedDoc?.document_number,
        generalRegisterNumber: student?.gr_no,
        date: issuedDoc
          ? moment(issuedDoc?.issuance_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        studentRef: studentRef,
        studentId: student?.id,
        uidNumber: student?.aadhaar,
        studentName: student?.full_name,
        motherName: student?.mother_name,
        nationality: student?.nationality,
        caste: student?.caste,
        subCaste: student?.sub_caste,
        religion: student?.religion,
        motherTongue: student?.mother_tongue,
        birthPlace: student?.place_of_birth,
        country: "India",
        studentStateName: "Maharashtra",
        dateOfBirth: student?.dob
          ? moment(student?.dob).format("DD/MM/YYYY")
          : null,
        dateOfBirthInWords: `${dobDayWords} ${dobMonthWords} ${dobYearWords}`,
        previousSchool: student?.last_school,
        previousSchoolClass: student?.last_school_class,
        dateOfAdmission: student?.enroll_date
          ? moment(student?.enroll_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        admittedClass: student?.admitted_class,
        leftClass: student?.left_class,
        academicProgress: student?.student_progress,
        behaviour: student?.conduct,
        transferDate: student?.transfer_date
          ? moment(student?.transfer_date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        reasonForTransfer: student?.transfer_reason,
        remarks: student?.remarks,
        class: student?.division?.class?.class_name,
        division: divisionName,
        image: student?.school?.image,
        isTransferred: student?.transfer_date && issuedDoc,
        additionalInfo: student?.additional_info,
      };
      let currentLanguage = "en";
      const documents = documentSchoolData?.settings?.documents;
      if (documents?.length) {
        // find document with code "tc"
        const tcDocument = documents.find((document) => document.code === "tc");
        if (tcDocument) {
          currentLanguage = tcDocument.languageCode;
        }
      }
      setDocumentStudentData(studentDocumentData);
      const languageData =
        student.additional_info?.translation?.[currentLanguage];
      if (languageData) {
        studentDocumentData = {
          ...studentDocumentData,
          ...languageData,
          date: languageData.issuanceDate || studentDocumentData.date,
        };
        setDocumentStudentData({
          ...studentDocumentData,
        });
      }
      // translate data if language data is not available
      const tcDocument = documentSchoolData?.settings?.documents?.find(
        (document) => document.code === "tc"
      );
      // Translate document if language is not english
      if (tcDocument?.languageCode !== "en") {
        translateStudentData(tcDocument?.languageCode, studentDocumentData);
      }
    },
  });

  const [updateStudentAndSchool] = useMutation(UPDATE_STUDENT_AND_SCHOOL, {
    refetchQueries: [
      {
        query: GET_SCHOOL_DETAILS,
        variables: {
          schoolId: getSchoolID(),
        },
      },
      {
        query: GET_STUDENT_DOCUMENT_DETAILS,
        variables: {
          studentId: studentDocumentDetails?.students_by_pk?.id,
          documentType: "Transfer Certificate",
        },
      },
    ],
  });

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleEditFormat = () => {
    setIsEditingFormat(!isEditingFormat);
  };

  const handleSelectStudent = (student) => {
    setSearch("");
    // query for student document details and set to formDetails when data is returned
    getStudentTCDetails({
      variables: {
        studentId: student.id,
        documentType: "Transfer Certificate",
      },
    });
  };

  const getTranslatedData = async (textList, sourceLang, targetLang) => {
    setIsLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/translateText`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        contents: textList,
        sourceLanguageCode: sourceLang,
        targetLanguageCode: targetLang,
      },
    };
    let res = await axios.post(config.url, config.data, config.headers);
    setIsLoading(false);
    return res.data;
  };

  const handleSave = async (tcFormDetails) => {
    if (!nextSerialNumber) {
      notification.error({
        message: "Please enter serial number",
      });
      return;
    }
    trackEvent("Save TC");
    let studentAdditionalInfo = {
      ...documentStudentData?.additionalInfo,
    };
    if (!studentAdditionalInfo.translation) {
      studentAdditionalInfo.translation = {};
    }
    const studentTranslationData = {
      ...studentAdditionalInfo.translation,
      [selectedLanguage]: {
        ...studentAdditionalInfo.translation?.[selectedLanguage],
        generalRegisterNumber: tcFormDetails.generalRegisterNumber,
        studentRef: tcFormDetails.studentRef,
        uidNumber: tcFormDetails.uidNumber,
        studentName: tcFormDetails.studentName,
        motherName: tcFormDetails.motherName,
        country: tcFormDetails.country,
        nationality: tcFormDetails.nationality,
        caste: tcFormDetails.caste,
        subCaste: tcFormDetails.subCaste,
        religion: tcFormDetails.religion,
        motherTongue: tcFormDetails.motherTongue,
        studentStateName: tcFormDetails.studentStateName,
        birthPlace: tcFormDetails.birthPlace,
        dateOfBirth: moment(tcFormDetails.dateOfBirth, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        dateOfBirthInWords: tcFormDetails?.dateOfBirthInWords,
        previousSchool: tcFormDetails.previousSchool,
        previousSchoolClass: tcFormDetails.previousSchoolClass,
        dateOfAdmission: moment(
          tcFormDetails.dateOfAdmission,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY"),
        admittedClass: tcFormDetails.admittedClass,
        academicProgress: tcFormDetails.academicProgress,
        behaviour: tcFormDetails.behaviour,
        transferDate: moment(tcFormDetails.transferDate, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        reasonForTransfer: tcFormDetails.reasonForTransfer,
        remarks: tcFormDetails.remarks,
        leftClass: tcFormDetails.leftClass,
        issuanceDate: tcFormDetails?.date || moment().format("DD/MM/YYYY"),
      },
    };
    studentAdditionalInfo.translation = studentTranslationData;
    let schoolSettings = { ...documentSchoolData?.settings };
    const hasTranslationData = schoolSettings.translation;
    if (!hasTranslationData) {
      schoolSettings.translation = {};
    }
    const schoolTranslationData = {
      ...schoolSettings.translation,
      [selectedLanguage]: {
        ...schoolSettings.translation?.[selectedLanguage],
        indexNumber: tcFormDetails.indexNumber,
        udiseNumber: tcFormDetails.udiseNumber,
        schoolRegistrationNumber: tcFormDetails.schoolRegistrationNumber,
        mobileNumber: tcFormDetails.mobileNumber,
        board: tcFormDetails.board,
        schoolMedium: tcFormDetails.schoolMedium,
        email: tcFormDetails.email,
      },
    };
    schoolSettings.translation = schoolTranslationData;
    updateStudentAndSchool({
      variables: {
        studentId: tcFormDetails.studentId,
        schoolId: getSchoolID(),
        studentData: {
          additional_info: studentAdditionalInfo,
        },
        schoolData: {
          settings: schoolSettings,
        },
      },
    });
    setIsEditing(false);
  };

  const handleSaveFormat = async (formData) => {
    let updatedSettings = { ...documentSchoolData?.settings };
    const englishTemplate = "mh_tc_english";
    const marathiTemplate = "mh_tc_marathi";
    const marathiInputCode = "mr-t-i0-und";
    // update or add document to settings
    const hasDocument =
      updatedSettings.documents &&
      updatedSettings.documents.find((doc) => doc.code === "tc");
    if (!hasDocument) {
      const currentDocuments = updatedSettings?.documents || [];
      updatedSettings.documents = [...currentDocuments];
      setPreviousLanguage("en");
      updatedSettings.documents.push({
        name: "Transfer Certificate",
        code: "tc",
        language: selectedLanguage === "en" ? "English" : "Marathi",
        languageCode: selectedLanguage === "en" ? "en" : "mr-IN",
        googleInputCode: selectedLanguage === "en" ? "en" : marathiInputCode,
        template: selectedLanguage === "en" ? englishTemplate : marathiTemplate,
      });
    } else {
      const documentIndex = updatedSettings.documents.findIndex(
        (document) => document.code === "tc"
      );
      if (documentIndex > -1) {
        const documents = [...updatedSettings.documents];
        const tcDocument = { ...documents[documentIndex] };
        setPreviousLanguage(tcDocument.languageCode);
        tcDocument.language = selectedLanguage === "en" ? "English" : "Marathi";
        tcDocument.languageCode = selectedLanguage === "en" ? "en" : "mr-IN";
        tcDocument.googleInputCode =
          selectedLanguage === "en" ? "en" : marathiInputCode;
        tcDocument.template =
          selectedLanguage === "en" ? englishTemplate : marathiTemplate;
        documents[documentIndex] = tcDocument;
        updatedSettings.documents = documents;
      }
    }
    // udpate translated data in settings
    const hasTranslationData = updatedSettings.translation;
    if (!hasTranslationData) {
      updatedSettings.translation = {};
    }
    const translationData = {
      ...updatedSettings.translation,
      [selectedLanguage]: {
        slogan: formData.slogan,
        trustName: formData.trustName,
        schoolName: formData.schoolName,
        cityName: formData.cityName,
        stateName: formData.stateName,
        udiseNumber: formData.udiseNumber,
        indexNumber: formData.indexNumber,
        schoolRegistrationNumber: formData.schoolRegistrationNumber,
        mobileNumber: formData.mobileNumber,
        board: formData.board,
        schoolMedium: formData.schoolMedium,
        email: formData.email,
      },
    };
    updatedSettings.translation = translationData;
    trackEvent("Save TC Format");
    updateSchool({
      variables: {
        schoolId: getSchoolID(),
        schoolData: {
          settings: updatedSettings,
        },
      },
    });
    setIsEditingFormat(false);
  };

  const handleIssueDocument = (tcFormDetails) => {
    // TODO: Refactor this code with async/await and clean small functions
    trackEvent("Issue TC");
    handleDownloadDocument(tcFormDetails, true);
  };

  const handleCancelEditFormat = () => {
    toggleEditFormat();
    // reset language to default
    // check if language is english in school settings
    const tcDocument = documentSchoolData?.settings?.documents?.find(
      (document) => document.code === "tc"
    );
    if (tcDocument?.languageCode === "en") {
      handleLanguageChange("en");
    }
    if (tcDocument?.languageCode === "mr-IN") {
      handleLanguageChange("mr-IN");
    }
  };

  const handleDownloadDocument = (tcFormData, isIssued) => {
    setIsLoading(true);
    trackEvent("Download TC");
    // copy formattedTCData and replace with "-" string if it is null
    const formattedData = { ...documentSchoolData, ...documentStudentData };
    Object.keys(formattedData).forEach((key) => {
      if (
        formattedData[key] === null ||
        formattedData[key] === "null" ||
        formattedData[key] === "undefined"
      ) {
        formattedData[key] = "";
      }
    });
    const student = documentStudentData;
    const requestTCData = {
      ...formattedData,
    };
    const getTCPayload = {
      ...requestTCData,
      serialNumber: formattedData?.serialNumber
        ? `${formattedData?.serialNumber}`
        : `${nextSerialNumber}`,
      dateOfBirthInWords:
        tcFormData?.dateOfBirthInWords || requestTCData?.dateOfBirthInWords,
      division: tcFormData?.leftClass || requestTCData?.leftClass,
    };
    // remove settings from payload
    delete getTCPayload["settings"];
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getTC`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: selectedLanguage === "en" ? "english" : "marathi",
        isDuplicate: student?.isTransferred ? true : false,
        data: {
          ...getTCPayload,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${formattedData.studentName}_Transfer_Certificate`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        if (tcFormData && isIssued) {
          handleSave(tcFormData);
          const academicYearId = academicYearData?.acadamic_years?.[0]?.id;
          issueDocument({
            variables: {
              studentId: tcFormData.studentId,
              schoolId: getSchoolID(),
              documentNumber: `${nextSerialNumber}`,
              documentType: "Transfer Certificate",
              issuanceDate: moment(tcFormData?.date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
              academicYearId,
            },
          });
          // set student as inactive
          updateStudentAndSchool({
            variables: {
              studentId: tcFormData.studentId,
              schoolId: getSchoolID(),
              studentData: {
                is_active: false,
                transfer_date: moment(
                  tcFormData.transferDate,
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD"),
                transfer_reason: tcFormData.reasonForTransfer,
              },
              schoolData: {},
            },
          });
          setLastIssuedDocumentNumber(nextSerialNumber);
          if (!isNaN(parseInt(nextSerialNumber)) && nextSerialNumber !== null) {
            setNextSerialNumber(parseInt(nextSerialNumber) + 1);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleDownloadSampleDocument = async () => {
    trackEvent("Download Sample TC");
    const sampleData = {
      slogan: documentSchoolData.slogan,
      trustName: documentSchoolData.trustName,
      schoolName: documentSchoolData.schoolName,
      cityName: documentSchoolData.cityName,
      stateName: documentSchoolData.stateName,
      udiseNumber: documentSchoolData.udiseNumber,
      indexNumber: documentSchoolData.indexNumber,
      schoolRegistrationNumber: documentSchoolData.schoolRegistrationNumber,
      mobileNumber: documentSchoolData.mobileNumber,
      board: documentSchoolData.board,
      schoolMedium: documentSchoolData.schoolMedium,
      email: documentSchoolData.email,
      serialNumber: "40000",
      generalRegisterNumber: "3000",
      date: "01/04/2022",
      studentRef: "ID:2011272812007040055",
      studentId: "4000",
      uidNumber: "123412341234",
      studentName: "Student Name",
      motherName: "Mother Name",
      nationality: "Indian",
      caste: "Maratha",
      religion: "Hindu",
      motherTongue: "Hindi",
      birthPlace: "Pune",
      country: "India",
      dateOfBirth: "01/06/2000",
      dateOfBirthInWords: "One June Two Thousand",
      previousSchool: "Sane Guruji sec.school Latur",
      previousSchoolClass: "1",
      dateOfAdmission: "01/04/2021",
      admittedClass: "2A",
      academicProgress: "good",
      behaviour: "good",
      transferDate: "01/04/2022",
      reasonForTransfer: "Transfer to other school",
      remarks: "good",
      class: "2",
      division: "2A",
      image: documentSchoolData?.image,
      isTransferred: {
        __typename: "documents_issuance_history",
        document_number: "43252",
        document_type: "Transfer Certificate",
        issuance_date: "2022-04-01",
      },
    };
    let translatedSampleData = { ...sampleData };
    if (selectedLanguage !== "en") {
      const textList = [
        sampleData.studentName || "-",
        sampleData.motherName || "-",
        sampleData.nationality || "-",
        sampleData.caste || "-",
        sampleData.religion || "-",
        sampleData.motherTongue || "-",
        sampleData.birthPlace || "-",
        sampleData.country || "-",
        sampleData.dateOfBirthInWords,
        sampleData.previousSchool || "-",
        sampleData.previousSchoolClass || "-",
        sampleData.admittedClass || "-",
        sampleData.academicProgress || "-",
        sampleData.behaviour || "-",
        sampleData.reasonForTransfer || "-",
        sampleData.remarks || "-",
        sampleData.class || "-",
        sampleData.leftClass || "-",
        sampleData.subCaste || "-",
      ];
      const translatedTextList = await getTranslatedData(
        textList,
        "en",
        selectedLanguage
      );
      translatedSampleData = {
        ...sampleData,
        studentName:
          translatedTextList?.[0]?.translatedText !== "-"
            ? translatedTextList?.[0]?.translatedText
            : sampleData.studentName,
        motherName:
          translatedTextList?.[1]?.translatedText !== "-"
            ? translatedTextList?.[1]?.translatedText
            : sampleData.motherName,
        nationality:
          translatedTextList?.[2]?.translatedText !== "-"
            ? translatedTextList?.[2]?.translatedText
            : sampleData.nationality,
        caste:
          translatedTextList?.[3]?.translatedText !== "-"
            ? translatedTextList?.[3]?.translatedText
            : sampleData.caste,
        religion:
          translatedTextList?.[4]?.translatedText !== "-"
            ? translatedTextList?.[4]?.translatedText
            : sampleData.religion,
        motherTongue:
          translatedTextList?.[5]?.translatedText !== "-"
            ? translatedTextList?.[5]?.translatedText
            : sampleData.motherTongue,
        birthPlace:
          translatedTextList?.[6]?.translatedText !== "-"
            ? translatedTextList?.[6]?.translatedText
            : sampleData.birthPlace,
        country:
          translatedTextList?.[7]?.translatedText !== "-"
            ? translatedTextList?.[7]?.translatedText
            : sampleData.country,
        dateOfBirthInWords:
          translatedTextList?.[8]?.translatedText !== "-"
            ? translatedTextList?.[8]?.translatedText
            : sampleData.dateOfBirthInWords,
        previousSchool:
          translatedTextList?.[9]?.translatedText !== "-"
            ? translatedTextList?.[9]?.translatedText
            : sampleData.previousSchool,
        previousSchoolClass:
          translatedTextList?.[10]?.translatedText !== "-"
            ? translatedTextList?.[10]?.translatedText
            : sampleData.previousSchoolClass,
        admittedClass:
          translatedTextList?.[11]?.translatedText !== "-"
            ? translatedTextList?.[11]?.translatedText
            : sampleData.admittedClass,
        academicProgress:
          translatedTextList?.[12]?.translatedText !== "-"
            ? translatedTextList?.[12]?.translatedText
            : sampleData.academicProgress,
        behaviour:
          translatedTextList?.[13]?.translatedText !== "-"
            ? translatedTextList?.[13]?.translatedText
            : sampleData.behaviour,
        reasonForTransfer:
          translatedTextList?.[14]?.translatedText !== "-"
            ? translatedTextList?.[14]?.translatedText
            : sampleData.reasonForTransfer,
        remarks:
          translatedTextList?.[15]?.translatedText !== "-"
            ? translatedTextList?.[15]?.translatedText
            : sampleData.remarks,
        class:
          translatedTextList?.[16]?.translatedText !== "-"
            ? translatedTextList?.[16]?.translatedText
            : sampleData.class,
        leftClass:
          translatedTextList?.[17]?.translatedText !== "-"
            ? translatedTextList?.[17]?.translatedText
            : sampleData.leftClass,
        subCaste:
          translatedTextList?.[18]?.translatedText !== "-"
            ? translatedTextList?.[18]?.translatedText
            : sampleData.subCaste,
      };
    }
    setIsLoading(true);
    // copy formattedTCData and replace with "-" string if it is null
    const formattedData = { ...sampleData };
    Object.keys(formattedData).forEach((key) => {
      if (
        formattedData[key] === null ||
        formattedData[key] === "null" ||
        formattedData[key] === "undefined"
      ) {
        formattedData[key] = "";
      }
    });
    const requestTCData = {
      ...formattedData,
      ...translatedSampleData,
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/docs/getTC`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        language: selectedLanguage === "en" ? "english" : "marathi",
        isDuplicate: false,
        data: {
          ...requestTCData,
          serialNumber: formattedData?.serialNumber
            ? `${formattedData?.serialNumber}`
            : `${nextSerialNumber}`,
        },
      },
    };
    axios(config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `Sample_Transfer_Certificate`;
        const docFormat = "pdf";
        link.setAttribute("download", `${fileName}.${docFormat}`);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const schoolDataToDisplay = {
    image: documentSchoolData?.image,
    trustName: documentSchoolData?.trustName,
    schoolName: documentSchoolData?.schoolName,
    cityName: documentSchoolData?.cityName,
    stateName: documentSchoolData?.stateName,
    udiseNumber: documentSchoolData?.udiseNumber,
    schoolRegistrationNumber: documentSchoolData?.schoolRegistrationNumber,
    mobileNumber: documentSchoolData?.mobileNumber,
    board: documentSchoolData?.board,
    schoolMedium: documentSchoolData?.schoolMedium,
    email: documentSchoolData?.email,
  };

  return (
    <div className="flex flex-col ml-6 pl-0 md:space-y-4 h-screen w-screen overflow-x-hidden">
      <div className="flex flex-row gap-x-4 items-center mb-6 justify-between p-4">
        <p className="text-2xl text-left font-bold flex-col m-0">
          Transfer Certificate
        </p>
        <Button
          id="documents-transfer-back"
          onClick={() => navigate(-1)}
          buttonStyle="secondary"
        >
          Back to Documents
        </Button>
      </div>
      <Tabs
        activeKey={activeTab.toString()}
        onChange={(key) => {
          if (!isEditingFormat && !isEditing) {
            setActiveTab(Number(key));
            if (key === "2") {
              setDocumentStudentData(null);
              setSearch(null);
            }
          }
        }}
        className="custom-tabs overflow-y-auto px-4"
        animated={{ inkBar: true, tabPane: false }}
      >
        <TabPane tab="Issue TC" key={1} className="relative mt-4 mb-6">
          <div className="relative">
            {!isEditing && (
              <div className="relative">
                <p className="text-left font-semibold text-black">
                  Select a student
                </p>
                <div className="flex relative mt-2">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id='"form-subscribe-Filter'
                    className="pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Search by student name"
                    autoComplete="off"
                    onChange={(e) => {
                      e.preventDefault();
                      if (
                        e.target.value.length >= 2 ||
                        e.target.value.length === 0
                      ) {
                        setSearch(e.target.value);
                      }
                    }}
                  />
                  <Button
                    buttonStyle="success"
                    className="ml-6"
                    onClick={handleDownloadSampleDocument}
                  >
                    Download Sample Document
                  </Button>
                </div>
                {studentsData && search && (
                  <div className="bg-white absolute z-30 w-11/12 mt-2 border border-gray-300 rounded-md">
                    {studentsData.students &&
                      studentsData?.students?.map((student, index) => {
                        const isTransferred =
                          student?.transfer_date &&
                          student?.documents_issuance_histories.find(
                            (doc) =>
                              doc.document_type === "Transfer Certificate"
                          );
                        const isActive = index === cursor;
                        const isDeleted =
                          student?.is_active === false && !student?.division;
                        return (
                          <button
                            className={`flex flex-col px-9 py-2 items-start justify-center border-b w-full text-black font-bold  ${
                              isActive && "bg-gray-200"
                            }`}
                            key={student.id}
                            onClick={() => {
                              handleSelectStudent(student);
                            }}
                            onMouseEnter={() => {
                              setHovered(student);
                            }}
                            onMouseLeave={() => {
                              setHovered(null);
                            }}
                          >
                            {`${student.full_name} • ${
                              student?.division?.division_name || "NA"
                            } • ${student.mother_name || "-"} • ${
                              student?.dob
                                ? moment(student.dob).format("DD MMM YYYY")
                                : "NA"
                            } • UID no.${
                              !student?.aadhaar ||
                              student?.aadhaar === "undefined" ||
                              student?.aadhaar === "null"
                                ? "-"
                                : student?.aadhaar
                            } • ${
                              isTransferred
                                ? "Transferred"
                                : isDeleted
                                ? "Deleted"
                                : "Active"
                            }`}
                          </button>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
            <div className="flex my-3 gap-x-9">
              <p className="text-black font">
                Serial number of the last issued TC :{" "}
                <strong className="text-red-500">
                  {lastIssuedDocumentNumber || "NA"}
                </strong>
              </p>
              <p className="text-black font">
                Current serial number :{" "}
                <input
                  type="text"
                  className="w-32 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 pl-1"
                  value={nextSerialNumber}
                  onChange={(e) => setNextSerialNumber(e.target.value)}
                  disabled={
                    !documentStudentData || !!documentStudentData?.isTransferred
                  }
                />
              </p>
              <p className="text-black font">
                Selected student :{" "}
                <strong className="text-black font-semibold">
                  {documentStudentData?.studentName || "-"}
                </strong>
              </p>
            </div>
            <Spin spinning={tcLoading || isLoading}>
              <TransferCertificateView
                isMasked={studentsData?.students?.length > 0 && search}
                isEditing={isEditing}
                data={{
                  ...documentSchoolData,
                  ...documentStudentData,
                }}
                isEditable={documentStudentData}
                translatedData={{
                  ...documentSchoolData,
                  ...documentStudentData,
                }}
                languageInputCode={getLanguageInputCode(selectedLanguage)}
                nextSerialNumber={
                  !documentStudentData || !!documentStudentData?.isTransferred
                    ? null
                    : nextSerialNumber
                }
                setNextSerialNumber={setNextSerialNumber}
                onCancel={toggleEdit}
                getTranslatedText={getTranslatedData}
                onEdit={toggleEdit}
                onSave={handleSave}
                onIssueDocument={handleIssueDocument}
                onDownloadDocument={handleDownloadDocument}
                selectedLanguage={selectedLanguage}
                containerStyle={{
                  fontFamily: selectedLanguage === "en" ? "" : "Mukta",
                }}
              />
            </Spin>
          </div>
        </TabPane>
        <TabPane
          tab="Create your own format"
          key={2}
          className="relative mt-4 mb-6"
        >
          <div className="flex justify-between">
            <div className="text-left">
              <label className="font-semibold text-black">
                Select language
              </label>
              <div className="flex gap-x-3 mt-1">
                <Radio.Group
                  onChange={(e) => {
                    handleLanguageChange(e.target.value);
                  }}
                  value={selectedLanguage}
                  style={{
                    display: "flex",
                    columnGap: "0.75rem",
                  }}
                  disabled={!isEditingFormat}
                >
                  <div
                    id="documents-transfer-edit-english"
                    className="bg-white py-2 px-3 rounded-lg shadow-md"
                  >
                    <Radio value={"en"} checked={selectedLanguage === "en"}>
                      <strong
                        className={`font-semibold ${
                          selectedLanguage === "en"
                            ? "text-black"
                            : "text-gray-600"
                        } ${!isEditingFormat && "opacity-50"}`}
                      >
                        English
                      </strong>
                    </Radio>
                  </div>
                  <div
                    id="documents-transfer-edit-marathi"
                    className="bg-white py-2 px-3 rounded-lg shadow-md"
                  >
                    <Radio
                      value={"mr-IN"}
                      checked={selectedLanguage === "mr-IN"}
                    >
                      <strong
                        className={`font-semibold ${
                          selectedLanguage === "mr-IN"
                            ? "text-black"
                            : "text-gray-600"
                        }  ${!isEditingFormat && "opacity-50"}`}
                      >
                        Marathi
                      </strong>
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
              {!isEditingFormat && (
                <p className="mt-2 text-cancel font-semibold">
                  Please click on the edit format to change the school details
                  or the transfer certificate language.
                </p>
              )}
              {isEditingFormat && (
                <p className="mt-2 text-cancel font-semibold">
                  Please select the language for the transfer certificate.
                </p>
              )}
            </div>
            <Button
              id="documents-transfer-edit-format-1"
              buttonStyle="primary"
              className="h-12 mr-6"
              onClick={() => {
                toggleEditFormat();
              }}
            >
              <PencilIcon className="h-4 w-4" aria-hidden="true" />
              <span className="ml-2">Edit Format</span>
            </Button>
          </div>
          <Spin spinning={tcLoading || isLoading}>
            <TransferCertificateView
              isMasked={studentsData?.students?.length > 0 && search}
              languageInputCode={getLanguageInputCode(selectedLanguage)}
              isEditing={isEditingFormat}
              data={documentSchoolData}
              translatedData={schoolDataToDisplay}
              onCancel={handleCancelEditFormat}
              onEdit={toggleEditFormat}
              onSave={handleSaveFormat}
              containerStyle={{
                marginTop: "25px",
                fontFamily: selectedLanguage === "en" ? "" : "Mukta",
              }}
              isEditFormat
              hideDownload
            />
          </Spin>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default TransferCertificate;
