import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import moment from "moment";
import {
  GET_SETTINGS,
  CREATE_CLASSES_INIT,
  CREATE_CLASSES,
  UPDATE_SCHOOL,
  GET_STATES,
  GET_DISTRICTS,
  GET_CITIES,
  ADD_ADDRESS,
  UPDATE_USER_ADDRESS,
  GET_GROUPS,
  ADD_GROUPS,
  GET_BOARD_LIST,
  ADD_CITY,
} from "../pages/Settings/graphql";
import { CREATE_SCHOOL } from "../views/adminCreateSchoolView/graphql";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Cascader, Select, Upload, Button, message, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getSchoolID } from "../shared/getSchoolID";
import { v4 as uuid } from "uuid";
import { getS3Image, getS3Bucket } from "../shared/s3";
import { MultiSelect } from "react-multi-select-component";
import {
  managementTypeList,
  categoryList,
  DATE_FORMATS,
  schoolMediumList
} from "../utils/constants";
import { validateEmail } from "../utils/";

const { Option } = Select;
const imagesS3Bucket = getS3Bucket();
const CREATE_NEW_CITY = "CREATE_NEW_CITY";

const JUNE_FIRST = "06-01";
const MAY_THIRTY = "05-31";

const getAcademicYearDates = () => {
  const today = moment();
  const currentYear = today.year();
  const previousYear = today.year() - 1;
  const nextYear = today.year() + 1;

  let startDate, endDate, academicTitle;

  if (today.isAfter(`${currentYear}-${MAY_THIRTY}`)) {
    startDate = moment(`${currentYear}-${JUNE_FIRST}`).format(
      DATE_FORMATS.DDMMYYYY
    );
    endDate = moment(`${nextYear}-${MAY_THIRTY}`).format(DATE_FORMATS.DDMMYYYY);
    academicTitle = `${currentYear}-${nextYear}`;

    return {
      academicYearFrom: startDate,
      academicYearTo: endDate,
      academicYearTitle: academicTitle,
    };
  }
  startDate = moment(`${previousYear}-${JUNE_FIRST}`).format(
    DATE_FORMATS.DDMMYYYY
  );
  endDate = moment(`${currentYear}-${MAY_THIRTY}`).format(
    DATE_FORMATS.DDMMYYYY
  );
  academicTitle = `${previousYear}-${currentYear}`;

  return {
    academicYearFrom: startDate,
    academicYearTo: endDate,
    academicYearTitle: academicTitle,
  };
};

const FormCard = ({ children, overrideClassnames }) => {
  return (
    <>
      <div
        className={`${overrideClassnames} w-2/3 border-2 bg-white rounded-md mb-6 pt-6`}
      >
        {children}
      </div>
    </>
  );
};
const FormCol = ({ children, overrideClassnames }) => {
  return (
    <div className={`${overrideClassnames} flex flex-col flex-auto`}>
      {children}
    </div>
  );
};
const FormRow = ({ children, overrideClassnames }) => {
  return (
    <div
      className={`${overrideClassnames} flex flex-row justify-start gap-x-8 mb-4 px-6`}
    >
      {children}
    </div>
  );
};
const FormSideSection = ({ title, description, children }) => {
  return (
    <div className="w-1/4 text-left ">
      <p className="text-lg leading-6 font-medium text-gray-900 mb-1">
        {title}
      </p>
      <p className="mt-1 text-sm leading-5 font-normal text-gray-500 w-8/12  ">
        {description}
      </p>
      {children}
    </div>
  );
};

const AddSettingsForm = ({
  settingsData,
  createClassesInit,
  academicYearData,
  createClasses,
  isClassDetailsLoading,
  createAdmin,
  updateSchool,
  createSchool,
  states,
  getDistricts,
  districts,
  getCities,
  cities,
  groups,
  saveGroup,
  boards,
  isSchoolDetailsLoading,
}) => {
  let navigate = useNavigate();
  const classList = [
    { value: 1, label: "Class 1" },
    { value: 2, label: "Class 2" },
    { value: 3, label: "Class 3" },
    { value: 4, label: "Class 4" },
    { value: 5, label: "Class 5" },
    { value: 6, label: "Class 6" },
    { value: 7, label: "Class 7" },
    { value: 8, label: "Class 8" },
    { value: 9, label: "Class 9" },
    { value: 10, label: "Class 10" },
  ];
  const divisionsList = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
  ];

  const stateList = states?.map((state) => {
    return {
      value: state.id,
      label: state.state_name,
    };
  });
  const districtList = districts?.map((district) => {
    return {
      value: district.id,
      label: district.district_name,
    };
  });
  const cityList = cities?.map((city) => {
    return {
      value: city.id,
      label: city.city_name,
    };
  });
  const groupList = groups?.map((group) => {
    return {
      value: group.id,
      label: group.name,
    };
  });

  const defaultSchoolDetails = {
    image: settingsData?.schools_by_pk?.image,
    boardName: settingsData?.schools_by_pk?.board_name,
    title: settingsData?.schools_by_pk?.title,
    trustName: settingsData?.schools_by_pk?.trust_name,
    udiseNumber: settingsData?.schools_by_pk?.udise_number,
    addressLineOne: settingsData?.schools_by_pk?.address?.address_line_one,
    addressLineTwo: settingsData?.schools_by_pk?.address?.address_line_two,
    stateId: settingsData?.schools_by_pk?.address?.city?.district?.state?.id,
    stateName:
      settingsData?.schools_by_pk?.address?.city?.district?.state?.state_name,
    districtId: settingsData?.schools_by_pk?.address?.city?.district?.id,
    districtName:
      settingsData?.schools_by_pk?.address?.city?.district?.district_name,
    cityId: settingsData?.schools_by_pk?.address?.city?.id,
    cityName: settingsData?.schools_by_pk?.address?.city?.city_name,
    pinCode: settingsData?.schools_by_pk?.address?.pincode,
    mobileNumber: settingsData?.schools_by_pk?.mobile_number,
    email: settingsData?.schools_by_pk?.email,
    managementType: settingsData?.schools_by_pk?.management_type,
    categoryType: settingsData?.schools_by_pk?.category,
  };
  const defaultClassDetails = {
    classStartsFrom:
      settingsData?.schools_by_pk?.school_class_inits?.[0]?.class_from,
    classUpto: settingsData?.schools_by_pk?.school_class_inits?.[0]?.class_to,
    divisionsEachClass:
      settingsData?.schools_by_pk?.school_class_inits?.[0]
        ?.divisions_each_class,
  };
  const [schoolFormDetails, setSchoolFormDetails] = useState({});
  const [classFormDetails, setClassFormDetails] = useState({});
  const [adminFormDetails, setAdminFormDetails] = useState({});
  const [adminFormItems, setAdminFormItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedGroupList, setSelectedGroupList] = useState([]);

  // disable classes edit if already added
  const shouldDisableClasses =
    settingsData?.schools_by_pk?.school_class_inits?.length > 0 || true;

  useEffect(() => {
    const { academicYearFrom, academicYearTo, academicYearTitle } =
      getAcademicYearDates();
    setSchoolFormDetails({
      ...schoolFormDetails,
      academicYearTitle,
      academicYearFrom,
      academicYearTo,
    });
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    trigger,
    setError,
    setValue,
  } = useForm({
    criteriaMode: "all",
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "contactDetails", // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  );

  const [createCity] = useMutation(ADD_CITY);

  const handleClassDetailsSubmit = (e) => {
    e.preventDefault();
    const classesInitData = {
      class_from: classFormDetails.classStartsFrom?.[0],
      class_to: classFormDetails.classUpto?.[0],
      divisions_each_class: classFormDetails.divisionsEachClass?.[0],
      school_id: settingsData?.schools_by_pk?.id,
    };
    // map from class from and class to and create class name for each class number
    const classFrom = classesInitData.class_from;
    const classTo = classesInitData.class_to;
    const academicYearId = academicYearData?.acadamic_years?.[0]?.id;
    const classesData = [];
    for (let i = classFrom; i <= classTo; i++) {
      // create division array for number of divisions_each_class
      const divisionArray = [];
      for (let j = 1; j <= classesInitData.divisions_each_class; j++) {
        // get alphabet for division number
        const divisionLetter = String.fromCharCode(65 + j - 1);
        divisionArray.push({
          division_name: `${i}${divisionLetter}`,
          school_id: settingsData?.schools_by_pk?.id,
          acadamic_year_id: academicYearId,
        });
      }
      classesData.push({
        class_name: `${i}`,
        school_id: settingsData?.schools_by_pk?.id,
        divisions: {
          data: divisionArray,
        },
      });
    }
    createClasses({
      variables: {
        classesData: classesData,
      },
    }).then((res) => {
      createClassesInit({
        variables: {
          classesData: classesInitData,
        },
      });
    });
  };

  const addNewCity = async () => {
    if (
      schoolFormDetails?.cityName !== "" &&
      schoolFormDetails?.districtId !== null
    ) {
      // capitalize first letter of city
      const cityName =
        schoolFormDetails?.cityName.charAt(0).toUpperCase() +
        schoolFormDetails?.cityName.slice(1);
      const addCityData = {
        object: {
          city_name: cityName,
          district_id: schoolFormDetails?.districtId,
        },
      };
      createCity({
        variables: addCityData,
      }).then((res) => {
        createNewSchool({ cityId: res.data.insert_cities_one?.id });
      });
      getCities({ variables: { districtId: schoolFormDetails?.districtId } });
    }
  };

  const createNewSchool = (options) => {
    const cityId = options?.cityId || parseInt(schoolFormDetails.cityId);
    const addressData = {
      address_line_one: schoolFormDetails.addressLineOne,
      address_line_two: schoolFormDetails.addressLineTwo,
      city_id: cityId,
      pincode: schoolFormDetails.pinCode,
    };

    const schoolDetails = {
      title: schoolFormDetails.title,
      mobile_number: schoolFormDetails.mobileNumber,
      email: schoolFormDetails.email,
      board_name: schoolFormDetails.boardName,
      medium: schoolFormDetails.medium,
      board_id: schoolFormDetails.boardId,
      settings: schoolFormDetails?.boardSettings,
      trust_name: schoolFormDetails.trustName,
      udise_number: schoolFormDetails.udiseNumber,
      index_number: schoolFormDetails.indexNumber,

      acadamic_years: {
        data: {
          title: schoolFormDetails.academicYearTitle,
          to_date: moment(
            schoolFormDetails.academicYearTo,
            DATE_FORMATS.DDMMYYYY
          ).format(DATE_FORMATS.YYYYMMDD),
          from_date: moment(
            schoolFormDetails.academicYearFrom,
            DATE_FORMATS.DDMMYYYY
          ).format(DATE_FORMATS.YYYYMMDD),
          is_current: true,
        },
      },
      management_type: schoolFormDetails.managementType,
      category: schoolFormDetails.categoryType,
    };
    if (schoolFormDetails.addressLineOne) {
      schoolDetails.address = {
        data: addressData,
      };
    }

    if(schoolDetails?.mobile_number?.length >= 1){
      if(schoolDetails?.mobile_number?.length !== 10){
        setErrorMessage("Phone Number should be of 10 digits");
        return;
      }
      const isNAN = Number.isNaN(parseInt(schoolDetails?.mobile_number, 10));
      if(isNAN === true){
        setErrorMessage("Phone Number is not a number");
        return;
      }
    }

    if(schoolDetails?.email?.length >= 1 && !validateEmail(schoolDetails?.email)){
      setErrorMessage("Please enter valid email");
      return
    }
    
    createSchool({
      variables: {
        schoolDetails: schoolDetails,
      },
    }).then((res) => {
      const schoolId = res.data.insert_schools_one.id;
      if (selectedGroupList.length === 0) {
        setErrorMessage("Please fill POC Name");
        return;
      } else {
        saveGroup(selectedGroupList, schoolId);
        navigate("/adminsettings");
      }
      // clear error message
      setErrorMessage("");
    });
  };

  const handleSchoolCreate = (e) => {
    e.preventDefault();

    // set error message for empty school title
    if (!schoolFormDetails.boardName) {
      setErrorMessage("Please select the Board");
      return;
    }
    if (!schoolFormDetails.medium) {
      setErrorMessage("Please select the school medium");
      return;
    }
    if (!schoolFormDetails.title) {
      setErrorMessage("Please fill school name");
      return;
    }
    if (selectedGroupList.length === 0) {
      setErrorMessage("Please fill POC Name");
      return;
    }    
    // clear error message
    setErrorMessage("");
    let cityId = null;
    if (schoolFormDetails.cityId === CREATE_NEW_CITY) {
      // create new city then create school
      addNewCity();
    } else {
      cityId = parseInt(schoolFormDetails.cityId);
      createNewSchool({ cityId });
    }
  };

  const handleCreateAdminSubmit = (e) => {
    e.preventDefault();
    const adminList = adminFormItems.map((item) => {
      return {
        fullName: item.schoolAdminName,
        contactNumber: item.contactNumber,
      };
    });
    createAdmin(adminList);
  };

  const resetSchoolDetailsForm = () => {
    setSchoolFormDetails({
      ...schoolFormDetails,
      ...defaultSchoolDetails,
    });
  };

  const resetAdminDetailsForm = () => {
    setAdminFormItems([]);
    setAdminFormDetails({
      ...adminFormDetails,
      schoolAdmins: settingsData?.schools_by_pk?.school_admins,
    });
  };

  const resetClassDetailsForm = () => {
    setClassFormDetails({
      ...classFormDetails,
      ...defaultClassDetails,
    });
  };

  const handleUpload = async (file, onSuccess) => {
    const fileId = uuid();
    // get file extension
    const fileExtension = file.name.split(".").pop();
    const key = `${process.env.REACT_APP_S3_BUCKET_FOLDER}/${fileId}.${fileExtension}`;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
    };
    imagesS3Bucket.putObject(params).send((err, data) => {
      if (err) {
        // handle the error here
        console.error("s3 err", err);
      } else {
        // handle the success here
        onSuccess(data.response, file);
        //  store file id in school instead of image url
        const imageKey = `${fileId}.${fileExtension}`;
        // update image in school
        updateSchool({
          variables: {
            schoolId: settingsData?.schools_by_pk?.id,
            schoolData: {
              image: imageKey,
            },
          },
        });
      }
    });
  };

  const renderImage = () => {
    if (schoolFormDetails.image) {
      const url = getS3Image(schoolFormDetails.image);
      return (
        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
          <img className="h-full w-full object-cover" src={url} alt="avatar" />
        </span>
      );
    }
    return (
      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  };
  const shouldDisableAdmin = true;
  const adminButtonClass = shouldDisableAdmin ? "opacity-20" : "";
  const loadingButtonClass = isSchoolDetailsLoading ? "opacity-20" : "";
  const classesLoadingClass =
    isClassDetailsLoading || shouldDisableClasses ? "opacity-20" : "";
  const uploadProps = {
    customRequest({ file, onSuccess }) {
      return handleUpload(file, onSuccess);
    },
  };
  const key = "code";
  const BoardList = [
    ...new Map(boards?.map((board) => [board[key], board])).values(),
  ];

  useEffect(() => {
    if (
      schoolFormDetails?.stateId != null &&
      schoolFormDetails?.districtId != null
    ) {
      getDistricts({ variables: { stateId: schoolFormDetails?.stateId } });
      getCities({ variables: { districtId: schoolFormDetails?.districtId } });
    }
  }, [schoolFormDetails]);

  return (
    <form>
      <div className="flex">
        <FormSideSection
          title="School Details"
          description="Information about the school and points of contact"
        />
        <FormCard>
          <FormRow>
            <FormCol>
              <div>
                <label className="block text-sm font-medium text-gray-700 text-left">
                  School Logo
                </label>
                <div className="mt-1 flex items-center">
                  {renderImage()}
                  <Upload
                    {...uploadProps}
                    beforeUpload={(file) => {
                      // check if file is an jpeg or png
                      if (
                        file.type !== "image/jpeg" &&
                        file.type !== "image/png"
                      ) {
                        message.error("Only jpeg or png images are allowed!");
                        return false;
                      }
                      // check if file size is less than 2MB
                      if (file.size > 2000000) {
                        message.error("File size should be less than 2MB!");
                        return false;
                      }
                      return true;
                    }}
                    showUploadList={false}
                    className="ml-5 bg-white py-2 px-3 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <Button
                      style={{
                        borderRadius: 6,
                      }}
                      disabled
                    >
                      Upload
                    </Button>
                  </Upload>
                </div>
              </div>
            </FormCol>
            <FormCol overrideClassnames="w-60">
              <div className="flex flex-row">
                <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                  School Board
                </label>
                <label className="text-red-500 font-medium text-sm leading-5 pb-1 text-left ml-1">
                  *
                </label>
              </div>
              <Select
                className="customised-antd-selector-controlled"
                defaultValue={schoolFormDetails.boardName}
                value={schoolFormDetails.boardName}
                onChange={(e) => {
                  const boardName = BoardList.find((x) => x.id === e).name;
                  const boardSettings = BoardList.find(
                    (x) => x.id === e
                  ).settings;
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    boardId: e,
                    boardName: boardName,
                    boardSettings: boardSettings,
                  });
                }}
                size="large"
                placeholder={"Board Name"}
              >
                {BoardList.map((board) => {
                  return (
                    <Option key={board?.id} value={board?.id}>
                      {board.name}
                    </Option>
                  );
                })}
              </Select>
            </FormCol>
            <FormCol>
              <div className="flex flex-row">
                <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                  School Medium
                </label>
                <label className="text-red-500 font-medium text-sm leading-5 pb-1 text-left ml-1">
                  *
                </label>
              </div>
              <Select
                className="customised-antd-selector-controlled"
                defaultValue={schoolFormDetails.medium}
                value={schoolFormDetails.medium}
                onChange={(e) => {
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    medium : e
                  });
                }}
                size="large"
                placeholder={"Medium"}
              >
                {schoolMediumList.map((medium) => {
                  return (
                    <Option key={medium?.value} value={medium?.value}>
                      {medium.label}
                    </Option>
                  );
                })}
              </Select>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                School Name
              </label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.title}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    title: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                Academic Year Title
              </label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.academicYearTitle}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    academicYearTitle: e.target.value,
                  })
                }
              />
            </FormCol>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                School Start Date
              </label>
              <DatePicker
                value={moment(
                  schoolFormDetails?.academicYearFrom,
                  DATE_FORMATS.DDMMYYYY
                )}
                onChange={(date, dateString) => {
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    academicYearFrom: dateString,
                  });
                }}
                suffixIcon={null}
                format={DATE_FORMATS.DDMMYYYY}
                className="rounded-md"
                allowClear={false}
                style={{
                  height: "40px",
                  borderRadius: "6px",
                  textAlign: "center",
                }}
              />
            </FormCol>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                School End Date
              </label>
              <DatePicker
                value={moment(schoolFormDetails?.academicYearTo, DATE_FORMATS.DDMMYYYY)}
                onChange={(date, dateString) => {
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    academicYearTo: dateString,
                  });
                }}
                suffixIcon={null}
                format={DATE_FORMATS.DDMMYYYY}
                className="rounded-md"
                allowClear={false}
                style={{
                  height: "40px",
                  borderRadius: "6px",
                  textAlign: "center",
                }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                Trust Name
              </label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.trustName}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    trustName: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                U-DISE Number
              </label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.udiseNumber}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    udiseNumber: e.target.value,
                  })
                }
              />
            </FormCol>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                Index Number
              </label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.indexNumber}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    indexNumber: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
              School Address
            </label>
          </FormRow>
          <FormRow>
            <FormCol>
              <input
                type="text"
                placeholder="Address Line 1"
                required
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.addressLineOne}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    addressLineOne: e.target.value,
                  })
                }
              />
            </FormCol>
            <FormCol>
              <input
                type="text"
                placeholder="Address Line 2"
                required
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.addressLineTwo}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    addressLineTwo: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <div className="flex flex-col">
                <Select
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails?.stateName}
                  value={schoolFormDetails?.stateName}
                  labelInValue
                  onChange={(val) => {
                    getDistricts({ variables: { stateId: val.value } });
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      stateId: val.value,
                      stateName: val.label,
                      districtId: null,
                      districtName: null,
                      cityId: null,
                      cityName: null,
                    });
                  }}
                  size="large"
                  placeholder={"State"}
                >
                  {stateList?.map((state) => (
                    <Option key={state?.value} value={state?.value}>
                      {state.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </FormCol>
            <FormCol>
              <Select
                className="customised-antd-selector-controlled"
                defaultValue={schoolFormDetails.districtName}
                value={schoolFormDetails.districtName}
                labelInValue
                onChange={(val) => {
                  getCities({ variables: { districtId: val.value } });
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    districtId: val.value,
                    districtName: val.label,
                    cityId: null,
                    cityName: null,
                  });
                }}
                size="large"
                placeholder={"District"}
              >
                {districtList?.map((district) => (
                  <Option key={district.value}>{district.label}</Option>
                ))}
              </Select>
            </FormCol>
            <FormCol>
              <Select
                className="customised-antd-selector-controlled"
                defaultValue={schoolFormDetails.cityName}
                value={schoolFormDetails.cityName}
                size="large"
                placeholder={"City"}
                showSearch
                labelInValue
                optionFilterProp="children"
                onChange={(val) => {
                  let label = val.label;
                  if (val.value === CREATE_NEW_CITY) {
                    label = val.key;
                  }
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    cityId: val.value,
                    cityName: label,
                  });
                }}
                onSearch={(e) => {
                  const pascalCaseCity = e.charAt(0).toUpperCase() + e.slice(1);
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    citySearchValue: pascalCaseCity,
                  });
                }}
              >
                {schoolFormDetails?.citySearchValue &&
                  cityList?.filter(
                    (city) =>
                      city.label?.toLowerCase() ===
                      schoolFormDetails?.citySearchValue?.toLowerCase()
                  ).length === 0 && (
                    <Option
                      key={schoolFormDetails?.citySearchValue}
                      value={CREATE_NEW_CITY}
                    >
                      {"Add New City " +
                        `"${schoolFormDetails?.citySearchValue}"`}
                    </Option>
                  )}
                {cityList?.map((city) => (
                  <Option key={city.value} value={city.value}>
                    {city.label}
                  </Option>
                ))}
              </Select>
            </FormCol>
          </FormRow>
          <FormRow overrideClassnames="grid grid-cols-3">
            <FormCol overrideClassnames="col-span-1">
              <input
                type="text"
                placeholder="Pin Code"
                required
                className="border max-w-xs border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.pinCode}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    pinCode: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                Email Address
              </label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.email}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    email: e.target.value,
                  })
                }
              />
            </FormCol>
            <FormCol>
              <label className="text-gray-700 font-bold text-sm leading-5 pb-1 text-left ">
                Mobile Number
              </label>
              <input
                type="text"
                className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={schoolFormDetails.mobileNumber}
                onChange={(e) =>
                  setSchoolFormDetails({
                    ...schoolFormDetails,
                    mobileNumber: e.target.value,
                  })
                }
              />
            </FormCol>
          </FormRow>
          <FormRow overrideClassnames="grid grid-cols-2">
            <FormCol overrideClassnames="col-span-1">
              <div id="settings-district" className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 text-left">
                  School Management Type
                </label>
                <Select
                  showSearch
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails?.managementType}
                  value={schoolFormDetails?.managementType}
                  onChange={(value) =>
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      managementType: value,
                    })
                  }
                  size="large"
                  placeholder={"Select"}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {managementTypeList?.map((type) => (
                    <Option key={type.value}>{type.label}</Option>
                  ))}
                </Select>
              </div>
            </FormCol>

            <FormCol overrideClassnames="col-span-1">
              <div id="settings-category" className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 text-left">
                  School Category
                </label>
                <Select
                  showSearch
                  className="customised-antd-selector-controlled"
                  defaultValue={schoolFormDetails?.categoryType}
                  value={schoolFormDetails?.categoryType}
                  onChange={(value) =>
                    setSchoolFormDetails({
                      ...schoolFormDetails,
                      categoryType: value,
                    })
                  }
                  size="large"
                  placeholder={"Select"}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {categoryList?.map((type) => (
                    <Option key={type?.value}>{type?.label}</Option>
                  ))}
                </Select>
              </div>
            </FormCol>
          </FormRow>

          <FormRow>
            <FormCol>
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                POC Name{" "}
                {
                  <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                    *
                  </label>
                }
              </label>
              <MultiSelect
                options={groupList || ""}
                className="max-w-xs focus:ring-2 focus:outline-none ring-indigo-700 text-left"
                value={selectedGroupList}
                onChange={setSelectedGroupList}
                labelledBy={"Select"}
              />
            </FormCol>
          </FormRow>

          <FormRow overrideClassnames="justify-end">
            {/* display errormessage */}
            {errorMessage && (
              <div className="text-red-500 text-sm">{errorMessage}</div>
            )}
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            <div className="flex gap-x-3">
              <button
                type="button"
                onClick={() => resetSchoolDetailsForm()}
                class="py-3 px-5 flex justify-center items-center border border-gray-300 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                onClick={handleSchoolCreate}
                disabled={isSchoolDetailsLoading}
                class={`${loadingButtonClass} py-3 px-5 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md`}
              >
                <span>{isSchoolDetailsLoading ? "Loading..." : "Save"}</span>
              </button>              
            </div>
          </FormRow>
        </FormCard>
      </div>
      <div className="flex">
        <FormSideSection
          title="Admin Details"
          description="Information about admin"
        />
        <FormCard>
          {adminFormDetails.schoolAdmins?.length > 0 &&
            adminFormDetails.schoolAdmins.map((admin) => {
              return (
                <FormRow>
                  <FormCol>
                    <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                      School Admin Name
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 bg-gray-200 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                      value={admin.user.first_name}
                      disabled
                    />
                  </FormCol>
                  <FormCol>
                    <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 bg-gray-200  rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                      value={admin.user.mobile_number}
                      disabled
                    />
                  </FormCol>
                </FormRow>
              );
            })}
          {adminFormItems.map((item) => {
            return (
              <FormRow>
                <FormCol>
                  <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                    School Admin Name
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={item.schoolAdminName}
                    onChange={(e) =>
                      setAdminFormItems([
                        ...adminFormItems.map((admin) => {
                          if (admin.id === item.id) {
                            return {
                              ...admin,
                              schoolAdminName: e.target.value,
                            };
                          }
                          return admin;
                        }),
                      ])
                    }
                  />
                </FormCol>
                <FormCol>
                  <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                    value={item.contactNumber}
                    onChange={(e) =>
                      setAdminFormItems([
                        ...adminFormItems.map((admin) => {
                          if (admin.id === item.id) {
                            return {
                              ...admin,
                              contactNumber: e.target.value,
                            };
                          }
                          return admin;
                        }),
                      ])
                    }
                  />
                </FormCol>
              </FormRow>
            );
          })}

          <FormRow>
            <button
              id="settings-newadmin"
              onClick={(e) => {
                e.preventDefault();
                setAdminFormItems([
                  ...adminFormItems,
                  {
                    id: adminFormItems.length + 1,
                    schoolAdminName: "",
                    contactNumber: "",
                  },
                ]);
              }}
              className="p-2 rounded-md bg-white border border-gray-300 text-xs
              leading-4 font-medium shadow-sm  cursor-pointer "
            >
              Add new contact (Optional)
            </button>
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            <div className="flex gap-x-3">
              <button
                type="button"
                onClick={() => resetAdminDetailsForm()}
                class="py-3 px-5 flex justify-center items-center border border-gray-300 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                onClick={handleCreateAdminSubmit}
                disabled={shouldDisableAdmin}
                class={`${adminButtonClass} py-3 px-5 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md`}
              >
                <span>Save</span>
              </button>              
            </div>
          </FormRow>
        </FormCard>
      </div>
      <div className="flex">
        <FormSideSection title="Class Details" description="School setup" />
        <FormCard>
          <FormRow>
            <FormCol>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <div className="flex flex-col">
                    <label className="text-gray-700 font-medium text-sm leading-5 pb-1 text-left ">
                      Class starts from
                    </label>
                    <Cascader
                      {...field}
                      register={register}
                      options={classList}
                      value={value}
                      disabled={shouldDisableClasses}
                      defaultValue={classFormDetails.classStartsFrom}
                      onChange={(value) => {
                        setClassFormDetails({
                          ...classFormDetails,
                          classStartsFrom: value,
                        });
                      }}
                      placeholder={
                        shouldDisableClasses
                          ? classFormDetails?.classStartsFrom
                          : "Please select"
                      }
                      rules={{ required: true }}
                    />
                  </div>
                )}
              />
            </FormCol>
            <FormCol>
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <div className="flex flex-col">
                    <label className="text-gray-700 font-medium text-sm leading-5 pb-1 text-left ">
                      Class upto
                    </label>
                    <Cascader
                      {...field}
                      register={register}
                      options={classList}
                      disabled={shouldDisableClasses}
                      defaultValue={classFormDetails.classUpto}
                      onChange={(value) => {
                        setClassFormDetails({
                          ...classFormDetails,
                          classUpto: value,
                        });
                      }}
                      placeholder={
                        shouldDisableClasses
                          ? classFormDetails.classUpto
                          : "Please select"
                      }
                      rules={{ required: true }}
                    />
                  </div>
                )}
              />
            </FormCol>
            <FormCol overrideClassnames="mr-32">
              <Controller
                name="select"
                control={control}
                render={({ field, onChange, value }) => (
                  <div className="flex flex-col">
                    <label className="text-gray-700 font-medium text-sm leading-5 pb-1 text-left ">
                      Divisions in each class
                    </label>
                    <Cascader
                      {...field}
                      register={register}
                      options={divisionsList}
                      disabled={shouldDisableClasses}
                      defaultValue={classFormDetails.divisionsEachClass}
                      onChange={(value) => {
                        setClassFormDetails({
                          ...classFormDetails,
                          divisionsEachClass: value,
                        });
                      }}
                      placeholder={
                        shouldDisableClasses
                          ? classFormDetails.divisionsEachClass
                          : "Please select"
                      }
                      rules={{ required: true }}
                    />
                  </div>
                )}
              />
            </FormCol>
          </FormRow>
          <FormRow overrideClassnames="justify-end">
            <div className="flex gap-x-3">
              <button
                type="button"
                onClick={() => resetClassDetailsForm()}
                disabled={shouldDisableClasses || isClassDetailsLoading}
                class="py-3 px-5 flex justify-center items-center border border-gray-300 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                onClick={handleClassDetailsSubmit}
                disabled={shouldDisableClasses || isClassDetailsLoading}
                class={`${classesLoadingClass} py-3 px-5 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md`}
              >
                <span>{isClassDetailsLoading ? "Loading..." : "Save"}</span>
              </button>              
            </div>
          </FormRow>
        </FormCard>
      </div>
    </form>
  );
};

function Settings() {
  const [isEditing, setIsEditing] = useState(false);
  const settingsData = null;

  const { data: boardListData } = useQuery(GET_BOARD_LIST);

  const saveGroup = (groupList, schoolId) => {
    const groupListObject = groupList.map((group) => {
      return {
        group_id: group.value,
        school_id: schoolId,
      };
    });
    addGroups({
      variables: {
        groupSchools: groupListObject,
      },
    });
  };

  const [
    addGroups,
    { loading: addGroupLoading, error: addGroupError, data: addGroupData },
  ] = useMutation(ADD_GROUPS);

  const {
    loading: statesLoading,
    error: statesError,
    data: statesData,
  } = useQuery(GET_STATES);

  const [
    getDistricts,
    { loading: districtsLoading, error: districtsError, data: districtsData },
  ] = useLazyQuery(GET_DISTRICTS);

  const [
    getCities,
    { loading: citiesLoading, error: citiesError, data: citiesData },
  ] = useLazyQuery(GET_CITIES);

  const {
    loading: groupsLoading,
    error: groupsError,
    data: groupsData,
  } = useQuery(GET_GROUPS);

  const [CreateSchool, { loading: createSchoolLoading }] =
    useMutation(CREATE_SCHOOL);

  const [UpdateSchool, { loading: updateSchoolLoading }] = useMutation(
    UPDATE_SCHOOL,
    {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    }
  );

  const [
    AddAddress,
    {
      loading: addAddressLoading,
      error: addAddressError,
      data: addAddressData,
    },
  ] = useMutation(ADD_ADDRESS);

  const [UpdateUserAddress, { loading: updateUserAddressLoading }] =
    useMutation(UPDATE_USER_ADDRESS, {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    });

  const createAdmin = (payloadforAdmins) => {
    let userPayload = [];
    payloadforAdmins.map((usr) => {
      userPayload.push({
        phone: `+91${usr.contactNumber}`,
        first_name: usr.fullName,
        school_id: getSchoolID(),
      });
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_FUNCTION_ENDPOINT}/createAdmin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { data: userPayload },
    };
    axios(config)
      .then((res) => {
        console.log("created admin", res.data);
      })
      .catch((err) => console.error(err));
  };

  const [AddClassesInit, { loading: addClassesInitLoading }] = useMutation(
    CREATE_CLASSES_INIT,
    {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    }
  );

  const [AddClasses, { loading: addClassesLoading }] = useMutation(
    CREATE_CLASSES,
    {
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: {
            schoolId: getSchoolID(),
          },
        },
      ],
    }
  );

  const renderSettings = () => {
    return (
      <AddSettingsForm
        settingsData={null}
        updateSchool={UpdateSchool}
        createSchool={CreateSchool}
        createAdmin={createAdmin}
        academicYearData={[]}
        createClassesInit={AddClassesInit}
        createClasses={AddClasses}
        isClassDetailsLoading={addClassesLoading || addClassesInitLoading}
        states={statesData?.states}
        boards={boardListData?.boards}
        getDistricts={getDistricts}
        districts={districtsData?.districts}
        getCities={getCities}
        cities={citiesData?.cities}
        groups={groupsData?.groups}
        addAddress={AddAddress}
        updateUserAddress={UpdateUserAddress}
        isSchoolDetailsLoading={
          updateSchoolLoading || addAddressLoading || updateUserAddressLoading
        }
        saveGroup={saveGroup}
      />
    );
  };

  const renderControlButtons = () => {
    if (settingsData && !isEditing) {
      return (
        <button
          style={{
            minWidth: "6rem",
          }}
          class="py-3 px-4 flex justify-center bg-white items-center border border-gray-300 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
          onClick={() => setIsEditing(true)}
        >
          Edit
        </button>
      );
    }
    return (
      <div className="flex gap-x-3">
        <button
          type="button"
          onClick={() => setIsEditing(false)}
          style={{
            minWidth: "6rem",
          }}
          class="py-3 px-4 flex justify-center bg-pink-400 text-white items-center border border-gray-300 hover:bg-pink-600 focus:ring-pink-500 focus:ring-offset-pink-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
        >
          <span className="ml-2">Cancel</span>
        </button>
      </div>
    );
  };

  return (
    <div id="settings-createschool">
      <main className="bg-gray-100 dark:bg-gray-800 rounded-2xl h-screen overflow-hidden relative">
        <div className="flex ">
          <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
            <div className="flex flex-row gap-x-x4 items-center mb-12 justify-between">
              <p className="text-2xl text-left font-bold flex-col m-0 ">
                Create School
              </p>
              {renderControlButtons()}
            </div>
            {renderSettings()}
          </div>
        </div>
      </main>
      <></>
    </div>
  );
}

export default Settings;
