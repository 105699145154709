import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import TeacherAvailability from "./AvailabilityList";

const ManageTeacherAvailability = () => {
  const navigate = useNavigate();
  return (
    <main className="w-full px-8 py-6 md:space-y-4 h-screen overflow-hidden">
      <div className="h-full w-full">
        <div className="flex flex-row justify-between">
          <h1 className="text-2xl text-left font-bold flex-col">
            Staff Attendance
          </h1>
          <Button
            buttonStyle="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Dashboard
          </Button>
        </div>
        <div className="h-full">
          <TeacherAvailability />
        </div>
      </div>
    </main>
  );
};
export default ManageTeacherAvailability;
