/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getAuthID } from "../shared/getAuthID";

const getClassSettings = async (payload = {}) => {
  try {
    const token = getAuthID();
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_NODE_ENDPOINT}/class/get/settings`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer: ${token}`,
      },
      data: payload,
    };
    const { data } = await axios.request(config);
    return data?.data;
  } catch (e) {
    console.error("Use getClassSettings Error: ", { e: e.message });
    return null;
  }
};

export default function useGetClassSettings(payload = [], options = {}) {
  return useQuery(
    ["getClassSettings", payload],
    () => getClassSettings(payload),
    options
  );
}
