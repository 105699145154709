import { gql } from "@apollo/client";

export const ADD_DIVISION = gql`
  mutation AddDivisionToClass($divisionData: divisions_insert_input!) {
    insert_divisions_one(object: $divisionData) {
      id
    }
  }
`;

export const GET_CLASSES_WITH_FILTER = gql`
  query GetClasses(
    $classfilters: classes_bool_exp
    $divisionFilters: divisions_bool_exp
  ) {
    classes(where: $classfilters) {
      id
      class_name
      divisions(where: $divisionFilters) {
        id
        division_name
        is_promoted
        exam_type
        class_teachers(
          order_by: { id: desc }
          where: {
            is_active: { _eq: true }
            user: { is_active: { _eq: true } }
          }
        ) {
          id
          user {
            full_name: first_name
            first_name
            last_name
          }
        }
        division_students_aggregate(
          where: {
            is_active: { _eq: true }
            student: { is_active: { _eq: true }, is_draft: { _eq: false } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_CLASSES_FOR_YEAR = gql`
  query GetClasses($schoolId: Int!, $academicYearId: Int!) {
    classes(
      where: {
        school_id: { _eq: $schoolId }
        is_active: { _eq: true }
        academic_year_id: { _eq: $academicYearId }
      }
    ) {
      id
      class_name
      divisions(where: { is_active: { _eq: true } }) {
        id
        division_name
        is_promoted
        class_teachers(
          order_by: { id: desc }
          where: {
            is_active: { _eq: true }
            user: { is_active: { _eq: true } }
          }
        ) {
          id
          user {
            full_name: first_name
          }
        }
        division_students_aggregate(
          where: {
            is_active: { _eq: true }
            student: { is_active: { _eq: true }, is_draft: { _eq: false } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const ADD_CLASSES_AND_DIVISIONS = gql`
  mutation AddClasses($classesData: [classes_insert_input!]!) {
    insert_classes(objects: $classesData) {
      affected_rows
      returning {
        id
        divisions {
          id
        }
      }
    }
  }
`;
