import { gql } from "@apollo/client";

export const GET_ALL_SCHOOLS = gql`
query getAllSchools {
  schools(order_by: {id: asc}, where: {is_active: {_eq: true}}) {
    id
    title
    address {
      city {
        city_name
      }
    }
  }
}
`;
