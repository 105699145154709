import React, { useState, useEffect } from "react";

// Ant Components
import { Modal, Select, Spin } from "antd";
import { Segmented } from "antd";

// Custom Components
import CloseIcon from "../../components/closeIcon";
import CustomInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomAntdDatePicker from "../../views/studentsView/CustomDatePicker";

// Hooks
import { useForm, Controller } from "react-hook-form";
import useFilterOptions from "../../hooks/useFilterOptions";
import useCreateAdmissionEnquiry from "../../hooks/useCreateAdmissionEnquiry";

// Helper functions
import { getSchoolID } from "../../shared/getSchoolID";

const FormCol = ({ id, children, overrideClassnames }) => {
	return (
		<div
			id={id}
			className={`${overrideClassnames} flex flex-col flex-auto mb-5`}
		>
			{children}
		</div>
	);
};

const AddEnquiry = ({ onClose, visible }) => {
	const {
		register,
		formState: { errors },
		control,
	} = useForm({
		criteriaMode: "all",
	});
	const schoolId = getSchoolID();
	const [parentType, setParentType] = useState("Father");
	const [formDetails, setFormDetails] = useState({});
	const [isCreating, setIsCreating] = useState(false);
	const { data: filterData } = useFilterOptions(
		{
			schoolId: schoolId,
			entity: "enquiry",
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const academicYears = filterData?.academicYears || [];
	const { mutateAsync: createAdmissionEnquiry } = useCreateAdmissionEnquiry();
	const sortedClasses = filterData?.classes || [];
	useEffect(() => {
		if (visible) {
			setFormDetails({});
		}
	}, [visible]);

	const updateDetails = (newData) => {
		setFormDetails((currentData) => ({
			...currentData,
			...newData,
		}));
	};

	const submitDetails = async () => {
		try {
			setIsCreating(true);
			const finalDetails = {...formDetails};
			if (!finalDetails?.academicYearId && academicYears?.length) {
				finalDetails.academicYearId = academicYears[academicYears?.length - 1].value;
			}
			finalDetails.parentType = parentType;
			const response = await createAdmissionEnquiry({
				...finalDetails,
				schoolId,
			});
			if (response?.success && onClose) {
				onClose();
			}
		} catch (err) {
			// Ignore
		} finally {
			setIsCreating(false);
		}
	};

	return (
		<Modal
			visible
			centered
			footer={null}
			bodyStyle={{
				padding: 0,
			}}
			width={400}
			onClose={onClose}
			closable={false}
			closeIcon={null}
		>
			<Spin spinning={isCreating}>
				<div className="px-5 pt-5 my-2 border-gray-300">
					<div className="flex flex-row justify-between">
						<h3 className="font-bold text-lg">Add Enquiry</h3>
						<div className="cursor-pointer" onClick={onClose}>
							<CloseIcon />
						</div>
					</div>
				</div>
				<div className="px-5 pb-5">
					<>
						<FormCol
							id={"managestudents-addstudent-lastschoolclass"}
						>
							<label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
								Year for Application
							</label>
							<Controller
								name="yearForApplication"
								control={control}
								render={() => (
									<Select
										className="customised-antd-selector-controlled"
										onChange={(value) => {
											updateDetails({
												academicYearId: value,
											});
										}}
										size="large"
										allowClear
										defaultValue={academicYears?.[academicYears?.length - 1]}
									>
										{academicYears.map((cl) => (
											<Select.Option key={cl.value}>
												{cl.label}
											</Select.Option>
										))}
									</Select>
								)}
							/>
						</FormCol>

						<FormCol>
							<CustomInput
								id={"admission-student-name"}
								name="studentName"
								placeholder="studentName"
								label="Student Name"
								rules={{
									maxLength: {
										value: 20,
										message:
											"can't be longer than 20 characters",
									},
									required: "required",
								}}
								onChange={(e) => {
									updateDetails({
										studentName: e.target.value,
									});
								}}
								register={register}
								errors={errors}
							/>
						</FormCol>
						<FormCol
							id={"managestudents-addstudent-lastschoolclass"}
						>
							<label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
								Admission to class
							</label>
							<Controller
								name="admissionToClass"
								control={control}
								render={() => (
									<Select
										className="customised-antd-selector-controlled"
										onChange={(value) => {
											updateDetails({
												classId: value,
											});
										}}
										size="large"
										allowClear
									>
										{sortedClasses.map((cl) => (
											<Select.Option key={cl.value}>
												{cl.label}
											</Select.Option>
										))}
									</Select>
								)}
							/>
						</FormCol>
						<FormCol
							id={"managestudents-addstudent-lastschoolclass"}
						>
							<Controller
								name="dateOfBirth"
								control={control}
								render={({ field }) => (
									<>
										<CustomAntdDatePicker
											fieldData={{ ...field }}
											name="dateOfBirth"
											placeholder="dateOfBirth"
											label="Date of birth"
											register={register}
											format="DD/MM/YY"
											errors={errors}
											onChange={(dob) => updateDetails({
												dob: dob.format("YYYY-MM-DD")
											})}
										/>
									</>
								)}
							/>
						</FormCol>
						<div className="flex flex-row justify-between mb-4">
							<div
								onClick={() => setParentType("Father")}
								className={`pointer text-sm font-semibold flex justify-center items-center rounded-lg ${
									parentType === "Father"
										? "bg-primary text-white"
										: "border"
								}`}
								style={{ height: 40, width: "48%" }}
							>
								Father
							</div>
							<div
								onClick={() => setParentType("Mother")}
								className={`pointer text-sm font-semibold flex justify-center items-center rounded-lg ${
									parentType === "Mother"
										? "bg-primary text-white"
										: "border"
								}`}
								style={{ height: 40, width: "48%" }}
							>
								Mother
							</div>
						</div>
						<FormCol>
							<CustomInput
								id={"admission-parent-name"}
								name="parentName"
								label={`${parentType}'s Name`}
								rules={{
									maxLength: {
										value: 20,
										message:
											"can't be longer than 20 characters",
									},
									required: "required",
								}}
								register={register}
								errors={errors}
								onChange={(e) => {
									updateDetails({
										parentName: e.target.value,
									});
								}}
							/>
						</FormCol>
						<FormCol>
							<CustomInput
								id={"admission-mobile-number"}
								name="mobileNumber"
								label="Mobile Number"
								register={() =>
									register("mobileNumber", {
										required: true,
										pattern: /^[6-9]\d{9}$/i,
									})
								}
								onChange={(e) => {
									updateDetails({
										parentNumber: e.target.value,
									});
								}}
								errors={errors}
							/>
						</FormCol>

						<FormCol>
							<CustomInput
								id={"admission-note"}
								name="note"
								placeholder="note"
								label="Additional note"
								rules={{
									maxLength: {
										value: 20,
										message:
											"can't be longer than 20 characters",
									},
									required: "required",
								}}
								onChange={(e) => {
									updateDetails({
										note: e.target.value,
									});
								}}
								register={register}
								errors={errors}
							/>
						</FormCol>

						<div className="flex gap-x-3 justify-end">
							<Button
								id="settings-schooldetails-cancel"
								onClick={onClose}
								className="px-5 h-10"
								buttonStyle="default"
							>
								<span>Cancel</span>
							</Button>

							<Button
								id="settings-schooldetails-save"
								type="button"
								buttonStyle="primary"
								className="px-5 h-10"
								onClick={submitDetails}
							>
								<span>Save</span>
							</Button>
						</div>
					</>
				</div>
			</Spin>
		</Modal>
	);
};

export default AddEnquiry;