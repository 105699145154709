import React, { useState } from "react";

//Custom Components
import Button from "../../../components/Button";

//Third-Party Components
import { Radio, Spin } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import deleteIcon from "../../../assets/images/delete.png";

//Custom Hooks
import useGetReceiptSettings from "../../../hooks/useGetReceiptSettings";
import useUpdateReceiptSettings from "../../../hooks/useUpdateReceiptSettings";

//Third Party Hooks
import { useNavigate } from "react-router-dom";

const defaultState = {
  id: null,
  notes: [""],
  showSignatures: true,
};

function ReceiptSettings() {
  const [initialDetails, setInitialDetails] = useState(defaultState);
  const [receiptDetails, setReceiptDetails] = useState(defaultState);
  const { mutate: saveReceiptSettings, isLoading: isSavingSettings } = useUpdateReceiptSettings();
  const { isFetching: isLoading } = useGetReceiptSettings({
    onSuccess: (data) => {
      const settings = data.settings;
      settings.id = data?.id;
      setReceiptDetails(settings);
      setInitialDetails(settings);
    },
  });
  const navigate = useNavigate();

  const handleDeleteNote = (index) => {
    const noteCount = receiptDetails.notes.length;
    if (noteCount > 1) {
      const notes = receiptDetails.notes;
      notes.splice(index, 1);
      setReceiptDetails({
        ...receiptDetails,
        notes: notes,
      });
    } else {
      setReceiptDetails({
        ...receiptDetails,
        notes: [""],
      });
    }
  };

  const handleNoteValue = (value, updateIndex) => {
    const updatedNotes = receiptDetails?.notes?.map((item, index) => {
      if (index === updateIndex) {
        return value;
      }
      return item;
    });
    setReceiptDetails({ ...receiptDetails, notes: updatedNotes });
  };

  const handleAddNote = () => {
    setReceiptDetails({
      ...receiptDetails,
      notes: [...(receiptDetails?.notes || []), ""],
    });
  };

  const handleSave = () => {
    saveReceiptSettings({
      id: receiptDetails.id,
      settings: {
        showSignatures: receiptDetails.showSignatures,
        notes: receiptDetails.notes,
      },
    });
  };

  const handleCancel = () => {
    setReceiptDetails(initialDetails);
  }

  return (
    <>
      <div className="flex flex-col w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
        <Spin spinning={isLoading || isSavingSettings}>
          <div className="flex flex-row gap-x-4 items-center mb-2 justify-between mr-7">
            <p className="text-2xl ml-6 text-left font-bold flex-col m-0">
              Receipt Settings
            </p>
            <Button
              id="attendancereport-back"
              buttonStyle="secondary"
              onClick={() => navigate(-1)}
            >
              Back to Fees Collection
            </Button>
          </div>
          <div className="mt-6 relative bg-white rounded-lg w-3/5 pt-4 pl-8 pb-10 pr-4 ml-6 items-start">
            <div className="flex flex-col border-opacity-10 items-start">
              <div className="mt-2 text-gray-600 font-medium text-sm">
                Show signature fields in the receipt
              </div>
              <div className="mt-4">
                <Radio.Group
                  value={receiptDetails.showSignatures}
                  style={{
                    display: "flex",
                    columnGap: "0.75rem",
                  }}
                  onChange={(e) => {
                    setReceiptDetails({
                      ...receiptDetails,
                      showSignatures: e.target.value,
                    });
                  }}
                >
                  <div className="bg-white">
                    <Radio value={true}>
                      <strong className={`font-medium`}>Yes</strong>
                    </Radio>
                  </div>
                  <div className="bg-white">
                    <Radio value={false}>
                      <strong className={`font-medium`}>No</strong>
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
            </div>
            <div className="flex flex-col mt-5 items-start">
              <div className="flex items-center mt-3">
                <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                  Notes
                </label>
                <div className="p-1 bg-backgroundGrey mx-3 rounded-md flex items-center">
                  <InfoCircleFilled
                    className="mr-1.5"
                    style={{
                      color: "#6B7280",
                    }}
                  />
                  <p className="text-xs text-textGrey">
                    Note will be shown on every receipt
                  </p>
                </div>
              </div>
              {receiptDetails?.notes?.map((noteItem, noteIndex) => {
                const showDeleteButton =
                  receiptDetails?.notes?.length > 1 || noteItem?.length;
                return (
                  <>
                    <div
                      style={{ width: "100%" }}
                      className="flex flex-row items-center "
                    >
                      <input
                        type="text"
                        className="border mt-3 border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                        required
                        style={{ width: "80%" }}
                        value={noteItem}
                        placeholder="Type any note here..."
                        onChange={(e) => {
                          handleNoteValue(e.target.value, noteIndex);
                        }}
                      />
                      {showDeleteButton ? (
                        <img
                          alt="delete"
                          src={deleteIcon}
                          className="h-6 mt-2 ml-4 cursor-pointer"
                          onClick={() => handleDeleteNote(noteIndex)}
                        />
                      ) : null}
                    </div>
                  </>
                );
              })}
              <button
                className="text-primary self-start font-medium mt-4 "
                onClick={(e) => {
                  e.preventDefault();
                  handleAddNote();
                }}
              >
                + Add new note
              </button>
            </div>

            <div className="flex gap-x-4 mt-5">
              <button
                onClick={handleSave}
                type="submit"
                className="inline-flex w-24  flex-cols justify-center py-2 px-4 border border:transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
              <button
                onClick={handleCancel}
                type="reset"
                className="py-3 px-3  justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100  "
              >
                Cancel
              </button>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
}

export default ReceiptSettings;