import React, { useState } from "react";
import { PlusIcon, EyeIcon, PencilIcon } from "@heroicons/react/solid";
import { DownloadIcon } from "@heroicons/react/outline";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import manageIllustration from "../../../assets/images/manage.png";
import useGetFeesStructures from "../../../hooks/useGetFeesStructures";
import useDownloadFeeStructure from "../../../hooks/useDownloadFeeStructure";
import { Modal } from "antd";
import moment from "moment";
import { getSortedClasses } from "../../../helpers";
import { Spin } from "antd";
import useTracking from "../../../hooks/useTracking";

const StructureCard = ({ feesStructure }) => {
  const navigate = useNavigate();
  const [downloadStructure, setDownloadStructure] = useState("");
  const [viewStructure, setViewStructure] = useState("");
  const { trackEvent } = useTracking();

  const { isFetching: isDownloading } = useDownloadFeeStructure(
    {
      feeStructureId: downloadStructure?.id,
    },
    {
      onSettled: () => {
        setDownloadStructure("");
      },
    }
  );

  const { data: structureHtml, isFetching: isStructureLoading } =
    useDownloadFeeStructure({
      feeStructureId: viewStructure?.id,
      responseType: "html",
    });

  const handleEditDetailsClick = () => {
    trackEvent("Edit Structure Click");
    navigate(`/fees-structure/new-structure`, {
      state: {
        structureData: feesStructure,
      },
    });
  };

  const handleDownloadClick = () => {
    trackEvent("Download Structure");
    setDownloadStructure(feesStructure);
  };

  const handleViewClick = () => {
    trackEvent("View Structure Format");
    setViewStructure(feesStructure);
  };

  return (
    <div
      style={{
        height: "600px",
        width: "450px",
      }}
      className="h-full flex flex-col max-w-lg bg-white relative overflow-y-auto overflow-x-hidden rounded-lg"
    >
      <div className="sticky top-0">
        <div className="flex h-12 bg-scandalGreen text-center items-center justify-center pl-3 text-lg font-semibold text-darkerGrey">
          <p className="font-semibold text-darkerGrey">
            {feesStructure?.title} {feesStructure?.academicYear?.title}
          </p>
        </div>
        <div className="flex items-start pl-4 py-3 font-semibold text-darkGreen text-lg bg-white">
          <p>
            For class{" "}
            {getSortedClasses(
              feesStructure?.classIds?.map((item) => {
                return {
                  ...item,
                  class_name: item?.name,
                };
              })
            )
              ?.map((item) => item?.class_name)
              ?.toString()}
          </p>
        </div>
      </div>
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th
              scope="col"
              className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey text-left text-sm uppercase font-medium"
            >
              SR NO.
            </th>
            <th
              scope="col"
              className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey text-left text-sm uppercase font-medium"
            >
              Particulars
            </th>
            <th
              scope="col"
              className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey text-left text-sm uppercase font-medium"
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {feesStructure?.particulars?.map((item, index) => {
            const rowId = "structure-item-" + index;
            return (
              <tr id={rowId} key={index}>
                <td className="px-7 py-5 border-b border-gray-200 bg-white text-sm text-left">
                  <p className="text-darkerGrey whitespace-no-wrap font-semibold">
                    {index + 1}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                  <p className="text-darkerGrey whitespace-no-wrap font-semibold">
                    {item?.title}
                  </p>
                  {item?.breakup?.map((item) => {
                    return <p className="text-riverGrey">{item?.title}</p>;
                  })}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                  <p className="text-darkerGrey whitespace-no-wrap font-semibold">
                    {item?.amount}
                  </p>
                  {item?.breakup?.map((item) => {
                    return <p className="text-riverGrey">{item?.amount}</p>;
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="py-5 bg-zumthorBlue flex justify-between items-center pl-7 pr-12 font-semibold">
        <p className="text-darkerGrey">Total</p>
        <p className="text-darkerGrey">
          {feesStructure?.particulars?.reduce(
            (n, { amount }) => n + parseInt(amount, 10),
            0
          )}
        </p>
      </div>
      {feesStructure?.acceptInstallments && (
        <div>
          <div className="flex items-center justify-center">
            <div className="bg-scandalGreen rounded-2xl px-6 py-1 mt-4 mb-2 items-center justify-center text-darkGreen font-medium">
              <p>Installment Distribution</p>
            </div>
          </div>
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey text-left text-sm uppercase font-medium"
                >
                  NAME
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey text-left text-sm uppercase font-medium"
                >
                  DUE DATE
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray-50  border-b border-gray-200 text-textGrey text-left text-sm uppercase font-medium"
                >
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              {feesStructure?.installmentDetails?.map((item, index) => {
                const rowId = "installment-item-" + index;
                return (
                  <tr id={rowId} key={index}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                      <p className="text-darkerGrey whitespace-no-wrap font-semibold">
                        {item?.name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                      <p className="text-darkerGrey whitespace-no-wrap font-semibold">
                        {moment(item?.dueDate, "YYYY-MM-DD").format(
                          "DD MMM YYYY"
                        )}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                      <p className="text-darkerGrey whitespace-no-wrap font-semibold">
                        {item?.amount}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="text-left pt-4 pl-5">
        <p className=" text-darkerGrey font-semibold">Note :-</p>
        <ul>
          {feesStructure?.notes?.map((noteItem, index) => {
            return (
              <li>
                <p className="text-riverGrey">
                  {index + 1}. {noteItem}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="flex justify-around px-3 py-3 sticky bottom-0 bg-white mt-auto">
        <Button
          id="download-all-students-data"
          buttonStyle="primary"
          className="py-3 px-4 bg-thaliumFlame"
          onClick={handleViewClick}
        >
          <EyeIcon className="h-4 w-4 mr-1" />
          View Format
        </Button>
        <Button
          id="download-all-students-data"
          buttonStyle="primary"
          className="py-3 px-4"
          onClick={handleEditDetailsClick}
        >
          <PencilIcon className="h-4 w-4 mr-1" aria-hidden="true" />
          Edit Details
        </Button>
        <Button
          id="download-all-students-data"
          buttonStyle="success"
          className="py-3 px-4"
          onClick={handleDownloadClick}
          disabled={isDownloading}
        >
          <DownloadIcon className="h-6 w-5 mr-1" />
          Download
        </Button>
      </div>
      <Modal
        open={viewStructure ? true : false}
        centered
        closable={false}
        onCancel={() => setViewStructure("")}
        bodyStyle={{
          overflowX: "auto",
          marginLeft: -20,
        }}
        width={800}
        footer={null}
      >
        <Spin spinning={isStructureLoading}>
          <div
            dangerouslySetInnerHTML={{ __html: structureHtml }}
            className="text-center"
          />
        </Spin>
      </Modal>
    </div>
  );
};

function FeesStructure() {
  const navigate = useNavigate();
  const { data: feesStructures } = useGetFeesStructures();

  const getSortedStructures = (structures) => {
    // sort by createdAt - structure with latest createdAt will be last
    const sortedStructures = structures?.sort((a, b) => {
      return moment(a?.createdAt).diff(moment(b?.createdAt));
    });
    return sortedStructures;
  };

  return (
    <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row gap-x-4 items-center mb-4">
        <p className="text-2xl text-left font-bold flex-col m-0">
          Fees Structure
        </p>
        <Button
          id="download-all-students-data"
          buttonStyle="primary"
          className="py-3 px-4"
          onClick={() => navigate(`/fees-structure/new-structure`)}
        >
          <PlusIcon className="h-4 w-4" />
          <span className="ml-2"> Create New Structure</span>
        </Button>
      </div>
      {feesStructures?.length > 0 ? (
        <div
          className="grid gap-10"
          style={{
            gridTemplateColumns: "repeat(2, 450px)",
          }}
        >
          {getSortedStructures(feesStructures)?.map((feesStructure) => {
            return <StructureCard feesStructure={feesStructure} />;
          })}
        </div>
      ) : (
        <div className="mb-0 pb-0 object-fit h-96 flex mt-44 flex-col place-items-center">
          <img src={manageIllustration} alt="" className=" max-h-full" />
          <p className="font-bold text-3xl mt-9">
            You have not created any fees structure yet
          </p>
          <p className="font-medium text-xl mt-7">
            Click on Create New Structure button
          </p>
        </div>
      )}
    </div>
  );
}

export default FeesStructure;
