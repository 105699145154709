import React from "react";
import useAttendanceData from "../../hooks/useAttendanceData";
import moment from "moment";
import { DATE_FORMATS } from "../../utils/constants";
import { validNumber } from "../../utils";

const MonthWiseAttendance = ({ month, absentDays }) => {
  return (
    <section className="mt-3 pb-2 border-b border-fadedGrey flex flex-1 flex-col justify-start items-start">
      <span className="rounded-full bg-lightOrange px-3 py-0.5 text-left">
        <span className="text-lightRed text-sm font-medium">{month}</span>
      </span>
      {absentDays?.map((day) => (
        <div className="flex flex-1 justify-between w-full mt-2">
          <p className="font-medium text-darkerGrey text-base ">
            {day?.date
              ? moment(day?.date).format(DATE_FORMATS.DDSMMMSYY)
              : null}
          </p>
          <p className=" font-medium text-fainterGrey text-base">{day?.day}</p>
        </div>
      ))}
    </section>
  );
};

const GRADIENT_COLORS = {
  GREEN: "#0FC398, #27FBB0",
  YELLOW: "#F6A123, #FFCD29",
  RED: "#F55522, #FF845D",
};

const AttendanceBlock = ({ totalDays, attendedDay, headlineText }) => {
  const percentage = Math.round((attendedDay / totalDays) * 100);

  let color = GRADIENT_COLORS.RED;

  if (percentage >= 81) {
    color = GRADIENT_COLORS.GREEN;
  } else if (percentage >= 41) {
    color = GRADIENT_COLORS.YELLOW;
  }

  const style = {
    background: `linear-gradient(to bottom right, ${color})`,
  };

  return (
    <article
      className="flex flex-col items-center rounded-xl bg-white border border-fadedGrey overflow-hidden justify-between h-28"
      style={{ width: "45%" }}
    >
      <p className="text-linerGrey font-semibold pt-1.5 text-base">
        This {headlineText}
      </p>
      <p className="text-darkerGrey font-semibold mb-4 text-xl">
        {totalDays}/{attendedDay}
      </p>
      <div
        className="h-1/3 w-full flex items-center justify-center mt-2"
        style={style}
      >
        <p className="font-semibold text-white text-lg">
          {validNumber(percentage)}%
        </p>
      </div>
    </article>
  );
};

const Attendance = ({ studentId }) => {
  const { data: attendanceData } = useAttendanceData(studentId);

  return (
    <article className="pr-5 h-full w-full block overflow-y-auto p-4 bg-white rounded-xl card-shadow">
      <header className="flex flex-1 justify-between">
        <h2 className="text-left font-semibold text-darkerGrey text-xl">
          Attendance
        </h2>
      </header>

      <summary className="flex flex-1 justify-between w-full mt-1">
        <AttendanceBlock
          headlineText="Month"
          totalDays={attendanceData?.totalMonthDays}
          attendedDay={attendanceData?.thisMonthPresent}
        />
        <AttendanceBlock
          headlineText="Year"
          totalDays={attendanceData?.totalYearDays}
          attendedDay={attendanceData?.totalYearPresentDays}
        />
      </summary>

      <p className="mt-4 font-medium text-base text-fainterGrey text-left">
        Absent days for year {attendanceData?.currentAcademicYear}
      </p>

      {attendanceData?.thisYearAbsentDays?.map((monthAbsent) => (
        <MonthWiseAttendance {...monthAbsent} />
      ))}
    </article>
  );
};

export default Attendance;
