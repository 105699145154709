/**
 * @FileInfo: Manage Report settings at class level
 * @Desc: Set custom grade on progress report
 */
import React, { useState, useMemo } from "react";

// Custom components
import { Modal, Table, Form, Menu, Input, Spin, Dropdown } from "antd";
import Button from "../../../components/Button/Button";
import deleteIcon from "../../../assets/images/delete.png";
import { ChevronDownIcon } from "@heroicons/react/outline";

// Hooks
import useGetClassSettings from "../../../hooks/useGetClassSettings";
import useUpdateClassSettings from "../../../hooks/useUpdateClassSettings";

// Helper Functions
import { v4 as uuidv4 } from "uuid";
import { notification } from "antd";

const rowOptions = [
	{
		value: "addTop",
		label: "Add row at top",
	},
	{
		value: "addBottom",
		label: "Add row at bottom",
	},
];

const EditableCell = ({
	title,
	type,
	children,
	dataIndex,
	record,
	handleSave,
	onDelete,
	...restProps
}) => {
	const [inputValue, fromRange, toRange] = useMemo(() => {
		if (type === "input") {
			return [record?.[dataIndex] || "", "", ""];
		} else if (type === "range-input") {
			return [
				"",
				(record?.[dataIndex]?.[0]).toString() || "",
				record?.[dataIndex]?.[1]?.toString() || "",
			];
		}
		return ["", "", ""];
	}, [record, dataIndex, type]);

	const handleChange = (e, valueType) => {
		if (valueType === "single") {
			handleSave(dataIndex, e.target.value);
		} else if (valueType === "fromInput") {
			handleSave(dataIndex, e.target.value, 0);
		} else {
			handleSave(dataIndex, e.target.value, 1);
		}
	};

	let childNode = null;
	switch (type) {
		case "input":
			childNode = (
				<Form.Item
					style={{
						margin: 0,
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `${title} is required.`,
						},
					]}
				>
					<Input
						style={{ borderRadius: 5 }}
						defaultValue={inputValue}
						onChange={(e) => handleChange(e, "single")}
					/>
				</Form.Item>
			);
			break;
		case "range-input":
			childNode = (
				<div className="flex justify-center items-center">
					<Input
						defaultValue={fromRange}
						style={{ borderRadius: 5 }}
						onChange={(e) => handleChange(e, "fromInput")}
					/>
					<div className="text-sm text-gray-500 font-semibold mx-3">
						to
					</div>
					<Input
						defaultValue={toRange}
						style={{ borderRadius: 5 }}
						onChange={(e) => handleChange(e, "toInput")}
					/>
				</div>
			);
			break;
		case "delete":
			childNode = (
				<div
					onClick={onDelete}
					className="flex flex-col justify-center items-center cursor-pointer"
				>
					<img
						style={{ width: 23, height: 23 }}
						src={deleteIcon}
						alt="Delete Button"
					/>
				</div>
			);
			break;
		default:
			childNode = children;
	}

	return <td {...restProps}>{childNode}</td>;
};

const Header = ({ children, ...restProps }) => {
	return (
		<td {...restProps} className="bg-lightPurple">
			<div
				className="text-sm flex text-center flex-col justify-center font-semibold w-full items-center"
				style={{
					height: 40,
				}}
			>
				{children}
			</div>
		</td>
	);
};

const ClassSettings = ({ visible, onClose, classId, className }) => {
	const { isFetching: isLoading } = useGetClassSettings(
		{
			classId,
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const rows = (data?.grades || []).map((row) => {
					row.key = uuidv4();
					return row;
				});
				setDataSource(rows);
			},
		}
	);
	const { mutateAsync: updateSettings } = useUpdateClassSettings();
	const [isSaving, setIsSaving] = useState(false);
	const [dataSource, setDataSource] = useState([]);

	const addRow = (addType) => {
		let rows = [...dataSource];
		const key = uuidv4();
		const object = { key, grade: "", description: "", range: ["", ""] };
		if (addType === "addTop") {
			rows.unshift(object);
		} else {
			rows.push(object);
		}
		setDataSource(rows);
	};

	const onDelete = (rowIndex) => {
		const newData = [...dataSource];
		newData.splice(rowIndex, 1);
		setDataSource(newData);
	};

	const handleSave = (dataKey, dataValue, rowIndex, valueIndex = null) => {
		const newData = [...dataSource];
		const item = newData[rowIndex];
		if (valueIndex === null) {
			item[dataKey] = dataValue;
		} else {
			const rangeValue = item[dataKey];
			rangeValue[valueIndex] = dataValue;
			item[dataKey] = rangeValue;
		}
		newData[rowIndex] = item;
		setDataSource(newData);
	};

	const handleSubmit = async () => {
		let errorText = null;
		let endNumber = null;
		const grades = [];
		[...dataSource].reverse().forEach((mGrade) => {
			if (!mGrade.grade?.length) {
				errorText = "Grade name is required";
				return true;
			}
			const grade = {
				...mGrade,
			};
			const fromRange = parseInt(mGrade?.range?.[0] || 0);
			const toRange = parseInt(mGrade?.range?.[1]);
			if (isNaN(fromRange) || isNaN(toRange)) {
				errorText = "Marks range should be number";
				return true;
			}
			if (endNumber === null) {
				endNumber = toRange;
			} else if (fromRange !== endNumber + 1) {
				errorText = "Mark range should be continuous";
				return true;
			} else {
				endNumber = toRange;
			}
			grade.range = [fromRange, toRange];
			grades.unshift(grade);
		});
		if (errorText) {
			console.log(errorText);
			notification["error"]({
				message: "Failure",
				description: errorText,
				duration: 1.5,
			});
			return;
		}
		try {
			setIsSaving(true);
			await updateSettings({
				grades,
				classId,
			});
			onClose();
		} catch (err) {
			// Ignore
		} finally {
			setIsSaving(false);
		}
	};

	const columns = [
		{
			title: "Grade",
			onCell: (record, ri) => ({
				dataIndex: "grade",
				record,
				type: "input",
				handleSave: (dk, dv) => handleSave(dk, dv, ri),
				width: "20%",
			}),
		},
		{
			title: "Marks Range",
			onCell: (record, ri) => ({
				dataIndex: "range",
				record,
				width: "40%",
				type: "range-input",
				handleSave: (dk, dv, vi) => handleSave(dk, dv, ri, vi),
			}),
		},
		{
			title: "Description",
			onCell: (record, ri) => ({
				dataIndex: "description",
				record,
				width: "30%",
				type: "input",
				handleSave: (dk, dv) => handleSave(dk, dv, ri),
			}),
		},
		{
			title: "Actions",
			onCell: (record, rowIndex) => ({
				record,
				width: "23%",
				type: "delete",
				onDelete: () => onDelete(rowIndex),
			}),
		},
	];
	const components = {
		body: {
			cell: EditableCell,
		},
		header: {
			cell: Header,
		},
	};
	const rowMenu = (
		<Menu
			onClick={({ key }) => {
				addRow(key);
			}}
		>
			{rowOptions.map((div) => (
				<Menu.Item key={div.value} title={div.label}>
					{div.label}
				</Menu.Item>
			))}
		</Menu>
	);
	return (
		<Modal
			visible={visible}
			centered
			closable={false}
			onCancel={onClose}
			bodyStyle={{
				justifyContent: "center",
			}}
			width={"50%"}
			footer={null}
		>
			<Spin spinning={isLoading || isSaving}>
				<div className="flex justify-between mb-3">
					<h3 className="text-lg font-semibold">
						Report Grade Settings ({className})
					</h3>
					<Dropdown overlay={rowMenu}>
						<button
							id="rowoption-download"
							className="py-2 px-3 flex justify-center items-center whitespace-nowrap bg-primary text-white transition ease-in duration-197 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ant-dropdown-trigger"
						>
							+ Add New Row
							<ChevronDownIcon
								className="h-4 w-5 ml-4"
								aria-hidden="true"
							/>
						</button>
					</Dropdown>
				</div>
				<Table
					components={components}
					rowClassName={() => "editable-row"}
					bordered
					pagination={false}
					dataSource={dataSource}
					columns={columns}
				/>
				<div className="flex justify-end mt-4">
					<div className="flex gap-x-4">
						<Button onClick={onClose}>Cancel</Button>
						<Button buttonStyle="primary" onClick={handleSubmit}>
							Save changes
						</Button>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export default ClassSettings;