import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";
import { getAuthID } from "../shared/getAuthID";
import axios from "axios";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CLASSES_AND_DIVISIONS,
  INSERT_PARENT_STUDENT_RELATIONSHIP,
  INSERT_STUDENTS,
  UPDATE_DIVISION_FOR_BULK_UPLOAD,
} from "../views/studentsView/graphql";
import moment from "moment";
import { notification, Spin } from "antd";
import { getSchoolID } from "../shared/getSchoolID";
import { useNavigate } from "react-router-dom";
import { pluralRenderer } from "../shared/pluralRenderer";
import ReactExport from "react-data-export";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import useAxios from "../hooks/useAxios";
import { DownloadIcon } from "@heroicons/react/outline";
import { STUDENT_BULK_UPLOAD_FORMAT, DATE_FORMATS } from "../utils/constants";
import { Button } from "../components";

const CHECK_FOR_SPACES = `Please also make sure column headers don't have any additional space`;

const importURL = `${process.env.REACT_APP_FUNCTION_ENDPOINT}/importUsers`;
const getFileUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/aws/getFile`;
const uploadFileUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/aws/uploadFile`;
const updateStudentRefUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/students/updateDuplicateStudentRef`;

const trimAllStrings = (arrayOfRecords) => {
  const SanitizedArrayOfRecords = [];
  arrayOfRecords.forEach((record) => {
    const sanitizedRecord = {};
    const keysOfRecord = Object.keys(record);
    keysOfRecord.forEach((key) => {
      if (typeof record[key] === typeof "string") {
        sanitizedRecord[key] = record[key].trim();
      } else {
        sanitizedRecord[key] = record[key];
      }
    });
    SanitizedArrayOfRecords.push(sanitizedRecord);
  });
  return SanitizedArrayOfRecords;
};

function StudentBulkUpload() {
  const [errors, setErrors] = useState();
  const [classes, setClasses] = useState([]);
  const [divsData, setDivsData] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [studentCount, setStudentCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [recentlyUploadedList, setRecentlyUploadedList] = useState({
    files: [],
    filesToShow: 5,
    isExpanded: false,
  });
  const EXCEL_FORMAT_FILENAME = "GROUNDUP_EXCEL_FORMAT";

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  const { data: filesData } = useAxios({
    method: "post",
    url: "aws/getRecentlyUploadedFiles",
    variables: {
      schoolId: getSchoolID(),
    },
  });

  useEffect(() => {
    if (filesData) {
      setRecentlyUploadedList({
        ...recentlyUploadedList,
        files: filesData?.files,
      });
    }
  }, [filesData]);

  const handleUpdateStudentRef = () => {
    var config = {
      method: "post",
      url: updateStudentRefUrl,
      headers: {
        "Content-Type": "application/json",
      },
      data: { schoolId: getSchoolID() },
    };
    axios(config).catch((err) => console.error(err));
  };

  useEffect(() => {
    const handleUploadFile = async () => {
      setLoadingState(true);
      var formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("schoolId", getSchoolID());
      formData.append("fileName", selectedFile.name);
      try {
        const res = await axios.post(uploadFileUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setRecentlyUploadedList({
          ...recentlyUploadedList,
          files: res?.data?.files,
        });
        handleUpdateStudentRef();
      } catch (error) {
        console.error(error);
      } finally {
        setSelectedFile(null);
        setLoadingState(false);
        setSuccess(false);
      }
    };
    if (selectedFile != null && success) {
      handleUploadFile();
    }
  }, [success]);

  const handleFileDownload = async (fileKey) => {
    const fileName = fileKey?.split("/")[1];
    setLoadingState(true);
    const params = {
      method: "post",
      url: getFileUrl,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        fileKey: fileKey,
      },
    };
    try {
      axios(params)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState(false);
    }
  };

  const handleDrop = (acceptedFiles) => {
    setErrors(null);
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(acceptedFiles[0]);
      setSelectedFile(acceptedFiles[0]);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 0,
          defval: null,
        });
        resolve(data);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
    promise.then((data) => {
      //chechking if data is present or not

      if (Object.keys(data[0]).length > 1) {
        //checking if mandatory fields are present or not
        checkIfMandatoryFieldColumnMissing(data);
      } else {
        setErrors(
          "Data is Missing please fill the data and then upload the file"
        );
        setLoadingState(false);
      }
    });
  };

  const navigate = useNavigate();

  const { loading } = useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (classd) => {
      const clasArr = [];
      const divsArr = [];

      classd.classes.forEach((item) => {
        clasArr.push({
          id: item.id,
          name: item.class_name,
        });
        item.divisions.forEach((divItem) => {
          divsArr.push(divItem);
        });
      });
      setClasses(clasArr);
      setDivsData([...divsArr]);
    },
  });

  const [insertStudents] = useMutation(INSERT_STUDENTS);
  const [updateDivisionForBulkUpload] = useMutation(
    UPDATE_DIVISION_FOR_BULK_UPLOAD
  );
  const [insertParentStudent] = useMutation(INSERT_PARENT_STUDENT_RELATIONSHIP);

  const checkIfMandatoryFieldColumnMissing = (data) => {
    const hasClass = _.has(data[0], ["Class"]);
    const hasDivision = _.has(data[0], ["Division"]);
    const hasStudName = _.has(data[0], ["Student Name"]);
    const hasMobNo = _.has(data[0], ["Mobile Number"]);
    const hasSRNO = _.has(data[0], ["S.No"]);
    const hasGRSET = _.has(data[0], ["GR Set"]);
    const hasGRNO = _.has(data[0], ["GR No."]);
    const HasStudentId = _.has(data[0], ["Student ID"]);
    const hasMotherName = _.has(data[0], ["Mother's Name"]);
    const hasRTE = _.has(data[0], ["RTE"]);
    const hasDOB = _.has(data[0], ["Birth Date"]);
    const hasGender = _.has(data[0], ["Gender"]);
    const hasReligion = _.has(data[0], ["Religion"]);
    const hasCategory = _.has(data[0], ["Category"]);
    const hasInsertedDate = _.has(data[0], ["Inserted Date"]);
    const hasAadhar = _.has(data[0], ["Aadhaar"]);
    const hasCaste = _.has(data[0], ["Caste"]);
    const hasEnrollDate = _.has(data[0], ["Enroll date"]);
    const hasPlaceOfBirth = _.has(data[0], ["Place of birth"]);
    const hasLastSchool = _.has(data[0], ["Last School"]);
    const hasPayOrFree = _.has(data[0], ["Paying or free"]);
    const hasAdmittedClass = _.has(data[0], ["Admitted class"]);
    const hasAdmittedDivision = _.has(data[0], ["Admitted division"]);
    const hasConduct = _.has(data[0], ["Conduct"]);
    const hasStudentProgress = _.has(data[0], ["Student Progress"]);
    const hasRemarks = _.has(data[0], ["Remarks"]);

    if (
      !hasClass &&
      !hasDivision &&
      !hasStudName &&
      !hasMobNo &&
      !hasGRNO &&
      !hasGRSET &&
      !HasStudentId &&
      !hasMotherName &&
      !hasRTE &&
      !hasDOB &&
      !hasGender &&
      !hasReligion &&
      !hasCategory &&
      !hasInsertedDate &&
      !hasAadhar &&
      !hasCaste &&
      !hasEnrollDate &&
      !hasPlaceOfBirth &&
      !hasLastSchool &&
      !hasPayOrFree &&
      !hasAdmittedClass &&
      !hasAdmittedDivision &&
      !hasConduct &&
      !hasStudentProgress &&
      !hasRemarks
    ) {
      setErrors(
        "Data is Missing please fill the data and then upload the file "
      );
      setLoadingState(false);
    } else {
      if (!hasClass) {
        setErrors("Class column is missing. " + CHECK_FOR_SPACES);
        setLoadingState(false);
        return;
      }
      if (!HasStudentId) {
        setErrors("Student ID column is missing. " + CHECK_FOR_SPACES);
        setLoadingState(false);
        return;
      }
      if (!hasDivision) {
        setErrors("Division column is missing. " + CHECK_FOR_SPACES);
        setLoadingState(false);
        return;
      }
      if (!hasStudName) {
        setErrors("Student Name column is missing. " + CHECK_FOR_SPACES);
        setLoadingState(false);
        return;
      }
      if (!hasSRNO) {
        setErrors("S.No  column is missing. " + CHECK_FOR_SPACES);
        setLoadingState(false);
        return;
      }
      if (!hasMobNo) {
        setErrors(
          "Mobile Number column is missing or the header you assigned doesn't match the header in format. " +
            CHECK_FOR_SPACES
        );
        setLoadingState(false);
        return;
      }
      if (!hasDOB) {
        setErrors(
          `"Birth Date" column is missing or the header you assigned doesn't match the header in format. ` +
            CHECK_FOR_SPACES
        );
        setLoadingState(false);
        return;
      }
      if (hasClass && hasDivision && hasStudName && hasSRNO && hasDOB) {
        const sanitizedData = trimAllStrings(data);
        process(sanitizedData);
      }
    }
  };

  const checkIfMandatoryFieldsExist = (data) => {
    return new Promise((resolve, reject) => {
      const hasRow = _.has(data[0], "row_no");
      const hasFN = _.has(data[0], "first_name");

      const hasDN = _.has(data[0], "division");
      const hasArr = [hasRow, hasFN, hasDN];
      const isMissing = _.some(hasArr, false);

      if (isMissing) {
        reject("Mandatory Fields are missing");
        setErrors("Mandatory Fields are missing");
        setLoadingState(false);
      } else {
        resolve(data);
      }
    });
  };

  const checkIfMandatoryValuesAreEmpty = (data) => {
    return new Promise((resolve, reject) => {
      //#1 map and find if there are any null or undefined values in mandy keys
      const emptyKeys = [];
      const phoneKeys = [];
      data.forEach((dat, datIndex) => {
        emptyKeys.push({
          index: datIndex + 1,
          row_no: _.isNil(dat.row_no),
          first_name: _.isNil(dat.first_name),
          class: _.isNil(dat.class),
          contact_name_one: _.isNil(dat.contact_name_one),
          contact_mobile_one: _.isNil(dat.contact_mobile_one),

          division: _.isNil(dat.division),
        });
        phoneKeys.push(dat.contact_mobile_one);
      });
      //#2 if some value satisfies _.isNil, partition them
      const findIfRowNo = _.partition(emptyKeys, { row_no: true });
      const findIfFirstName = _.partition(emptyKeys, { first_name: true });

      const findIfContactNameOne = _.partition(emptyKeys, {
        contact_name_one: true,
      });
      const findIfContactMobileOne = _.partition(emptyKeys, {
        contact_mobile_one: true,
      });

      const findIfDivision = _.partition(emptyKeys, { division: true });
      //#2 see if such partitioned array lengths are greater than 0
      const isEmptyValue = [
        findIfRowNo[0].length > 0,
        findIfFirstName[0].length > 0,
        findIfContactNameOne[0].length > 0,
        findIfContactMobileOne[0].length > 0,

        findIfDivision[0].length > 0,
      ];

      //#3 if any one of the mandy fields are not empty then resolve
      // else reject
      const isMissing = _.includes(isEmptyValue, true);
      if (isMissing) {
        reject("Mandatory Fields are empty");
        setErrors("Mandatory Fields are empty");
        setLoadingState(false);
      } else {
        resolve(data, phoneKeys);
      }
    });
  };

  //#third step
  const checkIfMandatoryValuesAreEmptyForHavesNot = (data) => {
    return new Promise((resolve, reject) => {
      //#1 map and find if there are any null or undefined values in mandy keys
      const emptyKeys = [];
      const phoneKeys = [];
      data.forEach((dat, datIndex) => {
        emptyKeys.push({
          index: datIndex + 1,
          row_no: _.isNil(dat.row_no),
          first_name: _.isNil(dat.first_name),
          // class: _.isNil(dat.class),
          division: _.isNil(dat.division),
        });
        //mobile NO. in new excel file
        phoneKeys.push(dat.contact_mobile_one);
      });
      //#2 if some value satisfies _.isNil, partition them
      const findIfRowNo = _.partition(emptyKeys, { row_no: true });
      const findIfFirstName = _.partition(emptyKeys, { first_name: true });
      // let findIfclass = _.partition(emptyKeys, { class: true });
      const findIfDivision = _.partition(emptyKeys, { division: true });
      //#2 see if such partitioned array lengths are greater than 0
      const isEmptyValue = [
        findIfRowNo[0].length > 0,
        findIfFirstName[0].length > 0,
        // findIfclass[0].length > 0,
        findIfDivision[0].length > 0,
      ];
      //#3 if any one of the mandy fields are not empty then resolve
      // else reject
      const isMissing = _.includes(isEmptyValue, true);
      if (isMissing) {
        reject("Mandatory Fields are empty");
        setErrors("Mandatory Fields are empty");
        setLoadingState(false);
      } else {
        resolve(data, phoneKeys);
      }
    });
  };

  const checkFormatOfPhoneNumber = (data) => {
    return new Promise((resolve, reject) => {
      const testPattern = data.filter((obj) =>
        /^[6-9]\d{9}$/.test(obj.contact_mobile_one)
      );

      const dataLength = data.length;

      const checkIfSame = dataLength === testPattern.length;

      if (checkIfSame) {
        resolve(data);
      } else {
        setErrors("Some phone numbers does not meet the format requirements ");
        reject("Some phone numbers does not meet the format requirements ");
        setLoadingState(false);
      }
    });
  };

  //#sixth step
  const makeThemFlat = (res2) => {
    return new Promise((resolve, reject) => {
      const payload = {
        school_id: getSchoolID(),
        data: [],
      };
      res2.map((dat, datIndex) => {
        payload.data.push({
          first_name: dat.contact_name_one,
          mobile_number: `+91${dat.contact_mobile_one}`,
        });
      });

      if (payload.data.length > 0) {
        resolve(payload);
      } else {
        setErrors("Not enough data");
        reject("Not enough data");
        setLoadingState(false);
      }
    });
  };

  //#seventh step
  const final = (payload) => {
    return new Promise((resolve, reject) => {
      const config = {
        method: "post",
        url: importURL,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };
      axios(config)
        .then((res) => {
          resolve(res.data.successArray);
        })
        .catch((err) => {
          console.error(`err`, err?.response?.data?.message);
          setErrors(err?.response?.data?.message);
          reject(err.response.data.message);
          setLoadingState(false);
        });
    });
  };

  const findDivsion = (divisionString) => {
    if (!_.isNil(divisionString)) {
      const found = divsData.find(
        (division) =>
          division.division_name.toLowerCase() === divisionString.toLowerCase()
      );
      return found?.id;
    }
  };

  const findParent = (data, res4, contactMobileOne) => {
    const parentObj = res4.find(
      (parent) => parent.mobile_number === `+91${contactMobileOne}`
    );
    return parentObj ? parentObj.auth_id : null;
  };

  //#eighth step
  const replaceStringsWithIDs = (data, res4, parentExists) => {
    return new Promise((resolve, reject) => {
      const replaced = [];

      data.forEach((item) => {
        const checkIfDivisionExists = _.isNil(findDivsion(item.division));
        if (item.admitted_class !== null) {
          const checkIfAdmittedDivisionExists = _.isNil(
            findDivsion(item.admitted_class)
          );
          if (checkIfAdmittedDivisionExists) {
            if (
              item.admitted_class.split(" ").length > 1
                ? /[a-zA-Z]/.test(item.admitted_class.split(" ")[1])
                : /[a-zA-Z]/.test(item.admitted_class.split("")[1])
            ) {
              setErrors(
                `Admitted Division doesn't exist ${item.admitted_class}`
              );
              reject(`Admitted Division doesn't exist ${item.admitted_class}`);
              setLoadingState(false);
              return;
            }
          }
        }
        if (checkIfDivisionExists) {
          if (
            item.division.split(" ").length > 1
              ? /[a-zA-Z]/.test(item.division.split(" ")[1])
              : /[a-zA-Z]/.test(item.division.split("")[1])
          ) {
            setErrors(`Division doesn't exist ${item.division}`);
            reject(`Division doesn't exist ${item.division}`);
            setLoadingState(false);
            return;
          } else {
            setErrors(`Division is missing `);
            reject(`Division is missing `);
            setLoadingState(false);
            return;
          }
        }

        replaced.push({
          aadhaar: item.aadhaar ? `${item.aadhaar}` : null,
          caste: item.caste,
          dob: _.isNil(item.dob)
            ? null
            : moment(item.dob, DATE_FORMATS.DDMMYYYY).format(
                DATE_FORMATS.YYYYMMDD
              ),
          enroll_date: _.isNil(item.enroll_date)
            ? null
            : moment(item.enroll_date, DATE_FORMATS.DDMMYYYY).format(
                DATE_FORMATS.YYYYMMDD
              ),
          first_name: item.first_name,
          school_id: getSchoolID(),
          is_draft: false,
          last_name: item.last_name,
          middle_name: item.middle_name,
          religion: item.religion,
          conduct: item.conduct,
          admitted_class: item.admitted_class,
          last_school: item.last_school,
          paying_or_free: item.paying_or_free,
          place_of_birth: item.place_of_birth,
          remarks: item.remarks,
          student_progress: item.student_progress,
          mother_name: item.mother_name,
          father_name: item.middle_name,
          rte: item.rte,
          category: item.category,
          student_ref: item.student_ref,
          gr_set: item.gr_set,
          gr_no: item.gr_no,
          gender: item.gender,
          audit_trails: {
            data: {
              added_by: getAuthID(),
              changes: "Bulk Upload",
            },
          },
          ...(!checkIfDivisionExists && {
            division_id: findDivsion(item.division),
            // division_students: {
            //   data: {
            //     division_id: findDivsion(item.division),
            //   },
            // },
          }),
          ...(parentExists && {
            student_parents: {
              data: {
                user_id: findParent(data, res4, item.contact_mobile_one),
                relation: item.contact_relation_one,
                updated_at: "now()",
              },
              on_conflict: {
                constraint: "student_parents_student_id_user_id_key",
                update_columns: "updated_at",
              },
            },
          }),
        });
      });

      resolve(replaced);
      if (replaced.length === 0) {
        reject("Nothing to replace with");
      }
    });
  };

  //#nineth step

  const sendToDB = (payload) => {
    return new Promise((resolve, reject) => {
      insertStudents({ variables: { objects: payload } })
        .then((response) => {
          const student_id = [];
          const objects = [];

          response.data.insert_students.returning.forEach((stud) => {
            student_id.push(stud.id);
            objects.push({
              division_id: stud.division_id,
              from_date: "now()",
              is_active: true,
              student_id: stud.id,
            });
          });
          updateDivisionForBulkUpload({
            variables: {
              student_id: student_id,
              objects: objects,
            },
          }).then((res) => {
            notification["success"]({ message: "Students added", duration: 2 });
            setErrors(null);
            setLoadingState(false);
            resolve();
            setSuccess(true);
            setShowSuccessMessage(true);
          });
        })
        .catch((reserror) => {
          setErrors(reserror?.graphQLErrors?.[0]?.message);
          setLoadingState(false);
          reject();
        });
    });
  };

  //here we are seperating file on the basis of having contact_mobile_one or not
  //#second step
  const partition = (arrayToPartition) => {
    const part = _.partition(arrayToPartition, { contact_mobile_one: null });
    //has mobile arr
    const havesNot = part[0];

    //has not mobile arr
    const haves = part[1];

    setStudentCount(arrayToPartition.length);
    //#third step
    checkIfMandatoryValuesAreEmptyForHavesNot(arrayToPartition)
      .then((res) => {
        //#fourth step after checking checkIfMandatoryValuesAreEmptyForHavesNot
        if (haves.length > 0) {
          return havesPath(haves, havesNot);
        } else {
          havesNotPath(havesNot);
        }
      })
      .catch((err) => {
        setLoadingState(false);
      });
  };
  const havesNotPath = (havesNot) => {
    // 1. Check if Mandatory fields exists, don't priceed if these don't exist:
    //  row_no
    // first_name
    // class
    // division
    // 2. Check if mandatory values are empty, don't proceed if they are.
    // 3. Replace all strings with IDs, especially divisions
    // 4. Send to DB. InsertStudents Mutation.
    // 5. If there are any objects in the haves array, send them to havesPath
    setLoadingState(true);
    replaceStringsWithIDs(havesNot, null, false)
      .then((replacedData) => {
        return sendToDB(replacedData);
      })
      .catch((replacedErr) => {
        setLoadingState(false);
      });
  };

  //#fifth A step
  const havesPath = (data, havesNot) => {
    checkFormatOfPhoneNumber(data)
      .then((passesFormatChecks) => {
        //#sixth step
        return makeThemFlat(passesFormatChecks);
      })
      .then((flatData) => {
        //#seventh step
        // here we need to implement the check for updating data
        return final(flatData);
      })
      .then((finalData) => {
        //#eightth step

        return replaceStringsWithIDs(data, finalData, true);
      })
      .then((replacedData) => {
        //#nineth step

        return sendToDB(replacedData);
      })
      .then((sentToDb) => {
        if (havesNot.length > 0) {
          havesNotPath(havesNot);
        }
      })
      .catch((err) => console.error("overall error havesPath", err));
  };

  const process = (data, fileInfo, originalFile) => {
    //#first step
    //check the format and the type of fields in uploaded data
    const wrongTypeData = [];
    const wrongFormatData = [];
    data.some((datArr, index) => {
      if (datArr["Aadhaar"] && typeof datArr["Aadhaar"] !== "number") {
        wrongTypeData.push({
          field: "Aadhaar",
          data: datArr["Aadhaar"],
          type: "number",
        });
        return true;
      }

      if (datArr["Aadhaar"] && datArr["Aadhaar"].toString().length !== 12) {
        wrongFormatData.push({
          field: "Aadhaar",
          data: datArr["Aadhaar"],
          type: "of 12 digit number ",
        });
        return true;
      }

      if (typeof datArr["S.No"] !== "number") {
        wrongTypeData.push({
          field: "S.No",
          data: datArr["S.No"],
          type: "number and it should not be empty",
        });
        return true;
      }
      if (
        datArr["Student Name"] &&
        typeof datArr["Student Name"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Student Name",
          data: datArr["Student Name"],
          type: "string",
        });
        return true;
      }
      if (datArr["Birth Date"] && typeof datArr["Birth Date"] !== "string") {
        wrongTypeData.push({
          field: "Birth Date",
          data: datArr["Birth Date"],
          type: "string",
        });
        return true;
      }
      if (datArr["Enroll date"] && typeof datArr["Enroll date"] !== "string") {
        wrongTypeData.push({
          field: "Enroll date",
          data: datArr["Enroll date"],
          type: "string",
        });
        return true;
      }
      if (
        datArr["Place of birth"] &&
        typeof datArr["Place of birth"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Place of birth",
          data: datArr["Place of birth"],
          type: "string",
        });
        return true;
      }
      if (datArr["Last School"] && typeof datArr["Last School"] !== "string") {
        wrongTypeData.push({
          field: "Last School",
          data: datArr["Last School"],
          type: "string",
        });
        return true;
      }
      if (
        datArr["Paying or free"] &&
        typeof datArr["Paying or free"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Paying or free",
          data: datArr["Paying or free"],
          type: "string",
        });
        return true;
      }
      if (
        datArr["Paying or free"] &&
        datArr["Paying or free"] !== "Paying" &&
        datArr["Paying or free"] !== "Free"
      ) {
        wrongFormatData.push({
          field: "Paying or free",
          data: datArr["Paying or free"],
          type: "Paying or Free",
        });
        return true;
      }

      if (datArr["Caste"] && typeof datArr["Caste"] !== "string") {
        wrongTypeData.push({
          field: "Caste",
          data: datArr["Caste"],
          type: "string",
        });
        return true;
      }
      if (datArr["Religion"] && typeof datArr["Religion"] !== "string") {
        wrongTypeData.push({
          field: "Religion",
          data: datArr["Religion"],
          type: "string",
        });
        return true;
      }
      if (
        datArr["Mobile Number"] &&
        typeof datArr["Mobile Number"] !== "number"
      ) {
        wrongTypeData.push({
          field: "Mobile Number",
          data: datArr["Mobile Number"],
          type: "number",
        });
        return true;
      }

      if (datArr["Division"] && typeof datArr["Division"] !== "number") {
        wrongTypeData.push({
          field: "Division",
          data: datArr["Division"],
          type: "number",
        });
        return true;
      }

      if (
        datArr["Admitted division"] &&
        typeof datArr["Admitted division"] !== "number"
      ) {
        wrongTypeData.push({
          field: "Admitted division",
          data: datArr["Admitted division"],
          type: "number",
        });
        return true;
      }
      if (datArr["Conduct"] && typeof datArr["Conduct"] !== "string") {
        wrongTypeData.push({
          field: "Conduct",
          data: datArr["Conduct"],
          type: "string",
        });
        return true;
      }
      if (
        datArr["Student Progress"] &&
        typeof datArr["Student Progress"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Student Progress",
          data: datArr["Student Progress"],
          type: "string",
        });
        return true;
      }
      if (datArr["Remarks"] && typeof datArr["Remarks"] !== "string") {
        wrongTypeData.push({
          field: "Remarks",
          data: datArr["Remarks"],
          type: "string",
        });
        return true;
      }

      if (
        datArr["Mother's Name"] &&
        typeof datArr["Mother's Name"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Mother's Name",
          data: datArr["Mother's Name"],
          type: "string",
        });
        return true;
      }
      if (datArr["RTE"] && typeof datArr["RTE"] !== "string") {
        wrongTypeData.push({
          field: "RTE",
          data: datArr["RTE"],
          type: "string",
        });
        return true;
      }
      if (datArr["RTE"] && datArr["RTE"] !== "Yes" && datArr["RTE"] !== "No") {
        wrongFormatData.push({
          field: "RTE",
          data: datArr["RTE"],
          type: "Yes/No",
        });
        return true;
      }
      if (datArr["Category"] && typeof datArr["Category"] !== "string") {
        wrongTypeData.push({
          field: "Category",
          data: datArr["Category"],
          type: "string",
        });
        return true;
      }
      if (typeof datArr["Student ID"] !== "string") {
        wrongTypeData.push({
          field: "Student ID",
          data: datArr["Student ID"],
          type: "String and it should not be empty",
        });
        return true;
      }
      if (datArr["GR Set"] && typeof datArr["GR Set"] !== "string") {
        wrongTypeData.push({
          field: "GR Set",
          data: datArr["GR Set"],
          type: "string",
        });
        return true;
      }
      if (datArr["GR No."] && typeof datArr["GR No."] !== "number") {
        wrongTypeData.push({
          field: "GR No.",
          data: datArr["GR No."],
          type: "number",
        });
        return true;
      }
      if (datArr["Gender"] && typeof datArr["Gender"] !== "string") {
        wrongTypeData.push({
          field: "Gender",
          data: datArr["Gender"],
          type: "string",
        });
        return true;
      }
      if (
        datArr["Gender"] &&
        datArr["Gender"] !== "M" &&
        datArr["Gender"] !== "F"
      ) {
        wrongFormatData.push({
          field: "Gender",
          data: datArr["Gender"],
          type: "M or F",
        });
        return true;
      }
      return false;
    });

    if (!isEmpty(wrongTypeData)) {
      setErrors(
        `${wrongTypeData[0].field} should be of type ${wrongTypeData[0].type}`
      );
      setLoadingState(false);
    } else if (!isEmpty(wrongFormatData)) {
      setErrors(
        `Field ${wrongFormatData[0].field} should be in Format ${wrongFormatData[0].type}`
      );
      setLoadingState(false);
    } else {
      const newProcessArray = [];

      //here make changes into file data as required for database and push in newProcessArray
      data.forEach((datArr) => {
        if (
          datArr["Student Name"] === null //when whole column is not there
        ) {
          setErrors("Student Name is missing");
          setLoadingState(false);
        }

        const splitName = datArr["Student Name"].split(" ");
        const transformArray = {
          row_no: datArr["S.No"],
          first_name: splitName[0],
          middle_name: splitName[1],
          last_name: splitName[2],
          aadhaar: datArr["Aadhaar"],
          caste: datArr["Caste"],
          enroll_date: datArr["Enroll date"],
          place_of_birth: datArr["Place of birth"],
          last_school: datArr["Last School"],
          paying_or_free: datArr["Paying or free"],
          admitted_class:
            datArr["Admitted class"] && datArr["Admitted division"]
              ? typeof datArr["Admitted class"] === "number"
                ? `${datArr["Admitted class"]}${(
                    datArr["Admitted division"] + 9
                  )
                    .toString(36)
                    .toUpperCase()}`
                : `${datArr["Admitted class"]} ${(
                    datArr["Admitted division"] + 9
                  )
                    .toString(36)
                    .toUpperCase()}`
              : null,
          conduct: datArr["Conduct"],
          student_progress: datArr["Student Progress"],
          remarks: datArr["Remarks"],
          dob: datArr["Birth Date"],
          religion: datArr["Religion"] !== null ? datArr["Religion"] : "",
          contact_name_one: splitName[1],
          contact_mobile_one:
            datArr["Mobile Number"] !== undefined
              ? datArr["Mobile Number"]
              : null,
          contact_relation_one: "Father",
          class: datArr["Class"] || null,
          division:
            typeof datArr["Class"] === "number"
              ? `${datArr["Class"]}${(datArr["Division"] + 9)
                  .toString(36)
                  .toUpperCase()}`
              : `${datArr["Class"]} ${(datArr["Division"] + 9)
                  .toString(36)
                  .toUpperCase()}`,
          mother_name: datArr["Mother's Name"],
          rte: datArr["RTE"] === "YES" ? true : false,
          category: datArr["Category"] !== null ? datArr["Category"] : "",
          student_ref:
            datArr["Student ID"] !== null ? datArr["Student ID"] : "",
          gr_set: datArr["GR Set"] !== null ? datArr["GR Set"] : "",
          gr_no: datArr["GR No."],
          gender: datArr["Gender"],
        };
        newProcessArray.push(transformArray);
      });

      setLoadingState(true);
      //#second step
      partition(newProcessArray);
    }
  };

  const showAllFiles = () => {
    recentlyUploadedList?.isExpanded
      ? setRecentlyUploadedList({
          ...recentlyUploadedList,
          filesToShow: 5,
          isExpanded: false,
        })
      : setRecentlyUploadedList({
          ...recentlyUploadedList,
          filesToShow: recentlyUploadedList?.files?.length,
          isExpanded: true,
        });
  };

  const ErrorMessage = (errorz) => {
    return (
      <React.Fragment>
        <div className="text-left flex-col flex">
          <p className="text-red-500 text-lg leading-5 font-medium">
            Oops! Upload failed
          </p>

          <p className="text-sm leading-5 font-normal text-gray-500 mt-2.5">
            {errorz.error ? errorz.error : errorz}
          </p>
          <p className="text-sm leading-5 font-normal text-gray-500 mt-1">
            Please download the Template Excel File and upload in the right
            format
          </p>
        </div>
        <div className="flex-col flex">
          <button
            onClick={() => navigate(-1)}
            className="opacity-50 mr-2.5 w-48 ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white pointer-events-none"
          >
            View Student Details
          </button>
        </div>
      </React.Fragment>
    );
  };
  const SuccessMessage = () => {
    return (
      <React.Fragment>
        <div className="text-left flex-col flex">
          <p className="text-black text-lg leading-5 font-medium">
            Upload Successful
          </p>

          <p className="text-sm leading-5 font-normal text-gray-500 mt-2.5">
            {pluralRenderer(
              studentCount,
              "student record have been saved",
              "student records have been saved"
            )}
          </p>
        </div>
        <div className="flex-col flex">
          <button
            onClick={() => navigate(-1)}
            className=" mr-2.5 w-48 ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white"
          >
            View Student Details
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="flex flex-col w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={loadingState || loading}>
        <div className="flex flex-row justify-between">
          <p className="text-2xl  text-left font-bold">Bulk Upload Students</p>
          <Button
            id="managestudents-bulkupload-back"
            buttonStyle="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Manage Students
          </Button>
        </div>

        <div className="flex flex-row justify-around items-center mt-10 gap-x-10">
          <div className="flex flex-col w-full ">
            <p className="text-left font-bold text-base ">
              <span className="text-indigo-600  font-bold ">Step 1:</span>{" "}
              Download Template Excel file
            </p>

            <div className="border-4 px-24 py-12 rounded-lg border-grey-300 bg-white h-48 mt-5">
              <span className="flex flex-row justify-center">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_113_10484)">
                    <rect
                      x="3.5"
                      y="4.5"
                      width="42"
                      height="38"
                      rx="3.5"
                      stroke="#9CA3AF"
                    />
                    <line x1="4" y1="13.5" x2="46" y2="13.5" stroke="#9CA3AF" />
                    <line
                      x1="13"
                      y1="20.5"
                      x2="46"
                      y2="20.5"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="13"
                      y1="27.5"
                      x2="46"
                      y2="27.5"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="13"
                      y1="34.5"
                      x2="46"
                      y2="34.5"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="13.5"
                      y1="14"
                      x2="13.5"
                      y2="43"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="23.5"
                      y1="14"
                      x2="23.5"
                      y2="43"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="33.5"
                      y1="14"
                      x2="33.5"
                      y2="43"
                      stroke="#9CA3AF"
                    />
                    <circle cx="40" cy="37" r="8" fill="#4338CA" />
                    <path
                      d="M44.2825 37.5323L40.5325 41.2823C40.4611 41.3506 40.377 41.4041 40.285 41.4398C40.1024 41.5148 39.8976 41.5148 39.715 41.4398C39.6229 41.4041 39.5388 41.3506 39.4675 41.2823L35.7175 37.5323C35.6475 37.4624 35.5921 37.3794 35.5542 37.288C35.5164 37.1967 35.4969 37.0987 35.4969 36.9998C35.4969 36.8001 35.5762 36.6086 35.7175 36.4673C35.8587 36.3261 36.0502 36.2468 36.25 36.2468C36.4497 36.2468 36.6412 36.3261 36.7825 36.4673L39.25 38.9423V33.2498C39.25 33.0509 39.329 32.8602 39.4696 32.7195C39.6103 32.5788 39.801 32.4998 40 32.4998C40.1989 32.4998 40.3896 32.5788 40.5303 32.7195C40.6709 32.8602 40.75 33.0509 40.75 33.2498V38.9423L43.2175 36.4673C43.2872 36.397 43.3701 36.3412 43.4615 36.3032C43.5529 36.2651 43.6509 36.2455 43.75 36.2455C43.849 36.2455 43.947 36.2651 44.0384 36.3032C44.1298 36.3412 44.2127 36.397 44.2825 36.4673C44.3528 36.5371 44.4085 36.62 44.4466 36.7114C44.4847 36.8028 44.5043 36.9008 44.5043 36.9998C44.5043 37.0988 44.4847 37.1969 44.4466 37.2883C44.4085 37.3797 44.3528 37.4626 44.2825 37.5323Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_113_10484">
                      <rect width="48" height="48" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <ExcelFile
                filename={EXCEL_FORMAT_FILENAME}
                element={
                  <button
                    id="managestudents-bulkupload-gettemplate"
                    style={{ color: "#4F46E5" }}
                    className="font-medium"
                  >
                    Click to Download
                  </button>
                }
              >
                <ExcelSheet
                  dataSet={STUDENT_BULK_UPLOAD_FORMAT}
                  name={EXCEL_FORMAT_FILENAME}
                />
              </ExcelFile>

              <p className="text-xs leading-4 font-normal text-gray-500 mt-1">
                Fill details mentioned under each column
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full customUploader ">
            <Dropzone onDropAccepted={(files) => handleDrop(files, divsData)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <p className="text-left font-bold text-base">
                    <span className="text-indigo-600  font-bold">Step 2:</span>{" "}
                    Upload Excel file
                  </p>
                  <>
                    <div
                      {...getRootProps()}
                      className="border-4 px-24 py-12 rounded-lg  bg-white h-48 mt-5 border-grey-300	border-dashed"
                    >
                      <input {...getInputProps()} />

                      <span className="flex flex-row justify-center">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_113_10463)">
                            <rect
                              x="3.5"
                              y="4.5"
                              width="42"
                              height="38"
                              rx="3.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="4"
                              y1="13.5"
                              x2="46"
                              y2="13.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="20.5"
                              x2="46"
                              y2="20.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="27.5"
                              x2="46"
                              y2="27.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="34.5"
                              x2="46"
                              y2="34.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13.5"
                              y1="14"
                              x2="13.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="23.5"
                              y1="14"
                              x2="23.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="33.5"
                              y1="14"
                              x2="33.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <circle
                              r="8"
                              transform="matrix(1 0 0 -1 40 37)"
                              fill="#4338CA"
                            />
                            <path
                              d="M44.2825 36.4677L40.5325 32.7177C40.4611 32.6494 40.377 32.5959 40.285 32.5602C40.1024 32.4852 39.8976 32.4852 39.715 32.5602C39.6229 32.5959 39.5388 32.6494 39.4675 32.7177L35.7175 36.4677C35.6475 36.5376 35.5921 36.6206 35.5542 36.712C35.5164 36.8033 35.4969 36.9013 35.4969 37.0002C35.4969 37.1999 35.5762 37.3914 35.7175 37.5327C35.8587 37.6739 36.0502 37.7532 36.25 37.7532C36.4497 37.7532 36.6412 37.6739 36.7825 37.5327L39.25 35.0577V40.7502C39.25 40.9491 39.329 41.1398 39.4696 41.2805C39.6103 41.4212 39.801 41.5002 40 41.5002C40.1989 41.5002 40.3896 41.4212 40.5303 41.2805C40.6709 41.1398 40.75 40.9491 40.75 40.7502V35.0577L43.2175 37.5327C43.2872 37.603 43.3701 37.6588 43.4615 37.6968C43.5529 37.7349 43.6509 37.7545 43.75 37.7545C43.849 37.7545 43.947 37.7349 44.0384 37.6968C44.1298 37.6588 44.2127 37.603 44.2825 37.5327C44.3528 37.4629 44.4085 37.38 44.4466 37.2886C44.4847 37.1972 44.5043 37.0992 44.5043 37.0002C44.5043 36.9012 44.4847 36.8031 44.4466 36.7117C44.4085 36.6203 44.3528 36.5374 44.2825 36.4677Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_113_10463">
                              <rect width="48" height="48" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <p>
                        <span
                          id="managestudents-bulkupload-uploadfile"
                          className="text-sm leading-5 font-medium text-indigo-600"
                        >
                          Upload Excel file
                        </span>{" "}
                        <span className="text-sm text-gray-600">
                          or drag and drop here
                        </span>
                      </p>
                      <p className="text-xs leading-4 font-normal text-gray-500 mt-1">
                        We will share a preview once uploaded
                      </p>
                    </div>
                  </>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
        {errors ? (
          <div className="border-2 px-4 py-6 rounded-lg  bg-white flex flex-row mt-12 justify-between items-center">
            <ErrorMessage error={errors} />
          </div>
        ) : null}
        {success || showSuccessMessage ? (
          <div className="border-2 px-4 py-6 rounded-lg  bg-white flex flex-row mt-12 justify-between items-center">
            <SuccessMessage />
          </div>
        ) : null}
        <div className="text-left mt-8">
          <div className="flex items-center gap-x-4 mb-2">
            <h2 className="font-bold text-gray-700 m-0 p-0">
              Recently Uploaded Files
            </h2>
            {recentlyUploadedList?.files?.length > 5 && (
              <Button
                buttonStyle="primary"
                className="h-7 text-xs font-medium"
                onClick={showAllFiles}
              >
                {recentlyUploadedList?.isExpanded ? "View less" : "View all"}
              </Button>
            )}
          </div>
          <div className="flex flex-col gap-y-3">
            {recentlyUploadedList?.files
              ?.slice(0, recentlyUploadedList?.filesToShow)
              .map((file, index) => {
                // get filename after / in file key
                const fileName = file?.["Key"]?.split("/")[1];
                const downloadId =
                  "managestudents-bulkupload-download" + JSON.stringify(index);

                return (
                  <div
                    key={index}
                    className="bg-white rounded-xl shadow-sm flex justify-between p-2 items-center"
                  >
                    <div className="ml-1">
                      <p className="font-medium text-gray-700">{fileName}</p>
                    </div>
                    <div className="flex gap-x-5 items-center">
                      <p className="text-gray-400">
                        Uploaded on :{" "}
                        {moment(file?.["LastModified"]).format(
                          "DD MMM YYYY - hh:mma"
                        )}
                      </p>
                      <Button
                        id={downloadId}
                        buttonStyle="success"
                        className="h-9 rounded-md"
                        onClick={() => handleFileDownload(file?.["Key"])}
                      >
                        <DownloadIcon className="h-6 w-5 mr-2" />
                        Download
                      </Button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Spin>
    </div>
  );
}
export default StudentBulkUpload;
