import moment from "moment";
import { useState } from "react";
import { DATE_FORMATS } from "../../utils/constants";
import useCreateExpense from "../../hooks/useCreateExpense";
import useUploadFeesAsset from "../../hooks/useUploadFeesAsset";
import useCreateExpenseCategory from "../../hooks/useCreateExpenseCategory";
import useUpdateExpense from "../../hooks/useUpdateExpense";
import { v4 as uuid } from "uuid";
import { notification, Upload, message } from "antd";
import { getSchoolID } from "../../shared/getSchoolID";
import { getAuthID } from "../../shared/getAuthID";
import { Radio, DatePicker, Select } from "antd";
import deleteIcon from "../../assets/images/delete.png";
import Button from "../Button/Button";
import { InfoCircleFilled, CalendarOutlined } from "@ant-design/icons";
import useTracking from "../../hooks/useTracking";

const UploadCard = ({ handleUpload, title }) => {
  const handleCustomRequest = ({ file, onSuccess }) => {
    return handleUpload(file, onSuccess);
  };

  const checkIfFileIsImageOrVideo = (file) => {
    if (file.size > 5 * 1000 * 1000) {
      message.error("File size too large, should be less than 5 MB");
      return false;
    }
    return true;
  };

  return (
    <div>
      <Upload
        customRequest={handleCustomRequest}
        beforeUpload={checkIfFileIsImageOrVideo}
        showUploadList={false}
        className="bg-white rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Button
          style={{
            borderRadius: 6,
            fontWeight: "bold",
            color: "#374151",
          }}
        >
          Upload
        </Button>
      </Upload>
    </div>
  );
};

const validateForm = (formDetails, newCategory) => {
  if (!formDetails?.title?.trim() || !formDetails.amountType) {
    notification["error"]({
      message: "Incomplete Details",
      description: `Title and Amount Type are mandatory fields.`,
      duration: 3,
    });
    return false;
  }

  if (formDetails?.amount === "" || formDetails?.amount === undefined) {
    notification["error"]({
      message: "Incomplete Details",
      description: `Amount is required`,
      duration: 3,
    });
    return false;
  }

  if (formDetails?.category === null && !newCategory?.trim()?.length) {
    notification["error"]({
      message: "Incomplete Details",
      description: `New Expense Category cannot be empty`,
      duration: 3,
    });
    return false;
  }
  return true;
};

const ExpenseForm = ({
  selectedExpense,
  onCancel,
  category,
  amount,
  onSave,
}) => {
  const [formDetails, setFormDetails] = useState({
    ...selectedExpense,
    date: selectedExpense?.date || moment().format(DATE_FORMATS.YYYYMMDD),
    time: selectedExpense?.time || moment().format(DATE_FORMATS.HHMMA),
    category: selectedExpense?.category || category?.[0]?.value,
    categoryTitle: selectedExpense?.categoryTitle || category?.[0]?.label,
    amountType: selectedExpense?.amountType || amount?.[0]?.value,
  });
  const [newCategory, setNewCategory] = useState("");
  const [attachments, setAttachments] = useState(
    selectedExpense?.attachments || []
  );

  const { mutateAsync: createExpenseCategory } = useCreateExpenseCategory();
  const { mutateAsync: saveFeesAsset } = useUploadFeesAsset();
  const { mutateAsync: createExpense } = useCreateExpense();
  const { mutateAsync: updateExpense } = useUpdateExpense();
  const { trackEvent } = useTracking();

  const saveFileInMemory = (file) => {
    const newFileAttatchment = {
      size: file.size,
      title: file.name,
      file,
      id: uuid(),
      isUploaded: false,
    };
    setAttachments((prevAttachments) => [
      ...prevAttachments,
      newFileAttatchment,
    ]);
  };

  const handleSave = async () => {
    // Validation
    if (!validateForm(formDetails, newCategory)) return;
    try {
      // Save Expense Category
      let categoryId = formDetails?.category;
      if (formDetails?.category === null) {
        const { data: categoryData } = await createExpenseCategory({
          title: newCategory,
          schoolId: getSchoolID(),
        });
        categoryId = categoryData;
      }

      // Save Images
      const uploadedFiles = attachments?.filter(
        ({ isUploaded }) => isUploaded === true
      );
      const attachmentsToSave = attachments?.filter(
        ({ isUploaded }) => isUploaded === false
      );
      const filesToUpload = attachmentsToSave?.map(({ file, id }) => ({
        file,
        id,
      }));

      let finalListOfFiles = uploadedFiles || [];
      if (filesToUpload.length) {
        const { data: uploadedFilesResponse } = await saveFeesAsset(
          filesToUpload
        );
        const { success = [] } = uploadedFilesResponse;

        const formattedSuccessFiles = success.map((file) => {
          const { fileName, value: url } = file;
          const id = fileName?.split(".")?.[0];
          const correspondingFile = attachments.find(
            ({ id: currentFileId }) => currentFileId === id
          );
          return {
            size: correspondingFile?.size / (1000 * 1000),
            title: correspondingFile?.title,
            url,
          };
        });
        finalListOfFiles = [...uploadedFiles, ...formattedSuccessFiles];
      }

      // Save Expense
      const payload = {
        expenseCategoryId: categoryId,
        title: formDetails?.title,
        amount: formDetails?.amount,
        date: formDetails?.date,
        time: formDetails?.time,
        paymentCategory: formDetails?.amountType,
        note: formDetails?.note,
        attachments: finalListOfFiles,
        schoolId: getSchoolID(),
        createdBy: getAuthID(),
      };

      // Insert condition, as selectedExpense is not passed
      if (!selectedExpense) {
        trackEvent("Create Expense");
        await createExpense(payload);
        onSave?.();
        return;
      }

      trackEvent("Update Expense");
      // Update condition
      payload.expenseId = selectedExpense?.expenseId;
      await updateExpense(payload);
      onSave?.();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = (id) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter(({ id: currentId }) => currentId !== id)
    );
  };

  const handleDateChange = (date) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      date: moment(date).format(DATE_FORMATS.YYYYMMDD),
    }));
  };

  const handleTimeChange = (time) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      time: moment(time).format(DATE_FORMATS.HHMMA),
    }));
  };

  return (
    <div className="flex flex-col border-opacity-10">
      <form className="flex flex-col gap-6 mt-2">
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
            Category
          </label>
          <Select
            className="customised-antd-selector"
            value={formDetails?.categoryTitle}
            style={{
              flex: 1,
              width: "60%",
              textAlign: "left",
              color: "#111827",
              fontWeight: 500,
              fontSize: 14,
            }}
            defaultValue={category?.[0]}
            onChange={(value) => {
              setNewCategory("");
              const finalCategory = category?.find(
                (currentCategory) => currentCategory.value === value
              );
              setFormDetails({
                ...formDetails,
                category: value || null,
                categoryTitle: finalCategory?.label || "Add New Category",
              });
            }}
            size="large"
            allowClear
            placeholder="Select category"
          >
            {category?.map((currentCategory) => (
              <Select.Option
                key={currentCategory.value}
                value={currentCategory.value}
                label={currentCategory.label}
                style={{
                  flex: 1,
                  textAlign: "left",
                  color: "#374151",
                  fontWeight: 400,
                  fontSize: 14,
                }}
              >
                {currentCategory.label}
              </Select.Option>
            ))}
            <Select.Option value={null} label="Add New Category">
              <div className="text-primary">Add New Category</div>
            </Select.Option>
          </Select>
          {formDetails?.category === null ? (
            <div className="mt-4 flex flex-col">
              <input
                type="text"
                placeholder="Type category name"
                className="border border-gray-400 h-10 rounded-md pl-2 outline-none text-textGrey font-medium text-sm"
                value={newCategory}
                style={{
                  width: "60%",
                }}
                onChange={(e) => setNewCategory(e.target.value)}
                name="categoryName"
                autoFocus
              />
            </div>
          ) : null}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
            Title
            <span className="text-cancel">*</span>
          </label>
          <input
            type="text"
            className="text-textGrey font-medium text-sm w-3/5 border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
            value={formDetails.title}
            required
            placeholder="Type title name here..."
            onChange={(e) =>
              setFormDetails({ ...formDetails, title: e.target.value })
            }
          />
        </div>
        <>
          <div className="flex flex-row gap-x-4">
            <div className="flex flex-col w-3/5">
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left">
                Amount
                <span className="text-cancel">*</span>
              </label>
              <input
                type="number"
                className="text-textGrey font-medium text-sm border border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700"
                value={formDetails?.amount}
                required
                placeholder="Enter amount here..."
                onChange={(e) =>
                  setFormDetails({ ...formDetails, amount: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                Date
              </label>
              <div className="flex gap-x-4">
                <DatePicker
                  value={moment(formDetails?.date)}
                  format={"D MMM, YYYY"}
                  style={{
                    height: "40px",
                    borderRadius: 6,
                  }}
                  allowClear={false}
                  suffixIcon={<CalendarOutlined style={{ color: "#111827" }} />}
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <div className="flex flex-col bg-white">
              <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
                Time
              </label>
              <DatePicker
                value={moment(
                  formDetails?.time?.split("+")?.[0] || new Date(),
                  DATE_FORMATS.HHMMSS
                )}
                picker="time"
                allowClear={false}
                format={DATE_FORMATS.HHMMA}
                style={{
                  borderRadius: 6,
                  height: "40px",
                }}
                suffixIcon={<CalendarOutlined style={{ color: "#111827" }} />}
                onChange={handleTimeChange}
              />
            </div>
          </div>
          <div>
            <Radio.Group
              onChange={(e) => {
                setFormDetails({ ...formDetails, amountType: e.target.value });
              }}
              value={formDetails.amountType}
              style={{
                display: "flex",
                columnGap: "0.75rem",
              }}
            >
              {amount.map((amountType) => (
                <div className="bg-white">
                  <Radio
                    value={amountType.value}
                    checked={formDetails.amountType === amount.value}
                  >
                    <strong className="font-normal text-greyLight">
                      {amountType.label}
                    </strong>
                  </Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
        </>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
            Note
          </label>
          <input
            type="text"
            className="border mt-3 border-gray-300 rounded-md p-2 shadow-sm focus:ring-2 focus:outline-none focus:border-transparent ring-indigo-700 text-textGrey font-medium text-sm"
            value={formDetails.note}
            required
            placeholder="Type any extra note here..."
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                note: e.target.value?.slice(0, 30),
              })
            }
          />
          <div className="self-end">
            <p className="text-textGrey">
              {formDetails?.note?.length || 0}/30 characters
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full justify-center">
          <label className="text-gray-600 font-medium text-sm leading-5 pb-1 text-left ">
            Add Attachment
          </label>
          <div className="flex flex-col w-full">
            {attachments.map((attachment) => (
              <div className="flex justify-between items-center">
                <div className="mt-3 p-2 border  border-gray-300 rounded-md flex-1 flex-row">
                  <span className="font-semibold ">{attachment?.title}</span>
                </div>
                <div
                  className="hover:cursor-pointer"
                  onClick={() => handleDelete(attachment.id)}
                  style={{ height: 24, width: 24, marginLeft: 6 }}
                >
                  <img src={deleteIcon} alt="Delete Button" />
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-start items-center py-3 h-12 my-3">
            <UploadCard handleUpload={saveFileInMemory} />
            <div className="p-2.5 bg-backgroundGrey mx-3 rounded-md flex items-center">
              <InfoCircleFilled
                className="mr-1.5"
                style={{
                  color: "#6B7280",
                }}
              />
              <p className="text-sm text-textGrey">File size limit is 5MB</p>
            </div>
          </div>
        </div>

        <div className="flex gap-x-6 items-end justify-end">
          <Button onClick={onCancel} className="py-3 px-4 w-32 h-12">
            Cancel
          </Button>
          <Button
            buttonStyle="primary"
            className="py-3 px-4 w-44 h-12"
            onClick={handleSave}
          >
            {selectedExpense ? "Save Changes" : "Create Expense"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ExpenseForm;
