import React, { useState, useEffect } from "react";
import Logo from "../images/Logo2x.png";
import { useNavigate } from "react-router-dom";
import fireBaseApp, { uiConfig } from "../shared/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { validateAuthId } from "../shared/getAuthID";

const Auth = () => {
  let navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userToken, setUserToken] = useState();
  const [userClaims, setUserClaims] = useState(null);
  const [isAdmin, setisAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isRegionalAdmin, setIsRegionalAdmin] = useState(false);

  

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = fireBaseApp
      .auth()
      .onAuthStateChanged((user) => {                
        if (user) {
          user.getIdTokenResult().then((res) => {
            validateAuthId(res?.claims?.phone_number);
            let checkClaims = res.claims.hasOwnProperty(
              "https://hasura.io/jwt/claims",
            );
            if (checkClaims) {
              let claims = res.claims["https://hasura.io/jwt/claims"];
              if (
                claims["x-hasura-default-role"] !== "school_admin" &&
                claims["x-hasura-default-role"] !== "admin" &&
                claims["x-hasura-default-role"] !== "regional_admin"
              ) {
                setisAdmin(false);
              } else {
                localStorage.setItem("token", res.token);
                localStorage.setItem(
                  "claims",
                  JSON.stringify(res.claims["https://hasura.io/jwt/claims"]),
                );
                window.analytics.identify(res?.claims?.user_id, {
                  phone: res?.claims?.phone_number,
                });
                setisAdmin(true);
                setUserToken(res.token);
                setUserClaims(res.claims["https://hasura.io/jwt/claims"]);
                if (claims["x-hasura-default-role"] === "admin") {
                  setIsSuperAdmin(true);
                  localStorage.setItem("isSuperAdmin", true);
                  navigate("/adminsettings", { replace: true });
                } else if (
                  claims["x-hasura-default-role"] === "regional_admin"
                ) {
                  setIsRegionalAdmin(true);
                  localStorage.setItem("IsRegionalAdmin", true);
                  navigate("/adminsettings", { replace: true });
                } else {
                  navigate("/", { replace: true });
                }
              }
            } else {
              setisAdmin(false);
            }
          });
        }
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const onSignOut = () => {
    localStorage.clear();
    setUserToken(null);
    setIsSignedIn(false);
    setUserClaims(null);
    fireBaseApp.auth().signOut();
  };

  if (!isSignedIn) {
    return (
      <div
        style={{
          backgroundColor: "#F9FAFB",
          minHeight: "100vh",
          paddingTop: "1rem",
        }}
      >
        <div className=" mx-auto my-auto flex flex-col w-full max-w-md px-4 py-8  sm:px-6 md:px-12 lg:px-10">
          <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
            <img alt="Logo" src={Logo} className="mx-auto w-16 h-16 mb-6" />
            <span className="text-3xl leading-9 font-extrabold text-black ">
              Sign in to your account
            </span>
          </div>
        </div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={fireBaseApp.auth()}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#F9FAFB",
        minHeight: "100vh",
        paddingTop: "1rem",
      }}
    >
      <div className=" mx-auto my-auto flex flex-col w-full max-w-md px-4 py-8  sm:px-6 md:px-12 lg:px-10">
        <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
          <img alt="Logo" src={Logo} className="mx-auto w-10 mb-6" />
          <span className="text-3xl leading-9 font-extrabold text-black ">
            Sign in to your account
          </span>
        </div>
        {!isAdmin ? (
          <div>
            <div className="flex flex-row items-start bg-yellow-50 p-4 gap-x-4">
              <span className="flex flex-col">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.25694 3.09882C9.02154 1.73952 10.9786 1.73952 11.7432 3.09882L17.3235 13.0194C18.0735 14.3526 17.11 15.9999 15.5804 15.9999H4.41978C2.89013 15.9999 1.9267 14.3526 2.67663 13.0194L8.25694 3.09882ZM11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13ZM10 5C9.44772 5 9 5.44772 9 6V9C9 9.55228 9.44772 10 10 10C10.5523 10 11 9.55228 11 9V6C11 5.44772 10.5523 5 10 5Z"
                    fill="#FBBF24"
                  />
                </svg>
              </span>
              <div className="flex flex-col text-left">
                <p className="text-yellow-800 text-sm leading-5 font-medium mb-2">
                  This account does not exist
                </p>
                <p className="text-yellow-700 text-sm leading-5 font-normal mb-2">
                  Please contact GroundUp and register your number
                </p>
              </div>
            </div>
            <button
              type="submit"
              className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg mt-4"
              onClick={() => onSignOut()}
            >
              Sign out
            </button>
          </div>
        ) : (
          <div className="flex w-full mt-8">
            <button
              type="submit"
              className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              onClick={() => navigate("/")}
            >
              Take me home
            </button>
            <button
              type="submit"
              className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              onClick={() => onSignOut()}
            >
              Sign out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Auth;