import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, DatePicker, Form } from "antd";
import moment from "moment";
import Button from "../../../components/Button";
import { GET_CURRENT_ACADEMIC_YEAR } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { getSchoolID } from "../../../shared/getSchoolID";
import { Dropdown as AntdDropdown } from "antd";
import { DATE_FORMATS } from "../../../utils/constants";
import { EditModalContext } from "../ExamsTab/CCETab";
import { MENU_OPTIONS } from "../../../utils/constants";
import { MoreOutlined, PlusCircleOutlined } from "@ant-design/icons";
import deleteIcon from "../../../assets/images/delete.png";

const items = [
  {
    label: (
      <div className="flex text-greyLight items-center">
        <PlusCircleOutlined className="text-greyLight" />
        <p className="text-greyLight pl-1 font-medium">Add/Remove Sub-Exam</p>
      </div>
    ),
    key: MENU_OPTIONS.EDIT,
  },
  {
    label: (
      <div className="flex text-cancel items-center">
        <img
          src={deleteIcon}
          alt="Delete Button"
          style={{ width: 14, height: 14 }}
        />
        <p className="text-cancel pl-1 font-medium">Delete Exam</p>
      </div>
    ),
    key: MENU_OPTIONS.DELETE,
  },
];

const getTotalForSubject = (subject, assessment_subject_id) => {
  const assessment = subject?.params?.find(
    (currentAssessment) =>
      currentAssessment?.assessmentSubjectId === assessment_subject_id
  );
  const addedAssessment = assessment?.eval_params?.reduce(
    (acc, currentParameter) => {
      if (currentParameter?.max_marks) {
        return acc + currentParameter?.max_marks;
      }
      return acc;
    },
    0
  );
  return addedAssessment;
};

const getNewTableState = (record, assessmentId, eval_param_id, max_marks) => {
  const newRecord = JSON.parse(JSON.stringify(record));
  let assessment_subject_id;

  const newParams = newRecord?.params?.map((assessment) => {
    if (parseInt(assessment?.assessmentId) === parseInt(assessmentId)) {
      const newParams = assessment?.eval_params?.map((eval_param) => {
        if (parseInt(eval_param?.eval_param_id) === parseInt(eval_param_id)) {
          assessment_subject_id = eval_param?.assessment_subject_id;
          return { ...eval_param, max_marks: parseInt(max_marks) };
        }
        return eval_param;
      });
      assessment.eval_params = newParams;
    }
    return assessment;
  });

  newRecord.params = newParams;

  return [
    newRecord,
    {
      max_marks: parseInt(max_marks),
      assessment_subject_id,
      eval_param_id,
      assessmentId,
    },
  ];
};

const calculateAssessmentTotal = (pageData) => {
  const summaryData = [];
  const numberOfEvalParamsForAssessment = new Map();
  pageData?.[0]?.params?.forEach((eval_param) => {
    numberOfEvalParamsForAssessment.set(
      eval_param.assessmentId,
      eval_param?.eval_params?.length
    );
  });

  // Initializing empty arrays
  for (let [
    assessmentId,
    evalParamsLength,
  ] of numberOfEvalParamsForAssessment) {
    const arrayForSumOfCurrentAssessment = Array(evalParamsLength).fill(0);
    summaryData.push({
      rowTotal: 0,
      row: arrayForSumOfCurrentAssessment,
      assessmentId,
    });
  }

  // Adding max_marks to correct index
  pageData?.forEach((subject) => {
    subject?.params?.forEach((assessment, idx) => {
      assessment?.eval_params?.forEach((eval_param, eval_idx) => {
        const marks = eval_param?.max_marks;
        summaryData[idx].row[eval_idx] += marks;
        summaryData[idx].rowTotal += marks;
      });
    });
  });

  let overallTotal = 0;
  summaryData?.forEach((total) => {
    overallTotal += total?.rowTotal;
  });
  return { overallTotal, summaryData };
};

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableHeader = ({
  title,
  examTitle,
  column,
  editable,
  isGroup,
  isEditable,
  style,
  children,
  dataIndex,
  handleSave,
  examId,
  isSubExam,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const [formFields, setFormFields] = useState({
    [dataIndex]: examTitle,
  });
  const { isEditModalVisible, isCCE, handleExamDelete, showSubExamAddModal } =
    useContext(EditModalContext) || {};
  let childNode = children;

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const onMenuClick = (key, id) => {
    switch (true) {
      case key === MENU_OPTIONS.DELETE:
        handleExamDelete(id);
        break;
      case key === MENU_OPTIONS.EDIT:
        showSubExamAddModal(id);
        break;
      default:
        break;
    }
  };

  const toggleEdit = () => {
    if (!isEditable) return;
    setEditing(!editing);
  };

  const save = async () => {
    try {
      if (examTitle !== formFields[dataIndex]) {
        column.editedKeys = [
          ...(column?.editedKeys || []),
          { id: column.id, title: formFields[dataIndex] },
        ];
      }
      toggleEdit();
      setFormFields({
        ...formFields,
        editedKeys: [...(column?.editedKeys || [])],
      });
      if (!dataIndex) return;

      handleSave(dataIndex, formFields[dataIndex]);
    } catch (error) {
      console.error("Save failed:", error);
    }
  };

  if (editable && !isGroup && editing) {
    childNode = (
      <>
        <input
          ref={inputRef}
          defaultValue={examTitle}
          value={formFields[dataIndex]}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              [dataIndex]: e.target.value,
            });
          }}
          onBlur={save}
          style={{
            outline: "none",
            border: "none",
            width: "100%",
            height: "4rem",
            textAlign: "center",
          }}
        />
      </>
    );
  } else {
    const editModeStyle =
      editable && !isGroup && isEditable
        ? {
            color: "black",
          }
        : {
            color: "#6B7280",
          };
    childNode = (
      <div
        className="editable-cell-value-wrap font-semibold text-gray-500 flex flex-1"
        style={{
          ...style,
          ...editModeStyle,
        }}
        onClick={() => setEditing(true)}
      >
        <div className="flex flex-1 justify-center">
          {formFields[dataIndex]}
        </div>
        {!isSubExam && (
          <div
            className={
              isEditModalVisible
                ? "flex justify-self-end  justify-end mx-2 rounded-md h-full"
                : ""
            }
          >
            {isEditModalVisible && !isCCE ? (
              <div onClick={(e) => e.stopPropagation()}>
                <AntdDropdown
                  menu={{
                    items,
                    onClick: ({ key }) => onMenuClick(key, examId),
                    style: {
                      borderRadius: 6,
                    },
                  }}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <div className="absolute cursor-pointer w-5 h-5">
                    <MoreOutlined
                      className="relative right-5 w-full h-full"
                      style={{
                        fontSize: 24,
                        color: "#111827",
                      }}
                    />
                  </div>
                </AntdDropdown>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }

  const editedClass =
    isEditable &&
    (formFields[dataIndex] && formFields[dataIndex] !== examTitle
      ? "bg-yellow-100"
      : "");
  const editingStyle =
    isEditable && editing && !isGroup
      ? {
          border: "2px solid black",
        }
      : {
          borderRight: "1px solid #d1d5db",
          borderBottom: "1px solid #d1d5db",
        };
  const groupClass = isGroup ? "bg-lightPurple" : "bg-lighterPurple";
  return (
    <td
      {...restProps}
      key={dataIndex}
      style={{
        ...style,
        ...editingStyle,
        minWidth: "6rem",
      }}
      className={`text-center py-3 w-auto justify-center ${editedClass} ${
        !editedClass ? groupClass : ""
      }`}
    >
      {!isEditable && !editable ? children : childNode}
    </td>
  );
};

const getListOfSubject = (tableData) => {
  const updatedSubjectState = [];

  tableData?.forEach((subject) => {
    updatedSubjectState.push({
      id: subject.key,
      isEdited: false,
      total: {},
      updatedFields: [], // Store {assessment_subject_id, eval_params, max_marks} that have changed
    });
  });
  return updatedSubjectState;
};

const getEditedSubjects = (
  prevState,
  assessmentId,
  subjectId,
  eval_param_id,
  max_marks,
  assessment_subject_id,
  tableData
) => {
  const newState = prevState.map((subjectData) => {
    if (subjectData.id !== subjectId) {
      return subjectData;
    }
    const subjectDataCopy = JSON.parse(JSON.stringify(subjectData));
    const total = getTotalForSubject(tableData, assessment_subject_id);
    subjectDataCopy.total[`${assessment_subject_id}`] = total;

    if (!subjectDataCopy.isEdited) {
      subjectDataCopy.isEdited = true;
      subjectDataCopy.updatedFields.push({
        eval_param_id,
        max_marks,
        assessment_subject_id,
        assessmentId,
      });
      return subjectDataCopy;
    }

    // Logic to avoid multiple eval_param_ids and assessment_subject_id combination, having different max_marks ending up in payload
    let isCurrentFieldPresent = false;
    let newUpdatedFields = subjectDataCopy.updatedFields.map((field) => {
      if (
        parseInt(field.eval_param_id) === parseInt(eval_param_id) &&
        parseInt(field.assessment_subject_id) ===
          parseInt(assessment_subject_id)
      ) {
        isCurrentFieldPresent = true;
        return {
          eval_param_id,
          max_marks,
          assessment_subject_id,
          assessmentId,
        };
      }
      return field;
    });

    subjectDataCopy.updatedFields = newUpdatedFields;
    if (!isCurrentFieldPresent) {
      newUpdatedFields.push({
        eval_param_id,
        max_marks,
        assessment_subject_id,
        assessmentId,
      });
    }

    return subjectDataCopy;
  });
  return newState;
};

const EditableCell = ({
  title,
  isDateTime,
  isGroup,
  isEditable,
  editable,
  children,
  dataIndex,
  examId,
  record,
  column,
  assessmentId,
  evalParamId,
  handleSave,
  setSubjectsUpdated,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { data: currentYearData } = useQuery(GET_CURRENT_ACADEMIC_YEAR, {
    variables: { schoolId: getSchoolID() },
  });

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (isGroup || !isEditable) return;
    setEditing(!editing);
  };

  const save = async (marksToRender) => {
    try {
      const values = await form.validateFields();
      const parsedValues = {};
      let max_marks = 0;
      let eval_param = 0;
      let assessmentId = 0;

      Object.keys(values).forEach((key) => {
        const [
          assessmentIdFromObject,
          evalParamFromObject,
          assessmentSubjectIdFromObject,
        ] = key.split(",");
        assessmentId = assessmentIdFromObject;
        eval_param = evalParamFromObject;
        max_marks = values[key];
      });

      if (max_marks === undefined) {
        max_marks = marksToRender;
      }

      // Highlighting Logic
      if (parseInt(marksToRender) !== parseInt(max_marks)) {
        record.editedKeys = [
          ...(record?.editedKeys || []),
          {
            id: examId,
            title: dataIndex,
          },
        ];
      }
      toggleEdit();
      const [newRecord, editedValues] = getNewTableState(
        record,
        assessmentId,
        eval_param,
        max_marks
      );
      setSubjectsUpdated((prevState) =>
        getEditedSubjects(
          prevState,
          assessmentId,
          record.key,
          eval_param,
          max_marks,
          editedValues?.assessment_subject_id,
          newRecord
        )
      );
      handleSave(newRecord);
    } catch (error) {
      console.error("Save failed:", error);
    }
  };

  const saveDate = (value) => {
    const values = {};
    const formattedDate = moment(value).format(DATE_FORMATS.YYYYMMDDHHMMA);
    if (record[dataIndex] !== formattedDate) {
      record.editedKeys = [
        ...(record?.editedKeys || []),
        {
          id: examId,
          title: dataIndex,
        },
      ];
    }
    values[dataIndex] = formattedDate;
    toggleEdit();
    handleSave({ ...record, ...values });
  };

  const renderDisplay = () => {
    if (isDateTime) {
      const dateDisplay = record[dataIndex]
        ? moment(record[dataIndex], DATE_FORMATS.YYYYMMDDHHMMA).format(
            DATE_FORMATS.DDMMMYYYY
          )
        : "-";
      const timeDisplay = record[dataIndex]
        ? moment(record[dataIndex], DATE_FORMATS.YYYYMMDDHHMMA).format(
            DATE_FORMATS.HHMMA
          )
        : "-";
      if (dateDisplay === "-" && timeDisplay === "-" && isEditable) {
        return (
          <div
            className="editable-cell-value-wrap text-center items-center flex justify-center whitespace-nowrap"
            style={{
              paddingRight: 24,
            }}
            onClick={toggleEdit}
          >
            <div className="text-left">
              <p className="text-gray-400">Select Date</p>
            </div>
          </div>
        );
      }
      return (
        <div
          className="editable-cell-value-wrap text-center items-center flex justify-center whitespace-nowrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          <div className="text-left">
            <p className="font-semibold">{dateDisplay}</p>
            <p className="text-gray-400">{timeDisplay}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  };

  const disabledDate = (current) => {
    const fromDate =
      currentYearData?.schools_by_pk?.acadamic_years?.[0]?.from_date;
    const toDate = currentYearData?.schools_by_pk?.acadamic_years?.[0]?.to_date;
    // show disabled date if current date is not in the academic year
    if (
      current < moment(fromDate, DATE_FORMATS.YYYYMMDD) ||
      current > moment(toDate, DATE_FORMATS.YYYYMMDD).add(1, "day")
    ) {
      return true;
    }
    return false;
  };

  let childNode = children;
  if (editable) {
    if (isDateTime && editing) {
      childNode = (
        <DatePicker
          ref={inputRef}
          format={DATE_FORMATS.YYYYMMDDHHMMA}
          showTime={{
            defaultValue: moment("00:00", DATE_FORMATS.HHMMA),
            use12Hours: true,
          }}
          open
          disabledDate={disabledDate}
          onBlur={toggleEdit}
          onOk={saveDate}
          style={{
            width: "100%",
            height: "6rem",
            outline: "none",
            border: "none",
          }}
        />
      );
    } else {
      const assessmentBeingEdited = record?.params?.find(
        (assessment) => assessment?.assessmentId === assessmentId
      );
      const evalParamBeingEdited = assessmentBeingEdited?.eval_params?.find(
        (evalParam) => evalParam?.eval_param_id === evalParamId
      );

      const marksToRender = evalParamBeingEdited?.max_marks || "";
      childNode = editing ? (
        <div className="editable-cell-value-wrap flex justify-center">
          <Form.Item
            style={{
              margin: 0,
              width: "10rem",
              outline: "none",
              border: "none",
            }}
            name={dataIndex}
            rules={[
              {
                required: marksToRender ? false : true,
                message: `This is required.`,
              },
            ]}
          >
            <input
              ref={inputRef}
              type="number"
              min="0"
              onBlur={() => save(marksToRender)}
              style={{
                width: "100%",
                minWidth: "6rem",
                height: "4rem",
                outline: "none",
                border: "none",
                textAlign: "center",
              }}
              defaultValue={marksToRender}
            />
          </Form.Item>
        </div>
      ) : (
        renderDisplay()
      );
    }
  }

  const editedClass =
    isEditable && record?.["editedKeys"]?.some((key) => key.title === dataIndex)
      ? "bg-yellow-100"
      : "";
  const editingStyle =
    isEditable && editing
      ? {
          border: "2px solid black",
          padding: 0,
        }
      : {
          borderRightColor: "#d1d5db",
          borderBottomColor: "#d1d5db",
        };
  let editModeStyle = {};
  if (!editable) {
    editModeStyle = {
      color: "#6B7280",
    };
  }
  return (
    <td
      {...restProps}
      className={`${editedClass}`}
      style={{
        ...editingStyle,
        ...editModeStyle,
      }}
    >
      {childNode}
    </td>
  );
};

function EditableTable({
  tableColumns,
  tableData,
  isEditable,
  showTotal,
  onCancel,
  onSave,
  isCCE,
  isImportTable,
  updateParentState,
}) {
  const [columns, setColumns] = useState(tableColumns);
  const [data, setData] = useState({
    dataSource: tableData,
    count: 2,
  });
  const [editedFormFields, setEditedFormFields] = useState(
    getListOfSubject(data.dataSource)
  );
  const [updatedNames, setUpdatedNames] = useState({
    assessmentNames: {},
    evalParamNames: {},
  });

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }

    const updateAssessmentNames = (oldAssessmentName, newAssessmentName) => {
      setUpdatedNames((prevNames) => ({
        ...prevNames,
        assessmentNames: {
          ...prevNames.assessmentNames,
          [oldAssessmentName]: newAssessmentName,
        },
      }));
    };

    const updateEvalParamNames = (key, newEvalParamName) => {
      setUpdatedNames((prevNames) => ({
        ...prevNames,
        evalParamNames: {
          ...prevNames.evalParamNames,
          [key]: newEvalParamName,
        },
      }));
    };

    const newCol = {
      ...col,
      onHeaderCell: (column) => {
        return {
          column,
          editable: col.editable && !isCCE,
          isGroup: col.isGroup,
          isEditable: isEditable && !isCCE,
          style: col.headerStyle || {},
          dataIndex: col.dataIndex,
          examId: col.id,
          title: col.title,
          examTitle: col.examTitle,
          isSubExam: col?.isSubExam,
          handleSave: col?.isSubExam
            ? updateEvalParamNames
            : updateAssessmentNames,
        };
      },
      onCell: (record) => ({
        record,
        editable: col.editable,
        isDateTime: col.isDateTime,
        isGroup: col.isGroup,
        isEditable: isEditable,
        dataIndex: col.dataIndex,
        examId: col.id,
        title: col.title,
        assessmentId: col?.assessmentId,
        evalParamId: col?.evalParamId,
        handleSave: handleRowSave,
        setSubjectsUpdated,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  // To hold unique set of subjects that were unpdated
  const [subjectsUpdated, setSubjectsUpdated] = useState(
    getListOfSubject(data.dataSource)
  );

  // This is used for import Editable render, to update parent
  // TODO: If UI lags, find a way to update parentState only when term changes, and use editedFormFields for generating payload current selected term on save
  useEffect(() => {
    if (updateParentState) {
      updateParentState(editedFormFields);
    }
  }, [editedFormFields]);

  useEffect(() => {
    setData({
      dataSource: tableData,
      count: 2,
    });
    const defaultData = [...tableData];
    setEditedFormFields({
      ...editedFormFields,
      data: defaultData,
    });
  }, [tableData]);

  useEffect(() => {
    setColumns(tableColumns);
  }, [tableColumns]);

  const handleColumnSave = (column) => {
    const newColumns = { ...(editedFormFields?.columns || {}) };
    const dataIndex = column.dataIndex;
    const newValue = column[dataIndex];
    newColumns[dataIndex] = newValue;
    newColumns["editedKeys"] = [
      ...(newColumns["editedKeys"] || []),
      {
        id: column.id,
        title: newValue,
      },
    ];
    setEditedFormFields({ ...editedFormFields, columns: newColumns });
  };

  const handleRowSave = (row) => {
    const rowId = row?.key;
    const newSourceData = dataSource?.map((currentRow) => {
      if (currentRow.key === rowId) {
        return row;
      }
      return currentRow;
    });
    setData({
      dataSource: newSourceData,
    });

    setEditedFormFields({
      ...editedFormFields,
      data: newSourceData,
    });
  };

  const handleSaveChanges = () => {
    onSave(subjectsUpdated, editedFormFields, updatedNames);
  };

  const { dataSource } = data;
  const components = {
    header: {
      row: EditableRow,
      cell: EditableHeader,
    },
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const displayColumns = columns.map(mapColumns);
  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={displayColumns}
        pagination={false}
        scroll={{ x: 400 }}
        summary={(pageData) => {
          if (showTotal) {
            const { overallTotal, summaryData } =
              calculateAssessmentTotal(pageData);

            // Single Cell Offset as subject has no total
            return (
              <>
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    {summaryData?.length ? (
                      <Table.Summary.Cell className="text-textGrey font-semibold summary-cell">
                        Total
                      </Table.Summary.Cell>
                    ) : null}
                    {summaryData?.map((assessment) => {
                      const currentAssessment = assessment?.row?.map(
                        (eval_param_total) => (
                          <Table.Summary.Cell className="text-center items-center text-textGrey font-semibold summary-cell">
                            {eval_param_total}
                          </Table.Summary.Cell>
                        )
                      );
                      const total_marks_cell = (
                        <Table.Summary.Cell className="text-center items-center text-textGrey font-semibold summary-cell">
                          {assessment?.rowTotal}
                        </Table.Summary.Cell>
                      );
                      currentAssessment.push(total_marks_cell);
                      return currentAssessment;
                    })}
                    {summaryData?.length && !isImportTable ? (
                      <Table.Summary.Cell className="text-center items-center text-textGrey font-semibold summary-cell">
                        {overallTotal}
                      </Table.Summary.Cell>
                    ) : null}
                  </Table.Summary.Row>
                </Table.Summary>
              </>
            );
          }
        }}
        className="custom-table"
      />
      {isEditable && (
        <div className="flex justify-between mt-4">
          <div className="flex items-center gap-x-2">
            <div className="w-12 h-10 border bg-yellow-100"></div>
            <p className="text-black">- Edited cells</p>
          </div>
          <div className="flex gap-x-4">
            <Button onClick={onCancel}>Cancel</Button>
            <Button buttonStyle="primary" onClick={handleSaveChanges}>
              Save changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

EditableTable.defaultProps = {
  tableColumns: [],
  tableData: [],
  showTotal: true,
  isImportTable: false,
};

export default EditableTable;
