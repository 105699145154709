import React, { useState } from "react";
import TeacherSelector from "./TeacherSelector";
import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import {
  GET_CLASS_TEACHER_AND_TEACHERS_LIST,
  GET_DIVISION_SUBJECTS,
  GET_SUBJECTS_OF_CLASS_ISENABLE,
} from "../../views/classesView/graphql";
import { getSchoolID } from "../../shared/getSchoolID";

const SubjectsAndTeachers = () => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [teacherSubjects, setteacherSubjects] = useState([]);
  const [classTeacher, setclassTeacher] = useState();

  const [teachers, setTeachers] = useState([]);
  let params = useParams();
  const { state } = useLocation();

  const { loading, error } = useQuery(GET_DIVISION_SUBJECTS, {
    variables: {
      divid: parseInt(params.id),
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      setteacherSubjects(res?.division_subjects);
    },
  });
  const { loadingz, errorz } = useQuery(GET_CLASS_TEACHER_AND_TEACHERS_LIST, {
    variables: {
      divid: parseInt(params.id),
      schoolId: getSchoolID(),
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      setclassTeacher(res?.class_teacher[0]?.class_teachers[0]?.user?.id);
      setTeachers(res?.teachers);
    },
  });
  const { loading: subjectsLoading, error: subjectsError } = useQuery(
    GET_SUBJECTS_OF_CLASS_ISENABLE,
    {
      variables: {
        classid: state.classId,
        divid: parseInt(params.id),
        withDivisions: true,
      },
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        setSelectedSubjects(res?.subjects);
      },
    }
  );

  if (loading || loadingz || subjectsLoading) return "Loading...";
  if (error || errorz || subjectsError) return `Error! ${error.message}`;
  return (
    <div className="container">
      <div className="shadow-lg rounded-lg py-4 px-6 bg-white dark:bg-gray-800 mt-8 ">
        <div className="row-auto flex flex-row w-6/12 justify-start items-start text-left">
          <div className="text-left w-1/3 font-semibold ">Subject</div>
          <div className="text-left w-2/3 font-semibold">Assigned to</div>
        </div>
        {selectedSubjects
          ?.filter((sub) => sub?.selected)
          ?.map((sub, subIndex) => {
            const id = "manageclasses-class-subject-" + subIndex;
            return (
              <div
                id={id}
                className="row-auto flex flex-row justify-start mt-6 items-start w-6/12"
                key={sub.id}
              >
                <div className="text-left w-1/3">{sub.name}</div>
                <div className="text-left w-2/3">
                  <TeacherSelector
                    teacher={sub?.user}
                    teachersList={teachers}
                    subIndex={subIndex}
                    sub={sub}
                    divid={params.id}
                    teacherSubjects={teacherSubjects}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default SubjectsAndTeachers;
