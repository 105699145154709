import React from "react";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <Component />
    </>
  );
};
export default PublicRoute;
