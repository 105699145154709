import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";

import axios from "axios";

import { notification, Spin } from "antd";
import { getSchoolID } from "../shared/getSchoolID";

import { useNavigate } from "react-router-dom";
import { pluralRenderer } from "../shared/pluralRenderer";

import * as XLSX from "xlsx";
import ReactExport from "react-data-export";
import { TEACHER_BULK_UPLOAD_FORMAT } from "../utils/constants";
import Dropzone from "react-dropzone";
import moment from "moment";
import { DATE_FORMATS } from "../utils/constants";
import useAxios from "../hooks/useAxios";
import { Button } from "../components";
import { DownloadIcon } from "@heroicons/react/outline";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const importURL = `${process.env.REACT_APP_FUNCTION_ENDPOINT}/createUsers`;
const uploadFileUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/aws/uploadFile`;
const getFileUrl = `${process.env.REACT_APP_NODE_ENDPOINT}/aws/getFile`;

const TeacherBulkUpload = () => {
  const [errors, setErrors] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [teacherCount, setTeacherCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [recentlyUploadedList, setRecentlyUploadedList] = useState({
    files: [],
    filesToShow: 5,
    isExpanded: false,
  });

  const { data: filesData } = useAxios({
    method: "post",
    url: "aws/getRecentlyUploadedFiles",
    variables: {
      schoolId: getSchoolID(),
      forTeacherUpload: true,
    },
  });

  useEffect(() => {
    if (filesData) {
      setRecentlyUploadedList({
        ...recentlyUploadedList,
        files: filesData?.files,
      });
    }
  }, [filesData]);

  useEffect(() => {
    const handleUploadFile = async () => {
      setLoadingState(true);
      var formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("schoolId", getSchoolID());
      formData.append("forTeacherUpload", true);
      formData.append("fileName", selectedFile.name);
      try {
        const res = await axios.post(uploadFileUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setRecentlyUploadedList({
          ...recentlyUploadedList,
          files: res?.data?.files,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSelectedFile(null);
        setLoadingState(false);
        setSuccess(false);
      }
    };
    if (selectedFile != null && success) {
      handleUploadFile();
    }
  }, [success]);

  const handleFileDownload = async (fileKey) => {
    const fileNames = fileKey?.split("/");
    const fileName = fileNames?.[fileNames?.length - 1];
    setLoadingState(true);
    const params = {
      method: "post",
      url: getFileUrl,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: {
        fileKey: fileKey,
      },
    };
    try {
      axios(params)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState(false);
    }
  };

  const handleDrop = (acceptedFiles) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(acceptedFiles[0]);
      setSelectedFile(acceptedFiles[0]);
      fileReader.onload = (e) => {
        const readOpts = {
          cellText: false,
          cellDates: true,
          type: "buffer",
        };
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, readOpts);
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 0,
          defval: null,
          raw: false,
          dateNF: 'dd"-"mm"-"yyyy',
        });
        resolve(data);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
    promise.then((data) => {
      // check if file has data or not
      if (Object.keys(data[0]).length > 1) {
        // checking if mandatory fields are present or not
        checkIfMandatoryFieldColumnMissing(data);
      } else {
        setErrors(
          "Data is missing please fill the data and then upload the file"
        );
        setLoadingState(false);
      }
    });
  };

  const navigate = useNavigate();

  const checkIfMandatoryFieldColumnMissing = (data) => {
    const hasTeacherName = _.has(data[0], ["Teacher's Name"]);
    const hasSRNO = _.has(data[0], ["S.No"]);
    const hasMobNo = _.has(data[0], ["Mobile Number"]);

    if (hasTeacherName === false) {
      setErrors("Teacher's name column is missing");
      setLoadingState(false);
      return;
    }
    if (hasSRNO === false) {
      setErrors("S.No  column is missing");
      setLoadingState(false);
      return;
    }
    if (hasMobNo === false) {
      setErrors("Mobile Number column is missing");
      setLoadingState(false);
      return;
    }

    if (hasTeacherName && hasSRNO && hasMobNo) {
      process(data);
    }
  };

  const checkFormatOfPhoneNumber = (data) => {
    return new Promise((resolve, reject) => {
      const testPattern = data.filter((obj) =>
        /^[6-9]\d{9}$/.test(obj.contact_mobile_one)
      );

      const dataLength = data.length;

      const checkIfSame = dataLength === testPattern.length;

      if (checkIfSame) {
        resolve(data);
      } else {
        const wrongFormatNo = [];
        const testPattern = data.forEach((obj) => {
          const mob = /^[6-9]\d{9}$/.test(obj.contact_mobile_one);
          if (mob !== true) {
            wrongFormatNo.push(obj.contact_mobile_one);
          }
        });
        setErrors(
          `Some phone numbers does not meet the format requirements ${wrongFormatNo} `
        );
        reject("Some phone numbers does not meet the format requirements ");
        setLoadingState(false);
      }
    });
  };
  const makeThemFlat = (res2) => {
    return new Promise((resolve, reject) => {
      const payload = [];

      res2.forEach((dat, datIndex) => {
        payload.push({
          phone: `+91${dat.contact_mobile_one}`,
          first_name: dat.first_name,
          middle_name: dat.middle_name,
          last_name: dat.last_name,
          school_id: getSchoolID(),
          role_id: 2,
          gender: dat.gender,
          dob: dat.dob,
        });
      });

      if (payload.length > 0) {
        resolve(payload);
      } else {
        setErrors("Not enough data");
        reject("Not enough data");
        setLoadingState(false);
      }
    });
  };

  const final = (payload) => {
    // add the firebase url of add teacher

    return new Promise((resolve, reject) => {
      const config = {
        method: "post",
        url: importURL,
        headers: {
          "Content-Type": "application/json",
        },
        data: { data: payload },
      };
      axios(config)
        .then((res) => {
          if (res.data.successArray.length > 0) {
            resolve(res.data.successArray);
            notification["success"]({ message: "Teachers added", duration: 2 });
            setErrors(null);
            setLoadingState(false);
            setSuccess(true);
            setShowSuccessMessage(true);
          }
          if (res.data.failedArray.length > 0) {
            resolve(res.data.failedArray);
            const faildMob = [];
            res.data.failedArray.forEach((teach) => {
              faildMob.push(teach.phoneNumber);
            });
            notification["error"]({
              message: "Teachers already exists",
              duration: 2,
            });
            setErrors(
              `"These teacher's mobile number are already assigned to another teacher's" ${faildMob}`
            );
            setLoadingState(false);
            setTeacherCount(res.data.successArray.length);
          }
          if (
            res.data.failedArray.length > 0 &&
            res.data.successArray.length > 0
          ) {
            setSuccess(true);
            setShowSuccessMessage(true);
            resolve(res.data.failedArray);
            const faildMob = [];
            res.data.failedArray.forEach((teach) => {
              faildMob.push(teach.phoneNumber);
            });

            setErrors(
              `"These teacher's mobile number are already assigned to another teacher's" ${faildMob}`
            );
            setLoadingState(false);
          }
        })
        .catch((err) => {
          console.log("err", err);

          setErrors(err?.response?.data?.message);
          setLoadingState(false);
          reject(err.response.data.message);
        });
    });
  };

  const havesPath = (data, havesNot) => {
    setTeacherCount(data.length);
    checkFormatOfPhoneNumber(data)
      .then((passesFormatChecks) => {
        return makeThemFlat(passesFormatChecks);
      })
      .then((flatData) => {
        return final(flatData);
      })

      .catch((err) => console.log("overall error havesPath", err));
  };

  const process = (data, fileInfo, originalFile) => {
    // check the format and the type of fields in uploaded data
    const wrongTypeData = [];
    const wrongFormatData = [];

    data.some((datArr, index) => {
      if (
        datArr["Teacher's Name"] &&
        typeof datArr["Teacher's Name"] !== "string"
      ) {
        wrongTypeData.push({
          field: "Teacher's Name",
          data: datArr["Teacher's Name"],
          type: "string",
        });
        return true;
      }
      if (isNaN(datArr["S.No"])) {
        wrongTypeData.push({
          field: "S.No",
          data: datArr["S.No"],
          type: "number and this should not be empty ",
        });
        return true;
      }

      if (isNaN(datArr["Mobile Number"])) {
        wrongTypeData.push({
          field: "Mobile Number",
          data: datArr["Mobile Number"],
          type: "number and this should not be empty",
        });
        return true;
      }

      if (datArr["Gender"] && typeof datArr["Gender"] !== "string") {
        wrongTypeData.push({
          field: "Gender",
          data: datArr["Gender"],
          type: "string",
        });
        return true;
      }
      if (
        datArr["Gender"] &&
        datArr["Gender"] !== "M" &&
        datArr["Gender"] !== "F" &&
        datArr["Gender"] !== "T" &&
        datArr["Gender"] !== "NA"
      ) {
        wrongFormatData.push({
          field: "Gender",
          data: datArr["Gender"],
          type: "M or F or T or NA",
        });
        return true;
      }

      if (
        !datArr["Birth Date"] ||
        !moment(datArr["Birth Date"], DATE_FORMATS.DDMMYYYY).isValid()
      ) {
        wrongTypeData.push({
          field: "Birth Date",
          data: datArr["Birth Date"],
          type: "string, in the format DD-MM-YYYY",
        });
        return true;
      }

      return false;
    });

    if (!isEmpty(wrongTypeData)) {
      setErrors(
        `${wrongTypeData[0].field} should be of type ${wrongTypeData[0].type}`
      );
      setLoadingState(false);
    } else if (!isEmpty(wrongFormatData)) {
      setErrors(
        `Field ${wrongFormatData[0].field} should be in Format ${wrongFormatData[0].type}`
      );
      setLoadingState(false);
    } else {
      const newProcessArray = [];
      data.forEach((datArr) => {
        if (datArr["Teacher's Name"] === null) {
          setErrors("Teacher's name is missing");
          setLoadingState(false);
        }
        const splitName = datArr["Teacher's Name"].split(" ");
        const transformArray = {
          row_no: datArr["S.No"],
          first_name: splitName[0],
          middle_name: splitName[1],
          last_name: splitName[2],
          contact_mobile_one:
            datArr["Mobile Number"] !== undefined
              ? datArr["Mobile Number"]
              : null,

          gender: datArr["Gender"],
          dob: _.isNil(datArr["Birth Date"])
            ? null
            : moment(datArr["Birth Date"], DATE_FORMATS.DDMMYYYY).format(
                DATE_FORMATS.YYYYMMDD
              ),
        };
        newProcessArray.push(transformArray);
      });

      setLoadingState(true);

      havesPath(newProcessArray);
    }
  };

  const showAllFiles = () => {
    recentlyUploadedList?.isExpanded
      ? setRecentlyUploadedList({
          ...recentlyUploadedList,
          filesToShow: 5,
          isExpanded: false,
        })
      : setRecentlyUploadedList({
          ...recentlyUploadedList,
          filesToShow: recentlyUploadedList?.files?.length,
          isExpanded: true,
        });
  };

  const ErrorMessage = (errorz) => {
    return (
      <React.Fragment>
        <div className="text-left flex-col flex">
          <p className="text-red-500 text-lg leading-5 font-medium">
            Oops! Upload failed
          </p>

          <p className="text-sm leading-5 font-normal text-gray-500 mt-2.5">
            {errorz.error ? errorz.error : errorz}
          </p>
          <p className="text-sm leading-5 font-normal text-gray-500 mt-1">
            Please download the Template Excel File and upload in the right
            format
          </p>
        </div>
        <div className="flex-col flex">
          <button
            onClick={() => navigate(-1)}
            className="opacity-50 mr-2.5 w-48 ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white pointer-events-none"
          >
            View Teacher Details
          </button>
        </div>
      </React.Fragment>
    );
  };
  const SuccessMessage = () => {
    return (
      <React.Fragment>
        <div className="text-left flex-col flex">
          <p className="text-black text-lg leading-5 font-medium">
            Upload Successful
          </p>

          <p className="text-sm leading-5 font-normal text-gray-500 mt-2.5">
            {pluralRenderer(
              teacherCount,
              "teacher record have been saved",
              "teacher's records have been saved"
            )}
          </p>
        </div>
        <div className="flex-col flex">
          <button
            onClick={() => navigate(-1)}
            className=" mr-2.5 w-48 ml-2 py-2 bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-300 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded hover:text-white"
          >
            View Teacher Details
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="flex flex-col  px-8 py-7 md:space-y-4 h-screen w-screen overflow-x-hidden overflow-y-auto">
      <Spin spinning={loadingState}>
        <div className="flex flex-row justify-between">
          <p className="text-2xl w-full text-left font-bold">
            Bulk Upload Teachers
          </p>
          <Button
            id="manageteachers-bulkupload-back"
            buttonStyle="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Manage Teachers
          </Button>
        </div>

        <div className="flex flex-row justify-around items-center mt-10 gap-x-10">
          <div className="flex flex-col w-full ">
            <p className="text-left font-bold text-base">
              <span className="text-indigo-600 font-bold text-base">
                Step 1:
              </span>{" "}
              Download Template Excel file
            </p>
            <div className="border-4 px-24 py-12 rounded-lg border-grey-300  bg-white h-48 mt-5">
              <span className="flex flex-row justify-center">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_113_10484)">
                    <rect
                      x="3.5"
                      y="4.5"
                      width="42"
                      height="38"
                      rx="3.5"
                      stroke="#9CA3AF"
                    />
                    <line x1="4" y1="13.5" x2="46" y2="13.5" stroke="#9CA3AF" />
                    <line
                      x1="13"
                      y1="20.5"
                      x2="46"
                      y2="20.5"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="13"
                      y1="27.5"
                      x2="46"
                      y2="27.5"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="13"
                      y1="34.5"
                      x2="46"
                      y2="34.5"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="13.5"
                      y1="14"
                      x2="13.5"
                      y2="43"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="23.5"
                      y1="14"
                      x2="23.5"
                      y2="43"
                      stroke="#9CA3AF"
                    />
                    <line
                      x1="33.5"
                      y1="14"
                      x2="33.5"
                      y2="43"
                      stroke="#9CA3AF"
                    />
                    <circle cx="40" cy="37" r="8" fill="#4338CA" />
                    <path
                      d="M44.2825 37.5323L40.5325 41.2823C40.4611 41.3506 40.377 41.4041 40.285 41.4398C40.1024 41.5148 39.8976 41.5148 39.715 41.4398C39.6229 41.4041 39.5388 41.3506 39.4675 41.2823L35.7175 37.5323C35.6475 37.4624 35.5921 37.3794 35.5542 37.288C35.5164 37.1967 35.4969 37.0987 35.4969 36.9998C35.4969 36.8001 35.5762 36.6086 35.7175 36.4673C35.8587 36.3261 36.0502 36.2468 36.25 36.2468C36.4497 36.2468 36.6412 36.3261 36.7825 36.4673L39.25 38.9423V33.2498C39.25 33.0509 39.329 32.8602 39.4696 32.7195C39.6103 32.5788 39.801 32.4998 40 32.4998C40.1989 32.4998 40.3896 32.5788 40.5303 32.7195C40.6709 32.8602 40.75 33.0509 40.75 33.2498V38.9423L43.2175 36.4673C43.2872 36.397 43.3701 36.3412 43.4615 36.3032C43.5529 36.2651 43.6509 36.2455 43.75 36.2455C43.849 36.2455 43.947 36.2651 44.0384 36.3032C44.1298 36.3412 44.2127 36.397 44.2825 36.4673C44.3528 36.5371 44.4085 36.62 44.4466 36.7114C44.4847 36.8028 44.5043 36.9008 44.5043 36.9998C44.5043 37.0988 44.4847 37.1969 44.4466 37.2883C44.4085 37.3797 44.3528 37.4626 44.2825 37.5323Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_113_10484">
                      <rect width="48" height="48" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <ExcelFile
                filename="GROUNDUP_EXCEL_FORMAT"
                element={
                  <button
                    id="manageteachers-bulkupload-gettemplate"
                    style={{ color: "#4F46E5" }}
                    className="font-medium"
                  >
                    Click to Download
                  </button>
                }
              >
                <ExcelSheet
                  dataSet={TEACHER_BULK_UPLOAD_FORMAT}
                  name="GROUNDUP_EXCEL_FORMAT"
                />
              </ExcelFile>
              <p className="text-xs leading-4 font-normal text-gray-500 mt-1">
                Fill details mentioned under each column
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full customUploader">
            <Dropzone onDropAccepted={(files) => handleDrop(files)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <p className="text-left font-bold text-base">
                    <span className="text-indigo-600  font-bold text-base">
                      Step 2:
                    </span>{" "}
                    Upload Excel file
                  </p>
                  <>
                    <div
                      {...getRootProps()}
                      className="border-4 border-grey-300	border-dashed px-24 py-12 rounded-lg  bg-white h-48 mt-5"
                    >
                      <input {...getInputProps()} />

                      <span className="flex flex-row justify-center">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_113_10463)">
                            <rect
                              x="3.5"
                              y="4.5"
                              width="42"
                              height="38"
                              rx="3.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="4"
                              y1="13.5"
                              x2="46"
                              y2="13.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="20.5"
                              x2="46"
                              y2="20.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="27.5"
                              x2="46"
                              y2="27.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13"
                              y1="34.5"
                              x2="46"
                              y2="34.5"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="13.5"
                              y1="14"
                              x2="13.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="23.5"
                              y1="14"
                              x2="23.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <line
                              x1="33.5"
                              y1="14"
                              x2="33.5"
                              y2="43"
                              stroke="#9CA3AF"
                            />
                            <circle
                              r="8"
                              transform="matrix(1 0 0 -1 40 37)"
                              fill="#4338CA"
                            />
                            <path
                              d="M44.2825 36.4677L40.5325 32.7177C40.4611 32.6494 40.377 32.5959 40.285 32.5602C40.1024 32.4852 39.8976 32.4852 39.715 32.5602C39.6229 32.5959 39.5388 32.6494 39.4675 32.7177L35.7175 36.4677C35.6475 36.5376 35.5921 36.6206 35.5542 36.712C35.5164 36.8033 35.4969 36.9013 35.4969 37.0002C35.4969 37.1999 35.5762 37.3914 35.7175 37.5327C35.8587 37.6739 36.0502 37.7532 36.25 37.7532C36.4497 37.7532 36.6412 37.6739 36.7825 37.5327L39.25 35.0577V40.7502C39.25 40.9491 39.329 41.1398 39.4696 41.2805C39.6103 41.4212 39.801 41.5002 40 41.5002C40.1989 41.5002 40.3896 41.4212 40.5303 41.2805C40.6709 41.1398 40.75 40.9491 40.75 40.7502V35.0577L43.2175 37.5327C43.2872 37.603 43.3701 37.6588 43.4615 37.6968C43.5529 37.7349 43.6509 37.7545 43.75 37.7545C43.849 37.7545 43.947 37.7349 44.0384 37.6968C44.1298 37.6588 44.2127 37.603 44.2825 37.5327C44.3528 37.4629 44.4085 37.38 44.4466 37.2886C44.4847 37.1972 44.5043 37.0992 44.5043 37.0002C44.5043 36.9012 44.4847 36.8031 44.4466 36.7117C44.4085 36.6203 44.3528 36.5374 44.2825 36.4677Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_113_10463">
                              <rect width="48" height="48" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <p id="manageteachers-bulkupload-uploadfile">
                        <span className="text-sm leading-5 font-medium text-indigo-600">
                          Upload Excel file
                        </span>{" "}
                        <span className="text-sm text-gray-600">
                          or drag and drop here
                        </span>
                      </p>
                      <p className="text-xs leading-4 font-normal text-gray-500 mt-1">
                        We will share a preview once uploaded
                      </p>
                    </div>
                  </>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
        {errors ? (
          <div className="border-2 px-4 py-6 rounded-lg  bg-white flex flex-row mt-12 justify-between items-center">
            <ErrorMessage error={errors} />
          </div>
        ) : null}
        {success || showSuccessMessage ? (
          <div className="border-2 px-4 py-6 rounded-lg  bg-white flex flex-row mt-12 justify-between items-center">
            <SuccessMessage />
          </div>
        ) : null}
        <div className="text-left mt-8">
          <div className="flex items-center gap-x-4 mb-2">
            <h2 className="font-bold text-gray-700 m-0 p-0">
              Recently Uploaded Files
            </h2>
            {recentlyUploadedList?.files?.length > 5 && (
              <Button
                buttonStyle="primary"
                className="h-7 text-xs font-medium"
                onClick={showAllFiles}
              >
                {recentlyUploadedList?.isExpanded ? "View less" : "View all"}
              </Button>
            )}
          </div>
          <div className="flex flex-col gap-y-3">
            {recentlyUploadedList?.files
              ?.slice(0, recentlyUploadedList?.filesToShow)
              .map((file, index) => {
                // get filename after / in file key
                const fileNames = file?.["Key"]?.split("/");
                const fileName = fileNames[fileNames.length - 1];
                const downloadId =
                  "managestudents-bulkupload-download" + JSON.stringify(index);

                return (
                  <div
                    key={index}
                    className="bg-white rounded-xl shadow-sm flex justify-between p-2 items-center"
                  >
                    <div className="ml-1">
                      <p className="font-medium text-gray-700">{fileName}</p>
                    </div>
                    <div className="flex gap-x-5 items-center">
                      <p className="text-gray-400">
                        Uploaded on :{" "}
                        {moment(file?.["LastModified"]).format(
                          "DD MMM YYYY - hh:mma"
                        )}
                      </p>
                      <Button
                        id={downloadId}
                        buttonStyle="success"
                        className="h-9 rounded-md"
                        onClick={() => handleFileDownload(file?.["Key"])}
                      >
                        <DownloadIcon className="h-6 w-5 mr-2" />
                        Download
                      </Button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Spin>
    </div>
  );
};
export default TeacherBulkUpload;
