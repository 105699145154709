import React, { useState, useRef } from "react";
import { Spin, Pagination, notification } from "antd";
import { PlusIcon } from "@heroicons/react/solid";
import { LinkOutlined } from "@ant-design/icons";
import AddEnquiry from "./AddEnquiry";
import GUDropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import ConfirmModal from "../../components/modal";
import NoteAdder from "./NoteAdder";

// Helper functions
import { useNavigate } from "react-router-dom";
import useGetAdmissionEnquires from "../../hooks/useGetAdmissionEnquires";
import useDeleteAdmissionEnquiry from "../../hooks/useDeleteAdmissionEnquiry";
import useUpdateEnquiryStatus from "../../hooks/useUpdateEnquiryStatus";
import useGetAdmissionEnquiresXL from "../../hooks/useGetAdmissionEnquiresXL";
import { getSchoolID } from "../../shared/getSchoolID";
import useFilterOptions from "../../hooks/useFilterOptions";

const statusOptions = [
	{
		label: "All status",
		value: null,
	},
	{
		label: "Pending",
		value: "pending",
	},
	{
		label: "On Hold",
		value: "onhold",
	},
	{
		label: "Rejected",
		value: "rejected",
	},
	{
		label: "Accepted",
		value: "accepted",
	},
	{
		label: "Filled",
		value: "filled",
	},
];

const Admissions = () => {
	const PAGE_SIZE = 10;
	const schoolId = getSchoolID();
	const navigate = useNavigate();
	const containerRef = useRef();
	const [currentPage, setCurrentPage] = useState(1);
	const [currentOffset, setCurrentOffset] = useState(0);
	const [academicYears, setAcademicYears] = useState([]);
	const [classList, setClassList] = useState([]);
	const { isLoading: isLoadingAY } = useFilterOptions(
		{
			schoolId: schoolId,
			entity: "enquiry",
		},
		{
			onSuccess: (data) => {
				if (data?.academicYears?.length) {
					setAcademicYears(data.academicYears);
					setSelectedAcademicYear(data.academicYears[data.academicYears.length - 1]);
				}
				setClassList(data?.classes || []);
			},
			refetchOnWindowFocus: false,
		},
	);
	const [selectedAcademicYear, setSelectedAcademicYear] = useState({});
	const [showLoader, setShowLoader] = useState(false);
	const [search, setSearch] = useState("");
	const [currentClass, setCurrentClass] = useState({});
	const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(statusOptions[0].value);
	const { mutateAsync: updateEnquiryStatus } = useUpdateEnquiryStatus();
	const { data, isLoading } = useGetAdmissionEnquires(
		{
			schoolId,
			limit: PAGE_SIZE,
			offset: currentOffset,
			academicYearId: selectedAcademicYear?.value,

			enquiryStatus: currentStatus?.value,
			studentName: search,
			classId: currentClass.value,
		},
		{
			onSuccess: () => {
				setTimeout(() => {
					if (shouldScrollToBottom) {
						const dv = containerRef.current;
						dv.scrollTop = dv.scrollHeight;
						setShouldScrollToBottom(false);
					}
				}, 100);
			},
		},
	);
	const { mutateAsync: deleteEnquiry, isLoading: isDeleting } =
		useDeleteAdmissionEnquiry();

	const { mutateAsync: startDownloadXL } = useGetAdmissionEnquiresXL();
	const [currentEnquiry, setCurrentEnquiry] = useState({
		visible: false,
	});
	const [IsAddCreateEnquiryOpen, setIsAddCreateEnquiryOpen] = useState(false);
	const [IsUpdateNoteVisible, setIsUpdateNoteVisible] = useState(false);
	const admissionClassData = data?.classData || [];
	const overallData = data?.overallData || [];
	const totalEnquriesCount = overallData?.total || 0;
	const rowOptions = [
		{
			label: "Pending",
			value: "pending",
		},
		{
			label: "On hold",
			value: "onhold",
		},
		{
			label: "Rejected",
			value: "rejected",
		},
		{
			label: "Accepted",
			value: "accepted",
		},
		{
			label: "Fill admission form",
			value: "filled",
		},
	];
	const isBusy = isLoading || isDeleting || isLoadingAY || showLoader;

	const handlePageChange = (nextPage) => {
		setCurrentPage(nextPage);
		const newOffset = (nextPage - 1) * PAGE_SIZE;
		setCurrentOffset(newOffset);
	};

	const handleChangeStatus = async (formId, newStatus) => {
		if (newStatus === "filled") {
			navigate("/admissions/edit-form/" + formId);
			return;
		}
		try {
			setShouldScrollToBottom(true);
			setShowLoader(true);
			await updateEnquiryStatus({
				admissionEnquiryId: formId,
				enquiryStatus: newStatus,
			});
		} catch (err) {
			// Ignore
		} finally {
			setShowLoader(false);
		}
	};

	const downloadXL = async () => {
		try {
			setShowLoader(true);
			await startDownloadXL({
				academicYearId: selectedAcademicYear?.value,
				schoolId: schoolId,
			});
		} catch (err) {
			// Ignore
		} finally {
			setShowLoader(false);
		}
	};

	const copyFormLink = () => {
		const url = window.location.origin + "/v2/enquiry/" + schoolId;
		navigator.clipboard.writeText(url);
		notification["success"]({
			message: "Success!",
			description: "Enquiry Link copied sucessfully",
			duration: 1.5,
		});
	};

	const onConfirmDelete = async () => {
		try {
			await deleteEnquiry({
				admissionEnquiryId: currentEnquiry.id,
			});
		} catch {
			// Ignore
		} finally {
			setCurrentPage(1);
			setCurrentOffset(0);
			setCurrentEnquiry({ visible: false });
		}
	};

	const handleAcademicYearChange = (value) => {
		const selectedYear = academicYears.find(
			(academicYear) => academicYear.value === parseInt(value),
		);
		setSelectedAcademicYear(selectedYear);
		setCurrentOffset(0);
		setCurrentPage(1);
	};

	const handleClassChange = (value) => {
		setShouldScrollToBottom(true);
		const selectedClass = classList.find(
			(cl) => cl.value === parseInt(value),
		);
		setCurrentClass(selectedClass);
		setCurrentOffset(0);
		setCurrentPage(1);
	};

	const handleEditNote = (ae) => {		
		setCurrentEnquiry({
			id: ae?.id,
			note: ae?.note || "",
		});
		console.log(ae?.note, ae?.id)
		setTimeout(() => {
			setIsUpdateNoteVisible(true);
		}, 100)
	};

	const handleStatusChange = (value) => {
		const cs = statusOptions.find((cl) => cl.value === value);
		setShouldScrollToBottom(true);
		setCurrentStatus(cs);
		setCurrentOffset(0);
		setCurrentPage(1);
	};

	return (
		<div
			ref={containerRef}
			className="flex flex-col items-start ml-8 mt-6 pr-4 mb-6 h-screen w-screen overflow-y-scroll relative"
		>
			<div className="flex flex-row items-center flex flex-row w-full">
				<h2 className="text-2xl text-left font-bold flex-col m-0 mr-5">
					Admission Enquiries
				</h2>
				<GUDropdown
					id="manageclasses-year"
					type="default"
					options={academicYears}
					value={selectedAcademicYear?.label}
					onItemClick={handleAcademicYearChange}
				/>
			</div>
			<div className="mt-5 flex flex-row items-center flex flex-row justify-between w-full">
				<div className="flex flex-row gap-x-6">
					<Button
						id="formsettings"
						className="px-5 h-9 text-primary"
						buttonStyle="default"
						onClick={copyFormLink}
					>
						<LinkOutlined
							style={{ fontSize: "15px", marginRight: 8 }}
						/>
						<span>Copy Form Link</span>
					</Button>
					<button
						id="managecalendar-addnewevent"
						type="button"
						onClick={() => setIsAddCreateEnquiryOpen(true)}
						className="h-9 px-5 flex justify-center items-center bg-indigo-700 hover:bg-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md "
					>
						<span className="flex justify-center items-center gap-x-1">
							<PlusIcon className="h-4 w-5" />
							Add Enquiry
						</span>
					</button>
				</div>
				<Button
					id="formsettings"
					className="px-5 h-9"
					buttonStyle="success"
					onClick={downloadXL}
				>
					<span>Download All Inquiries</span>
				</Button>
			</div>
			<div className="mt-1 pt-4 min-w-full">
				<div className="min-w-full shadow rounded-lg overflow-hidden">
					<Spin spinning={isBusy}>
						<div className="flex flex-col justify-center bg-gray-50 text-center h-10 border-b border-gray-200 text-gray-500 text-sm font-bold">
							Action Status
						</div>
						<table className="min-w-full leading-normal">
							<thead>
								<tr>
									<th
										scope="col"
										className="px-5 py-2 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Class
									</th>
									<th
										scope="col"
										className="px-5 py-2 bg-gray-50  border-b border-gray-200 text-gray-500  text-center text-sm font-bold"
									>
										Total Inquiries
									</th>
									<th
										scope="col"
										className="px-5 py-2 bg-gray-50  border-b border-gray-200 text-gray-500  text-center text-sm font-bold"
									>
										Pending Actions
									</th>
									<th
										scope="col"
										className="px-5 py-2 bg-gray-50  border-b border-gray-200 text-gray-500  text-center text-sm font-bold"
									>
										Rejected
									</th>
									<th
										scope="col"
										className="px-5 py-2 bg-gray-50  border-b border-gray-200 text-gray-500  text-center text-sm font-bold"
									>
										On Hold
									</th>
									<th
										scope="col"
										className="px-5 py-2 bg-gray-50  border-b border-gray-200 text-gray-500  text-center text-sm font-bold"
									>
										Accepted
									</th>
									<th
										scope="col"
										className="px-5 py-2 bg-gray-50  border-b border-gray-200 text-gray-500  text-center text-sm font-bold"
									>
										Admission form filled
									</th>
								</tr>
							</thead>
							<tbody>
								<tr className="bg-gray-50 border-b border-gray-200">
									<td className="px-5 py-2 border-b border-gray-200 text-gray-500 text-left text-sm font-bold">
										<p className="text-gray-500">Total</p>
									</td>
									<td className="px-5 py-2 border-b border-gray-200 text-gray-500 text-center text-sm font-bold">
										<p className="text-gray-500">
											{overallData.total}
										</p>
									</td>
									<td className="px-5 py-2 border-b border-gray-200 text-gray-500 text-center text-sm font-bold">
										<p className="text-gray-500">
											{overallData.pending}
										</p>
									</td>
									<td className="px-5 py-2 border-b border-gray-200 text-gray-500 text-center text-sm font-bold">
										<p className="text-gray-500">
											{overallData.rejected}
										</p>
									</td>
									<td className="px-5 py-2 border-b border-gray-200 text-gray-500 text-center text-sm font-bold">
										<p className="text-gray-500">
											{overallData.onhold}
										</p>
									</td>
									<td className="px-5 py-2 border-b border-gray-200 text-gray-500 text-center text-sm font-bold">
										<p className="text-gray-500">
											{overallData.accepted}
										</p>
									</td>
									<td className="px-5 py-2 border-b border-gray-200 text-gray-500 text-center text-sm font-bold">
										<p className="text-gray-500">
											{overallData.filled}
										</p>
									</td>
								</tr>

								{admissionClassData?.map((acd, mockIndex) => {
									return (
										<tr id={acd.className} key={mockIndex}>
											<td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-left">
												<p className="text-gray-500">
													Class {acd.className}
												</p>
											</td>
											<td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-center">
												<p className="text-gray-500">
													{acd?.total}
												</p>
											</td>
											<td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-center">
												<p className="text-gray-500">
													{acd?.pending}
												</p>
											</td>
											<td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-center">
												<p className="text-gray-500">
													{acd?.rejected}
												</p>
											</td>
											<td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-center">
												<p className="text-gray-500">
													{acd?.onhold}
												</p>
											</td>
											<td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-center">
												<p className="text-gray-500">
													{acd?.accepted}
												</p>
											</td>
											<td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-center">
												<p className="text-gray-500">
													{acd.filled}
												</p>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Spin>
				</div>
			</div>

			<div className="flex flex-row mt-8 sm:mb-0 justify-between w-full items-center">
				<div>
					<form
						className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center"
						autoComplete="off"
						onSubmit={(e) => e.preventDefault()}
					>
						<div className="relative">
							<span className="absolute inset-y-0 left-0 flex items-center pl-2">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.446 15.6239 7.53434C15.4667 5.62267 14.583 3.84391 13.1545 2.56379C11.7261 1.28368 9.86143 0.599512 7.94402 0.651979C6.02662 0.704446 4.20219 1.48956 2.84587 2.84587C1.48956 4.20219 0.704446 6.02662 0.651979 7.94402C0.599512 9.86143 1.28368 11.7261 2.56379 13.1545C3.84391 14.583 5.62267 15.4667 7.53434 15.6239C9.446 15.7812 11.3453 15.2 12.8416 13.9999L15.9083 17.0666C15.9857 17.1447 16.0779 17.2067 16.1794 17.249C16.281 17.2913 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2913 16.8204 17.249C16.922 17.2067 17.0141 17.1447 17.0916 17.0666C17.2418 16.9112 17.3257 16.7035 17.3257 16.4874C17.3257 16.2713 17.2418 16.0636 17.0916 15.9083ZM8.16659 13.9999C7.01286 13.9999 5.88505 13.6578 4.92576 13.0168C3.96647 12.3759 3.2188 11.4648 2.77729 10.3989C2.33578 9.33301 2.22026 8.16012 2.44534 7.02856C2.67042 5.89701 3.22599 4.8576 4.0418 4.0418C4.8576 3.22599 5.89701 2.67042 7.02856 2.44534C8.16012 2.22026 9.33301 2.33578 10.3989 2.77729C11.4648 3.2188 12.3759 3.96647 13.0168 4.92576C13.6578 5.88505 13.9999 7.01286 13.9999 8.16659C13.9999 9.71368 13.3853 11.1974 12.2914 12.2914C11.1974 13.3853 9.71368 13.9999 8.16659 13.9999Z"
										fill="#9CA3AF"
									/>
								</svg>
							</span>
							<input
								defaultValue={search}
								type="text"
								id='"form-subscribe-Filter'
								className="pl-10 rounded-md border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
								placeholder="Search by student name"
								onChange={(e) => {
									if (
										e.target.value.length > 2 ||
										e.target.value.length === 0
									) {
										setSearch(e.target.value);
									}
								}}
							/>
						</div>
					</form>
				</div>
				<div id="manageteachers-sort" className="flex gap-x-2">
					<Button
						id="formsettings"
						className="px-5 text-primary"
						buttonStyle="default"
					>
						<span>
							Filled: {data?.filledEnquries || "0"}/
							{data?.totalEnquries || "0"}
						</span>
					</Button>
					<GUDropdown
						defaultValue={"Class: All"}
						id="manageclasses-year"
						type="primary"
						options={classList}
						value={currentClass?.label}
						onItemClick={handleClassChange}
					/>
					<GUDropdown
						id={"asd"}
						value={currentStatus?.label}
						defaultValue={"All status"}
						type="primary"
						className
						options={statusOptions}
						onItemClick={handleStatusChange}
					/>
				</div>
			</div>

			<div className="mt-3 -mx-4 sm:-mx-8 px-4 sm:px-8 pt-4">
				<div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
					<Spin spinning={isBusy}>
						<table className="min-w-full leading-normal">
							<thead>
								<tr>
									<th
										scope="col"
										className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Enquiry ID
									</th>
									<th
										scope="col"
										className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Student Name
									</th>
									<th
										scope="col"
										className="w-72 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Parent's Name
									</th>
									<th
										scope="col"
										className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Class
									</th>
									<th
										scope="col"
										className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Mobile No.
									</th>
									<th
										scope="col"
										className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Status
									</th>
									<th
										scope="col"
										className="w-36 px-5 py-3 bg-gray-50  border-b border-gray-200 text-gray-500  text-left text-sm font-bold"
									>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{(data?.enquiries || [])?.map(
									(admissionEnquiry, mockIndex) => {
										const rowId =
											"admissionEnquiry-" +
											admissionEnquiry.id;
										return (
											<tr id={rowId} key={mockIndex}>
												<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
													<p className="text-gray-500">
														{admissionEnquiry?.id}
													</p>
													<p className="text-gray-500 text-xs">
														{admissionEnquiry?.date}
													</p>
												</td>
												<td
													onClick={() =>
														handleEditNote(
															admissionEnquiry,
														)
													}
													className="px-5 cursor-pointer py-5 border-b border-gray-200 bg-white text-sm text-left"
												>
													<p className="text-gray-500">
														{
															admissionEnquiry?.studentName
														}
													</p>
													<p className="text-gray-500 text-xs">
														{admissionEnquiry?.note
															?.length
															? admissionEnquiry?.note
															: "Add note"}
													</p>
												</td>
												<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
													<p className="text-gray-500">
														{
															admissionEnquiry?.parentName
														}
													</p>
												</td>
												<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
													<p className="text-gray-500">
														{
															admissionEnquiry?.className
														}
													</p>
												</td>
												<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
													<p className="text-gray-500">
														{
															admissionEnquiry?.parentContact
														}
													</p>
												</td>
												<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
													<p className="text-gray-500">
														{
															admissionEnquiry.enquiryStatus
														}
													</p>
												</td>
												<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
													{admissionEnquiry?.enquiryStatus ===
													"filled" ? (
														<Button
															id="formsettings"
															className="px-5 h-9"
															buttonStyle="success"
															onClick={() =>
																navigate(
																	"/admissions/edit-form/" +
																		admissionEnquiry.id,
																)
															}
														>
															<span>
																View Form
															</span>
														</Button>
													) : (
														<ControlledDropdown
															value={
																admissionEnquiry?.enquiryStatus
															}
															type="primary"
															className
															options={rowOptions}
															onItemClick={(
																cs,
															) => {
																handleChangeStatus(
																	admissionEnquiry?.id,
																	cs,
																);
															}}
														/>
													)}
												</td>
											</tr>
										);
									},
								)}
							</tbody>
						</table>
					</Spin>
				</div>
			</div>
			<div className="w-full items-center flex flex-col pt-5 pb-20">
				<Pagination
					size="small"
					pageSize={PAGE_SIZE}
					total={totalEnquriesCount}
					current={currentPage}
					showSizeChanger={false}
					onChange={handlePageChange}
				/>
			</div>

			{IsAddCreateEnquiryOpen ? (
				<AddEnquiry onClose={() => setIsAddCreateEnquiryOpen(false)} />
			) : null}
			{IsUpdateNoteVisible ? (
				<NoteAdder
					data={currentEnquiry}
					onClose={() => setIsUpdateNoteVisible(false)}
				/>
			) : null}
			<ConfirmModal
				visible={currentEnquiry.visible}
				onConfirm={onConfirmDelete}
				onCancel={() => setCurrentEnquiry({ visible: false })}
				cancelText="No"
				okText="Yes"
				message="Are you sure you want to remove this enquiry?"
			/>
		</div>
	);
};

const ControlledDropdown = (props) => {
	const [currentValue, setCurrentValue] = useState(props?.value);
	const currentOption = props?.options?.find(
		(op) => op.value === currentValue,
	);
	return (
		<GUDropdown
			{...props}
			value={currentOption?.label || currentValue}
			onItemClick={(v) => {
				setCurrentValue(v);
				props?.onItemClick(v);
			}}
		/>
	);
};

export default Admissions;