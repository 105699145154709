// custom hook to track page views using Segment
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
}
