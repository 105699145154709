import React from "react";
import Card from "./Card";

export default function StatsView({
  currentAcademicYear,
  teacherCount,
  studentCount,
  studentsPresent,
  teachersPresent,
  isTeacherAttendanceTaken,
}) {
  return (
    <>
      <p className="text-sm w-full text-left font-medium">
        Current Academic Year {currentAcademicYear}
      </p>
      <div className=" grid grid-cols-4 gap-5">
        <Card
          id={"dashboard-studentcount"}
          title="Total Students"
          stat={studentCount}
        />
        <Card
          id={"dashboard-teachercount"}
          title="Total Teachers"
          stat={teacherCount}
        />
        <Card
          id={"dashboard-studentcount"}
          showPercentage="true"
          title="Students Present Today"
          stat={studentsPresent}
          total={studentCount}
        />
        <Card
          id={"dashboard-teachercount"}
          showPercentage="true"
          title="Teachers Present Today"
          stat={teachersPresent}
          total={teacherCount}
          isTeacherAttendanceTaken={isTeacherAttendanceTaken}
        />
      </div>
    </>
  );
}
