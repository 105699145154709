/* eslint-disable no-console */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const GetEnquiryData = async (payload) => {
	const config = {
		url: `${process.env.REACT_APP_NODE_ENDPOINT}/admission/getEnquiryBasicData`,
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		data: payload,
	};
	const { data } = await axios.request(config);
	return data?.data;
};

export default function useGetEnquiryData(payload, options = {}) {
	return useQuery(
		["GetEnquiryData"],
		() => GetEnquiryData(payload),
		{
			...options,
			enabled: payload?.admissionEnquiryId !== undefined,
		}
	);
}