import React from "react";
import book from "../../assets/images/book.png";
import { useNavigate } from "react-router-dom";

const ReportBook = ({ onClick, children, id }) => (
  <button id={id} onClick={onClick} className="relative text-center">
    <img alt="book" src={book} className="h-56 w-64" />
    <div className="absolute top-0 left-7 text-white font-bold text-lg w-36 h-40 flex flex-col justify-center items-center">
      {children}
    </div>
  </button>
);

function Reports() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col ml-6 w-screen pl-0 md:p-4 md:space-y-4 h-screen overflow-x-hidden overflow-y-auto">
      <div className="flex flex-row gap-x-4 items-center">
        <p className="text-2xl text-left font-bold flex-col m-0">Reports</p>
      </div>
      <div className="flex flex-col flex-1 items-center justify-center">
        <div className="flex justify-center items-center gap-x-7 mb-14">
          <ReportBook
            onClick={() => {
              navigate(`/attendanceReport`);
            }}
            id="reports-student-attendance"
          >
            <p className="leading-snug">Student</p>
            <p className="leading-snug">Attendance</p>
          </ReportBook>
          <ReportBook
            onClick={() => {
              navigate(`/reports/staffReport`);
            }}
            id="reports-staff-attendance"
          >
            <p className="leading-snug">Staff</p>
            <p className="leading-snug">Attendance</p>
          </ReportBook>
          <ReportBook
            onClick={() => {
              navigate(`/reports/student-report`);
            }}
            id="reports-students"
          >
            <p className="leading-snug">Student</p>
            <p className="leading-snug">Details</p>
          </ReportBook>
        </div>
        <div className="flex justify-center items-center gap-x-7">
          <ReportBook
            onClick={() => {
              navigate(`/marksReport`);
            }}
            id="reports-marks"
          >
            <p className="leading-snug">Marks</p>
            <p className="leading-snug">Report</p>
          </ReportBook>
          <ReportBook
            onClick={() => {
              navigate(`/reports/progressReport`);
            }}
            id="reports-progress"
          >
            <p className="leading-snug">Progress</p>
            <p className="leading-snug">Report</p>
          </ReportBook>
        </div>
      </div>
    </div>
  );
}

export default Reports;
