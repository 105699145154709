import { Avatar } from "antd";
import _ from "lodash";
import { getS3Image } from "./s3";
export const getAvatar = (first_name, last_name, image, size, style) => {
  let lastName = _.isNil(last_name) ? "" : last_name;
  let hasNoImage = _.isNil(image);
  if (hasNoImage) {
    return (
      <Avatar
        size={size === "small" ? 24 : 40}
        style={{
          color: "#fff",
          backgroundColor: "#4F46E5",
          textTransform: "uppercase",
          ...style,
        }}
      >
        {first_name?.charAt(0)}
        {lastName?.charAt(0)}
      </Avatar>
    );
  }
  return (
    <Avatar
      src={getS3Image(image)}
      size={size === "small" ? 24 : 40}
      alt="avatar"
      style={{
        color: "#fff",
        backgroundColor: "#4F46E5",
        textTransform: "uppercase",
        ...style,
      }}
    />
  );
};
export const getAvatarForDetailedView = (
  first_name,
  last_name,
  image,
  size
) => {
  let lastName = _.isNil(last_name) ? "" : last_name;
  let hasNoImage = _.isNil(image);
  if (hasNoImage) {
    return (
      <Avatar
        shape="square"
        className="avatar-detailedView"
        style={{
          backgroundColor: "#4F46E5",
        }}
      >
        {first_name?.charAt(0)}
        {lastName?.charAt(0)}
      </Avatar>
    );
  }
  return (
    <img
      src={getS3Image(image)}
      alt=""
      className="-p-6"
      style={{
        height: "288px",
        margin: "auto",
      }}
    />
  );
};
